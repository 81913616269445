import * as fromEntities from '../shared/entities';
import {
  getIdsForParentIdAndPage,
  getCurrentPageForParentId,
  getTotalPagesForParentId,
  getIsLoadingForParentId,
  getIsLoadedForParentId,
} from '../shared/relationalPaginator';
//- Selectors

export const getForTicketIdAndPage = (state, ticketId, page) =>
  getIdsForParentIdAndPage(
    state.ticketAllocation.ticketAllocationsByTicketId,
    ticketId,
    page
  ).map(id => fromEntities.getEntity(state.entities.ticketAllocation, id));
export const getCurrentPageForTicketId = (state, ticketId) =>
  getCurrentPageForParentId(
    state.ticketAllocation.ticketAllocationsByTicketId,
    ticketId
  );
export const getTotalPagesForTicketId = (state, ticketId) =>
  getTotalPagesForParentId(
    state.ticketAllocation.ticketAllocationsByTicketId,
    ticketId
  );
export const getIsLoadingForTicketId = (state, ticketId, page) =>
  getIsLoadingForParentId(
    state.ticketAllocation.ticketAllocationsByTicketId,
    ticketId,
    page
  );
export const getIsLoadedForTicketId = (state, ticketId) =>
  getIsLoadedForParentId(
    state.ticketAllocation.ticketAllocationsByTicketId,
    ticketId
  );

export const getPurchases = state =>
  state.ticketAllocation.currentAllocation.purchases;
export const getTicketAllocation = (state, allocationId) =>
  state.entities.ticketAllocation
    ? fromEntities.getEntity(state.entities.ticketAllocation, allocationId)
    : {};
export const getTicket = (state, ticketId) =>
  state.entities.ticket
    ? fromEntities.getEntity(state.entities.ticket, ticketId)
    : {};

export const getDiscount = state =>
  state.ticketAllocation.currentAllocation.discount
    ? fromEntities.getEntity(
        state.entities.discount,
        state.ticketAllocation.currentAllocation.discount
      )
    : null;
export const getAgent = state =>
  state.ticketAllocation.currentAllocation.agent
    ? fromEntities.getEntity(
        state.entities.agent,
        state.ticketAllocation.currentAllocation.agent
      )
    : null;

export const getIssueError = state =>
  state.ticketAllocation.currentAllocation.error;
