import React from "react";
import { connect } from "react-redux";

// components
import MainContent, {
  Title,
  LineUpHelmet
} from "../../components/MainContent/MainContent";

// other
import { mailchimpActions } from "../../modules/mailchimp/mailchimp";
import { getQueryVariable } from "../../modules/mailchimp/utils";
import "./MailchimpManage.css";
import connectMailchimp from "./connect_mailchimp.png";

// Grommet
import { Box, Image, Heading, Paragraph } from "grommet";

class MailchimpComplete extends React.Component {
  componentDidMount() {
    this.props.completeMailchimpConnect({
      code: this.props.code
    });
  }

  render() {
    return (
      <>
        <LineUpHelmet title="Mailchimp" description="Your Mailchimp Sync" />
        <MainContent>
          <Title>Mailchimp Sync</Title>
          <Box
            margin={{ vertical: "medium" }}
            background="#fff194"
            justify="between"
            round="xsmall"
            direction="row"
            wrap={false}
            pad="large"
            align="start"
            gap="large"
          >
            <Box
              direction="column"
              width={{ max: "500px" }}
              margin={{ top: "large" }}
            >
              <Heading level="2" size="small">
                Please wait while we setup your Mailchimp integration
              </Heading>
              <Paragraph>This won't take long.</Paragraph>
            </Box>
            <Box width={{ min: "450px", max: "450px" }}>
              <Image
                src={connectMailchimp}
                alt="Connect your Mailchimp account"
                title="Connect your Mailchimp account"
                fit="contain"
                fill
              />
            </Box>
          </Box>
        </MainContent>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    code: getQueryVariable("code")
  };
};

export default connect(mapStateToProps, { ...mailchimpActions })(
  MailchimpComplete
);
