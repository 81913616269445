import React from "react";
import Pagination from "../../Pagination/Pagination";
import EmptyState from "../../EmptyState/EmptyState";
import PerformanceTable from "./PerformanceTable";
import withEmptyState from "../../withEmptyState";
import emptyPerformances from "./empty_performances.png";

export const PerformanceEmptyState = ({ showButton, onButtonClick }) => (
  <EmptyState
    background
    buttonText="Create New Performance"
    onButtonClick={onButtonClick}
    showButton={showButton}
    emptyStateHeadline="This event has no future performances"
    emptyStateDescription="A performance is a date and time when your event is happening. When you have future performances, they can be managed from this page."
    emptyStateImageSrc={emptyPerformances}
    emptyStateImageAlt="No future performances"
    emptyStateImageTitle="No future performances"
  />
);

const PerformanceList = ({
  onPageClick,
  performances,
  totalPages,
  currentPage,
  onGeneratePerformanceLink
}) => (
  <>
    <PerformanceTable
      performances={performances}
      onGeneratePerformanceLink={onGeneratePerformanceLink}
    />
    <Pagination
      totalPages={totalPages}
      currentPage={currentPage}
      onSelectPage={onPageClick}
    />
  </>
);

export default withEmptyState(PerformanceList);
