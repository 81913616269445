import React from 'react';
import { useField } from 'formik';

// This acts as an bridge to make the line-up custom form components
// compatible with Formik
const FormikField = props => {
  const [field, meta, helpers] = useField(props);
  // map to redux-form meta values
  const reduxFormMeta = {
    ...meta,
    warning: false // ?? we don't have mapping for this in Formik
  };

  // pull out the component from the props
  const { component, ...propsFiltered } = props;
  // React needs component names to start with
  // a capital letter
  const CustomFieldImplementation = component;
  const { setValue, setError } = helpers;

  const componentProps = {
    ...propsFiltered,
    ...field,
    setFormikInputValue: setValue,
    setFormikInputError: setError,
    meta: reduxFormMeta
  };

  return <CustomFieldImplementation {...componentProps} />;
};

export default FormikField;
