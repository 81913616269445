import React, { useState } from "react";
import { connect } from "react-redux";
import Modal from "../../../components/Modal/Modal";
import { hideModal } from "../../../modules/modal/modal";
import TransferForm from "../../../components/TransferForm/TransferForm";
import { orderActions, orderSelectors } from "../../../modules/orders/orders";
import {
  ticketActions,
  ticketSelectors,
} from "../../../modules/tickets/tickets";
import { toast } from "react-toastify";
import { bindPromiseCreators } from "redux-saga-routines";
import { bindActionCreators } from "redux";

import { showModal } from "../../../modules/modal/modal";

const TransferModal = ({
  order,
  lineItems,
  hideModal,
  showModal,
  submitCreateTicketTransfer,
  selectPerformanceforPurchase,
  selectTicketforPurchase,
  transferEntries,
  fetchOrder,
  sendOrderConfirmation,
}) => {
  const [loading, setLoading] = useState(false);
  const [didExchange, setDidExchange] = useState(false);
  const handleSelectSeatsClick = (e, performanceId, quantity, purchaseId) => {
    e.preventDefault();
    showModal("SEATING_CHART_MODAL", {
      performanceId: performanceId,
      quantity: quantity,
      purchaseId: purchaseId,
      order: order,
      lineItems: lineItems,
    });
  };

  const handleTransferModalClose = () => {
    if (didExchange) {
      fetchOrder({ orderId: order.id });
      const { customer, id } = order;
      showModal("ALERT_MODAL", {
        title: "Resend Confirmation?",
        description: `The email confirmation will be sent to ${customer.email}`,
        onConfirmClick: () => sendOrderConfirmation({ orderId: id }),
      });
    } else {
      hideModal();
    }
  };
  const handleTransferClicked = (purchaseId, quantity, ticketId, seats) => {
    setLoading(true);
    submitCreateTicketTransfer({
      purchaseId: purchaseId,
      quantity: quantity,
      ticketId: ticketId,
      seats: seats,
    }).then(
      (response) => {
        toast.success("Ticket Exchange Successful");
        setDidExchange(true);
        setLoading(false);
      },
      (error) => {
        toast.error(`${error.error.errors._error}`);
        setLoading(false);
      }
    );
  };

  const handlePerformanceChange = (purchaseId, selectedPerformance) => {
    selectPerformanceforPurchase({
      purchaseId: purchaseId,
      selectedPerformance: selectedPerformance,
    });
  };

  const handleTicketChange = (purchaseId, selectedTicket) => {
    selectTicketforPurchase({
      purchaseId: purchaseId,
      selectedTicket: selectedTicket,
    });
  };

  return (
    <Modal onRequestClose={handleTransferModalClose}>
      <TransferForm
        order={order}
        lineItems={lineItems}
        onSelectSeatsClick={handleSelectSeatsClick}
        onTransferModalClose={handleTransferModalClose}
        onTransferClicked={handleTransferClicked}
        onPerformanceChange={handlePerformanceChange}
        transferEntries={transferEntries}
        onTicketChange={handleTicketChange}
        loading={loading}
      />
    </Modal>
  );
};
const mapStateToProps = (state, ownProps) => {
  const orderId = parseInt(ownProps.orderId, 10);
  const transferEntries = ticketSelectors.getTransferEntries(state);

  return {
    order: orderSelectors.getOrder(state, orderId),
    lineItems: orderSelectors.getLineItemsForOrder(state, orderId),
    transferEntries: transferEntries,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindPromiseCreators(
      {
        submitCreateTicketTransfer: ticketActions.submitCreateTicketTransfer,
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        selectPerformanceforPurchase:
          ticketActions.selectPerformanceforPurchase,
        selectTicketforPurchase: ticketActions.selectTicketforPurchase,
        hideModal,
        showModal,
        fetchOrder: orderActions.fetchOrder,
        sendOrderConfirmation: orderActions.sendOrderConfirmation,
      },
      dispatch
    ),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferModal);
