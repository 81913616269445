import { call, takeLatest, put } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as types from './types';
import * as actions from './actions';
import * as api from '../../services/api';
import { fetchEntity } from '../shared/operations';

function* updateUser(action) {
  yield put(showLoading());
  yield put(startSubmit('user'));
  yield put(actions.userUpdate.request());
  const { response, error } = yield call(
    api.updateUser,
    action.payload.userId,
    action.payload.user
  );
  if (response) {
    yield put(actions.userUpdate.success(action.payload.userId, response));
    yield put(stopSubmit('user'));
  } else {
    yield put(actions.userUpdate.failure(action.payload.userId, error));
    yield put(stopSubmit('user', error.errors));
  }
  yield put(hideLoading());
}

export function* watchUpdateUser() {
  yield takeLatest(types.USER_UPDATE, updateUser);
}

function* fetchUser(action) {
  yield put(showLoading());
  yield put(actions.user.request(action.payload.userId));
  const { response, error } = yield call(api.getUser, action.payload.userId);
  if (response) {
    yield put(actions.user.success(action.payload.userId, response));
  } else {
    yield put(actions.user.failure(action.payload.userId, error));
  }
  yield put(hideLoading());
}

export function* watchLoadUser() {
  yield takeLatest(types.LOAD, fetchUser);
}

const exportCustomers = fetchEntity.bind(
  null,
  actions.exportCustomers,
  api.exportCustomers
);
export function* watchExportCustomers() {
  yield takeLatest(actions.exportCustomers, exportCustomers);
}
