import { call, takeEvery, put, fork } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import * as types from './types';
import * as actions from './actions';
import * as api from '../../services/api';

function* redeemReceipt(action) {
  const { receipt } = action.payload;
  yield put(showLoading());
  yield put(actions.redeemReceipt.request(receipt.id));
  const { response, error } = receipt.isRedeemed
    ? yield call(api.deleteCheckin, receipt.id)
    : yield call(api.createCheckin, receipt.id);
  if (response) {
    yield put(actions.redeemReceipt.success(response));
  } else {
    yield put(actions.redeemReceipt.failure(error));
  }
  yield put(hideLoading());
}

function* fetchTicketReceipts(page, performanceId) {
  yield put(actions.ticketReceipts.request(performanceId, page));
  const { response, error } = yield call(
    api.getTicketReceipts,
    performanceId,
    page
  );
  if (response) {
    yield put(actions.ticketReceipts.success(page, response));
    return response;
  } else {
    yield put(actions.ticketReceipts.failure(page, error));
  }
}

function* fetchPagedTicketReceipts(action) {
  const { performanceId } = action.payload;
  let page = 1;
  const response = yield call(fetchTicketReceipts, page, performanceId);
  if (response) {
    const { pagination } = response;
    while (page < pagination.totalPages) {
      page++;
      yield fork(fetchTicketReceipts, page, performanceId);
    }
  }
}

function* fetchAllTicketReceipts(action) {
  yield put(showLoading());
  yield put(actions.allTicketReceipts.request(action.payload.performanceId));
  yield call(fetchPagedTicketReceipts, action);
  yield put(actions.allTicketReceipts.success(action.payload.performanceId));
  yield put(hideLoading());
}

export function* watchRedeemTicketReceipt() {
  yield takeEvery(types.CHECKIN_USER, redeemReceipt);
}

export function* watchLoadTicketReceipts() {
  yield takeEvery(types.LOAD_PAGE, fetchAllTicketReceipts);
}
