import { createAction } from 'redux-actions';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import * as types from './types';

//- Actions
export const createDiscountCode = createAction(
  types.DISCOUNT_CREATE,
  (discount, push) => ({ discount, push })
);

export const discountCreate = {
  request: createAction(types.CREATE.REQUEST),
  success: createAction(types.CREATE.SUCCESS, response => ({
    response,
  })),
  failure: createAction(types.CREATE.FAILURE, error => ({
    error,
  })),
};

export const updateDiscountCode = createAction(
  types.DISCOUNT_UPDATE,
  (discountId, discount, push) => ({ discountId, discount, push })
);

export const discountUpdate = {
  request: createAction(types.UPDATE.REQUEST, discountId => ({
    discountId,
  })),
  success: createAction(types.UPDATE.SUCCESS, (discountId, response) => ({
    discountId,
    response,
  })),
  failure: createAction(types.UPDATE.FAILURE, (discountId, error) => ({
    discountId,
    error,
  })),
};

export const loadDiscount = createAction(types.LOAD_DISCOUNT, discountId => ({
  discountId,
}));

export const discount = {
  request: createAction(types.DISCOUNT.REQUEST, discountId => ({ discountId })),
  success: createAction(types.DISCOUNT.SUCCESS, (discountId, response) => ({
    discountId,
    response,
  })),
  failure: createAction(types.DISCOUNT.FAILURE, (discountId, error) => ({
    discountId,
    error,
  })),
};

export const searchDiscounts = createRoutine(types.SEARCH_DISCOUNTS);
