import { takeEvery, put, call, fork } from "redux-saga/effects";
import * as api from "../../services/api";
import * as actions from "./actions";
import { fetchEntity, createEntity, submitForm } from "../shared/operations";
import { showLoading, hideLoading } from "react-redux-loading-bar";

function* fetchEvents(page) {
  yield put(actions.fetchEvents.request({ page }));
  const { response, error } = yield call(api.fetchEvents, { page });
  if (response) {
    yield put(actions.fetchEvents.success({ response, page }));
    return response;
  } else {
    put(actions.fetchEvents.failure({ error, page }));
  }
  yield put(actions.fetchEvents.fulfill({ page }));
}

function* fetchPagedEvents(action) {
  let page = 1;
  const response = yield call(fetchEvents, page);
  if (response) {
    const { pagination } = response;
    while (page < pagination.totalPages) {
      page++;
      yield fork(fetchEvents, page);
    }
  }
}

function* fetchAllEvents(action) {
  yield put(showLoading());
  yield put(actions.fetchAllEvents.request());
  yield call(fetchPagedEvents, action);
  yield put(actions.fetchAllEvents.success());
  yield put(hideLoading());
}

export function* watchFetchAllEvents() {
  yield takeEvery(actions.fetchAllEvents.TRIGGER, fetchAllEvents);
}

const fetchEvent = fetchEntity.bind(null, actions.fetchEvent, api.fetchEvent);
export function* watchFetchEvent() {
  yield takeEvery(actions.fetchEvent.TRIGGER, fetchEvent);
}

const fetchEventBySlug = fetchEntity.bind(
  null,
  actions.fetchEventBySlug,
  api.fetchEventBySlug
);
export function* watchFetchEventBySlug() {
  yield takeEvery(actions.fetchEventBySlug.TRIGGER, fetchEventBySlug);
}

const createEvent = submitForm.bind(null, actions.createEvent, api.createEvent);

export function* watchCreateEvent() {
  yield takeEvery(actions.createEvent.TRIGGER, createEvent);
}

const updateEvent = submitForm.bind(null, actions.updateEvent, api.updateEvent);

export function* watchUpdateEvent() {
  yield takeEvery(actions.updateEvent.TRIGGER, updateEvent);
}

const getEventInvitations = fetchEntity.bind(
  null,
  actions.getEventInvitations,
  api.getEventInvitations
);
export function* watchGetEventInvitations() {
  yield takeEvery(actions.getEventInvitations.TRIGGER, getEventInvitations);
}

const getEventPermissions = fetchEntity.bind(
  null,
  actions.getEventPermissions,
  api.getEventPermissions
);
export function* watchGetEventPermissions() {
  yield takeEvery(actions.getEventPermissions.TRIGGER, getEventPermissions);
}

const createEventInvitation = fetchEntity.bind(
  null,
  actions.createEventInvitation,
  api.createEventInvitation
);
export function* watchCreateEventInvitation() {
  yield takeEvery(actions.createEventInvitation.TRIGGER, createEventInvitation);
}

const createEventPermission = createEntity.bind(
  null,
  actions.createEventPermission,
  api.createEventPermission
);
export function* watchCreateEventPermissions() {
  yield takeEvery(actions.createEventPermission.TRIGGER, createEventPermission);
}

const deleteEventInvitation = fetchEntity.bind(
  null,
  actions.deleteEventInvitation,
  api.deleteEventInvitation
);
export function* watchDeleteEventInvitation() {
  yield takeEvery(actions.deleteEventInvitation.TRIGGER, deleteEventInvitation);
}

const deleteEventPermission = fetchEntity.bind(
  null,
  actions.deleteEventPermission,
  api.deleteEventPermission
);
export function* watchDeleteEventPermission() {
  yield takeEvery(actions.deleteEventPermission.TRIGGER, deleteEventPermission);
}

const updateEventInvitation = fetchEntity.bind(
  null,
  actions.updateEventInvitation,
  api.updateEventInvitation
);
export function* watchUpdateEventInvitation() {
  yield takeEvery(actions.updateEventInvitation.TRIGGER, updateEventInvitation);
}

const createBulkUpload = fetchEntity.bind(
  null,
  actions.createBulkUpload,
  api.createBulkUpload
);
export function* watchCreateBulkUpload() {
  yield takeEvery(actions.createBulkUpload.TRIGGER, createBulkUpload);
}
