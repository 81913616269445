import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import './ToolbarButton.css';

const ToolBarButton = ({
  type,
  label,
  icon,
  onClick,
  isActive,
  postIcon,
  className
}) => {
  const toolbarBtnClass = classNames('toolbar-button', className, {
    'toolbar-button--active': isActive && isActive(type)
  });

  return (
    <button
      onClick={e => onClick && onClick(e, type)}
      title={label}
      className={toolbarBtnClass}
      tabIndex={-1}
    >
      {icon ? <FontAwesomeIcon icon={icon} fixedWidth /> : label}
      {postIcon && postIcon}
    </button>
  );
};

export default ToolBarButton;
