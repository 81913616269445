import React, { Component } from "react";
import { connect } from "react-redux";
import { SubTitle, PageTop } from "../../../components/MainContent/MainContent";
import {
  PerformanceList,
  PerformanceEmptyState,
} from "../../../components/Event/Event";
import PageNav, { PageNavItem } from "../../../components/PageNav/PageNav";
import Can, { checkRole } from "../../../components/Can";
import {
  performanceActions,
  performanceSelectors,
} from "../../../modules/performances/performances";
import {
  seatingChartSelectors,
  seatingChartActions,
} from "../../../modules/seatingCharts/seatingCharts";
import { showModal } from "../../../modules/modal/modal";
import { EventAuthContext } from "../event-auth-context";

// Grommet
import { Box, Button, ResponsiveContext } from "grommet";
import { Add, Upload } from "grommet-icons";
import moment from "moment";

class PerformanceListView extends Component {
  componentDidMount() {
    this.loadPerformances();
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.currentPage !== prevProps.currentPage ||
      this.props.eventId !== prevProps.eventId ||
      this.props.dateFilter !== prevProps.dateFilter
    ) {
      this.loadPerformances();
    }
  }
  loadPerformances = () => {
    this.props.fetchPerformancesPage({
      eventId: this.props.eventId,
      page: this.props.currentPage,
      resultsPerPage: 25,
      ...this.getDateFilters(),
    });
  };
  handlePageClick = (page) => {
    this.props.history.push(
      `/event/${this.props.eventId}/performances/${page}`
    );
  };

  handleAddPerformanceClick = (ev) => {
    ev.preventDefault();
    this.props.fetchAllSeatingCharts();
    this.props.showModal("PERFORMANCE_CREATE_MODAL", {
      eventId: this.props.eventId,
      seatingCharts: this.props.seatingCharts,
      onSubmitSuccess: this.handlePerformanceCreateSuccess,
      role: this.context,
    });
  };
  handleBulkUploadClick = () => {
    this.props.history.push(`/event/${this.props.eventId}/bulkupload`);
  };

  handlePerformanceCreateSuccess = (result) => {
    const { response } = result;
    const { eventId, history } = this.props;
    history.push(
      `/event/${eventId}/performance/${response.result}/ticket/create`
    );
  };
  handleGeneratePerformanceLink = (performanceId) => {
    return `/event/${this.props.eventId}/performance/${performanceId}`;
  };
  handleFilterFuturePerformances = () => {
    const { setDateFilter } = this.props;
    setDateFilter("future");
  };
  handleFilterAllPerformances = () => {
    const { setDateFilter } = this.props;
    setDateFilter("all");
  };
  getDateFilters = () =>
    this.props.dateFilter === "all"
      ? { gte: new Date(2012, 1, 1, 0, 0, 0, 0).toISOString() }
      : {
          gte: moment()
            .subtract(1, "hour")
            .toISOString(),
        };
  render() {
    const {
      performances = [],
      currentPage,
      totalPages,
      isLoading,
      eventId,
      dateFilter,
    } = this.props;
    return (
      <ResponsiveContext.Consumer>
        {(size) => (
          <>
            <PageTop>
              <SubTitle title="Dates & Times">
                <Can
                  role={this.context}
                  perform="event:performance:create"
                  yes={() => (
                    <Box
                      gap="small"
                      direction={size !== "small" ? "row" : "column"}
                    >
                      <Button
                        secondary
                        onClick={this.handleAddPerformanceClick}
                        label="New Performance"
                        size="small"
                        icon={<Add size="small" />}
                      />
                      <Button
                        secondary
                        onClick={this.handleBulkUploadClick}
                        label="Bulk Upload"
                        size="small"
                        icon={<Upload size="small" />}
                      />
                    </Box>
                  )}
                />
              </SubTitle>
            </PageTop>
            <Box
              margin={
                size !== "small"
                  ? { top: "medium", bottom: "large" }
                  : { top: "none", bottom: "large" }
              }
            >
              <Box margin={{ bottom: "medium" }}>
                {size !== "small" ? (
                  <PageNav mobileMenu="Performances Menu">
                    <PageNavItem
                      onClick={this.handleFilterFuturePerformances}
                      label="Future"
                      active={dateFilter === "future"}
                    />
                    <PageNavItem
                      onClick={this.handleFilterAllPerformances}
                      label="All"
                      active={dateFilter === "all"}
                    />
                  </PageNav>
                ) : (
                  <PageNav mobileMenu="Performances Menu">
                    {[
                      {
                        label: (
                          <PageNavItem
                            onClick={this.handleFilterFuturePerformances}
                            label="Future"
                            active={dateFilter === "future"}
                          />
                        ),
                      },
                      {
                        label: (
                          <PageNavItem
                            onClick={this.handleFilterAllPerformances}
                            label="All"
                            active={dateFilter === "all"}
                          />
                        ),
                      },
                    ]}
                  </PageNav>
                )}
              </Box>
              <PerformanceList
                isLoading={isLoading}
                isEmpty={!performances.length > 0}
                emptyStateComponent={
                  <PerformanceEmptyState
                    onButtonClick={this.handleAddPerformanceClick}
                    showButton={checkRole(this.context, "event:edit")}
                  />
                }
                onAddPerformanceClick={this.handleAddPerformanceClick}
                onPageClick={this.handlePageClick}
                performances={performances}
                currentPage={currentPage}
                totalPages={totalPages}
                eventId={eventId}
                onGeneratePerformanceLink={this.handleGeneratePerformanceLink}
              />
            </Box>
          </>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

PerformanceListView.contextType = EventAuthContext;

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const page = parseInt(match.params.page, 10) || 1;
  const eventId = parseInt(match.params.eventId, 10);
  const performanceId = parseInt(match.params.performanceId, 10);
  return {
    performances: performanceSelectors.getPerformancesForPage(state, page),
    currentPage: page,
    totalPages: performanceSelectors.getPerformancesTotalPages(state),
    dateFilter: performanceSelectors.getDateFilter(state),
    seatingCharts: seatingChartSelectors.getSeatingCharts(state),
    isLoading: performanceSelectors.getIsLoadingForPage(state, page),
    eventId,
    performanceId,
  };
};

export default connect(mapStateToProps, {
  ...performanceActions,
  ...seatingChartActions,
  showModal,
})(PerformanceListView);
