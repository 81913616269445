import React from 'react';
import './SearchBar.css';

const SearchBar = ({ onTextChange }) => (
  <div className="searchbar">
    <div className="searchbar__wrap">
      <input
        onKeyUp={onTextChange}
        className="searchbar-input"
        type="search"
        placeholder="Search here…"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        required=""
        maxLength="512"
      />
    </div>
  </div>
);

export default SearchBar;
