import React from 'react';
import styles from './DateKeyColumn.module.css';

import SpaceWarningLabel from 'components/Planner/SpaceWarningLabel';

import {
  getCellLayoutOffsets,
  getPendingPlacementsForPerformance,
  getCellTimeSubdivisions
} from './layoutUtils';

const DateKeyColumn = ({ startFrom, blocks, performances, placements }) => {
  // const { setColumnWidth, columnWidth } = usePlanner();
  let spaceWarning = [];
  for (let performance of performances) {
    const layout = getCellLayoutOffsets(startFrom, performance);
    const pendingPlacements = getPendingPlacementsForPerformance(
      performance.id,
      placements
    );
    if (pendingPlacements.length > 0) {
      spaceWarning.push({
        layout,
        performance,
        pendingPlacements
      });
    }
  }

  // const handleZoomIn = evt => {
  //   evt.preventDefault();
  //   setColumnWidth(columnWidth + 50);
  // };

  // const handleZoomOut = evt => {
  //   evt.preventDefault();
  //   setColumnWidth(columnWidth - 50);
  // };

  return (
    <div className={styles.dateColumnRoot}>
      <div className={styles.dateColumnRootHeader}>
        Time
        {/* <div className={styles.zoom}>
          <a href="#zoom-out" onClick={handleZoomOut}>
            <FontAwesomeIcon icon="minus" />
          </a>{" "}
          <a href="#zoom-in" onClick={handleZoomIn}>
            <FontAwesomeIcon icon="plus" />
          </a>
        </div> */}
      </div>
      <div className={styles.dateKeyColumnBlocksContent}>
        {spaceWarning.map(warning => (
          <SpaceWarningLabel {...warning} />
        ))}
        {blocks.map(block => {
          const hourDisplay = block.date.format('h a');
          return (
            <div
              key={block.date.format()}
              className={styles.dateKeyColumnBlock}
            >
              <div className={styles.hour}>{hourDisplay}</div>
              <div className={styles.timeslots}>
                {getCellTimeSubdivisions().map(timeslot => (
                  <div key={timeslot} className={styles.timeslot}>
                    {timeslot}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DateKeyColumn;
