import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedNumber } from 'react-intl';
/* eslint-disable react/style-prop-object */
import MainContent, {
  Title,
  HeaderActions,
  PageTop,
  LineUpHelmet
} from '../../components/MainContent/MainContent';
import EmptyState from '../../components/EmptyState/EmptyState';
import {
  productSelectors,
  productActions
} from '../../modules/products/products';
import TableView from '../table/TableView';

// Grommet
import { Box, Button } from 'grommet';
import { Add } from 'grommet-icons';

// Assets
import emptyProducts from './empty_products.png';

const columns = [
  {
    title: 'Name',
    dataIndex: 'title',
    width: '55'
  },
  {
    title: 'Net',
    dataIndex: 'price',
    width: '15',
    render: (price, product) => (
      <FormattedNumber
        value={price}
        currency={product.currency}
        style='currency'
      />
    )
  },
  {
    title: 'Tax',
    dataIndex: 'tax',
    width: '15',
    render: (tax, product) => (
      <FormattedNumber
        value={tax}
        currency={product.currency}
        style='currency'
      />
    )
  },
  {
    title: 'Total',
    dataIndex: 'total',
    width: '15',
    render: (total, product) => (
      <FormattedNumber
        value={total}
        currency={product.currency}
        style='currency'
      />
    )
  }
];

class ProductList extends Component {
  handleProductClick = product => {
    this.props.history.push(`/product/${product.id}`);
  };

  handleSelectPage = page => {
    this.props.history.push(`/products/${page}`);
  };

  render() {
    const { products, currentPage, totalPages, size, isLoading } = this.props;
    return (
      <>
        <LineUpHelmet
          title='Products'
          description='Manage your products in Line-Up'
        />
        <MainContent>
          <PageTop>
            <Title>Products</Title>
            <HeaderActions>
              <Link to='/product/create'>
                <Button
                  primary
                  label='New Product'
                  icon={<Add size='small' />}
                  size='small'
                  fill={true}
                />
              </Link>
            </HeaderActions>
          </PageTop>
          <Box
            margin={
              size !== 'small' ? { vertical: 'medium' } : { vertical: 'large' }
            }
          >
            <TableView
              columns={columns}
              data={products}
              isLoading={isLoading}
              emptyStateComponent={
                <EmptyState
                  background
                  buttonText='Create New Products'
                  buttonLink='/product/create'
                  showButton={true}
                  emptyStateHeadline='You have no Products yet'
                  emptyStateDescription='Sell items such as vouchers, merchandise and other digital or non-digital products.'
                  emptyStateImageSrc={emptyProducts}
                  emptyStateImageAlt='No products yet'
                  emptyStateImageTitle='No products yet'
                />
              }
              fetchData={this.props.fetchProductsPage}
              onRowClick={this.handleProductClick}
              totalPages={totalPages}
              currentPage={currentPage}
              onPageClick={this.handleSelectPage}
            />
          </Box>
        </MainContent>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const page = parseInt(match.params.page, 10) || 1;
  return {
    products: productSelectors.getProductsForPage(state, page),
    currentPage: page,
    totalPages: productSelectors.getProductsTotalPages(state),
    isLoading: productSelectors.getProductsLoadingStatus(state)
  };
};

export default connect(mapStateToProps, {
  ...productActions
})(ProductList);
