import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { accountActions } from 'modules/account/account';
import { NavLink } from 'react-router-dom';

import styled from 'styled-components';

// Grommet
import {
  Anchor,
  Box,
  Header,
  Sidebar,
  Image,
  Nav,
  ResponsiveContext,
  Menu,
  Text
} from 'grommet';
import {
  CircleQuestion,
  SettingsOption,
  Logout,
  FormDown
} from 'grommet-icons';

// Assets
import logo from 'assets/sidebar_icon_alt.svg';

// Components
import AppMenu from 'components/MainContent/AppMenu/AppMenu';
import UserPhoto from 'components/UserPhoto/UserPhoto';

const AppSidebarContainer = styled.div`
  @media print {
    display: none;
  }
`;

const FixedHeader = styled.div`
  position: fixed;
  width: 100%;
  z-index: 5;
`;

const AppSideBar = ({ pathName, user, logoutUser }) => {
  const handleLogout = () => {
    logoutUser();
  };
  return (
    <AppSidebarContainer>
      <ResponsiveContext.Consumer>
        {size => (
          <>
            {size !== 'small' ? (
              <Sidebar
                background='sidebar'
                gap='medium'
                width={{ min: '160px' }}
                pad='medium'
                height={'100vh'}
                header={
                  <NavLink to='/' style={{ width: '35px' }}>
                    <Box
                      width={'35px'}
                      height={'37px'}
                      pad='none'
                      justify='start'
                      alignContent='start'
                    >
                      <Image src={logo} fit={'contain'} />
                    </Box>
                  </NavLink>
                }
              >
                <Nav gap='small'>
                  <AppMenu pathName={pathName} />
                </Nav>
              </Sidebar>
            ) : (
              <FixedHeader>
                <Header
                  direction='row'
                  background='sidebar'
                  pad={{ horizontal: 'medium', vertical: 'small' }}
                  justify='between'
                  align='center'
                >
                  <Box
                    width={'35px'}
                    height={'37px'}
                    pad='none'
                    justify='start'
                    alignContent='start'
                  >
                    <Image src={logo} fit={'contain'} />
                  </Box>
                  <Box direction='row' gap='medium' align='center'>
                    <Anchor
                      href='http://support.lineupnow.com'
                      target='_blank'
                      rel='noopener noreferrer'
                      title='Help'
                    >
                      <CircleQuestion />
                    </Anchor>
                    <AppMenu pathName={pathName} />
                    <Menu
                      plain
                      dropProps={{
                        align: { top: 'bottom', left: 'left' },
                        elevation: 'xlarge',
                        round: 'small',
                        width: '100%'
                      }}
                      dropBackground='sidebar'
                      icon={<FormDown size='small' color='brand' />}
                      label={[
                        <Box
                          alignContent='center'
                          direction='row'
                          align='center'
                          // justify="between"
                          gap='small'
                          pad={{ vertical: 'xsmall' }}
                        >
                          <UserPhoto user={user} />
                        </Box>
                      ]}
                      items={[
                        {
                          label: (
                            <Box alignSelf='center'>
                              <Text size='small' weight={900}>
                                Settings
                              </Text>
                            </Box>
                          ),
                          onClick: () => {},
                          icon: (
                            <Box pad='xsmall' alignSelf='center'>
                              <SettingsOption
                                size='small'
                                color='sidebar-copy'
                              />
                            </Box>
                          ),
                          href: '/account',
                          gap: 'small'
                        },
                        {
                          label: (
                            <Box alignSelf='center'>
                              <Text size='small' weight={900}>
                                Logout
                              </Text>
                            </Box>
                          ),
                          icon: (
                            <Box pad='xsmall' alignSelf='center'>
                              <Logout size='small' color='sidebar-copy' />
                            </Box>
                          ),
                          gap: 'small',
                          onClick: handleLogout
                        }
                      ]}
                    />
                  </Box>
                </Header>
              </FixedHeader>
            )}
          </>
        )}
      </ResponsiveContext.Consumer>
    </AppSidebarContainer>
  );
};

const mapStateToProps = state => {
  return {
    pathName: state.router.location.pathname,
    user: state.account.user.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        ...accountActions
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppSideBar);
