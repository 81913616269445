import { createRequestTypes } from '../shared/types';

export const DISCOUNT_CREATE = 'line-up/discount/CREATE';
export const DISCOUNT_UPDATE = 'line-up/discount/UPDATE';

export const DISCOUNTS = createRequestTypes('discount', 'DISCOUNTS');
export const DISCOUNT = createRequestTypes('discount', 'DISCOUNT');
export const CREATE = createRequestTypes('discount', 'CREATE');
export const UPDATE = createRequestTypes('discount', 'UPDATE');

export const LOAD_PAGE = 'line-up/discount/LOAD_PAGE';
export const LOAD_DISCOUNT = 'line-up/discount/LOAD_DISCOUNT';

export const LOAD_ALL = 'line-up/discount/LOAD_ALL';
export const ALL_DISCOUNTS = createRequestTypes('discount', 'ALL_DISCOUNTS');
export const SEARCH_DISCOUNTS = 'line-up/discount/SEARCH_DISCOUNTS';
