import React from 'react';
import * as Yup from 'yup';
import FormikField from 'components/Form/FormikField';
import FormikForm from 'components/Form/FormikForm';
import FormField from 'components/Form/FormField/FormField';

const validationSchema = Yup.object({
  password: Yup.string().required('A password is required'),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match'
  )
});

const ResetPasswordForm = ({ onSubmit }) => {
  return (
    <FormikForm
      validationSchema={validationSchema}
      initialValues={{ password: '', confirmpassword: '' }}
      onSubmit={onSubmit}
      cancelBtn={false}
      submitText='Reset Password'
      formTitle='Reset Your Password'
      guidanceNotes='Please enter a new password below'
      hasborder
      heavypadding
      headercentred
      submitfull
    >
      <FormikField
        name='password'
        label='Password'
        component={FormField}
        type='password'
        placeholder='Your new password'
      />
      <FormikField
        name='passwordConfirmation'
        label='Confirm Password'
        component={FormField}
        type='password'
        placeholder='Your new password'
      />
    </FormikForm>
  );
};

export default ResetPasswordForm;
