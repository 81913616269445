import reducer from './reducers';
import * as ticketReceiptSelectors from './selectors';
import * as ticketReceiptTypes from './types';
import * as ticketReceiptActions from './actions';
import * as ticketReceiptOperations from './operations';

export {
  ticketReceiptSelectors,
  ticketReceiptTypes,
  ticketReceiptActions,
  ticketReceiptOperations
};

export default reducer;
