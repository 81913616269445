import React from 'react';
import '../FormField/FormField.css';
import './FormFieldSelect.css';
import classNames from 'classnames';

const FormFieldSelect = ({
  input,
  label,
  type,
  placeholder,
  className,
  id,
  rowInline,
  rowInlineRadio,
  rowNoLabel,
  labelInline,
  labelRadio,
  labelCheckbox,
  fieldShort,
  fieldInline,
  inputRadio,
  inputCheckbox,
  options,
  hintBefore,
  meta: { touched, error, warning }
}) => {
  const rowClass = classNames('form__row', className, {
    'form__row--inline': rowInline,
    'form__row--inline-radio': rowInlineRadio,
    'form__row--no-label': rowNoLabel
  });
  const labelClass = classNames('form__label', className, {
    'form__label--inline': labelInline,
    'form__label--radio': labelRadio,
    'form__label--checkbox': labelCheckbox
  });
  const fieldClass = classNames('form__field', className, {
    'form__field--short': fieldShort,
    'form__field--inline': fieldInline
  });
  const inputClass = classNames('form__field-input--select', className, {});
  const optionClass = classNames('form__field-option', className, {});
  return (
    <div className={rowClass}>
      <label className={labelClass} htmlFor={id}>
        {label}
      </label>
      {hintBefore && (
        <div className="form__hint form__hint--before">{hintBefore}</div>
      )}
      <div className={fieldClass}>
        <select
          {...input}
          placeholder={placeholder}
          id={id}
          type={type}
          className={inputClass}
        >
          {options.map((option, i) => {
            return (
              <option value={option.value} key={i} className={optionClass}>
                {option.description}
              </option>
            );
          })}
        </select>
        {touched &&
          ((error && <span className="form__error">{error}</span>) ||
            (warning && <span className="form__error">{warning}</span>))}
      </div>
    </div>
  );
};

export default FormFieldSelect;
