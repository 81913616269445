import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../../components/Modal/Modal';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { hideModal } from '../../modules/modal/modal';
import NoteForm from '../../components/NoteForm/NoteForm';
import { removeEmpty } from '../../utils/forms';
import { orderActions } from '../../modules/orders/orders';

class NoteModal extends Component {
  handleOnSubmit = values => {
    if (this.props.noteId) {
      return this.props.onNoteUpdateSubmit({
        noteId: this.props.noteId,
        ...removeEmpty(values)
      });
    }
    return this.props
      .onNoteCreateSubmit({
        orderId: this.props.orderId,
        ...removeEmpty(values)
      })
      .then(() => {
        if (this.props.onSubmitSuccess) {
          this.props.onSubmitSuccess();
        }
      });
  };

  handleSubmitSuccess = () => {
    this.props.hideModal();
  };

  render() {
    return (
      <Modal onRequestClose={() => this.props.hideModal()}>
        <NoteForm
          onSubmit={this.handleOnSubmit}
          onSubmitSuccess={this.handleSubmitSuccess}
          onCancel={this.props.hideModal}
          edit={!!this.props.noteId}
          initialValues={{
            text: this.props.text
          }}
        />
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        onNoteCreateSubmit: orderActions.sumbmitCreateNoteForm,
        onNoteUpdateSubmit: orderActions.sumbmitUpdateNoteForm
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        hideModal
      },
      dispatch
    )
  };
};

export default connect(null, mapDispatchToProps)(NoteModal);
