import moment from 'moment';

import { getPerformanceStartDate, getPerformanceEndDate } from 'utils/dates';

const TIMESLOT_HEIGHT = 30; // in px
const MINUTES_PER_TIMESLOT = 15; // for each timeslot, how many minutes does it represent

const BLOCK_HEIGHT = (60 / MINUTES_PER_TIMESLOT) * TIMESLOT_HEIGHT; // a block is 1 full hour

// utility functions

// returns the hours that we'll need to render based on the earliest
// and last performance in the list
export const getStartEndForRender = performances => {
  let first = null;
  let last = null;
  let hours = 0;

  for (let performance of performances) {
    const perfStartDate = getPerformanceStartDate(performance);

    // if there's no endTime, getPerformanceEndDate will return the Date(endDate)
    // so the Date returned can be earlier than the start date when startDate == endDate
    // and startTime exists.
    // in this case we use the start date here
    const perfEndDate =
      getPerformanceEndDate(performance) < perfStartDate
        ? perfStartDate
        : getPerformanceEndDate(performance);

    if (first === null) {
      first = perfStartDate;
    } else {
      if (perfStartDate < first) {
        first = perfStartDate;
      }
    }

    if (last === null) {
      last = perfEndDate;
    } else {
      if (perfEndDate > last) {
        last = perfEndDate;
      }
    }
  }

  first = moment(first).startOf('hour');
  last = moment(last).endOf('hour');
  hours = last.diff(first, 'hours');

  return {
    first,
    last,
    hours
  };
};

export const getCellTimeSubdivisions = () => [0, 15, 30, 45];

export const getDateTimeDividerBlocks = (startDate, totalHours) => {
  let blocks = [];
  let currentHour = 0;
  while (currentHour <= totalHours) {
    blocks.push({
      date: startDate.clone().add(currentHour, 'h')
    });
    currentHour += 1;
  }
  return blocks;
};

// get placements for a lane and performance, that have been placed
export const getPlacementForLaneAndPerformance = (
  laneId,
  performanceId,
  placements
) => {
  const foundPlacements = placements.filter(placement => {
    if (
      placement.eventPlannerLaneId === laneId &&
      placement.performanceId === performanceId &&
      placement.isPlaced === true
    ) {
      return true;
    }
    return false;
  });

  return foundPlacements;
};

// get placements that are currently pending
export const getPendingPlacementsForPerformance = (
  performanceId,
  placements
) => {
  const pendingPlacements = placements.filter(placement => {
    if (
      placement.performanceId === performanceId &&
      placement.isPlaced === false
    ) {
      return true;
    }
    return false;
  });

  return pendingPlacements;
};

// this calculates the position that a cell should be placed
export const getCellLayoutOffsets = (startFrom, performance) => {
  const perfStart = getPerformanceStartDate(performance);
  const dateDelta = moment(perfStart).diff(startFrom, 'minutes');
  let top = (dateDelta / MINUTES_PER_TIMESLOT) * TIMESLOT_HEIGHT;

  // the height of the alloment based on the endTime (if it exists!)
  let height = TIMESLOT_HEIGHT;
  if (performance.endTime) {
    const endDate = getPerformanceEndDate(performance);
    const timeDelta = moment(endDate).diff(perfStart, 'minutes');
    // TODO: change this so it streches to the next performance's start time
    height = (timeDelta / MINUTES_PER_TIMESLOT) * TIMESLOT_HEIGHT;
  }

  // add in the 1px margin at the bottom of each block
  top = top + top / BLOCK_HEIGHT;

  return { top, height };
};

// generate the layout information we need to place the placements on the grid
export const buildPlacementLayoutData = (
  startFrom,
  lane,
  performances,
  placements,
  columnWidth
) => {
  let placementCells = [];

  for (let performance of performances) {
    let cellPlacements = getPlacementForLaneAndPerformance(
      lane.id,
      performance.id,
      placements
    );
    const layout = getCellLayoutOffsets(startFrom, performance);
    const placementWidth = columnWidth / lane.capacity;

    // there are some placemnets for this performance, and lane
    if (cellPlacements.length > 0) {
      let leftOffset = 0;
      for (let placement of cellPlacements) {
        placementCells.push({
          type: 'placement',
          lane,
          performance,
          placement,
          layout: {
            ...layout,
            left: leftOffset,
            width: placementWidth
          }
        });
        leftOffset += placementWidth;
      }

      // if there is capacity available, we can allow the user to allocate
      // tickets here
      if (cellPlacements.length < lane.capacity) {
        placementCells.push({
          type: 'createAllocation',
          lane,
          performance,
          layout: {
            ...layout,
            left: leftOffset,
            width: placementWidth
          }
        });
      }
    }

    // no placements
    else {
      placementCells.push({
        type: 'createAllocation',
        lane,
        performance,
        layout: {
          ...layout,
          left: 0,
          width: placementWidth
        }
      });
    }
  }
  return placementCells;
};

// find all cells that don't have a placement, and create layout data so we can place
// drop targets there
export const buildColumnCellsLayoutData = (
  startFrom,
  lane,
  performances,
  placements
) => {
  let cells = []; // a cell is (lane, performance, capacity_remaining)
  for (let performance of performances) {
    const cellPlacements = getPlacementForLaneAndPerformance(
      lane.id,
      performance.id,
      placements
    );
    const layout = getCellLayoutOffsets(startFrom, performance);
    const pendingPlacements = getPendingPlacementsForPerformance(
      performance.id,
      placements
    );

    // cell is completely empty
    if (cellPlacements.length === 0) {
      cells.push({
        type: 'droptarget',
        performance,
        lane,
        layout,
        pendingPlacements
      });
    }
    // there is a placement here, but more can be added to this cell
    else if (cellPlacements.length < lane.capacity) {
      cells.push({
        type: 'droptarget',
        performance,
        lane,
        layout,
        pendingPlacements
      });
    }
    // all capacity is taken for this cell
    else {
      cells.push({
        type: 'placeholder',
        performance,
        lane,
        layout,
        pendingPlacements
      });
    }
  }

  return cells;
};

export const isLaneLockedForDate = (lane, date) => {
  for (let eventPlannerLaneDate of lane.eventPlannerLaneDates) {
    if (eventPlannerLaneDate.date === date && eventPlannerLaneDate.isLocked) {
      return true;
    }
  }
  return false;
};

export const isLaneDisabledForDate = (lane, date) => {
  for (let eventPlannerLaneDate of lane.eventPlannerLaneDates) {
    if (eventPlannerLaneDate.date === date && eventPlannerLaneDate.isDisabled) {
      return true;
    }
  }
  return false;
};

// Given a lane, a performance, and all placements can we place new receipts/allocations
// into that lane? More specifically is there capacity?
export const canPlacePerformanceIntoLaneForDay = (
  lane,
  performance,
  placements
) => {
  const cellPlacements = getPlacementForLaneAndPerformance(
    lane.id,
    performance.id,
    placements
  );

  if (cellPlacements.length < lane.capacity) {
    return true;
  }

  return false;
};
