import React, { Component } from 'react';
import { connect } from 'react-redux';
import getCountryName from '../../utils/countries';
import { FormattedDate } from 'react-intl';
/* eslint-disable react/style-prop-object */
import MainContent, {
  ContentContainer,
  Title,
  SubTitle,
  HeaderActions,
  PageTop,
  ContentType,
  LineUpHelmet
} from '../../components/MainContent/MainContent';

import InfoBlock, {
  InfoBlockList,
  InfoBlockListItem
} from '../../components/InfoBlock/InfoBlock';
import { userActions, userSelectors } from '../../modules/users/users';
import { showModal } from '../../modules/modal/modal';

// Grommet
import { Box, Button } from 'grommet';
import { Edit, User } from 'grommet-icons';

class UserDetail extends Component {
  componentWillMount() {
    this.props.loadUser(this.props.userId);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.userId !== nextProps.userId) {
      this.props.loadUser(nextProps.userId);
    }
  }

  render() {
    const { user } = this.props;
    if (!user || !user.firstName) {
      return null;
    }
    return (
      <>
        <LineUpHelmet
          title={user.displayname}
          description='Manage your order in Line-Up'
        />
        <MainContent>
          <ContentType icon={<User size='small' />} label='Customer' />
          <Box margin={{ top: 'small' }}>
            <PageTop>
              <Title>{user.displayname}</Title>
              <HeaderActions>
                <Button
                  primary
                  href={`/customer/edit/${user.id}`}
                  label='Edit Customer'
                  size='small'
                  icon={<Edit size='small' />}
                />
              </HeaderActions>
            </PageTop>
          </Box>
          <Box margin={{ vertical: 'medium' }}>
            <ContentContainer>
              <SubTitle title={'Customer Details'} />
              <InfoBlock>
                <InfoBlockList>
                  <InfoBlockListItem
                    attribute='First Name'
                    value={user.firstName}
                  />
                  <InfoBlockListItem
                    attribute='Last Name'
                    value={user.lastName}
                  />
                  <InfoBlockListItem
                    attribute='Email Address'
                    value={user.email}
                  />
                  {user.telephone && (
                    <InfoBlockListItem
                      attribute='Phone'
                      value={user.telephone}
                    />
                  )}
                  <InfoBlockListItem
                    attribute='Signed Up'
                    value={
                      <FormattedDate
                        value={user.createdAt}
                        weekday='short'
                        year='numeric'
                        
                        day='numeric'
                        month='short'
                        hour='numeric'
                        minute='numeric'
                      />
                    }
                  />
                </InfoBlockList>
              </InfoBlock>
              {user.address && (
                <>
                  <SubTitle title={'Customer Address'} />
                  <InfoBlock>
                    <InfoBlockList>
                      <InfoBlockListItem
                        attribute='Street'
                        value={user.address.street}
                      />
                      <InfoBlockListItem
                        attribute='City'
                        value={user.address.region}
                      />
                      {user.county && (
                        <InfoBlockListItem
                          attribute='State'
                          value={user.address.county}
                        />
                      )}
                      <InfoBlockListItem
                        attribute={
                          user.address.country === 'US' ? 'ZIP' : 'Post Code'
                        }
                        value={user.address.postcode}
                      />
                      <InfoBlockListItem
                        attribute='Country'
                        value={getCountryName(user.address.country)}
                      />
                    </InfoBlockList>
                  </InfoBlock>
                </>
              )}
            </ContentContainer>
          </Box>
        </MainContent>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const userId = parseInt(ownProps.match.params.userId, 10);
  const user = userSelectors.getUser(state, userId);
  return {
    userId,
    user
  };
};

export default connect(mapStateToProps, {
  ...userActions,
  showModal
})(UserDetail);
