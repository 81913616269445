import React from 'react';
import styled from 'styled-components';

import TimeLineItem from './TimeLineItem/TimeLineItem';

// Grommet
import { Box } from 'grommet';

const TimelineBox = styled(Box)`
  background: ${props => (props.isInverted ? 'white' : '#f7f8fa')};
`;

const TimeLine = ({ children, className, isInverted, items = [] }) => {
  items.sort((a, b) => new Date(b.date) - new Date(a.date));
  return (
    <TimelineBox
      direction="column"
      gap="xsmall"
      pad={{ horizontal: 'medium', vertical: 'small' }}
      margin={{ top: 'medium' }}
      border="between"
      isInverted={isInverted}
    >
      {items.map((item, idx) => (
        <TimeLineItem
          key={idx}
          id={item.id}
          text={item.text}
          titleComponent={item.titleComponent}
          date={item.date}
          icon={item.icon}
          user={item.user}
          onEdit={item.onEdit}
          onDelete={item.onDelete}
        />
      ))}
    </TimelineBox>
  );
};

export default TimeLine;
