import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import AgentForm from '../../components/AgentForm/AgentForm';
import MainContent from '../../components/MainContent/MainContent';
import { agentActions } from '../../modules/agents/agents';
import { removeEmpty } from '../../utils/forms';

// Grommet
import { Box } from 'grommet';

class AgentCreate extends Component {
  componentDidMount() {
    if (this.props.agentId) {
      this.props.fetchAgent({ agentId: this.props.agentId });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.agentId !== prevProps.agentId) {
      this.props.fetchAgent({ agentId: this.props.agentId });
    }
  }
  handleAgentFormSubmit = values => {
    if (this.props.agentId) {
      return this.props.onAgentUpdateSubmit({
        agentId: this.props.agentId,
        ...removeEmpty(values),
        discounts: values.discounts
          ? values.discounts.map(discount => ({
              code: discount.label,
              id: discount.value,
            }))
          : [],
      });
    }
    return this.props.onAgentCreateSubmit({
      ...removeEmpty(values),
      discounts: values.discounts
        ? values.discounts.map(discount => ({
            code: discount.label,
            id: discount.value,
          }))
        : [],
    });
  };

  handleSubmitSuccess = () => {
    this.props.agentId
      ? this.props.history.push(`/agent/${this.props.agentId}`)
      : this.props.history.push(`/agents/1`);
  };

  render() {
    return (
      <MainContent>
        <Box margin={{ top: 'small', bottom: 'medium' }}>
          <AgentForm
            onSubmit={this.handleAgentFormSubmit}
            agentId={this.props.agentId}
            onSubmitSuccess={this.handleSubmitSuccess}
          />
        </Box>
      </MainContent>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const agentId = ownProps.match.params.agentId
    ? parseInt(ownProps.match.params.agentId, 10)
    : null;
  return {
    agentId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        onAgentCreateSubmit: agentActions.sumbmitCreateAgentForm,
        onAgentUpdateSubmit: agentActions.sumbmitUpdateAgentForm,
        // other promise creators can be here...
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        ...agentActions,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgentCreate);
