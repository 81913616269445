import React from 'react';
import { connect } from 'react-redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { Redirect } from 'react-router-dom';

import PlannerForm from 'components/PlannerForm/PlannerForm';
import { eventActions, eventSelectors } from 'modules/events/events';
import {
  eventPlannerActions,
  eventPlannerSelectors,
} from 'modules/eventPlanner/eventPlanner';
import { showModal, hideModal } from 'modules/modal/modal';

const PlannerEdit = ({
  eventId,
  dispatch,
  event,
  fetchEvent,
  fetchEventPlanner,
  isFetchedPlanner,
  hasPlanner,
  eventPlanner,
  submitUpdateEventPlanner,
  showModal,
  hideModal,
  submitDeleteEventPlanner,
}) => {
  // on load fetch the event
  React.useEffect(() => {
    fetchEvent({ eventId: eventId });
    fetchEventPlanner({ eventId: eventId });
  }, [fetchEvent, fetchEventPlanner, eventId]);

  const handleSubmit = values => {
    const payload = {
      ...values,
      eventPlannerId: eventPlanner.id,
    };
    return submitUpdateEventPlanner(payload).then(r => {
      dispatch(push(`/event/${eventId}/planner`));
    });
  };

  const handleCancel = () => {
    dispatch(push(`/event/${eventId}/planner`));
  };

  const handleDelete = () => {
    showModal('DELETE_PLANNER_CONFIRM_MODAL', {
      hideModal,
      onDelete: () => {
        return submitDeleteEventPlanner({
          eventPlannerId: eventPlanner.id,
        }).then(r => {
          hideModal();
          dispatch(push(`/event/${eventId}/planner`));
        });
      },
    });
  };

  // there is no event planner for this event
  if (isFetchedPlanner && !hasPlanner) {
    return <Redirect to={`/event/${eventId}/planner/create`} />;
  }

  return (
    <div>
      <PlannerForm
        onSubmit={handleSubmit}
        initialValues={eventPlanner}
        formTitle='Edit planner'
        guidanceNotes='Use the form below to edit your event planner'
        onCancel={handleCancel}
        onDelete={handleDelete}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const eventId = parseInt(ownProps.match.params.eventId, 10);
  const event = eventSelectors.getEvent(state, eventId);
  return {
    eventId,
    event,
    isFetchedPlanner: eventPlannerSelectors.isFetchedPlanner(state),
    hasPlanner: eventPlannerSelectors.hasPlanner(state),
    eventPlanner: eventPlannerSelectors.getEventPlannerData(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        ...eventActions,
        ...eventPlannerActions,
        showModal,
        hideModal,
      },
      dispatch
    ),
    ...bindPromiseCreators(
      {
        submitUpdateEventPlanner: eventPlannerActions.submitUpdateEventPlanner,
        submitDeleteEventPlanner: eventPlannerActions.submitDeleteEventPlanner,
      },
      dispatch
    ),
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerEdit);
