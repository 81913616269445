import React, { useEffect } from 'react';

// Grommet
import { Box } from 'grommet';

// Components
import MainContent, { Title } from 'components/MainContent/MainContent';
import AccountNav from 'components/Account/AccountNav/AccountNav';
import StripeConnect from 'components/StripeSettings/StripeConnect';

// Utils
import { bindActionCreators } from 'redux';
import { showModal } from '../../modules/modal/modal';
import { accountActions, accountSelectors } from 'modules/account/account';
import { connect } from 'react-redux';

const AccountTicketSales = ({
  currencies,
  stripeSettings,
  businessSettings,
  isBusinessSettingsLoaded,
  getStripeSettings,
  isStripeSettingsLoaded,
  getBusinessSettings,
  getCurrencies,
  createBusinessSettings,
  showModal,
  isCurrenciesLoaded,
}) => {
  useEffect(() => {
    if (currencies.length === 0) {
      getCurrencies();
    }
  }, [getCurrencies, currencies.length]);

  useEffect(() => {
    if (!businessSettings) {
      getBusinessSettings();
    }

    getStripeSettings();
  }, [getBusinessSettings, businessSettings, getStripeSettings]);

  const handleBusinessSettingsFormSubmit = values => {
    createBusinessSettings(values);
  };

  const handleStripeDisconnectClick = () => {
    showModal('STRIPE_DISCONNECT');
  };
  const handleStripeConnectClick = () => {
    stripeSettings &&
      stripeSettings.connectURL &&
      (window.location.href = stripeSettings.connectURL);
  };
  return (
    <MainContent>
      <Title>Your Account</Title>
      <>
        <Box margin={{ vertical: 'medium' }}>
          <AccountNav />
          <StripeConnect
            onStripeConnectClick={handleStripeConnectClick}
            onStripeDisconnectClick={handleStripeDisconnectClick}
            onBusinessSettingsFormSubmit={handleBusinessSettingsFormSubmit}
            businessSettings={businessSettings}
            stripeSettings={stripeSettings}
            currencies={currencies}
            isLoading={
              !(
                isBusinessSettingsLoaded &&
                isStripeSettingsLoaded &&
                isCurrenciesLoaded
              )
            }
          />
        </Box>
      </>
    </MainContent>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    stripeSettings: accountSelectors.getStripeSettings(state),
    businessSettings: accountSelectors.getBusinessSettings(state),
    isBusinessSettingsLoaded: accountSelectors.isBusinessSettingsLoaded(state),
    isStripeSettingsLoaded: accountSelectors.isStripeSettingsLoaded(state),
    currencies: accountSelectors.getCurrencies(state),
    isCurrenciesLoaded: accountSelectors.isCurrenciesLoaded(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        ...accountActions,
        showModal,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountTicketSales);
