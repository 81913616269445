import React from 'react';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import classNames from 'classnames';
import './FormFieldTime.css';
const FormFieldTime = ({
  input,
  hideLabel,
  label,
  placeholder,
  inline,
  className,
  meta: { touched, error, warning }
}) => {
  const rowClass = classNames(className, 'form__row', {
    'form__row--inline': inline
  });
  const labelClass = classNames('form__label', {
    'form__label--inline': inline
  });
  const handleOnChange = value => {
    return moment.isMoment(value)
      ? input.onChange(value.format('HH:mm'))
      : null;
  };
  return (
    <div className={rowClass}>
      <label className={labelClass} hidden={hideLabel}>
        {label}
      </label>
      <TimePicker
        showSecond={false}
        value={input.value ? moment.utc(input.value, 'HH:mm:ss') : null}
        minuteStep={5}
        focusOnOpen={true}
        className={input.className}
        onChange={handleOnChange}
        placeholder={placeholder}
        popupClassName="time-picker-popup"
      />
      {touched &&
        ((error && <div className="form__error">{error}</div>) ||
          (warning && <div className="form__error">{warning}</div>))}
    </div>
  );
};
export default FormFieldTime;
