import React from 'react';
import moment from 'moment';
import {
  aggregatorTemplates as tpl,
  numberFormat,
  derivers
} from '../../../components/PivotTable/Utilities';
import PivotTable from '../../../components/PivotTable/PivotTable';

const AgentSales = ({ data, title, exportFilename }) => {
  const usFmtInt = numberFormat({ digitsAfterDecimal: 0 });
  const usFmtPct = numberFormat({
    digitsAfterDecimal: 1,
    scaler: 100,
    suffix: '%'
  });
  const sortDates = (left, right) => {
    return moment
      .utc(left, 'ddd, DD MMM YYYY HH:mm')
      .diff(moment.utc(right, 'ddd, DD MMM YYYY HH:mm'));
  };
  const fmt = numberFormat({ prefix: '£' });
  return (
    <PivotTable
      title={title}
      exportFilename={exportFilename}
      data={data}
      aggregators={{
        'Qty Sold': () => tpl.sum(usFmtInt)(['Sold']),
        Comps: () => tpl.sum(usFmtInt)(['Comp']),
        Gross: () => tpl.sum(fmt)(['Face Value Gross']),
        'Gross Commission': () => tpl.sum(fmt)(['Inside Fee Gross']),
        Tax: () => tpl.sum(fmt)(['Inside Fee Tax']),
        'Net Commission': () => tpl.sum(fmt)(['Inside Fee Net'])
      }}
      derivedAttributes={{
        Sold: record => (record['Gross'] !== 0 ? record['Quantity'] : 0),
        Comp: record => (record['Gross'] === 0 ? record['Quantity'] : 0),
        'Commission Rate': record =>
          record['Inside Fee Fixed'] > 0
            ? record['Inside Fee Percentage'] > 0
              ? `${fmt(record['Inside Fee Fixed'])} + ${usFmtPct(
                  record['Inside Fee Percentage']
                )}`
              : fmt(record['Inside Fee Fixed'])
            : usFmtPct(record['Inside Fee Percentage']),
        'Event Date': derivers.dateFormat(
          'Start Date Time',
          '%w, %d %n %y %H:%M',
          true
        )
      }}
      valueFilter={{
        Seller: { Direct: false }
      }}
      rows={['Event Date', 'Seller', 'Ticket Description', 'Commission Rate']}
      sorters={{
        'Event Date': sortDates
      }}
    />
  );
};

export default AgentSales;
