import React from 'react';
import { TableCell, TableRow } from '../../Table/Table';
import { Price } from '../../Utils/Utils';

import { Ticket } from 'grommet-icons';

const TicketRowItem = ({
  name,
  description,
  ticket_status,
  quantity,
  face_value,
  currency,
  booking_fee,
  total,
  seating_category_key,
  max_per_order,
  min_per_order,
}) => (
  <TableRow key={name} striped>
    <TableCell width={5} className='lutable__cell--icon'>
      <Ticket size='small' />
    </TableCell>
    <TableCell width={15} texttop>
      {name}
    </TableCell>
    <TableCell width={20} texttop>
      {description}
    </TableCell>
    <TableCell width={10} texttop center>
      {ticket_status}
    </TableCell>
    <TableCell width={10} texttop center>
      {quantity}
    </TableCell>
    <TableCell width={10} texttop center>
      <Price amount={face_value} currency={currency} showZero />
    </TableCell>
    <TableCell width={8} texttop center>
      <Price amount={booking_fee} currency={currency} showZero />
    </TableCell>
    <TableCell width={10} texttop center>
      <Price amount={total} currency={currency} showZero />
    </TableCell>
    <TableCell width={10} texttop center>
      {max_per_order}
    </TableCell>
    <TableCell width={10} texttop center>
      {min_per_order}
    </TableCell>
    <TableCell width={12} texttop center>
      {seating_category_key}
    </TableCell>
  </TableRow>
);
export default TicketRowItem;
