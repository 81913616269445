import React from 'react';
import {
  aggregatorTemplates as tpl,
  numberFormat,
  sortAs
} from '../../../components/PivotTable/Utilities';
import PivotTable from '../../../components/PivotTable/PivotTable';

const PaymentsBySource = ({ data, title, exportFilename }) => {
  const fmt = numberFormat({ prefix: '£' });
  return (
    <PivotTable
      title={title}
      exportFilename={exportFilename}
      data={data}
      aggregators={{
        Gross: () => tpl.sum(fmt)(['Gross']),
        'Basket Discount': () => tpl.sum(fmt)(['Discount']),
        'Line-Up Fee Gross': () => tpl.sum(fmt)(['Lineup Fee Gross']),
        'Payment Fee': () => tpl.sum(fmt)(['Payment Fee']),
        'Net Received': () => tpl.sum(fmt)(['Net Income'])
      }}
      derivedAttributes={{
        Sold: record => (record['Gross'] !== 0 ? record['Quantity'] : 0),
        Comp: record => (record['Gross'] === 0 ? record['Quantity'] : 0),
        Channel: record =>
          record['Seller'] === 'Direct' ? 'Direct Sales' : 'Agency Sales'
      }}
      rows={['Transaction Type', 'Source']}
      sorters={{
        'Transaction Type': sortAs(['Sale', 'Refund']),
        Source: sortAs(['Stripe', 'Agent', 'Invoice', 'Cash', 'Voucher'])
      }}
    />
  );
};

export default PaymentsBySource;
