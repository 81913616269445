import React from 'react';
import GroupedPerformanceSelect from './GroupedPerformanceSelect';
import { Link } from 'react-router-dom';

// Grommet
import { Box, Button, ResponsiveContext } from 'grommet';
import { SettingsOption } from 'grommet-icons';

const PlannerMenu = ({
  event,
  currentSelectedDay,
  performances,
  onDayChange
}) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <div>
          <Box
            margin={{ vertical: 'medium' }}
            pad='medium'
            background='infobox'
            direction={size !== 'small' ? 'row' : 'column'}
            justify='between'
            align={size !== 'small' ? 'start' : 'center'}
            gap={size !== 'small' ? 'none' : 'medium'}
            fill={size !== 'small' ? false : true}
          >
            <GroupedPerformanceSelect
              value={currentSelectedDay}
              groupedPerformances={performances}
              onChange={onDayChange}
            />

            {/* <Button primary onClick={() => window.print()}>
              Print
            </Button> */}
            <Box fill={size !== 'small' ? false : true}>
              <Link to={`/event/${event.id}/planner/edit`}>
                <Button
                  secondary
                  size='small'
                  label='Planner Settings'
                  icon={<SettingsOption size='small' />}
                  fill={size !== 'small' ? false : true}
                />
              </Link>
            </Box>
          </Box>
        </div>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default PlannerMenu;
