import React from 'react';
import styled from 'styled-components';

// Grommet
import { ResponsiveContext } from 'grommet';

const AppContentWrapperWeb = styled.div`
  width: 100%;
  overflow: auto;
  max-height: 100vh;
  min-height: 100vh;
  @media print {
    max-height: none;
    min-height: none;
  }
`;

const AppContentWrapperNarrow = styled.div`
  width: 100%;
  margin-top: 58px;
  @media print {
    margin-top: 0;
  }
`;

const AppContent = ({ children }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          {size !== 'small' ? (
            <AppContentWrapperWeb>{children}</AppContentWrapperWeb>
          ) : (
            <AppContentWrapperNarrow>{children}</AppContentWrapperNarrow>
          )}
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default AppContent;
