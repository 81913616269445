import React from 'react';
import { TableCell, TableRow } from '../Table/Table';
import { Image, Button, Box } from 'grommet';
import styled from 'styled-components';

const DeleteButton = styled(Button)`
  background-color: #ff2e4a;
  &:hover {
    background-color: #d41c34;
  }
`;

const SeatingChartsTableRow = ({
  seatingChart,
  onSeatingChartEditClick,
  onSeatingChartManageClick,
  onSeatingChartDeleteClick
}) => {
  return (
    <TableRow striped={true}>
      <TableCell width={10}>{seatingChart.id}</TableCell>
      <TableCell width={20} image>
        <Image
          style={{ height: '100px', border: '1px solid #ccc' }}
          src={seatingChart.imageUrl}
        />
      </TableCell>
      <TableCell width={30}>{seatingChart.name}</TableCell>
      <TableCell width={40}>
        <Box direction='row' gap='small'>
          <Button
            secondary
            label='Edit'
            size='small'
            onClick={() => {
              onSeatingChartEditClick(seatingChart);
            }}
          />
          <Button
            secondary
            label='Manage Rulesets'
            size='small'
            onClick={() => {
              onSeatingChartManageClick(seatingChart);
            }}
          />
          <DeleteButton
            primary
            label='Delete'
            size='small'
            onClick={() => {
              onSeatingChartDeleteClick(seatingChart);
            }}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default SeatingChartsTableRow;
