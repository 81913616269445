import React from "react";
import { connect } from "react-redux";
import { bindPromiseCreators } from "redux-saga-routines";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";

// components
import MainContent, {
  Title,
  LineUpHelmet
} from "../../components/MainContent/MainContent";
import MailchimpAccountSettingsForm from "../../components/Mailchimp/AccountSettingsForm/AccountSettingsForm";

// other
import {
  mailchimpActions,
  mailchimpSelectors
} from "../../modules/mailchimp/mailchimp";
import { eventSelectors, eventActions } from "../../modules/events/events";
import "./MailchimpManage.css";
// import Button from '../../components/Button/Button';
import connectMailchimp from "./connect_mailchimp.png";

// Grommet
import {
  Box,
  Button,
  Image,
  Heading,
  Paragraph,
  ResponsiveContext
} from "grommet";

const transformValuesForAPI = values => {
  let eventIds = [];

  if (values.syncType !== "SPECIFIC_EVENTS") {
    return values;
  } else {
    for (let formKey in values) {
      const m = formKey.match(/event_([0-9]+)/);
      if (m) {
        eventIds.push(parseInt(m[1]));
      }
    }
    return {
      ...values,
      eventsToSync: eventIds
    };
  }
};

const transformAPIResponseToInitialValues = integrationData => {
  if (!integrationData) {
    return null;
  }
  if (integrationData.syncType !== "SPECIFIC_EVENTS") {
    return integrationData;
  }
  let specificEventFields = {};
  for (let eventId of integrationData.eventsToSync) {
    specificEventFields[`event_${eventId}`] = true;
  }
  return {
    ...specificEventFields,
    ...integrationData
  };
};

class MailchimpManage extends React.Component {
  componentDidMount() {
    this.props.fetchMailchimpIntegration();
  }

  componentDidUpdate(prevProps) {
    if (this.props.connected && !prevProps.connected) {
      this.props.fetchMailchimpLists();
      this.props.fetchAllEvents();
    }
  }

  handleSave = values => {
    const data = transformValuesForAPI(values);
    return this.props.submitUpdateMailchimpIntegrationForm(data).then(() => {
      this.props.dispatch(push("/tools"));
    });
  };

  handleDisconnect = () => {
    this.props.disconnectMailchimpIntegration();
  };

  render() {
    if (this.props.isFetching) {
      return null;
    }

    return (
      <>
        <LineUpHelmet
          title="Mailchimp Sync"
          description="Manage your Mailchimp Sync"
        />
        <ResponsiveContext.Consumer>
          {size => (
            <MainContent>
              <Title>Mailchimp Sync</Title>

              {!this.props.connected ? (
                <>
                  <Box
                    margin={{ vertical: "medium" }}
                    background="#fff194"
                    justify={size !== "small" ? "between" : "center"}
                    round="xsmall"
                    direction={size !== "small" ? "row" : "column"}
                    wrap={false}
                    pad="large"
                    align={size !== "small" ? "start" : "center"}
                    gap="large"
                  >
                    <Box
                      direction="column"
                      width={
                        size !== "small" ? { max: "500px" } : { max: "100%" }
                      }
                      margin={
                        size !== "small" ? { top: "large" } : { top: "none" }
                      }
                    >
                      <Heading
                        level={size !== "small" ? "2" : "3"}
                        size={size !== "small" ? "small" : "xsmall"}
                        margin={size !== "small" ? "none" : { top: "none" }}
                      >
                        Automatically add customers to your Mailchimp Audience
                      </Heading>
                      <Paragraph size={size !== "small" ? "medium" : "small"}>
                        Connect your Mailchimp account to add ticket buyers to a
                        Mailchimp Audience of your choice automatically. You'll
                        also be able to do one-off exports of event attendees to
                        Mailchimp.
                      </Paragraph>
                      <Box width="medium" margin={{ top: "medium" }}>
                        <Button
                          primary
                          href={this.props.connectUrl}
                          label="Connect your Mailchimp account now"
                        />
                      </Box>
                    </Box>
                    <Box
                      width={
                        size !== "small"
                          ? { min: "450px", max: "450px" }
                          : { min: "80%", max: "80%" }
                      }
                    >
                      <Image
                        src={connectMailchimp}
                        alt="Connect your Mailchimp account"
                        title="Connect your Mailchimp account"
                        fit="contain"
                        fill
                      />
                    </Box>
                  </Box>
                </>
              ) : (
                <Box margin={{ vertical: "medium" }}>
                  <MailchimpAccountSettingsForm
                    onSubmit={this.handleSave}
                    events={this.props.events}
                    initialValues={this.props.integrationData}
                    lists={this.props.lists || []}
                    handleDisconnect={this.handleDisconnect}
                  />
                </Box>
              )}
            </MainContent>
          )}
        </ResponsiveContext.Consumer>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    connected: mailchimpSelectors.isMailchimpConnected(state),
    isFetching: mailchimpSelectors.isFetchingIntegration(state),
    connectUrl: mailchimpSelectors.getConnectUrl(state),
    events: eventSelectors.getEvents(state),
    integrationData: transformAPIResponseToInitialValues(
      mailchimpSelectors.getMailchimpIntegrationData(state)
    ),
    lists: mailchimpSelectors.getMailchimpLists(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        submitUpdateMailchimpIntegrationForm:
          mailchimpActions.submitUpdateMailchimpIntegration
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        ...eventActions,
        ...mailchimpActions
      },
      dispatch
    ),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MailchimpManage);
