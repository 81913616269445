import reducer from './reducers';
import * as waitingListSelectors from './selectors';
import * as waitingListTypes from './types';
import * as waitingListActions from './actions';
import * as waitingListOperations from './operations';

export {
  waitingListSelectors,
  waitingListTypes,
  waitingListActions,
  waitingListOperations
};

export default reducer;
