import React from 'react';
import { TableCell, TableRow } from '../../Table/Table';
import { Price } from '../../Utils/Utils';
import { ticketStatus } from '../../../utils/types';

const TicketRowItem = ({ ticket, onClick }) => {
  const handleClick = evt => {
    evt.preventDefault();
    onClick && onClick(ticket.id);
  };
  const status = ticketStatus[ticket.status];
  return (
    <TableRow striped={true} onClick={handleClick}>
      <TableCell>
        <b>{ticket.description}</b>
      </TableCell>
      <TableCell>{status}</TableCell>
      <TableCell>
        <Price amount={ticket.faceValueTotal} currency={ticket.currency} />
      </TableCell>
      <TableCell>
        <Price amount={ticket.outsideFeeTotal} currency={ticket.currency} />
      </TableCell>
      <TableCell>
        <Price amount={ticket.total} currency={ticket.currency} />
      </TableCell>
      <TableCell center>{ticket.quantitySold}</TableCell>
      <TableCell center>{ticket.quantityComped}</TableCell>
      <TableCell center>{ticket.quantityAllocated}</TableCell>
      <TableCell center>{ticket.quantityReserved}</TableCell>
      <TableCell center>{ticket.inventoryLeft}</TableCell>
    </TableRow>
  );
};

export default TicketRowItem;
