import React from "react";
import { useContext } from "react";
import styled from "styled-components";

// Components
import UserPhoto from "components/UserPhoto/UserPhoto";

// Grommet
import { Anchor, Box, Header, Menu, Text } from "grommet";
import { SettingsOption, Logout, CircleQuestion } from "grommet-icons";

const TopBarHeader = styled(Header)`
  @media print {
    display: none;
  }
`;

const TopBar = ({ user, onLogout }) => {
  const handleLogout = (evt) => {
    evt.preventDefault();
    onLogout();
  };
  return (
    <TopBarHeader
      background="topbar"
      justify="end"
      align="center"
      pad={{ right: "small" }}
    >
      <Anchor
        href="http://support.lineupnow.com"
        target="_blank"
        rel="noopener noreferrer"
        title="Help"
      >
        <CircleQuestion />
      </Anchor>

      <Box align="center">
        <Menu
          plain
          dropProps={{
            align: { top: "bottom", left: "left" },
            elevation: "xlarge",
            round: { corner: "bottom", size: "xsmall" },
          }}
          label={[
            <Box
              alignContent="center"
              direction="row"
              align="center"
              gap="small"
              pad={{ vertical: "xsmall" }}
              dropBackground="light-2"
              key={0}
            >
              <UserPhoto user={user} />
              <Text size="small" weight="normal">
                {user.firstName}
              </Text>
            </Box>,
          ]}
          items={[
            {
              label: (
                <Box alignSelf="center">
                  <Text size="small" weight="normal">
                    Settings
                  </Text>
                </Box>
              ),
              onClick: () => {},
              icon: (
                <Box pad="xsmall" alignSelf="center">
                  <SettingsOption size="small" />
                </Box>
              ),
              href: "/account",
              gap: "small",
            },
            {
              label: (
                <Box alignSelf="center">
                  <Text size="small" weight="normal">
                    Logout
                  </Text>
                </Box>
              ),
              icon: (
                <Box pad="xsmall" alignSelf="center">
                  <Logout size="small" />
                </Box>
              ),
              gap: "small",
              onClick: handleLogout,
            },
          ]}
        />
      </Box>
    </TopBarHeader>
  );
};

export default TopBar;
