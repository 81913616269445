import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import EmptyState from 'components/EmptyState/EmptyState';
import emptyPlanner from './empty_planner.png';

const PlannerNoFuturePerformancesState = ({ event, dispatch }) => (
  <EmptyState
    background
    emptyStateHeadline='Add Future Performances to use the Planner'
    emptyStateDescription='Oops, you have no future performances.'
    onButtonClick={() => dispatch(push(`/event/${event.id}/performances`))}
    showButton={true}
    buttonText={'Manage Performances'}
    emptyStateImageSrc={emptyPlanner}
    emptyStateImageAlt='No future performances'
    emptyStateImageTitle='No future performances'
  />
);

const mapStateToProps = (state, ownProps) => {};

export default connect(mapStateToProps)(PlannerNoFuturePerformancesState);
