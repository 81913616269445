import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import MainContent, {
  LineUpHelmet,
} from '../../components/MainContent/MainContent';
import { EventNav } from '../../components/Event/Event';
import Can from '../../components/Can';
import Performances from './performance/Performances';
import BulkUpload from './performance/BulkUpload/BulkUpload';
import BulkUploadReview from './performance/BulkUpload/BulkUploadReview';
import BulkUploadComplete from './performance/BulkUpload/BulkUploadComplete';
import Performance from './performance/Performance';
import EventCreate from './EventCreate';
import EventEmbedSettings from './eventEmbedSettings/EventEmbedSettings';
import Tools from './tools/Tools';
import EventIssue from './issue/EventIssue';
import EventEmailScheduleCreate from './eventEmailSchedule/EventEmailScheduleCreate';
import EventEmailSchedule from './eventEmailSchedule/EventEmailSchedule';
import EventEmailScheduleEdit from './eventEmailSchedule/EventEmailScheduleEdit';
import { EventAuthContext } from './event-auth-context';
import { eventActions, eventSelectors } from '../../modules/events/events';
import Planner from './planner/Planner';
import PlannerCreate from './planner/PlannerCreate';
import PlannerEdit from './planner/PlannerEdit';
import UserManagement from './userManagement/UserManagement';
import Sales from './sales/EventSales';

class EventDetail extends Component {
  componentWillMount() {
    this.props.fetchEvent({ eventId: this.props.eventId });
  }
  componentDidUpdate(prevProps) {
    if (this.props.eventId !== prevProps.eventId) {
      this.props.loadEvent({ eventId: this.props.eventId });
    }
  }

  render() {
    const { event, eventId, match } = this.props;
    if (!event.id || !event.role) {
      return null;
    }
    const role = event.role;
    return (
      <EventAuthContext.Provider value={role}>
        <LineUpHelmet
          title={event.title}
          description='Manage your event in Line-Up'
        />
        <MainContent>
          <EventNav
            title={event.title}
            eventId={eventId}
            eventSlug={event.slug}
            role={role}
          />
          <Switch>
            <Route
              exact
              path={`${match.path}/edit`}
              render={props => (
                <Can
                  role={role}
                  perform='event:edit'
                  yes={() => <EventCreate {...props} event={event} />}
                  no={() => <Redirect to={`${match.url}/performances`} />}
                />
              )}
            />
            <Route
              exact
              path={[
                `${match.path}/performances`,
                `${match.path}/performances/:page(\\d+)`,
              ]}
              render={props => (
                <Can
                  role={role}
                  perform='event:view'
                  yes={() => <Performances {...props} />}
                  no={() => <Redirect to={`${match.url}/sales`} />}
                />
              )}
            />
            <Route
              exact
              path={[`${match.path}/bulkupload`]}
              component={BulkUpload}
            />
            <Route
              exact
              path={[`${match.path}/bulkupload/review`]}
              component={BulkUploadReview}
            />
            <Route
              exact
              path={[`${match.path}/bulkupload/complete`]}
              component={BulkUploadComplete}
            />
            <Route
              exact
              path={`${match.path}/users`}
              component={UserManagement}
            />
            <Route
              exact
              path={`${match.path}/sales`}
              render={props => (
                <Can
                  role={role}
                  perform='event:view-financials'
                  yes={() => <Sales {...props} />}
                  no={() => <Redirect to={`${match.url}/embed-settings`} />}
                />
              )}
            />
            <Route
              exact
              path={`${match.path}/embed-settings`}
              component={EventEmbedSettings}
            />
            <Route
              path={`${match.path}/performance/:performanceId(\\d+)`}
              component={Performance}
            />
            <Route path={`${match.path}/tools`} component={Tools} />
            <Route
              path={`${match.path}/issue`}
              render={props => (
                <Can
                  role={role}
                  perform='event:view'
                  yes={() => <EventIssue {...props} />}
                  no={() => <Redirect to={`${match.url}/sales`} />}
                />
              )}
            />
            <Route
              exact
              path={`${match.path}/email-schedule/:id(\\d+)`}
              component={EventEmailScheduleEdit}
            />
            <Route
              exact
              path={`${match.path}/email-schedule`}
              component={EventEmailSchedule}
            />
            <Route
              exact
              path={`${match.path}/email-schedule/create`}
              component={EventEmailScheduleCreate}
            />
            <Route exact path={`${match.path}/planner`} component={Planner} />
            <Route
              exact
              path={`${match.path}/planner/create`}
              component={PlannerCreate}
            />
            <Route
              exact
              path={`${match.path}/planner/edit`}
              component={PlannerEdit}
            />
          </Switch>
        </MainContent>
      </EventAuthContext.Provider>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const eventId = parseInt(ownProps.match.params.eventId, 10);
  const event = eventSelectors.getEvent(state, eventId);
  return {
    event,
    eventId,
  };
};

export default connect(
  mapStateToProps,
  {
    ...eventActions,
  }
)(EventDetail);
