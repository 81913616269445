import reducer from './reducers';
import * as discountSelectors from './selectors';
import * as discountTypes from './types';
import * as discountActions from './actions';
import * as discountOperations from './operations';

export {
  discountSelectors,
  discountTypes,
  discountActions,
  discountOperations
};

export default reducer;
