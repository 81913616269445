import React from 'react';
import { connect } from 'react-redux';
import { Field, FormSection, reduxForm } from 'redux-form';
import { agentSelectors } from '../../modules/agents/agents';
import Form, { FormField } from '../Form/Form';
import { email, phone, required } from '../../utils/validators';
import FormFieldCountrySelect from '../Form/FormFieldCountrySelect/FormFieldCountrySelect';
import DiscountSelect from './DiscountSelect';
let AgentForm = (props) => {
  const { reset, agentId, handleSubmit, error, country } = props;
  return (
    <Form
      {...props}
      submitText={agentId ? 'Update' : 'Submit'}
      cancelText='Reset'
      onCancel={reset}
      handleSubmit={handleSubmit}
      error={error}
      formTitle={agentId ? 'Edit Agent Profile' : 'New Agent Profile'}
      guidanceNotes={
        agentId
          ? 'Use the form below to edit the ticketing agent profile code'
          : 'Use the form below to create a new agent profile'
      }
    >
      <Field
        name='company'
        component={FormField}
        label='Agent Name'
        type='text'
        validate={[required]}
      />
      <Field
        name='description'
        component={FormField}
        label='Agent Description'
        type='text'
        validate={[required]}
      />
      <Field
        name='vatNumber'
        component={FormField}
        label='Agent VAT Number'
        type='text'
      />
      <Field
        name='isActive'
        labelAfter='Is Active?'
        component={FormField}
        type='checkbox'
      />
      <Field
        name='discounts'
        label='Discounts'
        component={DiscountSelect}
        hintBefore='Add discounts to agent profile'
        placeholder='Discounts'
      />
      <div className='form__section-header'>
        <h5 className='form__section-header-title'>Commission Rate</h5>
      </div>
      <FormSection name='commissionRate'>
        <Field
          name='insideFeePercentage'
          component={FormField}
          label='Fee Percentage'
          type='number'
          step='any'
          hintBefore='Please enter the percentage as a decimal, e.g. for 4%, enter 0.04'
        />
        <Field
          name='insideFeeFixedValue'
          component={FormField}
          label='Fixed Fee'
          type='number'
          step='any'
        />
        <Field
          name='insideFeeTaxRate'
          component={FormField}
          label='Tax Rate'
          type='number'
          step='any'
          hintBefore='Please enter tax rate percentage as a decimal, e.g. for 20%, enter 0.2'
        />
        <Field
          name='includeOutsideFee'
          id='includeOutsideFee'
          component={FormField}
          labelAfter='Include Outside Booking Fees?'
          type='checkbox'
        />
      </FormSection>
      <div className='form__section-header'>
        <h5 className='form__section-header-title'>Primary Contact</h5>
      </div>
      <Field
        name='firstName'
        component={FormField}
        label='First Name'
        type='text'
        validate={[required]}
      />
      <Field
        name='lastName'
        component={FormField}
        label='Last Name'
        type='text'
        validate={[required]}
      />
      <Field
        name='email'
        component={FormField}
        label='Contact Email'
        type='email'
        validate={[required, email]}
      />
      <Field
        name='telephone'
        component={FormField}
        label='Contact Phone Number'
        type='tel'
        validate={[phone]}
      />
      <div className='form__section-header'>
        <h5 className='form__section-header-title'>Billing Address</h5>
      </div>
      <FormSection name='address'>
        <Field
          name='street'
          component={FormField}
          type='text'
          label='Street'
          placeholder='221b Baker Street'
        />
        <Field
          name='region'
          component={FormField}
          type='text'
          label='City'
          placeholder='London'
        />
        {country === 'US' && (
          <Field
            name='county'
            component={FormField}
            type='text'
            label='State'
            placeholder='NY'
          />
        )}
        <Field
          name='postcode'
          component={FormField}
          type='text'
          label={country === 'US' ? 'ZIP' : 'Post Code'}
          placeholder={country === 'US' ? '10036' : 'NW1 6XE'}
        />
        <Field
          name='country'
          component={FormFieldCountrySelect}
          label='Country'
        />
      </FormSection>
    </Form>
  );
};

AgentForm = reduxForm({
  form: 'agent',
})(AgentForm);

const mapStateToProps = (state, ownProps) => {
  const agentId = ownProps.agentId;
  const agent = agentSelectors.getAgent(state, agentId);
  const discounts = agent && agent.apiClient ? agent.apiClient.discounts : [];
  let initialValues;
  if (agentId) {
    if (agent.firstName) {
      initialValues = {
        ...agent,
        discounts: discounts.map((discount) => ({
          label: discount.code,
          value: discount.id,
        })),
      };
    }
  } else {
    initialValues = {
      commissionRate: {
        includeOutsideFee: true,
      },
    };
  }
  return {
    initialValues,
  };
};

export default connect(mapStateToProps)(AgentForm);
