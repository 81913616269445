import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import Form, { FormSectionHeader } from '../../Form/Form';
import FormField from '../../Form/FormField/FormField';
import MailchimpListSelect from '../ListSelect/ListSelect';
import Button from '../../Button/Button';

const validate = values => {
  let errors = {};
  if (values.mainListId === 'NOT_SELECTED' || !values.mainListId) {
    errors.mainListId = 'Please select a list to sync to';
  }
  return errors;
};

const EventsToSyncList = ({ events }) => {
  return (
    <div className="mailchimp-manage__events-list">
      {events.map(event => (
        <Field
          key={event.id}
          name={`event_${event.id}`}
          type="checkbox"
          component={FormField}
          labelAfter={event.title}
          className="mailchimp-manage__events-list-item"
        />
      ))}
    </div>
  );
};

let MailchimpAccountSettingsForm = props => {
  const {
    events,
    handleSubmit,
    reset,
    syncType,
    lists,
    submitting,
    handleDisconnect
  } = props;
  return (
    <Form
      handleSubmit={handleSubmit}
      formTitle="Mailchimp Settings"
      submitText="Update settings"
      onCancel={reset}
      submitting={submitting}
      guidanceNotes="Manage your Mailchimp settings below."
    >
      <Field
        name="syncEnabled"
        labelAfter="Enable Mailchimp Sync"
        component={FormField}
        type="checkbox"
        hintAfter="Ticking the Mailchimp Sync box will mean that new Attendees in Line-Up will automatically be added to your selected Mailchimp Audience."
      />
      <Field
        name="syncHistoricData"
        labelAfter="Sync Existing Attendees"
        component={FormField}
        type="checkbox"
        hintAfter="Ticking this box will mean that Attendees who have purchased prior to today will be added to your selected Mailchimp Audience. If you don't tick the box, only new attendees will be added."
      />
      <FormSectionHeader title="Which event attendees do you want to sync?" />
      <Field
        name="syncType"
        labelAfter="All events"
        component={FormField}
        type="radio"
        value="ALL_EVENTS"
      />
      <Field
        name="syncType"
        labelAfter="Just specific events"
        component={FormField}
        type="radio"
        value="SPECIFIC_EVENTS"
      />
      {syncType === 'SPECIFIC_EVENTS' ? (
        <EventsToSyncList events={events} />
      ) : null}
      <FormSectionHeader title="Which Audience (List) you want to sync attendee information to?" />
      <MailchimpListSelect lists={lists} />

      <FormSectionHeader title="Disconnect your Mailchimp connection" />
      <Button
        type="button"
        onClick={handleDisconnect}
        danger={true}
        className="mailchimp-manage-disconnect"
      >
        Disconnect
      </Button>
    </Form>
  );
};

const stateFormName = 'mailchimpAccountSettings';

MailchimpAccountSettingsForm = reduxForm({
  form: stateFormName,
  validate
})(MailchimpAccountSettingsForm);

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector(stateFormName);
  return {
    syncType: selector(state, 'syncType')
  };
};

export default connect(mapStateToProps)(MailchimpAccountSettingsForm);
