import React from 'react';
import classNames from 'classnames';
import CodeEditor from './CodeEditor';
// import "./FormFieldCodeEditor.css";

const FormFieldCodeEditor = ({
  input,
  hideLabel,
  label,
  placeholder,
  inline,
  className,
  meta: { touched, error, warning, pristine },
  contentType
}) => {
  const rowClass = classNames(className, 'form__row');
  const labelClass = classNames('form__label');
  const inputClass = classNames('form__field-input', {
    'form__field-input--error': (touched && error) || (touched && warning)
  });
  return (
    <div className={rowClass}>
      <label className={labelClass} hidden={hideLabel}>
        {label}
      </label>
      <CodeEditor
        onChange={input.onChange}
        value={input.value}
        pristine={pristine}
        className={inputClass}
        contentType={contentType}
      />
      {touched &&
        ((error && <div className="form__error">{error}</div>) ||
          (warning && <div className="form__error">{warning}</div>))}
    </div>
  );
};
export default FormFieldCodeEditor;
