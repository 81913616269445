import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table, {
  TableCell,
  TableBody,
  TableHeader
} from '../../../../components/Table/Table';
import WaitingListRowItem from '../../../../components/WaitingList/WaitingListRowItem';
import {
  waitingListActions,
  waitingListSelectors
} from '../../../../modules/waitingList/waitingList';
import Pagination from '../../../../components/Pagination/Pagination';
import { SubTitle } from '../../../../components/MainContent/MainContent';

// Grommet
import { Box } from 'grommet';

class WaitingList extends Component {
  componentDidMount() {
    this.props.loadWaitingListPage(
      this.props.performanceId,
      this.props.currentPage
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.currentPage !== prevProps.currentPage ||
      this.props.performanceId !== prevProps.performanceId
    ) {
      this.props.loadWaitingListPage(
        this.props.performanceId,
        this.props.currentPage
      );
    }
  }

  handleSelectPage = page => {
    this.props.history.push(
      `/event/${this.props.eventId}/performance/${this.props.performanceId}/waiting-list/${page}`
    );
  };
  handleReceiptClick = receipt => {};

  render() {
    const { waitingUsers, currentPage, totalPages, loading } = this.props;
    if (loading) {
      return null;
    }
    return (
      <Box margin={{ vertical: 'medium' }}>
        <SubTitle title="Waiting List" />
        <Box margin={{ vertical: 'medium' }}>
          <Table>
            <TableHeader>
              <TableCell width={40}>Email</TableCell>
              <TableCell width={25}>Date Joined</TableCell>
              <TableCell width={25}>Last Emailed</TableCell>
              <TableCell width={10} />
            </TableHeader>
            <TableBody
              renderItem={waitingUser => (
                <WaitingListRowItem
                  email={waitingUser.email}
                  joinedAt={waitingUser.joinedAt}
                  lastEmailedAt={waitingUser.lastEmailedAt}
                  key={waitingUser.id}
                />
              )}
              items={waitingUsers}
            />
          </Table>
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onSelectPage={this.handleSelectPage}
          />
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const page = parseInt(match.params.page, 10) || 1;
  const performanceId = parseInt(match.params.performanceId, 10);
  const eventId = parseInt(match.params.eventId, 10);
  return {
    waitingUsers: waitingListSelectors.getWaitingListForPage(state, page),
    currentPage: page,
    eventId: eventId,
    performanceId: performanceId,
    totalPages: waitingListSelectors.getWaitingListTotalPages(state),
    loading: waitingListSelectors.getWaitingListLoadingStatus(state)
  };
};

export default connect(mapStateToProps, {
  ...waitingListActions
})(WaitingList);
