import React from "react";
import moment from "moment";
import { TableCell, TableRow } from "../Table/Table";
import Button from "../../components/Button/Button";
import { FormattedDate, FormattedNumber } from "react-intl";
/* eslint-disable react/style-prop-object */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TicketRowItem = ({ ticket, onBtnClick, btnType, btnText }) => {
  const handleClick = (evt) => {
    evt.preventDefault();
    if (onBtnClick) {
      onBtnClick(parseInt(ticket.objectID, 10));
    }
  };

  return (
    <TableRow striped={true}>
      <TableCell>{ticket.event.title}</TableCell>
      <TableCell>{ticket.description}</TableCell>
      <TableCell>
        <FormattedDate
          value={new Date(ticket.performance.start * 1000)}
          weekday="short"
          year="numeric"
          day="numeric"
          month="short"
          hour="numeric"
          minute="numeric"
          timeZone="UTC"
        />
      </TableCell>
      <TableCell>
        {ticket.faceValueTotal > 0 ? (
          <FormattedNumber
            value={ticket.faceValueTotal}
            style="currency"
            currency="GBP"
          />
        ) : (
          "Free"
        )}
      </TableCell>
      <TableCell>
        {ticket.outsideFeeTotal > 0 && (
          <FormattedNumber
            value={ticket.outsideFeeTotal}
            style="currency"
            currency="GBP"
          />
        )}
      </TableCell>
      <TableCell>
        <Button
          danger={btnType === "danger"}
          primary={btnType === "primary"}
          onClick={handleClick}
        >
          {btnType === "danger" ? (
            <FontAwesomeIcon icon="minus" />
          ) : (
            <FontAwesomeIcon icon="plus" />
          )}
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default TicketRowItem;
