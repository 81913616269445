import React, { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import classNames from 'classnames';
import { searchVenues } from '../../../services/api';

class VenueSelect extends Component {
  loadOptions = (inputValue, callback) => {
    const venues = searchVenues({
      query: inputValue
    }).then(({ response, error }) =>
      response.map(venue => ({
        label: venue.name,
        value: venue.id
      }))
    );
    return venues;
  };

  handleChange = (value, { action }) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      input,
      width,
      label,
      className,
      id,
      placeholder,
      hintBefore,
      labelAfter,
      defaultOptions,
      hintAfter,
      meta: { touched, error, warning }
    } = this.props;

    const rowClass = classNames('form__row', className);

    const style = () => {
      if (width) {
        return {
          width: `${width}%`
        };
      }
      return {};
    };

    const styles = {
      container: styles => ({
        ...styles,
        fontFamily: 'Lato',
        fontSize: '0.85rem',
        marginTop: '0',
        marginBottom: '0'
      }),
      control: styles => ({
        ...styles,
        backgroundColor: 'white',
        fontFamily: 'Lato',
        borderWidth: '0.09rem',
        borderColor: '#ddd',
        borderRadius: '0.2rem'
      }),
      valueContainer: styles => ({
        ...styles,
        padding: '0 0.5rem',
        fontFamily: 'Lato'
      }),
      input: styles => ({
        ...styles,
        fontFamily: 'Lato',
        '& input': {
          font: 'inherit'
        }
      })
    };

    return (
      <div className={rowClass} style={style()}>
        <label className='form__label' htmlFor={id}>
          {label}
        </label>
        {hintBefore && (
          <div className='form__hint form__hint--before'>{hintBefore}</div>
        )}
        <AsyncSelect
          value={input.value}
          onChange={this.handleChange}
          defaultOptions={defaultOptions}
          cacheOptions
          loadOptions={this.loadOptions}
          onBlur={() => null}
          styles={styles}
          isClearable
          placeholder={placeholder}
        />
        {touched &&
          ((error && <span className='form__error'>{error}</span>) ||
            (warning && <span className='form__error'>{warning}</span>))}
        <label className='form__label' htmlFor={id}>
          {labelAfter}
        </label>
        {hintAfter && (
          <div className='form__hint form__hint--after'>{hintAfter}</div>
        )}
      </div>
    );
  }
}

export default VenueSelect;
