import { call, put } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { SubmissionError } from 'redux-form';
import { push } from 'connected-react-router';

export function* fetchEntity(entity, apiFn, action) {
  yield put(showLoading());
  yield put(entity.request({ ...action.payload }));
  const { response, error } = yield call(apiFn, { ...action.payload });
  if (response) yield put(entity.success({ ...action.payload, response }));
  else yield put(entity.failure({ ...action.payload, error }));
  yield put(entity.fulfill({ ...action.payload }));
  yield put(hideLoading());
}

export function* createEntity(entity, apiFn, action) {
  yield put(showLoading());
  yield put(entity.request({ ...action.payload }));
  const { response, error } = yield call(apiFn, { ...action.payload });
  if (response) yield put(entity.success({ response, ...action.payload }));
  else yield put(entity.failure({ error, ...action.payload }));
  yield put(hideLoading());
}

export function* updateEntity(entity, apiFn, action) {
  yield put(showLoading());
  yield put(entity.request({ ...action.payload }));
  const { response, error } = yield call(apiFn, { ...action.payload });
  if (response) yield put(entity.success({ response, ...action.payload }));
  else yield put(entity.failure({ error, ...action.payload }));
  yield put(hideLoading());
}

export function* deleteEntity(entity, apiFn, action) {
  yield put(showLoading());
  yield put(entity.request());
  const { response, error } = yield call(apiFn, { ...action.payload });
  if (response) {
    yield put(entity.success({ response, ...action.payload }));
    if (action.payload.onSuccessUrl) {
      yield put(push(action.payload.onSuccessUrl));
    }
  } else {
    yield put(entity.failure({ error, ...action.payload }));
  }
  yield put(hideLoading());
}

export function* submitForm(entity, apiFn, action) {
  yield put(showLoading());
  yield put(entity.request({ ...action.payload }));
  const { response, error } = yield call(apiFn, { ...action.payload });
  if (response) yield put(entity.success({ response, ...action.payload }));
  else yield put(entity.failure(new SubmissionError(error.errors)));
  yield put(hideLoading());
}
