import React from "react";
import Form from "../Form/Form";
import { reduxForm } from "redux-form";
import Table, { TableCell, TableHeader, TableBody } from "../Table/Table";
import { Box } from "grommet";
import TransferFormRow from "./TransferFormRow";

let TransferForm = (props) => {
  const {
    onTransferClicked,
    onTransferModalClose,
    order,
    lineItems,
    onSelectSeatsClick,
    onPerformanceChange,
    onTicketChange,
    transferEntries,
    loading,
  } = props;

  return (
    <Form
      submitBtn={false}
      onCancel={onTransferModalClose}
      formTitle="Exchange Tickets"
      cancelText="Done"
      guidanceNotes={
        <div>
          <div>
            {order.customer.displayname} - {order.customer.email}
          </div>
          {order.reference}
        </div>
      }
    >
      <Box pad={{ bottom: "large" }}>
        <Table containerBorder mobileWide>
          <TableHeader className="receipt__header">
            <TableCell width={20}>TICKET</TableCell>
            <TableCell width={10}>QUANTITY</TableCell>
            <TableCell width={10}>SEATS</TableCell>
            <TableCell width={20}>NEW DATE/TIME</TableCell>
            <TableCell width={20}>TICKET</TableCell>
            <TableCell width={20} />
          </TableHeader>
          <TableBody
            renderItem={(item) => (
              <TransferFormRow
                key={item.purchaseId}
                item={item}
                onTransferClicked={onTransferClicked}
                onSelectSeatsClick={onSelectSeatsClick}
                onPerformanceChange={onPerformanceChange}
                onTicketChange={onTicketChange}
                transferEntries={transferEntries}
                loading={loading}
              />
            )}
            items={lineItems.tickets}
          />
        </Table>
      </Box>
    </Form>
  );
};

TransferForm = reduxForm({
  form: "transfer",
})(TransferForm);
export default TransferForm;
