import React from 'react';

// Grommet
import { Box } from 'grommet';

const InfoBlockActions = ({ children }) => (
  <Box direction="row" gap="small">
    {children}
  </Box>
);

export default InfoBlockActions;
