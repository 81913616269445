import * as fromPaginator from '../shared/paginate';
import * as fromEntities from '../shared/entities';

//- Selectors
export const getDonationsForPage = (state, page) => {
  const ids = fromPaginator.getIdsForPage(state.donations.byPage, page);
  return ids.map(id => fromEntities.getEntity(state.entities.donation, id));
};

export const getDonationsCurrentPage = state =>
  fromPaginator.getCurrentPage(state.donations.byPage);
export const getDonationsTotalPages = state =>
  fromPaginator.getTotalPages(state.donations.byPage);
export const getDonationsLoadingStatus = state => state.donations.loading;
