import React from 'react';

const ProductEmbedSettings = () => {
  return (
    <div>
      <p>Embedded Settings</p>
    </div>
  );
};

export default ProductEmbedSettings;
