import React from 'react';
import Spinner from 'react-spinkit';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Button.css';

const Button = ({
  href,
  to,
  type,
  children,
  onClick,
  danger,
  size,
  disabled,
  className,
  primary,
  secondary,
  tertiary,
  quaternary,
  loading,
  icon,
  title,
  target,
  slim,
  small,
  cell,
  mailchimp,
  nomargin,
  full,
  large
}) => {
  const btnClass = classNames('button', className, {
    'button--primary': primary,
    'button--secondary': secondary,
    'button--danger': danger,
    'button--tertiary': tertiary,
    'button--quaternary': quaternary,
    'button--slim': slim,
    'button--small': small,
    'button--cell': cell,
    'button--mailchimp': mailchimp,
    'button--nomargin': nomargin,
    'button--full': full,
    'button--large': large
  });
  const iconClass = classNames('button__icon', {
    'button__icon--no-label': !children
  });
  const Element = to || href ? (to ? Link : 'a') : 'button';
  const role = Element === 'a' ? 'button' : null;
  return (
    <Element
      className={btnClass}
      to={to}
      href={href}
      onClick={onClick}
      disabled={disabled}
      type={type}
      role={role}
      title={title}
      target={target}
    >
      {icon && <FontAwesomeIcon icon={icon} className={iconClass} fixedWidth />}
      {loading ? (
        <Spinner
          name='pulse'
          color='#333'
          fade='none'
          className='button-spinner'
        />
      ) : (
        children
      )}
    </Element>
  );
};

export default Button;
