import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// Components
import MainContent, { LineUpHelmet } from 'components/MainContent/MainContent';

// Grommet
import { Box } from 'grommet';
import './SeatingChartCreate.css';
import {
  seatingChartActions,
  seatingChartSelectors,
} from 'modules/seatingCharts/seatingCharts';
import { bindActionCreators } from 'redux';
import { SeatsioChartManager } from '@seatsio/seatsio-react';

const SeatingChartManage = ({ seatingUser, chartKey, getSeatingUser }) => {
  useEffect(() => {
    if (!seatingUser) {
      getSeatingUser();
    }
  }, [seatingUser]);
  return (
    <>
      <LineUpHelmet
        title='Seating Chart Create'
        description='Create a Seating Chart'
      />
      <MainContent>
        <Box>
          {seatingUser && chartKey && (
            <SeatsioChartManager
              secretKey={seatingUser.secretKey}
              chart={chartKey}
              mode='manageRulesets'
            />
          )}
        </Box>
      </MainContent>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const chartKey = ownProps.match.params.chartKey;
  return {
    chartKey,
    seatingUser: seatingChartSelectors.getSeatingUser(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        ...seatingChartActions,
      },
      dispatch
    ),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeatingChartManage);
