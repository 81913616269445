import React from 'react';
import { Price, Percent } from '../../Utils/Utils';
import {
  InfoBlockList,
  InfoBlockListItem
} from '../../../components/InfoBlock/InfoBlock';
import './TicketPricingSummary.css';
const TicketPricingSummary = ({
  faceValue = 0,
  outsideFee = 0,
  userSettings: { feePercentage, feeFixedValue, feeTaxPercentage, currency }
}) => {
  const total = parseFloat(faceValue, 10) + parseFloat(outsideFee, 10);
  const lineupFee =
    total > 0
      ? parseFloat(feePercentage, 10) * total + parseFloat(feeFixedValue, 10)
      : 0;
  const lineupFeeTax =
    total > 0 ? parseFloat(feeTaxPercentage, 10) * lineupFee : 0;
  const net = total - lineupFee - lineupFeeTax;
  return (
    <div>
      <span className="ticket-pricing-summary-title">
        Pricing/Fees Breakdown
      </span>
      <div className="ticket-pricing-summary">
        <div className="ticket-pricing-summary-column">
          <span className="ticket-pricing-summary-subtitle">
            For the Customer
          </span>
          <InfoBlockList form>
            <InfoBlockListItem
              form
              attribute="Face Value"
              value={<Price amount={faceValue} currency={currency} showZero />}
            />
            <InfoBlockListItem
              form
              attribute="Booking Fee"
              value={<Price amount={outsideFee} currency={currency} showZero />}
            />
            <InfoBlockListItem
              form
              attribute="Total"
              value={<Price amount={total} currency={currency} showZero />}
            />
          </InfoBlockList>
        </div>
        <div className="ticket-pricing-summary-column">
          <span className="ticket-pricing-summary-subtitle">For You</span>
          <InfoBlockList form>
            <InfoBlockListItem
              form
              attribute={
                <span>
                  Line-Up fee @{' '}
                  {feePercentage > 0 && (
                    <Percent numerator={feePercentage} denominator={1} />
                  )}{' '}
                  {feeFixedValue > 0 && (
                    <>
                      + <Price amount={feeFixedValue} currency={currency} />
                    </>
                  )}
                </span>
              }
              value={<Price amount={lineupFee} currency={currency} showZero />}
            />
            <InfoBlockListItem
              form
              attribute="Line-Up Fee Tax"
              value={
                <Price amount={lineupFeeTax} currency={currency} showZero />
              }
            />
            <InfoBlockListItem
              form
              attribute="Net (exc. stripe Fee)"
              value={<Price amount={net} currency={currency} showZero />}
            />
          </InfoBlockList>
        </div>
      </div>
    </div>
  );
};

export default TicketPricingSummary;
