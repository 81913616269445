import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Route, Switch } from "react-router-dom";
import moment from "moment";
import uuid from "uuid";
import buildUrl from "build-url";
import { getPerformanceStartDate } from "../../utils/dates";
import { titleCase } from "../../utils/formats";

import {
  Title,
  LineUpHelmet,
  ContentType,
} from "../../components/MainContent/MainContent";
import ReportFilters from "../../components/ReportFilters/ReportFilters";

import Advance from "./financial/Advance";
import Payment from "./financial/Payment";
import Transactions from "./financial/Transactions";
import PaymentsBySource from "./financial/PaymentsBySource";

import Wrap from "./sales/Wrap";
import Discounts from "./sales/Discounts";
import AgentSales from "./sales/AgentSales";
import Settlement from "./sales/Settlement";
import SalesByPerformance from "./sales/SalesByPerformance";
import PerformanceDetail from "./sales/PerformanceDetail";

import SalesByTicketPrice from "./sales/SalesByTicketPrice";
import Inventory from "./sales/Inventory";

import SalesByTransactionDay from "./analytics/SalesByTransactionDay";
import DiscountUsage from "./analytics/DiscountUsage";

import { reportActions, reportSelectors } from "../../modules/reports/reports";
import { eventSelectors, eventActions } from "../../modules/events/events";
import { agentActions, agentSelectors } from "../../modules/agents/agents";
import { CSVLink } from "react-csv";
import {
  performanceActions,
  performanceSelectors,
} from "../../modules/performances/performances";

// Grommet
import { Box, Text, Button } from "grommet";
import { LineChart, Download } from "grommet-icons";

import "./Report.css";

// Assets

import ReportLogo from "../../assets/logo.png";

const Report = (props) => {
  const {
    history,
    reportName,
    eventTitle,
    reportData,
    rawData,
    events,
    agents,
    performances,

    currentFilters,
    addFilter,
    updateFilter,
    setFilters,
    removeFilter,
    eventId,
    fromDate,
    toDate,
    agentId,
    performanceStart,
    performanceEnd,
    performanceId,
    selectedPerformance,
    valuesLoading,

    fetchTicketData,
    fetchAllEvents,
    fetchAgentsPage,
    fetchAllPerformances,
  } = props;

  useEffect(() => {
    fetchAllEvents();
    fetchAgentsPage({ page: 1 });
  }, [fetchAllEvents, fetchAgentsPage]);

  useEffect(() => {
    eventId &&
      fetchAllPerformances({
        eventId: eventId,
        gte: new Date(2022, 12, 1, 0, 0, 0, 0).toISOString(),
      });
  }, [fetchAllPerformances, eventId]);

  useEffect(() => {
    eventId &&
      fetchTicketData({
        ...(eventId && { eventId }),
        ...(fromDate && { from: fromDate }),
        ...(toDate && { to: toDate }),
        ...(performanceStart && { performanceStart }),
        ...(performanceEnd && { performanceEnd }),
        ...(performanceId && { performanceId }),
        ...(agentId && { sellerId: agentId }),
        reportName,
      });
  }, [
    agentId,
    eventId,
    performanceId,
    fromDate,
    toDate,
    performanceStart,
    performanceEnd,
    reportName,
    fetchTicketData,
  ]);

  useEffect(() => {
    if (
      reportName === "all-transactions" ||
      reportName === "payments-by-source" ||
      reportName === "payments"
    ) {
      fromDate &&
        toDate &&
        fetchTicketData({
          ...(fromDate && { from: fromDate }),
          ...(toDate && { to: toDate }),
          reportName,
        });
    }
  }, [fromDate, toDate, reportName, fetchTicketData]);

  // Load filters from url

  useEffect(() => {
    const filters = {};
    if (
      reportName !== "all-transactions" &&
      reportName !== "payments-by-source" &&
      reportName !== "payments"
    ) {
      const filterId = 1;
      filters[filterId] = {
        id: filterId,
        attribute: "eventId",
        operator: "=",
        value: eventId,
        canRemove: false,
      };
      if (fromDate && toDate) {
        const filterId = uuid.v4();
        filters[filterId] = {
          id: filterId,
          attribute: "transactionDate",
          operator: "><",
          value: {
            from: fromDate,
            to: toDate,
          },
          canRemove: true,
        };
      }
      if (performanceStart && performanceEnd) {
        const filterId = uuid.v4();
        filters[filterId] = {
          id: filterId,
          attribute: "performanceDate",
          operator: "><",
          value: {
            from: performanceStart,
            to: performanceEnd,
          },
          canRemove: true,
        };
      }
      if (performanceId) {
        const filterId = uuid.v4();
        filters[filterId] = {
          id: filterId,
          attribute: "performanceDate",
          operator: "=",
          value: performanceId,
          canRemove: true,
        };
      }
      if (agentId) {
        const filterId = uuid.v4();
        filters[filterId] = {
          id: filterId,
          attribute: "agentId",
          operator: "=",
          value: agentId,
          canRemove: true,
        };
      }
    } else {
      const filterId = uuid.v4();
      const from = fromDate
        ? fromDate
        : moment
            .utc()
            .startOf("day")
            .toISOString();
      const to = toDate
        ? toDate
        : moment
            .utc()
            .endOf("day")
            .toISOString();
      filters[filterId] = {
        id: filterId,
        attribute: "transactionDate",
        operator: "><",
        value: {
          from: from,
          to: to,
        },
        canRemove: false,
      };
    }

    setFilters({ filters });
  }, [
    fromDate,
    toDate,
    performanceStart,
    performanceEnd,
    eventId,
    agentId,
    reportName,
    performanceId,
    setFilters,
  ]);

  const handleAddFilter = () => {
    addFilter({ filterId: uuid.v4() });
  };

  const handleUpdateFilter = (filterId, attribute, operator, value) => {
    if (attribute === "eventId") {
      fetchAllPerformances({
        eventId: value,
        gte: new Date(2022, 12, 1, 0, 0, 0, 0).toISOString(),
      });
    }
    updateFilter({
      filterId,
      attribute,
      operator,
      value,
    });
  };

  const handleRemoveFilter = (filterId) => {
    removeFilter({ filterId });
  };

  const handleRunReportClick = () => {
    let endpoint = "/";
    let params = {};
    for (let filter of currentFilters) {
      if (filter.attribute === "eventId") {
        if (filter.value) {
          endpoint = `/${filter.value}/`;
        }
      }
      if (filter.attribute === "transactionDate") {
        params = {
          ...params,
          fromDate: filter.value.from,
          toDate: filter.value.to,
        };
      }
      if (filter.attribute === "performanceDate") {
        params = {
          ...params,
          performanceStart: filter.value.from,
          performanceEnd: filter.value.to,
          ...(!filter.value.from && { performanceId: filter.value }),
        };
      }
      if (filter.attribute === "agentId") {
        params = {
          ...params,
          agentId: filter.value,
        };
      }
    }
    const url = buildUrl(`/report/${reportName}`, {
      path: endpoint,
      queryParams: params,
    });
    history.push(url);
  };
  const getTrasnactionDateRange = () => {
    let trasnactionDateRange = "All Transactions";
    const from = fromDate ? moment(fromDate) : null;
    const to = toDate ? moment(toDate) : null;
    if (from && to) {
      if (from.isSame(to, "day")) {
        trasnactionDateRange = `Transactions on: ${from.format(
          "ddd, MMM Do YYYY"
        )}`;
      } else {
        trasnactionDateRange = `Transactions between: ${from.format(
          "ddd, MMM Do YYYY"
        )} - ${to.format("ddd, MMM Do YYYY")}`;
      }
    } else if (to) {
      trasnactionDateRange = `Transactions to: ${to.format(
        "ddd, MMM Do YYYY"
      )}`;
    } else if (from) {
      trasnactionDateRange = `Transactions from: ${from.format(
        "ddd, MMM Do YYYY"
      )}`;
    }
    return trasnactionDateRange;
  };
  const getPerformanceDataRange = () => {
    let performanceDateRange = "";
    const start = performanceStart ? moment(performanceStart) : null;
    const end = performanceEnd ? moment(performanceEnd) : null;

    if (selectedPerformance && selectedPerformance.startDate) {
      const performanceDate = getPerformanceStartDate(selectedPerformance);
      performanceDateRange = `Performance: ${moment(performanceDate).format(
        "ddd, MMM Do YYYY HH:mm"
      )}`;
    }
    if (start && end) {
      if (start.isSame(end, "day")) {
        performanceDateRange = `Performances on: ${start.format(
          "ddd, MMM Do YYYY"
        )}`;
      } else {
        performanceDateRange = `Performances between: ${start.format(
          "ddd, MMM Do YYYY"
        )} - ${end.format("ddd, MMM Do YYYY")}`;
      }
    } else if (end) {
      performanceDateRange = `Performances to: ${end.format(
        "ddd, MMM Do YYYY"
      )}`;
    } else if (start) {
      performanceDateRange = `Performances from: ${start.format(
        "ddd, MMM Do YYYY"
      )}`;
    }
    return performanceDateRange;
  };
  const title = titleCase(reportName);

  const generalFilters = [
    {
      label: "Event",
      value: "eventId",
      operatorType: "item",
      values: events.map((event) => ({
        label: event.title,
        value: event.id,
      })),
    },
    {
      label: "Transaction Date",
      value: "transactionDate",
      operatorType: "date",
    },
    {
      label: "Performance Date",
      value: "performanceDate",
      operatorType: "hybrid",
      values: performances.map((performance) => ({
        label: `${performance.startDate} ${performance.startTime}`,
        value: performance.id,
      })),
    },
    {
      label: "Agent",
      value: "agentId",
      operatorType: "item",
      values: agents.map((agent) => ({
        label: agent.company,
        value: agent.id,
      })),
    },
  ];

  const performanceFilters = [
    {
      label: "Event",
      value: "eventId",
      operatorType: "item",
      values: events.map((event) => ({
        label: event.title,
        value: event.id,
      })),
    },

    {
      label: "Performance Date",
      value: "performanceDate",
      operatorType: "hybrid",
      values: performances.map((performance) => ({
        label: `${performance.startDate} ${performance.startTime}`,
        value: performance.id,
      })),
    },
  ];

  const performanceRangeFilters = [
    {
      label: "Event",
      value: "eventId",
      operatorType: "item",
      values: events.map((event) => ({
        label: event.title,
        value: event.id,
      })),
    },

    {
      label: "Performance Date",
      value: "performanceDate",
      operatorType: "date",
    },
  ];
  return (
    <>
      <LineUpHelmet title="Report" description="Your Line-Up sales report" />

      <div className="report">
        <div className="report__wrapper">
          <div className="report__content-type">
            <ContentType icon={<LineChart size="small" />} label="Report" />
          </div>

          <div className="report__details-wrapper">
            <div className="report__screen-title">
              <Title print>{title}</Title>
            </div>
            <div className="report__print-header">
              <div className="report__lu-logo">
                <img src={ReportLogo} />
              </div>
              <div className="report__header-heading">Report</div>
              <div className="report__header-title">{title}</div>
              <div className="report__header-details">
                <div className="report__event-name">
                  <b>Event:</b> {eventTitle}
                </div>
                <div className="report__prepared-date">
                  <b>Prepared:</b> {moment().format("ddd, MMM Do YYYY")}
                </div>
              </div>
            </div>

            <ReportFilters
              currentFilters={currentFilters}
              onRemoveFilter={handleRemoveFilter}
              onAddFilter={handleAddFilter}
              onUpdateFilter={handleUpdateFilter}
              onRunReportClick={handleRunReportClick}
              filters={
                reportName === "inventory" ||
                reportName === "performance-detail"
                  ? performanceRangeFilters
                  : generalFilters
              }
              valuesLoading={valuesLoading}
              allowAdd={
                reportName !== "all-transactions" &&
                reportName !== "payments-by-source" &&
                reportName !== "payments"
              }
            />

            <div>
              <div className="report__info-range">
                {getTrasnactionDateRange()}
              </div>
              <div className="report__info-range">
                {getPerformanceDataRange()}
              </div>

              <div className="report__info-raw-data">
                {rawData && (
                  <CSVLink
                    data={rawData}
                    filename={`line-up-data-${reportName}.csv`}
                  >
                    <Button size="small">
                      <Box
                        direction="row"
                        align="center"
                        gap="small"
                        margin={{ top: "xsmall" }}
                      >
                        <Download size="small" />
                        <Text color="copy" size="xsmall">
                          Export Raw Data
                        </Text>
                      </Box>
                    </Button>
                  </CSVLink>
                )}
              </div>
            </div>

            <Switch>
              <Route
                path="/report/sales-by-performance-date/"
                render={() => (
                  <SalesByPerformance
                    data={reportData}
                    title={title}
                    exportFilename={reportName}
                  />
                )}
              />
              <Route
                path="/report/performance-detail/"
                render={() => (
                  <PerformanceDetail
                    data={reportData}
                    title={title}
                    exportFilename={reportName}
                  />
                )}
              />
              <Route
                path="/report/settlement/"
                render={(props) => (
                  <Settlement
                    data={reportData}
                    title={title}
                    exportFilename={reportName}
                  />
                )}
              />
              <Route
                path="/report/sales-by-transaction-day/"
                render={() => (
                  <SalesByTransactionDay
                    data={reportData}
                    title={title}
                    exportFilename={reportName}
                  />
                )}
              />
              <Route
                path="/report/sales-by-ticket-price/"
                render={() => (
                  <SalesByTicketPrice
                    data={reportData}
                    title={title}
                    exportFilename={reportName}
                  />
                )}
              />
              <Route
                path={`/report/wrap/`}
                render={() => (
                  <Wrap
                    data={reportData}
                    title={title}
                    exportFilename={reportName}
                  />
                )}
              />
              <Route
                path={"/report/payments/"}
                render={() => (
                  <Payment
                    data={reportData}
                    title={title}
                    exportFilename={reportName}
                  />
                )}
              />
              <Route
                path="/report/advance/"
                render={() => (
                  <Advance
                    data={reportData}
                    title={title}
                    exportFilename={reportName}
                  />
                )}
              />
              <Route
                path="/report/agent-sales/"
                render={() => (
                  <AgentSales
                    data={reportData}
                    title={title}
                    exportFilename={reportName}
                  />
                )}
              />
              <Route
                path="/report/discounts/"
                render={() => (
                  <Discounts
                    data={reportData}
                    title={title}
                    exportFilename={reportName}
                  />
                )}
              />
              <Route
                path="/report/inventory/"
                render={() => (
                  <Inventory
                    data={reportData}
                    title={title}
                    exportFilename={reportName}
                  />
                )}
              />
              <Route
                path="/report/all-transactions/"
                render={() => (
                  <Transactions
                    data={reportData}
                    title={title}
                    exportFilename={reportName}
                  />
                )}
              />
              <Route
                path="/report/payments-by-source/"
                render={() => (
                  <PaymentsBySource
                    data={reportData}
                    title={title}
                    exportFilename={reportName}
                  />
                )}
              />
              <Route
                path="/report/discount-usage/"
                render={() => (
                  <DiscountUsage
                    data={reportData}
                    title={title}
                    exportFilename={reportName}
                  />
                )}
              />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { match, location } = ownProps;
  const events = eventSelectors.getEvents(state);
  const agents = agentSelectors.getAgentsForPage(state, 1);
  const performances = performanceSelectors.getAllPerformances(state);
  const currentFilters = reportSelectors.getCurrentFilters(state);

  const query = new URLSearchParams(location.search);
  const eventId = parseInt(match.params.eventId, 10);
  const event = eventSelectors.getEvent(state, eventId);
  const fromDate = query.get("fromDate");
  const toDate = query.get("toDate");
  const performanceStart = query.get("performanceStart");
  const performanceEnd = query.get("performanceEnd");
  const performanceId = query.get("performanceId")
    ? parseInt(query.get("performanceId"), 10)
    : null;
  const agentId = query.get("agentId");
  const selectedPerformance = performanceSelectors.getPerformance(
    state,
    performanceId
  );
  return {
    reportData: reportSelectors.reportData(state),
    rawData: reportSelectors.getTicketData(state),
    eventTitle: event ? event.title : "",
    events,
    agents,
    performances,
    currentFilters,
    eventId,
    fromDate,
    toDate,
    agentId,
    performanceStart,
    performanceEnd,
    performanceId,
    performance,
    selectedPerformance,
    reportName: ownProps.match.params.reportName,
  };
};

export default connect(mapStateToProps, {
  ...reportActions,
  ...eventActions,
  ...agentActions,
  ...performanceActions,
})(Report);
