import React from 'react';
import { uploadImage } from 'services/api';

// defaults
const imageMaxSize = 1000000000;
const acceptedFileTypes = [
  'image/x-png',
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/gif',
];

const useImageUpload = initialFileModel => {
  const [uploading, setUploading] = React.useState(false);
  const [fileModel, setFileModel] = React.useState(initialFileModel);

  const uploadFile = async file => {
    if (!acceptedFileTypes.includes(file.type)) {
      throw new Error('This file is not an image. Only images are allowed');
    }

    if (file.size > imageMaxSize) {
      throw new Error('This file is too large');
    }

    setUploading(true);

    return uploadImage('image', file).then(({ response }) => {
      setUploading(false);
      setFileModel(response);
      return response;
    });
  };

  const deleteFile = () => {
    setFileModel(null);
  };

  return {
    uploadFile,
    uploading,
    fileModel,
    deleteFile,
    acceptedFileTypes,
  };
};

export default useImageUpload;
