import * as fromListIds from '../shared/listIds';
import * as fromPaginator from '../shared/paginate';
import * as fromEntities from '../shared/entities';

//- Selectors

export const getPerformance = (state, performanceId) =>
  fromEntities.getEntity(state.entities.performance, performanceId);

export const getPerformancesForPage = (state, page) => {
  const ids = fromPaginator.getIdsForPage(state.performances.byPage, page);
  return ids.map(id => getPerformance(state, id));
};

export const getAllPerformances = state => {
  const ids = fromListIds.getIds(state.performances.byId);
  return ids.map(id => getPerformance(state, id));
};

export const getPerformancesLoadingStatus = state => state.performances.loading;

export const getPerformancesCurrentPage = state =>
  fromPaginator.getCurrentPage(state.performances.byPage);

export const getPerformancesTotalPages = state =>
  fromPaginator.getTotalPages(state.performances.byPage);
export const getIsLoadingForPage = (state, page) =>
  fromPaginator.getIsLoading(state.performances.byPage, page);

export const getDateFilter = state => state.performances.filters.date;
