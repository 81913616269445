import { createAction } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';
import * as types from './types';

export const exportCustomers = createRoutine(types.EXPORT_CUSTOMERS);

//- Actions
export const updateUser = createAction(types.USER_UPDATE, (userId, user) => ({
  userId,
  user
}));

export const userUpdate = {
  request: createAction(types.UPDATE.REQUEST, userId => userId),
  success: createAction(types.UPDATE.SUCCESS, (userId, response) => ({
    userId,
    response
  })),
  failure: createAction(types.UPDATE.FAILURE, (userId, error) => ({
    userId,
    error
  }))
};

export const loadUser = createAction(types.LOAD, userId => ({
  userId
}));

export const user = {
  request: createAction(types.USER.REQUEST, userId => ({ userId })),
  success: createAction(types.USER.SUCCESS, (userId, response) => ({
    userId,
    response
  })),
  failure: createAction(types.USER.FAILURE, (userId, error) => ({
    userId,
    error
  }))
};
