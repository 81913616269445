import React from 'react';
import ToolbarButton from './ToolbarButton/ToolbarButton';
import './Toolbar.css';

const Toolbar = ({
  onMarkClick,
  onBlockClick,
  selectionHasMark,
  selectionHasBlock,
  onLinkClick,
  selectionHasLink
}) => (
  <div className="toolbar">
    <ToolbarButton
      type="bold"
      label="Bold"
      icon="bold"
      onClick={onMarkClick}
      isActive={selectionHasMark}
    />
    <ToolbarButton
      type="italic"
      label="Italic"
      icon="italic"
      onClick={onMarkClick}
      isActive={selectionHasMark}
    />
    <ToolbarButton
      type="underline"
      label="Underline"
      icon="underline"
      onClick={onMarkClick}
      isActive={selectionHasMark}
    />
    <ToolbarButton
      type="quote"
      label="Quote"
      icon="quote-right"
      onClick={onBlockClick}
      isActive={selectionHasBlock}
    />
    <ToolbarButton
      type="link"
      label="Link"
      icon="link"
      onClick={onLinkClick}
      isActive={selectionHasLink}
    />
    <ToolbarButton
      type="heading-one"
      label="Heading One"
      icon="heading"
      postIcon="1"
      onClick={onBlockClick}
      isActive={selectionHasBlock}
    />
    <ToolbarButton
      type="heading-two"
      label="Heading Two"
      icon="heading"
      postIcon="2"
      onClick={onBlockClick}
      isActive={selectionHasBlock}
    />
    <ToolbarButton
      type="heading-three"
      label="Heading Three"
      icon="heading"
      postIcon="3"
      onClick={onBlockClick}
      isActive={selectionHasBlock}
    />
    <ToolbarButton
      type="numbered-list"
      label="Numbered List"
      icon="list-ol"
      onClick={onBlockClick}
      isActive={selectionHasBlock}
    />
    <ToolbarButton
      type="bulleted-list"
      label="Bulleted List"
      icon="list-ul"
      onClick={onBlockClick}
      isActive={selectionHasBlock}
    />
  </div>
);

export default Toolbar;
