import * as types from './types';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const authenticateUser = createRoutine(types.AUTHENTICATE_USER);
export const submitAuthenticateUser = promisifyRoutine(authenticateUser);

export const logoutUser = createRoutine(types.LOGOUT_USER);
export const submitLogoutUser = promisifyRoutine(logoutUser);

export const createUser = createRoutine(types.CREATE_USER);
export const submitCreateUser = promisifyRoutine(createUser);

export const recoverAuth = createRoutine(types.RECOVER_AUTH);
export const reAuth = createRoutine(types.GET_USER_FROM_TOKEN);

export const updateUser = createRoutine(types.UPDATE_USER);
export const submitUpdateUser = promisifyRoutine(updateUser);

export const updateUserProfile = createRoutine(types.UPDATE_USER_PROFILE);
export const submitUpdateUserProfile = promisifyRoutine(updateUserProfile);

export const getSubscription = createRoutine(types.GET_SUBSCRIPTION);

export const getPaymentMethod = createRoutine(types.GET_PAYMENT_METHOD);

export const createPaymentMethod = createRoutine(types.CREATE_PAYMENT_METHOD);
export const submitCreatePaymentMethod = promisifyRoutine(createPaymentMethod);

export const updateSubscription = createRoutine(types.UPDATE_SUBSCRIPTION);
export const submitUpdateSubscription = promisifyRoutine(updateSubscription);

export const getStripeSettings = createRoutine(types.GET_STRIPE_SETTINGS);

export const createStripeSettings = createRoutine(types.CREATE_STRIPE_SETTINGS);

export const deleteStripeSettings = createRoutine(types.DELETE_STRIPE_SETTINGS);

export const createBusinessSettings = createRoutine(
  types.CREATE_BUSINESS_SETTINGS
);
export const getBusinessSettings = createRoutine(types.GET_BUSINESS_SETTINGS);

export const forgotPassword = createRoutine(types.FORGOT_PASSWORD);
export const submitForgotPassword = promisifyRoutine(forgotPassword);

export const resetPassword = createRoutine(types.RESET_PASSWORD);
export const submitResetPassword = promisifyRoutine(resetPassword);

export const getCurrencies = createRoutine(types.GET_CURRENCIES);
