import React from 'react';
import ReactMarkdown from 'react-markdown';
import { FormattedDate } from 'react-intl';
import './TimeLineItem.css';
import styled from 'styled-components';

// Grommet
import { Box, Button, ResponsiveContext, Text } from 'grommet';
import { Edit, Trash } from 'grommet-icons';

const TimelineButton = styled(Button)`
  font-weight: 900;
  padding: 5px 10px;
  font-size: 12px;
`;

const TimeLineItem = ({
  children,
  className,
  id,
  text,
  titleComponent,
  date,
  icon,
  user,
  onEdit,
  onDelete
}) => {
  const handleEditClick = e => {
    e.preventDefault();
    onEdit && onEdit(id);
  };
  const handleDeleteClick = e => {
    e.preventDefault();
    onDelete && onDelete(id);
  };
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          direction={size !== 'small' ? 'row' : 'column'}
          align='start'
          justify='between'
          gap='medium'
          pad={
            size !== 'small' ? { vertical: 'small' } : { vertical: 'medium' }
          }
        >
          <Box
            direction='column'
            width={size !== 'small' ? '80%' : '100%'}
            gap='xsmall'
          >
            <Box direction='row' gap='small' align='center'>
              {icon}
              {titleComponent && <Text size='xsmall'>{titleComponent}</Text>}
              {text && (
                <ReactMarkdown source={text} className='timeline__text' />
              )}
            </Box>
            <Box direction='row'>
              <Text size='xsmall' color='copy-light'>
                <FormattedDate
                  value={date}
                  weekday='short'
                  year='numeric'
                  
                  day='numeric'
                  month='short'
                  hour='numeric'
                  minute='numeric'
                />
                {user && <> by {user}</>}
              </Text>
            </Box>
          </Box>
          <Box
            direction='row'
            width={size !== 'small' ? '20%' : '100%'}
            justify={size !== 'small' ? 'end' : 'start'}
            gap='small'
          >
            {onEdit && (
              <TimelineButton
                secondary
                onClick={handleEditClick}
                icon={<Edit size='small' />}
                label='Edit'
                size='small'
              />
            )}
            {onDelete && (
              <TimelineButton
                secondary
                onClick={handleDeleteClick}
                icon={<Trash size='small' />}
                label='Delete'
                size='small'
              />
            )}
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default TimeLineItem;
