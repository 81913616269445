import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../../../components/Modal/Modal';

import { hideModal } from '../../../modules/modal/modal';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import IssueTicketForm from '../../../components/IssueTicketForm/IssueTicketForm';
import { ticketAllocationActions } from '../../../modules/ticketAllocation/ticketAllocation';
import { agentActions, agentSelectors } from '../../../modules/agents/agents';

class IssueTicketModal extends Component {
  handleOnSubmit = values => {
    const { ticket, allocationId } = this.props;
    const customer = {
      firstName: values.firstName,
      lastName: values.lastName,
    };
    const purchaseData = {
      quantity: values.quantity,
      customer: customer,
      discount: ticket.faceValueTotal - values.price,
      feeExempt: !values.includeFee,
    };
    return this.props.onSumbmitIssueTicketForm({
      allocationId: allocationId,
      agentId: values.agent.value,
      purchaseData: [purchaseData],
    });
  };
  handleOnSubmitSuccess = (result, dispatch) => {
    this.props.onSubmitSuccess && this.props.onSubmitSuccess(result);
    this.props.hideModal();
  };

  render() {
    const { agents, agentId } = this.props;
    const agentOptions = agents.map(d => ({
      value: d.id,
      label: d.company,
    }));
    const intialAgent = agentId
      ? agentOptions.find(a => a.value === this.props.agentId)
      : null;
    return (
      <Modal onRequestClose={() => this.props.hideModal()}>
        <IssueTicketForm
          onSubmit={this.handleOnSubmit}
          onSubmitSuccess={this.handleOnSubmitSuccess}
          onCancel={this.props.hideModal}
          ticket={this.props.ticket}
          initialValues={{
            price: this.props.ticket.faceValueTotal,
            includeFee: true,
            quantity: 1,
            agent: intialAgent,
          }}
          agents={agentOptions}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    agents: agentSelectors.getAgentsForPage(state, 1),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        onSumbmitIssueTicketForm:
          ticketAllocationActions.sumbmitIssueTicketForm,
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        ...agentActions,
        hideModal,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IssueTicketModal);
