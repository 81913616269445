import React from 'react';
import classNames from 'classnames';
import './InfoBlockImage.css';

const InfoBlockImage = ({ src, alt = 'image', className }) => {
  const infoBlockImageClass = classNames('info-block__image-wrap', className);
  return (
    <span className={infoBlockImageClass}>
      <img alt={alt} src={src} className="info-block__image" />
    </span>
  );
};

export default InfoBlockImage;
