//https://stackoverflow.com/questions/35623656/how-can-i-display-a-modal-dialog-in-redux-that-performs-asynchronous-actions/35641680
import { createAction } from 'redux-actions';

//types
const MODAL_SHOW = 'line-up/modal/MODAL_SHOW';
const MODAL_HIDE = 'line-up/modal/MODAL_HIDE';

//- Actions
export const showModal = createAction(
  MODAL_SHOW,
  (modalType, modalProps = {}) => ({
    modalType,
    modalProps
  })
);
export const hideModal = createAction(MODAL_HIDE);

//- State
const initialState = {
  modalType: null,
  modalProps: {}
};

//- Reducers
const modal = (state = initialState, { type, payload }) => {
  switch (type) {
    case MODAL_SHOW:
      return {
        ...state,
        ...payload
      };
    case MODAL_HIDE:
      return initialState;
    default:
      return state;
  }
};

export default modal;

//- Selectors
