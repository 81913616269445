import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

//Redux
import { connect } from 'react-redux';

// Modules
import { eventActions, eventSelectors } from '../../modules/events/events';
import {
  accountActions,
  accountSelectors,
} from '../../modules/account/account';

// Components
import TicketWidget from 'components/TicketWidget/TicketWidget';
import PublicHeader from 'components/MainContent/PublicHeader/PublicHeader';
import PublicFooter from 'components/MainContent/PublicFooter/PublicFooter';

// Grommet
import {
  Anchor,
  Box,
  Heading,
  Image,
  Stack,
  Text,
  Main,
  ResponsiveContext,
  Spinner,
} from 'grommet';
import { Facebook, Twitter, Location } from 'grommet-icons';

import EventNotFound from './event_not_found.png';

const SocialShareWrapper = styled.div`
  position: fixed;
  top: 16%;
  right: 0;
  background: #399ba2;
  z-index: 1;
`;

const EventBackgroundImage = styled(Image)`
  filter: blur(15px) saturate(1) grayscale(20%) contrast(1) sepia(10%);
`;

const EventMainImage = styled(Image)`
  border-radius: 6px;
`;

const ShareButton = styled(Anchor)`
  &:hover {
    background: #30848a;
  }
`;

const SocialShare = () => {
  return (
    <SocialShareWrapper>
      <Box direction='column' elevation='large'>
        <ShareButton
          icon={<Facebook size='medium' color='white' />}
          href='https://www.facebook.com/sharer/sharer.php?u='
        />
        <ShareButton
          icon={<Twitter size='medium' color='white' />}
          href='https://twitter.com/intent/tweet?url='
        />
      </Box>
    </SocialShareWrapper>
  );
};

const PublicEvent = ({
  loading,
  event,
  eventSlug,
  fetchEventBySlug,
  isAuthenticated,
  recoverAuth,
  pathName,
}) => {
  useEffect(() => {
    fetchEventBySlug({
      eventSlug: eventSlug,
    });
  }, [fetchEventBySlug, eventSlug]);
  React.useEffect(() => {
    if (!isAuthenticated) {
      recoverAuth({ redirect: pathName });
    }
  }, [recoverAuth, pathName, isAuthenticated]);
  return loading ? (
    <Main
      pad='large'
      background='topbar'
      align='center'
      justify='center'
      direction='column'
      gap='medium'
    >
      <Spinner />
      <Text>Loading</Text>
    </Main>
  ) : event ? (
    event.errors ? (
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box background='topbar'>
            <PublicHeader isAuthenticated={isAuthenticated} />
            <Box align='center' justify='center' pad='large' gap='medium'>
              <Heading
                color='heading'
                level='2'
                size='medium'
                textAlign='center'
              >
                Oops...we can't find this event
              </Heading>
              <Box
                width={
                  size !== 'small'
                    ? { min: '35%', max: '35%' }
                    : { min: '300px', max: '300px' }
                }
              >
                <Image src={EventNotFound} fit='contain' fill />
              </Box>
              {/* <Text>{event.errors._error}</Text> */}
              <Box>
                <Text size='small' textAlign='center'>
                  Expecting to see your event here?
                  <br />
                  Check your{' '}
                  <Link to='/account/ticket-sales' style={{ color: 'inherit' }}>
                    Ticket Sales settings
                  </Link>{' '}
                  to ensure you're ready for sales.
                </Text>
              </Box>
            </Box>

            <PublicFooter />
          </Box>
        )}
      </ResponsiveContext.Consumer>
    ) : (
      <ResponsiveContext.Consumer>
        {(size) => (
          <div>
            <Helmet>
              <html lang='en' />
              <title>{`${event.title} | Line-Up`}</title>
              <meta name='description' content={event.shortDescription} />
              <link
                rel='apple-touch-icon'
                sizes='180x180'
                href='/img/apple-touch-icon.png'
              />
              <link
                rel='icon'
                type='image/png'
                href='/img/favicon-32x32.png'
                sizes='32x32'
              />
              <link
                rel='icon'
                type='image/png'
                href='/img/favicon-16x16.png'
                sizes='16x16'
              />

              <link
                rel='mask-icon'
                href='/img/safari-pinned-tab.svg'
                color='#fff'
              />
              <meta name='theme-color' content='#fff' />

              <meta property='og:type' content='business.business' />
              <meta property='og:title' content={event.title} />
              <meta property='og:url' content='/' />
              <meta property='og:image' content='/img/og-image.jpg' />
            </Helmet>

            <Box background='topbar'>
              <PublicHeader isAuthenticated={isAuthenticated} />
              <Box
                justify='center'
                align='center'
                margin={{ vertical: 'large' }}
              >
                <Heading color='heading' level='1' size='medium'>
                  {event.title}
                </Heading>
                <SocialShare />
                <Stack
                  anchor='top'
                  fill='horizontal'
                  margin={{ bottom: 'large' }}
                >
                  <Box
                    pad
                    height={size !== 'small' ? '350px' : '250px'}
                    width='100%'
                    background='border'
                    overflow='hidden'
                  >
                    <EventBackgroundImage fit='cover' src={event.image.url} />
                  </Box>
                  <Box
                    width={size !== 'small' ? 'medium' : '300px'}
                    height={size !== 'small' ? 'medium' : '300px'}
                    background='brand'
                    round='xsmall'
                    elevation='large'
                    margin={{ top: 'medium' }}
                  >
                    <EventMainImage fit='cover' src={event.image.url} />
                  </Box>
                </Stack>
                <Box
                  justify='center'
                  align='center'
                  margin={
                    size !== 'small'
                      ? { vertical: 'large' }
                      : { vertical: 'xlarge' }
                  }
                  width={size !== 'small' ? '95%' : '90%'}
                >
                  <Box direction='row' gap='xsmall' align='center'>
                    <Location size='small' />
                    <Text textAlign='center'>{event.venue.name}</Text>
                  </Box>

                  <Heading level='2' size='small' textAlign='center'>
                    {event.shortDescription}
                  </Heading>
                  <Box width={size !== 'small' ? '900px' : '100%'}>
                    <div
                      dangerouslySetInnerHTML={{ __html: event.description }}
                    />
                  </Box>
                </Box>
                <Box
                  // height='800px'
                  width={size !== 'small' ? '900px' : '90%'}
                  margin={{ bottom: 'large' }}
                >
                  <TicketWidget
                    eventId={event.id}
                    apiKey={process.env.REACT_APP_PLATFORM_PUBLIC_API_KEY}
                  />
                </Box>
              </Box>
              <PublicFooter />
            </Box>
          </div>
        )}
      </ResponsiveContext.Consumer>
    )
  ) : (
    <div> No Event Found</div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const eventSlug = ownProps.match.params.eventSlug;
  const event = eventSelectors.getPublicEvent(state);
  const loading = eventSelectors.getPublicEventLoading(state);
  const isAuthenticated = accountSelectors.isAuthenticated(state);
  const pathName = state.router.location;
  return {
    loading,
    event,
    eventSlug,
    isAuthenticated,
    pathName,
  };
};

export default connect(mapStateToProps, {
  ...eventActions,
  ...accountActions,
})(PublicEvent);
