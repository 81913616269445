import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import { fork, all } from 'redux-saga/effects';

import entities from './shared/entities';
import discounts from './discounts/discounts';
import { discountOperations } from './discounts/discounts';

import donations, { donationOperations } from './donations/donations';
import products, { productOperations } from './products/products';

import ticketGroups from './ticketGroups/ticketGroups';
import { ticketGroupOperations } from './ticketGroups/ticketGroups';

import ticketAllocation, {
  ticketAllocationOperations,
} from './ticketAllocation/ticketAllocation';

import ticketReceipts, {
  ticketReceiptOperations,
} from './ticketReceipts/ticketReceipts';

import eventEmbedSettings, {
  embedSettingsOperations,
} from './eventEmbedSettings/eventEmbedSettings';

import tickets, { ticketOperations } from './tickets/tickets';

import users, { userOperations } from './users/users';
import waitingList, { waitingListOperations } from './waitingList/waitingList';
import agents, { agentOperations } from './agents/agents';
import reports, { reportOperations } from './reports/reports';
import orders, { orderOperations } from './orders/orders';
import events, { eventOperations } from './events/events';
import performances, {
  performanceOperations,
} from './performances/performances';
import seatingCharts, {
  seatingChartOperations,
} from './seatingCharts/seatingCharts';
import invoices, { invoiceOperations } from './invoices/invoices';
import forms, { formOperations } from './forms/forms';
import modal from './modal/modal';
import { venueOperations } from './venues/venues';
import mailchimp, { mailchimpOperations } from './mailchimp/mailchimp';
import eventPlanner, {
  eventPlannerOperations,
} from './eventPlanner/eventPlanner';

import emails, { emailOperations } from './emails/emails';
import eventEmailSchedules, {
  eventEmailScheduleOperations,
} from './eventEmailSchedules/eventEmailSchedules';
import account, { accountOperations } from './account/account';
import webhooks, { webhookOperations } from './webhooks/webhooks';

let allOperations = [];

const registerOperations = operations => {
  for (let exportedOperationName in operations) {
    allOperations.push(fork(operations[exportedOperationName]));
  }
};

registerOperations(discountOperations);
registerOperations(productOperations);
registerOperations(ticketGroupOperations);
registerOperations(ticketAllocationOperations);
registerOperations(ticketReceiptOperations);
registerOperations(donationOperations);
registerOperations(embedSettingsOperations);
registerOperations(userOperations);
registerOperations(waitingListOperations);
registerOperations(agentOperations);
registerOperations(reportOperations);
registerOperations(orderOperations);
registerOperations(eventOperations);
registerOperations(performanceOperations);
registerOperations(ticketOperations);
registerOperations(seatingChartOperations);
registerOperations(invoiceOperations);
registerOperations(venueOperations);
registerOperations(mailchimpOperations);
registerOperations(eventPlannerOperations);
registerOperations(emailOperations);
registerOperations(eventEmailScheduleOperations);
registerOperations(accountOperations);
registerOperations(webhookOperations);

export function* rootSaga() {
  yield all([
    fork(routinePromiseWatcherSaga),
    fork(formOperations.watchFetch),
    fork(formOperations.watchExportFormResponses),

    ...allOperations,
  ]);
}

export default history =>
  combineReducers({
    router: connectRouter(history),
    discounts,
    donations,
    eventEmbedSettings,
    ticketGroups,
    products,
    ticketAllocation,
    ticketReceipts,
    entities,
    modal,
    form: formReducer,
    loadingBar: loadingBarReducer,
    users,
    waitingList,
    agents,
    reports,
    orders,
    events,
    performances,
    invoices,
    seatingCharts,
    tickets,
    forms,
    emails,
    mailchimp,
    eventEmailSchedules,
    eventPlanner,
    account,
    webhooks,
  });
