import React from 'react';
import { Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import './ScheduleForm.css';

// components
import Form, { FormSectionHeader } from '../Form/Form';
import FormField from '../Form/FormField/FormField';
import FormFieldSelect from '../Form/FormFieldSelect/FormFieldSelect';

const validate = values => {
  let errors = {};

  if (!values.emailId || values.emailId === 'NOT_SET') {
    errors.emailId = 'Pick an email template to send';
  }

  if (!values.timePeriod) {
    errors.timePeriod = 'This is required';
  }

  if (!parseInt(values.timePeriod) || parseInt(values.timePeriod) < 0) {
    errors.timePeriod = 'This must be a positive number';
  }

  if (!values.timePeriodUnit || values.timePeriodUnit === 'NOT_SET') {
    errors.timePeriodUnit = 'This is required';
  }

  if (!values.beforeAfter || values.beforeAfter === 'NOT_SET') {
    errors.beforeAfter = 'This is required';
  }

  return errors;
};

export const toFormValues = eventEmailSchedule => {
  let mins = Math.abs(eventEmailSchedule.startDeltaMinutes);
  let convertedDelta = null;
  let unit = null;
  switch (eventEmailSchedule.displayUnit) {
    case 'days':
      convertedDelta = mins / 1440;
      unit = 'Days';
      break;
    case 'hours':
      convertedDelta = mins / 60;
      unit = 'Hours';
      break;
    default:
      convertedDelta = mins;
      unit = 'Minutes';
  }

  return {
    eventId: eventEmailSchedule.eventId,
    emailId: eventEmailSchedule.emailId,
    beforeAfter: eventEmailSchedule.startDeltaMinutes > 0 ? 'After' : 'Before',
    timePeriodUnit: unit,
    timePeriod: convertedDelta,
  };
};

export const toApiValues = formValues => {
  const v = formValues.beforeAfter === 'Before' ? -1 : 1;
  let multiplier = 1;
  if (formValues.timePeriodUnit === 'Hours') {
    multiplier = 60;
  }
  if (formValues.timePeriodUnit === 'Days') {
    multiplier = 1440;
  }

  return {
    eventId: parseInt(formValues.eventId),
    emailId: parseInt(formValues.emailId),
    id: formValues.id,
    startDeltaMinutes: v * parseFloat(formValues.timePeriod) * multiplier,
    displayUnit: formValues.timePeriodUnit.toLowerCase(),
  };
};

const withPlaceholderOption = (
  options,
  description = 'Please select an option'
) => {
  return [
    {
      value: 'NOT_SET',
      description,
    },
    ...options,
  ];
};

let ScheduleForm = ({ event, emails, handleSubmit, onCancel }) => {
  const options = withPlaceholderOption(
    emails.map(email => ({
      value: email.id,
      description: email.subject,
    }))
  );
  const timePeriodUnitOptions = withPlaceholderOption(
    ['Minutes', 'Hours', 'Days'].map(unit => ({
      value: unit,
      description: unit,
    }))
  );
  const beforeAfterOptions = withPlaceholderOption(
    ['Before', 'After'].map(v => ({
      value: v,
      description: v,
    }))
  );

  return (
    <Form
      handleSubmit={handleSubmit}
      cancelBtn={true}
      onCancel={onCancel}
      formTitle='New Email Schedule'
    >
      <Field
        label='Email Template'
        name='emailId'
        component={FormFieldSelect}
        options={options}
        type='select'
        hintBefore={
          <>
            Select one of your previously created Email Templates from the list
            below or{' '}
            <Link
              to='/emails'
              style={{
                textDecoration: 'none',
                width: '100%',
                color: '#399ba2',
              }}
            >
              Create a new Email Template
            </Link>
          </>
        }
      />

      <FormSectionHeader title='Email Send Time' />
      <div className='event-email-schedule-form'>
        <Field
          label='Unit'
          name='timePeriod'
          type='text'
          component={FormField}
          placeholder='1'
        />
        <Field
          label='Minutes/ Hours / Days'
          name='timePeriodUnit'
          component={FormFieldSelect}
          options={timePeriodUnitOptions}
        />
        <Field
          label='Before / After the performance start date and time'
          name='beforeAfter'
          component={FormFieldSelect}
          options={beforeAfterOptions}
        />
      </div>
    </Form>
  );
};

const stateFormName = 'eventEmailScheduleForm';

ScheduleForm = reduxForm({
  form: stateFormName,
  validate,
})(ScheduleForm);

export default ScheduleForm;
