import * as types from './types';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { createAction } from 'redux-actions';
export const fetchTicket = createRoutine(types.FETCH_TICKET);
export const fetchTicketsForPage = createRoutine(types.FETCH_TICKETS);
export const createTicket = createRoutine(types.CREATE_TICKET);
export const deleteTicket = createRoutine(types.DELETE_TICKET);

export const sumbmitCreateTicketForm = promisifyRoutine(createTicket);
export const updateTicket = createRoutine(types.UPDATE_TICKET);
export const sumbmitUpdateTicketForm = promisifyRoutine(updateTicket);
export const fetchUserSettings = createRoutine(types.FETCH_USER_SETTINGS);
export const createTicketTransfer = createRoutine(types.CREATE_TICKET_TRANSFER);
export const submitCreateTicketTransfer = promisifyRoutine(
  createTicketTransfer
);
// ticket transfer modal actions
export const selectPerformanceforPurchase = createAction(
  types.SELECT_PERFORMANCE_FOR_PURCHASE
);
export const selectTicketforPurchase = createAction(
  types.SELECT_TICKET_FOR_PURCHASE
);
export const selectSeatforPurchase = createAction(
  types.SELECT_SEAT_FOR_PURCHASE
);
export const unselectSeatforPurchase = createAction(
  types.UNSELECT_SEAT_FOR_PURCHASE
);
