export const FETCH_EVENTS = 'line-up/event/FETCH_EVENTS';
export const FETCH_EVENT = 'line-up/event/FETCH_EVENT';
export const FETCH_EVENT_BY_SLUG = 'line-up/event/FETCH_EVENT_BY_SLUG';
export const FETCH_ALL_EVENTS = 'line-up/event/FETCH_ALL_EVENTS';
export const SEARCH_EVENTS = 'line-up/event/SEARCH_EVENTS';
export const CREATE_EVENT = 'line-up/event/CREATE_EVENT';
export const UPDATE_EVENT = 'line-up/event/UPDATE_EVENT';
export const GET_EVENT_INVITAIONS = 'line-up/event/GET_EVENT_INVITAIONS';
export const GET_EVENT_PERMISSIONS = 'line-up/event/GET_EVENT_PERMISSIONS';
export const CREATE_EVENT_INVITATION = 'line-up/event/CREATE_EVENT_INVITATION';
export const CREATE_EVENT_PERMISSION = 'line-up/event/CREATE_EVENT_PERMISSION';
export const DELETE_EVENT_INVITATION = 'line-up/event/DELETE_EVENT_INVITATION';
export const DELETE_EVENT_PERMISSION = 'line-up/event/DELETE_EVENT_PERMISSION';
export const UPDATE_EVENT_INVITATION = 'line-up/event/UPDATE_EVENT_INVITATION';
export const CREATE_BULK_UPLOAD = 'line-up/event/CREATE_BULK_UPLOAD';
export const SET_BULK_UPLOAD_FILE_DATA =
  'line-up/event/SET_BULK_UPLOAD_FILE_DATA';
