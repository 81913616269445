import React, { Component } from 'react';
/* eslint-disable react/style-prop-object */
import { FormattedDate, FormattedNumber } from 'react-intl';
import {
  ContentContainer,
  SubTitle,
  PageTop,
} from '../../../components/MainContent/MainContent';
import InfoBlock, {
  InfoBlockList,
  InfoBlockListItem,
  InfoBlockImage,
} from '../../../components/InfoBlock/InfoBlock';
import { productOptions } from '../../../utils/types';
// Grommet
import { Button } from 'grommet';
import { Edit } from 'grommet-icons';

class ProductInfo extends Component {
  render() {
    const { product } = this.props;
    const kind = productOptions.find(f => product.kind === f.value);
    return (
      <ContentContainer>
        <PageTop>
          <SubTitle title='Product Details'>
            <Button
              primary
              href={`/product/edit/${product.id}`}
              label='Edit Product'
              size='small'
              icon={<Edit size='small' />}
            />
          </SubTitle>
        </PageTop>

        <InfoBlock>
          <InfoBlockList>
            <InfoBlockListItem attribute='Title' value={product.title} />
            <InfoBlockListItem
              attribute='Description'
              value={product.description}
            />
            <InfoBlockListItem
              attribute='Type'
              value={kind ? kind.label : null}
            />
            <InfoBlockListItem
              attribute='Created'
              value={
                <FormattedDate
                  value={product.createdAt}
                  weekday='short'
                  year='numeric'
                  
                  day='numeric'
                  month='short'
                  hour='numeric'
                  minute='numeric'
                />
              }
            />
            <InfoBlockListItem
              attribute='Net'
              value={
                <FormattedNumber
                  value={product.price}
                  style='currency'
                  currency={product.currency}
                />
              }
            />
            <InfoBlockListItem
              attribute='Tax'
              value={
                <FormattedNumber
                  value={product.tax}
                  style='currency'
                  currency={product.currency}
                />
              }
            />
            <InfoBlockListItem
              attribute='Total'
              value={
                <FormattedNumber
                  value={product.total}
                  style='currency'
                  currency={product.currency}
                />
              }
            />
            {product.trackInventory && (
              <InfoBlockListItem
                attribute='Remaining'
                value={`${product.inventoryLeft}/${product.inventoryTotal}`}
              />
            )}
          </InfoBlockList>
        </InfoBlock>

        <SubTitle title={'Product Image'} />

        <InfoBlock>
          {product.image && (
            <InfoBlockImage
              src={product.image.url}
              alt={`image for ${product.title}`}
            />
          )}
        </InfoBlock>

        {/* {product.kind===productTypes.membership &&
            <Fragment>
            <SubTitle title={'Member Discounts'} />
            <InfoBlock>
            </InfoBlock>
          </Fragment>
          } */}
      </ContentContainer>
    );
  }
}

export default ProductInfo;
