import { call, takeLatest, put } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as donationTypes from './types';
import * as donationActions from './actions';
import * as api from '../../services/api';
import { fetchEntity } from '../shared/operations';

function* fetchDonations(action) {
  yield put(showLoading());
  yield put(donationActions.donations.request(action.payload.page));
  const { response, error } = yield call(api.getDonations, action.payload.page);
  if (response) {
    yield put(donationActions.donations.success(action.payload.page, response));
  } else {
    yield put(donationActions.donations.failure(action.payload.page, error));
  }
  yield put(hideLoading());
}

export function* watchLoadDonations() {
  yield takeLatest(donationTypes.LOAD_PAGE, fetchDonations);
}

const exportDonations = fetchEntity.bind(
  null,
  donationActions.exportDonations,
  api.exportDonations
);
export function* watchExportDonations() {
  yield takeLatest(donationActions.exportDonations, exportDonations);
}
