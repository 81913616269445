import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindPromiseCreators } from 'redux-saga-routines';
import { bindActionCreators } from 'redux';

// components
import { Title } from '../../../components/MainContent/MainContent';
import EventEmailScheduleForm, {
  toFormValues,
  toApiValues,
} from '../../../components/EventEmailSchedule/ScheduleForm';

// Grommet
import { Box } from 'grommet';

// other
import { emailSelectors, emailActions } from '../../../modules/emails/emails';
import {
  eventEmailScheduleActions,
  eventEmailScheduleSelectors,
} from '../../../modules/eventEmailSchedules/eventEmailSchedules';

const EventEmailScheduleEdit = ({
  id,
  fetchData,
  emails,
  eventEmailSchedule,
  submitUpdateEventEmailScheduleForm,
  fetchEventEmailSchedule,
  dispatch,
}) => {
  React.useEffect(() => {
    fetchData({ page: 1 });
    fetchEventEmailSchedule({ id });
  }, [fetchData, fetchEventEmailSchedule, id]);

  const handleSubmit = values => {
    const data = {
      ...toApiValues(values),
      id: eventEmailSchedule.id,
    };
    submitUpdateEventEmailScheduleForm(data).then(() => {
      dispatch(push(`/event/${eventEmailSchedule.eventId}/email-schedule`));
    });
  };

  const handleCancel = () => {
    dispatch(push(`/event/${eventEmailSchedule.eventId}/email-schedule`));
  };

  if (!emails || !eventEmailSchedule) {
    return null;
  }

  return (
    <div>
      <Box margin={{ bottom: 'large' }}>
        <Title>Edit Email Schedule</Title>
        <Box margin={{ vertical: 'medium' }}>
          <EventEmailScheduleForm
            initialValues={toFormValues(eventEmailSchedule)}
            emails={emails}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </Box>
      </Box>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const id = parseInt(match.params.id);
  return {
    emails: emailSelectors.getEntitiesForPage(state, 1),
    id: id,
    eventEmailSchedule: eventEmailScheduleSelectors.getEventEmailSchedule(
      state,
      id
    ),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        submitUpdateEventEmailScheduleForm:
          eventEmailScheduleActions.submitUpdateEventEmailSchedule,
      },
      dispatch
    ),
    ...bindActionCreators(
      { ...emailActions, ...eventEmailScheduleActions },
      dispatch
    ),
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventEmailScheduleEdit);
