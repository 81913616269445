import React from 'react';
import * as Yup from 'yup';
import FormikField from 'components/Form/FormikField';
import FormikForm from 'components/Form/FormikForm';
import FormField from 'components/Form/FormField/FormField';
import StyledLink from 'components/MainContent/StyledLink/StyledLink';

// Grommet
import { Box } from 'grommet';

const validationSchema = Yup.object({
  login: Yup.string()
    .email('Invalid email address')
    .required('An email address is required'),
  password: Yup.string().required('A password is required'),
});

const LoginForm = ({ onSubmit }) => {
  return (
    <>
      <FormikForm
        validationSchema={validationSchema}
        initialValues={{ login: '', password: '' }}
        onSubmit={onSubmit}
        cancelBtn={false}
        submitText='Login'
        formTitle='Login'
        hasborder
        heavypadding
        headercentred
        submitfull
      >
        <FormikField
          name='login'
          label='Email'
          component={FormField}
          type='email'
          placeholder='Your email address'
        />
        <FormikField
          name='password'
          label='Password'
          component={FormField}
          type='password'
          placeholder='Your password'
        />
        <div>
          <StyledLink to={'/user/forgot-password'} label='Forgot password?' />
        </div>
      </FormikForm>
      <Box margin={{ top: 'medium' }}>
        <StyledLink to={'/user/signup'} label='No account? Create one' />
      </Box>
    </>
  );
};

export default LoginForm;
