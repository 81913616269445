import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../../components/Modal/Modal';
import { hideModal } from '../../modules/modal/modal';
import InvoiceForm from '../../components/Invoice/InvoiceForm/InvoiceForm';

class InvoiceCreateModal extends Component {
  render() {
    return (
      <Modal onRequestClose={() => this.props.hideModal()}>
        <InvoiceForm
          onSubmit={this.props.onInvoiceCreateSubmit}
          onCancel={this.props.hideModal}
          onSubmitSuccess={() => this.props.hideModal()}
        />
      </Modal>
    );
  }
}
export default connect(
  null,
  {
    hideModal
  }
)(InvoiceCreateModal);
