import React, { useState, useEffect } from "react";
import { TableCell, TableRow } from "../Table/Table";
import Select from "../Select/Select";
import { Button } from "grommet";
import AsyncSelect from "react-select/lib/Async";
import { fetchTicketsForPage } from "../../services/api";
import { FormattedDate } from "react-intl";
import { getPerformanceStartDate } from "../../utils/dates";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllPerformances } from "modules/performances/actions";
import { getAllPerformances } from "modules/performances/selectors";

let TransferFormRow = (props) => {
  const {
    onTransferClicked,
    item,
    onSelectSeatsClick,
    onPerformanceChange,
    transferEntries,
    onTicketChange,
    loading,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchAllPerformances({
        eventId: item.eventId,
        gte: new Date().toISOString(),
      })
    );
  }, [item.eventId, dispatch]);

  const performances = useSelector(getAllPerformances);
  const selectedPerformance = transferEntries[item.purchaseId]
    ? transferEntries[item.purchaseId].selectedPerformance
    : null;

  const selectedTicket = transferEntries[item.purchaseId]
    ? transferEntries[item.purchaseId].selectedTicket
    : null;

  const selectedSeats = transferEntries[item.purchaseId]
    ? transferEntries[item.purchaseId].seats
    : [];

  const getPerformancesForSelect = (performanceId) => {
    const isReservedSeating = item.seats.length > 0;
    let filteredPerformances = performances;
    if (isReservedSeating) {
      filteredPerformances = performances.filter((p) => p.seatingChart);
    }
    const performanceOptions = filteredPerformances.map((p) => ({
      label: (
        <FormattedDate
          value={getPerformanceStartDate(p)}
          weekday="short"
          year="numeric"
          day="numeric"
          month="short"
          hour="numeric"
          minute="numeric"
        />
      ),
      value: p,
      isDisabled: p.id === performanceId,
    }));
    return performanceOptions;
  };
  const getTicketsDropDownOptions = () => {
    const tickets = fetchTicketsForPage({
      performanceId: selectedPerformance.id,
    }).then(({ response, error }) => {
      return error
        ? []
        : response.result.map((entry) => {
            let ticket = response.entities.ticket[entry];
            return {
              label: ticket.description,
              value: ticket,
            };
          });
    });
    return tickets;
  };

  return (
    <TableRow striped={true}>
      <TableCell width={20}>{item.description}</TableCell>
      <TableCell width={10}>{item.quantity}</TableCell>
      <TableCell width={10}>
        {item.seats.length > 0 ? item.seats.join(", ") : null}
      </TableCell>
      <TableCell width={20}>
        <Select
          onChange={(entry) =>
            onPerformanceChange(item.purchaseId, entry.value)
          }
          defaultOptions={true}
          filterOption={() => true}
          options={getPerformancesForSelect(item.performanceId)}
          isOptionDisabled={(option) => option.isDisabled}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          value={
            selectedPerformance
              ? {
                  label: (
                    <FormattedDate
                      value={getPerformanceStartDate(selectedPerformance)}
                      weekday="short"
                      year="numeric"
                      day="numeric"
                      month="short"
                      hour="numeric"
                      minute="numeric"
                    />
                  ),
                  value: selectedPerformance,
                }
              : ""
          }
        />
      </TableCell>
      <TableCell width={20}>
        {item.seats.length > 0 ? (
          <div>
            <Button
              secondary
              size="small"
              label="Select Seats"
              disabled={!selectedPerformance}
              onClick={(e) =>
                onSelectSeatsClick(
                  e,
                  selectedPerformance.id,
                  item.quantity,
                  item.purchaseId
                )
              }
            />
          </div>
        ) : (
          <AsyncSelect
            key={
              selectedPerformance
                ? JSON.stringify(selectedPerformance.id)
                : JSON.stringify(item.purchaseId)
            }
            name="ticketselect"
            onChange={(entry) => onTicketChange(item.purchaseId, entry.value)}
            isDisabled={!selectedPerformance}
            defaultOptions={true}
            filterOption={() => true}
            loadOptions={(inputValue, callback) => {
              return selectedPerformance
                ? getTicketsDropDownOptions()
                : callback([]);
            }}
            value={
              selectedTicket
                ? {
                    label: selectedTicket.description,
                    value: selectedTicket,
                  }
                : ""
            }
          />
        )}
      </TableCell>
      <TableCell width={20}>
        <Button
          disabled={!selectedPerformance || !selectedTicket || loading}
          secondary
          size="small"
          label="Exchange"
          onClick={() => {
            onTransferClicked(
              item.purchaseId,
              item.quantity,
              selectedTicket.id,
              selectedSeats
            );
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default TransferFormRow;
