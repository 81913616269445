import React, { Component } from 'react';
import MainContent, {
  Title,
  LineUpHelmet
} from '../../components/MainContent/MainContent';
import Table, { TableCell, TableHeader } from '../../components/Table/Table';
import styled from 'styled-components';

// Grommet
import { Anchor, Box } from 'grommet';
import { Share } from 'grommet-icons';

const TableBody = styled.div`
  display: table-row-group;
  position: relative;
`;

const APITableRow = styled.tr`
  background: white;
  border-bottom: 1px solid #eee;
`;

class APIKeys extends Component {
  render() {
    return (
      <>
        <LineUpHelmet title='API Keys' description='Your Line-Up API keys' />
        <MainContent>
          <Title>API Keys</Title>
          <Box margin={{ vertical: 'medium' }}>
            <Table>
              <TableHeader>
                <TableCell width={30}>Name</TableCell>
                <TableCell width={70}>Token</TableCell>
              </TableHeader>
              <TableBody>
                <APITableRow>
                  <TableCell>Public Key</TableCell>
                  <TableCell>
                    <code>{window.p_lukey}</code>
                  </TableCell>
                </APITableRow>
                <APITableRow>
                  <TableCell>Secret Key</TableCell>
                  <TableCell>
                    <code>{window.lukey}</code>
                  </TableCell>
                </APITableRow>
              </TableBody>
            </Table>
          </Box>
          <Box>
            <Anchor
              size='small'
              icon={<Share size='small' />}
              label='View the Line-Up API Docs'
              target='_blank'
              href='https://docs.lineupnow.com/'
            />
          </Box>
        </MainContent>
      </>
    );
  }
}

export default APIKeys;
