import { createRequestTypes } from '../shared/types';

const TICKET_RECEIPTS = createRequestTypes(
  'ticket-receipts',
  'TICKET_RECEIPTS'
);
const ALL_TICKET_RECEIPTS = createRequestTypes(
  'ticket-receipts',
  'ALL_TICKET_RECEIPTS'
);
const REDEEM_RECEIPT = createRequestTypes('ticket-receipts', 'REDEEM_RECEIPT');
const CHECKIN_USER = 'line-up/ticket-receipts/CHECKIN_USER';
const LOAD_PAGE = 'line-up/ticket-receipts/LOAD_PAGE';
const UPDATE_RECEIPT = 'line-up/ticket-receipts/UPDATE_RECEIPT';
const SEARCH_RECEIPTS = 'line-up/ticket-receipts/SEARCH_RECEIPTS';
export {
  TICKET_RECEIPTS,
  LOAD_PAGE,
  REDEEM_RECEIPT,
  CHECKIN_USER,
  UPDATE_RECEIPT,
  SEARCH_RECEIPTS,
  ALL_TICKET_RECEIPTS
};
