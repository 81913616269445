import React from 'react';
import { connect } from 'react-redux';
import { bindPromiseCreators } from 'redux-saga-routines';

import MainContent, { Title } from 'components/MainContent/MainContent';
import AccountNav from 'components/Account/AccountNav/AccountNav';
import AccountProfileForm from 'components/Account/AccountProfileForm/AccountProfileForm';
import { accountActions } from 'modules/account/account';
import useImageUpload from './useImageUploadHook';

// Grommet
import { Box } from 'grommet';

const AccountProfile = ({ user, submitUpdateUserProfile }) => {
  const imageUploadHook = useImageUpload(user.photo);

  const handleSubmit = (values, { setFormError }) => {
    return submitUpdateUserProfile(values).catch(error => console.log(error));
  };

  return (
    <MainContent>
      <Title>Your Account Profile</Title>
      <div>
        <Box margin={{ vertical: 'medium' }}>
          <AccountNav />
        </Box>
      </div>
      <div>
        <Box margin={{ bottom: 'large' }}>
          <AccountProfileForm
            uploader={imageUploadHook}
            user={user}
            onSubmit={handleSubmit}
          />
        </Box>
      </div>
    </MainContent>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.account.user.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        submitUpdateUserProfile: accountActions.submitUpdateUserProfile
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountProfile);
