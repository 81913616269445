import React from 'react';
import cx from 'classnames';

import './dropdown.svg';
import './flags.svg';
import './FormFieldCountrySelect.css';

const FormFieldCountrySelect = ({
  input,
  label,
  hideLabel,
  id,
  meta: { touched, error, value, warning },
  setFormikInputValue
}) => {
  const inputWrapClass = cx('country-select__wrap', {
    'form__field-input--error': touched && error,
    [input.value.toLowerCase()]: input.value ? true : false
  });
  return (
    <div className="form__row">
      <label className="form__label" hidden={hideLabel} htmlFor={id}>
        {label}
      </label>
      <div className={inputWrapClass}>
        <select {...input}>
          <option value="" disabled selected>
            Select country
          </option>
          <option value="AU">Australia</option>
          <option value="AT">Austria</option>
          <option value="BE">Belgium</option>
          <option value="BR">Brazil</option>
          <option value="CA">Canada</option>
          <option value="CN">China</option>
          <option value="DK">Denmark</option>
          <option value="FI">Finland</option>
          <option value="FR">France</option>
          <option value="DE">Germany</option>
          <option value="HK">Hong Kong</option>
          <option value="IE">Ireland</option>
          <option value="IT">Italy</option>
          <option value="JP">Japan</option>
          <option value="LU">Luxembourg</option>
          <option value="MX">Mexico</option>
          <option value="NL">Netherlands</option>
          <option value="NZ">New Zealand</option>
          <option value="NO">Norway</option>
          <option value="PT">Portugal</option>
          <option value="SG">Singapore</option>
          <option value="ES">Spain</option>
          <option value="SE">Sweden</option>
          <option value="CH">Switzerland</option>
          <option value="GB">United Kingdom</option>
          <option value="US">United States</option>
        </select>
      </div>
      {touched &&
        ((error && <span className="form__error">{error}</span>) ||
          (warning && <span className="form__error">{warning}</span>))}
    </div>
  );
};

export default FormFieldCountrySelect;
