import * as fromEntities from '../shared/entities';
import * as fromListIds from '../shared/listIds';
import { getPerformanceStartDate } from '../../utils/dates';
import Fuse from 'fuse.js';

export const getEvent = (state, id) =>
  fromEntities.getEntity(state.entities.event, id);

export const getEvents = state => {
  const ids = fromListIds.getIds(state.events.byId);
  return ids.map(id => getEvent(state, id));
};

const fuseOptions = {
  shouldSort: true,
  threshold: 0.2,
  tokenize: true,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['title'],
};
const getComparisonDate = e =>
  e.nextPerformance
    ? getPerformanceStartDate(e.nextPerformance)
    : e.lastPerformance
    ? getPerformanceStartDate(e.lastPerformance)
    : new Date(2001, 1, 1);
const now = new Date();
const getFilterEvents = state => {
  let list = getEvents(state);
  list.sort((a, b) => {
    if (getComparisonDate(a) > now && getComparisonDate(b) > now)
      return getComparisonDate(a) > getComparisonDate(b) ? 1 : -1;
    if (getComparisonDate(a) < getComparisonDate(b)) return 1;
    if (getComparisonDate(a) > getComparisonDate(b)) return -1;
    if (a.title > b.title) return 1;
    if (a.title < b.title) return -1;
    return 0;
  });
  const { searchTerm } = state.events;
  if (searchTerm) {
    const fuse = new Fuse(list, fuseOptions);
    list = fuse.search(searchTerm);
  }
  return list;
};

export const getEventsForPage = (state, page, perPage = 25) => {
  return getFilterEvents(state).slice((page - 1) * perPage, page * perPage);
};

export const getEventsTotalPages = (state, perPage = 25) =>
  Math.ceil(getFilterEvents(state).length / perPage);

export const getEventsLoadingStatus = state => state.events.loading;

export const getSearchTerm = state => state.events.searchTerm;

export const getEventInvitations = state => state.events.invitations;
export const getEventPermissions = state => state.events.permissions;

export const getBulkUploadFileData = state => state.events.bulkUploadFileData;
export const getCreateBulkUploadLoading = state =>
  state.events.createBulkUploadLoading;

export const getPublicEvent = state => state.events.publicEvent;
export const getPublicEventLoading = state => state.events.publicEventLoading;
