import React from 'react';
import { connect } from 'react-redux';
import { eventSelectors } from '../../../../modules/events/events';
import { Paragraph, Box, Button, Anchor, ResponsiveContext } from 'grommet';
import {
  SubTitle,
  PageTop
} from '../../../../components/MainContent/MainContent';
import EmptyState from '../../../../components/EmptyState/EmptyState';
import { Book, Download, Alert, Calendar, Upload } from 'grommet-icons';
import { useHistory } from 'react-router-dom';
import BulkUploadComplete from './empty_performances.png';
import BulkUploadError from './empty_error.png';
import BulkUploadLoading from './empty_loading.png';

// Assets
import Template from 'assets/Line-Up_bulk_upload_template.csv';
import TemplateChart from 'assets/Line-Up_bulk_upload_template_seat_chart.csv';

const BulkUpload = ({ eventId, loading }) => {
  let history = useHistory();
  if (loading.status == null) {
    history.push(`/event/${eventId}/bulkupload`);
    return null;
  }
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <PageTop>
            <SubTitle title='New Bulk Upload' />
          </PageTop>
          {loading && loading.status ? (
            <EmptyState
              background
              showButton={false}
              emptyStateHeadline='We are creating your performances and tickets.'
              emptyStateDescription='This should not take long...'
              emptyStateImageSrc={BulkUploadLoading}
              emptyStateImageAlt='Bulk Upload Loading'
              emptyStateImageTitle='Bulk Upload Loading'
            />
          ) : loading.errors ? (
            <EmptyState
              background
              buttonText='Try Again'
              buttonLink={`/event/${eventId}/bulkupload`}
              showButton={true}
              emptyStateHeadline='Oops, there seems to be a problem'
              emptyStateDescription={
                <>
                  {loading.errors._error}
                  <Paragraph size={size !== 'small' ? 'medium' : 'small'}>
                    <b>Some Tips to help:</b>
                  </Paragraph>
                  <Box gap='xsmall' direction='column'>
                    <Anchor
                      size={size !== 'small' ? 'medium' : 'small'}
                      margin={{ bottom: 'small' }}
                      label='Read our Bulk Upload troubleshooting guide'
                      href='/'
                      color='heading'
                      icon={<Alert size='small' />}
                    />

                    <Anchor
                      size={size !== 'small' ? 'medium' : 'small'}
                      margin={{ bottom: 'small' }}
                      label='Read our Step by Step guide to bulk upload'
                      href='https://support.lineupnow.com/hc/en-us/articles/360035613293-Creating-multiple-performances-tickets-using-spreadsheet-bulk-upload-no-seating-plan-'
                      target='_blank'
                      rel='noopener noreferrer'
                      color='heading'
                      icon={<Book size='small' />}
                    />

                    <Anchor
                      size={size !== 'small' ? 'medium' : 'small'}
                      margin={{ bottom: 'small' }}
                      label='Download our template schedule'
                      href={Template}
                      icon={<Download size='small' />}
                      color='heading'
                    />

                    <Anchor
                      size={size !== 'small' ? 'medium' : 'small'}
                      margin={{ bottom: 'small' }}
                      label='Download our template schedule for events with a seat chart'
                      href={TemplateChart}
                      icon={<Download size='small' />}
                      color='heading'
                    />
                  </Box>
                </>
              }
              emptyStateImageSrc={BulkUploadError}
              emptyStateImageAlt='Bulk Upload Error'
              emptyStateImageTitle='Bulk Upload Error'
            />
          ) : (
            <EmptyState
              background
              showButton={false}
              emptyStateHeadline='Processing Completed'
              emptyStateDescription={
                <>
                  <Paragraph size={size !== 'small' ? 'medium' : 'small'}>
                    All your performances and tickets have been created.
                  </Paragraph>
                  <Box gap='medium' margin={{ top: 'large' }}>
                    <Box align='center' gap='small' direction='row'>
                      <Button
                        primary
                        label='Go to Performances'
                        size='small'
                        href={`/event/${eventId}/performances`}
                        icon={<Calendar size='small' />}
                      />
                      <Button
                        primary
                        label='Another Bulk Upload'
                        size='small'
                        href={`/event/${eventId}/bulkupload`}
                        icon={<Upload size='small' />}
                      />
                    </Box>
                  </Box>
                </>
              }
              emptyStateImageSrc={BulkUploadComplete}
              emptyStateImageAlt='Bulk Upload Complete'
              emptyStateImageTitle='Bulk Upload Complete'
            />
          )}
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

const mapStateToProps = (state, ownProps) => {
  const eventId = parseInt(ownProps.match.params.eventId, 10);
  return { eventId, loading: eventSelectors.getCreateBulkUploadLoading(state) };
};

export default connect(mapStateToProps, {})(BulkUpload);
