import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import Modal from '../../../components/Modal/Modal';
import { hideModal } from '../../../modules/modal/modal';
import EditReceiptForm from '../../../components/Order/Edit/EditReceiptForm';
import { orderActions, orderSelectors } from '../../../modules/orders/orders';
class EditModal extends Component {
  handleCancelClick = () => {
    this.props.hideModal();
  };

  handleTicketReceiptSubmit = values => {
    const { tickets, products } = this.props.selectedReceipts;
    return this.props.sumbmitEditReceiptsForm({
      values: {
        ...values
      },
      ticketReceipts: [...tickets],
      productReceipts: [...products]
    });
  };

  handleSubmitSucces = () => {
    this.props.onSubmitSuccess && this.props.onSubmitSuccess();
    this.props.hideModal();
  };
  render() {
    return (
      <Modal onRequestClose={() => this.props.hideModal()}>
        <EditReceiptForm
          onSubmit={this.handleTicketReceiptSubmit}
          onSubmitSuccess={this.handleSubmitSucces}
          onCancel={this.props.hideModal}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedReceipts: orderSelectors.getSelectedReceipts(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        sumbmitEditReceiptsForm: orderActions.sumbmitEditReceiptsForm
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        hideModal
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditModal);
