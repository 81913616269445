import React from 'react';
import * as Yup from 'yup';
import { injectStripe } from 'react-stripe-elements';

import FormikForm from 'components/Form/FormikForm';
import CardSection from 'components/Billing/CardSection';
import AddressSection from 'components/Billing/AddressSection';
import { FormSection } from 'components/Form/Form';

const validationSchema = Yup.object({
  name: Yup.string().required('Please enter your name'),
  companyName: Yup.string(),
  vat: Yup.string(),
  street: Yup.string().required('The street address is required'),
  town: Yup.string().required('The town is required'),
  city: Yup.string().required('The city is required'),
  county: Yup.string().required('Your county is required'),
  country: Yup.string().required('Your country is required'),
  postcode: Yup.string().required('A postcode is required'),
  cardholderName: Yup.string().required("The card holder's name is required")
});

// stripe & elements are injected via the injectStripe HOC
const BillingForm = ({ onSubmit, stripe, elements }) => {
  const tokenizeCardDetails = (values, props) => {
    const extraStripeBillingData = {
      name: values.cardholderName
    };
    return stripe.createToken(extraStripeBillingData).then(response => {
      if (response.token) {
        onSubmit(
          {
            ...values,
            token: response.token
          },
          props
        );
      } else {
        props.setFieldError('card', response.error);
      }
    });
  };

  return (
    <FormikForm
      validationSchema={validationSchema}
      initialValues={{
        name: '',
        country: 'uk',
        companyName: '',
        vat: '',
        street: '',
        town: '',
        city: '',
        county: '',
        postcode: '',
        cardholderName: ''
      }}
      onSubmit={tokenizeCardDetails}
      cancelBtn={false}
      submitText="Add your payment information"
      formTitle="Complete your billing details"
    >
      <FormSection title="Billing address">
        <AddressSection />
      </FormSection>
      <FormSection title="Payment details">
        <CardSection />
      </FormSection>
    </FormikForm>
  );
};

export default injectStripe(BillingForm);
