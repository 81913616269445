import React from 'react';
import Form, { FormField, FormFieldReactSelect } from '../Form/Form';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Field, reduxForm } from 'redux-form';
import { float, required } from '../../utils/validators';

let IssueTicketBulkForm = props => {
  const {
    handleSubmit,
    agent,
    discount,
    onAgentSelect,
    onDiscountSelect,
    onClearAgent,
    onClearDiscount,
    onCancel,
    submitting,
    ticket,
    agents,
    discounts
  } = props;
  return (
    <Form
      handleSubmit={handleSubmit}
      submitting={submitting}
      onCancel={onCancel}
      formTitle="Bulk Issue Tickets"
      guidanceNotes="Use the form below to issue tickets from this allocation"
    >
      <Field
        name="data"
        label="Paste Data"
        component={FormField}
        type="textarea"
        rows="3"
        placeholder="first name, last name, quantity"
        validate={[required]}
      />
      <Field
        label="Agent"
        name="agent"
        component={FormFieldReactSelect}
        type="input"
        options={agents}
        onSelect={onAgentSelect}
        onClear={onClearAgent}
      />
      <Field
        label="Discount"
        name="discount"
        component={FormFieldReactSelect}
        type="input"
        options={discounts}
        onSelect={onDiscountSelect}
        onClear={onClearDiscount}
      />
      {!discount && (
        <Field
          name="price"
          component={FormField}
          label="Ticket Price"
          type="number"
          step="any"
          normalize={float}
          placeholder="E.g. 10"
          fieldShort={true}
        />
      )}
      {!agent && !discount && (
        <Field
          name="includeFee"
          labelAfter={`Include ${ticket.feeTotal} Booking Fee?`}
          id="includeFee"
          component={FormField}
          type="checkbox"
        />
      )}
    </Form>
  );
};

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector('issueTicketBulk');
  const agent = selector(state, 'agent');
  const discount = selector(state, 'discount');
  return {
    agent,
    discount
  };
};

IssueTicketBulkForm = reduxForm({
  form: 'issueTicketBulk'
})(IssueTicketBulkForm);

export default connect(mapStateToProps)(IssueTicketBulkForm);
