import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { change } from 'redux-form';
import { EventForm } from '../../components/Event/Event';
import MainContent, {
  LineUpHelmet,
  Title
} from '../../components/MainContent/MainContent';
import { eventActions } from '../../modules/events/events';
import { removeEmpty } from '../../utils/forms';
import { showModal } from '../../modules/modal/modal';

// Grommet
import { Box } from 'grommet';

class EventCreate extends Component {
  handleEventFormSubmit = values => {
    const eventValues = {
      ...removeEmpty(values),
      venue: {
        id: values.venue.value
      },
      tags: values.tags ? values.tags.map(tag => ({ name: tag.label })) : [],
      categories: values.categories
        ? values.categories.map(category => ({ name: category.label }))
        : []
    };
    return this.props.event
      ? this.props.onEventUpdateSubmit({
          eventId: this.props.event.id,
          ...eventValues
        })
      : this.props.onEventCreateSubmit(eventValues);
  };

  handleSubmitSuccess = (result, dispatch) => {
    const { response } = result;
    const { history } = this.props;
    if (response.result) {
      return history.push(`/event/${response.result}/performances`);
    }
    return history.push(`/events`);
  };
  handleOnCreateVenueClick = ev => {
    ev.preventDefault();
    this.props.showModal('CREATE_VENUE', {
      onVenueCreateSuccess: this.handleVenueCreateSuccess
    });
  };
  handleVenueCreateSuccess = response => {
    const venue = response.entities.venue[response.result];
    this.props.change('event', 'venue', {
      label: venue.name,
      value: response.result
    });
  };
  render() {
    return (
      <>
        <LineUpHelmet
          title='Create Event'
          description='Create a new event in Line-Up'
        />
        <MainContent>
          <Title>{this.props.event ? 'Edit Event' : 'Add Event'}</Title>
          <Box margin={{ bottom: 'medium' }}>
            <EventForm
              onSubmit={this.handleEventFormSubmit}
              event={this.props.event}
              onSubmitSuccess={this.handleSubmitSuccess}
              onCreateVenueClick={this.handleOnCreateVenueClick}
            />
          </Box>
        </MainContent>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        onEventCreateSubmit: eventActions.sumbmitCreateEventForm,
        onEventUpdateSubmit: eventActions.sumbmitUpdateEventForm
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        ...eventActions,
        showModal,
        change
      },
      dispatch
    )
  };
};

export default connect(null, mapDispatchToProps)(EventCreate);
