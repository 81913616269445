import React, { Fragment } from "react";
import FormField from "../Form/FormField/FormField";
import FormFieldColor from "../Form/FormFieldColor/FormFieldColor";
import Form from "../Form/Form";
import FormFieldArray from "../Form/FormFieldArray/FormFieldArray";
import { Field, FieldArray, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

const validate = (values) => {
  const errors = {};
  if (!values.mainColor) {
    errors.mainColor = "Required";
  }
  if (!values.backgroundColor) {
    errors.backgroundColor = "Required";
  }
  if (values.enableMultiEventCheckout && !values.listingsLink) {
    errors.listingsLink = {
      _error: "You must enter a link to the listings page on your site",
    };
  }
  if (values.enableLowInventoryWarning && !values.lowInventoryLevel) {
    errors.lowInventoryLevel = {
      _error: "You must enter a value",
    };
  }
  if (
    values.enableDonations &&
    (!values.donationOptions || !values.donationOptions.length)
  ) {
    errors.donationOptions = { _error: "You must enter donation options" };
  }
  if (
    values.enableDonations &&
    values.donationOptions &&
    values.donationOptions.length > 4
  ) {
    errors.donationOptions = {
      _error: "No more than 4 donation options allowed",
    };
  }
  if (
    values.enableDonations &&
    values.donationOptions &&
    values.donationOptions.length
  ) {
    const donationArrayErrors = [];
    values.donationOptions.forEach((option, index) => {
      if (!option) {
        donationArrayErrors[index] = "Required";
      }
    });
    if (donationArrayErrors.length) {
      errors.donationOptions = donationArrayErrors;
    }
  }
  if (values.enableGiftAid && !values.charityName) {
    errors.charityName =
      "You must provide a charity name to collect enable gift aid";
  }
  return errors;
};

let TicketEmbedSettingsForm = (props) => {
  const {
    enableDonations,
    enableGiftAid,
    enableMultiEventCheckout,
    enableLowInventoryWarning,
    handleSubmit,
    reset,
  } = props;
  return (
    <Form
      handleSubmit={handleSubmit}
      onCancel={reset}
      formTitle="Customise"
      guidanceNotes="Use the form below to customise your ticketing widget for this event"
      submitText="Update"
    >
      <Field
        name="mainColor"
        label="Main Color"
        labelInline={true}
        fieldInline={true}
        component={FormFieldColor}
        type="text"
      />
      <Field
        name="backgroundColor"
        label="Background Color"
        labelInline={true}
        fieldInline={true}
        component={FormFieldColor}
        type="text"
      />
      <Field
        name="includePhone"
        labelAfter="Include Telephone Field on Checkout?"
        component={FormField}
        type="checkbox"
      />
      <Field
        name="includeAddress"
        labelAfter="Include Address Fields on Checkout?"
        component={FormField}
        type="checkbox"
      />
      <Field
        name="optInText"
        label="Opt In Text"
        component={FormField}
        type="textarea"
      />
      <Field
        name="showWaitingList"
        labelAfter="Enable Waiting List for Event?"
        component={FormField}
        type="checkbox"
      />
      <Field
        name="enableLowInventoryWarning"
        labelAfter="Show x tickets remaining message?"
        component={FormField}
        type="checkbox"
      />
      {enableLowInventoryWarning && (
        <Field
          name="lowInventoryLevel"
          label="Show message when tickets remaining reaches"
          component={FormField}
          type="number"
        />
      )}
      <Field
        name="enableMultiEventCheckout"
        labelAfter="Enable Multi Event Checkout?"
        component={FormField}
        type="checkbox"
      />
      {enableMultiEventCheckout && (
        <Field
          name="listingsLink"
          label="Link to event listings page"
          component={FormField}
          type="url"
        />
      )}
      <Field
        name="enableDonations"
        labelAfter="Enable Donations?"
        component={FormField}
        type="checkbox"
      />
      {enableDonations && (
        <Fragment>
          <FieldArray
            name="donationOptions"
            label="Donations"
            component={FormFieldArray}
            type="number"
            step="any"
            addItemText="Add Donation Option"
          />
          <Field
            name="donationText"
            label="Donation request text"
            component={FormField}
            type="text"
          />
          <Field
            name="enableGiftAid"
            labelAfter="Enable Gift Aid?"
            component={FormField}
            type="checkbox"
          />
          {enableGiftAid && (
            <Field
              name="charityName"
              label="Charity Name"
              component={FormField}
              type="text"
            />
          )}
        </Fragment>
      )}
      <Field
        name="gaId"
        label="Google Analytics ID"
        component={FormField}
        type="text"
      />
    </Form>
  );
};

TicketEmbedSettingsForm = reduxForm({
  form: "embedSettings",
  validate,
})(TicketEmbedSettingsForm);

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector("embedSettings");
  const enableDonations = selector(state, "enableDonations");
  const enableMultiEventCheckout = selector(state, "enableMultiEventCheckout");
  const enableGiftAid = selector(state, "enableGiftAid");
  const enableLowInventoryWarning = selector(
    state,
    "enableLowInventoryWarning"
  );
  return {
    enableDonations,
    enableGiftAid,
    enableMultiEventCheckout,
    enableLowInventoryWarning,
    initialValues: state.eventEmbedSettings.data,
    enableReinitialize: true,
  };
};

export default connect(mapStateToProps)(TicketEmbedSettingsForm);
