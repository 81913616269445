import { createAction } from 'redux-actions';
import * as types from './types';

//- Actions

export const loadTicketReceiptsPage = createAction(
  types.LOAD_PAGE,
  (performanceId, page) => ({
    performanceId,
    page
  })
);

export const ticketReceipts = {
  request: createAction(
    types.TICKET_RECEIPTS.REQUEST,
    (performanceId, page) => ({ performanceId, page })
  ),
  success: createAction(types.TICKET_RECEIPTS.SUCCESS, (page, response) => ({
    page,
    response
  })),
  failure: createAction(types.TICKET_RECEIPTS.FAILURE, (page, error) => ({
    page,
    error
  }))
};

export const allTicketReceipts = {
  request: createAction(types.ALL_TICKET_RECEIPTS.REQUEST, performanceId => ({
    performanceId
  })),
  success: createAction(types.ALL_TICKET_RECEIPTS.SUCCESS, performanceId => ({
    performanceId
  })),
  failure: createAction(types.ALL_TICKET_RECEIPTS.FAILURE, error => ({
    error
  }))
};

export const checkinUser = createAction(types.CHECKIN_USER, receipt => ({
  receipt
}));

export const redeemReceipt = {
  request: createAction(types.REDEEM_RECEIPT.REQUEST, receiptId => ({
    receiptId
  })),
  success: createAction(types.REDEEM_RECEIPT.SUCCESS, response => ({
    response
  })),
  failure: createAction(types.REDEEM_RECEIPT.FAILURE, error => ({
    error
  }))
};

export const updateReceipt = createAction(types.UPDATE_RECEIPT, response => ({
  response
}));

export const searchReceipts = createAction(
  types.SEARCH_RECEIPTS,
  searchTerm => ({
    searchTerm
  })
);
