import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import MailchimpListSelect from '../ListSelect/ListSelect';
import Form from '../../Form/Form';

const validate = values => {
  let errors = {};
  if (values.mainListId === 'NOT_SELECTED' || !values.mainListId) {
    errors.mainListId = 'Please select a list to sync to';
  }
  return errors;
};

let EventAttendeeSyncForm = props => {
  const { handleSubmit, lists, submitting } = props;
  return (
    <Form
      handleSubmit={handleSubmit}
      formTitle='Export All Attendees to a Mailchimp Audience (List)'
      submitText='Export Attendees Now'
      cancelBtn={false}
      submitting={submitting}
      guidanceNotes='This is a one off export to Mailchimp of all event attendees. For constant sync between your Line-Up and Mailchimp account, go to the account-level Mailchimp settings.'
    >
      <MailchimpListSelect lists={lists} />
    </Form>
  );
};

const stateFormName = 'mailchimpEventAttendeeSyncForm';

EventAttendeeSyncForm = reduxForm({
  form: stateFormName,
  validate,
})(EventAttendeeSyncForm);

export default connect()(EventAttendeeSyncForm);
