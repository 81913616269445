// acts as a index file to this module
// default export should be the reducer for this module
// operations (aka sagas), actions, selectors should also be exported here

import * as mailchimpOperations from './operations';
import * as mailchimpActions from './actions';
import * as mailchimpSelectors from './selectors';

import moduleReducer from './reducers';

export { mailchimpOperations, mailchimpActions, mailchimpSelectors };

export default moduleReducer;
