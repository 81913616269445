import React from 'react';
import { plans, row1, row2, row3 } from './PricingTableData';
import Table, {
  TableRow,
  TableHeader,
  TableCell
} from 'components/Table/Table';
import styles from './PricingTable.module.css';

const FeatureRow = ({ plans, feature }) => {
  let rowCells = [];
  rowCells.push(<TableCell key="featureName">{feature.name}</TableCell>);

  for (const plan of plans) {
    const featureForPlan = feature.plan[plan.name.toLowerCase()];
    const cellKey = `${feature.name}_${plan.name}`;
    if (featureForPlan === undefined || featureForPlan === true) {
      rowCells.push(
        <TableCell key={cellKey}>
          <i className="fa fa-fw fa-check" />
        </TableCell>
      );
    } else {
      if (featureForPlan === false) {
        rowCells.push(<TableCell key={cellKey}>-</TableCell>);
      } else {
        rowCells.push(<TableCell key={cellKey}>{featureForPlan}</TableCell>);
      }
    }
  }

  return <TableRow>{rowCells}</TableRow>;
};

const PriceRow = ({ plans }) => (
  <TableRow>
    <TableCell>Price</TableCell>
    {plans.map(plan => (
      <TableCell key={plan.name} className={styles.price}>
        {plan.textPrice ? (
          plan.textPrice
        ) : (
          <>
            <sup className={styles.currency}>£</sup>
            <span className={styles.amount}>{plan.amount}</span>
          </>
        )}
      </TableCell>
    ))}
  </TableRow>
);

const Divider = ({ children }) => (
  <TableRow className={styles.divider}>
    <TableCell>{children}</TableCell>
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
  </TableRow>
);

const PricingTable = ({ subscription }) => {
  return (
    <div className={styles.root}>
      <Table>
        <TableHeader className={styles.divider}>
          <TableCell />
          {plans.map(plan => (
            <TableCell key={plan.name}>{plan.name}</TableCell>
          ))}
        </TableHeader>
        <div className="lutable__row-group">
          <PriceRow plans={plans} />
          {row1.map(feature => (
            <FeatureRow key={feature.name} plans={plans} feature={feature} />
          ))}
          <Divider>Transaction fees</Divider>
          {row2.map(feature => (
            <FeatureRow key={feature.name} plans={plans} feature={feature} />
          ))}
          <Divider>Features</Divider>
          {row3.map(feature => (
            <FeatureRow key={feature.name} plans={plans} feature={feature} />
          ))}
        </div>
      </Table>
      <p className={styles.smallPrint}>
        Prices exclude VAT. Additional payment processing fees apply, dependent
        on your location. For European customers, payment processing is 1.4% +
        20p per transaction, charged to you directly by Stripe, our payment
        processor.
      </p>
    </div>
  );
};

export default PricingTable;
