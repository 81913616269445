export const FETCH_EVENT_EMAIL_SCHEDULES =
  'line-up/email/FETCH_EVENT_EMAIL_SCHEDULES';
export const FETCH_EVENT_EMAIL_SCHEDULE =
  'line-up/email/FETCH_EVENT_EMAIL_SCHEDULE';
export const UPDATE_EVENT_EMAIL_SCHEDULE =
  'line-up-email/UPDATE_EVENT_EMAIL_SCHEDULE';
export const CREATE_EVENT_EMAIL_SCHEDULE =
  'line-up-email/CREATE_EVENT_EMAIL_SCHEDULE';
export const DELETE_EVENT_EMAIL_SCHEDULE =
  'line-up-email/DELETE_EVENT_EMAIL_SCHEDULE';
