import React from "react";
import {
  aggregatorTemplates as tpl,
  numberFormat,
} from "../../../components/PivotTable/Utilities";
import PivotTable from "../../../components/PivotTable/PivotTable";
import ReportSection from "../../../components/ReportSection/ReportSection";

const DiscountUsage = ({ data, title, exportFilename }) => {
  const usFmtInt = numberFormat({ digitsAfterDecimal: 0 });
  const fmt = numberFormat({ prefix: "£" });
  return (
    <ReportSection>
      <PivotTable
        title={title}
        exportFilename={exportFilename}
        data={data}
        rows={["Discount Code"]}
        aggregators={{
          "Uses (Tickets)": () => tpl.sum(usFmtInt)(["Quantity"]),
          "Uses (Transactions)": () => tpl.countUnique(usFmtInt)(["Order Ref"]),
          "Discount Amount": () => tpl.sum(fmt)(["Discount"]),
          "Ticket Revenue": () => tpl.sum(fmt)(["Gross"]),
          "Gross Potential": () => tpl.sum(fmt)(["Gross Potential"]),
        }}
        derivedAttributes={{
          "Gross Potential": (record) => record["Discount"] + record["Gross"],
        }}
        valueFilter={{
          "Discount Code": { "": false },
          "Transaction Type": { Refund: false },
        }}
      />
    </ReportSection>
  );
};

export default DiscountUsage;
