import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  ticketGroupActions,
  ticketGroupSelectors
} from '../../modules/ticketGroups/ticketGroups';
import MainContent, {
  ContentContainer,
  Title,
  SubTitle,
  ContentType
} from '../../components/MainContent/MainContent';
import InfoBlock, {
  InfoBlockList,
  InfoBlockListItem,
  InfoBlockText
} from '../../components/InfoBlock/InfoBlock';
import TicketList from '../../components/Ticket/TicketList';
import { showModal } from '../../modules/modal/modal';
import Pagination from '../../components/Pagination/Pagination';

// Grommet
import { Box, Button } from 'grommet';
import { Edit, Multiple } from 'grommet-icons';

class TicketGroupList extends Component {
  componentWillMount() {
    this.props.loadTicketGroup(this.props.ticketGroupId);
    this.props.loadTicketGroupTickets(this.props.ticketGroupId);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.ticketGroupId !== nextProps.ticketGroupId) {
      this.props.loadTicketGroup(nextProps.ticketGroupId);
      this.props.loadTicketGroupTickets(nextProps.ticketGroupId);
    }
    if (nextProps.currentPage > nextProps.ticketsTotalPages) {
      nextProps.selectTicketGroupTicketsPage(
        nextProps.ticketGroupId,
        nextProps.ticketsTotalPages
      );
    }
  }

  handleAddTicketClick = () => {
    const modalProps = {
      ticketGroupId: this.props.ticketGroupId,
      handleBtnClick: this.handleAddRemoveTicket
    };
    this.props.showModal('SELECT_TICKETS', modalProps);
  };

  handleAddRemoveTicket = ticketId => {
    this.props.removeTicket(this.props.ticketGroupId, ticketId);
  };

  handleSelectTicketsPage = page => {
    this.props.selectTicketGroupTicketsPage(this.props.ticketGroupId, page);
  };

  render() {
    const {
      ticketGroup,
      tickets,
      ticketsTotalPages,
      currentPage,
      ticketsLoading
    } = this.props;
    return (
      <MainContent>
        <ContentType icon={<Multiple size="small" />} label="Ticket Group" />
        <Box
          direction="row"
          justify="between"
          align="center"
          margin={{ top: 'small' }}
        >
          <Title>{ticketGroup.name}</Title>
        </Box>
        <Box margin={{ vertical: 'medium' }}>
          <ContentContainer>
            <SubTitle title="Ticket Group Details" />
            <InfoBlock>
              <InfoBlockList>
                <InfoBlockListItem attribute="Name" value={ticketGroup.name} />
                <InfoBlockListItem
                  attribute="Description"
                  value={ticketGroup.description}
                />
              </InfoBlockList>
            </InfoBlock>
            <SubTitle title="Tickets">
              <Button
                secondary
                icon={<Edit size="small" />}
                label="Add/Remove Tickets"
                size="small"
                onClick={() =>
                  this.props.history.push(
                    `/ticket-group/${ticketGroup.id}/tickets`
                  )
                }
              />
            </SubTitle>
            <InfoBlock>
              {!ticketsLoading && tickets.length > 0 ? (
                <TicketList
                  tickets={tickets}
                  onBtnClick={this.handleAddRemoveTicket}
                />
              ) : (
                <InfoBlockText>
                  There are no tickets in this ticket group
                </InfoBlockText>
              )}
              <Pagination
                totalPages={ticketsTotalPages}
                currentPage={currentPage}
                onSelectPage={this.handleSelectTicketsPage}
              />
            </InfoBlock>
          </ContentContainer>
        </Box>
      </MainContent>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const ticketGroupId = parseInt(match.params.ticketGroupId, 10);
  const perPage = 5;
  const ticketsTotalPages = ticketGroupSelectors.getTicketGroupTicketsTotalPages(
    state,
    ticketGroupId,
    perPage
  );
  const currentPage = ticketGroupSelectors.getTicketGroupTicketsCurrentPage(
    state,
    ticketGroupId
  );
  return {
    ticketGroupId,
    ticketGroup: ticketGroupSelectors.getTicketGroup(state, ticketGroupId),
    tickets: ticketGroupSelectors.getTicketGroupTickets(
      state,
      ticketGroupId,
      perPage
    ),
    ticketGroupTicketIds: ticketGroupSelectors.getTicketIds(
      state,
      ticketGroupId
    ),
    ticketsLoading: ticketGroupSelectors.getTicketLoadingStatus(
      state,
      ticketGroupId
    ),
    ticketsTotalPages,
    currentPage
  };
};

export default connect(mapStateToProps, {
  ...ticketGroupActions,
  showModal
})(TicketGroupList);
