import React, { Component } from 'react';
import { connect } from 'react-redux';
/* eslint-disable react/style-prop-object */
import MainContent, {
  Title,
  HeaderActions
} from '../../components/MainContent/MainContent';
import {
  ticketGroupActions,
  ticketGroupSelectors
} from '../../modules/ticketGroups/ticketGroups';
import TicketSearchList from '../../components/Ticket/TicketSearchList';

// Grommet
import { Box, Button, Text } from 'grommet';
import { Checkmark } from 'grommet-icons';

class TicketGroupList extends Component {
  componentWillMount() {
    this.props.loadTicketGroup(this.props.ticketGroupId);
    this.props.loadTicketGroupTickets(this.props.ticketGroupId);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.ticketGroupId !== nextProps.ticketGroupId) {
      this.props.loadTicketGroup(nextProps.ticketGroupId);
      this.props.loadTicketGroupTickets(nextProps.ticketGroupId);
    }
  }
  handleAddRemoveTicket = ticketId => {
    if (this.props.ticketGroupTicketIds.find(id => id === ticketId)) {
      this.props.removeTicket(this.props.ticketGroupId, ticketId);
    } else {
      this.props.addTicket(this.props.ticketGroupId, ticketId);
    }
  };
  render() {
    const { ticketGroup, ticketGroupTicketIds } = this.props;
    return (
      <MainContent>
        <Box
          direction="row"
          justify="between"
          align="center"
          margin={{ vertical: 'medium' }}
        >
          <Title>{`Add/Remove Tickets for ${ticketGroup.name}`}</Title>
          <HeaderActions>
            <Button
              primary
              href={`/ticket-group/${ticketGroup.id}`}
              label="Done"
              size="small"
              icon={<Checkmark size="small" />}
            />
          </HeaderActions>
        </Box>
        <Text size="small">
          Click the '+' button on the ticket row in the table below to include
          the ticket in this ticket group.
        </Text>
        <Box margin={{ vertical: 'medium' }}>
          <TicketSearchList
            onBtnClick={this.handleAddRemoveTicket}
            ticketIds={ticketGroupTicketIds}
          />
        </Box>
      </MainContent>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const ticketGroupId = parseInt(ownProps.match.params.ticketGroupId, 10);
  return {
    ticketGroupTicketIds: ticketGroupSelectors.getTicketIds(
      state,
      ticketGroupId
    ),
    ticketGroup: ticketGroupSelectors.getTicketGroup(state, ticketGroupId),
    ticketGroupId
  };
};

export default connect(mapStateToProps, {
  ...ticketGroupActions
})(TicketGroupList);
