import { combineReducers } from 'redux';
import * as actions from './actions';
//- Reducers

const byId = (state = [], action) => {
  switch (action.type) {
    case actions.fetchAllSeatingCharts.TRIGGER:
      return [];
    case actions.fetchSeatingCharts.SUCCESS:
      return action.payload.response.result;
    case actions.fetchAllSeatingCharts.FAILURE:
      return [];
    case actions.deleteSeatingChart.SUCCESS:
      return state.filter((item, index) => item !== action.payload.response.id);
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case actions.fetchAllSeatingCharts.TRIGGER:
      return true;
    case actions.fetchAllSeatingCharts.SUCCESS:
    case actions.fetchAllSeatingCharts.FAILURE:
      return false;
    default:
      return state;
  }
};
const seatingUser = (
  state = {
    data: null,
  },
  action
) => {
  switch (action.type) {
    case actions.getSeatingUser.SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
      };
    }
    default:
      return state;
  }
};

const events = combineReducers({
  byId,
  loading,
  seatingUser,
});

export default events;
