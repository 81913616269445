import React, { Component } from 'react';
import LUTable from '../../components/Table/LUTable';
import Pagination from '../../components/Pagination/Pagination';
import Spinner from 'react-spinkit';
import './TableView.css';

class TableView extends Component {
  componentWillMount() {
    this.fetch(this.props.currentPage);
  }
  componentDidUpdate(prevProps) {
    if (this.props.currentPage !== prevProps.currentPage) {
      this.fetch(this.props.currentPage);
    }
  }
  fetch = page => {
    this.props.fetchData && this.props.fetchData({ page: page });
  };

  handleSelectPage = page => {
    // this.props.history.push(`/discounts/${page}`);
  };

  handleSearchTextChange = e => {
    // const searchQuery = e.target.value;
    // if (this.props.currentPage !== 1) {
    //   this.props.history.push(`/discounts/1`);
    // }
    // this.props.searchDiscounts(searchQuery);
  };

  render() {
    const {
      columns,
      data,
      emptyStateComponent = <div>No Data</div>,
      isLoading,
      totalPages,
      currentPage,
      onPageClick,
      ...tableProps
    } = this.props;
    const isEmpty = !(data && data.length > 0);

    if (isLoading) {
      return (
        <Spinner
          name='three-bounce'
          color='rgba(57,155,162,1)'
          fade='none'
          className='loading-spinner'
        />
      );
    } else if (isEmpty) {
      return emptyStateComponent;
    }
    return (
      <>
        <LUTable mobileWide columns={columns} data={data} {...tableProps} />
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onSelectPage={onPageClick}
        />
      </>
    );
  }
}

export default TableView;
