import React from 'react';
import FormikField from 'components/Form/FormikField';
import FormikForm from 'components/Form/FormikForm';
import FormField from 'components/Form/FormField/FormField';
import { FormFieldReactSelect } from '../Form/Form';
import * as Yup from 'yup';
import { useFormikContext } from 'formik';

const VatNumber = () => {
  const { values } = useFormikContext();
  if (values.includeVat) {
    return (
      <FormikField
        name='vatNumber'
        label='VAT Number*'
        component={FormField}
        type='text'
        placeholder='VAT Number'
      />
    );
  }
  return null;
};

let BusinessSettingsForm = props => {
  const { onBusinessSettingsFormSubmit, businessSettings, currencies } = props;
  const address = businessSettings ? businessSettings.address : null;
  const validationSchema = Yup.object({
    company: Yup.string().nullable(true),
    includeVat: Yup.boolean(),
    vatNumber: Yup.string().nullable(true).when('includeVat', {
      is: true,
      then: Yup.string()
        .required('Vat Number is required')
        .min(9, 'Vat Number should be a minimum of length 9'),
    }),
    street: Yup.string().nullable(true),
    locality: Yup.string().nullable(true),
    region: Yup.string().nullable(true),
    county: Yup.string().nullable(true),
    country: Yup.string().required('Your country is required'),
    postcode: Yup.string().required('A postcode is required'),
    currency: Yup.string().required('Currency is required'),
  });
  return (
    <FormikForm
      validationSchema={validationSchema}
      initialValues={{
        country: businessSettings ? businessSettings.country : null,
        includeVat: businessSettings ? businessSettings.includeVat : false,
        currency: businessSettings ? businessSettings.currency : null,
        vatNumber: businessSettings ? businessSettings.vatNumber : null,
        company: businessSettings ? businessSettings.company : null,
        street: address ? address.street : null,
        region: address ? address.region : null,
        county: address ? address.county : null,
        locality: address ? address.locality : null,
        postcode: address ? address.postcode : null,
      }}
      onSubmit={onBusinessSettingsFormSubmit}
      cancelBtn={false}
      submitText='Submit'
      formTitle='Box Office Settings'
      enableReinitialize={true}
    >
      <FormikField
        name='country'
        component={FormFieldReactSelect}
        label='In what country will you be paid?*'
        isClearable={false}
        options={[
          { value: 'GB', label: 'United Kingdom' },
          { value: 'USA', label: 'United States' },
        ]}
      />
      <FormikField
        name='includeVat'
        labelAfter='Are you VAT registered?'
        component={FormField}
        type='checkbox'
      />
      <VatNumber />
      <FormikField
        name='currency'
        component={FormFieldReactSelect}
        label='The currency you want to sell the tickets in*'
        isClearable={false}
        options={currencies.map(currency => {
          return {
            value: currency.iso,
            label: `${currency.name} (${currency.symbol})`,
          };
        })}
      />
      <FormikField
        name='company'
        label='Company name'
        component={FormField}
        type='text'
        placeholder='Company name (optional)'
      />
      <FormikField
        name='street'
        label='Street'
        component={FormField}
        type='text'
        placeholder='Street Name'
      />
      <FormikField
        name='locality'
        label='Town'
        component={FormField}
        type='text'
        placeholder='Town'
      />
      <FormikField
        name='region'
        label='City'
        component={FormField}
        type='text'
        placeholder='City'
      />
      <FormikField
        name='county'
        label='County'
        component={FormField}
        type='text'
        placeholder='County'
      />
      <FormikField
        name='postcode'
        label='Postal code / ZIP code'
        component={FormField}
        type='text'
        placeholder='Postcode'
      />
    </FormikForm>
  );
};

export default BusinessSettingsForm;
