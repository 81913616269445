import React from 'react';
import { TableCell, TableRow } from '../../components/Table/Table';
// import Button from '../../components/Button/Button';
// Grommet
import { Box, Button } from 'grommet';

const TicketGroupRowItem = ({ ticketGroup, onClick, onBtnClick }) => {
  const handleClick = evt => {
    evt.preventDefault();
    onClick(ticketGroup.id);
  };

  return (
    <TableRow striped={true} onClick={handleClick}>
      <TableCell>{ticketGroup.name}</TableCell>
      <TableCell>{ticketGroup.description}</TableCell>
      <TableCell>
        <Box justify="end">
          <Button
            secondary
            onClick={handleClick}
            size="small"
            label="Edit Tickets"
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default TicketGroupRowItem;
