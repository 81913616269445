import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import ProductForm from '../../components/Product/ProductForm';
import MainContent, {
  LineUpHelmet,
} from '../../components/MainContent/MainContent';
import { productActions } from '../../modules/products/products';
import { removeEmpty } from '../../utils/forms';

// Grommet
import { Box } from 'grommet';

class ProductCreate extends Component {
  componentDidMount() {
    if (this.props.productId) {
      this.props.fetchProduct({ productId: this.props.productId });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.productId !== prevProps.productId) {
      this.props.fetchProduct({ productId: this.props.productId });
    }
  }

  handleProductFormSubmit = values => {
    const newProduct = {
      ...removeEmpty(values),
      kind: values.kind.value,
    };
    if (this.props.productId) {
      return this.props.onProductUpdateSubmit({
        productId: this.props.productId,
        ...newProduct,
      });
    }
    return this.props.onProductCreateSubmit(newProduct);
  };

  handleSubmitSuccess = () => {
    if (this.props.productId) {
      this.props.history.push(`/product/${this.props.productId}`);
    }
  };

  render() {
    return (
      <>
        <LineUpHelmet
          title='Create Product'
          description='Create a new product in Line-Up'
        />
        <MainContent>
          <Box margin={{ top: 'small', bottom: 'medium' }}>
            <ProductForm
              onSubmit={this.handleProductFormSubmit}
              productId={this.props.productId}
              onSubmitSuccess={this.handleSubmitSuccess}
            />
          </Box>
        </MainContent>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const productId = ownProps.match.params.productId
    ? parseInt(ownProps.match.params.productId, 10)
    : null;
  return {
    productId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        onProductCreateSubmit: productActions.sumbmitCreateProductForm,
        onProductUpdateSubmit: productActions.sumbmitUpdateProductForm,
        // other promise creators can be here...
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        ...productActions,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductCreate);
