import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Modal from 'components/Modal/Modal';
import BookingForm from 'components/Planner/BookingForm';

import { orderActions, orderSelectors } from 'modules/orders/orders';
import { showModal, hideModal } from 'modules/modal/modal';
import { useOrderFormResponses } from './orderFormResponseHook';
import {
  isLaneDisabledForDate,
  isLaneLockedForDate,
  canPlacePerformanceIntoLaneForDay,
} from 'containers/event/planner/layoutUtils';

const BookingModal = ({
  showModal,
  hideModal,
  event,
  placement,
  lanes,
  order,
  notes,
  initialValues,
  fetchOrder,
  fetchOrderNotes,
  onSubmit,
  currentSelectedDay,
  placements,
  selectedLane,
  onNoteSubmitSuccess,
}) => {
  const orderFormResponsesHook = useOrderFormResponses();
  React.useEffect(() => {
    fetchOrder({ orderId: placement.orderId });
    fetchOrderNotes({ orderId: placement.orderId });
    orderFormResponsesHook.fetchOrderFormResponses({
      orderId: placement.orderId,
    });
  }, [fetchOrder, fetchOrderNotes, placement.orderId]);

  if (!order || !order.id) {
    return null;
  }

  const handleCreateNote = () => {
    showModal('NOTE_MODAL', {
      orderId: order.id,
      onSubmitSuccess: onNoteSubmitSuccess,
    });
  };

  const handleEditNote = noteId => {
    const note = notes.find(note => note.id === noteId);
    showModal('NOTE_MODAL', {
      text: note.text,
      noteId: note.id,
      onSubmitSuccess: onNoteSubmitSuccess,
    });
  };

  const disabledLanes = lanes.filter(lane => {
    if (
      isLaneDisabledForDate(lane, currentSelectedDay) ||
      isLaneLockedForDate(lane, currentSelectedDay) ||
      !canPlacePerformanceIntoLaneForDay(
        lane,
        placement.eventPerformance,
        placements
      )
    ) {
      return true;
    }
    return false;
  });

  const canUpdateLane =
    !isLaneDisabledForDate(selectedLane, currentSelectedDay) &&
    !isLaneLockedForDate(selectedLane, currentSelectedDay);

  return (
    <Modal onRequestClose={() => hideModal()}>
      <BookingForm
        initialValues={initialValues}
        onCancel={hideModal}
        event={event}
        placement={placement}
        lanes={lanes}
        notes={notes}
        order={order}
        formResponses={orderFormResponsesHook.data}
        onEditNote={handleEditNote}
        onCreateNote={handleCreateNote}
        onSubmit={onSubmit}
        disabledLanes={disabledLanes}
        canUpdateLane={canUpdateLane}
      />
    </Modal>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        hideModal,
        showModal,
        ...orderActions,
      },
      dispatch
    ),
  };
};

const mapStateToProps = (state, ownProps) => {
  const selectedLane = ownProps.lanes.find(
    lane => lane.id === ownProps.placement.eventPlannerLaneId
  );
  const selectedLaneOption = {
    label: selectedLane.name,
    value: selectedLane.id,
  };
  const selectColourOption = {
    value: ownProps.placement.colour,
  };

  return {
    order: orderSelectors.getOrder(state, ownProps.placement.orderId),
    notes: orderSelectors.getNotesForOrder(state, ownProps.placement.orderId),
    initialValues: {
      lane: selectedLaneOption,
      colour: selectColourOption,
    },
    selectedLane,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingModal);
