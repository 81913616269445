import React from 'react';
import { Route } from 'react-router-dom';
import classNames from 'classnames';

import { Link } from 'react-router-dom';

// Grommet
import { Box, Text, ResponsiveContext } from 'grommet';

const PageNavLink = ({ label, to, activeOnlyWhenExact = true, className }) => {
  const itemClass = classNames('page-nav-menu__item', className);
  const itemClassSelected = classNames(
    'page-nav-menu__item',
    className,
    'page-nav-menu__item--selected'
  );
  const itemLabelClass = classNames('page-nav-menu__item-label', className);
  const itemLabelClassSelected = classNames(
    'page-nav-menu__item-label',
    className,
    'page-nav-menu__item--label-selected'
  );
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Route
          path={to}
          exact={activeOnlyWhenExact}
          children={({ match }) => (
            <>
              {size !== 'small' ? (
                <span className={match ? itemClassSelected : itemClass}>
                  <Link to={to} style={{ textDecoration: 'none' }}>
                    <Box
                      pad={{ horizontal: 'small', bottom: 'xsmall' }}
                      margin={{ horizontal: 'xsmall' }}
                    >
                      <Text color='heading' size='small'>
                        <span
                          className={
                            match ? itemLabelClassSelected : itemLabelClass
                          }
                        >
                          {label}
                        </span>
                      </Text>
                    </Box>
                  </Link>{' '}
                </span>
              ) : (
                <Link
                  to={to}
                  key={label}
                  style={{ textDecoration: 'none', width: '100%' }}
                >
                  <Text size='small' color='heading'>
                    {label}
                  </Text>
                </Link>
              )}
            </>
          )}
        />
      )}
    </ResponsiveContext.Consumer>
  );
};

export default PageNavLink;
