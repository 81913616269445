import { combineReducers } from 'redux';
import * as actions from './actions';

const integration = (
  state = {
    loading: false,
    fetched: false,
    connected: false,
    data: null,
  },
  action
) => {
  switch (action.type) {
    case actions.fetchMailchimpIntegration.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.fetchMailchimpIntegration.SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
        connected: action.payload.response.id ? true : false,
        connectUrl: action.payload.response.id
          ? null
          : action.payload.response.connectUrl,
      };
    }
    case actions.fetchMailchimpIntegration.FULFILL: {
      return {
        ...state,
        loading: false,
        fetched: true,
      };
    }
    default:
      return state;
  }
};

const lists = (
  state = {
    loading: false,
    fetched: false,
    data: null,
  },
  action
) => {
  switch (action.type) {
    case actions.fetchMailchimpLists.TRIGGER: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.fetchMailchimpLists.SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
      };
    }
    case actions.fetchMailchimpLists.FULFILL: {
      return {
        ...state,
        loading: false,
        fetched: true,
      };
    }
    default:
      return state;
  }
};

const reducer = combineReducers({
  integration: integration,
  lists: lists,
});

export default reducer;
