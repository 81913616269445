import React from 'react';
import { FormattedNumber } from 'react-intl';
/* eslint-disable react/style-prop-object */
const Percent = ({ numerator, denominator }) =>
  denominator > 0 && numerator > 0 ? (
    <FormattedNumber
      value={numerator / denominator}
      style="percent"
      maximumFractionDigits={2}
    />
  ) : (
    'N/A'
  );

export default Percent;
