import { createAction } from 'redux-actions';
import * as types from './types';

//- Actions

export const loadWaitingListPage = createAction(
  types.LOAD_PAGE,
  (performanceId, page) => ({
    performanceId,
    page
  })
);

export const waitingList = {
  request: createAction(types.WAITING_LIST.REQUEST, (performanceId, page) => ({
    performanceId,
    page
  })),
  success: createAction(
    types.WAITING_LIST.SUCCESS,
    (performanceId, page, response) => ({
      performanceId,
      page,
      response
    })
  ),
  failure: createAction(
    types.WAITING_LIST.FAILURE,
    (performanceId, page, error) => ({
      performanceId,
      page,
      error
    })
  )
};
