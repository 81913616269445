import { createAction } from 'redux-actions';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import * as types from './types';

export const fetchTicketAllocation = createRoutine(
  types.FETCH_TICKET_ALLOCATION
);
export const fetchTicketAllocationPage = createRoutine(
  types.FETCH_TICKET_ALLOCATIONS
);
export const createTicketAllocation = createRoutine(
  types.CREATE_TICKET_ALLOCATION
);
export const deleteTicketAllocation = createRoutine(
  types.DELETE_TICKET_ALLOCATION
);
export const sumbmitCreateTicketAllocationForm = promisifyRoutine(
  createTicketAllocation
);

export const updateTicketAllocation = createRoutine(
  types.UPDATE_TICKET_ALLOCATION
);
export const sumbmitUpdateTicketAllocationForm = promisifyRoutine(
  updateTicketAllocation
);

//- Actions
export const addCustomerData = createAction(
  types.ADD_CUSTOMER_DATA,
  purchasesData => ({ purchasesData })
);
export const addDiscount = createAction(types.ADD_DISCOUNT, discountId => ({
  discountId
}));
export const addAgent = createAction(types.ADD_AGENT, agentId => ({ agentId }));
export const removeDiscount = createAction(types.REMOVE_DISCOUNT);
export const removeAgent = createAction(types.REMOVE_AGENT);

export const issueTicket = createRoutine(types.ISSUE_TICKET);
export const sumbmitIssueTicketForm = promisifyRoutine(issueTicket);
