import React from 'react';
import cx from 'classnames';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'react-spinkit';

import '../FormImageField/FormImageField.css';

const FormikImageUploadField = ({
  hideLabel,
  name,
  label,
  placeholder,
  className,
  id,
  meta: { error, touched },
  uploader: { uploadFile, deleteFile, uploading, fileModel, acceptedFileTypes },
  setFormikInputError,
  setFormikInputValue,
}) => {
  const rowClass = cx(className, 'form__row');
  const labelClass = cx('form__label');

  const handleOnDrop = (files, rejectedFiles) => {
    if (!files || files.length === 0) {
      return;
    }
    const file = files[0];
    uploadFile(file)
      .then(newFileModel => {
        setFormikInputValue(newFileModel);
      })
      .catch(e => {
        setFormikInputError(e);
      });
  };

  const handleImageDelete = () => {
    deleteFile();
    setFormikInputValue(null);
  };

  return (
    <div className={rowClass}>
      {!hideLabel && (
        <label className={labelClass} htmlFor={id}>
          {label}
        </label>
      )}
      {fileModel ? (
        <div className='form-field__dropzone'>
          {uploading ? (
            <Spinner
              name='pulse'
              color='#333'
              fade='none'
              className='form-field__spinner'
            />
          ) : (
            <>
              <img
                alt='preview'
                className='form-field__preview'
                src={fileModel.url}
              />
              <FontAwesomeIcon
                icon='trash'
                className='form-field__dropzone-delete-icon'
                size='lg'
                onClick={handleImageDelete}
              />
            </>
          )}
        </div>
      ) : (
        <>
          <Dropzone
            multiple={false}
            name={name}
            accept={acceptedFileTypes}
            onDrop={handleOnDrop}
          >
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <div {...getRootProps()} className='form-field__dropzone'>
                  {uploading ? (
                    <Spinner
                      name='pulse'
                      color='#333'
                      fade='none'
                      className='form-field__spinner'
                    />
                  ) : (
                    <>
                      <span className='form-field__placeholder'>
                        {placeholder}
                      </span>
                      <input {...getInputProps()} />
                    </>
                  )}
                </div>
              );
            }}
          </Dropzone>
        </>
      )}
      {touched && (error && <div className='form__error'>{error}</div>)}
    </div>
  );
};

export default FormikImageUploadField;
