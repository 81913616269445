import React from 'react';
import classNames from 'classnames';
import './InfoBlockText.css';

const InfoBlockText = ({ children, className }) => {
  const infoBlockTextClass = classNames('info-block__text', className);
  return <span className={infoBlockTextClass}>{children}</span>;
};

export default InfoBlockText;
