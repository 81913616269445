import React from 'react';
import { FormattedDate } from 'react-intl';
import moment from 'moment';

const PendingSubscriptionChange = ({ subscription }) => {
  const startDate = moment(subscription.startAt).toDate();
  const subscriptionSummary = 'TOOD: Add a summary of the subscription here';

  return (
    <div>
      <p>
        You currently have a pending subscription change that will be applied at
        the start of the next billing cycle:
      </p>
      <p>{subscriptionSummary}</p>
      <p>
        Your subscription will change on{' '}
        <FormattedDate
          value={startDate}
          year='numeric'
          day='numeric'
          month='short'
          weekday='short'
        />
        .
      </p>
    </div>
  );
};

export default PendingSubscriptionChange;
