import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindPromiseCreators } from 'redux-saga-routines';
import { bindActionCreators } from 'redux';
import { SubmissionError } from 'redux-form';

import MainContent, {
  Title,
  LineUpHelmet,
} from 'components/MainContent/MainContent';

import { webhookActions, webhookSelectors } from 'modules/webhooks/webhooks';
import WebhookForm from 'components/WebhookForm/WebhookForm';
// Grommet
import { Box } from 'grommet';

const WebhookCreate = ({
  webhookId,
  webhook,
  fetchWebhook,
  webhookTypes,
  fetchWebhookTypes,
  submitCreateWebhookForm,
  submitEditWebhookForm,
  webhookTypeOptions,
  dispatch,
}) => {
  useEffect(() => {
    if (webhookId) {
      dispatch(fetchWebhook({ id: webhookId }));
    }
    if (!webhookTypes) {
      dispatch(fetchWebhookTypes());
    }
  }, [webhookId, webhookTypes, fetchWebhook, fetchWebhookTypes, dispatch]);

  const handleWebhookCreateSubmit = values => {
    return webhookId
      ? submitEditWebhookForm({
          id: webhookId,
          url: values.url,
          webhookEventTypeIds: values.types.map(type => type.value),
        })
      : submitCreateWebhookForm({
          url: values.url,
          webhookEventTypeIds: values.types.map(type => type.value),
        });
  };

  const handleSubmitSuccess = () => {
    dispatch(push('/tools/webhooks/1'));
  };

  if (webhookId && !webhook.id) {
    return null;
  }

  return (
    <>
      <LineUpHelmet
        title='New Webhook'
        description='Create a new webhook in Line-Up'
      />
      <MainContent>
        <div>
          <Box direction='row' justify='between' align='center'>
            <Title>{webhookId ? 'Edit Webhook' : 'New Webhook'}</Title>
          </Box>
          <Box margin={{ top: 'medium', bottom: 'large' }}>
            <WebhookForm
              webhookId={webhookId}
              onSubmit={handleWebhookCreateSubmit}
              webhookTypeOptions={webhookTypeOptions}
              onSubmitSuccess={handleSubmitSuccess}
            />
          </Box>
        </div>
      </MainContent>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const webhookId = match.params.webhookId
    ? parseInt(match.params.webhookId, 10)
    : null;
  const webhook = webhookSelectors.getWebhook(state, webhookId);
  const webhookTypes = webhookSelectors.getWebhookTypes(state);
  const webhookTypeOptions = webhookTypes.map(type => ({
    label: type.name,
    value: type.id,
  }));
  return { webhookId, webhook, webhookTypeOptions };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        submitCreateWebhookForm: webhookActions.submitCreateWebhook,
        submitEditWebhookForm: webhookActions.submitEditWebhook,
      },
      dispatch
    ),
    ...bindActionCreators(webhookActions, dispatch),
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WebhookCreate);
