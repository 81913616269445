const rules = {
  //owner
  255: {
    static: [
      'event:view',
      'event:edit',
      'event:edit:inventory',
      'event:performance:create',
      'event:view:guestlist:names',
      'event:view:guestlist:email',
      'event:manage-users',
      'event:view-financials'
    ]
  },
  //admin
  235: {
    static: [
      'event:view',
      'event:edit',
      'event:edit:inventory',
      'event:view:guestlist:names',
      'event:view:guestlist:email',
      'event:view-financials'
    ]
  },
  //Inventory Manager
  97: {
    static: ['event:edit:inventory', 'event:view:guestlist:names']
  },
  //Finalcial Reporting
  161: {
    static: ['event:view-financials']
  },
  //Api Agent
  9: {
    static: ['event:view']
  },
  //FOH
  41: {
    static: [
      'event:view',
      'event:view:guestlist:names',
      'event:view:guestlist:email'
    ]
  }
};
export default rules;
