import React from 'react';

// Grommet
import { Box, Heading, Image, ResponsiveContext, Text } from 'grommet';

import EventNotFound from './page_not_found.png';

const PageNotFound = () => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box>
          <Box align='center' justify='center' pad='large' gap='medium'>
            <Heading color='heading' level='2' size='medium' textAlign='center'>
              Oops...we can't find the page you're looking for
            </Heading>
            <Box
              width={
                size !== 'small'
                  ? { min: '35%', max: '35%' }
                  : { min: '300px', max: '300px' }
              }
            >
              <Image src={EventNotFound} fit='contain' fill />
            </Box>
            <Text color='copy'>
              Please use the menu to navigate to the area you're trying to find.
            </Text>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default PageNotFound;
