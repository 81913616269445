import React from 'react';
import { Tooltip } from 'react-tippy';

import './CheckinProgressBar.css';

const CheckinProgressBar = ({ items }) => {
  const colors = [
    { r: 57, g: 155, b: 162 },
    { r: 255, g: 201, b: 88 },
    { r: 255, g: 96, b: 88 },
    { r: 74, g: 102, b: 177 }
  ];

  const getTotalSold = () => {
    return Object.keys(items).reduce((total, key) => {
      return total + parseInt(items[key]['totalSold'], 10);
    }, 0);
  };

  const getTotalCheckin = () => {
    return Object.keys(items).reduce((total, key) => {
      return total + parseInt(items[key]['totalCheckedIn'], 10);
    }, 0);
  };

  const getBars = () => {
    const total = getTotalSold();
    let count = 0;
    return Object.keys(items).map((key, index) => {
      let width = (parseInt(items[key]['totalSold'], 10) / total) * 100;
      let innerWidth =
        (parseInt(items[key]['totalCheckedIn'], 10) /
          parseInt(items[key]['totalSold'], 10)) *
        100;
      if (count >= colors.length) {
        count = 1;
      }
      let color = colors[count];
      count += 1;
      return (
        <Tooltip
          className='progress-bar__tip'
          arrow={true}
          key={key}
          html={
            <div
              style={{
                fontFamily: 'Lato',
                fontWeight: 900,
                fontSize: '0.9rem'
              }}
            >
              {key}: {items[key]['totalCheckedIn']}/{items[key]['totalSold']}
            </div>
          }
          position='top'
          distance={20}
          style={{ fontFamily: 'Lato' }}
        >
          <span
            className='progress-bar__outer'
            style={{
              width: `${width}%`,
              backgroundColor: `rgb(${color['r']},${color['g']},${color['b']},0.1)`
            }}
          >
            <span
              className='progress-bar__inner'
              style={{
                width: `${innerWidth}%`,
                backgroundColor: `rgb(${color['r']},${color['g']},${color['b']})`
              }}
            />
          </span>
        </Tooltip>
      );
    });
  };

  return (
    <div className='checkin-counter'>
      <span className='checkin-counter__summary'>
        <span className='checkin-counter__title'>Check Ins</span>
        <span className='check-counter__total'>
          TOTAL {getTotalCheckin()}/{getTotalSold()}
        </span>
      </span>
      <div className='progress-bar'>{getBars()}</div>
    </div>
  );
};

export default CheckinProgressBar;
