import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { accountSelectors, accountActions } from 'modules/account/account';
import { useQuery } from 'utils/hooks';
// This container should wrap around other containers which require
// the user to have a valid session

const AuthenticationGuard = ({
  children,
  isAuthenticated,
  pathName,
  recoverAuth,
  reAuth,
  triedRecoveringAuth,
}) => {
  const loginRoute = '/user/login';
  const query = useQuery();
  const authToken = query.get('token');

  // try to get user auth from localstorage
  React.useEffect(() => {
    if (!isAuthenticated && !triedRecoveringAuth) {
      if (authToken) {
        reAuth({ authToken, redirect: pathName });
      } else {
        recoverAuth({ redirect: pathName });
      }
    }
  }, [
    recoverAuth,
    pathName,
    isAuthenticated,
    triedRecoveringAuth,
    authToken,
    reAuth,
  ]);

  // wait till we've tried to recover the user's auth
  if (!triedRecoveringAuth && !isAuthenticated) {
    return null;
  }

  // user still not authenticated
  if (!isAuthenticated && pathName !== loginRoute) {
    return (
      <Redirect
        to={{
          pathname: loginRoute,
          state: { referrer: pathName },
        }}
      />
    );
  }

  return <div>{children}</div>;
};

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: accountSelectors.isAuthenticated(state),
    pathName: state.router.location,
    triedRecoveringAuth: accountSelectors.triedRecoveringAuth(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        ...accountActions,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationGuard);
