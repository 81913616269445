export const FETCH_AGENT = 'line-up/agent/FETCH_AGENT';
export const FETCH_AGENTS = 'line-up/agent/FETCH_AGENTS';
export const CREATE_AGENT = 'line-up/agent/CREATE_AGENT';
export const UPDATE_AGENT = 'line-up/agent/UPDATE_AGENT';

export const FETCH_INVOICES = 'line-up/agent/FETCH_INVOICES';

export const FETCH_INVOICE_PDF = 'line-up/agent/FETCH_INVOICE_PDF';
export const DELETE_INVOICE = 'line-up/agent/DELETE_INVOICE';

export const CREATE_INVOICE = 'line-up/agent/CREATE_INVOICE';
