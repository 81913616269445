import React from 'react';
import * as Yup from 'yup';

import FormikField from 'components/Form/FormikField';
import FormikForm from 'components/Form/FormikForm';
import FormField from 'components/Form/FormField/FormField';
import UserRoles from './UserRoles';

const validationSchema = Yup.object({
  emailAddress: Yup.string()
    .email('Invalid email address')
    .required('An email address is required'),
});

const AddUserForm = ({ onAddUserFormSubmit }) => {
  return (
    <FormikForm
      validationSchema={validationSchema}
      initialValues={{
        emailAddress: '',
        role: '235',
      }}
      onSubmit={onAddUserFormSubmit}
      cancelBtn={false}
      submitText='Add User'
      formTitle='Add User to Event'
      guidanceNotes='Invite a user to manage this event via their email address. If they do not already have an account, they will be asked to create one.'
    >
      <FormikField
        name='emailAddress'
        label='Email address:'
        component={FormField}
        type='email'
        placeholder='e.g. eventadmin@myvenue.com'
      />
      <>
        {UserRoles.map((role, idx) => (
          <FormikField
            name='role'
            key={idx}
            value={role.value}
            labelAfter={role.label}
            hintAfter={role.description}
            component={FormField}
            type='radio'
          />
        ))}
      </>
    </FormikForm>
  );
};

export default AddUserForm;
