import { takeEvery, put, call, fork } from 'redux-saga/effects';
import * as api from '../../services/api';
import * as actions from './actions';
import { fetchEntity, submitForm } from '../shared/operations';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

const fetchWebhooks = fetchEntity.bind(
  null,
  actions.fetchWebhooks,
  api.fetchWebhooks
);

export function* watchFetchWebhooks() {
  yield takeEvery(actions.fetchWebhooks.TRIGGER, fetchWebhooks);
}

const fetchWebhookTypes = fetchEntity.bind(
  null,
  actions.fetchWebhookTypes,
  api.fetchWebhookTypes
);

export function* watchFetchWebhookTypes() {
  yield takeEvery(actions.fetchWebhookTypes.TRIGGER, fetchWebhookTypes);
}

const createWebhook = submitForm.bind(
  null,
  actions.createWebhook,
  api.createWebhook
);

export function* watchCreateWebhook() {
  yield takeEvery(actions.createWebhook.TRIGGER, createWebhook);
}

const updateWebhook = submitForm.bind(
  null,
  actions.updateWebhook,
  api.updateWebhook
);

export function* watchUpdateWebhook() {
  yield takeEvery(actions.updateWebhook.TRIGGER, updateWebhook);
}

const deleteWebhook = submitForm.bind(
  null,
  actions.deleteWebhook,
  api.deleteWebhook
);

export function* watchDeleteWebhook() {
  yield takeEvery(actions.deleteWebhook.TRIGGER, deleteWebhook);
}

const fetchWebhook = submitForm.bind(
  null,
  actions.fetchWebhook,
  api.fetchWebhook
);

export function* watchFetchWebhook() {
  yield takeEvery(actions.fetchWebhook.TRIGGER, fetchWebhook);
}
