import React from 'react';
import moment from 'moment';

import * as api from 'services/api';
import { getFirstFutureDate } from 'utils/dates';

// this should handle the data layer, ie fetch stuff from the server, and changes
// locally to the data

// fetching the parent planner data (columns, capacity) is done via the normal dispatch
// to sagas, and stored in the global app state under 'eventPlanner'.

// fetching planner placements, and syncing changes is done via api calls in hooks

const getDefaultState = () => ({
  // main planner model
  planner: null,
  fetchedPlanner: false,
  fetchingPlanner: false,

  event: null,

  // ui
  columnWidth: 250,
});

const PlannerContext = React.createContext(getDefaultState());

export const PlannerProvider = ({ children, event }) => {
  const [contextState, setContextState] = React.useState({
    ...getDefaultState(),
    event,
  });

  const [currentSelectedDay, setCurrentSelectedDay] = React.useState(null);

  const [fetchingPerformances, setFetchingPerformances] = React.useState(false);
  const [performancesDirty, setPerformancesDirty] = React.useState(false);
  const [fetchedPerformances, setFetchedPerformances] = React.useState(false);
  const [performances, setPerformances] = React.useState(null);

  const [fetchingPlacements, setFetchingPlacements] = React.useState(false);
  const [fetchedPlacements, setFetchedPlacements] = React.useState(false);
  const [placements, setPlacements] = React.useState(null);
  const [placementsDirty, setPlacementsDirty] = React.useState(false);
  const [draggingPlacement, setDraggingPlacement] = React.useState(false);

  // run once at mount
  React.useEffect(() => {
    fetchEventPlanner({ eventId: event.id });
  }, [event.id]);

  // we want to fetch performances when we have the planner
  React.useEffect(() => {
    if (
      contextState.fetchedPlanner &&
      contextState.planner.id !== undefined &&
      !fetchedPerformances &&
      !fetchingPerformances
    ) {
      fetchEventPlannerPerformances();
    }
  }, [
    contextState.fetchedPlanner,
    contextState.planner,
    fetchedPerformances,
    fetchingPerformances,
  ]);

  // when the currently selected performance day is changed, refetch placements
  React.useEffect(() => {
    if (currentSelectedDay !== null) {
      fetchEventPlannerPlacements();
    }
  }, [currentSelectedDay]);

  // When we've got performances for the first time
  React.useEffect(() => {
    if (fetchedPerformances && currentSelectedDay === null) {
      setInitialDefaultDate();
    }
  }, [fetchedPerformances, currentSelectedDay]);

  // when placements has been marked as dirty, refetch them
  React.useEffect(() => {
    if (placementsDirty) {
      fetchEventPlannerPlacements();
    }
  }, [placementsDirty]);

  // if performances has been marked as dirty, refetch them
  React.useEffect(() => {
    if (performancesDirty) {
      fetchEventPlannerPerformances();
    }
  }, [performancesDirty]);

  // When we've got performances, set the default date on the dropdown
  const setInitialDefaultDate = () => {
    const dates = performances.map(groupedPerformanceBlock =>
      moment(groupedPerformanceBlock.date)
    );
    const firstFutureDate = getFirstFutureDate(dates);
    const selectedDate = firstFutureDate
      ? firstFutureDate
      : dates[dates.length - 1];

    setCurrentSelectedDay(selectedDate.format('YYYY-MM-DD'));
  };

  // when a placement is moved in the grid via the drag and drop interface
  const movePlacement = async moveData => {
    const sourcePlacement = placements.find(
      p => p.id === moveData.sourcePlacementId
    );

    if (moveData.placementAction === 'swapPlacements') {
      const destinationPlacement = placements.find(
        p => p.id === moveData.destinationPlacementId
      );
      swapPlacements(sourcePlacement, destinationPlacement);
      return;
    }

    if (moveData.placementAction === 'moveToLane') {
      const destinationLane = contextState.planner.eventPlannerLanes.find(
        lane => lane.id === moveData.destinationLaneId
      );
      updatePlacement({
        ...sourcePlacement,
        eventPlannerLaneId: destinationLane.id,
        eventPlannerLane: destinationLane,
      });
      return;
    }
  };

  // update placement's lane
  const updatePlacement = async placement => {
    updateLocalPlacementData(placement);
    const request = await api.updateEventPlannerPlacement(placement);

    if (request.error) {
      console.error(request.error);
      return null;
    }

    setPlacementsDirty(true);
  };

  // swap the lanes of two placements
  const swapPlacements = async (sourcePlacement, destinationPlacement) => {
    const updatedPlacements = placements.map(p => {
      if (p.id === sourcePlacement.id) {
        return {
          ...sourcePlacement,
          eventPlannerLane: destinationPlacement.eventPlannerLane,
          eventPlannerLaneId: destinationPlacement.eventPlannerLaneId,
        };
      }
      if (p.id === destinationPlacement.id) {
        return {
          ...destinationPlacement,
          eventPlannerLane: sourcePlacement.eventPlannerLane,
          eventPlannerLaneId: sourcePlacement.eventPlannerLaneId,
        };
      }
      return p;
    });

    setPlacements(updatedPlacements);

    const request = await api.swapEventPlannerPlacements({
      sourcePlacementId: sourcePlacement.id,
      destinationPlacementId: destinationPlacement.id,
    });

    if (request.error) {
      console.error(request.error);
      return null;
    }

    setPlacementsDirty(true);
  };

  // fetch the main event planner model
  const fetchEventPlanner = async () => {
    setContextState({
      ...contextState,
      fetchingPlanner: true,
    });
    const request = await api.fetchEventPlanner({ eventId: event.id });
    if (!request.error) {
      setContextState({
        ...contextState,
        fetchingPlanner: false,
        fetchedPlanner: true,
        planner: request.response,
      });
    }
  };

  // fetch placements based on the currently selected day
  const fetchEventPlannerPlacements = async () => {
    setFetchingPlacements(true);

    const request = await api.fetchEventPlannerPlacements({
      id: contextState.planner.id,
      day: currentSelectedDay,
    });

    setFetchingPlacements(false);
    setPlacementsDirty(false);
    setPlacements(request.response);
    setFetchedPlacements(true);
  };

  // fetch all performances for this planner
  const fetchEventPlannerPerformances = async () => {
    setFetchingPerformances(true);
    const request = await api.fetchEventPlannerPerformances({
      id: contextState.planner.id,
    });

    setFetchingPerformances(false);
    setPerformances(request.response);
    setFetchedPerformances(true);
    setPerformancesDirty(false);
  };

  // set the current day we're looking at
  const setSelectedDay = day => {
    setCurrentSelectedDay(day);
  };

  // update lane information
  const updateLane = async (lane, values) => {
    const request = await api.updateEventPlannerLane({
      eventPlannerLaneId: lane.id,
      ...values,
    });

    if (request.error) {
      console.log(request.error);
      return;
    }

    const updatedLanes = contextState.planner.eventPlannerLanes.map(lane => {
      if (lane.id === request.response.id) {
        return request.response;
      } else {
        return lane;
      }
    });
    setContextState({
      ...contextState,
      planner: {
        ...contextState.planner,
        eventPlannerLanes: updatedLanes,
      },
    });
    setPlacementsDirty(true);
  };

  // delete a lane
  const deleteLane = async lane => {
    const request = await api.deleteEventPlannerLane({
      eventPlannerLaneId: lane.id,
    });
    if (request.error) {
      console.error(request.error);
      return;
    }

    setContextState({
      ...contextState,
      planner: request.response,
    });
  };

  // update the lock on lane
  const updateLaneLock = async (lane, isLocked) => {
    const request = await api.updateEventPlannerLaneLock({
      eventPlannerLaneId: lane.id,
      isLocked,
      date: currentSelectedDay,
    });

    if (request.error) {
      console.error(request.error);
      return;
    }

    const updatedLanes = contextState.planner.eventPlannerLanes.map(lane => {
      if (lane.id === request.response.id) {
        return request.response;
      } else {
        return lane;
      }
    });

    const updatedPlacements = placements.map(placement => {
      if (placement.eventPlannerLaneId === request.response.id) {
        return {
          ...placement,
          eventPlannerLane: request.response,
        };
      } else {
        return placement;
      }
    });

    setPlacements(updatedPlacements);
    setContextState({
      ...contextState,
      planner: {
        ...contextState.planner,
        eventPlannerLanes: updatedLanes,
      },
    });
  };

  // update the disabled status on a lane
  const updateLaneDisabledStatus = async (lane, isDisabled) => {
    const request = await api.updateEventPlannerLaneDisabledStatus({
      eventPlannerLaneId: lane.id,
      isDisabled,
      date: currentSelectedDay,
    });

    if (request.error) {
      console.error(request.error);
      return;
    }

    const updatedLanes = contextState.planner.eventPlannerLanes.map(lane => {
      if (lane.id === request.response.id) {
        return request.response;
      } else {
        return lane;
      }
    });

    setContextState({
      ...contextState,
      planner: {
        ...contextState.planner,
        eventPlannerLanes: updatedLanes,
      },
    });
    setPlacementsDirty(true);
  };

  // create a allocation
  const createTicketAllocation = async data => {
    const request = await api.createTicketAllocationForPlanner(data);
    if (request.error) {
      console.error(request.error);
      return;
    }

    setPlacementsDirty(true);
    setPerformancesDirty(true);
  };

  // delete an allocation
  const deleteTicketAllocation = async data => {
    const request = await api.deleteTicketAllocation(data);
    if (request.error) {
      console.error(request.error);
      return;
    }

    setPlacementsDirty(true);
    setPerformancesDirty(true);
  };

  // issue tickets for an allocation
  const issueTicketForPlacementAllocation = async data => {
    // first we issue the ticket receipt for the allocation
    const issueTicketRequest = await api.issueTicketForPlanner({
      ticketAllocationId: data.placement.ticketAllocation.id,
      purchaseData: data.purchaseData,
    });

    if (issueTicketRequest.error) {
      console.log(issueTicketRequest.error);
      return;
    }

    await updatePlacement(data.placement);
  };

  // change the width of lane columns
  const setColumnWidth = width => {
    setContextState({
      ...contextState,
      columnWidth: width,
    });
  };

  // update a single placement in the provider's local state
  const updateLocalPlacementData = placement => {
    const updatedPlacements = placements.map(p => {
      if (p.id === placement.id) {
        return placement;
      }
      return p;
    });
    setPlacements(updatedPlacements);
  };

  const providerStateShape = {
    // methods
    movePlacement,
    setSelectedDay,
    updateLane,
    deleteLane,
    updateLaneLock,
    updateLaneDisabledStatus,
    createTicketAllocation,
    issueTicketForPlacementAllocation,
    setColumnWidth,
    deleteTicketAllocation,
    setDraggingPlacement,
    updatePlacement,
    updateLocalPlacementData,

    // TODO: remove this? this is what the UI components expect the data
    // to be shaped like
    plannerData: {
      lanes: contextState.fetchedPlanner
        ? contextState.planner.eventPlannerLanes
        : [],
      performances:
        contextState.fetchedPlanner && fetchedPerformances && currentSelectedDay
          ? performances.find(group => group.date === currentSelectedDay)
              .performances
          : [],
      placements:
        contextState.fetchedPlanner && fetchedPlacements ? placements : [],
    },

    // convience attribute
    hasPlanner:
      contextState.planner !== null && contextState.planner.id !== undefined,

    // local context state
    ...contextState,

    fetchingPerformances,
    fetchingPlacements,
    fetchedPlacements,
    fetchedPerformances,

    placements,
    performances,

    currentSelectedDay,
    draggingPlacement,
  };

  return (
    <PlannerContext.Provider value={providerStateShape}>
      {children}
    </PlannerContext.Provider>
  );
};

export const usePlanner = () => {
  return React.useContext(PlannerContext);
};
