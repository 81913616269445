import React from 'react';
import Table, {
  TableBody,
  TableHeader,
  TableCell,
} from '../../../components/Table/Table';
import TicketRowItem from './TicketRowItem';
import withEmptyState from '../../withEmptyState';
const TicketList = ({ tickets, onClick }) => (
  <Table borders tableMargin='0 0 2rem 0' containerBorder mobileWide>
    <TableHeader bgColor='eaeff7'>
      <TableCell width={24}>Name</TableCell>
      <TableCell width={15}>Status</TableCell>
      <TableCell width={7}>Face</TableCell>
      <TableCell width={7}>Fee</TableCell>
      <TableCell width={7}>Total</TableCell>
      <TableCell width={7} center>
        Sold
      </TableCell>
      <TableCell width={7} center>
        Comp
      </TableCell>
      <TableCell width={7} center>
        Held
      </TableCell>
      <TableCell width={7} center>
        Res.
      </TableCell>
      <TableCell width={7} center>
        Rem.
      </TableCell>
    </TableHeader>
    <TableBody
      renderItem={ticket => (
        <TicketRowItem ticket={ticket} key={ticket.id} onClick={onClick} />
      )}
      items={tickets}
    />
  </Table>
);

export default withEmptyState(TicketList);
