import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Modal from 'components/Modal/Modal';
import CreateReservationForm from 'components/Planner/CreateReservationForm';
import * as api from 'services/api';

import { hideModal } from 'modules/modal/modal';

const CreateReservationModal = ({ hideModal, onSubmit, lane, performance }) => {
  const [tickets, setTickets] = React.useState({
    fetched: false,
    loading: false,
    data: [],
  });

  const fetchPerformanceTickets = async performance => {
    setTickets({ ...tickets, loading: true });
    const request = await api.fetchTicketsForPerformance({
      performanceId: performance.id,
    });
    if (!request.error) {
      setTickets({
        data: request.response,
        fetched: true,
        loading: false,
      });
    }
  };

  React.useEffect(() => {
    fetchPerformanceTickets(performance);
  }, [performance]);

  if (!tickets.fetched) {
    return null;
  }

  return (
    <Modal onRequestClose={() => hideModal()}>
      <CreateReservationForm
        tickets={tickets.data}
        lane={lane}
        performance={performance}
        onCancel={hideModal}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        hideModal,
      },
      dispatch
    ),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateReservationModal);
