import React from "react";
import { Box } from "grommet";

export { default as Title } from "./Title/Title";
export { default as SubTitle } from "./SubTitle/SubTitle";
export { default as SectionTitle } from "./SectionTitle/SectionTitle";
export { default as PageTopSection } from "./PageTopSection/PageTopSection";
export { default as HeaderActions } from "./HeaderActions/HeaderActions";
export { default as ContentSummary } from "./ContentSummary/ContentSummary";
export { default as ContentColumn } from "./ContentColumn/ContentColumn";
export { default as ContentContainer } from "./ContentContainer/ContentContainer";
export { default as ContentType } from "./ContentType/ContentType";
export { default as PageTop } from "./PageTop/PageTop";
export { default as MultiActionContainer } from "./MultiActionContainer/MultiActionContainer";
export { default as LineUpHelmet } from "./LineUpHelmet/LineUpHelmet";

const MainContent = ({ children }) => {
  return (
    <div>
      <Box pad="medium" responsive={false}>
        {children}
      </Box>
    </div>
  );
};

export default MainContent;
