// pagination : {
//   events: {
//     currentPage: 1,
//     totalPages:10,
//     resultsPerPage:100
//     pages: {
//       1: {
//         ids: [1,2,3],
//         fetching: false
//       }
//     }
//   }

// }
// Creates a reducer managing pagination, given the action types to handle,
// and a function telling how to extract the key from an action.

const listIds = ({ types }) => {
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected types to be an array of three elements.');
  }
  if (!types.every(t => typeof t === 'string')) {
    throw new Error('Expected types to be strings.');
  }

  const [requestType, successType, failureType] = types;

  const ids = (state = [], action) => {
    switch (action.type) {
      case requestType:
        return [];
      case successType:
        return [...state, ...action.payload.response.result];
      case failureType:
        return [];
      default:
        return state;
    }
  };
  return ids;
};

export default listIds;

export const getTotalItems = state => state.length;
export const getIds = state => state;
export const getTotalPages = (state, perPage) =>
  Math.ceil(getTotalItems(state) / perPage);
