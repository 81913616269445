import React from 'react';
import {
  aggregatorTemplates as tpl,
  numberFormat,
  sortAs,
} from '../../../components/PivotTable/Utilities';
import PivotTable from '../../../components/PivotTable/PivotTable';
import ReportSection from '../../../components/ReportSection/ReportSection';

const PaymentReport = ({ data, title, exportFilename }) => {
  const fmt = numberFormat({ prefix: '£' });
  return (
    <ReportSection>
      <PivotTable
        title={title}
        exportFilename={exportFilename}
        data={data}
        aggregators={{
          Gross: () => tpl.sum(fmt)(['Gross']),
          Tax: () => tpl.sum(fmt)(['Tax']),
          Net: () => tpl.sum(fmt)(['Net']),
          'Line-Up Fee (Net)': () => tpl.sum(fmt)(['Lineup Fee Net']),
          'Stripe Fee (Net)': () => tpl.sum(fmt)(['Payment Fee']),
          'Online (Stripe)': () =>
            tpl.sumIf(
              (record) => record['Payment Source'] === 'stripe',
              fmt
            )(['Gross']),
          Invoice: () =>
            tpl.sumIf(
              (record) => record['Payment Source'] === 'invoice',
              fmt
            )(['Gross']),
          'OTD Card': () =>
            tpl.sumIf(
              (record) => record['Payment Source'] === 'card',
              fmt
            )(['Gross']),
          'OTD Cash': () =>
            tpl.sumIf(
              (record) => record['Payment Source'] === 'cash',
              fmt
            )(['Gross']),
          Agent: () =>
            tpl.sumIf(
              (record) => record['Payment Source'] === 'agent',
              fmt
            )(['Gross']),
        }}
        rows={['Receipt Title', 'Transaction Type']}
        rowSubTotals={true}
        rowSubTotalName={'Net'}
        sorters={{
          'Transaction Type': sortAs(['Sale', 'Refund', 'Net']),
        }}
      />
    </ReportSection>
  );
};

export default PaymentReport;
