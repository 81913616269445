import { combineReducers } from 'redux';
import * as actions from './actions';
import * as types from './types';
import relationshipById from '../shared/relationshipById';

//- Reducers

const notesByOrderId = relationshipById({
  mapActionToParentKey: action => action.payload.orderId,
  mapActionToKey: action => action.payload.noteId,
  fetchRoutine: actions.fetchOrderNotes,
  createRoutine: actions.createNoteOnOrder,
  deleteRoutine: actions.deleteNoteOnOrder,
});

const formResponses = (
  state = {
    data: null,
  },
  action
) => {
  switch (action.type) {
    case actions.fetchOrderFormResponses.SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
      };
    }
    default:
      return state;
  }
};

const selectItem = (id, items) => {
  return [...items, id];
};

const deselectItem = (id, items) => items.filter(item => item !== id);

const selectedItems = (
  state = {
    tickets: [],
    products: [],
    donations: [],
  },
  action
) => {
  switch (action.type) {
    case types.SELECT_RECEIPT: {
      const { receiptId, key } = action.payload;
      return {
        ...state,
        [key]: selectItem(receiptId, state[key]),
      };
    }
    case types.UNSELECT_RECEIPT: {
      const { receiptId, key } = action.payload;
      return {
        ...state,
        [key]: deselectItem(receiptId, state[key]),
      };
    }
    case actions.createRefund.SUCCESS:
    case actions.fetchOrder.SUCCESS:
      return {
        tickets: [],
        products: [],
        donations: [],
      };
    default:
      return state;
  }
};

const refundError = (state = null, action) => {
  switch (action.type) {
    case actions.createRefund.REQUEST:
    case actions.createRefund.SUCCESS:
      return null;
    case actions.createRefund.FAILURE:
    case actions.deleteRefund.FAILURE:
      return action.payload.error;
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case actions.fetchOrder.TRIGGER:
      return true;
    case actions.fetchOrder.SUCCESS:
    case actions.fetchOrder.FAILURE:
      return false;
    default:
      return state;
  }
};

const orders = combineReducers({
  selectedItems,
  notesByOrderId,
  formResponses,
  refundError,
  loading
});

export default orders;
