import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SubTitle } from '../../../components/MainContent/MainContent';
import {
  embedSettingsActions,
  embedSettingsSelectors
} from '../../../modules/eventEmbedSettings/eventEmbedSettings';
import TicketEmbedSettingsForm from '../../../components/TicketEmbedSettings/TicketEmbedSettingsForm';
import TicketWidget from '../../../components/TicketWidget/TicketWidget';
import Can from '../../../components/Can';
import { EventAuthContext } from '../event-auth-context';
import './EventEmbedSettings.css';

// Grommet
import { Box, ResponsiveContext, Text } from 'grommet';

const LOADER_URL = process.env.REACT_APP_LOADER_URL;

class EventEmbedSettings extends Component {
  componentDidMount() {
    this.props.loadEmbedSettings(this.props.eventId);
  }

  componentDidReceiveProps(prevProps) {
    if (this.props.eventId !== prevProps.eventId) {
      this.props.loadEmbedSettings(this.props.eventId);
    }
  }

  handleSubmit = values => {
    // NNED TO REMOVE LINK IF NOT MULTI CHECKOUT
    this.props.updateEmbedSettings(this.props.eventId, values);
  };

  getEmbedCode = () => {
    return `<div class="lineup-ticketing" data-lineup-background-color="${this.props.backgroundColor}" data-lineup-main-color="${this.props.mainColor}" data-lineup-event-id="${this.props.eventId}" data-lineup-api-key="${window.p_lukey}"><a href="https://lineupnow.com/event/${this.props.eventId}">Buy Tickets</a></div>\n<script src="${LOADER_URL}"></script>`;
  };
  getShortcode = () => {
    return `[lineup-event main_color=${this.props.mainColor} background_color=${this.props.backgroundColor} api_key=${window.p_lukey} event_id=${this.props.eventId} width=100% /]`;
  };
  handleFocus = e => {
    e.target.select();
  };

  render() {
    const { eventId } = this.props;
    return (
      <ResponsiveContext.Consumer>
        {size => (
          <>
            <SubTitle title='Embed Event' />
            <div>
              <Box
                margin={{ vertical: 'medium' }}
                background='infobox'
                pad='medium'
              >
                <div>
                  <Box margin={{ vertical: 'small' }} height='auto'>
                    <SubTitle title='Settings' />
                    <>
                      <Box
                        direction={size !== 'small' ? 'row' : 'column'}
                        justify='between'
                        gap='medium'
                        margin={{ top: 'medium' }}
                      >
                        <Box
                          width={size !== 'small' ? '60%' : '100%'}
                          height={size !== 'small' ? 'auto' : 'auto'}
                        >
                          <div>
                            <TicketWidget
                              eventId={eventId}
                              apiKey={window.p_lukey}
                              key={this.props.embedKey}
                            />
                          </div>
                        </Box>

                        <Box width={size !== 'small' ? '40%' : '100%'}>
                          <Can
                            role={this.context}
                            perform={'event:edit'}
                            yes={() => (
                              <div>
                                <TicketEmbedSettingsForm
                                  onSubmit={this.handleSubmit}
                                />
                              </div>
                            )}
                          />
                        </Box>
                      </Box>
                    </>
                  </Box>
                </div>
                <div>
                  <Box margin={{ vertical: 'medium' }}>
                    <SubTitle title='Embed Codes' />
                    <Box margin={{ vertical: 'small' }}>
                      <Box gap='small' direction='column'>
                        <Text size='small'>Standard Code:</Text>

                        <textarea
                          className='embed-code__text'
                          value={this.getEmbedCode()}
                          onFocus={this.handleFocus}
                          rows='4'
                          readOnly
                        />
                      </Box>
                      <Box
                        gap='small'
                        direction='column'
                        margin={{ top: 'small' }}
                      >
                        <Text size='small'>WordPress Shortcode:</Text>
                        <textarea
                          className='embed-code__text'
                          value={this.getShortcode()}
                          onFocus={this.handleFocus}
                          rows='2'
                          readOnly
                        />
                      </Box>
                    </Box>
                  </Box>
                </div>
              </Box>
            </div>
          </>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}
EventEmbedSettings.contextType = EventAuthContext;
const mapStateToProps = (state, ownProps) => {
  const eventId = parseInt(ownProps.match.params.eventId, 10);
  return {
    embedKey: state.eventEmbedSettings.key,
    backgroundColor: embedSettingsSelectors.getBackgrounColor(state),
    mainColor: embedSettingsSelectors.getMainColor(state),
    eventId
  };
};

export default connect(mapStateToProps, {
  ...embedSettingsActions
})(EventEmbedSettings);
