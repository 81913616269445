import React from 'react';
import { AIPagination as Pagination } from '../../components/AISearch/AISearch';
import { FormattedDate } from 'react-intl';
/* eslint-disable react/style-prop-object */
import Table, {
  TableCell,
  TableRow,
  TableBody,
  TableHeader,
} from '../../components/Table/Table';
import { SearchBox, Configure } from 'react-instantsearch-dom';
import EmptyState from '../../components/EmptyState/EmptyState';
import emptyCustomers from './empty_customers.png';
import SearchWithEmptyState from '../../components/SearchWithEmptyState';

// Grommet
import { Box, Heading } from 'grommet';

const UserRow = ({
  firstName,
  lastName,
  email,
  signupDate,
  onClick,
  userId,
}) => {
  const handleClick = evt => {
    evt.preventDefault();
    onClick(userId);
  };
  return (
    <TableRow striped={true} onClick={handleClick}>
      <TableCell>{firstName}</TableCell>
      <TableCell>{lastName}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>
        <FormattedDate
          value={signupDate}
          weekday='short'
          year='numeric'
          day='numeric'
          month='short'
        />
      </TableCell>
    </TableRow>
  );
};

const UserHits = ({ hits, onClick }) =>
  hits.length > 0 ? (
    <TableBody
      renderItem={item => (
        <UserRow
          firstName={item.firstName}
          lastName={item.lastName}
          email={item.emailAddress}
          signupDate={new Date(item.createdAt)}
          userId={item.id}
          key={item.objectID}
          onClick={onClick}
        />
      )}
      items={hits}
    />
  ) : (
    <TableRow striped={true}>
      <td colspan='5'>
        <Box
          pad='medium'
          background='light-2'
          width='100%'
          align='center'
          alignContent='center'
        >
          <Heading level='4'>No orders found</Heading>
        </Box>
      </td>
    </TableRow>
  );

export const UserList = ({ onClick }) => {
  return (
    <SearchWithEmptyState
      Component={({ hits }) => (
        <div>
          <Box margin={{ vertical: 'medium' }}>
            <SearchBox />
          </Box>
          <Configure hitsPerPage={25} />
          <Table mobileWide>
            <TableHeader>
              <TableCell width={25}>First Name</TableCell>
              <TableCell width={25}>Last Name</TableCell>
              <TableCell width={35}>Email</TableCell>
              <TableCell width={15}>Signup Date</TableCell>
            </TableHeader>
            <UserHits onClick={onClick} hits={hits} />
          </Table>
          <Pagination />
        </div>
      )}
      EmptyComponent={() => (
        <EmptyState
          background
          showButton={false}
          emptyStateHeadline='You have no Customers yet'
          emptyStateDescription="When you've had your first orders, your customers will appear here."
          emptyStateImageSrc={emptyCustomers}
          emptyStateImageAlt='No Customers yet'
          emptyStateImageTitle='No Customers yet'
        />
      )}
    />
  );
};
