import React, { Component } from "react";
import MainContent, {
  Title,
  SubTitle,
  ContentContainer,
  LineUpHelmet,
} from "../../components/MainContent/MainContent";
import Lozenge from "../../components/Lozenge/Lozenge";

// Grommet
import { Box, Grid, ResponsiveContext } from "grommet";
import {
  Apps,
  License,
  MailOption,
  Sync,
  UnorderedList,
  Cli,
  Connect,
} from "grommet-icons";

const columns = {
  small: ["auto"],
  medium: ["31%", "31%", "31%"],
  large: ["31%", "31%", "31%"],
};

const rows = {
  small: ["auto", "auto", "auto"],
  medium: ["auto"],
  large: ["auto"],
};

const fixedGridAreas = {
  small: [
    { name: "checkout", start: [0, 0], end: [0, 0] },
    { name: "marketing", start: [0, 1], end: [0, 1] },
    { name: "api", start: [0, 2], end: [0, 2] },
  ],
  medium: [
    { name: "checkout", start: [0, 0], end: [0, 0] },
    { name: "marketing", start: [1, 0], end: [1, 0] },
    { name: "api", start: [2, 0], end: [2, 0] },
  ],
  large: [
    { name: "checkout", start: [0, 0], end: [0, 0] },
    { name: "marketing", start: [1, 0], end: [1, 0] },
    { name: "api", start: [2, 0], end: [2, 0] },
  ],
};

const Responsive = ({
  children,
  overrideColumns,
  overrideRows,
  areas,
  ...props
}) => (
  <ResponsiveContext.Consumer>
    {(size) => {
      // Take into consideration if not array is sent but a simple string
      let columnsVal = columns;
      if (columns) {
        if (columns[size]) {
          columnsVal = columns[size];
        }
      }

      let rowsVal = rows;
      if (rows) {
        if (rows[size]) {
          rowsVal = rows[size];
        }
      }

      // Also if areas is a simple array not an object of arrays for
      // different sizes
      let areasVal = areas;
      if (areas && !Array.isArray(areas)) areasVal = areas[size];

      return (
        <Grid
          {...props}
          areas={!areasVal ? undefined : areasVal}
          rows={!rowsVal ? size : rowsVal}
          columns={!columnsVal ? size : columnsVal}
          fill={true}
        >
          {children}
        </Grid>
      );
    }}
  </ResponsiveContext.Consumer>
);

class ToolsList extends Component {
  render() {
    return (
      <>
        <LineUpHelmet
          title="Tools"
          description="Line-Up tools to help ticket vendors"
        />
        <MainContent>
          <Title>Tools</Title>
          <Box>
            <Responsive
              rows={rows}
              columns={columns}
              gap="3.5%"
              areas={fixedGridAreas}
            >
              <Box
                margin={{ vertical: "medium" }}
                gridArea="checkout"
                width="auto"
              >
                <Box margin={{ bottom: "medium" }}>
                  <SubTitle title="Checkout" />
                </Box>
                <ContentContainer>
                  <Box gap="small">
                    <Lozenge
                      label="Seating Charts"
                      icon={<Apps size="medium" />}
                      href="/tools/seating-charts"
                      description="Design seating charts for your venue and let your customers select their seats when they purchase."
                    />
                    <Lozenge
                      label="Custom Forms"
                      icon={<UnorderedList size="medium" />}
                      href="/forms"
                      description="Manage custom checkout forms."
                    />
                  </Box>
                </ContentContainer>
              </Box>
              <Box
                margin={{ vertical: "medium" }}
                gridArea="marketing"
                width="auto"
              >
                <Box margin={{ bottom: "medium" }}>
                  <SubTitle title="Marketing" />
                </Box>
                <ContentContainer>
                  <Box gap="small">
                    <Lozenge
                      label="Mailchimp Sync"
                      icon={<Sync size="medium" />}
                      href="/mailchimp"
                      description="Connect your Mailchimp account to automatically add ticket buyers to your Mailchimp Audiences."
                    />
                    <Lozenge
                      label="Email Templates - for Pre/Post show emails"
                      icon={<MailOption size="medium" />}
                      href="/emails"
                      description="Create email templates to send at specific times before and after an event performance."
                    />
                  </Box>
                </ContentContainer>
              </Box>
              <Box margin={{ vertical: "medium" }} gridArea="api" width="auto">
                <Box margin={{ bottom: "medium" }}>
                  <SubTitle title="API" />
                </Box>

                <ContentContainer>
                  <Box gap="small">
                    <Lozenge
                      label="My API Keys"
                      icon={<License size="medium" />}
                      href="/tools/api-keys"
                      description="Your Line-Up API keys"
                    />
                    <Lozenge
                      label="Webhooks"
                      icon={<Connect size="medium" />}
                      href="/tools/webhooks/1"
                      description="Manage webhooks."
                    />
                    <Lozenge
                      label="API Docs"
                      icon={<Cli size="medium" />}
                      href="https://docs.lineupnow.com/"
                      description="View the Line-Up API Docs"
                      target="_blank"
                    />
                  </Box>
                </ContentContainer>
              </Box>
            </Responsive>
          </Box>
        </MainContent>
      </>
    );
  }
}

export default ToolsList;
