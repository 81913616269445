import * as fromListIds from '../shared/listIds';
import * as fromEntities from '../shared/entities';
import * as fromPaginator from '../shared/paginate';
import { getTicketGroup } from '../ticketGroups/selectors';
import Fuse from 'fuse.js';

//- Selectors
export const getDiscount = (state, discountId) =>
  fromEntities.getEntity(state.entities.discount, discountId);

export const getTicketGroupsForDiscount = (state, discountId) => {
  const discount = getDiscount(state, discountId);
  return discount.ticketGroups
    ? discount.ticketGroups.map(id => getTicketGroup(state, id))
    : [];
};

const fuseOptions = {
  shouldSort: true,
  threshold: 0.2,
  tokenize: true,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['code'],
};

export const getDiscountsForPage = (state, page) => {
  const ids = fromPaginator.getIdsForPage(state.discounts.byPage, page);
  return ids.map(id => getDiscount(state, id));
};
export const getDiscountsCurrentPage = state =>
  fromPaginator.getCurrentPage(state.discounts.byPage);

export const getDiscountsTotalPages = (state, perPage = 25) =>
  fromPaginator.getTotalPages(state.discounts.byPage);

export const getDiscountsLoadingStatus = state => state.discounts.loading;
export const getNewDiscountId = state => state.discounts.newDiscount;
