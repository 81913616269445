import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  InvoiceHeaderItem,
  InvoiceAddress,
  InvoiceItemsTable,
} from '../../components/Invoice/Invoice';
import {
  invoiceActions,
  invoiceSelectors,
} from '../../modules/invoices/invoices';
import './InvoiceDetail.css';

class InvoiceDetail extends Component {
  componentWillMount() {
    this.props.fetchInvoice({ invoiceId: this.props.invoiceId });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.invoiceId !== nextProps.invoiceId) {
      this.props.fetchInvoice({ invoiceId: this.props.invoiceId });
    }
  }

  render() {
    const { invoice } = this.props;
    if (!invoice.id) {
      return null;
    }
    return (
      <div className='sheet'>
        <div className='header'>
          <span className='header__logo'>
            <img alt='Line-Up Logo' src='' />
          </span>
          <span className='header__title'>Invoice</span>
        </div>
        <div className='invoice-details'>
          <InvoiceHeaderItem
            title='Invoice reference:'
            value={invoice.reference}
          />
          <InvoiceHeaderItem
            title='Date of issue:'
            value={invoice.dateIssued}
            date
          />
          <InvoiceHeaderItem title='Date due:' value={invoice.dateDue} date />
          <InvoiceHeaderItem
            title='Start of Period:'
            value={invoice.fromPeriod}
            date
          />
          <InvoiceHeaderItem
            title='End of Period:'
            value={invoice.toPeriod}
            date
          />
        </div>
        <InvoiceAddress
          address={invoice.payer.address}
          vatNumber={invoice.payer.vatNumber}
          company={invoice.payer.company}
        />
        {invoice.payee && (
          <InvoiceAddress
            address={invoice.payee.address}
            vatNumber={invoice.payee.vatNumber}
            company={invoice.payee.company}
          />
        )}
        <InvoiceItemsTable invoice={invoice} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const invoiceId = parseInt(ownProps.match.params.invoiceId, 10);
  const invoice = invoiceSelectors.getInvoice(state, invoiceId);
  return {
    invoice,
    invoiceId,
  };
};

export default connect(
  mapStateToProps,
  {
    ...invoiceActions,
  }
)(InvoiceDetail);
