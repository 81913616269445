import React from 'react';
import { CardElement } from 'react-stripe-elements';

import FormikField from 'components/Form/FormikField';
import FormField from 'components/Form/FormField/FormField';

const CardSection = ({ fontSize, textColor }) => {
  const cardStyles = {
    base: {
      fontSize: fontSize,
      fontFamily: "'lato', sans serif",
      fontWeight: '400',
      color: textColor,
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#666',
      },
    },
    invalid: {
      iconColor: '#f3545b',
      color: '#e5424d',
      ':focus': {
        color: textColor,
      },
    },
  };

  return (
    <>
      <FormikField
        name='cardholderName'
        label='Cardholder name'
        component={FormField}
        type='text'
      />
      <CardElement style={cardStyles} />
    </>
  );
};

export default CardSection;
