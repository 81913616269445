import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
/* eslint-disable react/style-prop-object */
import MainContent, {
  LineUpHelmet
} from '../../components/MainContent/MainContent';
import {
  productActions,
  productSelectors
} from '../../modules/products/products';
import ProductNav from '../../components/Product/ProductNav';
import ProductInfo from './ProductInfo/ProductInfo';
import ProductEmbedSettings from './ProductEmbedSettings/ProductEmbedSettings';

class ProductDetail extends Component {
  componentDidMount() {
    this.props.fetchProduct({ productId: this.props.productId });
  }
  componentDidUpdate(prevProps) {
    if (this.props.productId !== prevProps.productId) {
      this.props.fetchProduct({ productId: this.props.productId });
    }
  }

  render() {
    const { product, match } = this.props;
    return (
      <>
        <LineUpHelmet
          title={product.title}
          description='Manage your product in Line-Up'
        />
        <MainContent>
          <ProductNav title={product.title} productId={product.id} />
          <Switch>
            <Route exact path={`${match.path}`}>
              <ProductInfo product={product} />
            </Route>
            <Route exact path={`${match.path}/embed-settings`}>
              <ProductEmbedSettings />
            </Route>
          </Switch>
        </MainContent>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const productId = parseInt(ownProps.match.params.productId, 10);
  const product = productSelectors.getProduct(state, productId);
  return {
    productId,
    product
  };
};

export default connect(mapStateToProps, {
  ...productActions
})(ProductDetail);
