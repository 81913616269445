const JobRoles = [
  {
    value: 'Venue owner/ manager',
    label: 'Venue owner/ manager'
  },
  {
    value: 'Professional events organiser/ promoter',
    label: 'Professional events organiser/ promoter'
  },
  {
    value: 'Musician/ artist',
    label: 'Musician/ artist'
  },
  {
    value: 'Courses/ classes/ workshops',
    label: 'Courses/ classes/ workshops'
  },
  {
    value: 'Marketing/ PR agency',
    label: 'Marketing/ PR agency'
  },
  {
    value: 'Charity',
    label: 'Charity'
  },
  {
    value: 'Community/ school events',
    label: 'Community/ school events'
  },
  {
    value: 'Semi-professional events organiser/ promoter',
    label: 'Semi-professional events organiser/ promoter'
  },
  {
    value: 'Other',
    label: 'Other'
  }
];

export default JobRoles;
