import React from 'react';
import Table, {
  TableBody,
  TableHeader,
  TableCell
} from '../../../components/Table/Table';
import TicketAllocationRowItem from './TicketAllocationRowItem';
const TicketAllocationList = ({ allocations, onClick }) => (
  <Table>
    <TableHeader>
      <TableCell width={40}>Name</TableCell>
      <TableCell width={15} center>
        Held
      </TableCell>
      <TableCell width={15} center>
        Allocated
      </TableCell>
      <TableCell width={15} center>
        Total
      </TableCell>
      <TableCell width={15} center />
    </TableHeader>
    <TableBody
      renderItem={allocation => (
        <TicketAllocationRowItem
          allocation={allocation}
          key={allocation.id}
          onClick={onClick}
        />
      )}
      items={allocations}
    />
  </Table>
);

export default TicketAllocationList;
