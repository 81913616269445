import * as actions from './actions';

const eventPlanner = (
  state = {
    loading: false,
    fetched: false,
    data: null
  },
  action
) => {
  switch (action.type) {
    case actions.fetchEventPlanner.TRIGGER:
      return {
        ...state,
        loading: true,
        fetched: false
      };
    case actions.fetchEventPlanner.SUCCESS:
      return {
        ...state,
        data: action.payload.response
      };
    case actions.fetchEventPlanner.FAILURE:
      return {
        ...state,
        data: null
      };
    case actions.fetchEventPlanner.FULFILL:
      return {
        ...state,
        fetched: true,
        loading: false
      };
    default:
      return state;
  }
};

export default eventPlanner;
