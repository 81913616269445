import * as fromEntities from '../shared/entities';
import { PivotData } from 'react-pivottable/Utilities';
export const getPaymentReportData = state => state.reports.paymentReport;
export const getTicketData = state => state.reports.ticketData;

export const getEvent = (state, id) =>
  fromEntities.getEntity(state.entities.event, id);

export const getEvents = (state, page) =>
  state.reports.events.byId.map(id => getEvent(state, id));

export const getEventsLoading = state => state.reports.events.loading;
export const getSelectedEventId = state => state.reports.filters.eventId;
export const getFromDate = state => state.reports.filters.from;
export const getToDate = state => state.reports.filters.to;

export const reportData = state => {
  const materializedInput = [];
  PivotData.forEachRecord(getTicketData(state) || [], {}, record =>
    materializedInput.push(record)
  );
  return materializedInput;
};

export const getCurrentFilters = state =>
  state.reports.allIds.map(id => state.reports.filtersById[id]);
