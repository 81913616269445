import React from 'react';
import * as Yup from 'yup';

import FormikField from 'components/Form/FormikField';
import FormikForm from 'components/Form/FormikForm';
import FormField from 'components/Form/FormField/FormField';
import FormFieldReactSelect from 'components/Form/FormFieldReactSelect/FormFieldReactSelect';
import JobRoles from './JobRoles';

const validationSchema = Yup.object({
  firstName: Yup.string().required('Your first name is required'),
  lastName: Yup.string().required('Your last name is required'),
  emailAddress: Yup.string()
    .email('Invalid email address')
    .required('An email address is required'),
  company: Yup.string(),
  telephone: Yup.string()
    .required('Please enter a number we can contact you on')
    .matches(
      /^[+0-9][ 0-9]+$/,
      'Please check the telephone number you have entered'
    ),
  password: Yup.string().min(6, 'Password must be at least 6 characters long'),
  confirmPassword: Yup.string().when('password', {
    is: val => (val && val.length > 0 ? true : false),
    then: Yup.string()
      .required('Please confirm the password you entered above')
      .oneOf([Yup.ref('password')], 'Passwords must be the same')
  })
});

const AccountDetailsForm = ({ onSubmit, user }) => {
  return (
    <FormikForm
      validationSchema={validationSchema}
      initialValues={{
        firstName: user.firstName,
        lastName: user.lastName,
        emailAddress: user.emailAddress,
        telephone: user.telephone || '',
        company: user.company || '',
        position: user.position || ''
      }}
      onSubmit={onSubmit}
      cancelBtn={false}
      submitText="Save changes"
      formTitle="Update your account details"
    >
      <FormikField
        name="firstName"
        label="First name"
        component={FormField}
        type="text"
        placeholder="Your first name"
      />
      <FormikField
        name="lastName"
        label="Last name"
        component={FormField}
        type="text"
        placeholder="Your last name"
      />
      <FormikField
        name="emailAddress"
        label="Email address"
        component={FormField}
        type="email"
        placeholder="Your email address"
      />
      <FormikField
        name="telephone"
        label="Telephone number"
        component={FormField}
        type="text"
        placeholder="A number we can reach you on"
      />
      <FormikField
        name="company"
        label="Company"
        component={FormField}
        type="text"
        placeholder="Company name"
      />
      <FormikField
        name="password"
        label="Password"
        component={FormField}
        type="password"
        placeholder="A secure password"
      />
      <FormikField
        name="confirmPassword"
        label="Confirm password"
        component={FormField}
        type="password"
        placeholder="Confirm the password you entered above"
        errorWithoutTouch={true}
      />
      <FormikField
        name="position"
        label="Please choose a description that closest fits your role"
        component={FormFieldReactSelect}
        type="select"
        options={JobRoles}
        placeholder="Select a role"
      />
    </FormikForm>
  );
};

export default AccountDetailsForm;
