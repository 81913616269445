import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// Components
import MainContent, { LineUpHelmet } from 'components/MainContent/MainContent';

// Grommet
import { Box } from 'grommet';
import './SeatingChartCreate.css';
import {
  seatingChartActions,
  seatingChartSelectors,
} from 'modules/seatingCharts/seatingCharts';
import { bindActionCreators } from 'redux';
import { SeatsioDesigner } from '@seatsio/seatsio-react';
import { push } from 'connected-react-router';
const SeatingChartEdit = ({
  seatingUser,
  chartKey,
  hasDraft,
  getSeatingUser,
  dispatch,
}) => {
  useEffect(() => {
    if (!seatingUser) {
      getSeatingUser();
    }
  }, [seatingUser]);
  return (
    <>
      <LineUpHelmet
        title='Seating Chart Create'
        description='Create a Seating Chart'
      />
      <MainContent>
        <Box>
          {seatingUser && chartKey && (
            <SeatsioDesigner
              secretKey={seatingUser.secretKey}
              chartKey={chartKey}
              baseColor='#283544'
              openDraftDrawing={hasDraft == 'true'}
              onExitRequested={() => {
                dispatch(push(`/tools/seating-charts/`));
              }}
            />
          )}
        </Box>
      </MainContent>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const hasDraft = ownProps.match.params.hasDraft;
  const chartKey = ownProps.match.params.chartKey;
  return {
    hasDraft,
    chartKey,
    seatingUser: seatingChartSelectors.getSeatingUser(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        ...seatingChartActions,
      },
      dispatch
    ),
    dispatch,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeatingChartEdit);
