import React from 'react';
import './FormFieldColor.css';
import '../FormField/FormField.css';
import classNames from 'classnames';
import { ChromePicker } from 'react-color';

class FormFieldColor extends React.Component {
  state = {
    displayColorPicker: false
  };
  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };
  render() {
    const {
      input,
      label,
      className,
      id,
      labelAfter,
      width,
      meta: { touched, error, warning }
    } = this.props;
    const style = () => {
      if (width) {
        return {
          width: `${width}%`
        };
      }
      return {};
    };
    const labelClass = classNames('form__label-color', className);
    const fieldClass = classNames('form__field-color', className);

    return (
      <div className="form__row-color" style={style()}>
        <label className={labelClass} htmlFor={id}>
          {label}
        </label>
        <div className={fieldClass}>
          <div className="form__field-color--swatch" onClick={this.handleClick}>
            <div
              className="form__field-color--color"
              style={{ backgroundColor: `${input.value}` }}
            />
          </div>
          {this.state.displayColorPicker ? (
            <div className="form__field-color--popover">
              <div
                className="form__field-color--cover"
                onClick={this.handleClose}
              />
              <ChromePicker
                disableAlpha={true}
                onChange={color => input.onChange(color.hex)}
                color={input.value}
              />
            </div>
          ) : null}

          <input {...input} id={id} type="hidden" />
          {touched &&
            ((error && <span className="form__error">{error}</span>) ||
              (warning && <span className="form__error">{warning}</span>))}
        </div>
        <label className={labelClass} htmlFor={id}>
          {labelAfter}
        </label>
      </div>
    );
  }
}

export default FormFieldColor;
