import React, { Fragment } from 'react';
import Form, { FormField, FormNote, FormSectionHeader } from '../Form/Form';
import { Field, reduxForm } from 'redux-form';
import { maxLength } from '../../utils/validators';
import { Price } from '../Utils/Utils';
const maxLength255 = maxLength(255);
let RefundForm = props => {
  const {
    handleSubmit,
    onCancel,
    submitting,
    totalFaceValueRefund,
    totalOutsideFeeRefund,
    currency,
    faceValueRefundType,
    outsideFeeRefundType,
    hasOutsideFee,
    hasValue
  } = props;
  return (
    <Form
      handleSubmit={handleSubmit}
      submitting={submitting}
      onCancel={onCancel}
      submitText={'Refund'}
      formTitle="Refund Order"
      guidanceNotes="Use the form below to refund the order - this action cannot be undone"
    >
      {hasValue && (
        <Fragment>
          <FormNote bold>Face Value Refund</FormNote>
          <Field
            name="faceValueRefundType"
            component={FormField}
            labelAfter={'Full Refund'}
            type="radio"
            value="full"
            inline
          />
          <Field
            name="faceValueRefundType"
            component={FormField}
            labelAfter={'Partial Refund'}
            type="radio"
            value="partial"
            inline
          />
          <Field
            name="faceValueRefundType"
            component={FormField}
            labelAfter={'None'}
            type="radio"
            value="none"
            inline
          />
          {faceValueRefundType === 'partial' && (
            <Field
              name="faceValueRefundAmount"
              label={`Max Refund Amount (per item)`}
              component={FormField}
              type="number"
              step="any"
              inline
            />
          )}
          <FormNote>
            <span>
              Total Face Value Refund:{' '}
              <Price
                amount={totalFaceValueRefund}
                currency={currency}
                showZero
              />
            </span>
          </FormNote>
        </Fragment>
      )}
      {hasOutsideFee && (
        <Fragment>
          <FormSectionHeader title={`Booking Fee Refund`} />
          <Field
            name="outsideFeeRefundType"
            component={FormField}
            labelAfter={'Full Refund'}
            type="radio"
            value="full"
            inline
          />
          <Field
            name="outsideFeeRefundType"
            component={FormField}
            labelAfter={'Partial Refund'}
            type="radio"
            value="partial"
            inline
          />
          <Field
            name="outsideFeeRefundType"
            component={FormField}
            labelAfter={'None'}
            type="radio"
            value="none"
            inline
          />
          {outsideFeeRefundType === 'partial' && (
            <Field
              name="outsideFeeRefundAmount"
              label={`Max Booking Fee Refund Amount (per item)`}
              component={FormField}
              type="number"
              step="any"
              inline
            />
          )}
          <FormNote>
            <span>
              Total Booking Fee Refund:{' '}
              <Price
                amount={totalOutsideFeeRefund}
                currency={currency}
                showZero
              />
            </span>
          </FormNote>
        </Fragment>
      )}
      {hasValue && (
        <Field
          name="note"
          component={FormField}
          type="textarea"
          validate={[maxLength255]}
          rows={3}
          placeholder={'Add details about this refund'}
        />
      )}
      <Field
        name="cancelReceipts"
        labelAfter={`Cancel Receipts?`}
        id="cancelReceipts"
        component={FormField}
        type="checkbox"
        hintAfter="This will cancel the order so it will not appear on guest lists. In the case of tickets it will also void and release any tickets so that they can be re-purchased"
      />
      <Field
        name="refundToVoucher"
        labelAfter={`Refund to Voucher?`}
        id="refundToVoucher"
        component={FormField}
        type="checkbox"
        hintAfter="The Customer will receive a voucher code by email, redeemable against future ticket purchases"
      />
    </Form>
  );
};

RefundForm = reduxForm({
  form: 'refund'
})(RefundForm);
export default RefundForm;
