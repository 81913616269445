import { createRoutine } from 'redux-saga-routines';
import { takeEvery, takeLatest } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { fetchEntity } from '../shared/operations';
import * as api from '../../services/api';
import paginate from '../shared/paginate';
import * as fromPaginator from '../shared/paginate';
import * as fromEntities from '../shared/entities';

const pagedList = (entityName, apiMethod) => {
  const pluralEntity = `${entityName}s`;
  const type = `line-up/${pluralEntity}/FETCH_${pluralEntity.toUpperCase()}`;
  const fetchData = createRoutine(type);
  const fetchPage = fetchEntity.bind(null, fetchData, apiMethod);
  const actions = {
    fetchData,
    fetchPage
  };
  const watchFetch = function* watchFetch() {
    yield takeEvery(fetchData.TRIGGER, fetchPage);
  };
  const operations = {
    fetchPage,
    watchFetch
  };
  const byPage = paginate({
    mapActionToKey: action => action.page,
    types: [fetchData.REQUEST, fetchData.SUCCESS, fetchData.FAILURE]
  });
  const reducers = combineReducers({
    byPage
  });
  const getEntity = (state, entityId) =>
    fromEntities.getEntity(state.entities[entityName], entityId);
  const getEntitiesForPage = (state, page) => {
    const ids = fromPaginator.getIdsForPage(state[pluralEntity].byPage, page);
    return ids.map(id => getEntity(state, id));
  };
  const getCurrentPage = state =>
    fromPaginator.getCurrentPage(state[pluralEntity].byPage);
  const getTotalPages = state =>
    fromPaginator.getTotalPages(state[pluralEntity].byPage);
  const getIsLoadingForPage = (state, page) =>
    fromPaginator.getIsLoading(state[pluralEntity].byPage, page);
  const selectors = {
    getEntity,
    getEntitiesForPage,
    getCurrentPage,
    getTotalPages,
    getIsLoadingForPage
  };
  return {
    reducers,
    operations,
    selectors,
    actions
  };
};
const apiCall = (entityName, apiMethod, method = 'FETCH') => {
  const type = `line-up/${entityName}/EXPORT`;
  const routine = createRoutine(type);
  const opeation = fetchEntity.bind(null, routine, apiMethod);
  const watch = function* watch() {
    yield takeLatest(routine, opeation);
  };
  return {
    routine,
    operation: watch
  };
};
const { selectors, reducers, ...fetchPagedList } = pagedList(
  'form',
  api.fetchForms
);
const exportFormResponses = apiCall(
  'form-response',
  api.exportFormResponses,
  'EXPORT'
);

export const formActions = {
  ...fetchPagedList.actions,
  exportFormResponses: exportFormResponses.routine
};
export const formOperations = {
  ...fetchPagedList.operations,
  watchExportFormResponses: exportFormResponses.operation
};
export { selectors as formSelectors };
export default reducers;
