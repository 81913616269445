import React from 'react';
// Grommet
import {
  Box,
  Button,
  Image,
  Heading,
  Paragraph,
  ResponsiveContext,
} from 'grommet';

// Assets
import emptyStripe from './empty_stripe.png';

const StripeSettingsEmptyState = ({ onStripeConnectClick }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          margin={{ vertical: 'medium' }}
          background='topbar'
          justify={size !== 'small' ? 'between' : 'center'}
          round='xsmall'
          direction={size !== 'small' ? 'row' : 'column'}
          wrap={false}
          pad='large'
          align={size !== 'small' ? 'start' : 'center'}
          gap='large'
          height={size !== 'small' ? 'none' : { min: '490px' }}
        >
          <Box
            direction='column'
            width={size !== 'small' ? { max: '500px' } : { max: '100%' }}
            margin={size !== 'small' ? { top: 'large' } : 'none'}
          >
            <Heading
              level={size !== 'small' ? '2' : '3'}
              size={size !== 'small' ? 'small' : 'xsmall'}
              margin={size !== 'small' ? 'none' : { top: 'none' }}
            >
              Start Selling Tickets
            </Heading>
            <Paragraph size={size !== 'small' ? 'medium' : 'small'}>
              To sell tickets, you need to create/connect a Stripe account.
              Stripe is a globally-trusted payments processor that is secure,
              easy to use and will automatically transfer ticket sale funds into
              your bank account.
            </Paragraph>

            <Box width='medium' margin={{ top: 'medium' }}>
              <Button
                primary
                onClick={onStripeConnectClick}
                label='Create / Connect a Stripe Account'
                size={size !== 'small' ? 'medium' : 'small'}
              />
            </Box>
          </Box>
          <Box
            width={
              size !== 'small'
                ? { min: '450px', max: '450px' }
                : { min: '80%', max: '80%' }
            }
          >
            <Image
              src={emptyStripe}
              alt='Connect to Stripe'
              title='Connect to Stripe'
              fit='contain'
              fill
            />
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default StripeSettingsEmptyState;
