import React, { Component } from 'react';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import classNames from 'classnames';
import { searchTags } from '../../../services/api';

class TagSelect extends Component {
  loadOptions = (inputValue, callback) => {
    const tags = searchTags({
      query: inputValue,
    }).then(({ response, error }) =>
      response.map(tag => ({
        label: tag.name,
        value: tag.id,
      }))
    );
    return tags;
  };
  handleChange = (value, { action }) => {
    this.props.input.onChange(value);
  };
  render() {
    const {
      input,
      width,
      label,
      className,
      id,
      placeholder,
      hintBefore,
      labelAfter,
      meta: { touched, error, warning },
    } = this.props;

    const rowClass = classNames('form__row', className);

    const style = () => {
      if (width) {
        return {
          width: `${width}%`,
        };
      }
      return {};
    };

    const styles = {
      container: styles => ({
        ...styles,
        fontFamily: 'lato, sans serif',
        fontSize: '0.85rem',
        marginTop: '0',
        marginBottom: '0',
      }),
      control: styles => ({
        ...styles,
        backgroundColor: 'white',
        borderWidth: '0.09rem',
        borderColor: '#ddd',
        borderRadius: '0.2rem',
      }),
      valueContainer: styles => ({
        ...styles,
      }),
    };

    return (
      <div className={rowClass} style={style()}>
        <label className='form__label' htmlFor={id}>
          {label}
        </label>
        {hintBefore && (
          <div className='form__hint form__hint--before'>{hintBefore}</div>
        )}
        <AsyncCreatableSelect
          value={input.value}
          onChange={this.handleChange}
          cacheOptions
          loadOptions={this.loadOptions}
          onBlur={() => null}
          styles={styles}
          isMulti
          isClearable
          isCreatable
          placeholder={placeholder}
        />
        {touched &&
          ((error && <span className='form__error'>{error}</span>) ||
            (warning && <span className='form__error'>{warning}</span>))}
        <label className='form__label' htmlFor={id}>
          {labelAfter}
        </label>
      </div>
    );
  }
}

export default TagSelect;
