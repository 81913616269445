import React from 'react';
import { FormattedNumber } from 'react-intl';
import styles from './ChangePlanSummary.module.css';
import Button from 'components/Button/Button';
/* eslint-disable react/style-prop-object */
const ChangePlanSummary = ({ plan, onConfirm }) => {
  const handleClick = evt => {
    onConfirm(plan);
  };

  return (
    <div className={styles.root} onClick={handleClick}>
      <div className={styles.title}>Your new plan</div>
      <div className={styles.row}>
        <div className={styles.name}>{plan.name}</div>
        <div className={styles.price}>
          <span className={styles.amount}>
            <FormattedNumber
              value={plan.amount}
              style='currency'
              currency={plan.currency}
            />
          </span>{' '}
          <span>per month + VAT</span>
        </div>
        <div className={styles.controls}>
          <Button type='button' onClick={handleClick} primary>
            Upgrade
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChangePlanSummary;
