import { takeEvery, put, call, fork } from 'redux-saga/effects';
import * as api from '../../services/api';
import * as actions from './actions';
import { fetchEntity } from '../shared/operations';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

const fetchSeatingCharts = fetchEntity.bind(
  null,
  actions.fetchSeatingCharts,
  api.fetchSeatingCharts
);

function* fetchPagedSeatingCharts(action) {
  let page = 1;
  const response = yield call(fetchSeatingCharts, page);
  if (response) {
    const { pagination } = response;
    while (page < pagination.totalPages) {
      page++;
      yield fork(fetchSeatingCharts, page);
    }
  }
}

function* fetchAllSeatingCharts(action) {
  yield put(showLoading());
  yield put(actions.fetchAllSeatingCharts.request());
  yield call(fetchPagedSeatingCharts, action);
  yield put(actions.fetchAllSeatingCharts.success());
  yield put(hideLoading());
}

export function* watchFetchAllSeatingCharts() {
  yield takeEvery(actions.fetchAllSeatingCharts.TRIGGER, fetchAllSeatingCharts);
}

const getSeatingUser = fetchEntity.bind(
  null,
  actions.getSeatingUser,
  api.getSeatingUser
);

export function* watchGetSeatingUser() {
  yield takeEvery(actions.getSeatingUser.TRIGGER, getSeatingUser);
}

const deleteSeatingChart = fetchEntity.bind(
  null,
  actions.deleteSeatingChart,
  api.deleteSeatingChart
);

export function* watchDeleteSeatingChart() {
  yield takeEvery(actions.deleteSeatingChart.TRIGGER, deleteSeatingChart);
}
