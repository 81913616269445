import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../../../components/Modal/Modal';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { hideModal } from '../../../modules/modal/modal';
import {
  ticketAllocationActions,
  ticketAllocationSelectors,
} from '../../../modules/ticketAllocation/ticketAllocation';
import { TicketAllocationForm } from '../../../components/Event/Event';
import { removeEmpty } from '../../../utils/forms';
import { agentActions, agentSelectors } from '../../../modules/agents/agents';
class TicketAllocationCreateModal extends Component {
  componentDidMount() {
    this.props.fetchAgentsPage({ page: 1 });
    this.props.allocationId &&
      this.props.fetchTicketAllocation({
        allocationId: this.props.allocationId,
      });
  }
  handleOnSubmit = values => {
    const alloctionData = {
      agentId: values.agent ? values.agent.value : null,
      description: values.description,
      quantity: values.quantity,
      ticketId: this.props.ticketId,
    };
    return this.props.allocationId
      ? this.props.onTicketAllocationEditSubmit({
          allocationId: this.props.allocationId,
          ...removeEmpty(alloctionData),
        })
      : this.props.onTicketAllocationCreateSubmit(removeEmpty(alloctionData));
  };
  handleSubmitSuccess = () => {
    this.props.onSubmitSuccess && this.props.onSubmitSuccess();
    this.props.hideModal();
  };
  render() {
    const { agents, allocation, hideModal, maxInventory } = this.props;
    const agentOptions = agents.map(d => ({
      value: d.id,
      label: d.company,
    }));
    const initialAgent =
      allocation && allocation.agent
        ? agentOptions.find(a => a.value === this.props.allocation.agent.id)
        : null;
    const initialValues = allocation
      ? {
          quantity: allocation.quantity,
          description: allocation.description,
          agent: initialAgent,
        }
      : null;
    return (
      <Modal onRequestClose={() => hideModal()}>
        <TicketAllocationForm
          onSubmit={this.handleOnSubmit}
          onSubmitSuccess={this.handleSubmitSuccess}
          onCancel={hideModal}
          maxInventory={maxInventory}
          allocation={allocation}
          agents={agentOptions}
          initialValues={initialValues}
        />
      </Modal>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const allocation = ownProps.allocationId
    ? ticketAllocationSelectors.getTicketAllocation(
        state,
        ownProps.allocationId
      )
    : null;

  return {
    allocation,
    agents: agentSelectors.getAgentsForPage(state, 1),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        onTicketAllocationCreateSubmit:
          ticketAllocationActions.sumbmitCreateTicketAllocationForm,
        onTicketAllocationEditSubmit:
          ticketAllocationActions.sumbmitUpdateTicketAllocationForm,
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        ...ticketAllocationActions,
        ...agentActions,
        hideModal,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketAllocationCreateModal);
