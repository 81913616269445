import {
  call,
  takeLatest,
  put,
  fork,
  select,
  takeEvery,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as types from './types';
import * as actions from './actions';
import * as api from '../../services/api';
import { ticketGroupSelectors } from '../../modules/ticketGroups/ticketGroups';
import { fetchEntity } from '../shared/operations';
function* updateDiscountCode(action) {
  const { discountId, discount, push } = action.payload;
  yield put(showLoading());
  yield put(startSubmit('discount'));
  yield put(actions.discountUpdate.request(discountId));
  const { response, error } = yield call(
    api.updateDiscount,
    discountId,
    discount
  );
  if (response) {
    yield put(actions.discountUpdate.success(discountId, response));
    yield put(stopSubmit('discount'));
    const newTicketGroupId = yield select(
      ticketGroupSelectors.getNewTicketGroupId
    );
    if (newTicketGroupId) {
      yield call(push, `/ticket-group/${newTicketGroupId}/tickets`);
    } else {
      yield call(push, `/discount/${discountId}`);
    }
  } else {
    yield put(stopSubmit('discount', error.errors));
    yield put(actions.discountUpdate.failure(discountId, error));
  }
  yield put(hideLoading());
}

export function* watchUpdateDiscount() {
  yield takeLatest(types.DISCOUNT_UPDATE, updateDiscountCode);
}

function* createDiscountCode(action) {
  yield put(showLoading());
  yield put(startSubmit('discount'));
  yield put(actions.discountCreate.request());
  const { response, error } = yield call(
    api.createDiscount,
    action.payload.discount
  );
  if (response) {
    yield put(actions.discountCreate.success(response));
    yield put(stopSubmit('discount'));
    const newTicketGroupId = yield select(
      ticketGroupSelectors.getNewTicketGroupId
    );
    if (newTicketGroupId) {
      yield call(
        action.payload.push,
        `/ticket-group/${newTicketGroupId}/tickets`
      );
    } else {
      yield call(action.payload.push, `/discount/${response.result}`);
    }
  } else {
    yield put(stopSubmit('discount', error.errors));
    yield put(actions.discountCreate.failure(error));
  }
  yield put(hideLoading());
}

export function* watchCreateDiscount() {
  yield takeLatest(types.DISCOUNT_CREATE, createDiscountCode);
}

function* fetchDiscount(action) {
  yield put(showLoading());
  yield put(actions.discount.request(action.payload.discountId));
  const { response, error } = yield call(
    api.getDiscount,
    action.payload.discountId
  );
  if (response) {
    yield put(actions.discount.success(action.payload.discountId, response));
  } else {
    yield put(actions.discount.failure(action.payload.discountId, error));
  }
  yield put(hideLoading());
}

export function* watchLoadDiscount() {
  yield takeLatest(types.LOAD_DISCOUNT, fetchDiscount);
}

const searchDiscounts = fetchEntity.bind(
  null,
  actions.searchDiscounts,
  api.searchDiscounts
);
export function* watchSearchDiscounts() {
  yield takeEvery(actions.searchDiscounts.TRIGGER, searchDiscounts);
}
