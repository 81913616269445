import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindPromiseCreators } from "redux-saga-routines";
import { bindActionCreators } from "redux";
import { SubmissionError } from "redux-form";

import MainContent, {
  Title,
  LineUpHelmet
} from "../../components/MainContent/MainContent";
import EmailEditorForm from "../../components/EmailEditor/EmailEditorForm";

import { emailActions } from "../../modules/emails/emails";

// Grommet
import { Box } from "grommet";

/* ignore import/no-webpack-loader-syntax */
import d from "./default.json";

const EmailCreate = ({ submitCreateEmailForm, dispatch }) => {
  const handleSave = values => {
    const normalizedValues = {
      ...values,
      html: values.code.html, // we need to pull out html & text
      text: values.code.text
    };
    return submitCreateEmailForm({ values: normalizedValues })
      .then(() => dispatch(push("/emails")))
      .catch(e => {
        throw new SubmissionError({
          code: e.errors._error
        });
      });
  };

  return (
    <>
      <LineUpHelmet
        title="New Email Template"
        description="Create a new email template in Line-Up"
      />
      <MainContent>
        <div>
          <Box direction="row" justify="between" align="center">
            <Title>New Email Template</Title>
          </Box>
          <Box margin={{ top: "medium", bottom: "large" }}>
            <EmailEditorForm
              onSubmit={handleSave}
              initialValues={{
                code: {
                  html: d["html"],
                  text: ""
                }
              }}
            />
          </Box>
        </div>
      </MainContent>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        submitCreateEmailForm: emailActions.submitCreateEmail
      },
      dispatch
    ),
    ...bindActionCreators(emailActions, dispatch),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailCreate);
