import React, { Fragment } from 'react';
import Form, {
  FormField,
  FormFieldReactSelect,
  FormSectionHeader,
  FormFieldDateTime,
} from '../Form/Form';
import TicketGroupSelect from '../TicketGroup/TicketGroupSelect';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Field, reduxForm } from 'redux-form';
import { discountSelectors } from '../../modules/discounts/discounts';
import './DiscountForm.css';

const upper = value => value && value.toUpperCase();
const interger = value => value && parseInt(value, 10);
const float = value => value && parseFloat(value, 10);

const validate = values => {
  const errors = {};
  if (!values.code) {
    errors.code = 'This is required';
  } else {
    if (values.code.length > 128) {
      errors.code = 'Discount code cannot be longer than 128';
    }
    if (!/^[-a-zA-Z0-9_]+$/i.test(values.code)) {
      errors.code =
        'Discount code can only contain alphanumeric characters, hyphens and underscores';
    }
  }
  if (values.description && values.description.length > 128) {
    errors.description = 'Discount description cannot be longer than 128';
  }
  if (values.amount === null || values.amount === '') {
    errors.amount = 'This is required';
  }
  if (
    values.amountType &&
    values.amountType.value !== 'price' &&
    values.amount === 0
  ) {
    errors.amount = 'Discount amount cannot be 0';
  }

  return errors;
};

const amountTypePercent = { value: 'percent', label: 'Percentage Discount' };
const amountTypeFixed = { value: 'fixed', label: 'Fixed Discount' };
const amountTypePrice = { value: 'price', label: 'Set ticket price to value' };
const amountTypes = [amountTypePercent, amountTypeFixed, amountTypePrice];
const amountTypesForVoucher = [amountTypePercent, amountTypeFixed];
let DiscountForm = props => {
  const {
    isUnlimited,
    isPromotion,
    amountType,
    discountType,
    handleSubmit,
    error,
    discountId,
    reset,
  } = props;
  return (
    <Form
      {...props}
      handleSubmit={handleSubmit}
      error={error}
      onCancel={reset}
      guidanceNotes={
        discountId
          ? 'Use the form below to edit the discount code'
          : 'Use the form below to create a new discount code'
      }
    >
      <Field
        name='code'
        label='Code'
        component={FormField}
        type='text'
        normalize={upper}
        placeholder='Type your code here, e.g. ALOHOMORA'
      />
      <Field
        name='description'
        label='Description'
        component={FormField}
        type='text'
        placeholder='Optional description of discount'
      />
      <Field
        name='enabled'
        label='Is Enabled?'
        id='enabled'
        component={FormField}
        type='checkbox'
        inline
      />
      <Field
        name='isPromotion'
        label='Is Agent Promotion?'
        component={FormField}
        type='checkbox'
        inline
      />
      <FormSectionHeader title='Discount Options:' />
      <Field
        name='unlimited'
        labelAfter='Unlimited Uses?'
        id='unlimited'
        component={FormField}
        type='checkbox'
      />
      {!isUnlimited && (
        <Field
          name='usesLeft'
          label='This Discount Code can be used'
          labelAfter='more time(s).'
          component={FormField}
          type='number'
          normalize={interger}
          placeholder='E.g. 50'
        />
      )}
      <Field
        name='removeBookingFee'
        component={FormField}
        labelAfter='Remove Outside Booking Fees?'
        type='checkbox'
      />
      <FormSectionHeader title='Discount applies to:' />
      {!isPromotion && (
        <Field
          name='discountType'
          component={FormField}
          labelAfter={'The total basket value'}
          type='radio'
          value='basket'
        />
      )}
      <Field
        name='discountType'
        component={FormField}
        labelAfter={'All tickets in the basket'}
        type='radio'
        value='all'
      />
      <Field
        name='discountType'
        component={FormField}
        labelAfter={'Only Tickets in the selected ticket groups'}
        type='radio'
        value='group'
      />
      {discountType === 'group' && (
        <Field
          name='ticketGroups'
          label='Ticket Groups - ticket groups represent a collection of tickets'
          component={TicketGroupSelect}
          placeholder='Select or create ticket groups...'
          labelAfter='You can add and remove tickets from a ticket group on the next step'
        />
      )}
      <Field
        name='amountType'
        component={FormFieldReactSelect}
        label={'Discount Type'}
        isClearable={false}
        options={
          discountType === 'basket' ? amountTypesForVoucher : amountTypes
        }
      />
      <Field
        name='amount'
        component={FormField}
        label={
          amountType === amountTypePrice
            ? 'New Ticket Price'
            : 'Discount Amount'
        }
        type='number'
        normalize={float}
        step='any'
        placeholder='E.g. 10'
        fieldShort={true}
      />
      {discountType === 'basket' && (
        <Field
          name='minBasketValue'
          component={FormField}
          label='The basket total must be at least'
          labelAfter=' for this discount to work.'
          type='number'
          normalize={float}
          step='any'
          placeholder='E.g. 10'
          inline
        />
      )}
      {discountType === 'basket' && amountType === amountTypePercent && (
        <Field
          name='maxDiscountAmount'
          component={FormField}
          label='Maximum discount amount (optional):'
          type='number'
          normalize={float}
          step='any'
          placeholder='E.g. 10'
          inline
        />
      )}
      {!isPromotion && (
        <Fragment>
          <FormSectionHeader title='Valid From/To (Optional):' />
          <div className='discount-form-info-date-time'>
            <Field
              name='validFrom'
              component={FormFieldDateTime}
              dateLabel='This discount is valid from date:'
              timeLabel='time (UTC):'
              inline
            />
          </div>
          <div className='discount-form-info-date-time'>
            <Field
              name='validTo'
              component={FormFieldDateTime}
              dateLabel='This discount is valid to date:'
              timeLabel='time (UTC):'
              inline
            />
          </div>
          {discountType !== 'basket' && (
            <>
              <FormSectionHeader title='Min/Max Rules (Optional):' />
              <Field
                name='minTicketQuantity'
                component={FormField}
                normalize={interger}
                label='The customer must have at least'
                labelAfter='ticket(s) in thier basket to use this discount.'
                type='number'
                placeholder='E.g. 3'
                inline
              />
              <Field
                name='maxUses'
                component={FormField}
                normalize={interger}
                label='This discount can be applied to'
                labelAfter=' ticket(s) per order.'
                placeholder='E.g. 1'
                type='number'
                inline
              />
            </>
          )}
        </Fragment>
      )}
      {isPromotion && (
        <Fragment>
          <FormSectionHeader title='Inside Commission Rules (Optional):' />
          <Field
            name='insideFeePercentage'
            component={FormField}
            step='any'
            label='Fee Percentage:'
            type='number'
            placeholder='0.1'
            inline
          />
          <Field
            name='insideFeeFixedValue'
            component={FormField}
            step='any'
            label='Fixed Fee:'
            type='number'
            placeholder='1.50'
            inline
          />
          <Field
            name='insideFeeTaxRate'
            component={FormField}
            step='any'
            label='Tax Rate:'
            type='number'
            placeholder='0.2'
            inline
          />
        </Fragment>
      )}
    </Form>
  );
};

DiscountForm = reduxForm({
  form: 'discount',
  validate,
})(DiscountForm);

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector('discount');
  const isUnlimited = selector(state, 'unlimited');
  const isPromotion = selector(state, 'isPromotion');
  const amountType = selector(state, 'amountType');
  const discountType = selector(state, 'discountType');
  const discountId = ownProps.discountId;
  let initialValues;
  if (ownProps.discountId) {
    const discount = discountSelectors.getDiscount(state, discountId);
    if (discount.code) {
      initialValues = {
        ...discount,
        amountType:
          discount.fixedDiscount > 0
            ? amountTypeFixed
            : discount.ticketPrice !== null
            ? amountTypePrice
            : amountTypePercent,
        amount:
          discount.fixedDiscount > 0
            ? discount.fixedDiscount
            : discount.ticketPrice !== null
            ? discount.ticketPrice
            : discount.percentageDiscount * 100,
        ticketGroups: discount.ticketGroups
          ? discountSelectors
              .getTicketGroupsForDiscount(state, discountId)
              .map(v => ({ label: v.name, value: v.slug }))
          : [],
        enabled: discount.status === 'active',
        validFrom: discount.validFrom
          ? new Date(discount.validFrom)
          : undefined,
        validTo: discount.validTo ? new Date(discount.validTo) : undefined,
      };
    }
  } else {
    initialValues = {
      unlimited: true,
      amountType: amountTypePercent,
      discountType: 'all',
      enabled: true,
    };
  }
  return {
    isUnlimited,
    isPromotion,
    amountType,
    discountType,
    initialValues,
  };
};

export default connect(mapStateToProps)(DiscountForm);
