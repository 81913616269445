import React from 'react';
import { connect } from 'react-redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { bindActionCreators } from 'redux';
import { FormattedDate } from 'react-intl';
import EmptyState from '../../components/EmptyState/EmptyState';
import styled from 'styled-components';

import MainContent, {
  Title,
  HeaderActions,
  PageTop,
  LineUpHelmet,
} from '../../components/MainContent/MainContent';
import TableView from '../table/TableView';

import { emailSelectors, emailActions } from '../../modules/emails/emails';
import createEmails from './empty_emails.png';

// Grommet
import { Box, Button } from 'grommet';
import { Add } from 'grommet-icons';

const DeleteButton = styled(Button)`
  background-color: #ff2e4a;
  &:hover {
    background-color: #d41c34;
  }
`;

const columns = onDelete => [
  {
    title: 'Subject',
    dataIndex: 'subject',
    width: '40',
  },
  {
    title: 'Date Created',
    dataIndex: 'createdAt',
    render: date => (
      <FormattedDate
        value={date}
        weekday='short'
        year='numeric'
        
        day='numeric'
        month='short'
        hour='numeric'
        minute='numeric'
      />
    ),
  },
  {
    title: 'Last Updated',
    dataIndex: 'updatedAt',
    render: date => (
      <FormattedDate
        value={date}
        weekday='short'
        year='numeric'
        
        day='numeric'
        month='short'
        hour='numeric'
        minute='numeric'
      />
    ),
  },
  {
    title: '',
    render: (text, record) => (
      <Box gap='small' direction='row' justify='end'>
        <Button
          href={`/email/${record.id}`}
          secondary
          size='small'
          label='Edit'
        />

        <DeleteButton
          onClick={() => onDelete(record)}
          primary
          size='small'
          label='Delete'
        />
      </Box>
    ),
    align: 'center',
    width: '20',
  },
];

const EmailListEmptyState = ({ showButton, onButtonClick }) => (
  <EmptyState
    background
    buttonText='Create an Email Template'
    onButtonClick={onButtonClick}
    showButton={showButton}
    emptyStateHeadline='You have not created any pre/post show email templates'
    emptyStateDescription='Create pre and post show email templates now'
    emptyStateImageSrc={createEmails}
    emptyStateImageAlt='No emails created'
    emptyStateImageTitle='No emails created'
  />
);

const EmailList = props => {
  const {
    history,
    fetchData,
    currentPage,
    totalPages,
    data,
    submitDeleteEmailForm,
    isLoading,
  } = props;
  const handleSelectPage = page => {
    history.push(`/emails/${page}`);
  };
  const handleDelete = email => {
    submitDeleteEmailForm({ emailId: email.id }).then(() =>
      fetchData({ page: currentPage })
    );
  };
  const handleCreateEmail = () => {
    history.push(`/email/create`);
  };
  return (
    <>
      <LineUpHelmet
        title='Email Templates'
        description='Manage your Line-Up email templates'
      />
      <MainContent>
        <PageTop>
          <Title>Your Email Templates</Title>
          <HeaderActions>
            <Button
              primary
              href='/email/create'
              label='New Email Template'
              size='small'
              icon={<Add size='small' />}
            />
          </HeaderActions>
        </PageTop>
        <Box margin={{ vertical: 'medium' }}>
          <TableView
            isLoading={isLoading}
            emptyStateComponent={
              <EmailListEmptyState
                showButton={true}
                onButtonClick={handleCreateEmail}
              />
            }
            columns={columns(handleDelete)}
            data={data}
            totalPages={totalPages}
            currentPage={currentPage}
            fetchData={fetchData}
            onPageClick={handleSelectPage}
          />
        </Box>
      </MainContent>
    </>
  );
};
const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const page = parseInt(match.params.page, 10) || 1;
  return {
    currentPage: page,
    data: emailSelectors.getEntitiesForPage(state, page),
    totalPages: emailSelectors.getTotalPages(state),
    isLoading: emailSelectors.getIsLoadingForPage(state, page),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        submitDeleteEmailForm: emailActions.submitDeleteEmail,
      },
      dispatch
    ),
    ...bindActionCreators(emailActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailList);
