import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Form, { FormField, FormRow, FormSectionHeader } from '../../Form/Form';
import { required } from '../../../utils/validators';
import { AddressLookup } from '../../AddressLookup/AddressLookup';
let VenueForm = props => {
  const { handleSubmit, submitting, error, reset, change } = props;
  const handleAddressRetrieve = ({
    name,
    street,
    town,
    city,
    postcode,
    country,
    lattitude,
    longitude,
  }) => {
    change('name', name);
    change('street', street);
    change('town', town);
    change('city', city);
    change('postcode', postcode);
    change('country', country);
    change('lattitude', lattitude);
    change('longitude', longitude);
  };
  const handleAddressClear = () => {
    reset();
  };
  return (
    <Form
      {...props}
      submitText={'Submit'}
      handleSubmit={handleSubmit}
      onCancel={reset}
      submitting={submitting}
      error={error}
      guidanceNotes='Use the form below to create a new venue'
      formTitle={'Add Venue'}
    >
      <AddressLookup
        onAddressRetrieve={handleAddressRetrieve}
        onAddressClear={handleAddressClear}
      />
      <FormSectionHeader title='Address' />
      <Field
        name='name'
        label='Venue Name'
        component={FormField}
        type='text'
        placeholder='Type your venue name here'
        validate={[required]}
      />
      <Field
        name='street'
        label='Street'
        component={FormField}
        type='text'
        placeholder='Street Name'
        validate={[required]}
      />
      <FormRow>
        <Field
          name='town'
          label='Town'
          component={FormField}
          type='text'
          placeholder='Town'
          validate={[required]}
        />
        <Field
          name='city'
          label='City'
          component={FormField}
          type='text'
          placeholder='City'
          validate={[required]}
        />
      </FormRow>
      <FormRow>
        <Field
          name='country'
          label='Country'
          component={FormField}
          type='text'
          placeholder='Country'
          validate={[required]}
        />
        <Field
          name='postcode'
          label='Postcode'
          component={FormField}
          type='text'
          placeholder='Postcode'
          validate={[required]}
        />
      </FormRow>
      <Field
        name='lattitude'
        component='input'
        type='hidden'
        validate={[required]}
      />
      <Field
        name='longitude'
        component='input'
        type='hidden'
        validate={[required]}
      />
    </Form>
  );
};

VenueForm = reduxForm({
  form: 'venue',
})(VenueForm);

export default VenueForm;
