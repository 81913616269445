import { createAction } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';
import * as types from './types';

export const exportDonations = createRoutine(types.EXPORT_DONATIONS);

export const loadDonationsPage = createAction(types.LOAD_PAGE);

export const donations = {
  request: createAction(types.DONATIONS.REQUEST, page => ({ page })),
  success: createAction(types.DONATIONS.SUCCESS, (page, response) => ({
    page,
    response,
  })),
  failure: createAction(types.DONATIONS.FAILURE, (page, error) => ({
    page,
    error,
  })),
};
