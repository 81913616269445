import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';

import Modal from 'components/Modal/Modal';
import Form from 'components/Form/Form';

import { hideModal } from 'modules/modal/modal';

const LockLaneConfirmModal = ({ hideModal, lane, onLockChange, isLocked }) => {
  const handleSubmit = () => {
    onLockChange({
      ...lane,
      isLocked: !isLocked
    });
  };

  return (
    <Modal onRequestClose={() => hideModal()}>
      <LockLaneForm
        isLocked={isLocked}
        lane={lane}
        onCancel={hideModal}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

let LockLaneForm = ({ lane, onCancel, handleSubmit, isLocked }) => (
  <>
    {isLocked ? (
      <Form
        formTitle="Are you sure you want to unlock this lane?"
        submitText="Yes, unlock this lane"
        onCancel={onCancel}
        handleSubmit={handleSubmit}
      >
        You are about to unlock this lane. This will mean:
        <ul>
          <li>
            Bookings can be added, and removed from this lane by your team
          </li>
          <li>New bookings will be automatically placed here</li>
        </ul>
      </Form>
    ) : (
      <Form
        formTitle="Are you sure you want to lock this lane?"
        submitText="Yes, lock this lane"
        handleSubmit={handleSubmit}
        onCancel={onCancel}
      >
        You are about to lock this lane. This will mean:
        <ul>
          <li>Existing bookings for this lane will be locked in place</li>
          <li>New bookings will not be automatically placed here</li>
          <li>
            Your team members will not be able to move any bookings into this
            lane
          </li>
        </ul>
      </Form>
    )}
  </>
);

LockLaneForm = reduxForm({
  form: 'lockLaneConfirmForm'
})(LockLaneForm);

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        hideModal
      },
      dispatch
    )
  };
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LockLaneConfirmModal);
