import moment from 'moment';
export const getPerformanceStartDate = performance =>
  performance.startTime
    ? moment(performance.startDate + ' ' + performance.startTime).toDate()
    : moment(performance.startDate).toDate();

export const getPerformanceEndDate = performance =>
  performance.endTime
    ? moment(performance.endDate + ' ' + performance.endTime).toDate()
    : moment(performance.endDate).toDate();

export const parsePerformanceDateToMoment = date =>
  moment.utc(date, 'YYYY-MM-DD');
export const parsePerformanceTimeToMoment = time =>
  moment.utc(time, 'HH:mm:ss');

// Given an unsorted list of dates, the first date that is in the future
export const getFirstFutureDate = dates => {
  const today = moment().startOf('day');
  const sortedFutureDates = dates
    .filter(date => date >= today)
    .sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });

  if (sortedFutureDates.length > 0) {
    return sortedFutureDates[0];
  }

  return null;
};
