import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { venueActions } from '../../modules/venues/venues';
import VenueForm from '../../components/Event/VenueForm/VenueForm';
import Modal from '../../components/Modal/Modal';
import { hideModal } from '../../modules/modal/modal';

const VenueCreateModal = props => {
  const { hideModal, onVenueCreateSubmit, onVenueCreateSuccess } = props;
  const handleVenueFormSubmit = values => {
    const venueValues = {
      name: values.name,
      lat: values.lattitude,
      lng: values.longitude,
      address: {
        street: values.street,
        locality: values.town,
        region: values.city,
        country: values.country,
        postcode: values.postcode
      }
    };
    return onVenueCreateSubmit(venueValues);
  };

  const handleSubmitSuccess = ({ response }) => {
    hideModal();
    onVenueCreateSuccess && onVenueCreateSuccess(response);
  };
  return (
    <Modal onRequestClose={() => hideModal()}>
      <VenueForm
        onSubmit={handleVenueFormSubmit}
        onSubmitSuccess={handleSubmitSuccess}
        onCancel={hideModal}
      />
    </Modal>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        onVenueCreateSubmit: venueActions.sumbmitCreateVenueForm
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        hideModal
      },
      dispatch
    )
  };
};

export default connect(
  null,
  mapDispatchToProps
)(VenueCreateModal);
