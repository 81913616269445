import { combineReducers } from 'redux';
import paginate from '../shared/paginate';
import * as actions from './actions';
//- Reducers

const byPage = paginate({
  mapActionToKey: action => action.page,
  types: [
    actions.fetchAgentsPage.REQUEST,
    actions.fetchAgentsPage.SUCCESS,
    actions.fetchAgentsPage.FAILURE,
  ],
});

const invoicesByPage = paginate({
  mapActionToKey: action => action.page,
  types: [
    actions.fetchInvoices.REQUEST,
    actions.fetchInvoices.SUCCESS,
    actions.fetchInvoices.FAILURE,
  ],
});
const invoicesByAgent = (state = {}, action) => {
  switch (action.type) {
    case actions.fetchInvoices.REQUEST:
    case actions.fetchInvoices.SUCCESS:
    case actions.fetchInvoices.FAILURE:
      const agentId = action.payload.agentId;
      return {
        ...state,
        [agentId]: invoicesByPage(state[agentId], action),
      };
    default:
      return state;
  }
};
const loading = (state = false, action) => {
  switch (action.type) {
    case actions.fetchAgentsPage.REQUEST:
      return true;
    case actions.fetchAgentsPage.SUCCESS:
    case actions.fetchAgentsPage.FAILURE:
      return false;
    default:
      return state;
  }
};
const agents = combineReducers({
  byPage,
  invoicesByAgent,
  loading,
});

export default agents;
