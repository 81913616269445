import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './CardDetail.module.css';

const CardDetail = ({ card }) => (
  <div className={styles.root}>
    <FontAwesomeIcon className={styles.icon} icon="credit-card" />{' '}
    <span className={styles.brand}>{card.brand}.</span>{' '}
    <span className={styles.last4}>Last 4 digits: {card.last4}.</span>{' '}
    <span className={styles.expiry}>
      Expiry: {card.expMonth}/{card.expYear}.
    </span>
  </div>
);

export default CardDetail;
