import React from 'react';
import Table, { TableCell, TableHeader, TableBody } from '../../../Table/Table';
import DonationRowItem from './DonationRowItem/DonationRowItem';

const ReceiptTable = ({ donation, selectedReceipts, onReceiptClick }) => (
  <Table mobileWide>
    <TableHeader className="receipt__header">
      <TableCell width={5} />
      <TableCell width={45}>Donation Amount</TableCell>
      <TableCell width={40}>Total</TableCell>
      <TableCell width={10}>Status</TableCell>
    </TableHeader>
    <TableBody
      renderItem={donation => (
        <DonationRowItem
          key={donation.id}
          donation={donation}
          onSelect={onReceiptClick}
          isSelected={!!selectedReceipts.includes(donation.id)}
        />
      )}
      items={[donation]}
    />
  </Table>
);
export default ReceiptTable;
