import React from 'react';
import './InvoiceAddress.css';

const InvoiceAddress = ({ company, address, vatNumber }) => {
  return (
    <div class="invoice-address">
      {company && (
        <div class="invoice-address__item invoice-address__item--company">
          {company}
        </div>
      )}
      {address && <div class="invoice-address__item">{address.street}</div>}
      {address && <div class="invoice-address__item">{address.locality}</div>}
      {address && <div class="invoice-address__item">{address.region}</div>}
      {address && <div class="invoice-address__item">{address.postcode}</div>}
      {vatNumber && <div class="invoice-address__item">{vatNumber}</div>}
    </div>
  );
};

export default InvoiceAddress;
