import React from 'react';
import styles from './DropdownMenu.module.css';

// Grommet
import { Button } from 'grommet';
import { Down } from 'grommet-icons';

const DropdownMenu = ({ label, title, children }) => {
  const rootNode = React.useRef(null);
  const [state, setState] = React.useState({
    open: false,
  });

  const windowClickHandler = evt => {
    if (isDescendant(rootNode.current, evt.target)) {
      return;
    }
    setState({
      ...state,
      open: false,
    });
  };

  const onClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  const handleOpen = evt => {
    evt.preventDefault();
    setState({
      ...state,
      open: !state.open,
    });
  };

  const style = {
    display: state.open ? 'block' : 'none',
  };
  React.useEffect(() => {
    window.addEventListener('click', windowClickHandler);
    return () => {
      window.removeEventListener('click', windowClickHandler);
    };
  }, []);
  return (
    <div className={styles.root} ref={rootNode}>
      <div className={styles.label}>
        <Button
          plain
          href='#menuLabel'
          onClick={handleOpen}
          label={label}
          icon={<Down size='small' color='white' />}
          size='small'
        />
      </div>
      <div className={styles.content} style={style}>
        <div className={styles.header}>{title}</div>
        <div className={styles.children}>{children({ onClose })}</div>
      </div>
    </div>
  );
};

const isDescendant = (parent, child) => {
  var node = child.parentNode;
  while (node != null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

export default DropdownMenu;
