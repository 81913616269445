export const FETCH_TICKET = 'line-up/ticket/FETCH_TICKET';
export const FETCH_TICKETS = 'line-up/ticket/FETCH_TICKETS';
export const CREATE_TICKET = 'line-up/ticket/CREATE_TICKET';
export const DELETE_TICKET = 'line-up/ticket/DELETE_TICKET';

export const UPDATE_TICKET = 'line-up/ticket/UPDATE_TICKET';
export const FETCH_USER_SETTINGS = 'line-up/ticket/FETCH_USER_SETTINGS';
export const CREATE_TICKET_TRANSFER = 'line-up/ticket/CREATE_TICKET_TRANSFER';
export const SELECT_PERFORMANCE_FOR_PURCHASE =
  'line-up/ticket/SELECT_PERFORMANCE_FOR_PURCHASE';
export const SELECT_TICKET_FOR_PURCHASE =
  'line-up/ticket/SELECT_TICKET_FOR_PURCHASE';
export const SELECT_SEAT_FOR_PURCHASE =
  'line-up/ticket/SELECT_SEAT_FOR_PURCHASE';
export const UNSELECT_SEAT_FOR_PURCHASE =
  'line-up/ticket/UNSELECT_SEAT_FOR_PURCHASE';
