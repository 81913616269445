import React, { Component } from 'react';
import { connect } from 'react-redux';
import MainContent, { Title } from '../../components/MainContent/MainContent';
import { InvoiceTable } from '../../components/Invoice/Invoice';
import {
  invoiceActions,
  invoiceSelectors
} from '../../modules/invoices/invoices';
import Pagination from '../../components/Pagination/Pagination';

class InvoiceList extends Component {
  componentDidMount() {
    this.props.fetchInvoices({ page: this.props.currentPage });
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentPage !== prevProps.currentPage) {
      this.props.fetchInvoices(this.props.currentPage);
    }
  }

  handleInvoiceClick = invoiceId => {
    this.props.history.push(`/account/invoice/${invoiceId}/`);
  };
  // handleDiscountClick = discount => {
  //   this.props.history.push(`/discount/${discount.id}`);
  // };
  // handleSelectPage = page => {
  //   this.props.history.push(`/discounts/${page}`);
  // };

  render() {
    const { invoices, currentPage, totalPages } = this.props;
    return (
      <MainContent>
        <Title>Invoices</Title>
        <InvoiceTable
          invoices={invoices}
          onBtnClick={this.handleDownloadInvoice}
          onRowClick={this.handleInvoiceClick}
        />
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onSelectPage={this.handleSelectPage}
        />
      </MainContent>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const page = parseInt(match.params.page, 10) || 1;
  return {
    invoices: invoiceSelectors.getInvoicesForPage(state, page),
    currentPage: page,
    totalPages: invoiceSelectors.getInvoicesTotalPages(state)
  };
};

export default connect(
  mapStateToProps,
  {
    ...invoiceActions
  }
)(InvoiceList);
