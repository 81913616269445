import React from 'react';
import classNames from 'classnames';
import { searchDiscounts } from '../../services/api';
import Async from 'react-select/lib/Async';
const DiscountSelect = ({
  input,
  width,
  label,
  className,
  id,
  placeholder,
  hintBefore,
  labelAfter,
  meta: { submitting, touched, error, warning },
}) => {
  const loadOptions = (inputValue, callback) => {
    const discounts = searchDiscounts({
      query: inputValue,
      discountType: 'agent',
    }).then(({ response, error }) => {
      return response.result.map((id) => ({
        label: response.entities.discount[id].code,
        value: response.entities.discount[id].id,
      }));
    });
    return discounts;
  };
  const handleChange = (value, { action }) => {
    input.onChange(value);
  };

  const rowClass = classNames('form__row', className);

  const style = () => {
    if (width) {
      return {
        width: `${width}%`,
      };
    }
    return {};
  };

  const styles = {
    container: (styles) => ({
      ...styles,
      fontFamily: 'lato, sans serif',
      fontSize: '0.85rem',
      marginTop: '0',
      marginBottom: '0',
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: 'white',
      borderWidth: '0.09rem',
      borderColor: '#ddd',
      borderRadius: '0.2rem',
    }),
    valueContainer: (styles) => ({
      ...styles,
    }),
  };

  return (
    <div className={rowClass} style={style()}>
      <label className='form__label' htmlFor={id}>
        {label}
      </label>
      {hintBefore && (
        <div className='form__hint form__hint--before'>{hintBefore}</div>
      )}
      <Async
        value={input.value}
        onChange={handleChange}
        cacheOptions
        isMulti
        defaultOptions={true}
        loadOptions={loadOptions}
        onBlur={() => null}
        styles={styles}
        isClearable
        placeholder={placeholder}
      />
      {touched &&
        ((error && <span className='form__error'>{error}</span>) ||
          (warning && <span className='form__error'>{warning}</span>))}
      <label className='form__label' htmlFor={id}>
        {labelAfter}
      </label>
    </div>
  );
};

export default DiscountSelect;
