import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { threeColumns as data } from './data';

// Components
import StarLogo from '../../StarLogo/StarLogo';
import Emoji from '../../Emoji/Emoji';

// Grommet
import { Anchor, Box, Footer, Image, Text, ResponsiveContext } from 'grommet';

// Assets
import logo from 'assets/sidebar_icon.png';

const FooterCapterraLink = styled.a`
  width: 100%;
`;

const StyledAnchor = styled(Anchor)`
  font-weight: 200;
  color: #444444;
  text-decoration: none;
  font-size: 13px;
`;

const StyledImage = styled(Image)`
  width: 100%;
`;

const FooterAnchor = ({ ...rest }) => (
  <StyledAnchor as={Link} size='small' color='white' {...rest} />
);

const FooterContent = () => {
  return data.map(item => (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          gap='medium'
          key={item[0]}
          margin={size !== 'small' ? '0' : { top: 'large' }}
        >
          <Text weight='900' size='small'>
            {item[0]}
          </Text>
          <Box gap='small'>
            {[1, 2].map(i => (
              <FooterAnchor to={item[i].link} key={item[i].title}>
                {item[i].title}
              </FooterAnchor>
            ))}
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  ));
};

const PublicFooter = () => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Footer
          background='light-1'
          pad={size !== 'small' ? 'xlarge' : 'large'}
          border={{
            side: 'top',
            color: 'border',
            style: 'solid',
            size: 'xsmall'
          }}
          align='start'
          direction={size !== 'small' ? 'row-reverse' : 'column'}
        >
          <FooterContent />
          <Box
            direction='row-responsive'
            gap='xsmall'
            margin={size !== 'small' ? '0' : { top: 'large' }}
          >
            <Box align='start' gap='small'>
              <Box direction='row' gap='medium' align='center'>
                <Box
                  width={'45px'}
                  height={'45px'}
                  pad='none'
                  justify='start'
                  alignContent='start'
                >
                  <Image src={logo} fit={'contain'} opacity='medium' />
                </Box>
                <Box width={'100px'}>
                  <FooterCapterraLink
                    href='https://www.capterra.com/reviews/157309/Line-Up?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <StyledImage
                      border='0'
                      src='https://assets.capterra.com/badge/f0584e7641e58d601c630a55cb9fc7db.png?v=2110302&p=157309'
                      alt='Line-Up Reviews'
                      fit='contain'
                    />
                  </FooterCapterraLink>
                </Box>
                <Box>
                  <StarLogo />
                </Box>
              </Box>
              <Text textAlign='left' size='small' weight='900'>
                Beautifully Crafted Ticketing
              </Text>
              <Text textAlign='left' size='xsmall'>
                <Emoji symbol='🇬🇧' label='GB' /> Made in GB, trusted Worldwide
              </Text>
              <Text textAlign='left' size='xsmall' color='copy-light'>
                © Line-Up 2021. All rights reserved.
              </Text>
            </Box>
          </Box>
        </Footer>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default PublicFooter;
