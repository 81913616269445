import React from 'react';
import Switch from 'react-switch';
import classNames from 'classnames';
import './FormFieldSwitch.css';

const FormFieldSwitch = ({
  input,
  hideLabel,
  label,
  placeholder,
  inline,
  switchfield,
  className,
  hintBefore,
  hintAfter,
  meta: { touched, error, warning }
}) => {
  const rowClass = classNames(className, 'form__row', {
    'form__row--inline': inline
  });
  const labelClass = classNames('form__label', {
    'form__label--inline': inline,
    'form__label--switch': switchfield
  });
  return (
    <div className={rowClass}>
      {hintBefore && (
        <div className="form__hint form__hint--before">{hintBefore}</div>
      )}
      <label className={labelClass} hidden={hideLabel}>
        {label}
        <Switch
          onChange={input.onChange}
          checked={!!input.value}
          className="form-field-switch"
        />
      </label>
      {hintAfter && (
        <div className="form__hint form__hint--after">{hintAfter}</div>
      )}
      {touched &&
        ((error && <div className="form__error">{error}</div>) ||
          (warning && <div className="form__error">{warning}</div>))}
    </div>
  );
};
export default FormFieldSwitch;
