import React from 'react';
import { reduxForm, Field, formValueSelector, isDirty } from 'redux-form';
import { connect } from 'react-redux';

// components
import Button from '../Button/Button';
import VerticalSplit from './VerticalSplit';
import Form, { FormSectionHeader } from '../Form/Form';
import FormFieldCodeEditor from './FormFieldCodeEditor';
import FormField from '../Form/FormField/FormField';
import PreviewPane from './PreviewPane';

import * as validators from '../../utils/validators';
import { CONTENT_TYPE_HTML } from './constants';

const validate = values => {
  let errors = {};
  if (!values.fromEmail) {
    errors.fromEmail = 'This is required';
  }
  if (validators.email(values.fromEmail) !== undefined) {
    errors.fromEmail = 'This must be an email address';
  }
  if (!values.replyTo) {
    errors.replyTo = 'This is required';
  }
  if (validators.email(values.replyTo) !== undefined) {
    errors.replyTo = 'This must be an email address';
  }
  if (!values.subject) {
    errors.subject = 'A subject line is required';
  }

  return errors;
};

const ContentTypeSwitch = ({ contentType, onChange }) => {
  return (
    <div>
      {contentType === CONTENT_TYPE_HTML ? (
        <Button type='button' onClick={() => onChange('text')}>
          Switch to text version
        </Button>
      ) : (
        <Button type='button' onClick={() => onChange('html')}>
          Switch to html version
        </Button>
      )}
    </div>
  );
};

const getPreviewContent = (contentType, codeValue) => {
  if (!codeValue) {
    return 'No content';
  }
  return codeValue[contentType];
};

let EmailEditorForm = ({
  code,
  handleSubmit,
  submitting,
  onClickSendPreview,
  dirty,
  initialValues,
}) => {
  const [contentType, setContentType] = React.useState(CONTENT_TYPE_HTML); // can be 'html' or 'text'

  return (
    <div>
      <Form
        handleSubmit={handleSubmit}
        formTitle='Edit your new email template below'
        submitText='Save changes to template'
        cancelBtn={false}
        submitting={submitting}
      >
        <Field
          name='fromEmail'
          label='From email address'
          placeholder='e.g. team@my-venue.com'
          type='text'
          component={FormField}
        />
        <Field
          name='replyTo'
          label='Reply To Email Address'
          placeholder='e.g. support@my-venue.com'
          type='text'
          component={FormField}
        />
        <Field
          name='subject'
          label='Email subject line'
          type='text'
          placeholder='e.g. About your upcoming visit to My Venue Name'
          component={FormField}
        />
        <FormSectionHeader title='Edit your email HTML template below' />
        <ContentTypeSwitch
          contentType={contentType}
          onChange={setContentType}
        />
        <VerticalSplit
          Left={
            <Field
              contentType={contentType}
              name='code'
              label='Email template'
              component={FormFieldCodeEditor}
              type='text'
            />
          }
          Right={
            <PreviewPane
              contentType={contentType}
              value={getPreviewContent(contentType, code)}
            />
          }
        />
        {initialValues.id !== undefined ? (
          <div className='form__row'>
            <Button disabled={dirty} type='button' onClick={onClickSendPreview}>
              Send preview
            </Button>
          </div>
        ) : null}
      </Form>
    </div>
  );
};

const stateFormName = 'emailEditorForm';

EmailEditorForm = reduxForm({
  form: stateFormName,
  validate,
})(EmailEditorForm);

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector(stateFormName);
  return {
    code: selector(state, 'code'),
    dirty: isDirty(state, stateFormName),
  };
};

export default connect(mapStateToProps)(EmailEditorForm);
