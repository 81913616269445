import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import Form, {
  FormField,
  FormDateField,
  FormSectionHeader,
  FormFieldTime,
  FormFieldReactSelect
} from '../../../Form/Form';
import Can from '../../../Can';
import { performanceSelectors } from '../../../../modules/performances/performances';
import './PerformanceForm.css';
import { required, positiveInt } from '../../../../utils/validators';
import { parsePerformanceDateToMoment } from '../../../../utils/dates';
import { performanceStatusOptions } from '../../../../utils/types';
const validate = values => {
  const errors = {};
  if (
    values.startDate &&
    values.endDate &&
    values.endDate - values.startDate < 0
  ) {
    errors.startDate = 'Start date cannot be after end date';
  }
  return errors;
};

let PerformanceForm = props => {
  const {
    handleSubmit,
    onCancel,
    submitting,
    title,
    error,
    useSeatingPlan,
    seatingCharts,
    startDate,
    endDate,
    performanceId,
    role,
    capacitySet,
    capacityLeft,
    newCapacity,
    ...otherProps
  } = props;
  return (
    <Form
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      submitting={submitting}
      formTitle={performanceId ? 'Edit Performance' : 'Add Single Performance'}
      guidanceNotes={
        performanceId
          ? 'Editing performance dates after tickets have been sold may impact your reporting'
          : ''
      }
      error={error}
      {...otherProps}
    >
      <div className='event-form-info'>
        <Can
          role={role}
          perform='event:edit'
          yes={() => (
            <>
              <div className='event-form-info-date-time'>
                <Field
                  label='Start Date'
                  name='startDate'
                  component={FormDateField}
                  to={endDate}
                  validate={[required]}
                  width='90'
                />
                <Field
                  label='Start Time'
                  name='startTime'
                  component={FormFieldTime}
                  validate={[required]}
                  placeholder='HH:MM'
                  width='90'
                />
              </div>
              <div className='event-form-info-date-time'>
                <Field
                  label='End Date'
                  name='endDate'
                  from={startDate}
                  component={FormDateField}
                  validate={[required]}
                />
                <Field
                  label='End Time (optional)'
                  name='endTime'
                  component={FormFieldTime}
                  placeholder='HH:MM'
                />
              </div>
            </>
          )}
        />
        <Field
          label='Status'
          name='status'
          component={FormFieldReactSelect}
          options={performanceStatusOptions}
          placeholder='Status'
          validate={[required]}
        />

        <FormSectionHeader
          title={performanceId ? 'Capacity Remaining' : 'Capacity'}
        />
        <Field
          hintBefore={
            performanceId
              ? 'The remaining number of tickets that can be sold for this performance'
              : 'The maximum number of tickets that can be sold for this performance.'
          }
          type='number'
          name='capacityLeft'
          step='any'
          width='25'
          placeholder='E.g. 150'
          component={FormField}
          validate={[required]}
          normalize={positiveInt}
          hintAfter={
            capacityLeft &&
            `Total Capacity for performance: ${capacitySet -
              (capacityLeft - newCapacity)}`
          }
        />

        {/* {!performanceId && seatingCharts && (
          <>
            <FormSectionHeader title="Seating Plan" />
            <Field
              label="Does this performance need a seating plan?"
              name="useSeatingPlan"
              component={FormFieldSwitch}
              switchfield
            />
            {useSeatingPlan && (
              <Field
                label="Seating plan to use"
                name="seatingChartId"
                component={FormFieldReactSelect}
                options={seatingCharts}
              />
            )}
          </>
        )} */}
      </div>
    </Form>
  );
};

PerformanceForm = reduxForm({
  form: 'performance',
  validate
})(PerformanceForm);

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector('performance');
  const useSeatingPlan = selector(state, 'useSeatingPlan');
  const startDate = selector(state, 'startDate');
  const endDate = selector(state, 'endDate');
  const newCapacity = selector(state, 'capacityLeft');
  const performanceId = ownProps.performanceId;
  let initialValues;
  const performance = performanceSelectors.getPerformance(state, performanceId);
  if (performanceId) {
    if (performance.startDate) {
      initialValues = {
        ...performance,
        status: performanceStatusOptions.find(
          p => p.value === performance.status
        ),
        useSeatingPlan: !!performance.seatingChart,
        ...(performance.seatingChart
          ? {
              seatingChart: {
                id: performance.seatingChart.id,
                label: performance.seatingChart.name
              },
              useSeatingPlan: true
            }
          : {}),
        startDate: parsePerformanceDateToMoment(performance.startDate).toDate(),
        endDate: parsePerformanceDateToMoment(performance.endDate).toDate()
      };
    }
  }

  return {
    useSeatingPlan,
    startDate,
    endDate,
    initialValues,
    newCapacity
  };
};

export default connect(mapStateToProps)(PerformanceForm);
