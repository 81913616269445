import React from 'react';
import { connect } from 'react-redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { LineUpHelmet } from 'components/MainContent/MainContent';

import SignupForm from 'components/SignupForm/SignupForm';

import { accountActions } from 'modules/account/account';

import logo from 'assets/logo_light.png';
import logoDark from 'assets/logo.png';
import signup from './signup.png';

// Grommet
import { Box, Footer, Text, Image, Heading, ResponsiveContext } from 'grommet';
import { StatusGood } from 'grommet-icons';

const UserSignup = ({ submitCreateUser, dispatch }) => {
  const handleSubmit = (values, { setFormError }) => {
    return submitCreateUser(values)
      .then(r => {
        dispatch(push('/events'));
      })
      .catch(e => {
        setFormError(e.errors._error);
      });
  };

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <LineUpHelmet title='Sign Up' description='Sign Up to Line-Up' />
          <Box direction={size !== 'small' ? 'row' : 'column-reverse'}>
            <Box
              background={size !== 'small' ? '#102052' : 'transparent'}
              height={
                size !== 'small'
                  ? { min: '100vh', max: '100%' }
                  : { min: 'auto', max: 'auto' }
              }
              direction='column'
              justify='end'
              width={size !== 'small' ? '45%' : '100%'}
            >
              {size !== 'small' ? (
                <>
                  <Box
                    width='70%'
                    margin={{
                      horizontal: 'auto',
                      bottom: 'large',
                      top: 'large'
                    }}
                  >
                    <Link to={'/'}>
                      <Box width='160px' height='42px'>
                        <Image src={logo} fit='contain' />
                      </Box>
                    </Link>
                  </Box>
                  <Box
                    width='70%'
                    margin={{ horizontal: 'auto', bottom: 'large' }}
                    gap='medium'
                  >
                    <Box
                      direction='row'
                      gap='small'
                      align='start'
                      justify='start'
                    >
                      <StatusGood color='white' size='medium' />
                      <Box direction='column' gap='small'>
                        <Heading level='5' color='white' margin='0'>
                          Get Going Quickly
                        </Heading>
                        <Text color='white'>
                          You can have tickets on sale in under 12 minutes.
                        </Text>
                      </Box>
                    </Box>
                    <Box
                      direction='row'
                      gap='small'
                      align='start'
                      justify='start'
                    >
                      <StatusGood color='white' size='medium' />
                      <Box direction='column' gap='small'>
                        <Heading level='5' color='white' margin='0'>
                          Free to setup
                        </Heading>
                        <Text color='white'>
                          Only start paying when you sell a ticket.
                        </Text>
                      </Box>
                    </Box>
                    <Box
                      direction='row'
                      gap='small'
                      align='start'
                      justify='start'
                    >
                      <StatusGood color='white' size='medium' />
                      <Box direction='column' gap='small'>
                        <Heading level='5' color='white' margin='0'>
                          Join the pioneers
                        </Heading>
                        <Text color='white'>
                          We're trusted by forward-thinking businesses, big and
                          small, globally.
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : null}
              <Box
                width={size !== 'small' ? '50%' : '80%'}
                margin={{ horizontal: 'auto', top: 'large' }}
              >
                <img
                  src={signup}
                  style={{ width: '100%' }}
                  alt='Sign up to Line-Up'
                />
              </Box>
            </Box>
            <Box
              align='center'
              margin={{ bottom: 'large' }}
              width={size !== 'small' ? '55%' : '100%'}
            >
              {size !== 'small' ? null : (
                <Box
                  width='100%'
                  margin={{
                    horizontal: 'auto',
                    bottom: 'large',
                    top: 'xlarge'
                  }}
                  align='center'
                >
                  <Link to={'/'}>
                    <Box width='160px' height='42px'>
                      <Image src={logoDark} fit='contain' />
                    </Box>
                  </Link>
                </Box>
              )}
              <Box
                margin={
                  size !== 'small'
                    ? { top: '8%', bottom: 'large' }
                    : { vertical: 'large' }
                }
                align='center'
                width={size !== 'small' ? 'auto' : '85%'}
              >
                <Box
                  width={size !== 'small' ? '450px' : '100%'}
                  align='center'
                  gap='medium'
                >
                  <SignupForm onSubmit={handleSubmit} />
                </Box>
              </Box>
              <Footer align='center' margin={{ top: 'auto' }}>
                <Text size='small' color='copy-light'>
                  © Line-Up 2021
                </Text>
              </Footer>
            </Box>
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        submitCreateUser: accountActions.submitCreateUser
      },
      dispatch
    ),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSignup);
