import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { createAction } from 'redux-actions';
import * as types from './types';

export const fetchPerformancesPage = createRoutine(types.FETCH_PERFORMANCES);
export const fetchPerformance = createRoutine(types.FETCH_PERFORMANCE);
export const fetchAllPerformances = createRoutine(types.FETCH_ALL_PERFORMANCES);
export const createPerformance = createRoutine(types.CREATE_PERFORMANCE);
export const sumbmitCreatePerformanceForm = promisifyRoutine(createPerformance);
export const updatePerformance = createRoutine(types.UPDATE_PERFORMANCE);
export const sumbmitUpdatePerformanceForm = promisifyRoutine(updatePerformance);
export const setDateFilter = createAction(types.SET_DATE_FILTER);
export const downloadGuestlistCSV = createRoutine(types.DOWNLOAD_GUESTLIST_CSV);
export const downloadGuestlistPDF = createRoutine(types.DOWNLOAD_GUESTLIST_PDF);
export const deletePerformance = createRoutine(types.DELETE_PERFORMANCE);
