import React from 'react';
import { useDrop } from 'react-dnd';
import classNames from 'classnames';

import styles from './LaneCellDropTarget.module.css';
import { usePlanner } from './Provider';
import { isLaneDisabledForDate, isLaneLockedForDate } from './layoutUtils';

const LaneCellDropTarget = ({
  lane,
  performance,
  layout,
  pendingPlacements,
  onClick
}) => {
  const { currentSelectedDay } = usePlanner();
  const targetName = `TARGET_${performance.id}`;
  const [collectedProps, drop] = useDrop({
    accept: targetName,
    canDrop: (item, monitor) => {
      return (
        !isLaneDisabledForDate(lane, currentSelectedDay) &&
        !isLaneLockedForDate(lane, currentSelectedDay)
      );
    },
    drop: (item, monitor) => {
      return {
        placementAction: 'moveToLane',
        sourcePlacementId: item.id,
        destinationLaneId: lane.id
      };
    },
    collect: (monitor, props) => {
      return {
        hovering: monitor.isOver({ shallow: true })
      };
    }
  });
  const handleClick = evt => {
    evt.preventDefault();
    onClick(lane, performance);
  };
  const style = {
    top: `${layout.top}px`,
    height: `${layout.height}px`
  };
  if (collectedProps.hovering) {
    style.backgroundColor = '#ccc';
  }
  const rootClassName = classNames(styles.laneCellDropTarget, {
    [styles.notEnoughSpaceWarning]: pendingPlacements.length > 0 ? true : false
  });

  return (
    <div
      onClick={handleClick}
      ref={drop}
      style={style}
      className={rootClassName}
    />
  );
};

export default LaneCellDropTarget;
