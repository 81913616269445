import React from 'react';
import moment from 'moment';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Button from '../Button/Button';
import './DateRange.css';

export default class DateRange extends React.Component {
  constructor(props) {
    super(props);
    const { from, to, disableAfter = new Date() } = this.props;
    this.state = {
      oldFrom: from,
      oldTo: to,
      from: from,
      to: to,
      disableAfter: disableAfter
    };
  }

  showFromMonth = () => {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  };
  handleFromChange = from => {
    this.setState({ from });
    this.props.onRangeSelect({
      from: from,
      to: this.state.to
    });
  };
  handleToChange = to => {
    this.setState({ to }, this.showFromMonth);
    this.props.onRangeSelect({
      from: this.state.from,
      to: to
    });
  };
  handleApplyClick = e => {
    e.preventDefault();
    this.setState({
      oldFrom: this.state.from,
      oldTo: this.state.to
    });
    this.props.onRangeSelect({
      from: this.state.from,
      to: this.state.to
    });
    this.to.getInput().blur();
  };
  handleCancelClick = e => {
    e.preventDefault();
    this.to.hideDayPicker();
    this.to.getInput().blur();
    this.setState({
      from: this.state.oldFrom,
      to: this.state.oldTo
    });
  };
  customOverlay = ({ classNames, children, selectedDay, ...props }) => {
    const { from, to } = this.state;
    return (
      <div className={classNames.overlayWrapper} {...props}>
        <div className={classNames.overlay}>
          {children}
          <div className={`${classNames.container}__actions`}>
            <Button
              primary
              onClick={this.handleApplyClick}
              disabled={!(to && from)}
            >
              Apply
            </Button>
            <Button onClick={this.handleCancelClick}>Cancel</Button>
          </div>
        </div>
      </div>
    );
  };
  render() {
    const { from, to, disableAfter } = this.state;
    const modifiers = { start: from, end: to };
    return (
      <div className="date-range">
        <DayPickerInput
          ref={el => (this.from = el)}
          value={from}
          placeholder="From"
          format="LL"
          formatDate={formatDate}
          parseDate={parseDate}
          classNames={{
            container: 'day-picker',
            overlayWrapper: 'day-picker__overlay-wrap',
            overlay: 'day-picker__overlay'
          }}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: { after: to },
            toMonth: to,
            modifiers,
            numberOfMonths: 2,
            onDayClick: () => this.to.getInput().focus()
          }}
          onDayChange={this.handleFromChange}
        />
        <span className="date-range__spacer">—</span>
        <DayPickerInput
          ref={el => (this.to = el)}
          value={to}
          placeholder="To"
          format="LL"
          formatDate={formatDate}
          parseDate={parseDate}
          overlayComponent={this.customOverlay}
          hideOnDayClick={false}
          classNames={{
            container: 'day-picker',
            overlayWrapper: 'day-picker__overlay-wrap',
            overlay: 'day-picker__overlay'
          }}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: { before: from, after: disableAfter },
            modifiers,
            month: from,
            fromMonth: from,
            numberOfMonths: 2,
            toMonth: disableAfter,
            onDayClick: () =>
              !this.state.from ? this.from.getInput().focus() : null
          }}
          onDayChange={this.handleToChange}
        />
      </div>
    );
  }
}
