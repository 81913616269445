import reducer from './reducers';
import * as ticketAllocationSelectors from './selectors';
import * as ticketAllocationTypes from './types';
import * as ticketAllocationActions from './actions';
import * as ticketAllocationOperations from './operations';

export {
  ticketAllocationSelectors,
  ticketAllocationTypes,
  ticketAllocationActions,
  ticketAllocationOperations
};

export default reducer;
