import React from 'react';
import { FormattedDate } from 'react-intl';
import { Field, reduxForm } from 'redux-form';

import Form, { FormField, FormFieldReactSelect } from 'components/Form/Form';
import InfoBlock, {
  InfoBlockList,
  InfoBlockListItem,
} from 'components/InfoBlock/InfoBlock';

import { getPerformanceStartDate } from 'utils/dates';

const validate = values => {
  let errors = {};
  if (!values.description) {
    errors.description = 'A name is required for this allocation';
  }
  if (!values.ticketId) {
    errors.ticketId = 'Please select a ticket to create a reservation for';
  }

  return errors;
};

const createTicketOptions = tickets => {
  return tickets.map(ticket => ({
    label: ticket.description,
    value: ticket.id,
  }));
};

let CreateReservationForm = ({
  onCancel,
  handleSubmit,
  lane,
  performance,
  tickets,
  submitting,
}) => {
  const startDate = getPerformanceStartDate(performance);
  return (
    <Form
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      formTitle='Create reservation'
      guidanceNotes='Use the form below to create a reservation'
      submitText='Create reservation'
      submitting={submitting}
    >
      <Field
        label='Name of the reservation'
        name='description'
        component={FormField}
        type='text'
      />

      <Field
        name='ticketId'
        label='Ticket'
        isClearable={false}
        component={FormFieldReactSelect}
        options={createTicketOptions(tickets)}
      />

      <InfoBlock>
        <InfoBlockList>
          <InfoBlockListItem attribute='Lane' value={lane.name + ` (x 1)`} />
          <InfoBlockListItem
            attribute='Date'
            value={
              <FormattedDate
                value={startDate}
                weekday='short'
                year='numeric'
                
                day='numeric'
                month='short'
                hour='numeric'
                minute='numeric'
              />
            }
          />
        </InfoBlockList>
      </InfoBlock>
    </Form>
  );
};

CreateReservationForm = reduxForm({
  form: 'createReservationForm',
  validate,
})(CreateReservationForm);

export default CreateReservationForm;
