import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindPromiseCreators } from 'redux-saga-routines';
import { bindActionCreators } from 'redux';

// components
import EventEmailScheduleForm, {
  toApiValues,
} from '../../../components/EventEmailSchedule/ScheduleForm';

// Grommet
import { Box } from 'grommet';

// other
import { emailSelectors, emailActions } from '../../../modules/emails/emails';
import { eventEmailScheduleActions } from '../../../modules/eventEmailSchedules/eventEmailSchedules';

const EventEmailScheduleCreate = ({
  eventId,
  fetchData,
  emails,
  submitCreateEventEmailScheduleForm,
  dispatch,
}) => {
  React.useEffect(() => {
    fetchData({ page: 1 });
  }, [fetchData]);

  const handleSubmit = values => {
    const data = {
      ...toApiValues(values),
      eventId,
    };
    submitCreateEventEmailScheduleForm(data).then(() => {
      dispatch(push(`/event/${eventId}/email-schedule`));
    });
  };

  const handleCancel = () => {
    dispatch(push(`/event/${eventId}/email-schedule`));
  };

  if (!emails) {
    return null;
  }

  return (
    <div>
      <Box margin={{ bottom: 'large' }}>
        <Box margin={{ top: 'small', bottom: 'medium' }}>
          <EventEmailScheduleForm
            emails={emails}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </Box>
      </Box>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const eventId = parseInt(ownProps.match.params.eventId, 10);
  return {
    emails: emailSelectors.getEntitiesForPage(state, 1),
    eventId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        submitCreateEventEmailScheduleForm:
          eventEmailScheduleActions.submitCreateEventEmailSchedule,
      },
      dispatch
    ),
    ...bindActionCreators(
      { ...emailActions, ...eventEmailScheduleActions },
      dispatch
    ),
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventEmailScheduleCreate);
