import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import Form, {
  FormField,
  FormFieldReactSelect,
  FormImageField
} from '../Form/Form';
import { productSelectors } from '../../modules/products/products';
import { productOptions } from '../../utils/types';
import { uploadImage, deleteImage } from '../../services/api';
import { integer, float } from '../../utils/validators';

const validate = values => {
  const errors = {};
  if (!values.title) {
    errors.title = 'This is required';
  }
  if (!values.total) {
    errors.total = 'This is required';
  }
  return errors;
};

let ProductForm = props => {
  const {
    handleSubmit,
    submitting,
    trackInventory,
    productId,
    error,
    reset
  } = props;
  return (
    <Form
      {...props}
      error={error}
      onCancel={reset}
      handleSubmit={handleSubmit}
      submitting={submitting}
      formTitle={productId ? 'Edit Product' : 'Create New Product'}
      guidanceNotes={
        productId
          ? 'Use the form below to edit the product'
          : 'Use the form below to create a new product'
      }
    >
      <Field
        name="title"
        label="Product Title"
        component={FormField}
        type="text"
        placeholder="Type your product title here"
      />
      <Field
        name="description"
        label="Product Description"
        component={FormField}
        type="text"
        placeholder="Type your product description here"
      />
      <Field
        name="kind"
        component={FormFieldReactSelect}
        label={'Product Type'}
        isClearable={false}
        options={productOptions}
      />
      <Field
        name="total"
        component={FormField}
        label="Total Price"
        type="number"
        step="any"
        normalize={float}
        placeholder="E.g. 10"
      />

      <Field
        name="trackInventory"
        labelAfter="Track Inventory"
        id="trackInventory"
        component={FormField}
        type="checkbox"
      />
      {trackInventory && (
        <Field
          name="inventoryLeft"
          label="Inventory Available"
          component={FormField}
          type="number"
          normalize={integer}
          placeholder="E.g. 50"
          fieldShort={true}
        />
      )}
      <Field
        name="image"
        label="Product Image"
        component={FormImageField}
        placeholder="Drop image here or click to upload"
        uploadImage={uploadImage}
        deleteImage={deleteImage}
      />
    </Form>
  );
};

ProductForm = reduxForm({
  form: 'product',
  validate
})(ProductForm);

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector('product');
  const trackInventory = selector(state, 'trackInventory');
  const productId = ownProps.productId;
  let initialValues;
  if (productId) {
    const product = productSelectors.getProduct(state, productId);
    if (product.title) {
      initialValues = {
        ...product,
        kind: productOptions.find(p => p.value === product.kind)
      };
    }
  } else {
    initialValues = {
      kind: productOptions[0]
    };
  }
  return {
    trackInventory,
    initialValues
  };
};

export default connect(mapStateToProps)(ProductForm);
