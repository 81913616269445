import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import Form, { FormField, FormDateRangePicker } from "../Form/Form";
import "./ExportOrdersForm.css";
let ExportOrdersForm = (props) => {
  const { handleSubmit, onCancel, submitting, dateRange, type } = props;
  return (
    <Form
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      submitting={submitting}
      formTitle="Export orders"
    >
      <h5 className="order-export-form__section-header-title">Export Type</h5>
      <Field
        name="type"
        labelAfter="Ticket Orders"
        value="orders"
        component={FormField}
        type="radio"
        inline
      />
      <Field
        name="type"
        labelAfter="Ticket Receipts"
        value="receipts"
        component={FormField}
        type="radio"
        inline
      />
      <Field
        name="type"
        labelAfter="Product Receipts"
        value="productReceipts"
        component={FormField}
        type="radio"
        inline
      />
      <Field
        name="type"
        labelAfter="Refunds"
        value="refunds"
        component={FormField}
        type="radio"
        inline
      />
      {dateRange === "custom" && (
        <Fragment>
          <div className="order-export-form__section-header">
            <h5 className="order-export-form__section-header-title">
              Select Dates
            </h5>
          </div>
          <Field name="dateRange" component={FormDateRangePicker} />
        </Fragment>
      )}
      {type === "receipts" && (
        <Fragment>
          <div className="order-export-form__section-header">
            <h5 className="order-export-form__section-header-title">Filter</h5>
          </div>
          <Field
            name="filterType"
            labelAfter="Transaction Date"
            value="transaction"
            component={FormField}
            type="radio"
            inline
          />
          <Field
            name="filterType"
            labelAfter="Event Performance Date"
            value="performance"
            component={FormField}
            type="radio"
            inline
          />
        </Fragment>
      )}
      <h5 className="order-export-form__section-header-title">Date</h5>
      <Field
        name="date"
        labelAfter="Today"
        value="today"
        component={FormField}
        type="radio"
      />
      <Field
        name="date"
        labelAfter="Yesterday"
        value="yesterday"
        component={FormField}
        type="radio"
      />
      <Field
        name="date"
        value="lastMonth"
        labelAfter="Previous Month"
        component={FormField}
        type="radio"
      />
      <Field
        name="date"
        value="all"
        labelAfter="All"
        component={FormField}
        type="radio"
      />
      <Field
        name="date"
        value="custom"
        labelAfter="Custom"
        component={FormField}
        type="radio"
      />
    </Form>
  );
};

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector("exportOrders");
  const dateRange = selector(state, "date");
  const type = selector(state, "type");
  return {
    dateRange,
    type,
  };
};

ExportOrdersForm = reduxForm({
  form: "exportOrders",
  initialValues: {
    type: "orders",
    date: "today",
    filterType: "transaction",
  },
})(ExportOrdersForm);
export default connect(mapStateToProps)(ExportOrdersForm);
