import React from 'react';
import styled from 'styled-components';
import './StarLogo.css';

// Grommet
import { Button, Image } from 'grommet';

const StarLogoWrapper = styled(Button)`
  width: 90px;
`;

const StarLogoImage = styled(Image)`
  width: 90px;
`;

export const StarLogo = () => {
  const verifyStar = () => {
    window.open(
      'http://www.star.org.uk/verify?dn=lineupnow.com',
      'sharer',
      'toolbar=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,width=560,height=490'
    );
  };

  return (
    <StarLogoWrapper plain onClick={() => verifyStar()} className="star-logo">
      <StarLogoImage
        src="https://www.star.org.uk/wp-content/uploads/2017/11/star_verification.gif"
        alt="Verfied star member"
      />
    </StarLogoWrapper>
  );
};

export default StarLogo;
