import React from "react";

// Grommet
import { Box, ResponsiveContext } from "grommet";

const PageTop = ({ children }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          direction={size !== "small" ? "row" : "column"}
          justify={size !== "small" ? "between" : "start"}
          align={size !== "small" ? "center" : "start"}
          gap={size !== "small" ? "" : "medium"}
          fill={size !== "small" ? false : true}
          margin={size !== "small" ? "" : { bottom: "medium" }}
        >
          {children}
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default PageTop;
