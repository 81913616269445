import { combineReducers } from 'redux';
import paginate from '../shared/paginate';
import * as actions from './actions';
//- Reducers

const byPage = paginate({
  mapActionToKey: action => action.page,
  types: [
    actions.fetchInvoices.REQUEST,
    actions.fetchInvoices.SUCCESS,
    actions.fetchInvoices.FAILURE
  ]
});

const invoices = combineReducers({
  byPage
});

export default invoices;
