import React from 'react';
import { connect } from 'react-redux';

import MainContent, { Title } from 'components/MainContent/MainContent';
import AccountNav from 'components/Account/AccountNav/AccountNav';

const AccountNotifications = ({ user }) => {
  return (
    <MainContent>
      <Title>Your Account</Title>
      <AccountNav />
    </MainContent>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.account.user.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountNotifications);
