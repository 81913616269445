import React from 'react';
import { TableCell, TableRow } from '../../Table/Table';
import { Box } from 'grommet';

import { Calendar } from 'grommet-icons';

const PerformanceRowItem = ({
  date,
  start_time,
  end_time,
  capacity,
  performance_status,
  seating_chart_id
}) => (
  <TableRow bgColor='#263a66' color='white'>
    <TableCell width={5}>
      <Box margin={{ top: '0' }}>
        <Calendar size='small' color='white' />
      </Box>
    </TableCell>
    <TableCell width={20} texttop>
      {date}
    </TableCell>
    <TableCell width={15} texttop center>
      {start_time}
    </TableCell>
    <TableCell width={15} texttop center>
      {end_time}
    </TableCell>
    <TableCell width={10} texttop center>
      {capacity}
    </TableCell>
    <TableCell width={15} texttop center>
      {performance_status}
    </TableCell>
    <TableCell width={10} texttop center>
      {seating_chart_id}
    </TableCell>
    <TableCell width={10} texttop>
      <div className='accordion__arrow' />
    </TableCell>
  </TableRow>
);
export default PerformanceRowItem;
