import React from "react";

import BusinessSettingsForm from "components/BusinessSettingsForm/BusinessSettingsForm";
// Grommet
import { Box, Button } from "grommet";

// Components
import withEmptyState from "components/withEmptyState";
import StripeSettingsConnectForm from "./StripeSettingsConnectForm";
import { SubTitle } from "components/MainContent/MainContent";

const StripeConnect = ({
  onStripeConnectClick,
  onStripeDisconnectClick,
  onBusinessSettingsFormSubmit,
  businessSettings,
  stripeSettings,
  currencies,
}) => {
  return (
    <>
      {stripeSettings && stripeSettings.connectURL ? (
        <StripeSettingsConnectForm
          onStripeConnectClick={onStripeConnectClick}
        />
      ) : (
        <>
          <Box margin={{ vertical: "medium" }}>
            <SubTitle>
              <Button
                color="error"
                hoverIndicator="error-hover"
                primary
                onClick={onStripeDisconnectClick}
                label="Disconnect your Stripe account"
                size={"small"}
              />
            </SubTitle>
          </Box>
          <Box margin={{ vertical: "medium" }}>
            <BusinessSettingsForm
              onBusinessSettingsFormSubmit={onBusinessSettingsFormSubmit}
              businessSettings={businessSettings}
              currencies={currencies}
            />
          </Box>
        </>
      )}
    </>
  );
};
export default withEmptyState(StripeConnect);
