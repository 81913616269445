import React from 'react';
import { connect } from 'react-redux';
// Components
import MainContent, {
  Title,
  HeaderActions,
  PageTop,
  LineUpHelmet,
} from 'components/MainContent/MainContent';
import TableView from '../../table/TableView';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// Grommet
import { Box, Button } from 'grommet';
import { Add } from 'grommet-icons';

import { webhookActions, webhookSelectors } from 'modules/webhooks/webhooks';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import EmptyState from 'components/EmptyState/EmptyState';
// Images
import emptyWebhooks from './empty_events.png';

const DeleteButton = styled(Button)`
  background-color: #ff2e4a;
  &:hover {
    background-color: #d41c34;
  }
`;

const columns = onDelete => [
  {
    title: 'URL',
    dataIndex: 'url',
    width: '30',
  },
  {
    title: 'Event Type',
    render: (text, webhook) =>
      webhook.webhookEventTypes.map(type => type.name).toString(),
    width: '30',
  },
  {
    title: '',
    render: (text, webhook) => (
      <Box gap='small' direction='row' justify='end'>
        <Link to={`/tools/webhook/${webhook.id}`}>
          <Button secondary size='small' label='Edit' />
        </Link>
        <DeleteButton
          onClick={() => onDelete(webhook)}
          primary
          size='small'
          label='Delete'
        />
      </Box>
    ),
    align: 'center',
    width: '20',
  },
];

const Webhooks = ({
  webhooks,
  currentPage,
  totalPages,
  isLoading,
  fetchWebhooks,
  submitDeleteWebhookForm,
}) => {
  const handleSelectPage = page => {
    this.props.history.push(`/webhooks/${page}`);
  };

  const handleWebhookDeleteClick = item => {
    submitDeleteWebhookForm({ id: item.id }).then(() =>
      fetchWebhooks({ page: currentPage })
    );
  };

  return (
    <>
      <LineUpHelmet
        title='Webhooks'
        description='Manage your webhooks in Line-Up'
      />
      <MainContent>
        <PageTop>
          <Title>Webhooks</Title>
          <HeaderActions>
            <Link to='/tools/webhook'>
              <Button
                primary
                label='New Webhook'
                icon={<Add size='small' />}
                size='small'
              />
            </Link>
          </HeaderActions>
        </PageTop>
        <Box margin={{ vertical: 'medium' }}>
          <TableView
            columns={columns(handleWebhookDeleteClick)}
            data={webhooks}
            isLoading={isLoading}
            emptyStateComponent={
              <EmptyState
                background
                buttonText='Create New Webhook'
                buttonLink='/tools/webhook'
                showButton={true}
                emptyStateHeadline='You have no webhooks set up yet'
                emptyStateDescription='Create Webhooks'
                emptyStateImageSrc={emptyWebhooks}
                emptyStateImageAlt='No webhooks yet'
                emptyStateImageTitle='No webhooks yet'
              />
            }
            fetchData={fetchWebhooks}
            totalPages={totalPages}
            currentPage={currentPage}
            onPageClick={handleSelectPage}
          />
        </Box>
      </MainContent>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const page = parseInt(match.params.page, 10) || 1;
  return {
    webhooks: webhookSelectors.getWebhooksForPage(state, page),
    currentPage: page,
    totalPages: webhookSelectors.getWebhooksTotalPages(state),
    isLoading: webhookSelectors.getWebhooksLoadingStatus(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        submitDeleteWebhookForm: webhookActions.submitDeleteWebhook,
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        ...webhookActions,
      },
      dispatch
    ),
    dispatch,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Webhooks);
