import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';

import Modal from 'components/Modal/Modal';
import Form from 'components/Form/Form';

import { hideModal } from 'modules/modal/modal';

const DeletePlannerConfirmModal = ({ hideModal, onDelete }) => {
  const handleSubmit = () => {
    return onDelete();
  };

  return (
    <Modal onRequestClose={() => hideModal()}>
      <DeletePlannerForm onCancel={hideModal} onSubmit={handleSubmit} />
    </Modal>
  );
};

let DeletePlannerForm = ({ onCancel, handleSubmit }) => (
  <Form
    formTitle="Are you sure you want to delete this planner?"
    submitText="Yes, delete this planner"
    handleSubmit={handleSubmit}
    onCancel={onCancel}
    danger={true}
  >
    <p>
      This action will delete the planner. Your bookings and allocations will
      not be deleted, just their placements in this planner.{' '}
      <b>There is no undo on this.</b>
    </p>
  </Form>
);

DeletePlannerForm = reduxForm({
  form: 'deleteplannerForm'
})(DeletePlannerForm);

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        hideModal
      },
      dispatch
    )
  };
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeletePlannerConfirmModal);
