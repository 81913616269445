import { combineReducers } from 'redux';
import * as actions from './actions';
import * as types from './types';
//- Reducers

const filters = (
  state = {
    eventId: null,
    from: null,
    to: null,
  },
  action
) => {
  switch (action.type) {
    case types.SELECT_EVENT:
      return {
        ...state,
        eventId: action.payload.eventId,
      };
    case types.SET_DATE_RANGE:
      return {
        ...state,
        from: action.payload.from,
        to: action.payload.to,
      };
    default:
      return state;
  }
};

const events = (
  state = {
    byId: [],
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case actions.fetchEvents.REQUEST:
      return {
        ...state,
        loading: true,
        byId: [],
      };
    case actions.fetchEvents.SUCCESS:
      return {
        ...state,
        loading: false,
        byId: [...action.payload.response.result],
      };
    case actions.fetchEvents.FAILURE:
      return {
        ...state,
        loading: false,
        byId: [],
      };
    default:
      return state;
  }
};

const paymentReport = (state = null, action) => {
  switch (action.type) {
    case actions.fetchPaymentReport.SUCCESS:
      return {
        ...action.payload.response.data,
      };
    case actions.fetchPaymentReport.FAILURE:
      return null;
    default:
      return state;
  }
};

const ticketData = (state = null, action) => {
  switch (action.type) {
    case actions.fetchTicketData.REQUEST:
      return null;
    case actions.fetchTicketData.SUCCESS:
      return [...action.payload.response];
    case actions.fetchTicketData.FAILURE:
      return null;
    default:
      return state;
  }
};

const allIds = (state = [1], action) => {
  switch (action.type) {
    case types.ADD_FILTER:
      return [...state, action.payload.filterId];
    case types.SET_FILTERS:
      return Object.keys(action.payload.filters);
    case types.REMOVE_FILTER:
      return state.filter((id) => id !== action.payload.filterId);
    default:
      return state;
  }
};

const updateFilter = (filter, action) => {
  const { payload } = action;
  const { attribute, operator, value } = payload;
  return {
    ...filter,
    attribute,
    operator,
    value,
  };
};

const filtersById = (
  state = {
    1: {
      id: 1,
      attribute: 'eventId',
      operator: '=',
      value: null,
      canRemove: false,
    },
  },
  action
) => {
  switch (action.type) {
    case types.ADD_FILTER:
      return {
        ...state,
        [action.payload.filterId]: {
          id: action.payload.filterId,
          attribute: action.payload.attribute,
          operator: action.payload.operator,
          value: action.payload.value,
          canRemove: true,
        },
      };
    case types.REMOVE_FILTER:
      const { [action.payload.filterId]: value, ...newState } = state;
      return newState;
    case types.UPDATE_FILTER:
      return {
        ...state,
        [action.payload.filterId]: updateFilter(
          state[action.payload.filterId],
          action
        ),
      };
    case types.SET_FILTERS:
      return {
        ...action.payload.filters,
      };
    default:
      return state;
  }
};

const reports = combineReducers({
  paymentReport,
  ticketData,
  events,
  filters,
  filtersById,
  allIds,
});

export default reports;
