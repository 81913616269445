import React, { useState } from 'react';
import fetch from 'isomorphic-fetch';
import _ from 'lodash';
import buildUrl from 'build-url';
import AsyncSelect from 'react-select/lib/Async';

const LOQATE_DEBOUCE = 200;
const LOQATE_ADDRESS_API_ROOT = process.env.REACT_APP_LOQATE_ADDRESS_API_ROOT;
const LOQATE_API_KEY = process.env.REACT_APP_LOQATE_API_KEY;

export const AddressLookup = props => {
  const { onAddressRetrieve, isInvalid, onBlur, onAddressClear } = props;

  const [keyIndex, setKeyIndex] = useState(0);
  const [addresses, setAddresses] = useState([]);

  /**
   * fetchLocate is a helper function to handle the api call via axios.
   * @param endpoint
   * @param fetchParams object of type AddressCapture | AddressRetrieve
   * @param successCallback
   */
  const fetchLocate = (endpoint, fetchParams, successCallback) => {
    const url = buildUrl(LOQATE_ADDRESS_API_ROOT, {
      path: endpoint,
      queryParams: {
        Key: LOQATE_API_KEY,
        Language: 'en',
        ...fetchParams
      }
    });
    fetch(url)
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (response.ok) {
          successCallback(json);
        }
      })
      .catch(error => {
        console.log(error);
        // TODO handle errors
      });
  };

  /**
   * findAddress takes a full/partial address string, makes a request to the loqate api and returns the values in json3 format.
   * @param inputValue full/partical string e.g. Lon/London
   * @param callback
   * @param container is the ID of a specific postcode lookup (represented by 'Id' in the response item)
   */
  const findAddress = (inputValue, callback, container) => {
    const params = {
      Text: inputValue,
      Container: container
    };

    fetchLocate('/Find/v1.10/json3.ws', params, response => {
      const addresses = response.Items;
      const formattedAddresses = addresses.map(address => ({
        ...address,
        label: `${address.Text} ${address.Description}`,
        value: `${address.Text} ${address.Description}`
      }));
      if (addresses.length === 1 && addresses[0].Type !== 'Address') {
        //   If last item is not an address, recurse findAddress with Containter set to Id of item
        findAddress(inputValue, callback, addresses[0].Id);
      } else {
        callback(formattedAddresses);
      }
    });
  };

  /**
   * handleInputChange recieves keydown input from async select and debounces api calls to loqate service.
   */
  const handleLoadAddresses = _.debounce((inputValue, callback) => {
    findAddress(inputValue, callback);
  }, LOQATE_DEBOUCE);

  /**
   * handleChange is triggered when an option is selected in Async Select.
   * Note that if the selected option is of type Address, the address is retrived and stored in state.
   * If the selected option is of type Postcode, findAddress is called with the selected item's Text as the inputValue and it's Id as Container.
   * To populate the result in async select, the react key is updated triggering a rerender with defaultMenuIsOpen = true
   * @param selectedAddress
   */
  const handleChange = selectedAddress => {
    // Selected value is null

    if (!selectedAddress) {
      setAddresses([]);
      onAddressClear && onAddressClear();
    }

    // Find address if Postcode
    selectedAddress &&
      selectedAddress.Type !== 'Address' &&
      findAddress(
        selectedAddress.Text,
        formattedAddresses => {
          setAddresses(formattedAddresses);
          setKeyIndex(keyIndex + 1);
        },
        selectedAddress.Id
      );

    // Retrieve address if Address
    selectedAddress &&
      selectedAddress.Type === 'Address' &&
      fetchLocate(
        '/Retrieve/v1.00/json3.ws',
        {
          Id: selectedAddress.Id,
          Field1Format: '{{Latitude}}',
          Field2Format: '{{Longitude}}'
        },
        response => {
          const {
            AdminAreaName,
            Company,
            City,
            CountryName,
            Line1,
            PostalCode,
            Field1: lattitude,
            Field2: longitude
          } = response.Items && response.Items[0];

          onAddressRetrieve &&
            onAddressRetrieve({
              street: Line1,
              city: City,
              country: CountryName,
              town: AdminAreaName,
              name: Company,
              postcode: PostalCode,
              lattitude,
              longitude
            });
        }
      );
  };
  const styles = {
    container: styles => ({
      ...styles,
      fontFamily: 'lato, sans serif',
      fontSize: '0.85rem',
      marginTop: '0',
      marginBottom: '0'
    }),
    control: styles => ({
      ...styles,
      backgroundColor: 'white',
      borderWidth: '0.09rem',
      borderColor: '#ddd',
      borderRadius: '0.2rem'
    })
  };
  return (
    <AsyncSelect
      cacheOptions
      isInvalid={isInvalid}
      placeholder="Search for address..."
      key={`address-lookup-${keyIndex}`}
      onChange={handleChange}
      onBlur={onBlur}
      loadOptions={handleLoadAddresses}
      defaultOptions={addresses}
      defaultMenuIsOpen={addresses.length > 0}
      escapeClearsValue={true}
      isClearable
      styles={styles}
    />
  );
};
