import React, { Component } from 'react';
import { connect } from 'react-redux';
import MainContent, {
  Title,
  LineUpHelmet,
} from '../../components/MainContent/MainContent';
import EmptyState from '../../components/EmptyState/EmptyState';
import { FormattedDate } from 'react-intl';
import TableView from '../table/TableView';
import { showModal } from '../../modules/modal/modal';
import { formSelectors, formActions } from '../../modules/forms/forms';

// Grommet
import { Box, Button } from 'grommet';
import { Download } from 'grommet-icons';

// Assets
import emptyForms from './empty_forms.png';

const columns = onExportClick => [
  {
    title: 'Form Title',
    dataIndex: 'name',
    width: '60',
  },
  {
    title: 'Date Created',
    dataIndex: 'createdAt',
    render: updatedDate => (
      <FormattedDate
        value={updatedDate}
        weekday='short'
        year='numeric'
        
        day='numeric'
        month='short'
        hour='numeric'
        minute='numeric'
      />
    ),
  },
  {
    title: '',
    render: (text, record) => (
      <Button
        primary
        onClick={() => onExportClick(record)}
        label='Export Responses'
        size='small'
        icon={<Download size='small' />}
      />
    ),
    align: 'center',
  },
];

class FormList extends Component {
  handleSelectPage = page => {
    this.props.history.push(`/forms/${page}`);
  };

  handleExportClick = item => {
    this.props.showModal('EXPORT', {
      onDateRangeSelected: dates => this.handleExportRangeSelected(dates, item),
      title: 'Export Form Responses',
    });
  };
  handleExportRangeSelected = (dates, item) => {
    this.props.exportFormResponses({ ...dates, formId: item.id });
  };
  render() {
    return (
      <>
        <LineUpHelmet
          title='Custom Forms'
          description='Manage your custom forms in Line-Up'
        />
        <MainContent>
          <Box
            direction='row'
            justify='between'
            align='center'
            margin={{ bottom: 'medium' }}
          >
            <Title>Custom Forms</Title>
          </Box>
          <TableView
            columns={columns(this.handleExportClick)}
            data={this.props.data}
            emptyStateComponent={
              <EmptyState
                background
                showButton={false}
                emptyStateHeadline='You have no Custom Forms set up'
                emptyStateDescription='If you have a custom form as part of your checkout, the data can be exported here.'
                emptyStateImageSrc={emptyForms}
                emptyStateImageAlt='No agent profiles'
                emptyStateImageTitle='No agent profiles'
              />
            }
            isLoading={this.props.isLoading}
            totalPages={this.props.totalPages}
            currentPage={this.props.currentPage}
            fetchData={this.props.fetchData}
            onPageClick={this.handleSelectPage}
          />
        </MainContent>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const page = parseInt(match.params.page, 10) || 1;
  return {
    currentPage: page,
    data: formSelectors.getEntitiesForPage(state, page),
    totalPages: formSelectors.getTotalPages(state),
    isLoading: formSelectors.getIsLoadingForPage(state, page),
  };
};

export default connect(
  mapStateToProps,
  {
    ...formActions,
    showModal,
  }
)(FormList);
