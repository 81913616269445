import React, { useEffect } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { toast } from "react-toastify";
import { bindPromiseCreators } from "redux-saga-routines";
import { eventActions } from "../../modules/events/events";
import { Redirect } from "react-router-dom";

const EventInviteComplete = ({
  inviteId,
  token,
  dispatch,
  submitCreateEventPermission,
}) => {
  useEffect(() => {
    submitCreateEventPermission({ inviteId: inviteId, token: token }).then(
      (response) =>
        toast.success(`Invite Accepted, you have been added to the event`),
      (e) => toast.error(e.error.errors._error)
    );
  }, [submitCreateEventPermission, dispatch, inviteId, token]);

  return <Redirect to="/events" />;
};

const mapStateToProps = (state, ownProps) => {
  const inviteId = parseInt(ownProps.match.params.inviteId, 10);
  const token = ownProps.match.params.token;
  return {
    inviteId,
    token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ...bindPromiseCreators(
      {
        submitCreateEventPermission: eventActions.submitCreateEventPermission,
      },
      dispatch
    ),
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventInviteComplete);
