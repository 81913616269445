import * as types from './types';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const fetchAgent = createRoutine(types.FETCH_AGENT);

export const fetchAgentsPage = createRoutine(types.FETCH_AGENTS);
export const createAgent = createRoutine(types.CREATE_AGENT);
export const sumbmitCreateAgentForm = promisifyRoutine(createAgent);

export const updateAgent = createRoutine(types.UPDATE_AGENT);
export const sumbmitUpdateAgentForm = promisifyRoutine(updateAgent);

export const fetchInvoices = createRoutine(types.FETCH_INVOICES);
export const fetchInvoicePdf = createRoutine(types.FETCH_INVOICE_PDF);
export const deleteInvoice = createRoutine(types.DELETE_INVOICE);

export const createInvoice = createRoutine(types.CREATE_INVOICE);
export const sumbmitCreateInvoiceForm = promisifyRoutine(createInvoice);
