import React from 'react';
import classNames from 'classnames';
import DateRange from '../../DateRange/DateRange';

const FormDateField = ({
  input,
  hideLabel,
  label,
  placeholder,
  inline,
  className,
  meta: { touched, error, warning }
}) => {
  const rowClass = classNames(className, 'form__row', {
    'form__row--inline': inline
  });
  const labelClass = classNames('form__label', {
    'form__label--inline': inline
  });
  return (
    <div className={rowClass}>
      {label && (
        <label className={labelClass} hidden={hideLabel}>
          {label}
        </label>
      )}
      <DateRange
        from={input.value.from}
        to={input.value.to}
        onRangeSelect={input.onChange}
      />
      {touched &&
        ((error && <div className="form__error">{error}</div>) ||
          (warning && <div className="form__error">{warning}</div>))}
    </div>
  );
};
export default FormDateField;
