import * as fromEntities from '../shared/entities';
import * as fromListIds from '../shared/listIds';

export const getSeatingChart = (state, id) =>
  fromEntities.getEntity(state.entities.seatingChart, id);

export const getSeatingCharts = state => {
  const ids = fromListIds.getIds(state.seatingCharts.byId);
  return ids.map(id => getSeatingChart(state, id));
};

export const getSeatingChartsLoadingStatus = state =>
  state.seatingCharts.loading;

export const getSeatingUser = state => state.seatingCharts.seatingUser.data;
