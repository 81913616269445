import { eventEmailScheduleActions } from './eventEmailSchedules';

const eventEmailSchedules = (
  state = {
    data: null,
  },
  action
) => {
  switch (action.type) {
    case eventEmailScheduleActions.fetchEventEmailSchedule.TRIGGER:
      return {
        ...state,
        data: null,
      };
    case eventEmailScheduleActions.fetchEventEmailSchedules.TRIGGER: {
      return {
        ...state,
        data: null,
      };
    }
    case eventEmailScheduleActions.fetchEventEmailSchedules.SUCCESS:
      return {
        ...state,
        data: action.payload.response,
      };
    case eventEmailScheduleActions.fetchEventEmailSchedule.SUCCESS:
      return {
        ...state,
        data: [action.payload.response],
      };
    case eventEmailScheduleActions.deleteEventEmailSchedule.SUCCESS:
      return {
        ...state,
        data: state.data.filter(item => item.id !== action.payload.id),
      };
    default:
      return state;
  }
};

const moduleReducer = eventEmailSchedules;

export default moduleReducer;
