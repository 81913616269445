import { call, takeLatest, put } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as types from './types';
import * as actions from './actions';
import * as api from '../../services/api';

function* updateEmbedSettings(action) {
  yield put(showLoading());
  yield put(startSubmit('embedSettings'));
  yield put(actions.embedSettingsUpdate.request());
  const { response, error } = yield call(
    api.updateEmbedSettings,
    action.payload.eventId,
    action.payload.embedSettingsData
  );
  if (response) {
    yield put(actions.embedSettingsUpdate.success(response));
    yield put(stopSubmit('embedSettings'));
  } else {
    yield put(stopSubmit('embedSettings', { error }));
    yield put(actions.embedSettingsUpdate.failure(error));
  }
  yield put(hideLoading());
}

export function* watchUpdateEmbedSettings() {
  yield takeLatest(types.EMBED_SETTINGS_UPDATE, updateEmbedSettings);
}

function* fetchEmbedSettings(action) {
  yield put(showLoading());
  yield put(actions.embedSettings.request(action.payload.eventId));
  const { response, error } = yield call(
    api.getEmbedSettings,
    action.payload.eventId
  );
  if (response) {
    yield put(actions.embedSettings.success(action.payload.eventId, response));
  } else {
    yield put(actions.embedSettings.failure(action.payload.eventId, error));
  }
  yield put(hideLoading());
}

export function* watchLoadEmbedSettings() {
  yield takeLatest(types.LOAD_EMBED_SETTINGS, fetchEmbedSettings);
}
