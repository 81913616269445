import React from "react";
import moment from "moment";

import Select from "components/Select/Select";

// Grommet
import { Box, ResponsiveContext } from "grommet";

const LABEL_DATE_FORMAT = "Do MMMM YYYY";

const GroupedPerformanceSelect = ({ groupedPerformances, onChange, value }) => {
  const handleChange = option => {
    onChange(option.value);
  };

  const options = groupedPerformances
    .map(group => ({
      value: group.date,
      label: moment(group.date).format(LABEL_DATE_FORMAT)
    }))
    .sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });

  const selectedOption = options.find(option => option.value === value);

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box fill={size !== "small" ? false : true}>
          <Select
            value={selectedOption}
            options={options}
            selectOption={handleChange}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.value}
            placeholder={"Performance..."}
            onChange={handleChange}
          />
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default GroupedPerformanceSelect;
