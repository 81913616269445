import { select, put, takeEvery, takeLatest, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as api from '../../services/api';
import * as actions from './actions';
import * as selectors from './selectors';
import { submitForm, fetchEntity, deleteEntity } from '../shared/operations';

const fetchAgent = fetchEntity.bind(null, actions.fetchAgent, api.fetchAgent);

export function* watchFetchAgent() {
  yield takeEvery(actions.fetchAgent.TRIGGER, fetchAgent);
}

const fetchAgents = fetchEntity.bind(
  null,
  actions.fetchAgentsPage,
  api.fetchAgents
);

export function* watchLoadAgents() {
  yield takeEvery(actions.fetchAgentsPage.TRIGGER, fetchAgents);
}

const createAgent = submitForm.bind(null, actions.createAgent, api.createAgent);

export function* watchCreateAgent() {
  yield takeEvery(actions.createAgent.TRIGGER, createAgent);
}

const updateAgent = submitForm.bind(null, actions.updateAgent, api.updateAgent);

export function* watchUpdateAgent() {
  yield takeEvery(actions.updateAgent.TRIGGER, updateAgent);
}

function* showNewAgent(action) {
  const { payload } = action;
  yield put(push(`/agent/${payload.response.result}`));
}

export function* watchNewAgentCreated() {
  yield takeLatest(actions.createAgent.SUCCESS, showNewAgent);
}

const fetchInvoices = fetchEntity.bind(
  null,
  actions.fetchInvoices,
  api.fetchAgentInvoices
);
export function* watchFetchAgentInvoices() {
  yield takeEvery(actions.fetchInvoices, fetchInvoices);
}

const fetchInvoicePdf = fetchEntity.bind(
  null,
  actions.fetchInvoicePdf,
  api.fetchInvoicePdf
);
export function* watchFetchAgentInvoicePdf() {
  yield takeEvery(actions.fetchInvoicePdf, fetchInvoicePdf);
}

const deleteInvoice = deleteEntity.bind(
  null,
  actions.deleteInvoice,
  api.deleteInvoice
);
export function* watchDeleteAgentInvoice() {
  yield takeEvery(actions.deleteInvoice, deleteInvoice);
  while (true) {
    const {
      payload: { page, agentId }
    } = yield take(actions.deleteInvoice.SUCCESS);
    yield put(actions.fetchInvoices({ page: page, agentId: agentId }));
  }
}

const createInvoice = submitForm.bind(
  null,
  actions.createInvoice,
  api.createInvoice
);

function* refreshInvoices(action) {
  const { agentId } = action.payload;

  const page = yield select(selectors.getInvoicesCurrentPage, agentId);
  yield put(actions.fetchInvoices({ page: page, agentId: agentId }));
}

export function* watchCreateInvoice() {
  yield takeEvery(actions.createInvoice.TRIGGER, createInvoice);
  yield takeLatest(actions.createInvoice.SUCCESS, refreshInvoices);
}
