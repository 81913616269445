import React from 'react';
import { connect } from 'react-redux';

import MainContent, { Title } from 'components/MainContent/MainContent';
import AccountNav from 'components/Account/AccountNav/AccountNav';
import CurrentPlan from 'components/Subscription/CurrentPlan';
import FreePlan from 'components/Subscription/FreePlan';
import PricingTable from 'components/Subscription/PricingTable';
import PendingSubscriptionChange from 'components/Subscription/PendingSubscriptionChange';
import PastDue from 'components/Subscription/PastDue';
import AllPackageSubscription from 'components/Subscription/AllPackageSubscription';
import UpgradeableSubscription from 'components/Subscription/UpgradeableSubscription';

import { accountSelectors } from 'modules/account/account';

// Grommet
import { Box } from 'grommet';

const AccountPlan = ({ subscription }) => {
  if (!subscription) {
    return null;
  }
  return (
    <MainContent>
      <Title>Your Account Plan</Title>
      <div>
        <Box margin={{ vertical: 'medium' }}>
          <AccountNav />
        </Box>
      </div>
      <div>
        {subscription.pendingSubscriptionChange ? (
          <PendingSubscriptionChange
            subscription={
              subscription.pendingSubscriptionChange.nextSubscription
            }
          />
        ) : null}

        {subscription.status === 'past_due' ? (
          <PastDue subscription={subscription} />
        ) : null}

        <CurrentPlan>
          {subscription.package.code === 'free' ? (
            <FreePlan subscription={subscription} />
          ) : null}

          {subscription.isSubscribedToAllPackages ? (
            <AllPackageSubscription subscription={subscription} />
          ) : null}

          {!subscription.isSubscribedToAllPackages &&
          subscription.package.code === 'paid' &&
          subscription.status !== 'trialing' ? (
            <UpgradeableSubscription subscription={subscription} />
          ) : null}
        </CurrentPlan>

        {subscription.package.code === 'free' ? <PricingTable /> : null}
      </div>
    </MainContent>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    subscription: accountSelectors.getSubscription(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountPlan);
