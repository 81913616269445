import React from 'react';
import Select from 'react-select';
import classNames from 'classnames';

const FormFieldReactSelect = ({
  input,
  width,
  label,
  className,
  id,
  placeholder,
  creating,
  loading,
  labelAfter,
  options,
  onSelect,
  onRemove,
  onClear,
  isClearable = true,
  isDisabled,
  isMulti,
  meta: { submitting, touched, error, warning, initialValue /* formik */ },
  field, // formik
  setFormikInputValue, // formik
}) => {
  const rowClass = classNames('form__row', className);
  const styles = {
    container: styles => ({
      ...styles,
      fontFamily: 'lato, sans serif',
      fontSize: '0.85rem',
      marginTop: '0',
      marginBottom: '0',
    }),
    control: styles => ({
      ...styles,
      backgroundColor: 'white',
      borderWidth: '0.09rem',
      borderColor: '#ddd',
      borderRadius: '0.2rem',
    }),
    valueContainer: styles => ({
      ...styles,
      padding: '0 0.5rem',
    }),
  };
  const handleChange = (value, { action }) => {
    if (action === 'select-option' && value) {
      onSelect && onSelect(value);
      setFormikInputValue && setFormikInputValue(value.value);
    } else if (action === 'clear') {
      onClear && onClear();
      setFormikInputValue && setFormikInputValue('');
    }
    // for redux-form
    if (input) {
      input.onChange(value);
    }
    // for formik
    if (field) {
      field.onChange(value);
    }
  };

  const defaultValue = initialValue
    ? options.find(o => o.value === initialValue)
    : null;

  return (
    <div className={rowClass}>
      <label className='form__label' htmlFor={id}>
        {label}
      </label>
      <Select
        {...input}
        onChange={handleChange}
        isMulti={isMulti}
        onBlur={() => null}
        isDisabled={submitting || creating || isDisabled}
        styles={styles}
        isClearable={isClearable}
        placeholder={placeholder}
        options={options}
        defaultValue={defaultValue}
      />
      {touched &&
        ((error && <span className='form__error'>{error}</span>) ||
          (warning && <span className='form__error'>{warning}</span>))}
      {labelAfter && (
        <label className='form__label' htmlFor={id}>
          {labelAfter}
        </label>
      )}
    </div>
  );
};

export default FormFieldReactSelect;
