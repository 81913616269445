export const FETCH_PERFORMANCES = 'line-up/performance/FETCH_PERFORMANCES';
export const FETCH_ALL_PERFORMANCES =
  'line-up/performance/FETCH_ALL_PERFORMANCES';
export const FETCH_PERFORMANCE = 'line-up/performance/FETCH_PERFORMANCE';
export const CREATE_PERFORMANCE = 'line-up/performance/CREATE_PERFORMANCE';
export const UPDATE_PERFORMANCE = 'line-up/performance/UPDATE_PERFORMANCE';
export const SET_DATE_FILTER = 'line-up/performance/SET_DATE_FILTER';
export const DOWNLOAD_GUESTLIST_CSV =
  'line-up/performance/DOWNLOAD_GUESTLIST_CSV';
export const DOWNLOAD_GUESTLIST_PDF =
  'line-up/performance/DOWNLOAD_GUESTLIST_PDF';
export const DELETE_PERFORMANCE = 'line-up/performance/DELETE_PERFORMANCE';
