import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Modal from '../../../components/Modal/Modal';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { hideModal } from '../../../modules/modal/modal';
import { performanceActions } from '../../../modules/performances/performances';
import { PerformanceForm } from '../../../components/Event/Event';
import { removeEmpty } from '../../../utils/forms';
class PerformanceCreateModal extends Component {
  handleOnSubmit = values => {
    const performanceData = {
      ...values,
      eventId: this.props.eventId,
      status: values.status.value,
      startDate: moment
        .utc(values.startDate)
        .startOf('day')
        .toISOString(),
      endDate: moment
        .utc(values.endDate)
        .startOf('day')
        .toISOString(),
      ...(values.seatingChart
        ? { seatingChartId: values.seatingChart.value }
        : {})
    };

    return this.props.performanceId
      ? this.props.onPerformanceUpdateSubmit({
          performanceId: this.props.performanceId,
          ...removeEmpty(performanceData)
        })
      : this.props.onPerformanceCreateSubmit(removeEmpty(performanceData));
  };

  handleSubmitSuccess = (result, dispatch) => {
    this.props.onSubmitSuccess && this.props.onSubmitSuccess(result);
    this.props.hideModal();
  };
  render() {
    const seatingChartOptions = this.props.seatingCharts.map(d => ({
      value: d.id,
      label: d.name
    }));
    return (
      <Modal onRequestClose={() => this.props.hideModal()}>
        <PerformanceForm
          performanceId={this.props.performanceId}
          onSubmit={this.handleOnSubmit}
          onSubmitSuccess={this.handleSubmitSuccess}
          onCancel={this.props.hideModal}
          seatingCharts={seatingChartOptions}
          capacitySet={this.props.capacitySet}
          capacityLeft={this.props.capacityLeft}
          role={this.props.role}
        />
      </Modal>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        onPerformanceCreateSubmit:
          performanceActions.sumbmitCreatePerformanceForm,
        onPerformanceUpdateSubmit:
          performanceActions.sumbmitUpdatePerformanceForm
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        hideModal
      },
      dispatch
    )
  };
};

export default connect(
  null,
  mapDispatchToProps
)(PerformanceCreateModal);
