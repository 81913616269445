import React from 'react';
import cx from 'classnames';
import { FormattedNumber } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './PlanOption.module.css';
/* eslint-disable react/style-prop-object */
const PlanOption = ({ plan, onClick, selected = false }) => {
  const handleClick = evt => {
    evt.preventDefault();
    onClick(plan);
  };

  const rootClass = cx(styles.root, {
    [styles.selected]: selected,
  });

  return (
    <div className={rootClass} onClick={handleClick}>
      {selected && (
        <div className={styles.check}>
          <FontAwesomeIcon icon='check-circle' fixedWidth />
        </div>
      )}
      <div className={styles.name}>{plan.name}</div>
      <div className={styles.description}>{plan.description}</div>
      <div className={styles.price}>
        <span className={styles.amount}>
          <FormattedNumber
            value={plan.amount}
            style='currency'
            currency={plan.currency}
          />
        </span>
        /month
      </div>
    </div>
  );
};

export default PlanOption;
