import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../../components/Modal/Modal';
import Button from '../../components/Button/Button';
import { hideModal } from '../../modules/modal/modal';
import './AlertModal.css';
class AlertModal extends Component {
  handleCancelClick = () => {
    this.props.hideModal();
  };
  handleConfirmClick = () => {
    this.props.onConfirmClick && this.props.onConfirmClick();
    this.props.hideModal();
  };

  render() {
    return (
      <Modal onRequestClose={() => this.props.hideModal()}>
        <div className="alert-modal">
          <div className="alert__title">{this.props.title}</div>
          <div className="alert__description">{this.props.description}</div>
          <div className="alert__actions">
            <Button
              secondary
              className="alert__button"
              onClick={this.handleCancelClick}
            >
              Cancel
            </Button>
            <Button
              className="alert__button"
              primary
              onClick={this.handleConfirmClick}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(
  null,
  {
    hideModal
  }
)(AlertModal);
