import React from 'react';
import classNames from 'classnames';
import './TableCell.css';

const TableCell = ({
  children,
  width,
  image,
  className,
  borders,
  center,
  onClick,
  texttop,
  textright
}) => {
  const style = {
    width: `${width}%`
  };
  const cellClass = classNames('lutable__cell', className, {
    'lutable__cell--image': image,
    'lutable__cell--borders': borders,
    'lutable__cell--center': center,
    'lutable__cell--texttop': texttop,
    'lutable__cell--textright': textright
  });
  const handleClick = e => {
    onClick && onClick(e);
  };
  return (
    <div style={style} className={cellClass} onClick={handleClick}>
      {children}
    </div>
  );
};

export default TableCell;
