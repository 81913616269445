import * as fromPaginator from '../shared/paginate';
import * as fromEntities from '../shared/entities';

export const getInvoice = (state, invoiceId) =>
  fromEntities.getEntity(state.entities.invoice, invoiceId);

export const getInvoicesLoadingStatus = (state, page) =>
  fromPaginator.getIsLoading(state.invoices.byPage, page);

export const getInvoicesForPage = (state, page) =>
  fromPaginator
    .getIdsForPage(state.invoices.byPage, page)
    .map(id => fromEntities.getEntity(state.entities.invoice, id));

export const getInvoicesCurrentPage = state =>
  fromPaginator.getCurrentPage(state.invoices.byPage);

export const getInvoicesTotalPages = state =>
  fromPaginator.getTotalPages(state.invoices.byPage);
