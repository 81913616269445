import React from 'react';
import DropdownMenu from 'components/DropdownMenu/DropdownMenu';
import styles from './LaneMenu.module.css';

// Grommet
import { Anchor } from 'grommet';
import { Lock, SubtractCircle, Edit, Trash } from 'grommet-icons';

const LaneMenu = ({
  lane,
  onLockLane,
  onDisableLane,
  onEditLane,
  onDeleteLane,
  isLocked,
  isDisabled
}) => {
  const createClickHandler = (callback, onClose) => {
    return evt => {
      evt.preventDefault();
      callback(lane);
      onClose();
    };
  };

  return (
    <DropdownMenu title="Lane Options">
      {({ onClose }) => (
        <div className={styles.root}>
          <ul>
            <li>
              <b>This date only</b>
            </li>
            <li>
              <Anchor
                href="#lockLane"
                onClick={createClickHandler(onLockLane, onClose)}
                label={isLocked ? 'Unlock lane' : 'Lock lane'}
                icon={<Lock size="small" />}
                size="small"
              />
            </li>
            <li>
              <Anchor
                href="#disableLane"
                onClick={createClickHandler(onDisableLane, onClose)}
                label={isDisabled ? 'Activate lane' : 'Disable lane'}
                icon={<SubtractCircle size="small" />}
                size="small"
              />
            </li>
          </ul>
          <ul>
            <li>
              <b>All dates</b>
            </li>
            <li>
              <Anchor
                href="#edit"
                onClick={createClickHandler(onEditLane, onClose)}
                label="Edit lane settings"
                icon={<Edit size="small" />}
                size="small"
              />
            </li>
            <li>
              <Anchor
                href="#delete"
                onClick={createClickHandler(onDeleteLane, onClose)}
                label="Delete lane"
                icon={<Trash size="small" />}
                size="small"
              />
            </li>
          </ul>
        </div>
      )}
    </DropdownMenu>
  );
};

export default LaneMenu;
