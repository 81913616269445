import React from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import './CodeMirrorCustom.css';

const CodeEditor = ({ value, onChange, contentType }) => {
  const optionsMap = {
    html: {
      mode: 'htmlmixed',
      theme: 'material',
      lineNumbers: true,
      lineWrapping: true,
      viewportMargin: Infinity,
    },
    text: {
      mode: 'text',
      theme: 'material',
      lineNumbers: true,
      lineWrapping: true,
      viewportMargin: Infinity,
    },
  };
  return (
    <CodeMirror
      value={value[contentType]}
      options={optionsMap[contentType]}
      onBeforeChange={(editor, data, newValue) => {
        const oldContentTypeValue = value[contentType];
        if (oldContentTypeValue !== newValue) {
          const data = {
            ...value,
            [contentType]: newValue,
          };
          onChange(data);
        }
      }}
      onChange={(editor, data, value) => {}}
    />
  );
};

export default CodeEditor;
