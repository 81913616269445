import React from 'react';

// Grommet
import { Menu, Nav, ResponsiveContext, Button } from 'grommet';
import { Menu as MenuIcon } from 'grommet-icons';

const PageNav = ({ children, mobileMenu }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          {size !== 'small' ? (
            <Nav direction='row' border='bottom' gap='none'>
              {children}
            </Nav>
          ) : (
            <Menu
              plain
              fill
              icon={false}
              dropProps={{
                align: { top: 'bottom', left: 'left' },
                elevation: 'xlarge',
                round: 'small'
              }}
              items={children}
            >
              {() => {
                return (
                  <Button
                    secondary
                    label={mobileMenu ? mobileMenu : 'Section Menu'}
                    icon={<MenuIcon size='small' />}
                    fill
                    size='small'
                  />
                );
              }}
            </Menu>
          )}
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default PageNav;

export { default as PageNavItem } from './PageNavItem/PageNavItem';
