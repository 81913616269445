import React from 'react';
import { FormattedDate } from 'react-intl';
import { TableCell, TableRow } from '../../../Table/Table';
import { Price } from '../../../Utils/Utils';
import './LineItemRowItem.css';

const LineItemRowItem = ({
  title,
  description,
  quantity,
  date,
  total,
  currency,
  lineItemType,
}) => (
  <TableRow className='line-items__title'>
    <TableCell width='10'>
      <div className='accordion__arrow' />
    </TableCell>
    <TableCell width='30'>{title}</TableCell>
    <TableCell width='30'>
      {description ? `${quantity} x ${description}` : ''}
    </TableCell>
    <TableCell width='20'>
      {date && (
        <FormattedDate
          value={date}
          weekday='short'
          year='numeric'
          
          day='numeric'
          month='short'
          hour='numeric'
          minute='numeric'
        />
      )}
    </TableCell>
    <TableCell width='10'>
      <Price amount={total} currency={currency} />
    </TableCell>
  </TableRow>
);
export default LineItemRowItem;
