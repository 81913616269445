import React from 'react';
import { connect } from 'react-redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { bindActionCreators } from 'redux';
import { SubmissionError } from 'redux-form';

import MainContent, {
  Title,
  LineUpHelmet,
} from '../../components/MainContent/MainContent';
import EmailEditorForm from '../../components/EmailEditor/EmailEditorForm';

import { emailSelectors, emailActions } from '../../modules/emails/emails';
import { showModal } from '../../modules/modal/modal';
import { push } from 'connected-react-router';
// Grommet
import { Box } from 'grommet';

const EmailEdit = ({
  emailId,
  email,
  fetchEmail,
  submitUpdateEmailForm,
  showModal,
  dispatch,
}) => {
  // if user refreshes the page, our email won't be in the entites store
  const [fetchTriggered, setFetchTriggered] = React.useState(false);

  React.useEffect(() => {
    if (!email.id && !fetchTriggered) {
      setFetchTriggered(true);
      fetchEmail({ emailId });
    }
  }, [email, fetchTriggered, fetchEmail, emailId]);

  const handleSave = values => {
    const normalizedValues = {
      ...values,
      html: values.code.html, // we need to pull out html & text
      text: values.code.text,
    };
    return submitUpdateEmailForm({ email, values: normalizedValues })
      .then(() => dispatch(push('/emails')))
      .catch(e => {
        throw new SubmissionError({
          code: e.errors._error,
        });
      });
  };

  // this shows the modal
  const handleClickSendPreview = () => {
    showModal('EMAIL_SEND_PREVIEW', {
      emailId: email.id,
    });
  };

  // don't show anything until we've got the email data
  if (!email.id) {
    return null;
  }

  return (
    <>
      <LineUpHelmet
        title='Edit Email Template'
        description='Edit your Line-Up email template'
      />
      <MainContent>
        <div>
          <Box direction='row' justify='between' align='center'>
            <Title>Edit Email Template</Title>
          </Box>
          <Box margin={{ top: 'medium', bottom: 'large' }}>
            <EmailEditorForm
              onClickSendPreview={handleClickSendPreview}
              onSubmit={handleSave}
              initialValues={{
                ...email,
                code: {
                  html: email.html,
                  text: email.text,
                },
              }}
            />
          </Box>
        </div>
      </MainContent>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const emailId = match.params.emailId
    ? parseInt(ownProps.match.params.emailId, 10)
    : null;
  const email = emailSelectors.getEntity(state, emailId);
  return {
    emailId,
    email,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        submitUpdateEmailForm: emailActions.submitUpdateEmail,
      },
      dispatch
    ),
    ...bindActionCreators(emailActions, dispatch),
    ...bindActionCreators({ showModal }, dispatch),
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailEdit);
