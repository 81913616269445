import React from 'react';
import { Field } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormField from '../FormField/FormField';
import Button from '../../Button/Button';
import '../FormFieldArray/FormFieldArray.css';

const float = value => value && parseFloat(value, 10);

const FormFieldArray = ({ fields, meta: { error } }) => (
  <span>
    <span>
      <Button
        type="button"
        className="donation-option__button"
        primary
        onClick={() => fields.push()}
      >
        Add Donation Option
      </Button>
    </span>
    <div className="donation-options">
      {fields.map((item, index) => (
        <span className="donation-option" key={index}>
          <Field
            name={item}
            type="number"
            step="any"
            placeholder="Donation Amount"
            component={FormField}
            className="form__row--dontation"
            normalize={float}
          />
          <Button type="button" danger onClick={() => fields.remove(index)}>
            <FontAwesomeIcon icon="trash" />
          </Button>
        </span>
      ))}
      {error && <span className="form__error--donation">{error}</span>}
    </div>
  </span>
);
export default FormFieldArray;
