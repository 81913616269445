import React from 'react';

import styled from 'styled-components';

// Grommet
import { Box, Button } from 'grommet';

import Table, {
  TableCell,
  TableHeader,
  TableBody,
  TableRow
} from '../Table/Table';
const DeleteButton = styled(Button)`
  background-color: #ff2e4a;
  &:hover {
    background-color: #d41c34;
  }
`;
const UserPermissionTable = ({
  permissions,
  pendingInvitations,
  onEventPermissionDelete,
  onEventInvitationDelete,
  onEventInvitationResend
}) => {
  return (
    <Table containerBorder mobileWide>
      <TableHeader className='receipt__header'>
        <TableCell width={45}>USER</TableCell>
        <TableCell width={20}>ROLE</TableCell>
        <TableCell width={15}>STATUS</TableCell>
        <TableCell width={20} />
      </TableHeader>
      <TableBody
        renderItem={item => (
          <TableRow striped={true} noClick={true}>
            <TableCell width={45}>{item.emailAddress}</TableCell>
            <TableCell width={20}>{item.role}</TableCell>
            <TableCell width={15}>Pending</TableCell>
            <TableCell width={20}>
              <Box gap='small' direction='row' justify='end'>
                <Button
                  secondary
                  label='Resend'
                  size='small'
                  onClick={() => onEventInvitationResend(item)}
                />
                <DeleteButton
                  onClick={() => onEventInvitationDelete(item)}
                  label='Remove'
                  primary
                  size='small'
                />
              </Box>
            </TableCell>
          </TableRow>
        )}
        items={pendingInvitations}
      />
      <TableBody
        renderItem={item => (
          <TableRow striped={true} noClick={true}>
            <TableCell width={45}>{item.userDisplayName}</TableCell>
            <TableCell width={20}>{item.role}</TableCell>
            <TableCell width={15}>
              {item.role.toLowerCase() !== 'owner' && 'Accepted'}
            </TableCell>
            <TableCell width={20} textright>
              {item.role.toLowerCase() !== 'owner' && (
                <DeleteButton
                  onClick={() => onEventPermissionDelete(item)}
                  label='Remove'
                  primary
                  size='small'
                />
              )}
            </TableCell>
          </TableRow>
        )}
        items={permissions}
      />
    </Table>
  );
};

export default UserPermissionTable;
