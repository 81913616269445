import React from 'react';
import { Link } from 'react-router-dom';
import { Text } from 'grommet';

const StyledLink = ({ label, to }) => {
  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <Text size='small' color='brand'>
        {label}
      </Text>
    </Link>
  );
};

export default StyledLink;
