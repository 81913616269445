import * as types from './types';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const fetchEventEmailSchedules = createRoutine(
  types.FETCH_EVENT_EMAIL_SCHEDULES
);

export const fetchEventEmailSchedule = createRoutine(
  types.FETCH_EVENT_EMAIL_SCHEDULE
);
export const createEventEmailSchedule = createRoutine(
  types.CREATE_EVENT_EMAIL_SCHEDULE
);
export const deleteEventEmailSchedule = createRoutine(
  types.DELETE_EVENT_EMAIL_SCHEDULE
);
export const updateEventEmailSchedule = createRoutine(
  types.UPDATE_EVENT_EMAIL_SCHEDULE
);

export const submitUpdateEventEmailSchedule = promisifyRoutine(
  updateEventEmailSchedule
);
export const submitCreateEventEmailSchedule = promisifyRoutine(
  createEventEmailSchedule
);
