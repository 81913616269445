import React from 'react';
import styles from './SpaceWarningLabel.module.css';

const SpaceWarningLabel = ({ layout, pendingPlacements }) => {
  const style = {
    top: `${layout.top}px`,
    height: `${layout.height}px`
  };

  return (
    <div style={style} className={styles.notEnoughSpaceWarningContainer}>
      <span className={styles.notEnoughSpaceWarningLabel}>
        {pendingPlacements.length} Unplaced Booking
      </span>
    </div>
  );
};

export default SpaceWarningLabel;
