import React from 'react';
import { FormattedDate } from 'react-intl';
/* eslint-disable react/style-prop-object */
import { TableCell, TableRow } from '../../../components/Table/Table';
import Button from '../../../components/Button/Button';
import { getPerformanceStartDate } from '../../../utils/dates';
import { performanceStatus } from '../../../utils/types';

const PerformanceRowItem = ({ performance, onGeneratePerformanceLink }) => {
  const performanceDate = getPerformanceStartDate(performance);
  const status = performanceStatus[performance.status];
  return (
    <TableRow striped={true} toLink={onGeneratePerformanceLink(performance.id)}>
      <TableCell>
        <FormattedDate
          value={performanceDate}
          year='numeric'
          day='numeric'
          month='short'
          weekday='short'
        />
      </TableCell>
      <TableCell>
        <FormattedDate
          value={performanceDate}
          hour='numeric'
          minute='numeric'
        />
      </TableCell>
      <TableCell>{status ? status : null}</TableCell>
      <TableCell center>
        {performance.availability === null
          ? 'N/A'
          : performance.capacitySet - performance.capacityLeft}
      </TableCell>
      <TableCell center>
        {performance.availability === null
          ? 'N/A'
          : performance.capacityLeft > 0
          ? `${performance.capacityLeft} / ${performance.capacitySet}`
          : 'Sold Out'}
      </TableCell>

      <TableCell>
        <Button secondary href={onGeneratePerformanceLink(performance.id)}>
          Manage
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default PerformanceRowItem;
