import reducer from './reducers';
import * as embedSettingsSelectors from './selectors';
import * as embedSettingsTypes from './types';
import * as embedSettingsActions from './actions';
import * as embedSettingsOperations from './operations';

export {
  embedSettingsSelectors,
  embedSettingsTypes,
  embedSettingsActions,
  embedSettingsOperations
};

export default reducer;
