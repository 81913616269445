import React from 'react';
import './Table.css';
import classNames from 'classnames';

// Grommet
import { Box, ResponsiveContext } from 'grommet';

import TableCell from './TableCell/TableCell';
import TableRow from './TableRow/TableRow';
import TableHeader from './TableHeader/TableHeader';
import TableBody from './TableBody/TableBody';

const Table = ({
  className,
  borders,
  width,
  tableMargin,
  tableTitle,
  containerBorder,
  columns,
  mobileWide,
  onRowClick,
  data = [],
}) => {
  const tableContainerStyle = {
    width: `${width}%`,
    margin: `${tableMargin}`,
  };
  const tableClass = classNames(className, 'lutable', {
    'lutable--borders': borders,
    'lutable--mobile-wide': mobileWide,
  });
  const tableContainerClass = classNames(className, 'lutable-container', {
    'lutable-container--container-border': containerBorder,
    'lutable-container--mobile-wide': mobileWide,
  });
  const handleOnClick = (item) => {
    onRowClick && onRowClick(item);
  };
  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <>
          {size !== 'small' ? (
            <Box margin={{ bottom: 'large' }}>
              <div style={tableContainerStyle} className={tableContainerClass}>
                {tableTitle && (
                  <div className='lutable-title'>{tableTitle}</div>
                )}
                <div className={tableClass}>
                  <TableHeader>
                    {columns.map((column, idx) => (
                      <TableCell key={idx} width={column.width}>
                        {column.title}
                      </TableCell>
                    ))}
                  </TableHeader>
                  <TableBody
                    renderItem={(item, idx) => (
                      <TableRow
                        key={idx}
                        striped={true}
                        onClick={() => handleOnClick(item)}
                      >
                        {columns.map((column, idx) => {
                          const propertyValue =
                            column.dataIndex &&
                            column.dataIndex
                              .split('.')
                              .reduce((prev, curr) => prev && prev[curr], item);
                          return (
                            <TableCell
                              key={idx}
                              center={column.align === 'center'}
                            >
                              {column.render
                                ? column.render(propertyValue, item)
                                : propertyValue}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    )}
                    items={data}
                  />
                </div>
              </div>
            </Box>
          ) : (
            <Box margin={{ bottom: 'large' }}>
              <div className={tableContainerClass}>
                {tableTitle && (
                  <div className='lutable-title'>{tableTitle}</div>
                )}
                <div className={tableClass}>
                  <TableHeader>
                    {columns.map((column, idx) => (
                      <TableCell key={idx} width={column.width}>
                        {column.title}
                      </TableCell>
                    ))}
                  </TableHeader>
                  <TableBody
                    renderItem={(item, idx) => (
                      <TableRow
                        key={idx}
                        striped={true}
                        onClick={() => handleOnClick(item)}
                      >
                        {columns.map((column, idx) => (
                          <TableCell
                            key={idx}
                            center={column.align === 'center'}
                          >
                            {column.render
                              ? column.render(item[column.dataIndex], item)
                              : item[column.dataIndex]}
                          </TableCell>
                        ))}
                      </TableRow>
                    )}
                    items={data}
                  />
                </div>
              </div>
            </Box>
          )}
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default Table;
