export const plans = [
  {
    name: 'Basic',
    textPrice: 'Free'
  },
  {
    name: 'Standard',
    amount: 19
  },
  {
    name: 'Pro',
    amount: 49
  },
  {
    name: 'Enterprise',
    textPrice: 'Contact us'
  }
];

export const row1 = [
  {
    name: 'Number of events',
    plan: {
      basic: 'Unlimited',
      standard: 'Unlimited',
      pro: 'Unlimited',
      enterprise: 'Unlimited'
    }
  },
  {
    name: 'Number of tickets/bookings',
    plan: {
      basic: 'Unlimited',
      standard: 'Unlimited',
      pro: 'Unlimited',
      enterprise: 'Unlimited'
    }
  }
];

export const row2 = [
  {
    name: 'Line-Up transaction free per ticket',
    plan: {
      basic: '5%',
      standard: '3%',
      pro: '2%',
      enterprise: 'Contact us'
    }
  },
  {
    name: 'Pause or cancel at any time',
    plan: {
      basic: false
    }
  }
];

export const row3 = [
  {
    name: 'Sell tickets directly on your website or in your app',
    plan: {}
  },
  {
    name: 'Full inventory management and sales reports',
    plan: {}
  },
  {
    name: 'Embeddable events calendar',
    plan: {}
  },
  {
    name: 'Check-in App',
    plan: {}
  },
  {
    name: 'Sell on Facebook Page',
    plan: {}
  },
  {
    name: 'Discount codes',
    plan: {
      basic: false
    }
  },
  {
    name: 'Sell gift vouchers',
    plan: {
      basic: false
    }
  },
  {
    name: 'Analytics integration',
    plan: {
      basic: false
    }
  },
  {
    name: 'Customisable tickets',
    plan: {
      basic: false,
      standard: false
    }
  },
  {
    name: 'Customisable booking fee',
    plan: {
      basic: false,
      standard: false
    }
  },
  {
    name: 'White label mobile app',
    plan: {
      basic: false,
      standard: false,
      pro: false
    }
  }
];
