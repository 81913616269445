import { takeEvery, put, call } from 'redux-saga/effects';
import { fetchEntity, submitForm } from '../shared/operations';
import * as actions from './actions';
import * as api from '../../services/api';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { push } from 'connected-react-router';

const fetchMailchimpIntegration = fetchEntity.bind(
  null,
  actions.fetchMailchimpIntegration,
  api.fetchMailchimpIntegration
);

export function* watchFetchMailchimpIntegration() {
  yield takeEvery(
    actions.fetchMailchimpIntegration.TRIGGER,
    fetchMailchimpIntegration
  );
}

function* completeMailchimpConnect(action) {
  yield put(showLoading());
  yield put(actions.completeMailchimpConnect.request());
  const { response, error } = yield call(
    api.completeMailchimpConnect,
    action.payload.code
  );
  if (response) {
    yield put(actions.completeMailchimpConnect.success({ response }));
    yield put(push(`/mailchimp/`));
  } else {
    yield put(actions.completeMailchimpConnect.failure({ error }));
  }
  yield put(actions.completeMailchimpConnect.fulfill());
  yield put(hideLoading());
}

export function* watchCompleteMailchimpConnect() {
  yield takeEvery(
    actions.completeMailchimpConnect.TRIGGER,
    completeMailchimpConnect
  );
}

const fetchMailchimpLists = fetchEntity.bind(
  null,
  actions.fetchMailchimpLists,
  api.fetchMailchimpLists
);

export function* watchFetchMailchimpLists() {
  yield takeEvery(actions.fetchMailchimpLists.TRIGGER, fetchMailchimpLists);
}

const updateMailchimpIntegration = submitForm.bind(
  null,
  actions.updateMailchimpIntegration,
  api.updateMailchimpIntegration
);

export function* watchUpdateMailchimpIntegration() {
  yield takeEvery(
    actions.updateMailchimpIntegration.TRIGGER,
    updateMailchimpIntegration
  );
}

const syncEventAttendeesToMailchimp = submitForm.bind(
  null,
  actions.syncEventAttendeesToMailchimp,
  api.syncAttendeesToMailchimp
);

export function* watchSyncEventAttendeesToMailchimp() {
  yield takeEvery(
    actions.syncEventAttendeesToMailchimp.TRIGGER,
    syncEventAttendeesToMailchimp
  );
}

function* disconnectMailchimpIntegration(action) {
  yield put(showLoading());
  yield put(actions.disconnectMailchimpIntegration.request());
  const { response, error } = yield call(api.disconnectMailchimpIntegration);
  if (response) {
    yield put(actions.disconnectMailchimpIntegration.success({ response }));
    yield put(actions.fetchMailchimpIntegration.trigger());
    yield put(push(`/mailchimp`));
  } else {
    yield put(actions.disconnectMailchimpIntegration.failure({ error }));
  }
  yield put(actions.disconnectMailchimpIntegration.fulfill());
  yield put(hideLoading());
}

export function* watchDisconnectMailchimpIntegration() {
  yield takeEvery(
    actions.disconnectMailchimpIntegration,
    disconnectMailchimpIntegration
  );
}
