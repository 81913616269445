import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class TicketWidget extends Component {
  componentDidMount() {
    window.LineupTicketingLoader && window.LineupTicketingLoader.setupWidgets();
  }

  render() {
    const LOADER_URL = process.env.REACT_APP_LOADER_URL;
    const { eventId, apiKey } = this.props;
    return (
      <>
        <Helmet>
          <script src={LOADER_URL} />
        </Helmet>
        <div
          className='lineup-ticketing'
          data-lineup-event-id={eventId}
          data-lineup-api-key={apiKey}
        />
      </>
    );
  }
}

export default TicketWidget;
