import React from 'react';

// Grommet
import { Box, Text, ResponsiveContext } from 'grommet';

const InfoBlockListItem = ({ attribute, value }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Box direction="row" gap="small" align="start">
            <Box
              width={size !== 'small' ? 'small' : '45%'}
              justify="end"
              align="end"
            >
              <Text size="small" color="copy-light">
                {attribute}:
              </Text>
            </Box>
            <Text size="small" wordBreak="break-word">
              {value}
            </Text>
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default InfoBlockListItem;
