import React, { useState, useEffect } from "react";
import Select from "../Select/Select";
import "./ReportFilters.css";
import moment from "moment";
import DateRange from "../DateRange/DateRange";
import DateSelect from "../DateRange/DateSelect";

// Grommet
import { Box, Button, ResponsiveContext } from "grommet";
import { Filter, Play, Trash } from "grommet-icons";

const operators = {
  item: [{ label: "is", value: "=", type: "value" }],
  date: [
    { label: "Between", value: "><", type: "dateRange" },
    // { label: "is", value: "=", type: "date" },
    // { label: "Before", value: "<", type: "date" },
    // { label: "After", value: ">", type: "date" }
  ],
  hybrid: [
    { label: "Between", value: "><", type: "dateRange" },
    { label: "is", value: "=", type: "value" },
    // { label: "Before", value: "<", type: "date" },
    // { label: "After", value: ">", type: "date" }
  ],
};

const ReportFilter = (props) => {
  const {
    attribute,
    operator,
    value,
    canRemove,
    id,
    onRemoveFilter,
    onUpdateFilter,
    filterOptions,
  } = props;
  const [selectedAttribute, setSelectedAttribute] = useState();
  const [selectedOperator, setSelectedOperator] = useState();
  const [selectedValue, setSelectedValue] = useState();

  const parseDateToString = (date, adjust) => {
    if (adjust === "start")
      return moment
        .utc(date)
        .startOf("day")
        .toISOString();
    if (adjust === "end")
      return moment
        .utc(date)
        .endOf("day")
        .toISOString();
    return moment.utc(date).toISOString();
  };

  useEffect(() => {
    const atr = filterOptions.find((filter) => filter.value === attribute);
    const opp =
      atr && operators[atr.operatorType].find((op) => op.value === operator);
    let val;
    if (opp && opp.type === "value") {
      val = atr.values && atr.values.find((v) => v.value === value);
    } else if (opp && opp.type === "dateRange") {
      val = {
        from: moment.utc(value.from).toDate(),
        to: moment.utc(value.to).toDate(),
      };
    } else if (opp && opp.type === "date") {
      val = moment.utc(value).toDate();
    }

    atr && setSelectedAttribute(atr);
    opp && setSelectedOperator(opp);
    val && setSelectedValue(val);
  }, [attribute, operator, value, filterOptions]);

  const handleAttributeSelect = (option) => {
    setSelectedAttribute(option);
    setSelectedOperator(null);
    setSelectedValue(moment.utc().toDate());
  };

  const handleOperatorSelect = (option) => {
    setSelectedOperator(option);
  };

  const handleDateRangeChange = (values) => {
    setSelectedValue(values);
    const { from, to } = values;
    const range = {
      from: parseDateToString(from, "start"),
      to: parseDateToString(to, "end"),
    };
    onUpdateFilter(id, selectedAttribute.value, selectedOperator.value, range);
  };

  const handleDateChange = (date) => {
    setSelectedValue(date);
    onUpdateFilter(
      id,
      selectedAttribute.value,
      selectedOperator.value,
      parseDateToString(date, "start")
    );
  };

  const handleValueSelect = (option) => {
    setSelectedValue(option);
    onUpdateFilter(
      id,
      selectedAttribute.value,
      selectedOperator.value,
      option.value
    );
  };

  const handleRemoveClick = () => {
    onRemoveFilter && onRemoveFilter(id);
  };

  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Box
          justify={size !== "small" ? "between" : "center"}
          direction={size !== "small" ? "row" : "column"}
          align="center"
          margin={{ bottom: "small" }}
          gap={size !== "small" ? "none" : "medium"}
          background={size !== "small" ? "none" : "sidebar-copy"}
          pad={size !== "small" ? "none" : "small"}
          round={size !== "small" ? "none" : "small"}
        >
          <Box
            width={size !== "small" ? { min: "31%", max: "31%" } : "100%"}
            margin={size !== "small" ? { right: "1%" } : "none"}
          >
            <Select
              value={selectedAttribute}
              isDisabled={!canRemove}
              options={filterOptions}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              placeholder={"Select attribute to filter..."}
              onChange={handleAttributeSelect}
            />
          </Box>
          <Box
            width={size !== "small" ? { min: "31%", max: "31%" } : "100%"}
            margin={size !== "small" ? { right: "1%" } : "none"}
          >
            <Select
              value={selectedOperator}
              isDisabled={!canRemove}
              options={
                selectedAttribute && operators[selectedAttribute.operatorType]
              }
              placeholder={"Select filter type..."}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              onChange={handleOperatorSelect}
            />
          </Box>
          <Box
            width={size !== "small" ? { min: "31%", max: "31%" } : "100%"}
            margin={size !== "small" ? { right: "1%" } : "none"}
          >
            {selectedOperator && (
              <>
                {selectedAttribute &&
                  selectedOperator.type === "value" &&
                  selectedAttribute.values && (
                    <Select
                      value={selectedValue}
                      options={selectedAttribute.values}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      placeholder={"Select value..."}
                      onChange={handleValueSelect}
                    />
                  )}
                {selectedAttribute && selectedOperator.type === "date" && (
                  <DateSelect
                    value={selectedValue}
                    onDaySelect={handleDateChange}
                    placeholder="Select Date"
                  />
                )}
                {selectedAttribute && selectedOperator.type === "dateRange" && (
                  <DateRange
                    from={selectedValue && selectedValue.from}
                    to={selectedValue && selectedValue.to}
                    disableAfter={moment()
                      .add(365, "days")
                      .toDate()}
                    onRangeSelect={handleDateRangeChange}
                  />
                )}
              </>
            )}
          </Box>
          <Box
            width={size !== "small" ? "2%" : "100%"}
            align="center"
            justify="end"
          >
            {canRemove && (
              <Button
                onClick={handleRemoveClick}
                plain
                icon={<Trash size="small" />}
              />
            )}
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};
const ReportFilters = (props) => {
  const {
    onAddFilter,
    onUpdateFilter,
    onRemoveFilter,
    onRunReportClick,
    currentFilters,
    filters,
    allowAdd = true,
  } = props;

  const addIsDisabled = currentFilters.length === filters.length;
  const selectedFilters = currentFilters.map((filter) => filter.attribute);

  const renderReportFilter = (filter) => {
    const options = filters.filter(
      (f) => f.value === filter.attribute || !selectedFilters.includes(f.value)
    );
    return (
      <ReportFilter
        key={filter.id}
        filterOptions={options}
        onRemoveFilter={onRemoveFilter}
        onUpdateFilter={onUpdateFilter}
        attribute={filter.attribute}
        operator={filter.operator}
        value={filter.value}
        id={filter.id}
        canRemove={filter.canRemove}
      />
    );
  };
  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <div className="report-filters">
          <Box
            background="infobox"
            margin={
              size !== "small" ? { vertical: "medium" } : { vertical: "large" }
            }
            pad="medium"
            gap="medium"
          >
            <Box>
              {currentFilters.map((filter) => renderReportFilter(filter))}
            </Box>

            <Box
              direction={size !== "small" ? "row" : "column"}
              justify={size !== "small" ? "between" : "center"}
              gap={size !== "small" ? "none" : "medium"}
            >
              <Button
                tertiary
                icon={<Filter size="small" />}
                onClick={onAddFilter}
                disabled={addIsDisabled || !allowAdd}
                label="Add Filter"
                size="small"
                secondary
              />
              <Button
                primary
                onClick={onRunReportClick}
                label="Run Report"
                size="small"
                icon={<Play size="small" />}
              />
            </Box>
          </Box>
        </div>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default ReportFilters;
