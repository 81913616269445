import * as fromListIds from '../shared/listIds';
import * as fromEntities from '../shared/entities';
import { getTicket } from '../tickets/selectors';
//- Selectors
export const getTicketGroup = (state, ticketGroupId) =>
  fromEntities.getEntity(state.entities.ticketGroup, ticketGroupId);

export const getTicketGroups = state => {
  const ids = fromListIds.getIds(state.ticketGroups.byId);
  return ids.map(id => fromEntities.getEntity(state.entities.ticketGroup, id));
};

export const getCreatingStatus = state => state.ticketGroups.creating;
export const getLoadingStatus = state => state.ticketGroups.loading;

export const getTicketIds = (state, ticketGroupId) =>
  state.ticketGroups.ticketsByTicketGroup[ticketGroupId]
    ? state.ticketGroups.ticketsByTicketGroup[ticketGroupId].ids
    : [];
export const getTicketsForTicketGroup = (state, ticketGroupId) =>
  getTicketIds(state, ticketGroupId).map(id => getTicket(state, id));

export const getTicketGroupTicketsCurrentPage = (state, ticketGroupId) =>
  state.ticketGroups.ticketsByTicketGroup[ticketGroupId]
    ? state.ticketGroups.ticketsByTicketGroup[ticketGroupId].currentPage
    : 1;

export const getTicketGroupTickets = (state, ticketGroupId, perPage = 25) => {
  const page = getTicketGroupTicketsCurrentPage(state, ticketGroupId);
  return getTicketIds(state, ticketGroupId)
    .slice((page - 1) * perPage, page * perPage)
    .map(id => getTicket(state, id));
};

export const getTicketGroupTicketsTotalPages = (
  state,
  ticketGroupId,
  perPage = 25
) =>
  Math.max(1, Math.ceil(getTicketIds(state, ticketGroupId).length / perPage));

export const getTicketLoadingStatus = (state, ticketGroupId) =>
  state.ticketGroups.ticketsByTicketGroup[ticketGroupId]
    ? state.ticketGroups.ticketsByTicketGroup[ticketGroupId].isFetching
    : false;

export const getNewTicketGroupId = state => state.ticketGroups.newTicketGroup;
