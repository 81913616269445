import React from 'react';
import Button from 'components/Button/Button';
import styles from './FreePlan.module.css';

const FreePlan = ({ subscription }) => {
  return (
    <div className={styles.root}>
      <div>
        <h1>Basic</h1>
        <h2>Free</h2>
        <p>5% ticket transaction fee</p>
      </div>
      <Button primary={true} to={`/account/change-plan`}>
        Upgrade now
      </Button>
    </div>
  );
};

export default FreePlan;
