import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import MainContent, {
  Title,
  HeaderActions,
  PageTop,
  LineUpHelmet
} from '../../components/MainContent/MainContent';
import EmptyState from '../../components/EmptyState/EmptyState';

import TableView from '../table/TableView';
import { FormattedDate } from 'react-intl';
import { agentSelectors, agentActions } from '../../modules/agents/agents';

// Grommet
import { Box, Button } from 'grommet';
import { Add } from 'grommet-icons';

// Assets
import emptyAgents from './empty_agents.png';

const columns = [
  {
    title: 'Company',
    dataIndex: 'company',
    width: '40'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    width: '40'
  },
  {
    title: 'Date Created',
    dataIndex: 'createdAt',
    width: '20',
    render: createdAt => (
      <FormattedDate
        value={createdAt}
        weekday='short'
        year='numeric'
        day='numeric'
        month='short'
      />
    )
  }
];

class AgentList extends Component {
  handleAgentClick = agent => {
    this.props.history.push(`/agent/${agent.id}`);
  };

  handleSelectPage = page => {
    this.props.history.push(`/agents/${page}`);
  };

  render() {
    const { agents, currentPage, totalPages, isLoading } = this.props;
    return (
      <>
        <LineUpHelmet
          title='Agents'
          description='Manage your agents in Line-Up'
        />
        <MainContent>
          <PageTop>
            <Title>Agents</Title>
            <HeaderActions>
              <Link to='/agent/create'>
                <Button
                  primary
                  label='New Agent'
                  size='small'
                  icon={<Add size='small' />}
                  fill={true}
                />
              </Link>
            </HeaderActions>
          </PageTop>
          <Box margin={{ vertical: 'medium' }}>
            <TableView
              columns={columns}
              data={agents}
              isLoading={isLoading}
              emptyStateComponent={
                <EmptyState
                  background
                  buttonText='Create New Agent'
                  buttonLink='/agent/create'
                  showButton={true}
                  emptyStateHeadline='You have no Agents yet'
                  emptyStateDescription='If you sell through ticket agents, their profiles, commission rates and invoices can be managed from this page.'
                  emptyStateImageSrc={emptyAgents}
                  emptyStateImageAlt='No agent profiles'
                  emptyStateImageTitle='No agent profiles'
                />
              }
              fetchData={this.props.fetchAgentsPage}
              onRowClick={this.handleAgentClick}
              totalPages={totalPages}
              currentPage={currentPage}
              onPageClick={this.handleSelectPage}
            />
          </Box>
        </MainContent>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const page = parseInt(match.params.page, 10) || 1;
  return {
    agents: agentSelectors.getAgentsForPage(state, page),
    currentPage: page,
    totalPages: agentSelectors.getAgentsTotalPages(state),
    isLoading: agentSelectors.getAgentsLoadingStatus(state)
  };
};

export default connect(mapStateToProps, {
  ...agentActions
})(AgentList);
