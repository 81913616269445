import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { accountActions } from 'modules/account/account';

import styled from 'styled-components';

// Grommet
import { ResponsiveContext } from 'grommet';

// Components
import AppContent from 'components/MainContent/AppContent/AppContent';
import AppSideBar from 'components/MainContent/AppSideBar/AppSideBar';
import TopBar from 'components/MainContent/TopBar/TopBar';

const LayoutWrapperWeb = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const LayoutWrapperNarrow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DefaultLayout = ({ logoutUser, pathName, user, children }) => {
  const handleLogout = () => {
    logoutUser();
  };

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          {size !== 'small' ? (
            <LayoutWrapperWeb>
              <AppSideBar />
              <AppContent>
                <TopBar user={user} onLogout={handleLogout} />
                {children}
              </AppContent>
            </LayoutWrapperWeb>
          ) : (
            <LayoutWrapperNarrow>
              <AppSideBar />
              <AppContent>{children}</AppContent>
            </LayoutWrapperNarrow>
          )}
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.account.user.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        ...accountActions
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
