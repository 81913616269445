import React from 'react';
import { connectStateResults } from 'react-instantsearch-dom';
import Spinner from 'react-spinkit';
const SearchWithEmptyState = connectStateResults(
  ({
    Component,
    EmptyComponent,
    showSpinner = true,
    searchState: { query } = {},
    searchResults,
    searching,
    isSearchStalled,
    ...searchProps
  }) => {
    if (isSearchStalled && searching) {
      return showSpinner ? (
        <Spinner
          name='three-bounce'
          color='rgba(57,155,162,1)'
          fade='none'
          className='loading-spinner'
        />
      ) : null;
    }
    const nbHits = searchResults ? searchResults.nbHits : 0;
    const empty = searchResults ? !query && nbHits === 0 : false;
    const hits = searchResults ? searchResults.hits : [];
    return <div>{empty ? <EmptyComponent /> : <Component hits={hits} />}</div>;
  }
);

export default SearchWithEmptyState;
