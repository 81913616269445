import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import moment from 'moment';
/* eslint-disable react/style-prop-object */
import { FormattedDate, FormattedNumber } from 'react-intl';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';

import getCountryName from '../../utils/countries';
import MainContent, {
  ContentContainer,
  Title,
  SubTitle,
  HeaderActions,
  ContentType,
  PageTop,
  LineUpHelmet
} from '../../components/MainContent/MainContent';
import { InvoiceTable } from '../../components/Invoice/Invoice';

import InfoBlock, {
  InfoBlockList,
  InfoBlockListItem
} from '../../components/InfoBlock/InfoBlock';
import { agentActions, agentSelectors } from '../../modules/agents/agents';
import Pagination from '../../components/Pagination/Pagination';
import { showModal } from '../../modules/modal/modal';

// Grommet
import { Box, Button, Text } from 'grommet';
import { Add, Edit, Directions } from 'grommet-icons';

class AgentDetail extends Component {
  componentDidMount() {
    this.props.fetchAgent({ agentId: this.props.agentId });
    this.props.fetchInvoices({
      agentId: this.props.agentId,
      page: this.props.currentPage
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.agentId !== prevProps.agentId) {
      this.props.fetchAgent({ agentId: this.props.agentId });
    }
  }
  handleDownloadInvoice = (invoiceId, reference) => {
    this.props.fetchInvoicePdf({
      agentId: this.props.agentId,
      invoiceId: invoiceId,
      filename: `invoice-${reference}.pdf`
    });
  };
  handleDeleteInvoice = invoiceId => {
    this.props.deleteInvoice({
      agentId: this.props.agentId,
      invoiceId: invoiceId,
      page: this.props.currentPage
    });
  };
  handleSelectInvoicesPage = page => {
    this.props.fetchInvoices({ agentId: this.props.agentId, page: page });
  };
  handleInvoiceCreateSubmit = values => {
    const fromPeriod = moment
      .utc(values.fromPeriod)
      .startOf('day')
      .toISOString();
    const toPeriod = moment
      .utc(values.toPeriod)
      .endOf('day')
      .toISOString();
    return this.props.onInvoiceFormSubmit({
      ...values,
      fromPeriod,
      toPeriod,
      agentId: this.props.agentId
    });
  };
  handleCreateInvoiceClick = ev => {
    ev.preventDefault();
    this.props.showModal('CREATE_INVOICE', {
      onInvoiceCreateSubmit: this.handleInvoiceCreateSubmit
    });
  };

  render() {
    const { agent } = this.props;
    if (!agent || !agent.firstName) {
      return null;
    }
    const { commissionRate } = agent;
    const {
      invoicesLoading,
      invoices,
      currentPage,
      invoicesTotalPages
    } = this.props;
    return (
      <>
        <LineUpHelmet
          title={agent.company}
          description='Manage agent profile in Line-Up'
        />
        <MainContent>
          <ContentType icon={<Directions size='small' />} label='Agent' />
          <Box margin={{ top: 'small' }}>
            <PageTop>
              <Title>{agent.company}</Title>
              <HeaderActions>
                <Link to={`/agent/edit/${agent.id}`}>
                  <Button
                    primary
                    label='Edit Agent'
                    size='small'
                    icon={<Edit size='small' />}
                    fill={true}
                  />
                </Link>
              </HeaderActions>
            </PageTop>
          </Box>
          <div>
            <Box margin={{ vertical: 'medium' }}>
              <ContentContainer>
                <SubTitle title={'Agent Details'} />
                <InfoBlock>
                  <InfoBlockList>
                    <InfoBlockListItem
                      attribute='Company'
                      value={agent.company}
                    />
                    <InfoBlockListItem
                      attribute='Description'
                      value={agent.description}
                    />
                    {agent.vatNumber && (
                      <InfoBlockListItem
                        attribute='VAT Number'
                        value={agent.vatNumber}
                      />
                    )}
                    <InfoBlockListItem
                      attribute='Created'
                      value={
                        <FormattedDate
                          value={agent.createdAt}
                          weekday='short'
                          year='numeric'
                          
                          day='numeric'
                          month='short'
                          hour='numeric'
                          minute='numeric'
                        />
                      }
                    />
                  </InfoBlockList>
                </InfoBlock>
                {agent.apiClient && agent.apiClient.discounts && (
                  <Fragment>
                    <SubTitle title='Discounts' />
                    <InfoBlock>
                      <InfoBlockList>
                        <InfoBlockListItem
                          attribute='Discounts Available'
                          value={agent.apiClient.discounts
                            .map(discount => discount.code)
                            .toString()}
                        />
                      </InfoBlockList>
                    </InfoBlock>
                  </Fragment>
                )}
                {agent.commissionRate && (
                  <Fragment>
                    <SubTitle title={'Commission Rate'} />
                    <InfoBlock>
                      <InfoBlockList>
                        {commissionRate.insideFeePercentage > 0 && (
                          <InfoBlockListItem
                            attribute='Inside Fee Percentage'
                            value={
                              <FormattedNumber
                                value={commissionRate.insideFeePercentage}
                                style='percent'
                              />
                            }
                          />
                        )}
                        {commissionRate.insideFeeFixedValue > 0 && (
                          <InfoBlockListItem
                            attribute='Inside Fee Fixed Value'
                            value={
                              <FormattedNumber
                                value={commissionRate.insideFeeFixedValue}
                                style='currency'
                                currency='GBP'
                              />
                            }
                          />
                        )}
                        {commissionRate.insideFeeTaxRate > 0 && (
                          <InfoBlockListItem
                            attribute='Inside Fee Tax Rate'
                            value={
                              <FormattedNumber
                                value={commissionRate.insideFeeTaxRate}
                                style='percent'
                              />
                            }
                          />
                        )}
                        <InfoBlockListItem
                          attribute='Include Booking Fee'
                          value={
                            commissionRate.includeOutsideFee ? 'True' : 'False'
                          }
                        />
                      </InfoBlockList>
                    </InfoBlock>
                  </Fragment>
                )}
                <SubTitle title={'Contact Details'} />
                <InfoBlock>
                  <InfoBlockList>
                    <InfoBlockListItem
                      attribute='First Name'
                      value={agent.firstName}
                    />
                    <InfoBlockListItem
                      attribute='Last Name'
                      value={agent.lastName}
                    />
                    <InfoBlockListItem
                      attribute='Email Address'
                      value={agent.email}
                    />
                    {agent.telephone && (
                      <InfoBlockListItem
                        attribute='Phone'
                        value={agent.telephone}
                      />
                    )}
                  </InfoBlockList>
                </InfoBlock>
                {agent.address && (
                  <span>
                    <SubTitle title='Invoicing Address' />
                    <InfoBlock>
                      <InfoBlockList>
                        <InfoBlockListItem
                          attribute='Street'
                          value={agent.address.street}
                        />
                        <InfoBlockListItem
                          attribute='City'
                          value={agent.address.region}
                        />
                        {agent.county && (
                          <InfoBlockListItem
                            attribute='State'
                            value={agent.address.county}
                          />
                        )}
                        <InfoBlockListItem
                          attribute={
                            agent.address.country === 'US' ? 'ZIP' : 'Post Code'
                          }
                          value={agent.address.postcode}
                        />
                        <InfoBlockListItem
                          attribute='Country'
                          value={getCountryName(agent.address.country)}
                        />
                      </InfoBlockList>
                    </InfoBlock>
                  </span>
                )}
                <SubTitle title='API Details' />
                <InfoBlock>
                  <InfoBlockList>
                    <InfoBlockListItem
                      attribute='Public Key'
                      value={agent.apiClient.publicKey}
                      longvalue
                    />
                    <InfoBlockListItem
                      attribute='Secret Key'
                      value={agent.apiClient.secretKey}
                      longvalue
                    />
                    <InfoBlockListItem
                      attribute='URL'
                      value='https://api.lineupnow.com'
                    />
                  </InfoBlockList>
                </InfoBlock>
                <SubTitle title='Invoices'>
                  <Button
                    secondary
                    size='small'
                    label='Create Invoice'
                    icon={<Add size='small' />}
                    onClick={this.handleCreateInvoiceClick}
                  />
                </SubTitle>
                <Box margin={{ vertical: 'medium' }}>
                  {!invoicesLoading && invoices.length > 0 ? (
                    <InvoiceTable
                      invoices={invoices}
                      onBtnClick={this.handleDownloadInvoice}
                      onDeleteClick={this.handleDeleteInvoice}
                    />
                  ) : (
                    <Box
                      background='infobox'
                      pad='large'
                      justify='center'
                      align='center'
                    >
                      <Text>There are no invoices for this agent</Text>
                    </Box>
                  )}
                  <Pagination
                    totalPages={invoicesTotalPages}
                    currentPage={currentPage}
                    onSelectPage={this.handleSelectInvoicesPage}
                  />
                </Box>
              </ContentContainer>
            </Box>
          </div>
        </MainContent>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const agentId = parseInt(ownProps.match.params.agentId, 10);
  const agent = agentSelectors.getAgent(state, agentId);
  const currentPage = agentSelectors.getInvoicesCurrentPage(state, agentId);
  return {
    agentId,
    agent,
    invoicesLoading: agentSelectors.getInvoicesLoading(
      state,
      agentId,
      currentPage
    ),
    invoices: agentSelectors.getInvoicesForPage(state, agentId, currentPage),
    invoicesTotalPages: agentSelectors.getInvoicesTotalPages(state, agentId),
    currentPage: currentPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        onInvoiceFormSubmit: agentActions.sumbmitCreateInvoiceForm
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        ...agentActions,
        showModal
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentDetail);
