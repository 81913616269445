import { takeLatest } from 'redux-saga/effects';
import { fetchEntity, submitForm, deleteEntity } from '../shared/operations';

import * as actions from './actions';
import * as api from '../../services/api';

const fetchTicketAllocation = fetchEntity.bind(
  null,
  actions.fetchTicketAllocation,
  api.fetchTicketAllocation
);

export function* watchFetchTicketAlloaction() {
  yield takeLatest(
    actions.fetchTicketAllocation.TRIGGER,
    fetchTicketAllocation
  );
}

const fetchTicketAllocationPage = fetchEntity.bind(
  null,
  actions.fetchTicketAllocationPage,
  api.fetchTicketAllocationPage
);

export function* watchFetchTicketAlloactions() {
  yield takeLatest(
    actions.fetchTicketAllocationPage.TRIGGER,
    fetchTicketAllocationPage
  );
}

const createTicketAllocation = submitForm.bind(
  null,
  actions.createTicketAllocation,
  api.createTicketAllocation
);

export function* watchCreateTicketAllocation() {
  yield takeLatest(
    actions.createTicketAllocation.TRIGGER,
    createTicketAllocation
  );
}

const updateTicketAllocation = submitForm.bind(
  null,
  actions.updateTicketAllocation,
  api.updateTicketAllocation
);

export function* watchUpdateTicketAllocation() {
  yield takeLatest(
    actions.updateTicketAllocation.TRIGGER,
    updateTicketAllocation
  );
}

const issueTicket = submitForm.bind(null, actions.issueTicket, api.issueTicket);

export function* watchIssueTicket() {
  yield takeLatest(actions.issueTicket.TRIGGER, issueTicket);
}

const deleteTicketAllocation = deleteEntity.bind(
  null,
  actions.deleteTicketAllocation,
  api.deleteTicketAllocation
);

export function* watchDeleteTicketAllocation() {
  yield takeLatest(
    actions.deleteTicketAllocation.TRIGGER,
    deleteTicketAllocation
  );
}

// function* issueTicket(action) {
//   yield put(startSubmit('issueTicket'));
//   yield put(showLoading());
//   const { purchaseData, allocationId, agentId } = action.payload;
//   yield put(actions.issueTicket.request(allocationId, purchaseData, agentId));
//   const { response, error } = yield call(
//     api.issueTicket,
//     allocationId,
//     agentId,
//     purchaseData
//   );
//   if (response) {
//     yield put(actions.issueTicket.success(allocationId, response));
//     yield put(stopSubmit('issueTicket'));
//     window.location = `/order/${response.result}`;
//   } else {
//     yield put(actions.issueTicket.failure(allocationId, error));
//     yield put(stopSubmit('issueTicket', { error }));
//   }
//   yield put(hideLoading());
// }

// export function* watchIssueTicket() {
//   yield takeLatest(types.ISSUE_TICKET_SUBMIT, issueTicket);
// }
