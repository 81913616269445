import { createRequestTypes } from '../shared/types';

export const TICKET_GROUP_CREATE = 'line-up/ticketGroup/CREATE';
export const CREATE = createRequestTypes('ticketGroup', 'CREATE');

export const LOAD_ALL = 'line-up/ticketGroup/LOAD_ALL';
export const ALL_TICKET_GROUPS = createRequestTypes(
  'ticketGroup',
  'ALL_TICKET_GROUPS'
);
export const TICKET_GROUPS = createRequestTypes('ticketGroup', 'TICKET_GROUPS');

export const LOAD = 'line-up/ticketGroup/LOAD';
export const TICKET_GROUP = createRequestTypes('ticketGroup', 'TICKET_GROUP');

export const ADD_TICKET = 'line-up/ticketGroup/ADD_TICKET';
export const TICKET_ADD = createRequestTypes('ticketGroup', 'TICKET_ADD');

export const REMOVE_TICKET = 'line-up/ticketGroup/REMOVE_TICKET';
export const TICKET_REMOVE = createRequestTypes('ticketGroup', 'TICKET_REMOVE');

export const LOAD_ALL_TICKETS = 'line-up/ticketGroup/LOAD_ALL_TICKETS';
export const TICKET_GROUP_TICKETS = createRequestTypes(
  'ticketGroup',
  'TICKET_GROUP_TICKETS'
);
export const ALL_TICKET_GROUP_TICKETS = createRequestTypes(
  'ticketGroup',
  'ALL_TICKET_GROUP_TICKETS'
);

export const SELECT_TICKET_GROUP_TICKETS_PAGE =
  'line-up/ticketGroup/SELECT_TICKET_GROUP_TICKETS_PAGE';
