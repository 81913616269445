import { call, takeLatest, put } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import * as types from './types';
import * as actions from './actions';
import * as api from '../../services/api';

function* fetchWaitingList(action) {
  yield put(showLoading());
  const { performanceId, page } = action.payload;
  yield put(actions.waitingList.request(performanceId, page));
  const { response, error } = yield call(
    api.getWaitingList,
    performanceId,
    page
  );
  if (response) {
    yield put(actions.waitingList.success(performanceId, page, response));
  } else {
    yield put(actions.waitingList.failure(performanceId, page, error));
  }
  yield put(hideLoading());
}

export function* watchLoadWaitingList() {
  yield takeLatest(types.LOAD_PAGE, fetchWaitingList);
}
