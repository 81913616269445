import React from 'react';
import {
  InstantSearch,
  SearchBox,
  Configure,
  connectHits
} from 'react-instantsearch-dom';
import Table, { TableCell, TableBody, TableHeader } from '../Table/Table';
import TicketRowItem from './TicketRowItem';
import { AIPagination as Pagination } from '../../components/AISearch/AISearch';

// Grommet
import { Box } from 'grommet';

const TicketHits = connectHits(({ hits, onBtnClick, includedIds }) => (
  <TableBody
    renderItem={item => {
      const inGroup = includedIds.some(
        id => id === parseInt(item.objectID, 10)
      );
      return (
        <TicketRowItem
          ticket={item}
          key={item.objectID}
          btnType={inGroup ? 'danger' : 'primary'}
          btnText={inGroup ? 'Remove' : 'Add'}
          onBtnClick={onBtnClick}
        />
      );
    }}
    items={hits}
  />
));

const TicketList = ({ ticketIds, onBtnClick }) => {
  return (
    <Box margin={{ vertical: 'small' }}>
      <InstantSearch
        appId={process.env.REACT_APP_ALGOLIA_APP_ID}
        apiKey={window.ticket_api_key}
        indexName={process.env.REACT_APP_ALGOLIA_TICKET_INDEX}
      >
        <SearchBox />
        <Box margin={{ vertical: 'medium' }}>
          <Configure hitsPerPage={25} />
          <Table>
            <TableHeader>
              <TableCell width={30}>Event</TableCell>
              <TableCell width={25}>Ticket</TableCell>
              <TableCell width={20}>Event Start Date</TableCell>
              <TableCell width={10}>Price</TableCell>
              <TableCell width={10}>Fee</TableCell>
              <TableCell width={5} />
            </TableHeader>
            <TicketHits onBtnClick={onBtnClick} includedIds={ticketIds} />
          </Table>
          <Pagination />
        </Box>
      </InstantSearch>
    </Box>
  );
};

export default TicketList;
