import React from 'react';

// Grommet
import { Box, Heading, ResponsiveContext } from 'grommet';

const SubTitle = ({ title, children, linkColor }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          direction={size !== 'small' ? 'row' : 'column'}
          justify={size !== 'small' ? 'between' : 'start'}
          align={size !== 'small' ? 'center' : 'start'}
          fill
          gap={size !== 'small' ? 'none' : 'medium'}
        >
          <Heading
            level='3'
            size='xsmall'
            margin='none'
            color={linkColor ? 'brand' : 'heading'}
          >
            {title}
          </Heading>
          <Box
            direction={size !== 'small' ? 'row' : 'column'}
            gap='small'
            fill={size !== 'small' ? false : true}
          >
            {children}
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default SubTitle;
