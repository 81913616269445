import * as types from './types';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const fetchEmail = createRoutine(types.FETCH_EMAIL);
export const createEmail = createRoutine(types.CREATE_EMAIL);
export const updateEmail = createRoutine(types.UPDATE_EMAIL);
export const deleteEmail = createRoutine(types.DELETE_EMAIL);
export const sendEmailPreview = createRoutine(types.SEND_EMAIL_PREVIEW);

export const submitUpdateEmail = promisifyRoutine(updateEmail);
export const submitCreateEmail = promisifyRoutine(createEmail);
export const submitDeleteEmail = promisifyRoutine(deleteEmail);
export const submitSendEmailPreview = promisifyRoutine(sendEmailPreview);
