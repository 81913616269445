import * as types from './types';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { createAction } from 'redux-actions';

export const fetchAllEvents = createRoutine(types.FETCH_ALL_EVENTS);
export const fetchEvents = createRoutine(types.FETCH_EVENTS);
export const fetchEvent = createRoutine(types.FETCH_EVENT);
export const fetchEventBySlug = createRoutine(types.FETCH_EVENT_BY_SLUG);
export const searchEvents = createAction(types.SEARCH_EVENTS, searchTerm => ({
  searchTerm,
}));

export const createEvent = createRoutine(types.CREATE_EVENT);
export const sumbmitCreateEventForm = promisifyRoutine(createEvent);
export const updateEvent = createRoutine(types.UPDATE_EVENT);
export const sumbmitUpdateEventForm = promisifyRoutine(updateEvent);

export const getEventInvitations = createRoutine(types.GET_EVENT_INVITAIONS);
export const getEventPermissions = createRoutine(types.GET_EVENT_PERMISSIONS);

export const createEventInvitation = createRoutine(
  types.CREATE_EVENT_INVITATION
);
export const submitCreateEventInvitation = promisifyRoutine(
  createEventInvitation
);
export const createEventPermission = createRoutine(
  types.CREATE_EVENT_PERMISSION
);
export const submitCreateEventPermission = promisifyRoutine(
  createEventPermission
);
export const deleteEventInvitation = createRoutine(
  types.DELETE_EVENT_INVITATION
);
export const deleteEventPermission = createRoutine(
  types.DELETE_EVENT_PERMISSION
);
export const updateEventInvitation = createRoutine(
  types.UPDATE_EVENT_INVITATION
);

export const createBulkUpload = createRoutine(types.CREATE_BULK_UPLOAD);
export const setBulkUploadFileData = createAction(
  types.SET_BULK_UPLOAD_FILE_DATA
);
