import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Grommet
import {
  Box,
  Button,
  Image,
  Heading,
  Paragraph,
  ResponsiveContext
} from 'grommet';

const StyledLink = styled(Link)`
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    outline: 0;
  }
`;

const EmptyState = ({
  buttonText,
  onButtonClick,
  buttonLink,
  emptyStateHeadline,
  emptyStateDescription,
  emptyStateImageSrc,
  emptyStateImageAlt,
  emptyStateImageTitle,
  showButton
}) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          margin={{ vertical: 'medium' }}
          background='topbar'
          justify={size !== 'small' ? 'between' : 'center'}
          round='xsmall'
          direction={size !== 'small' ? 'row' : 'column'}
          wrap={false}
          pad='large'
          align={size !== 'small' ? 'start' : 'center'}
          gap='large'
          height={size !== 'small' ? 'none' : { min: '490px' }}
        >
          <Box
            direction='column'
            width={size !== 'small' ? { max: '500px' } : { max: '100%' }}
            margin={size !== 'small' ? { top: 'large' } : 'none'}
          >
            <Heading
              level={size !== 'small' ? '2' : '3'}
              size={size !== 'small' ? 'small' : 'xsmall'}
              margin={size !== 'small' ? 'none' : { top: 'none' }}
            >
              {emptyStateHeadline}
            </Heading>
            <Paragraph size={size !== 'small' ? 'medium' : 'small'}>
              {emptyStateDescription}
            </Paragraph>
            {showButton && (
              <Box width='medium' margin={{ top: 'medium' }}>
                <StyledLink to={buttonLink}>
                  <Button
                    primary
                    onClick={onButtonClick}
                    label={buttonText}
                    size={size !== 'small' ? 'medium' : 'small'}
                    fill
                    focusIndicator={false}
                  />
                </StyledLink>
              </Box>
            )}
          </Box>
          <Box
            width={
              size !== 'small'
                ? { min: '450px', max: '450px' }
                : { min: '80%', max: '80%' }
            }
          >
            <Image
              src={emptyStateImageSrc}
              alt={emptyStateImageAlt}
              title={emptyStateImageTitle}
              fit='contain'
              fill
            />
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default EmptyState;
