import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import Form, {
  FormField,
  FormDateRangePicker,
  FormSectionHeader
} from '../Form/Form';
import './ExportForm.css';
let ExportForm = props => {
  const { handleSubmit, onCancel, submitting, title, dateRange } = props;
  return (
    <Form
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      submitting={submitting}
      formTitle={title}
      className="export-form"
    >
      <Field
        name="date"
        labelAfter="Today"
        value="today"
        component={FormField}
        type="radio"
      />
      <Field
        name="date"
        labelAfter="Yesterday"
        value="yesterday"
        component={FormField}
        type="radio"
      />
      <Field
        name="date"
        value="sevenDays"
        labelAfter="Last Seven Days"
        component={FormField}
        type="radio"
      />
      <Field
        name="date"
        value="lastMonth"
        labelAfter="Previous Month"
        component={FormField}
        type="radio"
      />
      <Field
        name="date"
        value="all"
        labelAfter="All"
        component={FormField}
        type="radio"
      />
      <Field
        name="date"
        value="custom"
        labelAfter="Custom"
        component={FormField}
        type="radio"
      />
      {dateRange === 'custom' && (
        <Fragment>
          <FormSectionHeader title="Select Dates" />
          <Field name="dateRange" component={FormDateRangePicker} />
        </Fragment>
      )}
    </Form>
  );
};

ExportForm = reduxForm({
  form: 'export',
  initialValues: {
    date: 'today'
  }
})(ExportForm);

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector('export');
  const dateRange = selector(state, 'date');
  return {
    dateRange
  };
};

export default connect(mapStateToProps)(ExportForm);
