import * as types from "./types";
import { createRoutine, promisifyRoutine } from "redux-saga-routines";
import { createAction } from "redux-actions";

export const fetchOrder = createRoutine(types.FETCH_ORDER);

export const selectReceipt = createAction(
  types.SELECT_RECEIPT,
  (key, receiptId) => ({ key, receiptId })
);
export const unselectReceipt = createAction(
  types.UNSELECT_RECEIPT,
  (key, receiptId) => ({ key, receiptId })
);

export const fetchOrderNotes = createRoutine(types.FETCH_ORDER_NOTES);
export const fetchOrderFormResponses = createRoutine(
  types.FETCH_ORDER_FORM_RESPONSES
);

export const createNoteOnOrder = createRoutine(types.CREATE_NOTE);
export const sumbmitCreateNoteForm = promisifyRoutine(createNoteOnOrder);

export const updateNoteOnOrder = createRoutine(types.UPDATE_NOTE);
export const sumbmitUpdateNoteForm = promisifyRoutine(updateNoteOnOrder);

export const deleteNoteOnOrder = createRoutine(types.DELETE_NOTE);
export const deleteRefund = createRoutine(types.DELETE_REFUND);

export const sendOrderConfirmation = createRoutine(types.SEND_CONFIRMATION);

export const createRefund = createRoutine(types.CREATE_REFUND);

export const editReceipt = createRoutine(types.EDIT_RECEIPT);
export const editReceipts = createRoutine(types.EDIT_RECEIPTS);
export const sumbmitEditReceiptsForm = promisifyRoutine(editReceipts);

export const exportOrders = createRoutine(types.EXPORT_ORDERS);

export const exportReceipts = createRoutine(types.EXPORT_RECEIPTS);

export const exportProductReceipts = createRoutine(
  types.EXPORT_PRODUCT_RECEIPTS
);

export const exportRefunds = createRoutine(types.EXPORT_REFUNDS);

export const exportTicketPDF = createRoutine(types.EXPORT_TICKET_PDF);
export const receiptRedeem = createAction(types.RECEIPT_REDEEM);
