import React from 'react';
import { connect } from 'react-redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { LineUpHelmet } from 'components/MainContent/MainContent';

import ResetPasswordForm from 'components/ResetPasswordForm/ResetPasswordForm';

import { accountActions } from 'modules/account/account';
import { toast } from 'react-toastify';

import styles from './UserLogin.module.css';

import logoDark from 'assets/logo.png';
import signup from './signup.png';

// Grommet
import { Box, Footer, Text, Image, ResponsiveContext } from 'grommet';

const UserResetPassword = ({ submitResetPassword, token, whiteLabel, dispatch }) => {
  const handleSubmit = (values, { setFormError }) => {
    return submitResetPassword({ ...values, token: token })
      .then(r => {
        toast('Your password has been reset');
        !whiteLabel && dispatch(push('/user/login'));
      })
      .catch(e => {
        setFormError(e.errors._error);
      });
  };

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <LineUpHelmet
            title='Reset Password'
            description='Reset your Line-Up password'
          />
          <Box direction='column'>
            <Box
              align='center'
              margin={
                size !== 'small' ? { bottom: 'large' } : { bottom: 'none' }
              }
              width={size !== 'small' ? '100%' : '100%'}
              height={size !== 'small' ? { min: 'auto' } : { min: '100vh' }}
            >
              <Box
                width='100%'
                margin={{
                  horizontal: 'auto',
                  bottom: 'large',
                  top: 'large'
                }}
                align='center'
              >
                <Link to={'/'}>
                  <Box width='160px' height='42px'>
                    <Image src={logoDark} fit='contain' />
                  </Box>
                </Link>
              </Box>

              <Box
                margin={
                  size !== 'small'
                    ? { top: 'small', bottom: 'large' }
                    : { top: 'large', bottom: 'large' }
                }
                align='center'
                width={size !== 'small' ? 'auto' : '85%'}
              >
                <Box width={size !== 'small' ? '450px' : '100%'} align='center'>
                  <ResetPasswordForm onSubmit={handleSubmit} />
                </Box>
              </Box>

              <Footer align='center' margin={{ top: 'auto' }}>
                <Text size='small' color='copy-light'>
                  © Line-Up 2021
                </Text>
              </Footer>
              {size !== 'small' ? (
                <div className={styles.loginImageWrapper}>
                  <img
                    src={signup}
                    className={styles.loginImage}
                    alt='Login to Line-Up'
                  />
                </div>
              ) : (
                <Box justify='end' width='100%' margin={{ top: 'xlarge' }}>
                  <Box
                    width={size !== 'small' ? '60%' : '80%'}
                    margin={{ horizontal: 'auto' }}
                  >
                    <img
                      src={signup}
                      style={{ width: '100%' }}
                      alt='Sign up to Line-Up'
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { location,match } = ownProps;
  const token = match.params.token;
  const query = new URLSearchParams(location.search);
  const whiteLabel = query.get("white_label") === "true";
  return { token,whiteLabel };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        submitResetPassword: accountActions.submitResetPassword
      },
      dispatch
    ),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserResetPassword);
