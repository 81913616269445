import { takeLatest } from 'redux-saga/effects';
import * as api from '../../services/api';
import * as actions from './actions';
import { fetchEntity } from '../shared/operations';

const fetchPaymentReport = fetchEntity.bind(
  null,
  actions.fetchPaymentReport,
  api.fetchPaymentReport
);

export function* watchFetchPaymentReport() {
  yield takeLatest(actions.fetchPaymentReport.TRIGGER, fetchPaymentReport);
}

const fetchTicketData = fetchEntity.bind(
  null,
  actions.fetchTicketData,
  api.fetchTicketData
);

export function* watchFetchTicketData() {
  yield takeLatest(actions.fetchTicketData.TRIGGER, fetchTicketData);
}

const fetchEvents = fetchEntity.bind(
  null,
  actions.fetchEvents,
  api.fetchReportEvents
);

export function* watchFetchEvents() {
  yield takeLatest(actions.fetchEvents.TRIGGER, fetchEvents);
}

const exportSalesReportPDF = fetchEntity.bind(
  null,
  actions.exportSalesReportPDF,
  api.exportSalesReportPDF
);

export function* watchExportSalesReportPDF() {
  yield takeLatest(actions.exportSalesReportPDF.TRIGGER, exportSalesReportPDF);
}
