import React from 'react';
import './Table.css';
import classNames from 'classnames';

export { default as TableCell } from './TableCell/TableCell';
export { default as TableRow } from './TableRow/TableRow';
export { default as TableHeader } from './TableHeader/TableHeader';
export { default as TableBody } from './TableBody/TableBody';

const Table = ({
  children,
  className,
  borders,
  width,
  tableMargin,
  tableTitle,
  containerBorder,
  mobileWide
}) => {
  const tableContainerStyle = {
    width: `${width}%`,
    margin: `${tableMargin}`
  };
  const tableClass = classNames(className, 'lutable', {
    'lutable--borders': borders,
    'lutable--mobile-wide': mobileWide
  });
  const tableContainerClass = classNames(className, 'lutable-container', {
    'lutable-container--container-border': containerBorder,
    'lutable-container--mobile-wide': mobileWide
  });
  return (
    <div style={tableContainerStyle} className={tableContainerClass}>
      {tableTitle && <div className="lutable-title">{tableTitle}</div>}
      <div className={tableClass}>{children}</div>
    </div>
  );
};

export default Table;
