import React, { useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Paragraph,
  Text,
  ResponsiveContext,
  Image,
  Anchor
} from 'grommet';

import BulkUpload from './empty_performances.png';

import { Book, Download } from 'grommet-icons';
import withEmptyState from '../withEmptyState';

// Assets
import Template from 'assets/Line-Up_bulk_upload_template.csv';
import TemplateChart from 'assets/Line-Up_bulk_upload_template_seat_chart.csv';

let BulkUploadForm = ({ onBulkUploadClick, error }) => {
  const [file, setFile] = useState(null);
  const handleOnChange = e => {
    e.persist();
    setFile(e.target.files[0]);
  };
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          margin={{ vertical: 'medium' }}
          background='topbar'
          justify={size !== 'small' ? 'between' : 'center'}
          round='xsmall'
          direction={size !== 'small' ? 'row' : 'column'}
          wrap={false}
          pad='large'
          align={size !== 'small' ? 'start' : 'center'}
          gap='large'
          height={size !== 'small' ? 'none' : { min: '490px' }}
        >
          <Box
            direction='column'
            width={size !== 'small' ? { max: '500px' } : { max: '100%' }}
          >
            <Heading
              level={size !== 'small' ? '2' : '3'}
              size={size !== 'small' ? 'small' : 'xsmall'}
              margin={size !== 'small' ? 'none' : { top: 'none' }}
            >
              Upload your schedule to automatically add performances and tickets
            </Heading>
            <Paragraph size={size !== 'small' ? 'medium' : 'small'}>
              Lots of dates and times? Use our bulk upload function to upload
              your schedule and Line-Up will create your performances and
              tickets automatically
            </Paragraph>
            <Paragraph size={size !== 'small' ? 'medium' : 'small'}>
              <b>Important Tips:</b>
            </Paragraph>

            <Anchor
              size={size !== 'small' ? 'medium' : 'small'}
              margin={{ bottom: 'small' }}
              label='Read our Step by Step guide to bulk upload'
              href='https://support.lineupnow.com/hc/en-us/articles/360035613293-Creating-multiple-performances-tickets-using-spreadsheet-bulk-upload-no-seating-plan-'
              target='_blank'
              rel='noopener noreferrer'
              color='heading'
              icon={<Book size='small' />}
            />

            <Anchor
              size={size !== 'small' ? 'medium' : 'small'}
              margin={{ bottom: 'small' }}
              label='Download our template schedule'
              href={Template}
              icon={<Download size='small' />}
              color='heading'
            />

            <Anchor
              size={size !== 'small' ? 'medium' : 'small'}
              margin={{ bottom: 'small' }}
              label='Download our template schedule for events with a seat chart'
              href={TemplateChart}
              icon={<Download size='small' />}
              color='heading'
            />

            <Box gap='medium' margin={{ top: 'medium' }}>
              <input type='file' accept='.csv' onChange={handleOnChange} />
              <Box align='center' gap='small'>
                <Button
                  primary
                  onClick={() => file && onBulkUploadClick(file)}
                  label='Upload File'
                  size={size !== 'small' ? 'medium' : 'small'}
                  fill
                />
                <Text size='small' color='copy-light'>
                  Only CSV files can be uploaded.{' '}
                  <Anchor
                    href='https://support.lineupnow.com/hc/en-us/articles/360035613293-Creating-multiple-performances-tickets-using-spreadsheet-bulk-upload-no-seating-plan-'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Read More
                  </Anchor>
                </Text>
                {error && (
                  <Text
                    size='small'
                    color='red'
                    weight={900}
                    textAlign='center'
                  >
                    {error}
                  </Text>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            width={
              size !== 'small'
                ? { min: '450px', max: '450px' }
                : { min: '80%', max: '80%' }
            }
          >
            <Image
              src={BulkUpload}
              alt='New Bulk Upload'
              title='New Bulk Upload'
              fit='contain'
              fill
            />
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default withEmptyState(BulkUploadForm);
