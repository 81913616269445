import React, { Fragment } from 'react';
import { startAsyncValidation, stopAsyncValidation } from 'redux-form';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'react-spinkit';
import { toast } from "react-toastify";
import './FormImageField.css';
const ImageUploadField = props => {
  const {
    hideLabel,
    name,
    label,
    placeholder,
    className,
    id,
    uploadImage,
    deleteImage,
    hintBefore,
    input: { value, onChange },
    meta: { error, touched, warning, form, dispatch, asyncValidating }
  } = props;
  const rowClass = classNames(className, 'form__row');
  const labelClass = classNames('form__label');

  const acceptedFileTypes =
    'image/x-png, image/png, image/jpg, image/jpeg, image/gif';

  const handleOnDrop = (files, rejectedFiles) => {
    if (files && files.length > 0) {
      const file = files[0];
      uploadImage('image', file).then(({ response, error }) => {
        if (response) {
          onChange(response);
          dispatch(stopAsyncValidation(form, 'image'));
        }
        if (error) {
          dispatch(stopAsyncValidation(form, { image: error.message }));
          toast.error(error.errors._error)
        }
      });
    }
  };

  const handleImageDelete = () => {
    window.URL.revokeObjectURL(value.preview);
    dispatch(startAsyncValidation({ form }));
    deleteImage(value.id).then(({ response, error }) => {
      if (response) {
        onChange(null);
        dispatch(stopAsyncValidation(form, 'image'));
      }
      if (error) {
        dispatch(stopAsyncValidation(form, { image: error.message }));
      }
    });
  };
  return (
    <div className={rowClass}>
      {!hideLabel && (
        <label className={labelClass} htmlFor={id}>
          {label}
        </label>
      )}
      {hintBefore && (
        <div className="form__hint form__hint--before">{hintBefore}</div>
      )}
      {value ? (
        <div className="form-field__dropzone">
          {asyncValidating ? (
            <Spinner
              name="pulse"
              color="#333"
              fade="none"
              className="form-field__spinner"
            />
          ) : (
            <Fragment>
              <img
                alt="preview"
                className="form-field__preview"
                src={value.url}
              />
              <FontAwesomeIcon
                icon="trash"
                className="form-field__dropzone-delete-icon"
                size="lg"
                onClick={handleImageDelete}
              />
            </Fragment>
          )}
        </div>
      ) : (
        <Fragment>
          <Dropzone
            multiple={false}
            name={name}
            accept={acceptedFileTypes}
            onDrop={handleOnDrop}
          >
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <div {...getRootProps()} className="form-field__dropzone">
                  {asyncValidating ? (
                    <Spinner
                      name="pulse"
                      color="#333"
                      fade="none"
                      className="form-field__spinner"
                    />
                  ) : (
                    <Fragment>
                      <span className="form-field__placeholder">
                        {placeholder}
                      </span>
                      <input {...getInputProps()} />
                    </Fragment>
                  )}
                </div>
              );
            }}
          </Dropzone>
        </Fragment>
      )}
      {touched &&
        ((error && <div className="form__error">{error}</div>) ||
          (warning && <div className="form__error">{warning}</div>))}
    </div>
  );
};
export default ImageUploadField;
