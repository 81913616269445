import { createAction } from 'redux-actions';
import * as types from './types';

//- Actions
export const createTicketGroup = createAction(
  types.TICKET_GROUP_CREATE,
  ticketGroup => ({ ticketGroup })
);

export const ticketGroupCreate = {
  request: createAction(types.CREATE.REQUEST),
  success: createAction(types.CREATE.SUCCESS, response => ({
    response
  })),
  failure: createAction(types.CREATE.FAILURE, error => ({
    error
  }))
};

export const loadTicketGroups = createAction(types.LOAD_ALL);

export const ticketGroups = {
  request: createAction(types.TICKET_GROUPS.REQUEST, page => ({ page })),
  success: createAction(types.TICKET_GROUPS.SUCCESS, (page, response) => ({
    page,
    response
  })),
  failure: createAction(types.TICKET_GROUPS.FAILURE, (page, error) => ({
    page,
    error
  }))
};

export const allTicketGroups = {
  request: createAction(types.ALL_TICKET_GROUPS.REQUEST),
  success: createAction(types.ALL_TICKET_GROUPS.SUCCESS, response => ({
    response
  })),
  failure: createAction(types.ALL_TICKET_GROUPS.FAILURE, error => ({
    error
  }))
};

export const loadTicketGroup = createAction(types.LOAD, ticketGroupId => ({
  ticketGroupId
}));

export const ticketGroup = {
  request: createAction(types.TICKET_GROUP.REQUEST, ticketGroupId => ({
    ticketGroupId
  })),
  success: createAction(
    types.TICKET_GROUP.SUCCESS,
    (ticketGroupId, response) => ({
      ticketGroupId,
      response
    })
  ),
  failure: createAction(types.TICKET_GROUP.FAILURE, (ticketGroupId, error) => ({
    ticketGroupId,
    error
  }))
};

export const addTicket = createAction(
  types.ADD_TICKET,
  (ticketGroupId, ticketId) => ({
    ticketGroupId,
    ticketId
  })
);

export const ticketAdd = {
  request: createAction(
    types.TICKET_ADD.REQUEST,
    (ticketGroupId, ticketId) => ({
      ticketGroupId,
      ticketId
    })
  ),
  success: createAction(
    types.TICKET_ADD.SUCCESS,
    (ticketGroupId, ticketId) => ({
      ticketGroupId,
      ticketId
    })
  ),
  failure: createAction(
    types.TICKET_ADD.FAILURE,
    (ticketGroupId, ticketId, error) => ({
      ticketGroupId,
      ticketId,
      error
    })
  )
};

export const removeTicket = createAction(
  types.REMOVE_TICKET,
  (ticketGroupId, ticketId) => ({
    ticketGroupId,
    ticketId
  })
);

export const ticketRemove = {
  request: createAction(
    types.TICKET_REMOVE.REQUEST,
    (ticketGroupId, ticketId) => ({
      ticketGroupId,
      ticketId
    })
  ),
  success: createAction(
    types.TICKET_REMOVE.SUCCESS,
    (ticketGroupId, ticketId) => ({
      ticketGroupId,
      ticketId
    })
  ),
  failure: createAction(
    types.TICKET_REMOVE.FAILURE,
    (ticketGroupId, ticketId, error) => ({
      ticketGroupId,
      ticketId,
      error
    })
  )
};

export const loadTicketGroupTickets = createAction(
  types.LOAD_ALL_TICKETS,
  ticketGroupId => ({
    ticketGroupId
  })
);

export const ticketGroupTickets = {
  request: createAction(
    types.TICKET_GROUP_TICKETS.REQUEST,
    (ticketGroupId, page) => ({
      ticketGroupId,
      page
    })
  ),
  success: createAction(
    types.TICKET_GROUP_TICKETS.SUCCESS,
    (ticketGroupId, page, response) => ({
      ticketGroupId,
      page,
      response
    })
  ),
  failure: createAction(
    types.TICKET_GROUP_TICKETS.FAILURE,
    (ticketGroupId, page, error) => ({
      ticketGroupId,
      page,
      error
    })
  )
};

export const allTicketGroupTickets = {
  request: createAction(
    types.ALL_TICKET_GROUP_TICKETS.REQUEST,
    ticketGroupId => ({
      ticketGroupId
    })
  ),
  success: createAction(
    types.ALL_TICKET_GROUP_TICKETS.SUCCESS,
    (ticketGroupId, response) => ({
      ticketGroupId,
      response
    })
  ),
  failure: createAction(
    types.ALL_TICKET_GROUP_TICKETS.FAILURE,
    (ticketGroupId, error) => ({
      ticketGroupId,
      error
    })
  )
};

export const selectTicketGroupTicketsPage = createAction(
  types.SELECT_TICKET_GROUP_TICKETS_PAGE,
  (ticketGroupId, page) => ({ ticketGroupId, page })
);
