import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './TextButton.css';

const TextButton = ({
  href,
  type,
  children,
  onClick,
  disabled,
  className,
  icon
}) => {
  const btnClass = classNames('text-button', className);
  const Element = href ? 'a' : 'button';
  const role = Element === 'a' ? 'button' : null;
  return (
    <Element
      className={btnClass}
      href={href}
      onClick={onClick}
      disabled={disabled}
      type={type}
      role={role}
    >
      {icon && (
        <FontAwesomeIcon icon={icon} className="text-button__icon" fixedWidth />
      )}
      <span className="text-button__text">{children}</span>
    </Element>
  );
};

export default TextButton;
