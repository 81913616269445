import React from "react";

// Grommet
import { Box, Heading, ResponsiveContext } from "grommet";

const Title = ({ children, icon }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Heading
          level={size !== "small" ? "1" : "2"}
          size="small"
          margin={{ vertical: "none" }}
          color="heading"
        >
          <Box direction="row" align="center" alignContent="center" gap="small">
            {icon}
            {children}
          </Box>
        </Heading>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default Title;
