const relationshipById = ({
  fetchRoutine,
  createRoutine,
  deleteRoutine,
  mapActionToParentKey,
  mapActionToKey
}) => {
  if (typeof mapActionToKey !== 'function') {
    throw new Error('Expected mapActionToKey to be a function.');
  }
  if (typeof mapActionToParentKey !== 'function') {
    throw new Error('Expected mapActionToParentKey to be a function.');
  }

  const fetchRelationshipForParent = (
    state = {
      isLoading: false,
      ids: [],
      error: null
    },
    action
  ) => {
    switch (action.type) {
      case fetchRoutine.REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case fetchRoutine.SUCCESS:
        return {
          ...state,
          isLoading: false,
          ids: action.payload.response.result
        };
      case fetchRoutine.FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.error
        };
      default:
        return state;
    }
  };

  const editRelationshipForParent = (
    state = {
      isLoading: false,
      ids: [],
      error: null
    },
    action
  ) => {
    switch (action.type) {
      case createRoutine.SUCCESS:
        return {
          ...state,
          ids: [...state.ids, action.payload.response.result]
        };
      case deleteRoutine.SUCCESS:
        const key = mapActionToKey(action);
        return {
          ...state,
          ids: state.ids.filter(id => id !== key)
        };
      default:
        return state;
    }
  };

  const byParentId = (state = {}, action = {}) => {
    switch (action.type) {
      case fetchRoutine.REQUEST:
      case fetchRoutine.SUCCESS:
      case fetchRoutine.FAILURE: {
        const parentId = mapActionToParentKey(action);
        return {
          ...state,
          [parentId]: fetchRelationshipForParent(state[parentId], action)
        };
      }
      case createRoutine.SUCCESS:
      case deleteRoutine.SUCCESS: {
        const parentId = mapActionToParentKey(action);
        return {
          ...state,
          [parentId]: editRelationshipForParent(state[parentId], action)
        };
      }
      default:
        return state;
    }
  };
  return byParentId;
};

export default relationshipById;

export const getChildIdsForParent = (state, parentId) =>
  state[parentId] ? state[parentId].ids : [];
export const getIsLoading = (state, parentId) =>
  state[parentId] ? state[parentId].isLoading : false;
