import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import Modal from "../../components/Modal/Modal";
import { hideModal } from "../../modules/modal/modal";
import ExportOrdersForm from "../../components/ExportForm/ExportOrdersForm";
import { orderActions } from "../../modules/orders/orders";
class OrderExportModal extends Component {
  handleOnSubmit = (values) => {
    let from;
    let to;
    switch (values.date) {
      case "today":
        from = moment
          .utc()
          .startOf("day")
          .toISOString();
        to = moment
          .utc()
          .endOf("day")
          .subtract("days")
          .toISOString();
        break;
      case "yesterday":
        from = moment
          .utc()
          .startOf("day")
          .subtract(1, "days")
          .toISOString();
        to = moment
          .utc()
          .endOf("day")
          .subtract(1, "days")
          .toISOString();
        break;
      case "sevenDays":
        from = moment
          .utc()
          .startOf("day")
          .subtract(7, "days")
          .toISOString();
        break;
      case "lastMonth":
        from = moment(moment.utc())
          .subtract(1, "months")
          .startOf("month")
          .toISOString();
        to = moment(moment.utc())
          .subtract(1, "months")
          .endOf("month")
          .toISOString();
        break;
      case "custom":
        from = moment
          .utc(values.dateRange.from)
          .startOf("day")
          .toISOString();
        to = moment
          .utc(values.dateRange.to)
          .endOf("day")
          .toISOString();
        break;
      default:
    }
    switch (values.type) {
      case "orders":
        this.props.exportOrders({ from, to });
        break;
      case "receipts":
        this.props.exportReceipts({ from, to, filter: values.filterType });
        break;
      case "productReceipts":
        this.props.exportProductReceipts({ from, to });
        break;
      case "refunds":
        this.props.exportRefunds({ from, to });
        break;
      default:
    }
    this.props.hideModal();
  };

  render() {
    return (
      <Modal onRequestClose={() => this.props.hideModal()}>
        <ExportOrdersForm
          onSubmit={this.handleOnSubmit}
          onCancel={this.props.hideModal}
        />
      </Modal>
    );
  }
}

export default connect(null, {
  ...orderActions,
  hideModal,
})(OrderExportModal);
