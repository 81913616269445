import React, { Component } from 'react';
import { connect } from 'react-redux';
/* eslint-disable react/style-prop-object */
import MainContent, {
  Title,
  PageTop,
  HeaderActions,
  LineUpHelmet
} from '../../components/MainContent/MainContent';
// import Button from "../../components/Button/Button";
import { InstantSearch } from 'react-instantsearch-dom';
import { userActions } from '../../modules/users/users';
import { showModal } from '../../modules/modal/modal';
import { UserList } from './UserList';
import SearchWithEmptyState from '../../components/SearchWithEmptyState';
// Grommet
import { Box, Button } from 'grommet';
import { Download } from 'grommet-icons';

const defaultAppId = process.env.REACT_APP_ALGOLIA_APP_ID;
const customerIndex = process.env.REACT_APP_ALGOLIA_CUSTOMERS_INDEX;

class Users extends Component {
  handleUserClick = userId => {
    this.props.history.push(`/customer/${userId}`);
  };
  handleExportClick = () => {
    this.props.showModal('EXPORT', {
      onDateRangeSelected: this.handleExportRangeSelected,
      title: 'Export Customers'
    });
  };
  handleExportRangeSelected = ({ from, to }) => {
    this.props.exportCustomers({ from, to });
  };

  render() {
    return (
      <>
        <LineUpHelmet
          title='Customers'
          description='Manage your customers in Line-Up'
        />
        <MainContent>
          <InstantSearch
            appId={defaultAppId}
            apiKey={window.users_api_key}
            indexName={customerIndex}
          >
            <PageTop>
              <Title>Customers</Title>
              <HeaderActions>
                <SearchWithEmptyState
                  Component={({ hits }) => (
                    <Box fill>
                      <Button
                        primary
                        onClick={this.handleExportClick}
                        label='Export'
                        size='small'
                        icon={<Download size='small' />}
                      />
                    </Box>
                  )}
                  EmptyComponent={() => null}
                  showSpinner={false}
                />
              </HeaderActions>
            </PageTop>
            <UserList onClick={this.handleUserClick} />
          </InstantSearch>
        </MainContent>
      </>
    );
  }
}

export default connect(null, {
  ...userActions,
  showModal
})(Users);
