import React from 'react';
import classNames from 'classnames';
import Button from '../Button/Button';
import './Form.css';

export { default as FormDateRangePicker } from './FormDateRange/FormDateRangePicker';
export { default as FormDateField } from './FormDateField/FormDateField';
export { default as FormFieldDateTime } from './FormFieldDateTime/FormFieldDateTime';
export { default as FormFieldReactSelect } from './FormFieldReactSelect/FormFieldReactSelect';
export { default as FormField } from './FormField/FormField';
export { default as FormFieldSeparator } from './FormFieldSeparator/FormFieldSeparator';
export { default as FormSectionHeader } from './FormSectionHeader/FormSectionHeader';
export { default as FormImageField } from './FormImageField/FormImageField';
export { default as FormNote } from './FormNote/FormNote';
export { default as FormSection } from './FormSection/FormSection';
export { default as FormFieldRichText } from './FormFieldRichText/FormFieldRichText';
export { default as FormFieldTime } from './FormFieldTime/FormFieldTime';
export { default as FormFieldSwitch } from './FormFieldSwitch/FormFieldSwitch';
export { default as FormRow } from './FormRow/FormRow';
export { default as FormFieldColourSelect } from './FormFieldColourSelect/FormFieldColourSelect';
const Form = ({
  handleSubmit,
  onCancel,
  cancelBtn = true,
  submitBtn = true,
  submitText = 'Submit',
  formTitle,
  guidanceNotes,
  cancelText = 'Cancel',
  children,
  submitting = false,
  pristine,
  error,
  className,
  sectional,
  danger,
  hasborder,
  heavypadding,
  headercentred,
  submitfull
}) => {
  const handleCancelClick = e => {
    e.preventDefault();
    onCancel && onCancel();
  };
  const formClass = classNames('form-container', className, {
    'form-container--sectional': sectional,
    'form-container--hasborder': hasborder
  });
  const formAreaClass = classNames('form__form-area', className, {
    'form__form-area--sectional': sectional,
    'form__form-area--hasborder': hasborder,
    'form__form-area--heavypadding': heavypadding
  });
  const formHeaderClass = classNames('form__header', className, {
    'form__header--sectional': sectional,
    'form__header--hasborder': hasborder,
    'form__header--heavypadding': heavypadding,
    'form__header--headercentered': headercentred
  });
  const formFooterClass = classNames('form__footer', className, {
    'form__footer--sectional': sectional,
    'form__footer--hasborder': hasborder,
    'form__footer--heavypadding': heavypadding
  });
  const formSubmitClass = classNames('form__submit', className, {
    'form__submit--full': submitfull
  });
  const formButtonClass = classNames('button button--primary', className, {
    'button--full': submitfull,
    'button--large': submitfull
  });
  return (
    <div className={formClass}>
      <form className='form' onSubmit={handleSubmit}>
        <div className={formHeaderClass}>
          <h3 className='form__header-title'>{formTitle}</h3>
          {guidanceNotes && !error && (
            <p className='form__header-notes'>{guidanceNotes}</p>
          )}
          {error && <p className='form__form-error'>{error}</p>}
        </div>
        <div className={formAreaClass}>{children}</div>
        <div className={formFooterClass}>
          <div className={formSubmitClass}>
            {submitBtn && (
              <Button
                className={danger ? 'button button--danger' : formButtonClass}
                disabled={pristine || submitting}
                type='submit'
              >
                {submitText}
              </Button>
            )}
            {cancelBtn && (
              <Button
                onClick={handleCancelClick}
                className='button button--secondary'
                disabled={pristine || submitting}
              >
                {cancelText}
              </Button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
