import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../../components/Modal/Modal';
import { hideModal } from '../../modules/modal/modal';
import TicketSearchList from '../../components/Ticket/TicketSearchList';
import { ContentContainer } from '../../components/MainContent/MainContent';
import { ticketGroupSelectors } from '../../modules/ticketGroups/ticketGroups';

class TicketListModal extends Component {
  handleItemClick = ticketId => {
    if (this.props.handleBtnClick) {
      this.props.handleBtnClick(ticketId);
    }
  };
  render() {
    return (
      <Modal onRequestClose={() => this.props.hideModal()}>
        <ContentContainer>
          <TicketSearchList
            onBtnClick={this.handleItemClick}
            ticketIds={this.props.ticketGroupTicketIds}
          />
        </ContentContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const ticketGroupId = ownProps.ticketGroupId;
  return {
    ticketGroupTicketIds: ticketGroupSelectors.getTicketIds(
      state,
      ticketGroupId
    )
  };
};

export default connect(mapStateToProps, {
  hideModal
})(TicketListModal);
