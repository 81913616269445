import * as types from './types';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const createEventPlanner = createRoutine(types.CREATE_EVENT_PLANNER);
export const submitCreateEventPlanner = promisifyRoutine(createEventPlanner);

export const updateEventPlanner = createRoutine(types.UPDATE_EVENT_PLANNER);
export const submitUpdateEventPlanner = promisifyRoutine(updateEventPlanner);

export const fetchEventPlanner = createRoutine(types.FETCH_EVENT_PLANNER);

export const deleteEventPlanner = createRoutine(types.DELETE_EVENT_PLANNER);
export const submitDeleteEventPlanner = promisifyRoutine(deleteEventPlanner);
