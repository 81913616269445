export const FETCH_ORDER = "line-up/order/FETCH_ORDER";
export const FETCH_ORDER_NOTES = "line-up/order/FETCH_ORDER_NOTES";
export const FETCH_ORDER_FORM_RESPONSES =
  "line-up/order/FETCH_ORDER_FORM_RESPONSES";
export const SELECT_RECEIPT = "line-up/order/SELECT_RECEIPT";
export const UNSELECT_RECEIPT = "line-up/order/UNSELECT_RECEIPT";

export const CREATE_NOTE = "line-up/order/CREATE_NOTE";
export const UPDATE_NOTE = "line-up/order/UPDATE_NOTE";
export const DELETE_NOTE = "line-up/order/DELETE_NOTE";

export const SEND_CONFIRMATION = "line-up/order/SEND_CONFIRMATION";

export const CREATE_REFUND = "line-up/order/CREATE_REFUND";
export const DELETE_REFUND = "line-up/order/DELETE_REFUND";
export const EDIT_RECEIPTS = "line-up/order/EDIT_RECEIPTS";
export const EDIT_RECEIPT = "line-up/order/EDIT_RECEIPT";

export const EXPORT_ORDERS = "line-up/order/EXPORT_ORDERS";
export const EXPORT_RECEIPTS = "line-up/order/EXPORT_RECEIPTS";
export const EXPORT_PRODUCT_RECEIPTS = "line-up/order/EXPORT_PRODUCT_RECEIPTS";
export const EXPORT_TICKET_PDF = "line-up/order/EXPORT_TICKET_PDF";
export const RECEIPT_REDEEM = "line-up/order/RECEIPT_REDEEM";
export const EXPORT_REFUNDS = "line-up/order/EXPORT_REFUNDS";
