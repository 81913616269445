import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';

import Modal from 'components/Modal/Modal';
import Form from 'components/Form/Form';

import { hideModal } from 'modules/modal/modal';

const DeleteLaneConfirmModal = ({ hideModal, lane, onDelete }) => {
  const handleSubmit = () => {
    onDelete(lane);
  };

  return (
    <Modal onRequestClose={() => hideModal()}>
      <DeleteLaneForm
        lane={lane}
        onCancel={hideModal}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

let DeleteLaneForm = ({ lane, onCancel, handleSubmit }) => (
  <Form
    formTitle="Are you sure you want to delete this?"
    submitText="Yes, delete this"
    handleSubmit={handleSubmit}
    onCancel={onCancel}
    danger={true}
  >
    This action will delete {lane.name}? There is no undo.
  </Form>
);

DeleteLaneForm = reduxForm({
  form: 'deleteLaneForm'
})(DeleteLaneForm);

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        hideModal
      },
      dispatch
    )
  };
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteLaneConfirmModal);
