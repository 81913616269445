import { takeEvery, put, call, fork } from 'redux-saga/effects';
import * as api from '../../services/api';
import * as actions from './actions';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { fetchEntity, submitForm, deleteEntity } from '../shared/operations';

const fetchPerformancesPage = fetchEntity.bind(
  null,
  actions.fetchPerformancesPage,
  api.fetchPerformancesPage
);

// TODO move to shared

function* fetchPerformances(action) {
  yield put(actions.fetchPerformancesPage.request({ ...action.payload }));
  const { response, error } = yield call(api.fetchPerformancesPage, {
    ...action.payload,
  });
  if (response) {
    yield put(
      actions.fetchPerformancesPage.success({ response, ...action.payload })
    );
    return response;
  } else {
    put(actions.fetchPerformancesPage.failure({ error, ...action.payload }));
  }
  yield put(actions.fetchPerformancesPage.fulfill({ ...action.payload }));
}

function* fetchPagedPerformances(action) {
  let page = 1;
  const response = yield call(fetchPerformances, {
    ...action,
    payload: {
      ...action.payload,
      page: page,
    },
  });
  if (response) {
    const { pagination } = response;
    while (page < pagination.totalPages) {
      page++;
      yield fork(fetchPerformances, {
        ...action,
        payload: {
          ...action.payload,
          page: page,
        },
      });
    }
  }
}

function* fetchAllPerformances(action) {
  yield put(showLoading());
  yield put(actions.fetchAllPerformances.request());
  yield call(fetchPagedPerformances, action);
  yield put(actions.fetchAllPerformances.success());
  yield put(hideLoading());
}

const fetchPerformance = fetchEntity.bind(
  null,
  actions.fetchPerformance,
  api.fetchPerformance
);

const createPerformance = submitForm.bind(
  null,
  actions.createPerformance,
  api.createPerformance
);

const updatePerformance = submitForm.bind(
  null,
  actions.updatePerformance,
  api.updatePerformance
);

export function* watchFetchAllPerformances() {
  yield takeEvery(actions.fetchAllPerformances.TRIGGER, fetchAllPerformances);
}

export function* watchFetchPerformances() {
  yield takeEvery(actions.fetchPerformancesPage.TRIGGER, fetchPerformancesPage);
}

export function* watchFetchPerformance() {
  yield takeEvery(actions.fetchPerformance.TRIGGER, fetchPerformance);
}

export function* watchCreatePerformance() {
  yield takeEvery(actions.createPerformance.TRIGGER, createPerformance);
}

export function* watchUpdatePerformance() {
  yield takeEvery(actions.updatePerformance.TRIGGER, updatePerformance);
}

const downloadGuestlistCSV = submitForm.bind(
  null,
  actions.downloadGuestlistCSV,
  api.downloadGuestlistCSV
);

export function* watchDownloadGuestlistCSV() {
  yield takeEvery(actions.downloadGuestlistCSV.TRIGGER, downloadGuestlistCSV);
}

const downloadGuestlistPDF = submitForm.bind(
  null,
  actions.downloadGuestlistPDF,
  api.downloadGuestlistPDF
);

export function* watchDownloadGuestlistPDF() {
  yield takeEvery(actions.downloadGuestlistPDF.TRIGGER, downloadGuestlistPDF);
}

const deletePerformance = deleteEntity.bind(
  null,
  actions.deletePerformance,
  api.deletePerformance
);

export function* watchDeletePerformance() {
  yield takeEvery(actions.deletePerformance.TRIGGER, deletePerformance);
}
