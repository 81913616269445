import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EventList, EventEmptyState } from '../../components/Event/Event';
import MainContent, {
  LineUpHelmet
} from '../../components/MainContent/MainContent';
import { eventActions, eventSelectors } from '../../modules/events/events';

class Events extends Component {
  componentDidMount() {
    this.props.fetchAllEvents();
  }

  handleEventClick = event => {
    this.props.history.push(`/event/${event.id}/performances`);
  };
  handleSelectPage = page => {
    this.props.history.push(`/events/${page}`);
  };

  handleSearchTextChange = e => {
    const searchQuery = e.target.value;
    if (this.props.currentPage !== 1) {
      this.props.history.push(`/events`);
    }
    this.props.searchEvents(searchQuery);
  };

  render() {
    const {
      events,
      currentPage,
      totalPages,
      isLoading,
      searchTerm
    } = this.props;
    return (
      <>
        <LineUpHelmet
          title='Events'
          description='Manage your events in Line-Up'
        />
        <MainContent>
          <EventList
            isLoading={isLoading}
            isEmpty={!events.length > 0 && searchTerm === null}
            events={events}
            emptyStateComponent={<EventEmptyState />}
            currentPage={currentPage}
            totalPages={totalPages}
            onSearchTextChange={this.handleSearchTextChange}
            onSelectPage={this.handleSelectPage}
            onEventClick={this.handleEventClick}
          />
        </MainContent>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const page = parseInt(match.params.page, 10) || 1;
  return {
    events: eventSelectors.getEventsForPage(state, page),
    isLoading: eventSelectors.getEventsLoadingStatus(state),
    currentPage: page,
    totalPages: eventSelectors.getEventsTotalPages(state),
    searchTerm: eventSelectors.getSearchTerm(state)
  };
};

export default connect(mapStateToProps, {
  ...eventActions
})(Events);
