import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import Form, { FormField, FormFieldReactSelect } from '../../Form/Form';
import { required, maxInt } from '../../../utils/validators';

import { Box, Text } from 'grommet';

let TicketAllocationForm = props => {
  const {
    handleSubmit,
    onCancel,
    error,
    submitting,
    maxInventory,

    allocation,
    agents,
    quantity
  } = props;
  const quantityAllocated = allocation && allocation.quantityAllocated;
  return (
    <Form
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      submitting={submitting}
      formTitle={
        allocation ? 'Edit Ticket Allocation' : 'Create Ticket Allocation'
      }
      error={error}
    >
      <Field
        label='Allocation Name'
        name='description'
        component={FormField}
        validate={[required]}
        type='input'
      />
      <Field
        label={
          quantityAllocated
            ? 'Remaining Tickets in Allocation'
            : 'Tickets in Allocation'
        }
        name='quantity'
        type='number'
        component={FormField}
        step='any'
        width='20'
        max={maxInventory}
        placeholder='E.g. 150'
        validate={[required]}
        normalize={maxInt(maxInventory)}
        hintAfter={
          <Box direction='column'>
            <Text size='xsmall'>
              Tickets already allocated: {quantityAllocated} tickets
            </Text>
            <Text size='xsmall'>
              Total size of Allocation: {quantityAllocated + quantity} tickets
            </Text>
          </Box>
        }
      />
      <Field
        label='Agent'
        name='agent'
        component={FormFieldReactSelect}
        type='input'
        options={agents}
      />
    </Form>
  );
};

TicketAllocationForm = reduxForm({
  form: 'ticketAllocation',
  enableReinitialize: true
})(TicketAllocationForm);

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector('ticketAllocation');
  const quantity = selector(state, 'quantity');
  return {
    quantity
  };
};

export default connect(mapStateToProps)(TicketAllocationForm);
