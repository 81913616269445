import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Modal from 'components/Modal/Modal';

import { hideModal } from 'modules/modal/modal';
import ConfirmReservationForm from 'components/Planner/ConfirmReservationForm';

// this modal is used to view an existing allocation, and issue a single ticket
// for it
const ConfirmReservationModal = ({
  hideModal,
  onSubmit,
  onDelete,
  placement,
}) => {
  return (
    <Modal onRequestClose={() => hideModal()}>
      <ConfirmReservationForm
        placement={placement}
        onSubmit={onSubmit}
        onCancel={hideModal}
        onDelete={onDelete}
        initialValues={{
          price: placement.ticketAllocation.ticket.faceValueTotal,
          includeFee: true,
        }}
      />
    </Modal>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        hideModal,
      },
      dispatch
    ),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmReservationModal);
