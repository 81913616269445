import React from 'react';
import { PivotData } from './Utilities';
import './TableRenderer.css';
import { CSVLink } from 'react-csv';

import { SubTitle } from '../../components/MainContent/MainContent';

// Grommet
import { Button } from 'grommet';
import { DocumentCsv } from 'grommet-icons';

// helper function for setting row/col-span in pivotTableRenderer
const spanSize = function(arr, i, j) {
  let x;
  if (i !== 0) {
    let asc, end;
    let noDraw = true;
    for (
      x = 0, end = j, asc = end >= 0;
      asc ? x <= end : x >= end;
      asc ? x++ : x--
    ) {
      if (arr[i - 1][x] !== arr[i][x]) {
        noDraw = false;
      }
    }
    if (noDraw) {
      return -1;
    }
  }
  let len = 0;
  while (i + len < arr.length) {
    let asc1, end1;
    let stop = false;
    for (
      x = 0, end1 = j, asc1 = end1 >= 0;
      asc1 ? x <= end1 : x >= end1;
      asc1 ? x++ : x--
    ) {
      if (arr[i][x] !== arr[i + len][x]) {
        stop = true;
      }
    }
    if (stop) {
      break;
    }
    len++;
  }
  return len;
};

const makeCSV = pivotData => {
  const colAttrs = pivotData.props.cols;
  const rowAttrs = pivotData.props.rows;
  const aggregations = pivotData.getAggregations();
  const rowKeys = pivotData.getRowKeys();
  const colKeys = pivotData.getColKeys();
  const data = [
    ...colAttrs.reduce(
      (rows, item, index) => [
        ...rows,
        [
          ...Array(rowAttrs.length).fill(''),
          ...colKeys.reduce(
            (row, colKey) => [
              ...row,
              colKey[index],
              ...Array(aggregations.length - 1).fill('')
            ],
            []
          ),
          ...[
            index === 0 ? 'Totals' : '',
            ...Array(aggregations.length - 1).fill('')
          ]
        ]
      ],
      []
    ),
    [
      ...Array(rowAttrs.length).fill(''),
      ...colKeys.reduce(vals => [...vals, ...aggregations], []),
      ...aggregations
    ],
    ...rowKeys.reduce(
      (bodyRows, rowKey, i) => [
        ...bodyRows,
        [
          ...rowKey,
          ...colKeys.reduce(
            (bodyRow, colKey) => [
              ...bodyRow,
              ...aggregations.map(aggr => {
                const aggregator = pivotData.getAggregator(
                  rowKey,
                  colKey,
                  aggr
                );
                return aggregator.value();
              }, [])
            ],
            []
          ),
          ...aggregations.map(aggr => {
            const totalAggregator = pivotData.getAggregator(rowKey, [], aggr);
            return totalAggregator.value();
          })
        ]
      ],
      []
    ),
    [
      'Totals',
      ...Array(rowAttrs.length - 1).fill(''),
      ...colKeys.reduce(
        (totalRow, colKey) => [
          ...totalRow,
          ...aggregations.map(aggr => {
            const totalAggregator = pivotData.getAggregator([], colKey, aggr);
            return totalAggregator.value();
          })
        ],
        []
      ),
      ...aggregations.map((aggr, i) => {
        const grandTotalAggregator = pivotData.getAggregator([], [], aggr);
        return grandTotalAggregator.value();
      })
    ]
  ];
  return data;
};
function makeRenderer(opts = {}) {
  class TableRenderer extends React.PureComponent {
    render() {
      const pivotData = new PivotData(this.props);
      const colAttrs = pivotData.props.cols;
      const rowAttrs = pivotData.props.rows;
      const aggregations = pivotData.getAggregations();
      const rowKeys = pivotData.getRowKeys();
      const colKeys = pivotData.getColKeys();
      const csvData = makeCSV(pivotData);
      let colTotalColors = () => {};
      return (
        <div>
          <div className='report__screen-table-title'>
            <SubTitle title={this.props.title}>
              <CSVLink
                data={csvData}
                filename={`${this.props.exportFilename}.csv`}
                className='report__csv-link-button'
              >
                <Button
                  size='small'
                  label='Export'
                  icon={<DocumentCsv size='small' />}
                  secondary
                  fill
                />
              </CSVLink>
            </SubTitle>
          </div>
          <div className='report__print-table-title'>{this.props.title}</div>

          <div className='report-table-container'>
            <table className='report-table'>
              <thead>
                {colAttrs.map((c, j) => {
                  return (
                    <tr key={`colAttr${j}`}>
                      {j === 0 && rowAttrs.length !== 0 && (
                        <th
                          colSpan={rowAttrs.length}
                          rowSpan={colAttrs.length}
                        />
                      )}
                      {colKeys.map(function(colKey, i) {
                        return (
                          <th
                            className='report-table__column-label'
                            key={`colKey${i}`}
                            colSpan={aggregations.length}
                          >
                            {colKey[j]}
                          </th>
                        );
                      })}

                      {j === 0 && (
                        <th
                          className='pvtTotalLabel'
                          colSpan={aggregations.length}
                          rowSpan={colAttrs.length}
                        >
                          Totals
                        </th>
                      )}
                    </tr>
                  );
                })}
                {rowAttrs.length !== 0 && (
                  <tr>
                    <th colSpan={rowAttrs.length} />
                    {colKeys.map((c, j) =>
                      aggregations.map((aggr, i) => (
                        <th key={`aggr${i}${j}`}>{aggr}</th>
                      ))
                    )}
                    {aggregations.map((aggr, i) => (
                      <th key={`aggrTotal${i}`}>{aggr}</th>
                    ))}
                  </tr>
                )}
              </thead>

              <tbody>
                {rowKeys.map(function(rowKey, i) {
                  return (
                    <tr key={`rowKeyRow${i}`}>
                      {rowKey.map(function(txt, j) {
                        const x = spanSize(rowKeys, i, j);
                        if (x === -1) {
                          return null;
                        }
                        return (
                          <td
                            key={`rowKeyLabel${i}-${j}`}
                            className='report-table__row-label'
                            rowSpan={x}
                          >
                            {txt}
                          </td>
                        );
                      })}
                      {colKeys.map((colKey, j) =>
                        aggregations.map((aggr, i) => {
                          const aggregator = pivotData.getAggregator(
                            rowKey,
                            colKey,
                            aggr
                          );
                          return (
                            <td
                              className='report-table__cells'
                              key={`pvtVal${i}-${j}`}
                            >
                              {aggregator.format(aggregator.value())}
                            </td>
                          );
                        })
                      )}
                      {aggregations.map((aggr, i) => {
                        const totalAggregator = pivotData.getAggregator(
                          rowKey,
                          [],
                          aggr
                        );
                        return (
                          <td
                            style={colTotalColors(totalAggregator.value())}
                            key={i}
                          >
                            {totalAggregator.format(totalAggregator.value())}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}

                <tr>
                  <th className='report-table__total' colSpan={rowAttrs.length}>
                    Totals
                  </th>

                  {colKeys.map((colKey, i) =>
                    aggregations.map((aggr, s) => {
                      const totalAggregator = pivotData.getAggregator(
                        [],
                        colKey,
                        aggr
                      );
                      return (
                        <td
                          className='report-table__grand-total'
                          key={`total${i}-${s}`}
                        >
                          {totalAggregator.format(totalAggregator.value())}
                        </td>
                      );
                    })
                  )}
                  {aggregations.map((aggr, i) => {
                    const grandTotalAggregator = pivotData.getAggregator(
                      [],
                      [],
                      aggr
                    );
                    return (
                      <td className='report-table__grand-total' key={i}>
                        {grandTotalAggregator.format(
                          grandTotalAggregator.value()
                        )}
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  return TableRenderer;
}

export default {
  Table: makeRenderer()
};
