import { fetchEntity, submitForm } from '../shared/operations';
import { takeEvery } from 'redux-saga/effects';
import * as actions from './actions';
import * as api from '../../services/api';

const fetchEmail = fetchEntity.bind(null, actions.fetchEmail, api.fetchEmail);

export function* watchFetchEmail() {
  yield takeEvery(actions.fetchEmail.TRIGGER, fetchEmail);
}

const updateEmail = submitForm.bind(null, actions.updateEmail, api.updateEmail);

export function* watchUpdateEmail() {
  yield takeEvery(actions.updateEmail.TRIGGER, updateEmail);
}

const createEmail = submitForm.bind(null, actions.createEmail, api.createEmail);

export function* watchCreateEmail() {
  yield takeEvery(actions.createEmail.TRIGGER, createEmail);
}

const deleteEmail = submitForm.bind(null, actions.deleteEmail, api.deleteEmail);

export function* watchDeleteEmail() {
  yield takeEvery(actions.deleteEmail.TRIGGER, deleteEmail);
}

const sendEmailPreview = submitForm.bind(
  null,
  actions.sendEmailPreview,
  api.sendEmailPreview
);

export function* watchSendEmailPreview() {
  yield takeEvery(actions.sendEmailPreview.TRIGGER, sendEmailPreview);
}
