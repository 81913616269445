import React, { Component } from 'react';
import { connect } from 'react-redux';
/* eslint-disable react/style-prop-object */
import MainContent, {
  Title,
  HeaderActions,
  PageTop
} from '../../components/MainContent/MainContent';
// import Button from '../../components/Button/Button';
import { InstantSearch } from 'react-instantsearch-dom';
// import SearchFilters from '../../components/SearchFilters/SearchFilters';
// import SearchFilter from '../../components/SearchFilters/SearchFilter/SearchFilter';
import { Helmet } from 'react-helmet';

// Grommet
import { Box, Button } from 'grommet';
import { Download } from 'grommet-icons';

import { showModal } from '../../modules/modal/modal';
import { OrderList } from './OrderList';
import SearchWithEmptyState from '../../components/SearchWithEmptyState';

const defaultAppId = process.env.REACT_APP_ALGOLIA_APP_ID;
const ordersIndex = process.env.REACT_APP_ALGOLIA_ORDERS_INDEX;

class Orders extends Component {
  handleOrderClick = orderId => {
    this.props.history.push(`/order/${orderId}/`);
  };
  handleGenerateOrderLink = orderId => {
    return `/order/${orderId}/`;
  };
  handleExportClick = () => {
    this.props.showModal('EXPORT_ORDERS', {});
  };
  render() {
    return (
      <>
        <Helmet>
          <html lang='en' />
          <title>Orders | Line-Up</title>
          <meta name='description' content='Manage your orders' />
        </Helmet>

        <MainContent>
          <InstantSearch
            appId={defaultAppId}
            apiKey={window.orders_api_key}
            indexName={ordersIndex}
          >
            <PageTop>
              <Title>Orders</Title>
              <HeaderActions>
                <SearchWithEmptyState
                  Component={({ hits }) => (
                    <Box fill>
                      <Button
                        primary
                        onClick={this.handleExportClick}
                        label='Export'
                        size='small'
                        icon={<Download size='small' />}
                      />
                    </Box>
                  )}
                  EmptyComponent={() => null}
                  showSpinner={false}
                />
              </HeaderActions>
            </PageTop>
            <OrderList onGenerateOrderLink={this.handleGenerateOrderLink} />
          </InstantSearch>
        </MainContent>
      </>
    );
  }
}

export default connect(null, {
  showModal
})(Orders);
