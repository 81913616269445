import React, { Component } from 'react';
import Creatable from 'react-select/lib/Creatable';
import classNames from 'classnames';

import {
  ticketGroupSelectors,
  ticketGroupActions
} from '../../modules/ticketGroups/ticketGroups';
import { connect } from 'react-redux';

class TicketGroupSelect extends Component {
  componentWillMount() {
    this.props.loadTicketGroups();
  }
  handleTicketGroupCreate = groupName => {
    this.props.createTicketGroup({
      name: groupName
    });
  };

  getOptions = () => {
    return this.props.ticketGroups.map(tg => ({
      label: tg.name,
      value: tg.slug
    }));
  };

  render() {
    const {
      input,
      width,
      label,
      className,
      id,
      placeholder,
      creating,
      loading,
      labelAfter,
      meta: { submitting, touched, error, warning }
    } = this.props;

    const rowClass = classNames('form__row', className);

    const style = () => {
      if (width) {
        return {
          width: `${width}%`
        };
      }
      return {};
    };

    const styles = {
      container: styles => ({
        ...styles,
        fontFamily: 'lato, sans serif',
        fontSize: '0.85rem',
        marginTop: '0',
        marginBottom: '0'
      }),
      control: styles => ({
        ...styles,
        backgroundColor: 'white',
        borderWidth: '0.09rem',
        borderColor: '#ddd',
        borderRadius: '0.2rem'
      }),
      valueContainer: styles => ({
        ...styles,
        padding: '0 0.5rem'
      })
    };

    return (
      <div className={rowClass} style={style()}>
        <label className="form__label" htmlFor={id}>
          {label}
        </label>
        <Creatable
          {...input}
          options={this.getOptions()}
          onBlur={() => null}
          isDisabled={submitting || creating}
          isLoading={creating || loading}
          styles={styles}
          onCreateOption={this.handleTicketGroupCreate}
          isClearable
          isMulti
          placeholder={placeholder}
        />
        {touched &&
          ((error && <span className="form__error">{error}</span>) ||
            (warning && <span className="form__error">{warning}</span>))}
        <label className="form__label" htmlFor={id}>
          {labelAfter}
        </label>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const ticketGroups = ticketGroupSelectors.getTicketGroups(state);
  const creating = ticketGroupSelectors.getCreatingStatus(state);
  const loading = ticketGroupSelectors.getLoadingStatus(state);
  return {
    ticketGroups,
    creating,
    loading
  };
};

export default connect(mapStateToProps, {
  ...ticketGroupActions
})(TicketGroupSelect);
