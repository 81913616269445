import React from 'react';
import { connect } from 'react-redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { Title } from '../../../components/MainContent/MainContent';

import PlannerForm from 'components/PlannerForm/PlannerForm';
import { eventActions, eventSelectors } from 'modules/events/events';
import { eventPlannerActions } from 'modules/eventPlanner/eventPlanner';

// Grommet
import { Box } from 'grommet';

const PlannerCreate = ({
  eventId,
  event,
  fetchEvent,
  submitCreateEventPlanner,
  dispatch,
}) => {
  // on load fetch the event
  React.useEffect(() => {
    fetchEvent({ eventId: eventId });
  }, [fetchEvent, eventId]);

  const handleSubmit = values => {
    const payload = {
      ...values,
      eventId,
    };
    return submitCreateEventPlanner(payload).then(r => {
      dispatch(push(`/event/${event.id}/planner`));
    });
  };

  const handleCancel = () => {
    dispatch(push(`/event/${eventId}/planner`));
  };

  return (
    <>
      <Title>Create Event Planner</Title>
      <Box margin={{ vertical: 'medium' }}>
        <PlannerForm
          onSubmit={handleSubmit}
          initialValues={{ eventPlannerLanes: [{}] }}
          onCancel={handleCancel}
        />
      </Box>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const eventId = parseInt(ownProps.match.params.eventId, 10);
  const event = eventSelectors.getEvent(state, eventId);
  return {
    eventId,
    event,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        ...eventActions,
        ...eventPlannerActions,
      },
      dispatch
    ),
    ...bindPromiseCreators(
      {
        submitCreateEventPlanner: eventPlannerActions.submitCreateEventPlanner,
      },
      dispatch
    ),
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlannerCreate);
