const updateEntity = (state, entities) => {
  return Object.assign({}, state, entities);
};

const updateEntities = (state, key, entities) => {
  return Object.assign({}, state, {
    [key]: updateEntity(state[key], entities[key])
  });
};
const defaultDataResolver = action => {
  if (
    action.payload &&
    action.payload.response &&
    action.payload.response.entities
  ) {
    return action.payload.response.entities;
  }
  return null;
};

const entities = (state = {}, action) => {
  let newState = state;
  const entities = defaultDataResolver(action);
  if (entities && typeof entities === 'object') {
    newState = Object.keys(entities).reduce(
      (acc, key) => updateEntities(acc, key, entities),
      newState
    );
  }
  return newState;
};

export default entities;

export const getEntity = (state, id) => (state ? state[id] : {});
