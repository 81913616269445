import React from 'react';
import { FormattedNumber } from 'react-intl';
/* eslint-disable react/style-prop-object */
const Price = ({ amount, currency, showFree = false, showZero = false }) =>
  amount > 0 ? (
    <FormattedNumber value={amount} style="currency" currency={currency} />
  ) : showFree ? (
    'Free'
  ) : showZero ? (
    <FormattedNumber value={amount} style="currency" currency={currency} />
  ) : null;
export default Price;
