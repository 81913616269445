import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import {
  SubTitle,
  PageTop,
} from '../../../../components/MainContent/MainContent';
import BulkUploadTable from '../../../../components/BulkUploadTable/BulkUploadTable';
import {
  eventActions,
  eventSelectors,
} from '../../../../modules/events/events';
import {
  accountActions,
  accountSelectors,
} from '../../../../modules/account/account';
import { Box, Button, Text } from 'grommet';
import { Checkmark, Close } from 'grommet-icons';

const BulkUpload = ({
  fileData,
  eventId,
  businessSettings,
  getBusinessSettings,
  createBulkUpload,
}) => {
  const history = useHistory();
  useEffect(() => {
    if (!businessSettings) {
      getBusinessSettings();
    }
  }, [getBusinessSettings, businessSettings]);
  const handleConfirmBulkUploadClick = () => {
    createBulkUpload({ data: fileData, eventId: eventId });
    history.push(`/event/${eventId}/bulkupload/complete`);
  };
  const handleCancelBulkUploadClick = () => {
    history.push(`/event/${eventId}/bulkupload`);
  };
  if(!fileData){
    history.push(`/event/${eventId}/bulkupload`);
    return null;
  }
  return (
    <>
      <PageTop>
        <SubTitle title='New Bulk Upload - Review Data' />
      </PageTop>
      <Text margin={{ vertical: 'medium' }}>
        Below are the performances and their tickets from your CSV. To continue,
        please confirm these are correct or cancel to go back and re-upload.
      </Text>
      <Box margin={{ top: 'small', bottom: 'large' }}>
        <BulkUploadTable
          fileData={fileData}
          currency={businessSettings ? businessSettings.currency : 'XXX'}
        />
        <Box margin={{ top: 'medium' }} direction='row' gap='small'>
          <Button
            primary
            onClick={handleConfirmBulkUploadClick}
            label='Confirm Bulk Upload'
            size='small'
            fill={false}
            icon={<Checkmark size='small' />}
          />
          <Button
            secondary
            backgroundColor='error'
            onClick={handleCancelBulkUploadClick}
            label='Cancel Bulk Upload'
            size='small'
            icon={<Close size='small' />}
          />
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const eventId = parseInt(ownProps.match.params.eventId, 10);
  return {
    eventId,
    fileData: eventSelectors.getBulkUploadFileData(state),
    businessSettings: accountSelectors.getBusinessSettings(state),
  };
};

export default connect(
  mapStateToProps,
  {
    ...accountActions,
    ...eventActions,
  }
)(BulkUpload);
