import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import './DateRange.css';

export default class DateSelect extends React.Component {
  render() {
    return (
      <div className="date-range">
        <DayPickerInput
          placeholder="Select Date"
          format="LL"
          formatDate={formatDate}
          parseDate={parseDate}
          classNames={{
            container: 'day-picker',
            overlayWrapper: 'day-picker__overlay-wrap',
            overlay: 'day-picker__overlay'
          }}
          dayPickerProps={{
            numberOfMonths: 2
          }}
          value={this.props.value}
          onDayChange={this.props.onDaySelect}
        />
      </div>
    );
  }
}
