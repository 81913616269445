import React from 'react';

/**
 * Mark Components
 */

const Bold = ({ children }) => <strong>{children}</strong>;
const Italic = ({ children }) => <em>{children}</em>;
const Underline = ({ children }) => <u>{children}</u>;
const Strikethrough = ({ children }) => <s>{children}</s>;
const Code = ({ children }) => <code>{children}</code>;

/**
 * Node Components
 */

const Paragraph = ({ attributes, children, node }) => (
  <p {...attributes}>{children}</p>
);
const ListItem = ({ attributes, children, node }) => (
  <li {...attributes}>{children}</li>
);
const Quote = ({ attributes, children, node }) => (
  <blockquote {...attributes}>{children}</blockquote>
);
const CodeBlock = ({ attributes, children, node }) => (
  <pre>
    <code {...attributes}>{children}</code>
  </pre>
);
const HeadingOne = ({ attributes, children, node }) => (
  <h1 {...attributes}>{children}</h1>
);
const HeadingTwo = ({ attributes, children, node }) => (
  <h2 {...attributes}>{children}</h2>
);
const HeadingThree = ({ attributes, children, node }) => (
  <h3 {...attributes}>{children}</h3>
);
const HeadingFour = ({ attributes, children, node }) => (
  <h4 {...attributes}>{children}</h4>
);
const HeadingFive = ({ attributes, children, node }) => (
  <h5 {...attributes}>{children}</h5>
);
const HeadingSix = ({ attributes, children, node }) => (
  <h6 {...attributes}>{children}</h6>
);
const Table = ({ attributes, children, node }) => (
  <table>
    <tbody {...attributes}>{children}</tbody>
  </table>
);
const TableRow = ({ attributes, children, node }) => (
  <tr {...attributes}>{children}</tr>
);
const TableCell = ({ attributes, children, node }) => (
  <td {...attributes}>{children}</td>
);
const ThematicBreak = ({ attributes, children, node }) => (
  <hr {...attributes} />
);
const BulletedList = ({ attributes, children, node }) => (
  <ul {...attributes}>{children}</ul>
);
const NumberedList = ({ attributes, children, node }) => (
  <ol {...attributes} start={node.data.get('start') || 1}>
    {children}
  </ol>
);
const Link = ({ attributes, children, node }) => {
  const data = node.get('data');
  const marks = data.get('marks');
  const url = data.get('href');
  const title = data.get('title');
  const link = (
    <a href={url} title={title} {...attributes}>
      {children}
    </a>
  );
  const result = !marks
    ? link
    : marks.reduce((acc, mark) => {
        return renderMark({ mark, children: acc });
      }, link);
  return result;
};
const Image = ({ attributes, children, node }) => {
  const data = node.get('data');
  const marks = data.get('marks');
  const url = data.get('url');
  const title = data.get('title');
  const alt = data.get('alt');
  const image = <img src={url} title={title} alt={alt} {...attributes} />;
  const result = !marks
    ? image
    : marks.reduce((acc, mark) => {
        return renderMark({ mark, children: acc });
      }, image);
  return result;
};

export const markForType = (type, attributes, children, node) => {
  switch (type) {
    case 'bold':
      return <Bold children={children} />;
    case 'italic':
      return <Italic children={children} />;
    case 'underline':
      return <Underline children={children} />;
    case 'strikethrough':
      return <Strikethrough children={children} />;
    case 'code':
      return <Code children={children} />;
    default:
      return children;
  }
};

export const renderMark = (props, editor, next) =>
  markForType(props.mark.type, props.attributes, props.children, props.node);

export const renderNode = (props, editor, next) =>
  nodeForType(props.node.type, props.attributes, props.children, props.node);

export const nodeForType = (type, attributes, children, node) => {
  switch (type) {
    case 'paragraph':
      return (
        <Paragraph attributes={attributes} children={children} node={node} />
      );
    case 'list-item':
      return (
        <ListItem attributes={attributes} children={children} node={node} />
      );
    case 'quote':
      return <Quote attributes={attributes} children={children} node={node} />;
    case 'code':
      return (
        <CodeBlock attributes={attributes} children={children} node={node} />
      );
    case 'heading-one':
      return (
        <HeadingOne attributes={attributes} children={children} node={node} />
      );
    case 'heading-two':
      return (
        <HeadingTwo attributes={attributes} children={children} node={node} />
      );
    case 'heading-three':
      return (
        <HeadingThree attributes={attributes} children={children} node={node} />
      );
    case 'heading-four':
      return (
        <HeadingFour attributes={attributes} children={children} node={node} />
      );
    case 'heading-five':
      return (
        <HeadingFive attributes={attributes} children={children} node={node} />
      );
    case 'heading-six':
      return (
        <HeadingSix attributes={attributes} children={children} node={node} />
      );
    case 'table':
      return <Table attributes={attributes} children={children} node={node} />;
    case 'table-row':
      return (
        <TableRow attributes={attributes} children={children} node={node} />
      );
    case 'table-cell':
      return (
        <TableCell attributes={attributes} children={children} node={node} />
      );
    case 'thematic-break':
      return (
        <ThematicBreak
          attributes={attributes}
          children={children}
          node={node}
        />
      );
    case 'bulleted-list':
      return (
        <BulletedList attributes={attributes} children={children} node={node} />
      );
    case 'numbered-list':
      return (
        <NumberedList attributes={attributes} children={children} node={node} />
      );
    case 'link':
      return <Link attributes={attributes} children={children} node={node} />;
    case 'image':
      return <Image attributes={attributes} children={children} node={node} />;
    default:
      return (
        <Paragraph attributes={attributes} children={children} node={node} />
      );
  }
};
