import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedDate } from 'react-intl';
import { Link } from 'react-router-dom';
/* eslint-disable react/style-prop-object */
import { LineItemsTable } from '../../components/Order/Order';
import MainContent, {
  ContentContainer,
  Title,
  SubTitle,
  ContentType,
  MultiActionContainer,
  LineUpHelmet,
} from '../../components/MainContent/MainContent';
import { Price } from '../../components/Utils/Utils';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import InfoBlock, {
  InfoBlockList,
  InfoBlockListItem,
} from '../../components/InfoBlock/InfoBlock';
import TimeLine from '../../components/TimeLine/TimeLine';
import { orderActions, orderSelectors } from '../../modules/orders/orders';
import { showModal } from '../../modules/modal/modal';
// Grommet
import { Box, Button, Heading, ResponsiveContext, Text } from 'grommet';
import {
  CreditCard,
  PauseFill,
  Note,
  Undo,
  Download,
  Send,
  Revert,
  Transaction,
  StatusGood,
  Tag,
} from 'grommet-icons';
import _ from 'lodash';
import Spinner from 'react-spinkit';
class OrderDetail extends Component {
  componentDidMount() {
    this.props.fetchOrder({ orderId: this.props.orderId });
  }
  componentDidUpdate(prevProps) {
    if (this.props.orderId !== prevProps.orderId) {
      this.props.fetchOrder({ orderId: this.props.orderId });
    }
  }

  handleRefundAllClick = (e) => {
    e.preventDefault();
    this.props.showModal('REFUND_MODAL', {
      orderId: this.props.order.id,
      currency: this.props.order.currency,
      onRefundClick: this.handleRefundSubmit,
      selectAll: true,
    });
  };
  handelRefundSelectedClick = (e) => {
    e.preventDefault();

    this.props.showModal('REFUND_MODAL', {
      orderId: this.props.order.id,
      currency: this.props.order.currency,
      onRefundClick: this.handleRefundSubmit,
      selectAll: !this.props.selectedItemsCount > 0,
    });
  };

  handleReceiptClick = (key, receiptId) => {
    this.props.selectedReceipts[key].find((id) => id === receiptId)
      ? this.props.unselectReceipt(key, receiptId)
      : this.props.selectReceipt(key, receiptId);
  };

  handleRefundSubmit = (values, refundData) => {
    const { note } = values;
    this.props.createRefund({
      refund: {
        ...refundData,
        note: note,
        refundToVoucher: values.refundToVoucher,
      },
      orderId: this.props.orderId,
    });
  };

  handleAddNoteClick = (e) => {
    e.preventDefault();
    this.props.showModal('NOTE_MODAL', {
      orderId: this.props.orderId,
    });
  };

  handleEditNote = (noteId) => {
    const note = this.props.notes.find((note) => note.id === noteId);
    if (note) {
      this.props.showModal('NOTE_MODAL', {
        text: note.text,
        noteId: noteId,
      });
    }
  };

  handleDeleteNote = (noteId) => {
    this.props.deleteNoteOnOrder({
      noteId,
      orderId: this.props.orderId,
    });
  };

  handleResendClick = (e) => {
    e.preventDefault();
    const { customer, id } = this.props.order;
    this.props.showModal('ALERT_MODAL', {
      title: 'Resend Confirmation?',
      description: `The email confirmation will be sent to ${customer.email}`,
      onConfirmClick: () => this.props.sendOrderConfirmation({ orderId: id }),
    });
  };
  handleTransferClick = (e) => {
    e.preventDefault();
    this.props.showModal('TRANSFER_MODAL', {
      orderId: this.props.order.id,
    });
    // window.location = `/ticket/order/${this.props.order.id}`;
  };
  handelEditReceiptClick = (e) => {
    e.preventDefault();
    this.props.showModal('EDIT_RECEIPT_MODAL', {
      onSubmitSuccess: () =>
        this.props.fetchOrder({ orderId: this.props.orderId }),
    });
  };
  handleRefundDelete = (refundId) => {
    this.props.deleteRefund({
      refundId,
      orderId: this.props.orderId,
    });
  };
  handleDownloadTicketClick = () => {
    this.props.exportTicketPDF({
      id: this.props.orderId,
      fileName: `ticket-order-${this.props.order.reference}`,
    });
  };
  handleReceiptRedeemClick = (receipt, lineItemType) => {
    this.props.receiptRedeem({
      receipt: receipt,
      lineItemType: lineItemType,
      orderId: this.props.orderId
    });
  };
  render() {
    const {
      order,
      selectedReceipts,
      notes,
      lineItems,
      selectedItemsCount,
      error,
      totalOutsideFee,
      formResponses,
      readOnly,
      isLoading,
    } = this.props;
    if (!order || !order.invoice) {
      return null;
    }
    const { customer, invoice, seller, refunds, vouchers } = order ? order : {};
    const totalRefunded = refunds.reduce((total, r) => total + r.total, 0);
    const totalFeeRefunded = refunds.reduce((total, r) => total + r.fee, 0);

    const orderIsFullyRefunded =
      totalRefunded === order.total && order.total > 0;
    const holders =
      lineItems && lineItems.tickets && lineItems.tickets.length > 0
        ? _.uniq(
            _.flatMap(lineItems.tickets, (item) => {
              return _.map(
                item.receipts,
                (receipt) => ` ${receipt.firstName} ${receipt.lastName}`
              );
            })
          )
        : [];
    const timeline = [];
    timeline.push({
      text: `Order ${order.status}`,
      date: order.createdAt,
      icon:
        order.status !== 'confirmed' ? (
          <StatusGood size='small' />
        ) : (
          <PauseFill size='small' />
        ),
    });
    if (vouchers) {
      vouchers.forEach((voucher) => {
        timeline.push({
          titleComponent: (
            <Fragment>
              <Link
                to={`/discount/${voucher.discountId}`}
                style={{ textDecoration: 'none' }}
                target='_blank'
                rel='noopener noreferrrer'
              >
                <Text size='12px' color='brand'>
                  Voucher Payment for:{' '}
                  <Price
                    amount={voucher.total}
                    currency={order.currency}
                    showZero
                  />
                </Text>
              </Link>
            </Fragment>
          ),
          date: voucher.createdAt,
          icon: <Tag size='small' />,
        });
      });
    }
    notes.forEach((note) => {
      timeline.push({
        text: note.text,
        date: note.createdAt,
        user: note.createdBy.displayname,
        icon: <Note size='small' />,
        onEdit: this.handleEditNote,
        onDelete: this.handleDeleteNote,
        id: note.id,
      });
    });
    refunds.forEach((refund) => {
      timeline.push({
        titleComponent: (
          <Fragment>
            Refund{' '}
            {refund.voucher ? (
              <Link
                to={`/discount/${refund.voucher.id}`}
                style={{ textDecoration: 'none' }}
                target='_blank'
                rel='noopener noreferrrer'
              >
                <Text size='12px' color='brand'>
                  (voucher):{' '}
                </Text>
              </Link>
            ) : (
              ':  '
            )}
            <Price amount={refund.total} currency={order.currency} showZero />
          </Fragment>
        ),
        text: refund.note,
        date: refund.createdAt,
        user: refund.createdBy.displayname,
        icon: <Undo size='small' />,
        id: refund.id,
        onDelete: refund.voucher && this.handleRefundDelete,
      });
    });
    if (isLoading) {
      return (
        <MainContent>
          <Spinner
            name='three-bounce'
            color='rgba(57,155,162,1)'
            fade='none'
            className='loading-spinner'
          />
        </MainContent>
      );
    }
    return (
      <>
        <LineUpHelmet
          title={customer.displayname}
          description='Manage your order in Line-Up'
        />
        <ResponsiveContext.Consumer>
          {(size) => (
            <MainContent>
              <div>
                <Box direction='column' gap='xsmall'>
                  <ContentType
                    icon={<CreditCard size='small' />}
                    label='Order'
                  />
                  <Title>
                    <Fragment>
                      <Price
                        amount={order.total}
                        currency={order.currency}
                        showZero
                      />
                    </Fragment>
                  </Title>

                  <Text>
                    <Link
                      to={`/customer/${customer.id}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <Heading
                        level='4'
                        size='xsmall'
                        margin='none'
                        color='brand'
                      >
                        {customer.displayname} - {customer.email}
                      </Heading>
                    </Link>
                  </Text>
                  <Text color='copy-light' size='small'>
                    {order.reference}
                  </Text>
                  {holders.length > 0 && (
                    <Text color='copy-light' size='small'>
                      Ticket Holder
                      {holders.length > 1 ? 's' : ''}: {holders.toString()}
                    </Text>
                  )}
                </Box>
              </div>
              <Box margin={{ bottom: 'medium' }}>
                <ErrorMessage error={error} />
                <ContentContainer>
                  <div>
                    <Box>
                      <TimeLine items={timeline} />
                    </Box>
                  </div>
                  <div>
                    {!readOnly && (
                      <Box
                        margin={{ top: 'medium' }}
                        pad='medium'
                        background='infobox'
                      >
                        <MultiActionContainer>
                          <Button
                            secondary
                            onClick={this.handleAddNoteClick}
                            size='small'
                            label='Add Note'
                            icon={<Note size='small' />}
                            fill={size !== 'small' ? false : true}
                          />
                          <Button
                            secondary
                            onClick={this.handleDownloadTicketClick}
                            size='small'
                            label='Download Tickets'
                            icon={<Download size='small' />}
                            fill={size !== 'small' ? false : true}
                          />
                          <Button
                            secondary
                            onClick={this.handleResendClick}
                            size='small'
                            label='Resend Confirmation'
                            icon={<Send size='small' />}
                            fill={size !== 'small' ? false : true}
                          />
                          <Button
                            secondary
                            disabled={orderIsFullyRefunded}
                            onClick={this.handleRefundAllClick}
                            size='small'
                            label='Refund Order'
                            icon={<Revert size='small' />}
                            fill={size !== 'small' ? false : true}
                          />
                          {order.ticketPurchases &&
                            order.ticketPurchases.length > 0 && (
                              <Button
                                secondary
                                onClick={this.handleTransferClick}
                                size='small'
                                label='Exchange Tickets'
                                icon={<Transaction size='small' />}
                                fill={size !== 'small' ? false : true}
                              />
                            )}
                        </MultiActionContainer>
                      </Box>
                    )}
                  </div>
                  <Box margin={{ top: 'medium' }}>
                    <SubTitle title={'Order Details'} />
                  </Box>
                  <InfoBlock>
                    <InfoBlockList>
                      <InfoBlockListItem
                        attribute='Date'
                        value={
                          <FormattedDate
                            value={order.createdAt}
                            weekday='short'
                            year='numeric'
                            day='numeric'
                            month='short'
                            hour='numeric'
                            minute='numeric'
                          />
                        }
                      />
                      <InfoBlockListItem
                        attribute='Sales Channel'
                        value={seller.company}
                      />
                      <InfoBlockListItem
                        attribute='Payment Source'
                        value={invoice.paymentSource}
                      />
                      <InfoBlockListItem
                        attribute='Face Value'
                        value={
                          <Price
                            amount={order.total - totalOutsideFee}
                            currency={order.currency}
                            showZero
                          />
                        }
                      />
                      {totalOutsideFee > 0 && (
                        <InfoBlockListItem
                          attribute='Booking Fee'
                          value={
                            <Price
                              amount={totalOutsideFee}
                              currency={order.currency}
                            />
                          }
                        />
                      )}
                      {totalRefunded > 0 && (
                        <InfoBlockListItem
                          attribute='Refunded'
                          value={
                            <Price
                              amount={totalRefunded}
                              currency={order.currency}
                              showFree
                            />
                          }
                        />
                      )}
                      <InfoBlockListItem
                        attribute='Line-Up Fee'
                        value={
                          <Price
                            amount={order.fee - totalFeeRefunded}
                            currency={order.currency}
                            showZero
                          />
                        }
                      />
                      {order.paymentFee > 0 && (
                        <InfoBlockListItem
                          attribute='Payment Fee'
                          value={
                            <Price
                              amount={order.paymentFee}
                              currency={order.currency}
                            />
                          }
                        />
                      )}
                      <InfoBlockListItem
                        attribute='Net'
                        value={
                          <Price
                            amount={
                              order.total -
                              totalRefunded -
                              order.paymentFee -
                              order.fee +
                              totalFeeRefunded
                            }
                            currency={order.currency}
                            showZero
                          />
                        }
                      />
                    </InfoBlockList>
                  </InfoBlock>
                  <Box>
                    <LineItemsTable
                      title={'Purchases'}
                      onRefundClick={this.handelRefundSelectedClick}
                      onEditClick={this.handelEditReceiptClick}
                      lineItems={lineItems}
                      selectedItemsCount={selectedItemsCount}
                      onReceiptClick={this.handleReceiptClick}
                      selectedReceipts={selectedReceipts}
                      orderIsFullyRefunded={orderIsFullyRefunded}
                      onReceiptRedeemClick={this.handleReceiptRedeemClick}
                      readOnly={readOnly}
                    />
                  </Box>
                  {formResponses && formResponses.length > 0 ? (
                    <>
                      <SubTitle title={formResponses[0].name} />
                      <InfoBlock>
                        <InfoBlockList>
                          {formResponses[0].items.map((item) => (
                            <InfoBlockListItem
                              attribute={item.schema.label}
                              value={item.value}
                            />
                          ))}
                        </InfoBlockList>
                      </InfoBlock>
                    </>
                  ) : null}
                </ContentContainer>
              </Box>
            </MainContent>
          )}
        </ResponsiveContext.Consumer>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const orderId = ownProps.orderId
    ? ownProps.orderId
    : parseInt(ownProps.match.params.orderId, 10);
  const order = orderSelectors.getOrder(state, orderId);

  return {
    order,
    orderId,
    selectedItemsCount: orderSelectors.getSelectedReceiptsCount(state),
    lineItems: orderSelectors.getLineItemsForOrder(state, orderId),
    selectedReceipts: orderSelectors.getSelectedReceipts(state),
    notes: orderSelectors.getNotesForOrder(state, orderId),
    error: orderSelectors.getRefundError(state),
    totalOutsideFee: orderSelectors.getTotalOutsideFee(state, orderId),
    formResponses: orderSelectors.getFormResponses(state),
    isLoading: orderSelectors.getOrderLoadingStatus(state),
  };
};

export default connect(mapStateToProps, {
  ...orderActions,
  showModal,
})(OrderDetail);
