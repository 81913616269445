import React, { useState } from 'react';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import classNames from 'classnames';

import { formatDate, parseDate } from 'react-day-picker/moment';

const FormFieldDateTime = ({
  input,
  hideLabel,
  label,
  dateLabel,
  timeLabel,
  placeholder,
  inline,
  className,
  from,
  to,
  meta: { touched, error, warning }
}) => {
  const rowClass = classNames(className, 'form__row', {
    'form__row--inline': inline
  });
  const errorClass = classNames(className, {
    form__error: !inline,
    'form__error--inline': inline
  });
  const labelClass = classNames('form__label', {
    'form__label--inline': inline
  });
  const [selectedTime, setSelectedTime] = useState(null);

  const handleOnTimeChange = value => {
    setSelectedTime(value);
    return moment.isMoment(value) ? input.onChange(value.toDate()) : null;
  };
  const handleOnDateChange = value => {
    let newDate;
    if (selectedTime) {
      newDate = Date.UTC(
        value.getUTCFullYear(),
        value.getUTCMonth(),
        value.getUTCDate(),
        selectedTime.hour(),
        selectedTime.minute(),
        0
      );
    } else {
      newDate = Date.UTC(
        value.getUTCFullYear(),
        value.getUTCMonth(),
        value.getUTCDate(),
        0,
        0,
        0
      );
    }
    input.onChange(new Date(newDate));
  };
  return (
    <div className={rowClass}>
      <label className={labelClass} hidden={hideLabel}>
        {dateLabel || label}
      </label>
      <DayPickerInput
        inputProps={{
          ...input,
          autoComplete: 'off',
          onBlur: () => null
        }}
        formatDate={formatDate}
        value={input.value}
        parseDate={parseDate}
        format="DD-MM-YYYY"
        placeholder="DD-MM-YYYY"
        onDayChange={handleOnDateChange}
        dayPickerProps={{
          selectedDays: input.value,
          disabledDays: { before: from, after: to }
        }}
      />
      {timeLabel && (
        <label className={labelClass} hidden={hideLabel}>
          {timeLabel}
        </label>
      )}
      <TimePicker
        showSecond={false}
        id={input.name}
        value={input.value ? moment.utc(input.value) : null}
        minuteStep={5}
        focusOnOpen={true}
        className={input.className}
        onChange={handleOnTimeChange}
        allowEmpty={false}
        placeholder={'HH:MM'}
        popupClassName="time-picker-popup"
      />
      {touched &&
        ((error && <div className={errorClass}>{error}</div>) ||
          (warning && <div className={errorClass}>{warning}</div>))}
    </div>
  );
};
export default FormFieldDateTime;
