import React from 'react';
import { FormattedDate, FormattedNumber } from 'react-intl';
import moment from 'moment';
/* eslint-disable react/style-prop-object */
const AllPackageSubscription = ({ subscription }) => {
  const currentPeriodEndAt = moment(subscription.currentPeriodEndAt).toDate();

  return (
    <div>
      <h1>Exposure + Engage + Insight</h1>
      <h2>
        <FormattedNumber
          value={subscription.calculatedPrice}
          style='currency'
          currency={subscription.package.currency}
        />{' '}
        per month
      </h2>
      <p>
        Next payment:{' '}
        <FormattedDate
          value={currentPeriodEndAt}
          year='numeric'
          day='numeric'
          month='short'
          weekday='short'
        />
      </p>
    </div>
  );
};

export default AllPackageSubscription;
