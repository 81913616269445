import React from 'react';
import { Price } from '../../Utils/Utils';
import './InvoiceItemsTable.css';

const InvoiceItemsTable = ({ invoice }) => {
  const { invoiceItems, currency } = invoice;
  return (
    <table className="invoice-items-table">
      <thead>
        <tr className="invoice-items-table__header">
          <th className="invoice-items-table__cell invoice-items-table__cell--text">
            Description
          </th>
          <th className="invoice-items-table__cell">Quantity</th>
          <th className="invoice-items-table__cell">Net</th>
          <th className="invoice-items-table__cell">VAT</th>
          <th className="invoice-items-table__cell">Gross</th>
        </tr>
      </thead>
      <tbody className="invoice-items-table__body">
        {invoiceItems.map(item => (
          <tr key={item.id} className="invoice-items-table__row">
            <td className="invoice-items-table__cell invoice-items-table__cell--text">
              {item.summary}
            </td>
            <td className="invoice-items-table__cell">{item.quantity}</td>
            <td className="invoice-items-table__cell">
              <Price amount={item.price} currency={currency} showZero />
            </td>
            <td className="invoice-items-table__cell">
              <Price amount={item.tax} currency={currency} showZero />
            </td>
            <td className="invoice-items-table__cell">
              <Price amount={item.total} currency={currency} showZero />
            </td>
          </tr>
        ))}
        <tr className="invoice-items-table__row-summary invoice-items-table__row-summary--subtotal">
          <td className="invoice-items-table__cell" colSpan="3" />
          <td
            className="invoice-items-table__cell invoice-items-table__cell--text invoice-items-table__cell--subtotal"
            colSpan="1"
          >
            Subtotal
          </td>
          <td className="invoice-items-table__cell invoice-items-table__cell--subtotal">
            <Price amount={invoice.price} currency={currency} showZero />
          </td>
        </tr>
        <tr className="invoice-items-table__row-summary invoice-items-table__row-summary--vat">
          <td className="invoice-items-table__cell" colSpan="3" />
          <td
            className="invoice-items-table__cell invoice-items-table__cell--text invoice-items-table__cell--vat"
            colSpan="1"
          >
            VAT
          </td>
          <td className="invoice-items-table__cell invoice-items-table__cell--vat">
            <Price amount={invoice.tax} currency={currency} showZero />
          </td>
        </tr>
        <tr className="invoice-items-table__row-summary invoice-items-table__row-summary--total">
          <td className="invoice-items-table__cell" colSpan="3" />
          <td
            className="invoice-items-table__cell invoice-items-table__cell--text invoice-items-table__cell--total"
            colSpan="1"
          >
            Amount Due
          </td>
          <td className="invoice-items-table__cell invoice-items-table__cell--total">
            <Price amount={invoice.total} currency={currency} showZero />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default InvoiceItemsTable;
