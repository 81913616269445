import React from 'react';
import { Formik } from 'formik';

import Form from 'components/Form/Form';

// This component wraps the custom <Form> component with <Formik> and passes the right variables
// from Formik into the Form component
const FormikForm = ({
  onSubmit,
  children,
  initialValues,
  validationSchema,
  ...props
}) => {
  // This adds a `setFormError` method which can be used it set the global
  // error message for the form
  const internalSubmitHandler = (values, formikBag) => {
    const customBag = {
      ...formikBag,
      setFormError: message => formikBag.setFieldError('_error', message)
    };
    return onSubmit(values, customBag);
  };
  return (
    <Formik
      onSubmit={internalSubmitHandler}
      initialValues={initialValues}
      validationSchema={validationSchema}
      {...props}
    >
      {({ handleSubmit, isSubmitting, dirty, errors }) => (
        <Form
          handleSubmit={handleSubmit}
          submitting={isSubmitting}
          pristine={!dirty}
          error={errors && errors._error ? errors._error : null}
          {...props}
        >
          {children}
        </Form>
      )}
    </Formik>
  );
};

export default FormikForm;
