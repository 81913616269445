import React from "react";
import { connect } from "react-redux";

import MainContent, { Title } from "components/MainContent/MainContent";
import AccountNav from "components/Account/AccountNav/AccountNav";
import { accountSelectors } from "modules/account/account";
import CardDetail from "components/Billing/CardDetail";

// Grommet
import { Box, Heading, Button, Text } from "grommet";
import { Add } from "grommet-icons";

const AccountBilling = ({ user, payment }) => {
  if (!payment) {
    return null;
  }

  return (
    <MainContent>
      <Title>Your Account</Title>
      <div>
        <Box margin={{ vertical: "medium" }}>
          <AccountNav />
        </Box>
      </div>
      <div>
        <Heading level="3" size="small" margin={{ top: "nobe" }}>
          Payment Cards
        </Heading>

        {!payment.hasPaymentMethod ? (
          <>
            <Text>Please add a payment card</Text>
            <p>
              <Button
                primary
                size="small"
                href={"/account/update-card"}
                icon={<Add size="small" />}
                label="Add New Payment Method"
              />
            </p>
          </>
        ) : (
          <>
            <CardDetail payment={payment} />
          </>
        )}
      </div>
    </MainContent>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.account.user.data,
    payment: accountSelectors.getPaymentDetails(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountBilling);
