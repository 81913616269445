import * as types from './types';
//- Reducers

const embedSettings = (
  state = {
    key: 1,
    data: {
      mainColor: '#399ba2',
      backgroundColor: '#fff',
      optInText: 'Add me to the mailing list'
    }
  },
  action
) => {
  switch (action.type) {
    case types.EMBED_SETTINGS.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.response
        }
      };
    case types.UPDATE.SUCCESS:
      return {
        data: {
          ...state.data,
          ...action.payload.response
        },
        key: state.key + 1
      };
    default:
      return state;
  }
};

export default embedSettings;
