import React from 'react';
import Spinner from 'react-spinkit';
const withEmptyState = WrappedComponent => ({
  isLoading,
  isEmpty,
  emptyStateComponent,
  ...passThroughProps
}) => {
  if (isLoading) {
    return (
      <Spinner
        name='three-bounce'
        color='rgba(57,155,162,1)'
        fade='none'
        className='loading-spinner'
      />
    );
  } else if (isEmpty) {
    return emptyStateComponent;
  }
  return <WrappedComponent {...passThroughProps} />;
};

export default withEmptyState;
