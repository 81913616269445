export const AUTHENTICATE_USER = 'line-up/authentication/authenticate-user';
export const LOGOUT_USER = 'line-up/authentication/logout-user';
export const RECOVER_AUTH = 'line-up/authentication/recover-auth';
export const GET_USER_FROM_TOKEN = 'line-up/authentication/get-user-from-token';
export const CREATE_USER = 'line-up/authentication/create-user';

export const UPDATE_USER = 'line-up/account/UPDATE_USER';
export const UPDATE_USER_PROFILE = 'line-up/account/UPDATE_USER_PROFILE';

export const GET_SUBSCRIPTION = 'line-up/account/GET_SUBSCRIPTION';
export const GET_PAYMENT_METHOD = 'line-up/account/GET_PAYMENT_METHOD';
export const CREATE_PAYMENT_METHOD = 'line-up/account/CREATE_PAYMENT_METHOD';
export const UPDATE_SUBSCRIPTION = 'linee-up/account/UPDATE_SUBSCRIPTION';

export const GET_STRIPE_SETTINGS = 'line-up/account/GET_STRIPE_SETTINGS';
export const CREATE_STRIPE_SETTINGS = 'line-up/account/CREATE_STRIPE_SETTINGS';
export const DELETE_STRIPE_SETTINGS = 'line-up/account/DELETE_STRIPE_SETTINGS';

export const FORGOT_PASSWORD = 'line-up/account/FORGOT_PASSWORD';
export const RESET_PASSWORD = 'line-up/account/RESET_PASSWORD';

export const CREATE_BUSINESS_SETTINGS =
  'line-up/account/CREATE_BUSINESS_SETTINGS';
export const GET_BUSINESS_SETTINGS = 'line-up/account/GET_BUSINESS_SETTINGS';
export const GET_CURRENCIES = 'line-up/account/GET_CURRENCIES';
