import React from 'react';
import Table, { TableCell, TableBody, TableHeader } from '../Table/Table';
import emptySeatingPlans from './empty_seating_plans.png';
import EmptyState from '../EmptyState/EmptyState';
import SeatingChartsTableRow from './SeatingChartsTableRow';

const SeatingChartsTable = ({
  seatingCharts,
  onSeatingChartEditClick,
  onSeatingChartManageClick,
  onSeatingChartDeleteClick
}) => {
  return seatingCharts.length == 0 ? (
    <EmptyState
      background
      buttonText='Create New Seating Chart'
      buttonLink='/tools/seating-charts/create'
      showButton={true}
      emptyStateHeadline='You have no Seating Charts yet'
      emptyStateDescription='Create Seating Charts for your events'
      emptyStateImageSrc={emptySeatingPlans}
      emptyStateImageAlt='No seating charts yet'
      emptyStateImageTitle='No seating charts yet'
    />
  ) : (
    <Table mobileWide>
      <TableHeader>
        <TableCell width={10}>ID</TableCell>
        <TableCell width={20}>Preview</TableCell>
        <TableCell width={30}>Name</TableCell>
        <TableCell width={40}>Actions</TableCell>
      </TableHeader>
      <TableBody
        renderItem={(seatingChart, index) => (
          <SeatingChartsTableRow
            seatingChart={seatingChart}
            key={index}
            onSeatingChartEditClick={onSeatingChartEditClick}
            onSeatingChartManageClick={onSeatingChartManageClick}
            onSeatingChartDeleteClick={onSeatingChartDeleteClick}
          />
        )}
        items={seatingCharts}
      />
    </Table>
  );
};

export default SeatingChartsTable;
