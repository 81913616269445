import React from 'react';
import styles from './VerticalSplit.module.css';

const VerticalSplit = ({ Left, Right }) => {
  return (
    <div className={styles.root}>
      <div className={styles.left}>{Left}</div>
      <div className={styles.right}>{Right}</div>
    </div>
  );
};

export default VerticalSplit;
