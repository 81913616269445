import React from "react";
import moment from "moment";
import {
  aggregatorTemplates as tpl,
  numberFormat,
  derivers,
} from "../../../components/PivotTable/Utilities";
import PivotTable from "../../../components/PivotTable/PivotTable";
import ReportSection from "../../../components/ReportSection/ReportSection";

const PerformanceDetail = ({ data, title, exportFilename }) => {
  const usFmtInt = numberFormat({ digitsAfterDecimal: 0 });
  const fmt = numberFormat({ prefix: "£" });
  const sortDates = (left, right) => {
    return moment
      .utc(left, "ddd, DD MMM YYYY HH:mm")
      .diff(moment.utc(right, "ddd, DD MMM YYYY HH:mm"));
  };
  return (
    <ReportSection>
      <PivotTable
        title={title}
        exportFilename={exportFilename}
        data={data}
        aggregators={{
          Status: () => tpl.listUnique(", ")(["Status"]),
          "Capacity Set": () => tpl.sum(usFmtInt)(["Capacity Set"]),
          "Qty Sold": () => tpl.sum(usFmtInt)(["Sold"]),
          Comps: () => tpl.sum(usFmtInt)(["Comp"]),
          Held: () => tpl.sum(usFmtInt)(["Held"]),
          "Capacity Left": () => tpl.sum(usFmtInt)(["Capacity Left"]),
          "Face Value (Gross)": () => tpl.sum(fmt)(["Face Value Gross"]),
          "Booking Fee (Gross)": () => tpl.sum(fmt)(["Booking Fee Gross"]),
          "Total (Gross)": () => tpl.sum(fmt)(["Gross"]),
        }}
        derivedAttributes={{
          "Performance Date": derivers.dateFormat(
            "Start Date Time",
            "%w, %d %n %y %H:%M",
            true
          ),
        }}
        rows={["Start Date", "Start Time"]}
        sorters={{
          "Performance Date": sortDates,
        }}
      />
    </ReportSection>
  );
};

export default PerformanceDetail;
