import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import EmptyState from 'components/EmptyState/EmptyState';
import emptyPlanner from './empty_planner.png';

const PlannerEmptyState = ({ event, dispatch }) => (
  <EmptyState
    background
    emptyStateHeadline='Create planner'
    emptyStateDescription='Create a calendar planner for your event to schedule your bookings. This is designed for events with multiple lanes, rooms or spaces, not for single-space, auditorium events with high capacities.'
    onButtonClick={() => dispatch(push(`/event/${event.id}/planner/create`))}
    showButton={true}
    buttonText={'Create planner'}
    emptyStateImageSrc={emptyPlanner}
    emptyStateImageAlt='No future performances'
    emptyStateImageTitle='No future performances'
  />
);

const mapStateToProps = (state, ownProps) => {};

export default connect(mapStateToProps)(PlannerEmptyState);
