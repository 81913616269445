import React, { useEffect } from 'react';

// Redux
import { connect } from 'react-redux';
// Actions
import { showModal } from 'modules/modal/modal';

// Components
import { SubTitle, PageTop } from '../../../components/MainContent/MainContent';

// Grommet
import { Box, Button } from 'grommet';
import { Add } from 'grommet-icons';

import { eventActions, eventSelectors } from '../../../modules/events/events';
import UserPermissionTable from '../../../components/UserPermissionTable/UserPermissionTable';
import { bindActionCreators } from 'redux';

const UserManagement = ({
  showModal,
  eventId,
  invitations,
  permissions,
  getEventInvitations,
  getEventPermissions,
  deleteEventPermission,
  deleteEventInvitation,
  updateEventInvitation,
}) => {
  const handleEventPermissionDelete = (values) => {
    deleteEventPermission({ userId: values.userId, eventId: eventId });
  };
  const handleEventInvitationDelete = (values) => {
    deleteEventInvitation({ inviteId: values.id, eventId: eventId });
  };
  const handleEventInvitationResend = (values) => {
    updateEventInvitation({ inviteId: values.id, eventId: eventId });
  };
  const handleAddUserClick = () => {
    showModal('ADD_USER_MODAL', { eventId: eventId });
  };
  useEffect(() => {
    getEventInvitations({ eventId: eventId });
  }, [getEventInvitations, eventId]);
  useEffect(() => {
    getEventPermissions({ eventId: eventId });
  }, [getEventPermissions, eventId]);
  return (
    <>
      <PageTop>
        <SubTitle title='User Management'>
          <Button
            onClick={handleAddUserClick}
            secondary={true}
            size='small'
            icon={<Add size='small' />}
            label='Add Users'
          />
        </SubTitle>
      </PageTop>
      <Box margin={{ vertical: 'medium' }}>
        <UserPermissionTable
          onEventPermissionDelete={handleEventPermissionDelete}
          onEventInvitationDelete={handleEventInvitationDelete}
          onEventInvitationResend={handleEventInvitationResend}
          permissions={permissions}
          pendingInvitations={invitations}
        />
      </Box>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const eventId = parseInt(ownProps.match.params.eventId, 10);
  const invitations = eventSelectors.getEventInvitations(state);
  const permissions = eventSelectors.getEventPermissions(state);
  return {
    eventId,
    invitations,
    permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        ...eventActions,
        showModal,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
