import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  seatingChartActions,
  seatingChartSelectors
} from 'modules/seatingCharts/seatingCharts';
import { SeatsioEventManager } from '@seatsio/seatsio-react';
import styled from 'styled-components';

const ChartManagerContainer = styled.div`
  border: 1px solid #ccc;
  height: 1600px;
  width: 100%;
  border-radius: 4px;
`;

class ManageSeatingChart extends Component {
  componentDidMount() {
    this.props.getSeatingUser();
  }

  render() {
    return (
      <ChartManagerContainer>
        {this.props.seatingUser && (
          <SeatsioEventManager
            secretKey={this.props.seatingUser.secretKey}
            event={`${this.props.performanceId}`}
            mode='manageForSaleConfig'
            fitTo='width'
            id='chart'
          />
        )}
      </ChartManagerContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const performanceId = parseInt(ownProps.match.params.performanceId, 10);
  const seatingUser = seatingChartSelectors.getSeatingUser(state);
  return {
    performanceId,
    seatingUser
  };
};

export default connect(mapStateToProps, {
  ...seatingChartActions
})(ManageSeatingChart);
