import React from 'react';
import LoadingBar from 'react-redux-loading-bar';
import { ToastContainer } from 'react-toastify';

import { Route, Switch, Redirect } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import DiscountCreate from './containers/discount/DiscountCreate';
import DiscountDetail from './containers/discount/DiscountDetail';
import DiscountList from './containers/discount/DiscountList';

import DonationList from './containers/donation/DonationList';

import TicketGroupList from './containers/ticketGroup/TicketGroupList';
import TicketGroupDetail from './containers/ticketGroup/TicketGroupDetail';
import TicketGroupTickets from './containers/ticketGroup/TicketGroupTickets';

import Users from './containers/user/Users';
import UserDetail from './containers/user/UserDetail';
import UserEdit from './containers/user/UserEdit';

import Orders from './containers/order/Orders';

import ToolsList from './containers/tools/ToolsList';

import AgentCreate from './containers/agent/AgentCreate';
import AgentList from './containers/agent/AgentList';
import AgentDetail from './containers/agent/AgentDetail';

import ModalRoot from './containers/modal/ModalRoot';

import Events from './containers/event/Events';
import Event from './containers/event/Event';
import EventCreate from './containers/event/EventCreate';
import EventInviteComplete from './containers/event/EventInviteComplete';

import Reports from './containers/report/Reports';
// import BaseReport from "./containers/report/BaseReport";

import Report from './containers/report/Report';

import InvoiceList from './containers/invoice/InvoiceList';
import InvoiceDetail from './containers/invoice/InvoiceDetail';

import ProductList from './containers/product/ProductList';
import Product from './containers/product/Product';
import ProductCreate from './containers/product/ProductCreate';
// import ProductEmbedSettings from './containers/product/ProductEmbedSettings/ProductEmbedSettings';

import OrderDetail from './containers/order/OrderDetail';
import FormList from './containers/form/FormList';
import EmailList from './containers/email/EmailList';
import EmailEdit from './containers/email/EmailEdit';
import EmailCreate from './containers/email/EmailCreate';

import MailchimpManage from './containers/mailchimp/MailchimpManage';
import MailchimpComplete from './containers/mailchimp/MailchimpComplete';

import APIKeys from './containers/tools/APIKeys';
import SeatingChartList from './containers/tools/SeatingChartList';
import SeatingChartCreate from './containers/tools/SeatingChartCreate';
import SeatingChartEdit from './containers/tools/SeatingChartEdit';
import SeatingChartManage from './containers/tools/SeatingChartManage';
import Webhooks from './containers/tools/Webhooks/Webhooks';
import WebhookCreate from './containers/tools/Webhooks/WebhookCreate';

import AuthenticationGuard from './containers/auth/AuthenticationGuard';
import UserLogin from './containers/user/UserLogin';
import UserSignup from 'containers/user/UserSignup';
import UserForgotPassword from 'containers/user/UserForgotPassword';
import UserResetPassword from 'containers/user/UserResetPassword';

import PublicEvent from 'containers/publicEvent/PublicEvent';

import AccountDetails from 'containers/account/AccountDetails';
import AccountProfile from 'containers/account/AccountProfile';
import AccountPlan from 'containers/account/AccountPlan';
import AccountChangePlan from 'containers/account/AccountChangePlan';
import AccountCreatePaymentMethod from 'containers/account/AccountCreatePaymentMethod';
import AccountConfirmSubscription from 'containers/account/AccountConfirmSubscription';
import AccountTicketSales from 'containers/account/AccountTicketSales';
import AccountBilling from 'containers/account/AccountBilling';
import AccountInvoices from 'containers/account/AccountInvoices';
import AccountNotifications from 'containers/account/AccountNotifications';
import StripeOnboardingComplete from 'containers/account/StripeOnboardingComplete';

import DefaultLayout from 'containers/layout/DefaultLayout';
import PageNotFound from 'containers/error/404';

import { library } from '@fortawesome/fontawesome-svg-core';

import { Grommet } from 'grommet';
import { theme } from './theme';

import './App.css';

import {
  faQuoteRight,
  faHeading,
  faListOl,
  faListUl,
  faCode,
  faBold,
  faUnderline,
  faItalic,
  faMinus,
  faPlus,
  faTrash,
  faSignal,
  faChartBar,
  faChartLine,
  faChartArea,
  faDollarSign,
  faChartPie,
  faTag,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faUsers,
  faUser,
  faUserSecret,
  faCopy,
  faTimes,
  faExternalLinkAlt,
  faCalendarWeek,
  faTicketAlt,
  faCheckCircle,
  faTimesCircle,
  faCreditCard,
  faBookmark,
  faLink,
  faBan,
  faUndo,
  faCheck,
  faCalendarDay,
  faMoneyBillAlt,
  faFileCsv,
  faInfoCircle,
  faLock,
  faMinusCircle,
  faRegistered,
  faAlignLeft,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faQuoteRight,
  faHeading,
  faListOl,
  faListUl,
  faCode,
  faBold,
  faUnderline,
  faItalic,
  faMinus,
  faPlus,
  faTrash,
  faChartBar,
  faChartLine,
  faChartArea,
  faDollarSign,
  faChartPie,
  faTag,
  faSignal,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faUsers,
  faUser,
  faUserSecret,
  faCopy,
  faTimes,
  faExternalLinkAlt,
  faCalendarWeek,
  faTicketAlt,
  faCheckCircle,
  faTimesCircle,
  faCreditCard,
  faBookmark,
  faLink,
  faBan,
  faUndo,
  faCheck,
  faCalendarDay,
  faMoneyBillAlt,
  faFileCsv,
  faInfoCircle,
  faLock,
  faMinusCircle,
  faRegistered,
  faAlignLeft
);

const AuthenticatedRoutes = () => (
  <AuthenticationGuard>
    <DefaultLayout>
      <Switch>
        <Route exact path='/'>
          <Redirect to='/events' />
        </Route>
        <Route path='/discounts/:page(\d+)' component={DiscountList} />
        <Route exact path='/discount/create' component={DiscountCreate} />
        <Route
          exact
          path='/discount/edit/:discountId(\d+)'
          component={DiscountCreate}
        />
        <Route
          exact
          path='/discount/:discountId(\d+)'
          component={DiscountDetail}
        />
        <Route exact path='/donation/:page(\d+)' component={DonationList} />

        <Route
          exact
          path='/ticket-groups/:page(\d+)/'
          component={TicketGroupList}
        />
        <Route
          exact
          path='/ticket-group/:ticketGroupId(\d+)/'
          component={TicketGroupDetail}
        />
        <Route
          exact
          path='/ticket-group/:ticketGroupId(\d+)/tickets'
          component={TicketGroupTickets}
        />
        <Route exact path='/customers/:page(\d+)/' component={Users} />
        <Route exact path='/customer/:userId(\d+)/' component={UserDetail} />
        <Route exact path='/customer/edit/:userId(\d+)/' component={UserEdit} />
        <Route exact path='/orders/:page(\d+)/' component={Orders} />
        <Route exact path='/order/:orderId(\d+)/' component={OrderDetail} />

        <Route exact path='/tools/' component={ToolsList} />
        <Route exact path='/tools/api-keys' component={APIKeys} />
        <Route
          exact
          path='/tools/seating-charts'
          component={SeatingChartList}
        />
        <Route
          exact
          path='/tools/seating-charts/create'
          component={SeatingChartCreate}
        />
        <Route
          exact
          path='/tools/seating-charts/edit/:chartKey/:hasDraft'
          component={SeatingChartEdit}
        />
        <Route
          exact
          path='/tools/seating-charts/manage/:chartKey/'
          component={SeatingChartManage}
        />
        <Route path='/tools/webhooks/:page(\d+)' component={Webhooks} />
        <Route
          exact
          path={['/tools/webhook', '/tools/webhook/:webhookId(\\d+)']}
          component={WebhookCreate}
        />
        <Route exact path='/agents/:page(\d+)/' component={AgentList} />

        <Route exact path='/agent/:agentId(\d+)/' component={AgentDetail} />
        <Route exact path='/agent/create/' component={AgentCreate} />

        <Route
          exact
          path='/agent/edit/:agentId(\d+)/'
          component={AgentCreate}
        />
        <Route exact path='/reports/' component={Reports} />
        <Route exact path='/report/:reportName/' component={Report} />
        <Route
          exact
          path='/report/:reportName/:eventId(\d+)/'
          component={Report}
        />
        <Route exact path='/products/:page(\d+)/' component={ProductList} />
        <Route path='/product/:productId(\d+)' component={Product} />
        <Route exact path='/product/create/' component={ProductCreate} />
        <Route
          exact
          path='/product/edit/:productId(\d+)/'
          component={ProductCreate}
        />
        <Route exact path='/product/create/' component={ProductCreate} />
        <Route
          exact
          path={['/events/:page(\\d+)', '/events']}
          component={Events}
        />
        <Route exact path='/event/new/create' component={EventCreate} />
        <Route path='/event/:eventId(\d+)' component={Event} />
        <Route
          path={['/invoices/:page(\\d+)', '/invoices']}
          component={InvoiceList}
        />
        <Route path={'/invoice/:invoiceId(\\d+)'} component={InvoiceDetail} />
        <Route
          exact
          path={['/forms', '/forms/:page(\\d+)']}
          component={FormList}
        />
        <Route exact path='/mailchimp' component={MailchimpManage} />
        <Route exact path='/mailchimp/complete' component={MailchimpComplete} />

        <Route exact path={'/email/create'} component={EmailCreate} />
        <Route
          exact
          path={['/emails', '/emails/:page(\\d+)']}
          component={EmailList}
        />
        <Route
          exact
          path={['/email', '/email/:emailId(\\d+)']}
          component={EmailEdit}
        />
        <Route exact path='/account' component={AccountDetails} />
        <Route exact path='/account/profile' component={AccountProfile} />
        <Route exact path='/account/plan' component={AccountPlan} />
        <Route
          exact
          path='/account/change-plan'
          component={AccountChangePlan}
        />
        <Route
          exact
          path='/account/create-payment-method'
          component={AccountCreatePaymentMethod}
        />
        <Route
          exact
          path='/account/confirm-subscription'
          component={AccountConfirmSubscription}
        />
        <Route
          exact
          path='/account/ticket-sales'
          component={AccountTicketSales}
        />
        <Route exact path='/account/invoices' component={AccountInvoices} />
        <Route exact path='/account/billing' component={AccountBilling} />
        <Route
          exact
          path='/account/notifications'
          component={AccountNotifications}
        />
        <Route
          exact
          path='/account/stripe/connect/finish'
          component={StripeOnboardingComplete}
        />
        <Route
          exact
          path='/invite/:inviteId/:token'
          component={EventInviteComplete}
        />
        <Route path='*' component={PageNotFound} />
      </Switch>
    </DefaultLayout>
  </AuthenticationGuard>
);

const App = ({ store, history }) => {
  var language =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage;

  return (
    <Provider store={store}>
      <IntlProvider locale={'en-US'}>
        <ConnectedRouter history={history}>
          <Grommet theme={theme}>
            <LoadingBar className='loading-bar' />
            <ModalRoot />
            <ToastContainer />
            <Switch>
              <Route exact path='/user/login' component={UserLogin} />
              <Route
                exact
                path='/reset-password/:token'
                component={UserResetPassword}
              />
              <Route
                exact
                path='/user/forgot-password'
                component={UserForgotPassword}
              />
              <Route exact path='/user/signup' component={UserSignup} />
              <Route exact path='/event/:eventSlug' component={PublicEvent} />
              <Route path='/' component={AuthenticatedRoutes} />
            </Switch>
          </Grommet>
        </ConnectedRouter>
      </IntlProvider>
    </Provider>
  );
};

export default App;
