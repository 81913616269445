import React from 'react';
import { TableCell, TableRow } from '../../components/Table/Table';
import './TicketReceiptRow.css';

// Grommet
import { Box, ResponsiveContext, Text, Button } from 'grommet';
import { Checkmark, Login, Tag } from 'grommet-icons';

const TicketReceiptRow = ({
  receipt,
  onRedeemClick,
  showSeats,
  isLoading,
  onReceiptClick
}) => {
  const handleRedeemClick = evt => {
    evt.preventDefault();
    evt.stopPropagation();
    onRedeemClick(receipt);
  };
  const handleReceiptClick = () => {
    onReceiptClick(receipt.orderId);
  };
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          {size !== 'small' ? (
            <TableRow striped={true} onClick={handleReceiptClick}>
              <TableCell
                texttop
              >{`${receipt.firstName} ${receipt.lastName}`}</TableCell>
              <TableCell texttop>{receipt.emailAddress}</TableCell>
              <TableCell texttop>{receipt.reference}</TableCell>
              {showSeats && <TableCell texttop>{receipt.seat}</TableCell>}
              <TableCell texttop>
                <span>{receipt.ticketDescription}</span>
                {receipt.discountCode && (
                  <span key={receipt.discountCode}>
                    <Box
                      margin={{ vertical: 'xsmall' }}
                      align='center'
                      direction='row'
                      gap='xsmall'
                      width='auto'
                    >
                      <Tag size='small' />
                      <Text size='xsmall'>{receipt.discountCode}</Text>
                    </Box>
                  </span>
                )}
              </TableCell>
              <TableCell>
                {!receipt.isRedeemed ? (
                  <Button
                    secondary
                    onClick={handleRedeemClick}
                    label='Check In'
                    size='small'
                    icon={<Login size='small' />}
                    fill
                  />
                ) : (
                  <Button
                    secondary
                    color='checkedin'
                    onClick={handleRedeemClick}
                    label='Checked In'
                    size='small'
                    icon={<Checkmark size='small' />}
                    fill
                  />
                )}
              </TableCell>
            </TableRow>
          ) : (
            <TableRow striped={true} onClick={handleReceiptClick}>
              <Box pad='small'>
                <TableCell>
                  <Box direction='column'>
                    <Text size='small'>{`${receipt.firstName} ${receipt.lastName}`}</Text>
                    <Text size='xsmall'>{receipt.emailAddress}</Text>
                    <Text size='xsmall'>{receipt.reference}</Text>
                    {showSeats && <Text>{receipt.seat}</Text>}
                    <Text size='xsmall'>{receipt.ticketDescription}</Text>
                    {receipt.discountCode && (
                      <span key={receipt.discountCode}>
                        <Box
                          color='heading'
                          background='infobox'
                          pad='small'
                          round='xsmall'
                          margin={{ vertical: 'xsmall' }}
                          align='center'
                          direction='row'
                          gap='xsmall'
                        >
                          <Tag size='small' />
                          <Text size='xsmall'>{receipt.discountCode}</Text>
                        </Box>
                      </span>
                    )}
                    <Box margin={{ top: 'medium' }}>
                      {!receipt.isRedeemed ? (
                        <Button
                          secondary
                          onClick={handleRedeemClick}
                          label='Check In'
                          size='small'
                          icon={<Login size='small' />}
                        />
                      ) : (
                        <Button
                          secondary
                          onClick={handleRedeemClick}
                          color='checkedin'
                          label='Checked In'
                          size='small'
                          icon={<Checkmark size='small' />}
                        />
                      )}
                    </Box>
                  </Box>
                </TableCell>
              </Box>
            </TableRow>
          )}
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default TicketReceiptRow;
