import * as fromPaginator from '../shared/paginate';
import * as fromEntities from '../shared/entities';

//- Selectors

export const getWaitingListLoadingStatus = state => state.waitingList.loading;

export const getWaitingListForPage = (state, page, perPage = 2) => {
  const ids = fromPaginator.getIdsForPage(state.waitingList.byPage, page);
  return ids.map(id =>
    fromEntities.getEntity(state.entities.waitingListUser, id)
  );
};

export const getWaitingListCurrentPage = state =>
  fromPaginator.getCurrentPage(state.waitingList.byPage);

export const getWaitingListTotalPages = state =>
  fromPaginator.getTotalPages(state.waitingList.byPage);
