import * as types from './types';
import { createAction } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

export const fetchPaymentReport = createRoutine(types.FETCH_PAYMENT_REPORT);
export const fetchTicketData = createRoutine(types.FETCH_TICKET_DATA);
export const fetchEvents = createRoutine(types.FETCH_EVENTS);
export const exportSalesReportPDF = createRoutine(
  types.EXPORT_SALES_REPORT_PDF
);

export const selectEvent = createAction(types.SELECT_EVENT);
export const setDateRange = createAction(types.SET_DATE_RANGE);

export const addFilter = createAction(types.ADD_FILTER);
export const updateFilter = createAction(types.UPDATE_FILTER);
export const removeFilter = createAction(types.REMOVE_FILTER);
export const setFilters = createAction(types.SET_FILTERS);
