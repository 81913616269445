import React from 'react';
import { connect } from 'react-redux';
import { Field, FormSection, reduxForm } from 'redux-form';

import FormField from '../Form/FormField/FormField';
import FormFieldCountrySelect from '../Form/FormFieldCountrySelect/FormFieldCountrySelect';

import Form from '../Form/Form';
import { userSelectors } from '../../modules/users/users';

const validate = values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'First name is required';
  }
  if (!values.lastName) {
    errors.lastName = 'Last name is required';
  }
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.password) {
    errors.password = 'Password is required';
  } else if (values.password.length < 6) {
    errors.password = 'Your password needs to be at least 6 characters long';
  }
  return errors;
};

const phoneNumber = value =>
  value && !/^([+]|[0-9])([0-9]{8,15})$/i.test(value)
    ? 'This phone number does not appear to be vaild'
    : undefined;

let UserForm = props => {
  const { handleSubmit, error, reset, country } = props;
  return (
    <Form
      {...props}
      submitText="Update"
      cancelText="Reset"
      handleSubmit={handleSubmit}
      error={error}
      onCancel={reset}
      guidanceNotes="Use the form below to edit the customer's details"
    >
      <Field
        name="firstName"
        label="First Name"
        component={FormField}
        type="text"
        placeholder="Sherlock"
      />
      <Field
        name="lastName"
        label="Last Name"
        component={FormField}
        type="text"
        placeholder="Holmes"
      />
      <Field
        name="email"
        label="Email Address"
        component={FormField}
        type="email"
        placeholder="j.watson@221b.com"
      />
      <Field
        name="telephone"
        component={FormField}
        type="tel"
        label="Phone Number"
        placeholder="0207 525 1666"
        validate={[phoneNumber]}
      />
      <div className="form__section-header">
        <h5 className="form__section-header-title">Address</h5>
      </div>
      <FormSection name="address">
        <Field
          name="street"
          component={FormField}
          type="text"
          label="Street"
          placeholder="221b Baker Street"
        />
        <Field
          name="region"
          component={FormField}
          type="text"
          label="City"
          placeholder="London"
        />
        {country === 'US' && (
          <Field
            name="county"
            component={FormField}
            type="text"
            label="State"
            placeholder="NY"
          />
        )}
        <Field
          name="postcode"
          component={FormField}
          type="text"
          label={country === 'US' ? 'ZIP' : 'Post Code'}
          placeholder="NW1 6XE"
        />
        <Field
          name="country"
          component={FormFieldCountrySelect}
          label="Country"
        />
      </FormSection>
    </Form>
  );
};

UserForm = reduxForm({
  form: 'user',
  validate
})(UserForm);

const mapStateToProps = (state, ownProps) => {
  const userId = ownProps.userId;
  const user = userSelectors.getUser(state, userId);
  let initialValues;
  if (user.firstName) {
    initialValues = {
      ...user
    };
  }
  return {
    initialValues
  };
};

export default connect(mapStateToProps)(UserForm);
