import { combineReducers } from 'redux';
import paginate from '../shared/paginate';
import * as actions from './actions';
//- Reducers

const byPage = paginate({
  mapActionToKey: action => action.page,
  types: [
    actions.fetchProductsPage.REQUEST,
    actions.fetchProductsPage.SUCCESS,
    actions.fetchProductsPage.FAILURE,
  ],
});

const loading = (state = false, action) => {
  switch (action.type) {
    case actions.fetchProductsPage.REQUEST:
      return true;
    case actions.fetchProductsPage.SUCCESS:
    case actions.fetchProductsPage.FAILURE:
      return false;
    default:
      return state;
  }
};

const products = combineReducers({
  byPage,
  loading,
});

export default products;
