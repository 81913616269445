import React from 'react';
import EmptyState from '../../EmptyState/EmptyState';
import noBoxOffice from './no_box_office.png';

const TicketEmptyState = ({ onNewTicketClick }) => (
  <EmptyState
    background
    buttonText='Create a new Ticket'
    onButtonClick={onNewTicketClick}
    showButton={true}
    emptyStateHeadline='This performance has no tickets yet'
    emptyStateDescription='When you create tickets for this performance, they will appear here'
    emptyStateImageSrc={noBoxOffice}
    emptyStateImageAlt='No tickets found'
    emptyStateImageTitle='No tickets found'
  />
);

export default TicketEmptyState;
