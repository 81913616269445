import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import TicketForm from '../../../components/Ticket/TicketForm/TicketForm';
import {
  ticketActions,
  ticketSelectors,
} from '../../../modules/tickets/tickets';
import { removeEmpty } from '../../../utils/forms';
import { EventAuthContext } from '../event-auth-context';

// Grommet
import { Box } from 'grommet';

class TicketCreate extends Component {
  componentDidMount() {
    this.props.fetchUserSettings();
    this.props.ticketId &&
      this.props.fetchTicket({ ticketId: this.props.ticketId });
  }
  componentDidUpdate(prevProps) {
    if (this.props.ticketId !== prevProps.ticketId) {
      this.props.ticketId &&
        this.props.fetchTicket({ ticketId: this.props.ticketId });
    }
  }
  handleOnSubmit = values => {
    const ticketData = {
      ...values,
      status: values.status.value,
      performanceId: this.props.performanceId,
    };
    return this.props.ticketId
      ? this.props.onTicketUpdateSubmit({
          ticketId: this.props.ticket.id,
          ...removeEmpty(ticketData),
        })
      : this.props.onTicketCreateSubmit(removeEmpty(ticketData));
  };
  handleSubmitSuccess = () => {
    const { history, eventId, performanceId } = this.props;
    history.push(`/event/${eventId}/performance/${performanceId}`);
  };
  handleCancelForm = () => {
    const { history, eventId, performanceId } = this.props;
    history.push(`/event/${eventId}/performance/${performanceId}`);
  };

  render() {
    const title = this.props.ticketId ? 'Edit Ticket' : 'Add Ticket';
    return (
      <Box margin={{ vertical: 'medium' }}>
        <TicketForm
          isLoading={!this.props.isUserSettingsLoaded}
          isEmpty={!this.props.userSettings.feeSettings}
          title={title}
          ticket={this.props.ticket}
          onSubmit={this.handleOnSubmit}
          onSubmitSuccess={this.handleSubmitSuccess}
          userSettings={this.props.userSettings.feeSettings}
          onCancel={this.handleCancelForm}
          role={this.context}
          emptyStateComponent={<Redirect to='/account/ticket-sales/' />}
        />
      </Box>
    );
  }
}

TicketCreate.contextType = EventAuthContext;
const mapStateToProps = (state, ownProps) => {
  const eventId = parseInt(ownProps.match.params.eventId, 10);
  const performanceId = parseInt(ownProps.match.params.performanceId, 10);
  const ticketId = parseInt(ownProps.match.params.ticketId, 10) || null;
  const userSettings = ticketSelectors.getUserSettings(state);
  const isUserSettingsLoaded = ticketSelectors.getIsUserSettingsLoaded(state);
  return {
    eventId,
    performanceId,
    userSettings,
    isUserSettingsLoaded,
    ticketId,
    ticket: ticketSelectors.getTicket(state, ticketId),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        onTicketCreateSubmit: ticketActions.sumbmitCreateTicketForm,
        onTicketUpdateSubmit: ticketActions.sumbmitUpdateTicketForm,
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        ...ticketActions,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketCreate);
