import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedNumber, FormattedDate } from 'react-intl';
import { Link } from 'react-router-dom';

/* eslint-disable react/style-prop-object */
import TicketGroupTable from '../../components/TicketGroup/TicketGroupTable';
import MainContent, {
  ContentContainer,
  Title,
  SubTitle,
  HeaderActions,
  ContentType,
  PageTop,
  LineUpHelmet,
} from '../../components/MainContent/MainContent';

import InfoBlock, {
  InfoBlockList,
  InfoBlockListItem,
} from '../../components/InfoBlock/InfoBlock';
import {
  discountActions,
  discountSelectors,
} from '../../modules/discounts/discounts';
import { showModal } from '../../modules/modal/modal';

// Grommet
import { Box, Button } from 'grommet';
import { Edit, Tag } from 'grommet-icons';

class DiscountDetail extends Component {
  componentWillMount() {
    this.props.loadDiscount(this.props.discountId);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.discountId !== nextProps.discountId) {
      this.props.loadDiscount(nextProps.discountId);
    }
  }

  handleTicketGroupClick = ticketGroupId => {
    this.props.history.push(`/ticket-group/${ticketGroupId}`);
  };
  handleTicketGroupBtnClick = ticketGroupId => {
    this.props.history.push(`/ticket-group/${ticketGroupId}/tickets`);
  };

  render() {
    const { discount, ticketGroups } = this.props;
    const uses = discount.unlimited ? 'Unlimited' : `${discount.usesLeft}`;

    return (
      // {discount.discountType !== 'voucher' && (
      <>
        <LineUpHelmet
          title={discount.code}
          description='Manage your Line-Up discount'
        />
        <MainContent>
          <ContentType icon={<Tag size='small' />} label='Discount Code' />
          <Box margin={{ top: 'small' }}>
            <PageTop>
              <Title>{discount.code}</Title>
              <HeaderActions>
                {discount.discountType !== 'voucher' && (
                  <Link to={`/discount/edit/${discount.id}`}>
                    <Button
                      primary
                      label='Edit Discount'
                      size='small'
                      icon={<Edit size='small' />}
                    />
                  </Link>
                )}
              </HeaderActions>
            </PageTop>
          </Box>
          <Box margin={{ vertical: 'medium' }}>
            <ContentContainer>
              <SubTitle title='Discount Details' />
              <InfoBlock>
                <InfoBlockList>
                  <InfoBlockListItem attribute='Code' value={discount.code} />
                  <InfoBlockListItem
                    attribute='Status'
                    value={discount.status}
                  />
                  <InfoBlockListItem
                    attribute='Type'
                    value={discount.discountType}
                  />
                  {discount.fixedDiscount > 0 && (
                    <Box direction='column' gap='xsmall'>
                      <InfoBlockListItem
                        attribute='Fixed Discount'
                        value={
                          <FormattedNumber
                            value={discount.fixedDiscount}
                            style='currency'
                            currency='GBP'
                          />
                        }
                      />
                      {discount.discountType === 'voucher' && (
                        <InfoBlockListItem
                          attribute='Amount Remaining'
                          value={
                            <FormattedNumber
                              value={discount.amountRemaining}
                              style='currency'
                              currency='GBP'
                            />
                          }
                        />
                      )}
                    </Box>
                  )}
                  {discount.percentageDiscount > 0 && (
                    <InfoBlockListItem
                      attribute='Percentage Discount'
                      value={
                        <FormattedNumber
                          value={discount.percentageDiscount}
                          style='percent'
                        />
                      }
                    />
                  )}
                  {discount.ticketPrice !== null && (
                    <InfoBlockListItem
                      attribute='Fixed Ticket Price'
                      value={
                        <FormattedNumber
                          value={discount.ticketPrice}
                          style='currency'
                          currency='GBP'
                        />
                      }
                    />
                  )}
                  {discount.minBasketValue > 0 && (
                    <InfoBlockListItem
                      attribute='Min Basket Value'
                      value={
                        <FormattedNumber
                          value={discount.minBasketValue}
                          style='currency'
                          currency='GBP'
                        />
                      }
                    />
                  )}
                  {discount.maxDiscountAmount > 0 && (
                    <InfoBlockListItem
                      attribute='Maximum Discount'
                      value={
                        <FormattedNumber
                          value={discount.maxDiscountAmount}
                          style='currency'
                          currency='GBP'
                        />
                      }
                    />
                  )}
                  <InfoBlockListItem attribute='Uses Remaining' value={uses} />
                  {discount.discountType !== 'voucher' && (
                    <Box direction='column' gap='xsmall'>
                      <InfoBlockListItem
                        attribute='Remove Booking Fee'
                        value={discount.removeBookingFee ? 'True' : 'False'}
                      />
                      {discount.minTicketQuantity > 0 && (
                        <InfoBlockListItem
                          attribute='Min Tickets Required'
                          value={discount.minTicketQuantity}
                        />
                      )}
                      {discount.maxUses && (
                        <InfoBlockListItem
                          attribute='Max Uses Per Order'
                          value={discount.maxUses}
                        />
                      )}
                    </Box>
                  )}

                  {discount.description && (
                    <InfoBlockListItem
                      attribute='Description'
                      value={discount.description}
                    />
                  )}
                  {discount.validFrom && (
                    <InfoBlockListItem
                      attribute='Valid From (UTC)'
                      value={
                        <FormattedDate
                          weekday='short'
                          year='numeric'
                          day='numeric'
                          month='short'
                          hour='numeric'
                          minute='numeric'
                          value={discount.validFrom}
                        />
                      }
                    />
                  )}
                  {discount.validTo && (
                    <InfoBlockListItem
                      attribute='Valid To (UTC)'
                      value={
                        <FormattedDate
                          weekday='short'
                          year='numeric'
                          day='numeric'
                          month='short'
                          hour='numeric'
                          minute='numeric'
                          value={discount.validTo}
                        />
                      }
                    />
                  )}
                </InfoBlockList>
              </InfoBlock>
              {(discount.insideFeePercentage > 0 ||
                discount.insideFeeFixedValue > 0) && (
                <Fragment>
                  <SubTitle title='Inside Commission Rates' />
                  <InfoBlock>
                    <InfoBlockList>
                      {discount.insideFeePercentage > 0 && (
                        <InfoBlockListItem
                          attribute='Inside Fee Percentage'
                          value={
                            <FormattedNumber
                              value={discount.insideFeePercentage}
                              style='percent'
                              maximumSignificantDigits={4}
                            />
                          }
                        />
                      )}
                      {discount.insideFeeFixedValue > 0 && (
                        <InfoBlockListItem
                          attribute='Inside Fee Fixed Value'
                          value={
                            <FormattedNumber
                              value={discount.insideFeeFixedValue}
                              style='currency'
                              currency='GBP'
                            />
                          }
                        />
                      )}
                      {discount.insideFeeTaxRate > 0 && (
                        <InfoBlockListItem
                          attribute='Inside Fee Tax Rate'
                          value={
                            <FormattedNumber
                              value={discount.insideFeeTaxRate}
                              style='percent'
                            />
                          }
                        />
                      )}
                    </InfoBlockList>
                  </InfoBlock>
                </Fragment>
              )}
              {discount.discountType === 'group' && (
                <>
                  <SubTitle title='Ticket Groups' />
                  <Box margin={{ vertical: 'medium' }}>
                    <TicketGroupTable
                      ticketGroups={ticketGroups}
                      onTicketGroupClick={this.handleTicketGroupClick}
                      onTicketGroupBtnClick={this.handleTicketGroupBtnClick}
                    />
                  </Box>
                </>
              )}
            </ContentContainer>
          </Box>
        </MainContent>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const discountId = parseInt(ownProps.match.params.discountId, 10);
  const discount = discountSelectors.getDiscount(state, discountId);
  const ticketGroups = discountSelectors.getTicketGroupsForDiscount(
    state,
    discountId
  );
  return {
    discount,
    discountId,
    ticketGroups,
  };
};

export default connect(
  mapStateToProps,
  {
    ...discountActions,
    showModal,
  }
)(DiscountDetail);
