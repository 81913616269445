export const isFetchingIntegration = state =>
  !state.mailchimp.integration.fetched;
export const isFetchingLists = state => !state.mailchimp.lists.fetched;
export const isMailchimpConnected = state =>
  state.mailchimp.integration.connected;

export const getConnectUrl = state => state.mailchimp.integration.connectUrl;
export const hasFetchedMailchimpLists = state => state.mailchimp.lists.fetched;
export const getMailchimpLists = state => state.mailchimp.lists.data;
export const getMailchimpIntegrationData = state =>
  state.mailchimp.integration.data;
