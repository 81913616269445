import * as fromEntities from '../shared/entities';
import * as fromPaginator from '../shared/paginate';

export const getWebhooksLoadingStatus = state => state.webhooks.loading;

export const getWebhookTypes = state => state.webhooks.types.data;

export const getWebhook = (state, webhookId) =>
  fromEntities.getEntity(state.entities.webhook, webhookId);

export const getWebhooksForPage = (state, page) => {
  const ids = fromPaginator.getIdsForPage(state.webhooks.byPage, page);
  return ids.map(id => getWebhook(state, id));
};
export const getWebhooksCurrentPage = state =>
  fromPaginator.getCurrentPage(state.webhooks.byPage);

export const getWebhooksTotalPages = (state, perPage = 25) =>
  fromPaginator.getTotalPages(state.webhooks.byPage);
