import * as types from './types';
import { createRoutine } from 'redux-saga-routines';

export const fetchAllSeatingCharts = createRoutine(
  types.FETCH_ALL_SEATING_CHARTS
);
export const fetchSeatingCharts = createRoutine(types.FETCH_SEATING_CHARTS);

export const getSeatingUser = createRoutine(types.GET_SEATING_USER);

export const deleteSeatingChart = createRoutine(types.DELETE_SEATING_CHART);
