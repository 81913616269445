import React, { Fragment } from 'react';
import classNames from 'classnames';
import './ErrorMessage.css';

const ErrorMessage = ({ error, className }) => {
  const errorClass = classNames('error-message', className);
  return (
    <Fragment>
      {error && <span className={errorClass}>{error.errors._error}</span>}
    </Fragment>
  );
};

export default ErrorMessage;
