import React from 'react';
import PageNav, { PageNavItem } from '../../../components/PageNav/PageNav';

// Grommet
import { ResponsiveContext } from 'grommet';

const AccountNav = () => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          {size !== 'small' ? (
            <PageNav>
              <PageNavItem label='Account details' to='/account' />
              <PageNavItem label='Profile' to='/account/profile' />
              {/* <PageNavItem label="Monthly Plan" to="/account/plan" /> */}
              <PageNavItem label='Ticket Sales' to='/account/ticket-sales' />
              {/* <PageNavItem label="Billing" to="/account/billing" /> */}
              {/* <PageNavItem label="Invoices" to="/account/invoices" /> */}
              {/* <PageNavItem label="Notifications" to="/account/notifications" /> */}
            </PageNav>
          ) : (
            <PageNav mobileMenu='Account Menu'>
              {[
                {
                  label: <PageNavItem label='Account details' to='/account' />
                },
                {
                  label: <PageNavItem label='Profile' to='/account/profile' />
                },
                {
                  label: (
                    <PageNavItem
                      label='Ticket Sales'
                      to='/account/ticket-sales'
                    />
                  )
                }
                /* {
                  label: <PageNavItem label="Billing" to="/account/billing" />
                } */
              ]}
            </PageNav>
          )}
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default AccountNav;
