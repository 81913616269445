import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import './Modal.css';

const Modal = ({ children, onRequestClose }) => {
  ReactModal.setAppElement('#root');
  return (
    <ReactModal
      isOpen={true}
      overlayClassName='react-modal__overlay'
      className='react-modal__content'
      shouldCloseOnOverlayClick={true}
      contentLabel='modal'
      onRequestClose={onRequestClose}
    >
      <div className='modal-content__inner'>{children}</div>
    </ReactModal>
  );
};

export default Modal;
