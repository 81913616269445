import React from 'react';
import { connect } from 'react-redux';

import IssueTicketModal from '../event/ticketAllocation/IssueTicketModal';
import BulkIssueModal from '../event/ticketAllocation/BulkIssueModal';
import TicketListModal from '../ticket/TicketListModal';
import OrderExportModal from '../order/OrderExportModal';
import ExportModal from '../export/ExportModal';
import CreateInvoiceModal from '../agent/CreateInvoiceModal';
import RefundModal from '../order/refund/RefundModal';
import NoteModal from '../note/NoteModal';
import AlertModal from './AlertModal';
import TransferModal from '../order/transfer/TransferModal';
import SeatingChartModal from '../order/transfer/SeatingChartModal';
import EditModal from '../order/edit/EditModal';
import PerformanceCreateModal from '../event/performance/PerformanceCreateModal';
import TicketAllocationCreateModal from '../event/ticketAllocation/TicketAllocationCreateModal';
import VenueCreateModal from '../venue/VenueCreateModal';
import EmailSendPreviewModal from '../email/EmailSendPreviewModal';
import PlannerBookingModal from 'containers/event/planner/BookingModal';
import LaneEditModal from 'containers/event/planner/LaneEditModal';
import DeleteLaneConfirmModal from 'containers/event/planner/DeleteLaneConfirmModal';
import LockLaneConfirmModal from 'containers/event/planner/LockLaneConfirmModal';
import DisableLaneConfirmModal from 'containers/event/planner/DisableLaneConfirmModal';
import CreateReservationModal from 'containers/event/planner/CreateReservationModal';
import ConfirmReservationModal from 'containers/event/planner/ConfirmReservationModal';
import DeletePlannerConfirmModal from 'containers/event/planner/DeletePlannerConfirmModal';
import AddUserModal from 'containers/event/userManagement/AddUserModal';
import StripeDisconnectModal from 'containers/account/StripeDisconnectModal';
import OrderDetailModal from 'containers/event/performance/ticketReceipt/OrderDetailModal';

const MODAL_COMPONENTS = {
  ISSUE_TICKET: IssueTicketModal,
  BULK_ISSUE_TICKETS: BulkIssueModal,
  SELECT_TICKETS: TicketListModal,
  EXPORT_ORDERS: OrderExportModal,
  CREATE_INVOICE: CreateInvoiceModal,
  EXPORT: ExportModal,
  REFUND_MODAL: RefundModal,
  NOTE_MODAL: NoteModal,
  ALERT_MODAL: AlertModal,
  TRANSFER_MODAL: TransferModal,
  SEATING_CHART_MODAL: SeatingChartModal,
  EDIT_RECEIPT_MODAL: EditModal,
  PERFORMANCE_CREATE_MODAL: PerformanceCreateModal,
  TICKET_ALLOCATION_CREATE_MODAL: TicketAllocationCreateModal,
  CREATE_VENUE: VenueCreateModal,
  EMAIL_SEND_PREVIEW: EmailSendPreviewModal,
  PLANNER_BOOKING_MODAL: PlannerBookingModal,
  EDIT_LANE_MODAL: LaneEditModal,
  DELETE_LANE_CONFIRM_MODAL: DeleteLaneConfirmModal,
  LOCK_LANE_CONFIRM_MODAL: LockLaneConfirmModal,
  DISABLE_LANE_CONFIRM_MODAL: DisableLaneConfirmModal,
  CREATE_RESERVATION_MODAL: CreateReservationModal,
  CONFIRM_RESERVATION_MODAL: ConfirmReservationModal,
  DELETE_PLANNER_CONFIRM_MODAL: DeletePlannerConfirmModal,
  ADD_USER_MODAL: AddUserModal,
  STRIPE_DISCONNECT: StripeDisconnectModal,
  ORDER_DETAIL: OrderDetailModal,
};

const ModalRoot = ({ modalType, modalProps }) => {
  if (!modalType) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];
  return <SpecificModal {...modalProps} />;
};

const mapStateToProps = (state, ownProps) => {
  const { modalType, modalProps } = state.modal;
  return {
    modalType,
    modalProps,
  };
};

export default connect(mapStateToProps)(ModalRoot);
