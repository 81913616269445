import { schema, normalize } from "normalizr";
import { camelizeKeys } from "humps";
import fetch from "isomorphic-fetch";
import buildUrl from "build-url";
import { ApiError, NetworkError } from "../utils/errors";
import { saveAs } from "file-saver";

const API_ROOT = process.env.REACT_APP_API_ROOT;
const PUBLIC_API_KEY = process.env.REACT_APP_PLATFORM_PUBLIC_API_KEY;
const getAuthenticationToken = () =>
  window.lukey ? btoa(`${window.lukey}:`) : btoa(`${PUBLIC_API_KEY}:`);

const downloadFile = (url, config, filename, queryParams) =>
  fetch(fullUrl(url, queryParams), config)
    .then(
      (response) =>
        response.blob().then((blob) => {
          saveAs(blob, filename);
          return Promise.resolve();
        }),
      (err) => Promise.reject(new NetworkError())
    )
    .then(
      (response) => ({ response: "success" }),
      (error) => ({ error })
    );

const downloadConfig = (
  content = "application/pdf",
  method = "GET",
  authToken
) => {
  let config = {
    method: method,
    responseType: "blob",
    headers: {
      "Content-Type": content,
      Authorization: authToken
        ? `Bearer ${authToken}`
        : `Basic ${getAuthenticationToken()}`,
    },
  };
  return config;
};

const defaultConfig = (method = "GET", body, authToken) => {
  let config = {
    method: method,
    headers: {
      "Content-Type": method === "GET" ? null : "application/json",
      Authorization: authToken
        ? `Bearer ${authToken}`
        : `Basic ${getAuthenticationToken()}`,
    },
    body: body ? JSON.stringify(body) : null,
  };
  return config;
};

export const parseApiError = (meta) =>
  (meta.errors
    ? meta.errors
    : [{ attribute: "_error", messages: meta.message }]
  ).reduce(
    (obj, err) => {
      if (typeof err.messages === "object") {
        obj[err.attribute] = parseApiError(err.messages);
      } else {
        obj[err.attribute] = err.messages;
      }
      return obj;
    },
    { _error: "There was an error submitting the form" }
  );

const fullUrl = (endpoint, queryParams) => {
  return buildUrl(API_ROOT, {
    path: endpoint,
    queryParams: queryParams,
  });
};

const callApi = (endpoint, config = defaultConfig(), schema, queryParams) => {
  const url =
    endpoint.indexOf(API_ROOT) === -1
      ? fullUrl(endpoint, queryParams)
      : endpoint;
  return fetch(url, config)
    .then(
      (response) => response.json().then((json) => ({ json, response })),
      (err) => Promise.reject(new NetworkError())
    )
    .then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject(new ApiError(parseApiError(json._metadata)));
      }
      const camelizedJson = camelizeKeys(json.data);
      const pagination = {
        ...camelizeKeys(json._metadata),
      };

      if (schema) {
        return {
          ...normalize(camelizedJson, schema),
          pagination: pagination,
        };
      }
      return camelizedJson;
    })
    .then(
      (response) => ({ response }),
      (error) => ({ error })
    );
};

// api services
const userSettingsSchema = new schema.Entity("userSettings");
export const fetchUserSettings = (data) =>
  callApi(`v1/user-settings/`, defaultConfig(), userSettingsSchema);

export const searchTags = (data) =>
  callApi(`v1/tag/`, defaultConfig(), null, { query: data.query, size: 5 });

export const searchCategories = (data) =>
  callApi(`v1/category/`, defaultConfig(), null, {
    query: data.query,
    size: 100,
  });

export const searchDiscounts = (data) =>
  callApi(`v1/discounts/`, defaultConfig(), discountListSchema, {
    page: data.page,
    query: data.query ? data.query : "",
    size: 500,
    ...(data.discountType && { discountType: data.discountType }),
  });

export const searchVenues = (data) =>
  callApi(`v1/venue/`, defaultConfig(), null, { query: data.query, size: 5 });

const ticketSchema = new schema.Entity("ticket");
const ticketListSchema = [ticketSchema];

export const createTicketTransfer = (data) =>
  callApi(
    `/reservation/`,
    defaultConfig("POST", {
      basket: {
        tickets: [
          {
            ticketId: data.ticketId,
            seats: data.seats,
            quantity: data.quantity,
          },
        ],
      },
    })
  ).then((response) =>
    callApi(
      `v1/purchase/${data.purchaseId}/`,
      defaultConfig("PUT", {
        reservation: {
          reference: response.response.reference,
        },
      })
    )
  );

export const fetchTicket = (data) =>
  callApi(`v1/ticket/${data.ticketId}/`, defaultConfig(), ticketSchema);

export const fetchTicketsForPage = (data) =>
  callApi(
    `v1/performance/${data.performanceId}/tickets/`,
    defaultConfig(),
    ticketListSchema,
    {
      page: data.page,
      resultsPerPage: 100,
    }
  );

export const createTicket = (data) =>
  callApi(`v1/ticket/`, defaultConfig("POST", data), ticketSchema);

export const updateTicket = (data) =>
  callApi(
    `v1/ticket/${data.ticketId}/`,
    defaultConfig("PUT", data),
    ticketSchema
  );
export const deleteTicket = (data) =>
  callApi(`v1/ticket/${data.ticketId}/`, defaultConfig("DELETE"));
const ticketGroupSchema = new schema.Entity("ticketGroup");
const ticketGroupListSchema = [ticketGroupSchema];

const ticketAllocationSchema = new schema.Entity("ticketAllocation", {
  ticket: ticketSchema,
});

const ticketAllocationsSchema = [ticketAllocationSchema];

export const getTicketGroups = (page = 1) => {
  const url = fullUrl(`ticket-group/`, {
    page: page,
    resultsPerPage: 100,
  });
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
  };
  return callApi(url, config, ticketGroupListSchema);
};

export const getTicketGroup = (ticketGroupId) => {
  const url = fullUrl(`ticket-group/${ticketGroupId}/`);
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
  };
  return callApi(url, config, ticketGroupSchema);
};

export const createTicketGroup = (ticketGroup) => {
  const url = fullUrl(`ticket-group/`);
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
    body: JSON.stringify(ticketGroup),
  };
  return callApi(url, config, ticketGroupSchema);
};

const refundSchema = new schema.Entity("refund");
const orderSchema = new schema.Entity("order", {
  refunds: [refundSchema],
});

export const fetchOrder = (data) =>
  callApi(`v1/order/${data.orderId}/`, defaultConfig(), orderSchema);

export const fetchTicketAllocationPage = (data) =>
  callApi(
    `v1/ticket/${data.ticketId}/ticket-allocations/`,
    defaultConfig(),
    ticketAllocationsSchema
  );

export const fetchTicketAllocation = (data) => {
  const url = fullUrl(`v1/ticket-allocation/${data.allocationId}/`);
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
  };
  return callApi(url, config, ticketAllocationSchema);
};

export const createTicketAllocation = (data) =>
  callApi(
    `v1/ticket-allocation/`,
    defaultConfig("POST", data),
    ticketAllocationSchema
  );

export const updateTicketAllocation = (data) =>
  callApi(
    `v1/ticket-allocation/${data.allocationId}/`,
    defaultConfig("PUT", data),
    ticketAllocationSchema
  );

export const deleteTicketAllocation = (data) =>
  callApi(
    `v1/ticket-allocation/${data.allocationId}/`,
    defaultConfig("DELETE")
  );

export const issueTicket = ({ allocationId, agentId, purchaseData }) => {
  const url = fullUrl(`ticket-allocation/${allocationId}/issue/`);
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
    body: JSON.stringify({
      purchaseData: purchaseData,
      ...(agentId ? { agentId: agentId } : {}),
    }),
  };
  return callApi(url, config, orderSchema);
};

const productSchema = new schema.Entity("product");
const productListSchema = [productSchema];

export const fetchProduct = (data) =>
  callApi(`product/${data.productId}/`, defaultConfig(), productSchema);
export const fetchProducts = (data) =>
  callApi("product/", defaultConfig(), productListSchema, {
    page: data.page,
  });
export const createProduct = (product) => {
  const url = fullUrl(`product/`);

  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
    body: JSON.stringify(product),
  };
  return callApi(url, config, productSchema);
};
export const updateProduct = (product) => {
  const url = fullUrl(`product/${product.productId}/`);
  const config = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
    body: JSON.stringify(product),
  };
  return callApi(url, config, productSchema);
};

export const ticketReceiptSchema = new schema.Entity("ticketReceipt");
const ticketReceiptListSchema = [ticketReceiptSchema];

export const productReceiptSchema = new schema.Entity("productReceipt");

export const getTicketReceipts = (performanceId, page = 1, searchTerm) => {
  const url = fullUrl(`performance/${performanceId}/receipts/`, {
    page: page,
    resultsPerPage: 100,
    ...(searchTerm && { query: searchTerm }),
  });
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
  };
  return callApi(url, config, ticketReceiptListSchema);
};

export const deleteCheckin = (receiptId) => {
  const url = fullUrl(`ticket-receipt/${receiptId}/redeem/`);
  const config = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
  };
  return callApi(url, config, ticketReceiptSchema);
};

export const createCheckin = (receiptId) => {
  const url = fullUrl(`ticket-receipt/${receiptId}/redeem/`);
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
  };
  return callApi(url, config, ticketReceiptSchema);
};

const discountSchema = new schema.Entity("discount", {
  ticketGroups: [ticketGroupSchema],
});
const discountListSchema = [discountSchema];

export const getDiscounts = (page = 1) => {
  const url = fullUrl(`discount/`, {
    page: page,
    resultsPerPage: 100,
  });
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
  };
  return callApi(url, config, discountListSchema);
};

export const getDiscount = (discountId) => {
  const url = fullUrl(`discount/${discountId}/`);
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
  };
  return callApi(url, config, discountSchema);
};

export const createDiscount = (discount) => {
  const url = fullUrl(`discount/`);
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
    body: JSON.stringify(discount),
  };
  return callApi(url, config, discountSchema);
};

export const updateDiscount = (discountId, discount) => {
  const url = fullUrl(`discount/${discountId}/`);
  const config = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
    body: JSON.stringify(discount),
  };
  return callApi(url, config, discountSchema);
};

const donationSchema = new schema.Entity("donation");
const donationListSchema = [donationSchema];

export const getDonations = (page = 1) => {
  const url = fullUrl(`donation/`, {
    page: page,
    resultsPerPage: 100,
  });
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
  };
  return callApi(url, config, donationListSchema);
};

export const exportDonations = (data) =>
  downloadFile(
    `v1/export/donations/`,
    downloadConfig("text/csv"),
    "lineup-donations-export.csv",
    {
      fromDate: data.from,
      toDate: data.to,
    }
  );

export const getEmbedSettings = (eventId) => {
  const url = fullUrl(`event/${eventId}/embed-settings/`);
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
  };
  return callApi(url, config);
};

export const updateEmbedSettings = (eventId, embedSettings) => {
  const url = fullUrl(`event/${eventId}/embed-settings/`);
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
    body: JSON.stringify(embedSettings),
  };
  return callApi(url, config);
};

const userSchema = new schema.Entity("user");

export const getUser = (userId) => {
  const url = fullUrl(`user/${userId}/`);
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
  };
  return callApi(url, config, userSchema);
};

export const updateUser = (userId, user) => {
  const url = fullUrl(`user/${userId}/`);
  const config = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
    body: JSON.stringify(user),
  };
  return callApi(url, config, userSchema);
};

export const addTicketToTicketGroup = (ticketGroupId, ticketId) => {
  const url = fullUrl(`ticket-group/${ticketGroupId}/tickets/${ticketId}/`);
  const config = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
  };
  return callApi(url, config);
};

export const removeTicketFromTicketGroup = (ticketGroupId, ticketId) => {
  const url = fullUrl(`ticket-group/${ticketGroupId}/tickets/${ticketId}/`);
  const config = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
  };
  return callApi(url, config);
};

export const getTicketGroupTickets = (ticketGroupId, page = 1) => {
  const url = fullUrl(`ticket-group/${ticketGroupId}/tickets/`, {
    page: page,
    resultsPerPage: 100,
  });
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
  };
  return callApi(url, config, ticketListSchema);
};

export const waitingListUserSchema = new schema.Entity("waitingListUser");
const waitingListSchema = [waitingListUserSchema];

export const getWaitingList = (performanceId, page = 1) => {
  const url = fullUrl(`performance/${performanceId}/waiting-list/`, {
    page: page,
    resultsPerPage: 50,
  });
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
  };
  return callApi(url, config, waitingListSchema);
};

const agentSchema = new schema.Entity("agent");
const agentListSchema = [agentSchema];

export const fetchAgents = (data) =>
  callApi("agent/", defaultConfig(), agentListSchema, {
    page: data.page,
    resultsPerPage: 100,
  });

export const createAgent = (data) =>
  callApi("agent/", defaultConfig("POST", data), agentSchema);
export const updateAgent = (data) =>
  callApi(`agent/${data.agentId}/`, defaultConfig("PUT", data), agentSchema);

export const fetchAgent = (data) =>
  callApi(`agent/${data.agentId}/`, defaultConfig(), agentSchema);

const invoiceSchema = new schema.Entity("invoice");
const invoiceListSchema = [invoiceSchema];

export const fetchInvoices = (data) =>
  callApi(`v1/invoice/`, defaultConfig(), invoiceListSchema, {
    page: data.page,
    resultsPerPage: 25,
  });

export const fetchInvoice = (data) =>
  callApi(`v1/invoice/${data.invoiceId}/`, defaultConfig(), invoiceSchema);

export const fetchAgentInvoices = (data) =>
  callApi(
    `agent/${data.agentId}/invoices/`,
    defaultConfig(),
    invoiceListSchema,
    {
      page: data.page,
      resultsPerPage: 5,
    }
  );

export const deleteInvoice = (data) =>
  callApi(
    `agent/${data.agentId}/invoice/${data.invoiceId}/`,
    defaultConfig("DELETE")
  );

export const fetchInvoicePdf = (data) =>
  downloadFile(
    `v1/export/agent/${data.agentId}/invoice/${data.invoiceId}/pdf/`,
    downloadConfig(),
    data.filename
  );

export const createInvoice = (data) =>
  callApi(
    `agent/${data.agentId}/invoice/`,
    defaultConfig("POST", data),
    invoiceSchema
  );

export const fetchPaymentReport = (data) =>
  callApi(`v1/report/payments/`, defaultConfig(), null, {
    fromDate: data.from,
    toDate: data.to,
  });

export const exportCustomers = (data) =>
  downloadFile(
    `v1/export/customers/`,
    downloadConfig("text/csv"),
    "lineup-customer-export.csv",
    {
      fromDate: data.from,
      toDate: data.to,
    }
  );

const performanceSchema = new schema.Entity("performance");
const performanceListSchema = [performanceSchema];

export const fetchPerformancesPage = (data) =>
  callApi(
    `v1/event/${data.eventId}/performances/`,
    defaultConfig(),
    performanceListSchema,
    {
      resultsPerPage: data.resultsPerPage || 500,
      page: data.page,
      ...(data.gte && { gte: data.gte }),
      ...(data.lte && { lte: data.lte }),
    }
  );

export const fetchPerformance = (data) =>
  callApi(
    `v1/performance/${data.performanceId}/`,
    defaultConfig(),
    performanceSchema
  );

export const createPerformance = (data) =>
  callApi(`v1/performance/`, defaultConfig("POST", data), performanceSchema);

export const updatePerformance = (data) =>
  callApi(
    `v1/performance/${data.performanceId}/`,
    defaultConfig("PUT", data),
    performanceSchema
  );

export const deletePerformance = (data) =>
  callApi(`v1/performance/${data.performanceId}/`, defaultConfig("DELETE"));

export const fetchTicketData = (data) =>
  callApi(`v1/report/receipts/`, defaultConfig(), null, {
    fromDate: data.from,
    toDate: data.to,
    ...(data.performanceDate && { performanceDate: data.performanceDate }),
    performanceStart: data.performanceStart,
    performanceEnd: data.performanceEnd,
    eventId: data.eventId,
    sellerId: data.sellerId,
    performanceId: data.performanceId,
    reportName: data.reportName,
  });

const seatingChartSchema = new schema.Entity("seatingChart");
const seatingChartsSchema = [seatingChartSchema];
export const fetchSeatingCharts = (data) =>
  callApi("v1/seating-charts/", defaultConfig(), seatingChartsSchema);

export const getSeatingUser = () =>
  callApi(`v1/seating-user/`, defaultConfig("GET"));

export const deleteSeatingChart = (data) =>
  callApi(`v1/seating-charts/${data.chartId}/`, defaultConfig("DELETE"));

const eventSchema = new schema.Entity("event");
const eventsSchema = [eventSchema];
export const fetchReportEvents = (data) =>
  callApi(`v1/report/events/`, defaultConfig(), eventsSchema);

export const fetchEvents = (data) =>
  callApi(`v1/event/`, defaultConfig(), eventsSchema, {
    page: data.page,
    resultsPerPage: 100,
  });

export const fetchEvent = (data) =>
  callApi(`v1/event/${data.eventId}/`, defaultConfig(), eventSchema);

export const fetchEventBySlug = (data) =>
  callApi(`v1/event/${data.eventSlug}/`, defaultConfig());

export const createEvent = (data) =>
  callApi(`v1/event/`, defaultConfig("POST", data), eventSchema);

export const updateEvent = (data) =>
  callApi(`v1/event/${data.eventId}/`, defaultConfig("PUT", data), eventSchema);

const venueSchema = new schema.Entity("venue");
export const createVenue = (data) =>
  callApi(`v1/venue/`, defaultConfig("POST", data), venueSchema);

export const loadTicketReceipt = (data) =>
  callApi(
    `/ticket-receipt/${data.ticketReceiptId}/`,
    defaultConfig(),
    ticketReceiptSchema
  );
export const editTicketReceipt = (data) =>
  callApi(
    `/ticket-receipt/${data.receiptId}/`,
    defaultConfig("PUT", data.values),
    ticketReceiptSchema
  );

export const editProductReceipt = (data) =>
  callApi(
    `v1/product-receipt/${data.receiptId}/`,
    defaultConfig("PUT", data.values),
    productReceiptSchema
  );

export const uploadImage = (filename, file) => {
  const formData = new FormData();
  formData.append(filename, file);
  return callApi("image/", {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Basic ${getAuthenticationToken()}`,
    },
  });
};

export const deleteImage = (imageId) =>
  callApi(`image/${imageId}/`, defaultConfig("DELETE"));

const noteSchema = new schema.Entity("note");
const noteListSchema = [noteSchema];

export const fetchOrderNotes = (data) =>
  callApi(`v1/order/${data.orderId}/notes/`, defaultConfig(), noteListSchema, {
    page: 1,
  });

export const createNoteOnOrder = (data) =>
  callApi(
    `v1/order/${data.orderId}/notes/`,
    defaultConfig("POST", data),
    noteSchema
  );

export const updateNote = (data) =>
  callApi(`v1/note/${data.noteId}/`, defaultConfig("PUT", data), noteSchema);

export const deleteNote = (data) =>
  callApi(`v1/note/${data.noteId}/`, defaultConfig("DELETE"));

export const sendOrderConfirmation = (data) =>
  callApi(`v1/order/${data.orderId}/email-receipt/`, defaultConfig("POST"));

export const createRefund = (data) =>
  callApi(`v1/refund/`, defaultConfig("POST", data.refund));

export const deleteRefund = (data) =>
  callApi(`v1/refund/${data.refundId}/`, defaultConfig("DELETE"));

export const editOrder = (data) =>
  callApi(`v1/order/`, defaultConfig("PUT", data.refund));

const formSchema = new schema.Entity("form");
const formListSchema = [formSchema];

export const fetchForms = (data) =>
  callApi(`v1/form/`, defaultConfig(), formListSchema, {
    page: data.page,
    resultsPerPage: 50,
  });

export const exportFormResponses = (data) =>
  downloadFile(
    `v1/form/${data.formId}/responses/export/`,
    downloadConfig("text/csv"),
    "lineup-form-responses.csv",
    {
      fromDate: data.from,
      toDate: data.to,
    }
  );

export const fetchMailchimpIntegration = () =>
  callApi(`v1/mailchimp/`, defaultConfig());

export const completeMailchimpConnect = (code) =>
  callApi(
    `v1/mailchimp/oauth/complete/`,
    defaultConfig("POST", { code: code })
  );

export const fetchMailchimpLists = () => callApi(`v1/mailchimp/lists/`);

export const updateMailchimpIntegration = (data) =>
  callApi(`v1/mailchimp/integration/`, defaultConfig("POST", data));

export const syncAttendeesToMailchimp = (data) =>
  callApi(
    `v1/mailchimp/sync/${data.eventId}/`,
    defaultConfig("POST", { mainListId: data.mainListId })
  );

export const disconnectMailchimpIntegration = () =>
  callApi(`v1/mailchimp/disconnect/`, defaultConfig("POST"));

// Emails
const emailSchema = new schema.Entity("email");
const emailListSchema = [emailSchema];

export const fetchEmails = (data) =>
  callApi(`v1/email/`, defaultConfig(), emailListSchema, {
    page: data.page,
    resultsPerPage: 100,
  });

export const fetchEmail = (data) =>
  callApi(`v1/email/${data.emailId}/`, defaultConfig(), emailSchema);

export const updateEmail = (data) =>
  callApi(`v1/email/${data.email.id}/`, defaultConfig("PUT", data.values));

export const createEmail = (data) =>
  callApi(`v1/email/`, defaultConfig("POST", data.values));

export const deleteEmail = (data) =>
  callApi(`v1/email/${data.emailId}/`, defaultConfig("DELETE"));

export const fetchEventEmailSchedules = (data) =>
  callApi(`v1/event/${data.eventId}/email-schedules/`, defaultConfig());

export const fetchEventEmailSchedule = (data) =>
  callApi(`v1/event-email-schedule/${data.id}/`, defaultConfig());

export const createEventEmailSchedule = (data) =>
  callApi(`v1/event-email-schedule/`, defaultConfig("POST", data));

export const updateEventEmailSchedule = (data) =>
  callApi(`v1/event-email-schedule/${data.id}/`, defaultConfig("PUT", data));

export const deleteEventEmailSchedule = (data) =>
  callApi(`v1/event-email-schedule/${data.id}/`, defaultConfig("DELETE", data));

export const sendEmailPreview = (data) =>
  callApi(
    `v1/email/${data.emailId}/send-preview/`,
    defaultConfig("POST", data)
  );

export const createEventPlanner = (data) =>
  callApi(`v1/planner/`, defaultConfig("POST", data));

export const updateEventPlanner = (data) =>
  callApi(`v1/planner/${data.eventPlannerId}/`, defaultConfig("PUT", data));

export const fetchEventPlanner = (data) =>
  callApi(`v1/event/${data.eventId}/planner/`, defaultConfig());

export const fetchEventPlannerPlacements = (data) =>
  callApi(
    `v1/planner/${data.id}/placements/${data.day}/`,
    defaultConfig("GET")
  );

export const fetchEventPlannerPerformances = (data) =>
  callApi(`v1/planner/${data.id}/performances/`, defaultConfig("GET"));

export const updateEventPlannerPlacement = (data) =>
  callApi(`v1/planner/placement/`, defaultConfig("PATCH", data));

export const swapEventPlannerPlacements = (data) =>
  callApi(`v1/planner/placement/swap/`, defaultConfig("PUT", data));

export const fetchOrderFormResponses = (data) =>
  callApi(`v1/order/${data.orderId}/form-responses/`, defaultConfig());

export const updateEventPlannerLane = (data) =>
  callApi(
    `v1/planner/lane/${data.eventPlannerLaneId}/`,
    defaultConfig("PUT", data)
  );

export const deleteEventPlannerLane = (data) =>
  callApi(
    `v1/planner/lane/${data.eventPlannerLaneId}/`,
    defaultConfig("DELETE")
  );

export const updateEventPlannerLaneLock = (data) =>
  callApi(
    `v1/planner/lane/${data.eventPlannerLaneId}/lock/`,
    defaultConfig("PUT", data)
  );

export const updateEventPlannerLaneDisabledStatus = (data) =>
  callApi(
    `v1/planner/lane/${data.eventPlannerLaneId}/disable/`,
    defaultConfig("PUT", data)
  );

export const fetchTicketsForPerformance = (data) =>
  callApi(
    `v1/performance/${data.performanceId}/tickets/`,
    defaultConfig("GET")
  );

export const createTicketAllocationForPlanner = (data) =>
  callApi(`v1/planner/ticket-allocation/`, defaultConfig("POST", data));

export const issueTicketForPlanner = (data) =>
  callApi(
    `v1/planner/ticket-allocation/${data.ticketAllocationId}/issue/`,
    defaultConfig("POST", data)
  );

export const deleteEventPlanner = (data) =>
  callApi(`v1/planner/${data.eventPlannerId}/`, defaultConfig("DELETE"));

export const authenticateUser = (data) =>
  callApi(`v1/user/authenticate/`, defaultConfig("POST", data));

export const getUseFromToken = (data) => {
  return callApi(`v1/user/`, defaultConfig("GET", null, data.authToken));
};
export const createUser = (data) =>
  callApi(`v1/user/`, defaultConfig("POST", data));

export const updateCurrentUser = (data) =>
  callApi(`v1/user/`, defaultConfig("PUT", data));

export const updateUserProfile = (data) =>
  callApi(`v1/user/profile/`, defaultConfig("PUT", data));

export const getSubscription = () =>
  callApi("v1/subscription/", defaultConfig("GET"));

export const getPaymentMethod = () =>
  callApi("v1/user/payment-method/", defaultConfig("GET"));

export const createPaymentMethod = (data) =>
  callApi(`v1/user/payment-method/`, defaultConfig("POST", data));

export const getStripeSettings = () =>
  callApi(`v1/user/stripe-settings/`, defaultConfig("GET"));

export const createStripeSettings = (data) =>
  callApi(`v1/user/stripe-settings/`, defaultConfig("POST", data));

export const deleteStripeSettings = (data) =>
  callApi(`v1/user/stripe-settings/`, defaultConfig("DELETE"));

export const createBusinessSettings = (data) =>
  callApi(
    `v1/user/business-settings/`,
    defaultConfig("POST", {
      country: data.country,
      currency: data.currency,
      includeVat: data.includeVat,
      vatNumber: data.vatNumber,
      company: data.company,
      address: {
        region: data.region,
        country: data.country,
        postcode: data.postcode,
        street: data.street,
        locality: data.locality,
        county: data.county,
      },
    })
  );

export const getBusinessSettings = () =>
  callApi(`v1/user/business-settings/`, defaultConfig("GET"));

export const updateSubscription = (data) =>
  callApi(`v1/subscription/`, defaultConfig("PUT", data));

export const forgotPassword = (data) =>
  callApi(`v1/forgot-password/`, defaultConfig("POST", data));

export const resetPassword = (data) =>
  callApi(`v1/reset-password/`, defaultConfig("POST", data));

export const getCurrencies = () =>
  callApi(`v1/currencies/`, defaultConfig("GET"));

export const getEventInvitations = (data) =>
  callApi(`v1/event/${data.eventId}/invitations/`, defaultConfig("GET"));

export const getEventPermissions = (data) =>
  callApi(`v1/event/${data.eventId}/permissions/`, defaultConfig("GET"));

export const createEventInvitation = (data) =>
  callApi(`v1/event/${data.eventId}/invitations/`, defaultConfig("POST", data));

export const createEventPermission = (data) =>
  callApi(`v1/event/permissions/`, defaultConfig("POST", data));

export const deleteEventInvitation = (data) =>
  callApi(
    `v1/event/${data.eventId}/invitations/${data.inviteId}`,
    defaultConfig("DELETE")
  );

export const deleteEventPermission = (data) =>
  callApi(
    `v1/event/${data.eventId}/permissions/${data.userId}`,
    defaultConfig("DELETE")
  );

export const updateEventInvitation = (data) =>
  callApi(
    `v1/event/${data.eventId}/invitations/${data.inviteId}`,
    defaultConfig("PUT")
  );

export const createBulkUpload = (data) =>
  callApi(`v1/event/${data.eventId}/bulk-upload`, defaultConfig("POST", data));

export const downloadGuestlistCSV = (data) =>
  downloadFile(
    `v1/export/performance/${data.id}/receipts/`,
    downloadConfig("text/csv"),
    "lineup-performance-guestlist.csv"
  );

export const exportOrders = (data) =>
  downloadFile(
    `v1/export/orders/`,
    downloadConfig("text/csv"),
    "lineup-orders-export.csv",
    {
      fromDate: data.from,
      toDate: data.to,
      ...(data.filter && { filter: data.filter }),
    }
  );
export const exportReceipts = (data) =>
  downloadFile(
    `v1/export/receipts/`,
    downloadConfig("text/csv"),
    "lineup-receipts-export.csv",
    {
      fromDate: data.from,
      toDate: data.to,
      ...(data.filter && { filter: data.filter }),
    }
  );
export const exportProductReceipts = (data) =>
  downloadFile(
    `v1/export/product-receipts/`,
    downloadConfig("text/csv"),
    "lineup-product-receipts-export.csv",
    {
      fromDate: data.from,
      toDate: data.to,
    }
  );

export const exportRefunds = (data) =>
  downloadFile(
    `v1/export/refunds/`,
    downloadConfig("text/csv"),
    "lineup-refunds-export.csv",
    {
      fromDate: data.from,
      toDate: data.to,
    }
  );

export const exportTicketPDF = (data) =>
  downloadFile(
    `v1/export/order/${data.id}/pdf/`,
    downloadConfig("application/pdf"),
    data.fileName,
    {}
  );

export const downloadGuestlistPDF = (data) =>
  downloadFile(
    `v1/export/performance/${data.id}/receipts/pdf/`,
    downloadConfig("application/pdf"),
    "lineup-performance-guestlist.pdf"
  );

export const exportSalesReportPDF = (data) =>
  downloadFile(
    `v1/export/event/${data.eventId}/sales-report/pdf/`,
    downloadConfig("application/pdf"),
    "sales-report.pdf",
    {}
  );

export const createProductRedeem = (receiptId) =>
  callApi(`v1/product-receipt/${receiptId}/redeem/`, defaultConfig("POST"));

export const deleteProductRedeem = (receiptId) =>
  callApi(`v1/product-receipt/${receiptId}/redeem/`, defaultConfig("DELETE"));

const webhookSchema = new schema.Entity("webhook");
const webhookListSchema = [webhookSchema];
export const fetchWebhooks = (data) =>
  callApi(`v1/webhook-endpoint/`, defaultConfig("GET"), webhookListSchema, {
    page: data.page,
    resultsPerPage: 50,
  });

export const fetchWebhookTypes = () =>
  callApi(`v1/webhook-type/`, defaultConfig("GET"));

export const createWebhook = (data) =>
  callApi(`v1/webhook-endpoint/`, defaultConfig("POST", data), webhookSchema);

export const updateWebhook = (data) =>
  callApi(
    `v1/webhook-endpoint/${data.id}/`,
    defaultConfig("PUT", data),
    webhookSchema
  );

export const deleteWebhook = (data) =>
  callApi(`v1/webhook-endpoint/${data.id}/`, defaultConfig("DELETE"));

export const fetchWebhook = (data) =>
  callApi(
    `v1/webhook-endpoint/${data.id}/`,
    defaultConfig("GET"),
    webhookSchema
  );
