import React from 'react';
import Form, { FormField } from '../../Form/Form';
import { Field, reduxForm } from 'redux-form';

let EditReceiptForm = props => {
  const { handleSubmit, error, submitting, onCancel } = props;
  return (
    <Form
      handleSubmit={handleSubmit}
      error={error}
      submitting={submitting}
      onCancel={onCancel}
      submitText={'Edit Receipts'}
      formTitle={'Edit Receipts'}
      guidanceNotes={'Use the form below to edit the selected receipts'}
    >
      <Field
        name="firstName"
        label="First Name"
        component={FormField}
        type="text"
      />
      <Field
        name="lastName"
        label="Last Name"
        component={FormField}
        type="text"
      />
    </Form>
  );
};

EditReceiptForm = reduxForm({
  form: 'receipt'
})(EditReceiptForm);

export default EditReceiptForm;
