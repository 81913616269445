import React from 'react';
import PageNav, { PageNavItem } from '../../../components/PageNav/PageNav';
import Can from '../../../components/Can';
import {
  Title,
  HeaderActions,
  ContentType,
} from '../../../components/MainContent/MainContent';

// Grommet
import { Box, Button, ResponsiveContext } from 'grommet';
import { Calendar, Share } from 'grommet-icons';

const EventNav = ({ title, eventId, eventSlug, role }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          {size !== 'small' ? (
            <div>
              <Box>
                <ContentType icon={<Calendar size='small' />} label='Event' />
                <Box
                  direction='row'
                  justify='between'
                  align='center'
                  margin={{ top: 'small' }}
                >
                  <Title>{title}</Title>
                  <HeaderActions>
                    <Button
                      primary
                      icon={<Share size='small' />}
                      href={`/event/${eventSlug}`}
                      target='_blank'
                      size='small'
                      label='View Event Page'
                    />
                  </HeaderActions>
                </Box>
                <Box margin={{ vertical: 'medium' }}>
                  <PageNav>
                    <Can
                      role={role}
                      perform='event:edit'
                      yes={() => (
                        <PageNavItem
                          to={`/event/${eventId}/edit`}
                          label='Info'
                        />
                      )}
                    />
                    <Can
                      role={role}
                      perform='event:view'
                      yes={() => (
                        <PageNavItem
                          to={`/event/${eventId}/performances`}
                          activeOnlyWhenExact={false}
                          label='Performances & Tickets'
                        />
                      )}
                    />
                    <Can
                      role={role}
                      perform='event:view'
                      yes={() => (
                        <PageNavItem
                          to={`/event/${eventId}/issue`}
                          label='Sell'
                        />
                      )}
                    />

                    {/* <PageNavItem
                      to={`/event/${eventId}/bulkupload`}
                      activeOnlyWhenExact={true}
                      label='Bulk Upload'
                    /> */}
                    <Can
                      role={role}
                      perform='event:view-financials'
                      yes={() => (
                        <PageNavItem
                          to={`/event/${eventId}/sales`}
                          label='Sales'
                        />
                      )}
                    />
                    <Can
                      role={role}
                      perform='event:manage-users'
                      yes={() => (
                        <PageNavItem
                          to={`/event/${eventId}/users`}
                          label='Manage Users'
                        />
                      )}
                    />
                    <Can
                      role={role}
                      perform='event:edit'
                      yes={() => (
                        <PageNavItem
                          to={`/event/${eventId}/email-schedule`}
                          label='Emails'
                        />
                      )}
                    />
                    <PageNavItem
                      to={`/event/${eventId}/embed-settings`}
                      label='Embed'
                    />
                    <Can
                      role={role}
                      perform='event:edit'
                      yes={() => (
                        <PageNavItem
                          to={`/event/${eventId}/tools`}
                          label='Tools'
                        />
                      )}
                    />
                    {/* <Can
                      role={role}
                      perform='event:edit'
                      yes={() => (
                        <PageNavItem
                          to={`/event/${eventId}/planner`}
                          label='Planner'
                        />
                      )}
                    /> */}
                  </PageNav>
                </Box>
              </Box>
            </div>
          ) : (
            <div>
              <Box>
                <ContentType icon={<Calendar size='small' />} label='Event' />
                <Box
                  direction='column'
                  justify='start'
                  align='start'
                  gap='medium'
                >
                  <Title>{title}</Title>
                  <Button
                    primary
                    icon={<Share size='small' />}
                    href={`/event/${eventSlug}`}
                    target='_blank'
                    size='small'
                    label='View Event Page'
                    fill
                  />
                </Box>
                <Box margin={{ top: 'medium', bottom: 'large' }}>
                  <PageNav mobileMenu='Event Menu'>
                    {[
                      {
                        label: (
                          <Can
                            role={role}
                            perform='event:edit'
                            yes={() => (
                              <PageNavItem
                                to={`/event/${eventId}/edit`}
                                label='Info'
                              />
                            )}
                          />
                        ),
                      },
                      {
                        label: (
                          <PageNavItem
                            to={`/event/${eventId}/performances`}
                            activeOnlyWhenExact={false}
                            label='Performances & Tickets'
                          />
                        ),
                      },
                      {
                        label: (
                          <PageNavItem
                            to={`/event/${eventId}/issue`}
                            label='Sell'
                          />
                        ),
                      },

                      {
                        label: (
                          <Can
                            role={role}
                            perform='event:view-financials'
                            yes={() => (
                              <PageNavItem
                                to={`/event/${eventId}/sales`}
                                label='Sales'
                              />
                            )}
                          />
                        ),
                      },
                      {
                        label: (
                          <Can
                            role={role}
                            perform='event:manage-users'
                            yes={() => (
                              <PageNavItem
                                to={`/event/${eventId}/users`}
                                label='Manage Users'
                              />
                            )}
                          />
                        ),
                      },
                      {
                        label: (
                          <Can
                            role={role}
                            perform='event:edit'
                            yes={() => (
                              <PageNavItem
                                to={`/event/${eventId}/email-schedule`}
                                label='Emails'
                              />
                            )}
                          />
                        ),
                      },
                      {
                        label: (
                          <PageNavItem
                            to={`/event/${eventId}/embed-settings`}
                            label='Embed'
                          />
                        ),
                      },

                      /* {
                        label: (
                          <Can
                            role={role}
                            perform='event:edit'
                            yes={() => (
                              <PageNavItem
                                to={`/event/${eventId}/planner`}
                                label='Planner'
                              />
                            )}
                          />
                        )
                      } */
                    ]}
                  </PageNav>
                </Box>
              </Box>
            </div>
          )}
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default EventNav;
