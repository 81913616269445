import React from 'react';
import { Link } from 'react-router-dom';

// Grommet
import { Anchor, Box, Image, Nav, Button } from 'grommet';
import { Next } from 'grommet-icons';

// Assets
import logo from 'assets/logo_alt.svg';

const PublicHeader = ({ isAuthenticated }) => {
  return (
    <Nav
      background='sidebar'
      justify='between'
      align='center'
      direction='row'
      pad={{ horizontal: 'medium', vertical: 'small' }}
    >
      <Anchor href='/'>
        <Box
          width={'100px'}
          height={'45px'}
          pad='none'
          justify='start'
          alignContent='start'
        >
          <Image src={logo} fit={'contain'} />
        </Box>
      </Anchor>
      <Box>
        {isAuthenticated ? (
          <Link to='/events' style={{ textDecoration: 'none', width: '100%' }}>
            <Button
              size='small'
              label='Dashboard'
              secondary
              icon={<Next size='small' />}
              reverse
            />
          </Link>
        ) : (
          <Box direction='row' gap='small'>
            <Box width='90px'>
              <Link
                to='/user/signup'
                style={{ textDecoration: 'none', width: '100%' }}
              >
                <Button label='Sign Up' primary size='small' fill />
              </Link>
            </Box>
            <Box width='90px'>
              <Link
                to='/user/login'
                style={{ textDecoration: 'none', width: '100%' }}
              >
                <Button label='Login' secondary size='small' fill />
              </Link>
            </Box>
          </Box>
        )}
      </Box>
    </Nav>
  );
};

export default PublicHeader;
