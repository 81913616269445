import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import { TableCell, TableRow } from '../../../../Table/Table';
import { Price, Tag } from '../../../../Utils/Utils';
import { FormattedDate } from 'react-intl';
/* eslint-disable react/style-prop-object */

import styled from 'styled-components';

import './ReceiptRowItem.css';

// Grommet
import { Box, Button } from 'grommet';
import { Checkmark, Login, Undo, StatusInfo, Clear } from 'grommet-icons';

const InfoIcon = styled(StatusInfo)`
  width: 16px;
  height: 16px;
`;

const UndoIcon = styled(Undo)`
  width: 16px;
  height: 16px;
`;

const RedeemIcon = styled(Checkmark)`
  width: 16px;
  height: 16px;
`;

const CancelledIcon = styled(Clear)`
  width: 16px;
  height: 16px;
`;

const ReceiptRowItem = ({
  receipt,
  onReceiptClick,
  isSelected,
  includeSeat,
  className,
  lineItemType,
  onReceiptRedeemClick
}) => {
  const refundTotal =
    receipt.outsideFeeTotalRefund + receipt.faceValueTotalRefund;
  const isFullyRefund = receipt.total - refundTotal === 0 && receipt.cancelled;
  const handleReceiptClick = () => {
    !isFullyRefund && onReceiptClick && onReceiptClick(receipt.id);
  };
  const handleReceiptRedeemClick = event => {
    event.stopPropagation();
    onReceiptRedeemClick(receipt, lineItemType);
  };
  const rowClass = classNames(className, 'receipt-row', {
    'receipt-row--selected': isSelected,
    'receipt-row--disabled': isFullyRefund
  });
  return (
    <TableRow striped={true} onClick={handleReceiptClick} className={rowClass}>
      <TableCell>
        {!isFullyRefund && (
          <input readOnly type='checkbox' checked={isSelected} />
        )}
      </TableCell>
      <TableCell>{receipt.reference}</TableCell>
      <TableCell>
        {receipt.firstName && `${receipt.firstName} ${receipt.lastName}`}
      </TableCell>
      <TableCell>{includeSeat && receipt.seat}</TableCell>
      <TableCell>
        <Box direction='row' gap='xsmall' align='center'>
          <Price
            amount={receipt.faceValueTotal - receipt.faceValueTotalRefund}
            currency={receipt.currency}
            showZero
          />
          {receipt.faceValueTotalRefund > 0 && (
            <Fragment>
              <InfoIcon
                data-tip=''
                data-for={`${lineItemType}-faceValue-${receipt.id}`}
              >
                <StatusInfo size='small' />
              </InfoIcon>
              <ReactTooltip id={`${lineItemType}-faceValue-${receipt.id}`}>
                <span>
                  <Price
                    amount={receipt.faceValueTotalRefund}
                    currency={receipt.currency}
                  />{' '}
                  refunded
                </span>
              </ReactTooltip>
            </Fragment>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Box direction='row' gap='xsmall' align='center'>
          <Price
            amount={receipt.outsideFeeTotal - receipt.outsideFeeTotalRefund}
            currency={receipt.currency}
            showZero
          />
          {receipt.outsideFeeTotalRefund > 0 && (
            <Fragment>
              <InfoIcon
                data-tip=''
                data-for={`${lineItemType}-outsideFee-${receipt.id}`}
              >
                <StatusInfo size='small' />
              </InfoIcon>
              <ReactTooltip id={`${lineItemType}-outsideFee-${receipt.id}`}>
                <span>
                  <Price
                    amount={receipt.outsideFeeTotalRefund}
                    currency={receipt.currency}
                  />{' '}
                  refunded
                </span>
              </ReactTooltip>
            </Fragment>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Price amount={receipt.discount} currency={receipt.currency} />
        <Tag text={receipt.discountCode} />
      </TableCell>
      <TableCell>
        <Price
          amount={receipt.total - refundTotal}
          currency={receipt.currency}
          showZero
        />
      </TableCell>
      <TableCell>
        <Box direction='row' gap='xsmall' align='center'>
          {receipt.cancelled && (
            <Fragment>
              <CancelledIcon
                data-for={`${lineItemType}-cancelled-${receipt.id}`}
                data-tip=''
              >
                <Clear size='small' />
              </CancelledIcon>
              <ReactTooltip id={`${lineItemType}-cancelled-${receipt.id}`}>
                <span>
                  Cancelled:{' '}
                  <FormattedDate
                    value={receipt.cancelledAt}
                    year='numeric'
                    day='numeric'
                    month='short'
                    weekday='short'
                  />
                </span>
              </ReactTooltip>
            </Fragment>
          )}
          {receipt.redeemed && (
            <Fragment>
              <RedeemIcon
                data-for={`${lineItemType}-redeemed-${receipt.id}`}
                data-tip=''
              >
                <Checkmark size='small' />
              </RedeemIcon>
              <ReactTooltip id={`${lineItemType}-redeemed-${receipt.id}`}>
                <span>
                  Redeemed:{' '}
                  <FormattedDate
                    value={receipt.redeemedAt}
                    year='numeric'
                    day='numeric'
                    month='short'
                    weekday='short'
                  />
                </span>
              </ReactTooltip>
            </Fragment>
          )}
          {refundTotal > 0 && (
            <Fragment>
              <UndoIcon
                data-for={`${lineItemType}-total-${receipt.id}`}
                data-tip=''
              >
                <Undo size='small' />
              </UndoIcon>
              <ReactTooltip id={`${lineItemType}-total-${receipt.id}`}>
                <span>
                  {isFullyRefund ? 'Fully refunded:' : 'Partially refunded:'}{' '}
                  <Price
                    amount={
                      receipt.outsideFeeTotalRefund +
                      receipt.faceValueTotalRefund
                    }
                    currency={receipt.currency}
                  />
                </span>
              </ReactTooltip>
            </Fragment>
          )}
        </Box>
      </TableCell>
      <TableCell>
        {!receipt.cancelled && (lineItemType === 'tickets' || lineItemType === 'products') && (
          <>
            {!receipt.redeemed ? (
              <Button
                secondary
                onClick={handleReceiptRedeemClick}
                label={lineItemType === 'products' ? 'Redeem' : 'Check In'}
                size='small'
                icon={<Login size='small' />}
                fill
              />
            ) : (
              <Button
                secondary
                color='checkedin'
                onClick={handleReceiptRedeemClick}
                label={lineItemType === 'products' ? 'Redeemed' : 'Checked In'}
                size='small'
                icon={<Checkmark size='small' />}
                fill
              />
            )}
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default ReceiptRowItem;
