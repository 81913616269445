import React from 'react';
import range from 'lodash/range';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Pagination.css';

const PaginationPageItem = ({ page, currentPage, onPageClick }) => {
  const handlePageClick = e => {
    e.preventDefault();
    onPageClick(page);
  };
  return page === currentPage ? (
    <strong className="paginator__link paginator__current-page">
      {' '}
      {page}{' '}
    </strong>
  ) : (
    <button onClick={handlePageClick} className="paginator__link">
      {' '}
      {page}{' '}
    </button>
  );
};

const Pagination = ({
  currentPage,
  totalPages,
  leftEdge = 3,
  rightEdge = 3,
  leftCurrent = 2,
  rightCurrent = 2,
  onSelectPage
}) => {
  const getPagesArray = range(1, totalPages + 1).filter(page => {
    if (
      page <= leftEdge ||
      (page > currentPage - leftCurrent - 1 &&
        page < currentPage + rightCurrent) ||
      page >= totalPages - rightEdge
    ) {
      return page;
    }
    return false;
  });

  let previousPage = 0;

  const handleSelectPage = page => {
    onSelectPage(page);
  };
  const handlePreviousPageClick = e => {
    e.preventDefault();
    if (currentPage !== 1) {
      handleSelectPage(currentPage - 1);
    }
  };

  const handleNextPageClick = e => {
    e.preventDefault();
    if (currentPage !== totalPages) {
      handleSelectPage(currentPage + 1);
    }
  };

  const renderPage = (page, i) => {
    let pageTag = (
      <PaginationPageItem
        page={page}
        currentPage={currentPage}
        onPageClick={handleSelectPage}
      />
    );
    if (previousPage + 1 !== page) {
      pageTag = ' ... ';
    }
    previousPage = page;
    return <span key={i}>{pageTag}</span>;
  };
  return (
    <div>
      {totalPages > 1 && (
        <div className="paginator">
          {currentPage !== 1 && (
            <button
              onClick={handlePreviousPageClick}
              className="paginator__link paginator__link--directional"
            >
              <FontAwesomeIcon icon="chevron-left" fixedWidth />
            </button>
          )}
          {getPagesArray.map(renderPage)}
          {currentPage !== totalPages && (
            <button
              onClick={handleNextPageClick}
              className="paginator__link paginator__link--directional"
            >
              <FontAwesomeIcon icon="chevron-right" fixedWidth />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Pagination;
