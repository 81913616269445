import React from 'react';
import PerformanceRowItem from './PerformanceRowItem';
import Table, {
  TableBody,
  TableHeader,
  TableCell,
} from '../../../components/Table/Table';

const PerformanceTable = ({ performances, onGeneratePerformanceLink }) => (
  <Table mobileWide>
    <TableHeader>
      <TableCell width={30}>Start Date</TableCell>
      <TableCell width={20}>Start Time</TableCell>
      <TableCell width={10}>Status</TableCell>
      <TableCell center width={10}>
        Sold
      </TableCell>
      <TableCell center width={20}>
        Capacity Remaining
      </TableCell>
      <TableCell width={10} />
    </TableHeader>
    <TableBody
      renderItem={(performance) => (
        <PerformanceRowItem
          performance={performance}
          key={performance.id}
          onGeneratePerformanceLink={onGeneratePerformanceLink}
        />
      )}
      items={performances}
    />
  </Table>
);

export default PerformanceTable;
