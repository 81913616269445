const AUTH_KEY = 'lineup_auth';

export const setAuthenticationKeys = user => {
  window.lukey = user.keys.apiKey;
  window.p_lukey = user.keys.publicApiKey;
  window.ticket_api_key = user.keys.ticketApiKey;
  window.users_api_key = user.keys.usersApiKey;
  window.orders_api_key = user.keys.ordersApiKey;
};

export const saveUserDataClientSide = user => {
  localStorage.setItem(AUTH_KEY, JSON.stringify(user));
};

export const getUserDataClientSide = () =>
  localStorage.getItem(AUTH_KEY) !== null
    ? JSON.parse(localStorage.getItem(AUTH_KEY))
    : false;

export const clearUserDataClientSide = () => localStorage.removeItem(AUTH_KEY);
