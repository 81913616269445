import React from "react";

import PageNavLink from "./PageNavLink";
import PageNavButton from "./PageNavButton";
import "./PageNavItem.css";

const PageNavItem = ({ to, onClick, ...props }) =>
  to ? (
    <PageNavLink to={to} {...props} />
  ) : (
    <PageNavButton onClick={onClick} {...props} />
  );

export default PageNavItem;
