import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import MainContent from 'components/MainContent/MainContent';
import BusinessSettingsForm from 'components/BusinessSettingsForm/BusinessSettingsForm';
import { useHistory } from 'react-router-dom';
import { accountActions, accountSelectors } from 'modules/account/account';
import { bindActionCreators } from 'redux';

const StripeOnboardingComplete = ({
  code,
  currencies,
  createStripeSettings,
  createBusinessSettings,
  getCurrencies,
}) => {
  let history = useHistory();

  useEffect(() => {
    if (currencies.length === 0) {
      getCurrencies();
    }
  }, [getCurrencies, currencies.length]);

  useEffect(() => {
    if (code) {
      createStripeSettings({ code: code });
    }
  }, [createStripeSettings, code]);

  const handleBusinessSettingsFormSubmit = values => {
    createBusinessSettings(values);
    history.push('/account/ticket-sales');
  };
  return (
    <MainContent>
      <BusinessSettingsForm
        onBusinessSettingsFormSubmit={handleBusinessSettingsFormSubmit}
        currencies={currencies}
      />
    </MainContent>
  );
};
const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;
  const query = new URLSearchParams(location.search);
  const code = query.get('code');
  return {
    code,
    currencies: accountSelectors.getCurrencies(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        ...accountActions,
      },
      dispatch
    ),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StripeOnboardingComplete);
