import React from 'react';
import * as Yup from 'yup';
import FormikField from 'components/Form/FormikField';
import FormikForm from 'components/Form/FormikForm';
import FormField from 'components/Form/FormField/FormField';

const validationSchema = Yup.object({
  emailAddress: Yup.string()
    .email('Invalid email address')
    .required('An email address is required'),
});

const ForgotPasswordForm = ({ onSubmit }) => {
  return (
    <>
      <FormikForm
        validationSchema={validationSchema}
        initialValues={{ emailAddress: '' }}
        onSubmit={onSubmit}
        cancelBtn={false}
        submitText='Reset Password'
        formTitle='Forgot Password'
        guidanceNotes="Enter your email address below and we'll email you a link to change your password"
        hasborder
        heavypadding
        headercentred
        submitfull
      >
        <FormikField
          name='emailAddress'
          label='Email Address*'
          component={FormField}
          type='email'
          placeholder='Your email address'
        />
      </FormikForm>
    </>
  );
};

export default ForgotPasswordForm;
