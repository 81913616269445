export const FETCH_TICKET_ALLOCATION =
  'line-up/ticket-allocation/FETCH_TICKET_ALLOCATION';
export const FETCH_TICKET_ALLOCATIONS =
  'line-up/ticket-allocation/FETCH_TICKET_ALLOCATIONS';
export const CREATE_TICKET_ALLOCATION =
  'line-up/ticket-allocation/CREATE_TICKET_ALLOCATION';
export const UPDATE_TICKET_ALLOCATION =
  'line-up/ticket-allocation/UPDATE_TICKET_ALLOCATION';
export const DELETE_TICKET_ALLOCATION =
  'line-up/ticket-allocation/DELETE_TICKET_ALLOCATION';

export const ADD_CUSTOMER_DATA = 'line-up/ticket-allocation/ADD_CUSTOMER_DATA';
export const ADD_DISCOUNT = 'line-up/ticket-allocation/ADD_DISCOUNT';
export const ADD_AGENT = 'line-up/ticket-allocation/ADD_AGENT';
export const REMOVE_DISCOUNT = 'line-up/ticket-allocation/REMOVE_DISCOUNT';
export const REMOVE_AGENT = 'line-up/ticket-allocation/REMOVE_AGENT';

export const ISSUE_TICKET = 'line-up/ticket-allocation/ISSUE_TICKET';
