import React from 'react';
import moment from 'moment';
import {
  aggregatorTemplates as tpl,
  numberFormat,
  derivers
} from '../../../components/PivotTable/Utilities';
import PivotTable from '../../../components/PivotTable/PivotTable';
import ReportSection from '../../../components/ReportSection/ReportSection';

const SalesByPerformance = ({ data, title, exportFilename }) => {
  const usFmtInt = numberFormat({ digitsAfterDecimal: 0 });
  const fmt = numberFormat({ prefix: '£' });
  const sortDates = (left, right) => {
    return moment
      .utc(left, 'ddd, DD MMM YYYY HH:mm')
      .diff(moment.utc(right, 'ddd, DD MMM YYYY HH:mm'));
  };
  return (
    <ReportSection>
      <PivotTable
        title={title}
        exportFilename={exportFilename}
        data={data}
        aggregators={{
          'Qty Sold': () => tpl.sum(usFmtInt)(['Sold']),
          Comps: () => tpl.sum(usFmtInt)(['Comp']),
          Gross: () => tpl.sum(fmt)(['Face Value Gross'])
        }}
        derivedAttributes={{
          Channel: record =>
            record['Seller'] === 'Direct' ? 'Direct Sales' : 'Agency Sales',
          Price: record => fmt(Math.abs(record['Face Value Gross'])),
          Sold: record => (record['Gross'] !== 0 ? record['Quantity'] : 0),
          Comp: record => (record['Gross'] === 0 ? record['Quantity'] : 0),
          'Event Date': derivers.dateFormat(
            'Start Date Time',
            '%w, %d %n %y %H:%M',
            true
          )
        }}
        rows={['Event Date', 'Ticket Description', 'Discount Code', 'Price']}
        cols={['Channel']}
        sorters={{
          'Event Date': sortDates
        }}
      />
    </ReportSection>
  );
};

export default SalesByPerformance;
