import { combineReducers } from 'redux';
import * as actions from './actions';

const user = (
  state = {
    triedRecoveringAuth: false,
    data: {},
  },
  action
) => {
  switch (action.type) {
    case actions.authenticateUser.SUCCESS:
    case actions.reAuth.SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
      };
    }
    case actions.recoverAuth.FULFILL:
    case actions.reAuth.FULFILL: {
      return {
        ...state,
        triedRecoveringAuth: true,
      };
    }
    case actions.recoverAuth.SUCCESS: {
      return {
        ...state,
        data: action.payload.user,
      };
    }
    case actions.logoutUser.SUCCESS: {
      return {
        triedRecoveringAuth: false,
        data: {},
      };
    }
    case actions.createUser.SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
      };
    }
    case actions.updateUser.SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
      };
    }
    case actions.updateUserProfile.SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
      };
    }
    default:
      return state;
  }
};

const subscription = (
  state = {
    data: null,
  },
  action
) => {
  switch (action.type) {
    case actions.getSubscription.SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
      };
    }
    case actions.updateSubscription.SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
      };
    }
    default:
      return state;
  }
};

const payment = (
  state = {
    fetched: false,
    fetching: false,
    triggered: false,
    fulfilled: false,
    data: null,
  },
  action
) => {
  switch (action.type) {
    case actions.getPaymentMethod.TRIGGER:
      return {
        ...state,
        triggered: true,
      };
    case actions.getPaymentMethod.REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case actions.getPaymentMethod.FULFILL:
      return {
        ...state,
        fulfilled: true,
        fetching: false,
      };
    case actions.getPaymentMethod.SUCCESS:
      return {
        ...state,
        data: action.payload.response,
        fetched: true,
      };
    case actions.getPaymentMethod.FAILURE:
      return {
        ...state,
        error: action.payload.response,
      };
    case actions.createPaymentMethod.SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
      };
    }
    default:
      return state;
  }
};

const stripeSettings = (
  state = {
    data: null,
  },
  action
) => {
  switch (action.type) {
    case actions.getStripeSettings.SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
      };
    }
    case actions.createStripeSettings.SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
      };
    }
    case actions.deleteStripeSettings.SUCCESS: {
      return {
        ...state,
        data: {},
      };
    }
    default:
      return state;
  }
};

const businessSettings = (
  state = {
    data: null,
  },
  action
) => {
  switch (action.type) {
    case actions.getBusinessSettings.SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
      };
    }
    case actions.createBusinessSettings.SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
      };
    }
    case actions.deleteStripeSettings.SUCCESS: {
      return {
        ...state,
        data: null,
      };
    }
    default:
      return state;
  }
};

const businessSettingsLoaded = (state = false, action) => {
  switch (action.type) {
    case actions.getBusinessSettings.REQUEST: {
      return false;
    }
    case actions.getBusinessSettings.SUCCESS: {
      return true;
    }
    case actions.getBusinessSettings.FAILURE: {
      return true;
    }
    default:
      return state;
  }
};
const currencies = (
  state = {
    data: [],
  },
  action
) => {
  switch (action.type) {
    case actions.getCurrencies.SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
      };
    }
    default:
      return state;
  }
};
const currenciesLoaded = (state = false, action) => {
  switch (action.type) {
    case actions.getCurrencies.REQUEST: {
      return false;
    }
    case actions.getCurrencies.SUCCESS: {
      return true;
    }
    case actions.getCurrencies.FAILURE: {
      return true;
    }
    default:
      return state;
  }
};
const stripeSettingsLoaded = (state = false, action) => {
  switch (action.type) {
    case actions.getStripeSettings.REQUEST: {
      return false;
    }
    case actions.getStripeSettings.SUCCESS: {
      return true;
    }
    case actions.getStripeSettings.FAILURE: {
      return false;
    }
    default:
      return state;
  }
};
const reducer = combineReducers({
  user,
  subscription,
  payment,
  stripeSettings,
  businessSettings,
  businessSettingsLoaded,
  stripeSettingsLoaded,
  currencies,
  currenciesLoaded,
});

export default reducer;
