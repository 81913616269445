import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Form, { FormField, FormFieldReactSelect } from '../Form/Form';
import { required, url } from '../../utils/validators';
import { webhookSelectors } from 'modules/webhooks/webhooks';

const validate = values => {
  const errors = {};
  if (values.types && values.types.length == 0) {
    errors.types = 'Webhook Event Type is required';
  }
  return errors;
};

let WebhookForm = props => {
  const { reset, webhookId, webhookTypeOptions, handleSubmit, error } = props;
  return (
    <Form
      {...props}
      submitText={webhookId ? 'Update' : 'Submit'}
      cancelText='Reset'
      onCancel={reset}
      handleSubmit={handleSubmit}
      error={error}
      formTitle={webhookId ? 'Edit Webhook' : 'Create Webhook'}
      guidanceNotes={
        webhookId
          ? 'Use the form below to edit the webhook'
          : 'Use the form below to create a new webhook'
      }
    >
      <Field
        name='url'
        component={FormField}
        label='URL'
        type='text'
        placeholder='Type the full URL here eg: https://www.lineupnow.com'
        validate={[required, url]}
      />
      <Field
        name='types'
        component={FormFieldReactSelect}
        label={'Webhook Event Type'}
        isClearable={false}
        isMulti
        options={webhookTypeOptions}
        placeholder='Select the webhook event type from drop down'
      />
    </Form>
  );
};

WebhookForm = reduxForm({
  form: 'webhook',
  validate,
})(WebhookForm);

const mapStateToProps = (state, ownProps) => {
  const webhookId = ownProps.webhookId;
  let initialValues;
  if (webhookId) {
    const webhook = webhookSelectors.getWebhook(state, webhookId);
    initialValues = {
      url: webhook.url,
      types: webhook.webhookEventTypes
        ? webhook.webhookEventTypes.map(type => ({
            label: type.name,
            value: type.id,
          }))
        : [],
    };
  }
  return {
    initialValues,
  };
};

export default connect(mapStateToProps)(WebhookForm);
