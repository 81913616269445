import { combineReducers } from 'redux';

// pagination : {
//   events: {
//     currentPage: 1,
//     totalPages:10,
//     resultsPerPage:100
//     pages: {
//       1: {
//         ids: [1,2,3],
//         fetching: false
//       }
//     }
//   }

// }
// Creates a reducer managing pagination, given the action types to handle,
// and a function telling how to extract the key from an action.

const paginate = ({ types, mapActionToKey }) => {
  if (!Array.isArray(types) || !(types.length === 4 || types.length === 3)) {
    throw new Error('Expected types to be an array of four elements.');
  }
  if (!types.every(t => typeof t === 'string')) {
    throw new Error('Expected types to be strings.');
  }
  if (typeof mapActionToKey !== 'function') {
    throw new Error('Expected mapActionToKey to be a function.');
  }

  let requestType = null;
  let successType = null;
  let failureType = null;
  let fulfillType = 'optional/fulfill';

  if (types.length === 4) {
    [requestType, successType, failureType, fulfillType] = types;
  } else {
    [requestType, successType, failureType] = types;
  }

  const updatePagination = (
    state = {
      isFulfilled: false,
      isFetching: false,
      ids: []
    },
    action
  ) => {
    switch (action.type) {
      case requestType:
        return {
          ...state,
          ids: [],
          isFetching: true
        };
      case successType:
        return {
          ...state,
          isFetching: false,
          ids: action.payload.response.result
        };
      case failureType:
        return {
          ...state,
          ids: [],
          isFetching: false,
          error: action.error
        };
      case fulfillType:
        return {
          ...state,
          isFulfilled: true
        };
      default:
        return state;
    }
  };

  const currentPage = (currentPage = 1, action = {}) => {
    return action.type === requestType ? action.payload.page : currentPage;
  };
  const totalPages = (totalPages = 1, action = {}) => {
    return action.type === successType
      ? action.payload.response
        ? action.payload.response.pagination.totalPages
        : totalPages
      : totalPages;
  };

  const pages = (state = {}, action = {}) => {
    switch (action.type) {
      case requestType:
      case successType:
      case failureType:
      case fulfillType:
        const page = action.payload.page;
        return {
          ...state,
          [page]: updatePagination(state[page], action)
        };
      default:
        return state;
    }
  };

  return combineReducers({
    pages,
    currentPage,
    totalPages
  });
};

export default paginate;

export const getIdsForPage = (state, page) =>
  state.pages[page] ? state.pages[page].ids : [];
export const getCurrentPage = state => state.currentPage;
export const getTotalPages = state => state.totalPages;
export const getIsLoading = (state, page) =>
  state.pages[page] ? state.pages[page].isFetching : false;
export const getIsFulfilled = (state, page) =>
  state.pages[page] ? state.pages[page].isFulfilled : false;
