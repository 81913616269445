import { fetchEntity, submitForm } from '../shared/operations';
import { takeEvery } from 'redux-saga/effects';
import * as actions from './actions';
import * as api from '../../services/api';

const fetchEventEmailSchedules = fetchEntity.bind(
  null,
  actions.fetchEventEmailSchedules,
  api.fetchEventEmailSchedules
);

export function* watchFetchEventEmailSchedules() {
  yield takeEvery(
    actions.fetchEventEmailSchedules.TRIGGER,
    fetchEventEmailSchedules
  );
}

const fetchEventEmailSchedule = fetchEntity.bind(
  null,
  actions.fetchEventEmailSchedule,
  api.fetchEventEmailSchedule
);

export function* watchFetchEventEmailSchedule() {
  yield takeEvery(
    actions.fetchEventEmailSchedule.TRIGGER,
    fetchEventEmailSchedule
  );
}

const updateEventEmailSchedule = submitForm.bind(
  null,
  actions.updateEventEmailSchedule,
  api.updateEventEmailSchedule
);

export function* watchUpdateEventEmailSchedule() {
  yield takeEvery(
    actions.updateEventEmailSchedule.TRIGGER,
    updateEventEmailSchedule
  );
}

const createEventEmailSchedule = submitForm.bind(
  null,
  actions.createEventEmailSchedule,
  api.createEventEmailSchedule
);

export function* watchCreateEventEmailSchedule() {
  yield takeEvery(
    actions.createEventEmailSchedule.TRIGGER,
    createEventEmailSchedule
  );
}

const deleteEventEmailSchedule = submitForm.bind(
  null,
  actions.deleteEventEmailSchedule,
  api.deleteEventEmailSchedule
);

export function* watchDeleteEventEmailSchedule() {
  yield takeEvery(
    actions.deleteEventEmailSchedule.TRIGGER,
    deleteEventEmailSchedule
  );
}
