import React from 'react';

// Grommet
import { Box } from 'grommet';

const ContentContainer = ({ children }) => {
  return <Box>{children}</Box>;
};

export default ContentContainer;
