import React from 'react';
import styles from './PlaceholderCell.module.css';
import classNames from 'classnames';

const PlaceholderCell = ({ layout, pendingPlacements }) => {
  const style = {
    top: `${layout.top}px`,
    height: `${layout.height}px`
  };

  const rootClassName = classNames(styles.placeholderCell, {
    [styles.notEnoughSpaceWarning]: pendingPlacements.length > 0 ? true : false
  });

  return <div style={style} className={rootClassName} />;
};

export default PlaceholderCell;
