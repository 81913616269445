import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { toast } from 'react-toastify';
// components
import EventAttendeeSyncForm from '../../../components/Mailchimp/EventAttendeeSyncForm/EventAttendeeSyncForm';

import {
  mailchimpActions,
  mailchimpSelectors,
} from '../../../modules/mailchimp/mailchimp';

// Assets
import connectMailchimp from '../../mailchimp/connect_mailchimp.png';

// Grommet
import { Heading, Box, Button, Text, Image, ResponsiveContext } from 'grommet';

const MailchimpSection = ({
  eventId,
  connected,
  isFetching,
  lists,
  fetchMailchimpIntegration,
  fetchMailchimpLists,
  submitSyncEventAttendeesToMailchimp,
}) => {
  // load our data
  React.useEffect(() => {
    fetchMailchimpIntegration();
    fetchMailchimpLists();
  }, [fetchMailchimpIntegration, fetchMailchimpLists]);

  const handleSave = values => {
    return submitSyncEventAttendeesToMailchimp({
      eventId: eventId,
      mainListId: values.mainListId,
    }).then((result) => {
      toast.success(result.response.message);
    });
  };

  if (isFetching) {
    return null;
  }

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          {connected ? (
            <Box>
              <EventAttendeeSyncForm onSubmit={handleSave} lists={lists} />
            </Box>
          ) : (
            <>
              <Box
                background='#fff194'
                round='xsmall'
                direction={size !== 'small' ? 'row' : 'column'}
                wrap={false}
                align={size !== 'small' ? 'start' : 'center'}
                gap='medium'
                pad={size !== 'small' ? 'medium' : 'large'}
              >
                <Box
                  width={
                    size !== 'small'
                      ? { min: '160px', max: '160px' }
                      : { min: '80%', max: '80%' }
                  }
                  justify={size !== 'small' ? 'end' : 'start'}
                  align={size !== 'small' ? 'end' : 'center'}
                >
                  <Image
                    src={connectMailchimp}
                    alt='Connect your Mailchimp account'
                    title='Connect your Mailchimp account'
                    className='mailchimp-manage-image-image'
                    fit='contain'
                    width='160px'
                  />
                </Box>
                <Box
                  gap='medium'
                  width={size !== 'small' ? 'calc(100% - 160px)' : '90%'}
                  align={size !== 'small' ? 'start' : 'center'}
                >
                  <Heading
                    level='4'
                    size='small'
                    color='heading'
                    margin={{ vertical: 'none' }}
                  >
                    Mailchimp Export
                  </Heading>
                  <Text>
                    Connect your Mailchimp account to Line-Up to export your
                    event attendees into a Mailchimp Audience.
                  </Text>

                  <Button
                    secondary
                    href='/mailchimp'
                    label='Go to Mailchimp Settings'
                    size='small'
                    fill={size !== 'small' ? false : true}
                  />
                </Box>
              </Box>
            </>
          )}
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    connected: mailchimpSelectors.isMailchimpConnected(state),
    isFetching: mailchimpSelectors.isFetchingLists(state),
    lists: mailchimpSelectors.getMailchimpLists(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        ...mailchimpActions,
      },
      dispatch
    ),
    ...bindPromiseCreators(
      {
        submitSyncEventAttendeesToMailchimp:
          mailchimpActions.submitSyncEventAttendeesToMailchimp,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MailchimpSection);
