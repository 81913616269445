import * as fromPaginator from '../shared/paginate';
import * as fromEntities from '../shared/entities';

export const getProduct = (state, id) =>
  fromEntities.getEntity(state.entities.product, id);

export const getProductsForPage = (state, page) => {
  const ids = fromPaginator.getIdsForPage(state.products.byPage, page);
  return ids.map(id => getProduct(state, id));
};

export const getProductsCurrentPage = state =>
  fromPaginator.getCurrentPage(state.products.byPage);

export const getProductsTotalPages = state =>
  fromPaginator.getTotalPages(state.products.byPage);

export const getProductsLoadingStatus = state => state.products.loading;
