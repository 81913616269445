import React from 'react';
import { FormattedDate } from 'react-intl';
/* eslint-disable react/style-prop-object */
import { TableCell, TableRow } from '../../components/Table/Table';

const WaitingListRowItem = ({ email, joinedAt, lastEmailedAt, onClick }) => {
  return (
    <TableRow striped={true}>
      <TableCell>{email}</TableCell>
      <TableCell>
        <FormattedDate
          value={joinedAt}
          weekday='short'
          year='numeric'
          
          day='numeric'
          month='short'
          hour='numeric'
          minute='numeric'
        />
      </TableCell>
      <TableCell>
        {lastEmailedAt && (
          <FormattedDate
            value={lastEmailedAt}
            weekday='short'
            year='numeric'
            
            day='numeric'
            month='short'
            hour='numeric'
            minute='numeric'
          />
        )}
      </TableCell>
      <TableCell />
    </TableRow>
  );
};

export default WaitingListRowItem;
