import { combineReducers } from 'redux';
import paginate from '../shared/paginate';
import * as types from './types';
//- Reducers

const byPage = paginate({
  mapActionToKey: action => action.page,
  types: [
    types.WAITING_LIST.REQUEST,
    types.WAITING_LIST.SUCCESS,
    types.WAITING_LIST.FAILURE
  ]
});

const waitingList = combineReducers({
  byPage
});

export default waitingList;
