import React from 'react';
import Table, {
  TableCell,
  TableBody,
  TableHeader
} from '../../components/Table/Table';
import TicketGroupRowItem from './TicketGroupRowItem';

const TicketGroupTable = ({
  ticketGroups,
  onTicketGroupClick,
  onTicketGroupBtnClick
}) => (
  <Table striped>
    <TableHeader>
      <TableCell width={50}>Name</TableCell>
      <TableCell width={35}>Description</TableCell>
      <TableCell width={15} />
    </TableHeader>
    <TableBody
      renderItem={ticketGroup => (
        <TicketGroupRowItem
          ticketGroup={ticketGroup}
          key={ticketGroup.id}
          onClick={onTicketGroupClick}
          onBtnClick={onTicketGroupBtnClick}
        />
      )}
      items={ticketGroups}
    />
  </Table>
);

export default TicketGroupTable;
