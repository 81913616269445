import React from 'react';
import { FormattedDate } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { float } from 'utils/validators';
import { FormattedNumber } from 'react-intl';
/* eslint-disable react/style-prop-object */
import Form, { FormField } from 'components/Form/Form';
import InfoBlock, {
  InfoBlockList,
  InfoBlockListItem,
} from 'components/InfoBlock/InfoBlock';
import Button from 'components/Button/Button';
import FormFieldColourSelect from 'components/Form/FormFieldColourSelect/FormFieldColourSelect';

import { getPerformanceStartDate } from 'utils/dates';

const validate = values => {
  let errors = {};
  if (!values.firstName) {
    errors.firstName = 'This field is required';
  }
  if (!values.lastName) {
    errors.lastName = 'This field is required';
  }
  if (!values.price) {
    errors.price = 'Please enter a price for this reservation';
  }

  return errors;
};

let ConfirmReservationForm = ({
  onCancel,
  onDelete,
  handleSubmit,
  placement,
  submitting,
}) => {
  const startDate = getPerformanceStartDate(placement.eventPerformance);
  const price = (
    <FormattedNumber
      value={placement.ticketAllocation.ticket.feeTotal}
      style='currency'
      currency={placement.ticketAllocation.ticket.currency}
    />
  );
  const bookingFeeLabel = <>Include {price} Booking fee?</>;

  return (
    <Form
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      formTitle='Confirm reservation'
      guidanceNotes='Use the form below to confirm your reservation'
      submitText='Confirm Reservation'
      submitting={submitting}
    >
      <Field
        label='First name'
        name='firstName'
        component={FormField}
        type='text'
        placeholder='First name'
      />

      <Field
        label='Last name'
        name='lastName'
        component={FormField}
        type='text'
        placeholder='Last name'
      />

      <Field
        name='price'
        component={FormField}
        label='Ticket Price'
        type='number'
        step='any'
        normalize={float}
        placeholder='E.g. 10'
        fieldShort={true}
      />
      {placement.ticketAllocation.ticket.feeTotal > 0 && (
        <Field
          name='includeFee'
          labelAfter={bookingFeeLabel}
          id='includeFee'
          component={FormField}
          type='checkbox'
        />
      )}

      <Field
        name='colour'
        label='Colour'
        isClearable={false}
        component={FormFieldColourSelect}
      />

      <InfoBlock>
        <InfoBlockList>
          <InfoBlockListItem
            attribute='Allocation'
            value={placement.ticketAllocation.description}
          />
          <InfoBlockListItem
            attribute='Lane'
            value={placement.eventPlannerLane.name + ` (x 1)`}
          />
          <InfoBlockListItem
            attribute='Date'
            value={
              <FormattedDate
                value={startDate}
                weekday='short'
                year='numeric'
                
                day='numeric'
                month='short'
                hour='numeric'
                minute='numeric'
              />
            }
          />
        </InfoBlockList>
      </InfoBlock>

      <Button type='button' danger nomargin onClick={onDelete}>
        Delete this reservation
      </Button>
    </Form>
  );
};

ConfirmReservationForm = reduxForm({
  form: 'confirmReservationForm',
  validate,
})(ConfirmReservationForm);

export default ConfirmReservationForm;
