import { combineReducers } from 'redux';
import listIds from '../shared/listIds';
import * as types from './types';
//- Reducers

const byId = listIds({
  mapActionToKey: action => action.page,
  types: [
    types.ALL_TICKET_RECEIPTS.REQUEST,
    types.TICKET_RECEIPTS.SUCCESS,
    types.ALL_TICKET_RECEIPTS.FAILURE
  ]
});

const searchTerm = (state = null, action) => {
  switch (action.type) {
    case types.SEARCH_RECEIPTS:
      return action.payload.searchTerm;
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case types.ALL_TICKET_RECEIPTS.REQUEST:
      return true;
    case types.ALL_TICKET_RECEIPTS.SUCCESS:
    case types.ALL_TICKET_RECEIPTS.FAILURE:
      return false;
    default:
      return state;
  }
};
const error = (state = null, action) => {
  switch (action.type) {
    case types.REDEEM_RECEIPT.REQUEST:
    case types.REDEEM_RECEIPT.SUCCESS:
      return null;
    case types.REDEEM_RECEIPT.FAILURE:
      return action.payload;
    default:
      return state;
  }
};
const ticketReceipts = combineReducers({
  byId,
  searchTerm,
  loading,
  error
});

export default ticketReceipts;
