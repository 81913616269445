import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';

import Modal from 'components/Modal/Modal';
import Form from 'components/Form/Form';

import { hideModal } from 'modules/modal/modal';

const DisableLaneConfirmModal = ({
  hideModal,
  lane,
  onDisableChange,
  isDisabled
}) => {
  const handleSubmit = () => {
    onDisableChange({
      ...lane,
      isDisabled: !isDisabled
    });
  };

  return (
    <Modal onRequestClose={() => hideModal()}>
      <DisableLaneForm
        isDisabled={isDisabled}
        lane={lane}
        onCancel={hideModal}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

let DisableLaneForm = ({ lane, onCancel, handleSubmit, isDisabled }) => (
  <>
    {isDisabled ? (
      <Form
        formTitle="Are you sure you want to activate this lane?"
        submitText="Yes, activate this lane"
        onCancel={onCancel}
        handleSubmit={handleSubmit}
      >
        You are about to activate this lane. This will mean:
        <ul>
          <li>
            Bookings can be added, and removed from this lane by your team
          </li>
          <li>New bookings will be automatically placed here</li>
        </ul>
      </Form>
    ) : (
      <Form
        formTitle="Are you sure you want to disable this lane?"
        submitText="Yes, disable this lane"
        handleSubmit={handleSubmit}
        onCancel={onCancel}
      >
        You are about to disable this lane. This will mean:
        <ul>
          <li>New bookings will not be automatically placed here</li>
          <li>Your team will not be able to move bookings into this lane</li>
        </ul>
      </Form>
    )}
  </>
);

DisableLaneForm = reduxForm({
  form: 'disableLaneConfirmForm'
})(DisableLaneForm);

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        hideModal
      },
      dispatch
    )
  };
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisableLaneConfirmModal);
