import React from 'react';
import {
  aggregatorTemplates as tpl,
  numberFormat
} from '../../../components/PivotTable/Utilities';
import PivotTable from '../../../components/PivotTable/PivotTable';
import ReportSection from '../../../components/ReportSection/ReportSection';

const Discounts = ({ data, title, exportFilename }) => {
  const usFmtInt = numberFormat({ digitsAfterDecimal: 0 });
  const fmt = numberFormat({ prefix: '£' });
  return (
    <ReportSection>
      <PivotTable
        title={title}
        exportFilename={exportFilename}
        data={data}
        rows={['Discount Code', 'Ticket Description']}
        aggregators={{
          'Qty Used': () => tpl.sum(usFmtInt)(['Quantity']),
          'Gross Potential': () => tpl.sum(fmt)(['Gross Potential']),
          'Discount Amount': () => tpl.sum(fmt)(['Discount']),
          'Ticket Value': () => tpl.sum(fmt)(['Gross'])
        }}
        rowSubTotals={true}
        rowSubTotalName={'Total'}
        derivedAttributes={{
          'Gross Potential': record => record['Discount'] + record['Gross']
        }}
        valueFilter={{
          'Discount Code': { '': false }
        }}
      />
    </ReportSection>
  );
};

export default Discounts;
