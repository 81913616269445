import React from 'react';
import { Link } from 'react-router-dom';

import Table, { TableCell, TableBody, TableHeader } from '../../Table/Table';
import Pagination from '../../Pagination/Pagination';
import SearchBar from '../../SearchBar/SearchBar';
import EmptyState from '../../EmptyState/EmptyState';
import { HeaderActions, Title, PageTop } from '../../MainContent/MainContent';
import EventRowItem from './EventRowItem';
// import Button from "../../Button/Button";
import withEmptyState from '../../withEmptyState';

// Grommet
import { Box, Button, ResponsiveContext } from 'grommet';
import { Add } from 'grommet-icons';

// Images
import emptyPerformances from './empty_events.png';

export const EventEmptyState = () => (
  <EmptyState
    background
    buttonLink='/event/new/create'
    buttonText='Add New Event'
    showButton
    emptyStateHeadline='You are ready to create some Events'
    emptyStateDescription='When you have events, they can be managed from here, along with their performances and tickets.'
    emptyStateImageSrc={emptyPerformances}
    emptyStateImageAlt='No future events'
    emptyStateImageTitle='No future events'
  />
);

const EventList = ({
  onSearchTextChange,
  onSelectPage,
  onEventClick,
  events,
  totalPages,
  currentPage
}) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <PageTop>
            <Title>Events</Title>
            <HeaderActions>
              <Link to='/event/new/create'>
                <Button
                  primary
                  label='Create Event'
                  size='small'
                  icon={<Add size='small' />}
                  fill={true}
                />
              </Link>
            </HeaderActions>
          </PageTop>
          <Box margin={{ vertical: 'medium' }}>
            <SearchBar onTextChange={onSearchTextChange} />
          </Box>
          <Box margin={{ vertical: 'small' }}>
            <Table tableMargin='0 0 3rem 0'>
              <>
                <TableHeader>
                  <TableCell width={70}>Event</TableCell>
                  <TableCell width={30} />
                </TableHeader>
              </>

              <TableBody
                renderItem={event => (
                  <EventRowItem
                    event={event}
                    key={event.id}
                    onClick={onEventClick}
                  />
                )}
                items={events}
              />
            </Table>
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onSelectPage={onSelectPage}
            />
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default withEmptyState(EventList);
