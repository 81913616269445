import React from 'react';
import { FormattedDate } from 'react-intl';
import { getPerformanceStartDate } from '../../utils/dates';

export const FormattedStartDate = ({ performance }) => (
  <FormattedDate
    value={getPerformanceStartDate(performance)}
    weekday='short'
    year='numeric'
    day='numeric'
    month='short'
    hour='numeric'
    minute='numeric'
  />
);
