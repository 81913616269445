export const FETCH_PAYMENT_REPORT = 'line-up/reports/FETCH_PAYMENT_REPORT';
export const FETCH_TICKET_DATA = 'line-up/reports/FETCH_TICKET_DATA';
export const FETCH_EVENTS = 'line-up/reports/FETCH_EVENTS';
export const SELECT_EVENT = 'line-up/reports/SELECT_EVENT';
export const SET_DATE_RANGE = 'line-up/reports/SET_DATE_RANGE';

export const ADD_FILTER = 'line-up/reports/ADD_FILTER';
export const UPDATE_FILTER = 'line-up/reports/UPDATE_FILTER';
export const SET_FILTERS = 'line-up/reports/SET_FILTERS';

export const REMOVE_FILTER = 'line-up/reports/REMOVE_FILTER';
export const EXPORT_SALES_REPORT_PDF =
  'line-up/reports/EXPORT_SALES_REPORT_PDF';
