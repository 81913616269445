import React from 'react';
import { connect } from 'react-redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { bindActionCreators } from 'redux';

// components
import Modal from '../../components/Modal/Modal';
import EmailPreviewForm from '../../components/EmailPreviewForm/EmailPreviewForm';

// other
import { emailActions } from '../../modules/emails/emails';
import { hideModal } from '../../modules/modal/modal';

const EmailSendPreviewModal = ({
  hideModal,
  submitSendEmailPreviewForm,
  emailId
}) => {
  const handleSubmit = values => {
    return submitSendEmailPreviewForm({
      emailId: emailId,
      ...values
    }).then(() => hideModal());
  };

  return (
    <Modal onRequestClose={() => hideModal()}>
      <EmailPreviewForm onSubmit={handleSubmit} />
    </Modal>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        submitSendEmailPreviewForm: emailActions.submitSendEmailPreview
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        hideModal
      },
      dispatch
    )
  };
};

export default connect(
  null,
  mapDispatchToProps
)(EmailSendPreviewModal);
