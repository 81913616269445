import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

// Grommet

import { Nav, Menu, ResponsiveContext, Text, Button } from 'grommet';
import {
  Favorite,
  Calendar,
  ContactInfo,
  List,
  LineChart,
  Tag,
  Tools,
  Directions,
  Cube
} from 'grommet-icons';
import { Menu as MenuIcon } from 'grommet-icons';

const LinkWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const MenuLink = styled(Text)`
  color: #edf2ff;
  font-size: 14px;
  padding-bottom: 3px;
  &:hover {
    color: white;
    font-weight: 900;
  }
`;

const MenuLinkIconWrapper = styled.span`
  margin-right: 0.75rem;
`;

const MenuButton = styled(Button)`
  border: 1px solid rgba(255, 255, 255, 0.6);
  color: #edf2ff;
  &:hover {
    background: #050c21;
    color: white;
  }
`;

const items = [
  {
    label: 'Events',
    href: '/events',
    icon: <Calendar size='small' color='sidebar-copy' />
  },

  {
    label: 'Orders',
    href: '/orders/1',
    icon: <List size='small' color='sidebar-copy' />
  },
  {
    label: 'Customers',
    href: '/customers/1',
    icon: <ContactInfo size='small' color='sidebar-copy' />
  },
  {
    label: 'Reports',
    href: '/reports/',
    icon: <LineChart size='small' color='sidebar-copy' />
  },
  {
    label: 'Products',
    href: '/products/1',
    icon: <Cube size='small' color='sidebar-copy' />
  },
  {
    label: 'Agents',
    href: '/agents/1/',
    icon: <Directions size='small' color='sidebar-copy' />
  },
  {
    label: 'Discounts',
    href: '/discounts/1',
    icon: <Tag size='small' color='sidebar-copy' />
  },
  {
    label: 'Donations',
    href: '/donation/1',
    icon: <Favorite size='small' color='sidebar-copy' />
  },
  {
    label: 'Tools',
    href: '/tools/',
    icon: <Tools size='small' color='sidebar-copy' />
  }
];

// const routePartialMatch = (routePath, currentPath) => {
//   const routeParts = routePath.split("/");
//   const matchOn = `/${routeParts[1]}`;
//   if (currentPath.indexOf(matchOn) === 0) {
//     return true;
//   }
//   return false;
// };

const AppMenu = () => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          {size !== 'small' ? (
            <Nav pad='none' gap='small'>
              {items.map(item => (
                <NavLink
                  to={item.href}
                  key={item.label}
                  activeStyle={{
                    fontWeight: '900'
                  }}
                  style={{ textDecoration: 'none' }}
                >
                  <LinkWrapper>
                    <MenuLinkIconWrapper>{item.icon}</MenuLinkIconWrapper>
                    <MenuLink>{item.label}</MenuLink>
                  </LinkWrapper>
                </NavLink>
              ))}
            </Nav>
          ) : (
            <Menu
              plain
              icon={false}
              dropProps={{
                align: { top: 'bottom', left: 'left' },
                elevation: 'large',
                round: 'small',
                width: '100%'
              }}
              dropBackground='sidebar'
              label={[
                <MenuButton
                  size='small'
                  label='Menu'
                  icon={<MenuIcon size='small' />}
                />
              ]}
              items={items.map(item => ({
                label: (
                  <NavLink
                    to={item.href}
                    key={item.label}
                    activeStyle={{
                      fontWeight: '900'
                    }}
                    style={{ textDecoration: 'none', width: '100%' }}
                  >
                    <LinkWrapper>
                      <MenuLinkIconWrapper>{item.icon}</MenuLinkIconWrapper>
                      <MenuLink>{item.label}</MenuLink>
                    </LinkWrapper>
                  </NavLink>
                )
              }))}
            />
          )}
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default AppMenu;
