import React from 'react';
import { connect } from 'react-redux';
import { hideModal } from 'modules/modal/modal';
import Spinner from 'react-spinkit';

// Containers
import OrderDetail from 'containers/order/OrderDetail';

// Components
import Modal from 'components/Modal/Modal';
import { orderSelectors } from 'modules/orders/orders';

// Grommet
import { Box, Button } from 'grommet';

const OrderDetailModal = ({ hideModal, orderId, isLoading }) => {
  const handleCloseModal = () => {
    hideModal();
  };
  return (
    <Modal onRequestClose={handleCloseModal}>
      <Box background={{ color: 'white' }} height={isLoading ? 'large' : ''}>
        <OrderDetail orderId={orderId} readOnly />
        {!isLoading && (
          <Box margin={{ horizontal: 'medium', bottom: 'medium' }}>
            <Button
              secondary
              onClick={handleCloseModal}
              size='small'
              label='Close'
            />
          </Box>
        )}
      </Box>
    </Modal>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: orderSelectors.getOrderLoadingStatus(state),
  };
};
export default connect(
  mapStateToProps,
  { hideModal }
)(OrderDetailModal);
