import {
  call,
  takeLatest,
  put,
  fork,
  select,
  takeEvery
} from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { arrayPush } from 'redux-form';
import * as types from './types';
import * as actions from './actions';
import * as api from '../../services/api';
import { getTicketGroup } from './selectors';

function* createTicketGroup(action) {
  yield put(actions.ticketGroupCreate.request());
  const { response, error } = yield call(
    api.createTicketGroup,
    action.payload.ticketGroup
  );
  if (response) {
    yield put(actions.ticketGroupCreate.success(response));
    const ticketGroup = yield select(getTicketGroup, response.result);
    yield put(
      arrayPush('discount', 'ticketGroups', {
        label: ticketGroup.name,
        value: ticketGroup.slug
      })
    );
  } else {
    yield put(actions.ticketGroupCreate.failure(error));
  }
}

export function* watchCreateTicketGroup() {
  yield takeLatest(types.TICKET_GROUP_CREATE, createTicketGroup);
}

function* fetchTicketGroup(action) {
  yield put(actions.ticketGroup.request(action.payload.ticketGroupId));
  const { response, error } = yield call(
    api.getTicketGroup,
    action.payload.ticketGroupId
  );
  if (response) {
    yield put(
      actions.ticketGroup.success(action.payload.ticketGroupId, response)
    );
  } else {
    yield put(actions.ticketGroup.failure(action.payload.ticketGroupId, error));
  }
}

function* fetchTicketGroups(page) {
  yield put(actions.ticketGroups.request(page));
  const { response, error } = yield call(api.getTicketGroups, page);
  if (response) {
    yield put(actions.ticketGroups.success(page, response));
    return response;
  } else {
    yield put(actions.ticketGroups.failure(page, error));
  }
}

function* fetchPagedTicketGroups(action) {
  let page = 1;
  const response = yield call(fetchTicketGroups, page);
  if (response) {
    const { pagination } = response;
    while (page < pagination.totalPages) {
      page++;
      yield fork(fetchTicketGroups, page);
    }
  }
}

function* fetchAllTicketGroups(action) {
  yield put(showLoading());
  yield put(actions.allTicketGroups.request());
  yield call(fetchPagedTicketGroups, action);
  yield put(actions.allTicketGroups.success());
  yield put(hideLoading());
}

export function* watchLoadTicketGroups() {
  yield takeLatest(types.LOAD_ALL, fetchAllTicketGroups);
}

export function* watchLoadTicketGroup() {
  yield takeLatest(types.LOAD, fetchTicketGroup);
}

function* addTicket(action) {
  yield put(showLoading());
  const { ticketGroupId, ticketId } = action.payload;
  yield put(actions.ticketAdd.request(ticketGroupId, ticketId));
  const { response, error } = yield call(
    api.addTicketToTicketGroup,
    ticketGroupId,
    ticketId
  );
  if (response) {
    yield put(actions.ticketAdd.success(ticketGroupId, ticketId));
  } else {
    yield put(actions.ticketAdd.failure(ticketGroupId, ticketId, error));
  }
  yield put(hideLoading());
}

function* removeTicket(action) {
  yield put(showLoading());
  const { ticketGroupId, ticketId } = action.payload;
  yield put(actions.ticketRemove.request(ticketGroupId, ticketId));
  const { response, error } = yield call(
    api.removeTicketFromTicketGroup,
    ticketGroupId,
    ticketId
  );
  if (response) {
    yield put(actions.ticketRemove.success(ticketGroupId, ticketId));
  } else {
    yield put(actions.ticketRemove.failure(ticketGroupId, ticketId, error));
  }
  yield put(hideLoading());
}

export function* watchAddTicket() {
  yield takeEvery(types.ADD_TICKET, addTicket);
}

export function* watchRemoveTicket() {
  yield takeEvery(types.REMOVE_TICKET, removeTicket);
}

function* fetchTicketGroupTickets(ticketGroupId, page) {
  yield put(actions.ticketGroupTickets.request(ticketGroupId, page));
  const { response, error } = yield call(
    api.getTicketGroupTickets,
    ticketGroupId,
    page
  );
  if (response) {
    yield put(
      actions.ticketGroupTickets.success(ticketGroupId, page, response)
    );
    return response;
  } else {
    yield put(actions.ticketGroupTickets.failure(ticketGroupId, page, error));
  }
}

function* fetchPagedTicketGroupTickets(action) {
  let page = 1;
  const ticketGroupId = action.payload.ticketGroupId;
  const response = yield call(fetchTicketGroupTickets, ticketGroupId, page);
  if (response) {
    const { pagination } = response;
    while (page < pagination.totalPages) {
      page++;
      yield fork(fetchTicketGroupTickets, ticketGroupId, page);
    }
  }
}

function* fetchAllTicketGroupTickets(action) {
  yield put(showLoading());
  yield put(
    actions.allTicketGroupTickets.request(action.payload.ticketGroupId)
  );
  yield call(fetchPagedTicketGroupTickets, action);
  yield put(
    actions.allTicketGroupTickets.success(action.payload.ticketGroupId)
  );
  yield put(hideLoading());
}

export function* watchLoadTicketGroupTickets() {
  yield takeLatest(types.LOAD_ALL_TICKETS, fetchAllTicketGroupTickets);
}
