import React, { Fragment } from 'react';

import Table, { TableCell, TableHeader, TableBody } from '../Table/Table';

import PerformanceRowItem from './PerformanceRowItem/PerformanceRowItem';
import TicketRowItem from './TicketRowItem/TicketRowItem';

import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from 'react-accessible-accordion';

import './BulkUploadTable.css';

const BulkUploadTable = ({ fileData, currency }) => {
  return (
    <>
      <Table containerBorder mobileWide>
        <TableHeader>
          <TableCell width={5} />
          <TableCell width={20}>START DATE</TableCell>
          <TableCell width={15} center>
            START TIME
          </TableCell>
          <TableCell width={15} center>
            END TIME
          </TableCell>
          <TableCell width={10} center>
            CAPACITY
          </TableCell>
          <TableCell width={15} center>
            STATUS
          </TableCell>
          <TableCell width={10} center>
            SEATING CHART ID
          </TableCell>
          <TableCell width={10} />
        </TableHeader>
      </Table>
      <Table>
        <TableBody
          renderItem={(row, index) => (
            <Fragment
              key={`${row.date.format('ddd MMMM Do, YYYY')}${row.start_time}`}
            >
              <Accordion accordion={false} className='line-items'>
                <AccordionItem expanded={index < 3}>
                  <AccordionItemTitle>
                    <Table mobileWide>
                      <PerformanceRowItem
                        date={row.date.format('ddd MMMM Do, YYYY')}
                        start_time={row.start_time}
                        end_time={row.end_time}
                        capacity={row.capacity}
                        performance_status={row.performance_status}
                        seating_chart_id={row.seating_chart_id}
                      />
                    </Table>
                  </AccordionItemTitle>
                  <AccordionItemBody>
                    <Table>
                      <TableHeader>
                        <TableCell width={5} texttop />
                        <TableCell width={15} texttop>
                          TICKET NAME
                        </TableCell>
                        <TableCell width={20} texttop>
                          DESCRIPTION
                        </TableCell>
                        <TableCell width={10} texttop center>
                          STATUS
                        </TableCell>
                        <TableCell width={10} texttop center>
                          QUANTITY
                        </TableCell>
                        <TableCell width={10} texttop center>
                          FACE VALUE
                        </TableCell>
                        <TableCell width={8} texttop center>
                          FEE
                        </TableCell>
                        <TableCell width={10} texttop center>
                          TOTAL
                        </TableCell>
                        <TableCell width={10} texttop center>
                          MAX PER ORDER
                        </TableCell>
                        <TableCell width={10} texttop center>
                          MIN PER ORDER
                        </TableCell>
                        <TableCell width={12} texttop center>
                          SEATING CAT. KEY
                        </TableCell>
                      </TableHeader>

                      {row.tickets.map(ticket => (
                        <TicketRowItem
                          key={`${row.date.format('ddd MMMM Do, YYYY')}${
                            row.start_time
                          }${ticket.ticket_name}`}
                          name={ticket.ticket_name}
                          description={ticket.ticket_description}
                          ticket_status={ticket.ticket_status}
                          quantity={ticket.quantity}
                          face_value={ticket.ticket_face_value}
                          currency={currency}
                          booking_fee={ticket.booking_fee}
                          total={ticket.ticket_face_value + ticket.booking_fee}
                          seating_category_key={ticket.seating_category_key}
                          max_per_order={ticket.max_per_order}
                          min_per_order={ticket.min_per_order}
                        />
                      ))}
                    </Table>
                  </AccordionItemBody>
                </AccordionItem>
              </Accordion>
            </Fragment>
          )}
          items={fileData}
        />
      </Table>
    </>
  );
};

export default BulkUploadTable;
