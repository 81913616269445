import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import cx from 'classnames';

import styles from './LanePlacement.module.css';
import TicketReceiptPlacement from 'components/Planner/TicketReceiptPlacement';
import TicketAllocationPlacement from 'components/Planner/TicketAllocationPlacement';
import { usePlanner } from './Provider';

import { isLaneDisabledForDate, isLaneLockedForDate } from './layoutUtils';

const LanePlacement = ({ placement, performance, layout, onClick }) => {
  const {
    movePlacement,
    currentSelectedDay,
    setDraggingPlacement,
    draggingPlacement
  } = usePlanner();
  const targetName = `TARGET_${placement.performanceId}`;

  const [dropProps, drop] = useDrop({
    accept: targetName,
    canDrop: (item, monitor) => {
      return (
        !isLaneDisabledForDate(
          placement.eventPlannerLane,
          currentSelectedDay
        ) &&
        !isLaneLockedForDate(placement.eventPlannerLane, currentSelectedDay)
      );
    },
    drop: (item, monitor) => {
      return {
        placementAction: 'swapPlacements',
        sourcePlacementId: item.id,
        destinationPlacementId: placement.id
      };
    },
    collect: (monitor, props) => {
      return {
        isHovering: monitor.isOver({ shallow: true })
      };
    }
  });

  const [dragProps, drag] = useDrag({
    canDrag: monitor => {
      return !(
        isLaneDisabledForDate(placement.eventPlannerLane, currentSelectedDay) ||
        isLaneLockedForDate(placement.eventPlannerLane, currentSelectedDay)
      );
    },
    item: {
      id: placement.id,
      type: targetName
    },
    begin: monitor => {
      setDraggingPlacement(placement);
    },
    end: (item, monitor) => {
      const dropData = {
        ...monitor.getDropResult()
      };

      if (dropData.placementAction) {
        movePlacement(dropData);
      }

      setDraggingPlacement(false);
    },
    collect: monitor => {
      return {
        isDragging: monitor.isDragging()
      };
    }
  });

  const style = {
    top: `${layout.top}px`,
    width: `${layout.width}px`,
    height: `${layout.height}px`
  };
  if (layout.left) {
    style.left = `${layout.left}px`;
  }

  // when placement is being dragged, change the opacity, this will affect the
  // div that's in the lane, not the div being dragged
  if (dragProps.isDragging) {
    style.opacity = '0';
  }

  const handleClick = evt => {
    evt.preventDefault();
    onClick(placement);
  };

  let switchToDropTarget = false;

  // When we're dragging a placement, and this component is not the placement that
  // we're dragging, we convert the placement to a drop target
  if (draggingPlacement && draggingPlacement.id !== placement.id) {
    switchToDropTarget = true;
  }

  const rootClass = cx(styles.root, {
    [styles.switchAnimation]: dropProps.isHovering
  });

  return (
    <div
      className={rootClass}
      style={style}
      ref={switchToDropTarget ? drop : drag}
    >
      {placement.isTicketReceipt ? (
        <TicketReceiptPlacement onClick={handleClick} placement={placement} />
      ) : (
        <TicketAllocationPlacement
          onClick={handleClick}
          placement={placement}
        />
      )}
    </div>
  );
};

export default LanePlacement;
