import { takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "../../services/api";
import * as actions from "./actions";

import {
  fetchEntity,
  submitForm,
  createEntity,
  deleteEntity,
} from "../shared/operations";

const fetchTicketsForPage = fetchEntity.bind(
  null,
  actions.fetchTicketsForPage,
  api.fetchTicketsForPage
);

export function* watchFetchTicketsForPage() {
  yield takeLatest(actions.fetchTicketsForPage.TRIGGER, fetchTicketsForPage);
}

const fetchUserSettings = fetchEntity.bind(
  null,
  actions.fetchUserSettings,
  api.fetchUserSettings
);

export function* watchFetchUserSettings() {
  yield takeEvery(actions.fetchUserSettings.TRIGGER, fetchUserSettings);
}

const fetchTicket = fetchEntity.bind(
  null,
  actions.fetchTicket,
  api.fetchTicket
);

export function* watchFetchTicket() {
  yield takeEvery(actions.fetchTicket.TRIGGER, fetchTicket);
}

const createTicket = submitForm.bind(
  null,
  actions.createTicket,
  api.createTicket
);

const updateTicket = submitForm.bind(
  null,
  actions.updateTicket,
  api.updateTicket
);

export function* watchCreateTicket() {
  yield takeEvery(actions.createTicket.TRIGGER, createTicket);
}

export function* watchUpdateTicket() {
  yield takeEvery(actions.updateTicket.TRIGGER, updateTicket);
}

const deleteTicket = deleteEntity.bind(
  null,
  actions.deleteTicket,
  api.deleteTicket
);

export function* watchDeleteTicket() {
  yield takeLatest(actions.deleteTicket.TRIGGER, deleteTicket);
}

const createTicketTransfer = createEntity.bind(
  null,
  actions.createTicketTransfer,
  api.createTicketTransfer
);

export function* watchCreateTicketTransfer() {
  yield takeEvery(actions.createTicketTransfer.TRIGGER, createTicketTransfer);
}
