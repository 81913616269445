import * as fromPaginator from '../shared/paginate';
import * as fromEntities from '../shared/entities';

export const getAgent = (state, agentId) =>
  fromEntities.getEntity(state.entities.agent, agentId);

export const getAgentsLoadingStatus = state => state.agents.loading;

export const getAgentsForPage = (state, page) => {
  const ids = fromPaginator.getIdsForPage(state.agents.byPage, page);
  return ids.map(id => fromEntities.getEntity(state.entities.agent, id));
};

export const getAgentsCurrentPage = state =>
  fromPaginator.getCurrentPage(state.agents.byPage);

export const getAgentsTotalPages = state =>
  fromPaginator.getTotalPages(state.agents.byPage);

export const getInvoicesByPage = (state, agentId) =>
  state.agents.invoicesByAgent[agentId]
    ? state.agents.invoicesByAgent[agentId]
    : {
        currentPage: 1,
        totalPages: 1,
        pages: {}
      };
export const getInvoicesCurrentPage = (state, agentId) =>
  fromPaginator.getCurrentPage(getInvoicesByPage(state, agentId));
export const getInvoicesTotalPages = (state, agentId) =>
  fromPaginator.getTotalPages(getInvoicesByPage(state, agentId));
export const getInvoicesLoading = (state, agentId, page) =>
  fromPaginator.getIsLoading(getInvoicesByPage(state, agentId));
export const getInvoicesForPage = (state, agentId, page) => {
  const ids = fromPaginator.getIdsForPage(
    getInvoicesByPage(state, agentId),
    page
  );
  return ids.map(id => fromEntities.getEntity(state.entities.invoice, id));
};
