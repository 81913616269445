import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Modal from '../../components/Modal/Modal';
import { hideModal } from '../../modules/modal/modal';
import ExportForm from '../../components/ExportForm/ExportForm';

class ExportModal extends Component {
  handleOnSubmit = values => {
    let fromDate;
    let toDate = moment
      .utc()
      .endOf('day')
      .toISOString();
    switch (values.date) {
      case 'today':
        fromDate = moment
          .utc()
          .startOf('day')
          .toISOString();
        break;
      case 'yesterday':
        fromDate = moment
          .utc()
          .startOf('day')
          .subtract(1, 'days')
          .toISOString();
        toDate = moment
          .utc()
          .endOf('day')
          .subtract(1, 'days')
          .toISOString();
        break;
      case 'sevenDays':
        fromDate = moment
          .utc()
          .startOf('day')
          .subtract(7, 'days')
          .toISOString();
        break;
      case 'lastMonth':
        fromDate = moment(moment.utc())
          .subtract(1, 'months')
          .startOf('month')
          .toISOString();
        toDate = moment(moment.utc())
          .subtract(1, 'months')
          .endOf('month')
          .toISOString();
        break;
      case 'custom':
        fromDate = moment
          .utc(values.dateRange.from)
          .startOf('day')
          .toISOString();
        toDate = moment
          .utc(values.dateRange.to)
          .endOf('day')
          .toISOString();
        break;
      default:
    }
    this.props.onDateRangeSelected({
      from: fromDate,
      to: toDate,
    });
  };

  render() {
    return (
      <Modal onRequestClose={() => this.props.hideModal()}>
        <ExportForm
          title={this.props.title}
          onSubmit={this.handleOnSubmit}
          onSubmitSuccess={() => this.props.hideModal()}
          onCancel={this.props.hideModal}
        />
      </Modal>
    );
  }
}

export default connect(
  null,
  {
    hideModal,
  }
)(ExportModal);
