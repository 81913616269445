import { takeEvery, put, fork, call } from "redux-saga/effects";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { SubmissionError } from "redux-form";
import * as api from "../../services/api";
import * as actions from "./actions";
import * as types from "./types";
import {
  fetchEntity,
  submitForm,
  deleteEntity,
  createEntity,
} from "../shared/operations";

const fetchOrder = fetchEntity.bind(null, actions.fetchOrder, api.fetchOrder);
const fetchOrderNotes = fetchEntity.bind(
  null,
  actions.fetchOrderNotes,
  api.fetchOrderNotes
);
const fetchOrderFormResponses = fetchEntity.bind(
  null,
  actions.fetchOrderFormResponses,
  api.fetchOrderFormResponses
);

export function* watchFetchOrder() {
  yield takeEvery(actions.fetchOrder.TRIGGER, fetchOrder);
  yield takeEvery(actions.fetchOrder.TRIGGER, fetchOrderNotes);
  yield takeEvery(actions.fetchOrder.TRIGGER, fetchOrderFormResponses);
}

const createNoteOnOrder = submitForm.bind(
  null,
  actions.createNoteOnOrder,
  api.createNoteOnOrder
);
export function* watchCreateNoteOnOrder() {
  yield takeEvery(actions.createNoteOnOrder.TRIGGER, createNoteOnOrder);
}

const updateNoteOnOrder = submitForm.bind(
  null,
  actions.updateNoteOnOrder,
  api.updateNote
);
export function* watchUpdateNoteOnOrder() {
  yield takeEvery(actions.updateNoteOnOrder.TRIGGER, updateNoteOnOrder);
}

const deleteNoteOnOrder = deleteEntity.bind(
  null,
  actions.deleteNoteOnOrder,
  api.deleteNote
);
export function* watchDeleteNoteOnOrder() {
  yield takeEvery(actions.deleteNoteOnOrder.TRIGGER, deleteNoteOnOrder);
}

const sendOrderConfirmation = createEntity.bind(
  null,
  actions.sendOrderConfirmation,
  api.sendOrderConfirmation
);
export function* watchResendOrderConfirmation() {
  yield takeEvery(actions.sendOrderConfirmation.TRIGGER, sendOrderConfirmation);
}

const createRefund = createEntity.bind(
  null,
  actions.createRefund,
  api.createRefund
);

export function* watchCreateRefund() {
  yield takeEvery(actions.createRefund.TRIGGER, createRefund);
  yield takeEvery(actions.createRefund.SUCCESS, function* refreshOrder(action) {
    yield put(actions.fetchOrder({ orderId: action.payload.orderId }));
  });
}

function* editReceipt(receiptId, values, kind = "ticket") {
  yield put(actions.editReceipt.request({ receiptId, values, kind }));
  const apiFn =
    kind === "ticket" ? api.editTicketReceipt : api.editProductReceipt;
  const { response, error } = yield call(apiFn, { receiptId, values });
  if (response) yield put(actions.editReceipt.success({ response, receiptId }));
  else {
    yield put(actions.editReceipt.failure(error));
    throw new SubmissionError(error.errors);
  }
}

function* editAllReceipts(action) {
  const { ticketReceipts, productReceipts, values } = action.payload;
  for (let id of ticketReceipts) yield fork(editReceipt, id, values, "ticket");
  for (let id of productReceipts)
    yield fork(editReceipt, id, values, "product");
}

function* editReceipts(action) {
  yield put(showLoading());
  try {
    yield call(editAllReceipts, action);
    yield put(actions.editReceipts.success({ action }));
    yield put(hideLoading());
  } catch (e) {
    yield put(actions.editReceipts.failure(e));
  }
}

export function* watchEditReceipts() {
  yield takeEvery(actions.editReceipts.TRIGGER, editReceipts);
}

const deleteRefund = deleteEntity.bind(
  null,
  actions.deleteRefund,
  api.deleteRefund
);

export function* watchDeleteRefund() {
  yield takeEvery(actions.deleteRefund.TRIGGER, deleteRefund);
  yield takeEvery(actions.deleteRefund.SUCCESS, function* refreshOrder(action) {
    yield put(actions.fetchOrder({ orderId: action.payload.orderId }));
  });
}

const exportOrders = fetchEntity.bind(
  null,
  actions.exportOrders,
  api.exportOrders
);
export function* watchExportOrders() {
  yield takeEvery(actions.exportOrders, exportOrders);
}

const exportReceipts = fetchEntity.bind(
  null,
  actions.exportReceipts,
  api.exportReceipts
);
export function* watchExportReceipts() {
  yield takeEvery(actions.exportReceipts, exportReceipts);
}

const exportProductReceipts = fetchEntity.bind(
  null,
  actions.exportProductReceipts,
  api.exportProductReceipts
);
export function* watchExportProductReceipts() {
  yield takeEvery(actions.exportProductReceipts, exportProductReceipts);
}

const exportRefunds = fetchEntity.bind(
  null,
  actions.exportRefunds,
  api.exportRefunds
);

export function* watchExportRefunds() {
  yield takeEvery(actions.exportRefunds, exportRefunds);
}

const exportTicketPDF = fetchEntity.bind(
  null,
  actions.exportTicketPDF,
  api.exportTicketPDF
);
export function* watchExportTicketPDF() {
  yield takeEvery(actions.exportTicketPDF, exportTicketPDF);
}

function* receiptRedeem(action) {
  const { receipt, orderId, lineItemType } = action.payload;
  yield put(showLoading());
  let response = null;
  if (lineItemType === "products") {
    response = receipt.redeemed
      ? yield call(api.deleteProductRedeem, receipt.id)
      : yield call(api.createProductRedeem, receipt.id);
  } else {
    response = receipt.redeemed
      ? yield call(api.deleteCheckin, receipt.id)
      : yield call(api.createCheckin, receipt.id);
  }

  if (response) {
    yield put(actions.fetchOrder.trigger({ orderId: orderId }));
  }
  yield put(hideLoading());
}

export function* watchReceiptRedeem() {
  yield takeEvery(types.RECEIPT_REDEEM, receiptRedeem);
}
