import { combineReducers } from 'redux';
import * as types from './types';
//- Reducers

const updateTicketGroup = (
  state = {
    isFetching: false,
    ids: [],
    currentPage: 1
  },
  action
) => {
  switch (action.type) {
    case types.ALL_TICKET_GROUP_TICKETS.REQUEST:
      return {
        ...state,
        ids: [],
        isFetching: true
      };
    case types.TICKET_GROUP_TICKETS.SUCCESS:
      return {
        ...state,
        ids: [...state.ids, ...action.payload.response.result]
      };
    case types.ALL_TICKET_GROUP_TICKETS.SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case types.ALL_TICKET_GROUP_TICKETS.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        ids: []
      };
    case types.TICKET_ADD.SUCCESS:
      return {
        ...state,
        ids: [...state.ids, action.payload.ticketId]
      };
    case types.TICKET_REMOVE.SUCCESS:
      return {
        ...state,
        ids: state.ids.filter(id => id !== action.payload.ticketId)
      };
    case types.SELECT_TICKET_GROUP_TICKETS_PAGE:
      return {
        ...state,
        currentPage: action.payload.page
      };
    default:
      return state;
  }
};

const ticketsByTicketGroup = (state = {}, action) => {
  switch (action.type) {
    case types.TICKET_GROUP_TICKETS.REQUEST:
    case types.ALL_TICKET_GROUP_TICKETS.REQUEST:
    case types.TICKET_GROUP_TICKETS.SUCCESS:
    case types.ALL_TICKET_GROUP_TICKETS.SUCCESS:
    case types.ALL_TICKET_GROUP_TICKETS.FAILURE:
    case types.TICKET_ADD.SUCCESS:
    case types.TICKET_REMOVE.SUCCESS:
    case types.SELECT_TICKET_GROUP_TICKETS_PAGE:
      const ticketGroupId = action.payload.ticketGroupId;
      return {
        ...state,
        [ticketGroupId]: updateTicketGroup(state[ticketGroupId], action)
      };
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case types.ALL_TICKET_GROUPS.REQUEST:
      return true;
    case types.ALL_TICKET_GROUPS.SUCCESS:
    case types.ALL_TICKET_GROUPS.FAILURE:
      return false;
    default:
      return state;
  }
};

const creating = (state = false, action) => {
  switch (action.type) {
    case types.CREATE.REQUEST:
      return true;
    case types.CREATE.SUCCESS:
    case types.CREATE.FAILURE:
      return false;
    default:
      return state;
  }
};

const newTicketGroup = (state = null, action) => {
  switch (action.type) {
    case types.CREATE.REQUEST:
    case types.ALL_TICKET_GROUPS.REQUEST:
      return null;
    case types.CREATE.SUCCESS:
      return action.payload.response.result;
    case types.CREATE.FAILURE:
      return null;
    default:
      return state;
  }
};

const byId = (state = [], action) => {
  switch (action.type) {
    case types.ALL_TICKET_GROUPS.REQUEST:
      return [];
    case types.TICKET_GROUPS.SUCCESS:
      return [...state, ...action.payload.response.result];
    case types.ALL_TICKET_GROUPS.FAILURE:
      return [];
    case types.CREATE.SUCCESS:
    case types.TICKET_GROUP.SUCCESS:
      return [...state, action.payload.response.result];
    default:
      return state;
  }
};

const ticketGroups = combineReducers({
  byId,
  creating,
  newTicketGroup,
  loading,
  ticketsByTicketGroup
});

export default ticketGroups;
