import React, { Component } from 'react';
import { connect } from 'react-redux';
import Pusher from 'pusher-js';
import { normalize } from 'normalizr';
import { camelizeKeys } from 'humps';
import Table, {
  TableCell,
  TableBody,
  TableHeader,
} from '../../../../components/Table/Table';
import TicketReceiptRowItem from '../../../../components/TicketReceipt/TicketReceiptRowItem';
import Pagination from '../../../../components/Pagination/Pagination';
import { SubTitle } from '../../../../components/MainContent/MainContent';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import CheckinProgressBar from '../../../../components/TicketReceipt/CheckinProgressBar/CheckinProgressBar';
import ErrorMessage from '../../../../components/ErrorMessage/ErrorMessage';
import {
  ticketReceiptActions,
  ticketReceiptSelectors,
} from '../../../../modules/ticketReceipts/ticketReceipts';
import { ticketReceiptSchema } from '../../../../services/api';
import { showModal } from 'modules/modal/modal';
// Grommet
import { Box, ResponsiveContext } from 'grommet';

class TicketReceiptList extends Component {
  componentDidMount() {
    this.props.loadTicketReceiptsPage(
      this.props.performanceId,
      this.props.currentPage
    );
    const pusher = new Pusher('3857d249fac749e5ad07', {
      cluster: 'eu',
      encrypted: true,
    });
    const channel = pusher.subscribe(`checkin-${this.props.performanceId}`);
    channel.bind('checkedIn', this.updateReceipt);
  }

  componentDidUpdate(prevProps) {
    if (this.props.performanceId !== prevProps.performanceId) {
      this.props.loadTicketReceiptsPage(
        this.props.performanceId,
        this.props.currentPage
      );
    }
  }

  updateReceipt = data => {
    const camelizedJson = camelizeKeys(data);
    const response = {
      ...normalize(camelizedJson, ticketReceiptSchema),
    };
    this.props.updateReceipt(response);
  };

  handleRedeemClick = receipt => {
    this.props.checkinUser(receipt);
  };

  handleSearchTextChange = e => {
    const { history, performanceId, eventId, currentPage } = this.props;
    const searchQuery = e.target.value;
    if (currentPage !== 1) {
      history.push(`/event/${eventId}/performance/${performanceId}/receipts`);
    }
    this.props.searchReceipts(searchQuery);
  };

  handleSelectPage = page => {
    const { history, performanceId, eventId } = this.props;
    history.push(
      `/event/${eventId}/performance/${performanceId}/receipts/${page}`
    );
  };
  handleReceiptClick = orderId => {
    this.props.showModal('ORDER_DETAIL', {
      orderId: orderId,
    });
  };

  render() {
    const {
      receipts,
      currentPage,
      totalPages,
      receiptSummary,
      loading,
      error,
    } = this.props;
    const showSeats = receipts.find(receipt => receipt.seat) ? true : false;
    if (loading) {
      return null;
    }
    return (
      <ResponsiveContext.Consumer>
        {size => (
          <>
            {size !== 'small' ? (
              <Box margin={{ vertical: 'medium' }}>
                <ErrorMessage error={error} />
                <SubTitle title={'Ticket Receipts'} />
                <Box margin={{ vertical: 'medium' }}>
                  <SearchBar onTextChange={this.handleSearchTextChange} />
                </Box>
                <CheckinProgressBar items={receiptSummary} />
                <Box margin={{ vertical: 'medium' }}>
                  <Table>
                    <TableHeader>
                      <TableCell width={20}>Name</TableCell>
                      <TableCell width={20}>Email</TableCell>
                      <TableCell width={20}>Ticket Reference</TableCell>
                      {showSeats && <TableCell width={10}>Seat</TableCell>}
                      <TableCell width={showSeats ? 15 : 25}>
                        Ticket Type
                      </TableCell>
                      <TableCell width={15}>Status</TableCell>
                    </TableHeader>
                    <TableBody
                      renderItem={receipt => (
                        <TicketReceiptRowItem
                          receipt={receipt}
                          key={receipt.id}
                          onRedeemClick={this.handleRedeemClick}
                          showSeats={showSeats}
                          onReceiptClick={this.handleReceiptClick}
                        />
                      )}
                      items={receipts}
                    />
                  </Table>
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onSelectPage={this.handleSelectPage}
                  />
                </Box>
              </Box>
            ) : (
              <Box margin={{ vertical: 'medium' }}>
                <ErrorMessage error={error} />
                <SubTitle title={'Ticket Receipts'} />
                <Box margin={{ vertical: 'medium' }}>
                  <SearchBar onTextChange={this.handleSearchTextChange} />
                </Box>
                <CheckinProgressBar items={receiptSummary} />
                <Box margin={{ vertical: 'medium' }}>
                  <Table>
                    <TableHeader>
                      <TableCell width={100}>Details</TableCell>
                    </TableHeader>
                    <TableBody
                      renderItem={receipt => (
                        <TicketReceiptRowItem
                          receipt={receipt}
                          key={receipt.id}
                          onRedeemClick={this.handleRedeemClick}
                          showSeats={showSeats}
                          onReceiptClick={this.handleReceiptClick}
                        />
                      )}
                      items={receipts}
                    />
                  </Table>
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onSelectPage={this.handleSelectPage}
                  />
                </Box>
              </Box>
            )}
          </>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const page = parseInt(match.params.page, 10) || 1;
  const performanceId = parseInt(match.params.performanceId, 10);
  const eventId = parseInt(match.params.eventId, 10);
  return {
    receipts: ticketReceiptSelectors.getTicketReceiptsForPage(state, page),
    currentPage: page,
    totalPages: ticketReceiptSelectors.getTicketReceiptsTotalPages(state),
    receiptSummary: ticketReceiptSelectors.getTicketReceiptsSummary(state),
    loading: ticketReceiptSelectors.getTicketReceiptsLoadingStatus(state),
    error: ticketReceiptSelectors.getTicketReceiptsError(state),
    performanceId,
    eventId,
  };
};

export default connect(
  mapStateToProps,
  {
    ...ticketReceiptActions,
    showModal,
  }
)(TicketReceiptList);
