import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import TicketPricingSummary from '../TicketPricingSummary/TicketPricingSummary';
import Form, {
  FormField,
  FormFieldSwitch,
  FormSectionHeader,
  FormFieldReactSelect,
} from '../../Form/Form';
import './TicketForm.css';
import withEmptyState from '../../withEmptyState';
import { required, positiveInt, float } from '../../../utils/validators';
import { ticketOptions } from '../../../utils/types';
import Can, { checkRole } from '../../Can';
const validate = values => {
  const errors = {};
  if (values.minPerOrder !== null && values.minPerOrder === 0) {
    errors.minPerOrder = 'Please enter a value greater than zero';
  }
  if (values.maxPerOrder !== null && values.maxPerOrder === 0) {
    errors.maxPerOrder = 'Please enter a value greater than zero';
  }
  if (
    values.minPerOrder &&
    values.maxPerOrder &&
    values.maxPerOrder < values.minPerOrder
  ) {
    errors.maxPerOrder = 'Please enter a value greater than min value';
  }
  return errors;
};

let TicketForm = props => {
  const {
    handleSubmit,
    onCancel,
    submitting,
    title,
    faceValueTotal,
    outsideFeeTotal,
    userSettings,
    role,
    ticket,
    inventoryLeft,
    ...otherProps
  } = props;
  const canEdit = checkRole(role, 'event:edit');

  return (
    <Form
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      submitting={submitting}
      formTitle={title}
      {...otherProps}
    >
      <Field
        label='Ticket Name'
        name='description'
        type='text'
        component={FormField}
        validate={[required]}
        readOnly={!canEdit}
      />
      <Field
        label='Ticket Detail'
        name='detail'
        type='text'
        component={FormField}
        readOnly={!canEdit}
      />
      <Field
        label='Ticket Status'
        name='status'
        component={FormFieldReactSelect}
        options={ticketOptions}
        validate={[required]}
      />
      <FormSectionHeader title='Inventory Settings' />
      <div className='ticket-form-inventory'>
        <div className='ticket-form-inventory-section'>
          <Field
            label={
              ticket && ticket.description
                ? 'Number of Tickets Remaining'
                : 'Number of Tickets'
            }
            hintBefore={
              <a
                href='https://support.lineupnow.com/hc/en-us/articles/360020921074'
                target='_blank'
                rel='noopener noreferrer'
              >
                Unsure about capacity vs inventory?
              </a>
            }
            name='inventoryLeft'
            type='number'
            step='any'
            validate={[required]}
            normalize={positiveInt}
            component={FormField}
            hintAfter={
              ticket &&
              ticket.description &&
              `Total inventory for this ticket: ${ticket.quantityAllocated +
                ticket.quantityComped +
                ticket.quantityReserved +
                ticket.quantitySold +
                inventoryLeft}`
            }
          />
          <Field
            label='Capacity Lock This Ticket?'
            name='capacityLocked'
            component={FormFieldSwitch}
            switchfield
          />
        </div>
        <div className='ticket-form-inventory-section' />
      </div>
      <FormSectionHeader title='Pricing' />
      <div className='ticket-form-pricing'>
        <div className='ticket-form-pricing-section'>
          <Field
            label='Face Value (£)'
            width={100}
            name='faceValueTotal'
            type='number'
            component={FormField}
            placeholder='e.g. 30.00'
            normalize={float}
            validate={[required]}
            readOnly={!canEdit}
            step='any'
          />
          <Field
            label='Buyer Booking Fee (£)'
            hintBefore='Outside fee, added to the face vaue at point of purchase'
            width={100}
            name='outsideFeeTotal'
            type='number'
            component={FormField}
            placeholder='e.g. 1.50'
            normalize={float}
            validate={[required]}
            readOnly={!canEdit}
            step='any'
          />
        </div>
        <Can
          role={role}
          perform='event:edit'
          yes={() => (
            <div className='ticket-form-pricing-section ticket-form-pricing-section--calculator'>
              <div className='form__row'>
                <div className='form-column'>
                  <TicketPricingSummary
                    faceValue={faceValueTotal}
                    outsideFee={outsideFeeTotal}
                    userSettings={userSettings}
                  />
                </div>
              </div>
            </div>
          )}
        />
      </div>
      <FormSectionHeader title='Min/Max Rules (Optional):' />
      <Field
        name='minPerOrder'
        component={FormField}
        normalize={positiveInt}
        label='Minimum tickets per order'
        type='number'
        placeholder='E.g. 1'
        inline
      />
      <Field
        name='maxPerOrder'
        component={FormField}
        normalize={positiveInt}
        label='Maximum Tickets per order'
        placeholder='E.g. 3'
        type='number'
        inline
      />
    </Form>
  );
};

TicketForm = reduxForm({
  form: 'ticket',
  validate,
})(TicketForm);
TicketForm = withEmptyState(TicketForm);

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector('ticket');
  const faceValueTotal = selector(state, 'faceValueTotal');
  const outsideFeeTotal = selector(state, 'outsideFeeTotal');
  const inventoryLeft = selector(state, 'inventoryLeft');
  const ticket = ownProps.ticket;
  let initialValues;
  if (ticket && ticket.description) {
    if (ticket.description) {
      initialValues = {
        ...ticket,
        status: ticketOptions.find(p => p.value === ticket.status),
      };
    }
  }

  return {
    faceValueTotal,
    outsideFeeTotal,
    initialValues,
    ticket,
    inventoryLeft,
  };
};

export default connect(mapStateToProps)(TicketForm);
