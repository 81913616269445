import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import classNames from 'classnames';
import './FormDateField.css';
import { formatDate, parseDate } from 'react-day-picker/moment';

const FormDateField = ({
  input,
  hideLabel,
  label,
  placeholder,
  inline,
  className,
  from,
  to,
  meta: { touched, error, warning }
}) => {
  const rowClass = classNames(className, 'form__row', {
    'form__row--inline': inline
  });
  const labelClass = classNames('form__label', {
    'form__label--inline': inline
  });
  return (
    <div className={rowClass}>
      <label className={labelClass} hidden={hideLabel}>
        {label}
      </label>
      <DayPickerInput
        inputProps={{
          ...input,
          autoComplete: 'off',
          onBlur: () => null
        }}
        value={input.value}
        formatDate={formatDate}
        parseDate={parseDate}
        format="DD-MM-YYYY"
        placeholder="DD-MM-YYYY"
        onDayChange={input.onChange}
        dayPickerProps={{
          selectedDays: input.value,
          disabledDays: { before: from, after: to }
        }}
      />
      {touched &&
        ((error && <div className="form__error">{error}</div>) ||
          (warning && <div className="form__error">{warning}</div>))}
    </div>
  );
};
export default FormDateField;
