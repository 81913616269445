import React from 'react';
import Form, { FormField, FormFieldReactSelect } from '../Form/Form';
import { Field, reduxForm } from 'redux-form';
import { float, integer, required } from '../../utils/validators';
import { FormattedNumber } from 'react-intl';
/* eslint-disable react/style-prop-object */
let IssueTicketForm = props => {
  const {
    handleSubmit,
    onCancel,
    submitting,
    ticket,
    agents,
    ...otherProps
  } = props;
  return (
    <Form
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      submitting={submitting}
      formTitle='Issue a Ticket'
      guidanceNotes='Use the form below to issue a ticket from this allocation'
      {...otherProps}
    >
      <Field
        name='firstName'
        label='Name'
        component={FormField}
        type='text'
        placeholder='First Name'
        validate={[required]}
      />
      <Field
        name='lastName'
        label=''
        component={FormField}
        type='text'
        placeholder='Last Name'
        validate={[required]}
      />
      <Field
        name='quantity'
        component={FormField}
        label='Number of Tickets'
        type='number'
        normalize={integer}
        step='1'
        placeholder='E.g. 1'
        fieldShort={true}
      />
      <Field
        name='price'
        component={FormField}
        label='Ticket Price'
        type='number'
        step='any'
        normalize={float}
        placeholder='E.g. 10'
        fieldShort={true}
      />
      {ticket.feeTotal > 0 && (
        <Field
          name='includeFee'
          labelAfter={
            <div>
              Include{' '}
              <FormattedNumber
                value={ticket.feeTotal}
                style='currency'
                currency={ticket.currency}
              />{' '}
              Booking Fee?
            </div>
          }
          id='includeFee'
          component={FormField}
          type='checkbox'
        />
      )}
      <Field
        label='Agent'
        name='agent'
        validate={[required]}
        component={FormFieldReactSelect}
        type='input'
        options={agents}
      />
    </Form>
  );
};

export default (IssueTicketForm = reduxForm({
  form: 'issueTicket',
})(IssueTicketForm));
