import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import MainContent, { Title } from 'components/MainContent/MainContent';
import AccountNav from 'components/Account/AccountNav/AccountNav';
import PlanOption from 'components/Subscription/PlanOption';
import PlanOptionsContainer from 'components/Subscription/PlanOptionsContainer';
import ChangePlanSummary from 'components/Subscription/ChangePlanSummary';

import { accountSelectors } from 'modules/account/account';
import getPlanFromSubscriptionOption from './PlanOptionsData';

const AccountChangePlan = ({ subscription, payment, dispatch }) => {
  const [selectedPackageOptions, setSelectedPackageOptions] = React.useState(
    subscription.packageOptionCodes
  );
  const [currentPlan, setCurrentPlan] = React.useState(null);

  const handleSelectPlan = plan => {
    setSelectedPackageOptions([plan.code]);
  };

  const handleConfirmChangePlan = plan => {
    if (!payment.hasPaymentMethod) {
      dispatch(
        push(`/account/create-payment-method?packageOption=${plan.code}`)
      );
    } else {
      dispatch(
        push(`/account/confirm-subscription?packageOption=${plan.code}`)
      );
    }
  };

  const isPlanSelected = code => {
    return selectedPackageOptions.find(
      packageOptionCode => packageOptionCode === code
    );
  };

  const getCurrentlySelectedPlan = () => {
    const planCodes = ['standard', 'pro'];
    for (let code of planCodes) {
      if (isPlanSelected(code)) {
        return getPlanFromSubscriptionOption(code);
      }
    }
    return null;
  };
  React.useEffect(() => {
    setCurrentPlan(getCurrentlySelectedPlan());
  }, [getCurrentlySelectedPlan, selectedPackageOptions]);
  if (!subscription) {
    return null;
  }
  return (
    <MainContent>
      <Title>Your Account Plan</Title>
      <AccountNav />
      <h1>Upgrade to a paid monthly plan</h1>
      <p>Select the package you wish to upgrade to.</p>

      <PlanOptionsContainer>
        <PlanOption
          plan={getPlanFromSubscriptionOption('standard')}
          onClick={handleSelectPlan}
          selected={isPlanSelected('standard')}
        />
        <PlanOption
          plan={getPlanFromSubscriptionOption('pro')}
          onClick={handleSelectPlan}
          selected={isPlanSelected('pro')}
        />
      </PlanOptionsContainer>

      {currentPlan !== null && (
        <ChangePlanSummary
          plan={currentPlan}
          onConfirm={handleConfirmChangePlan}
        />
      )}
    </MainContent>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    subscription: accountSelectors.getSubscription(state),
    payment: accountSelectors.getPaymentDetails(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountChangePlan);
