import { createRoutine } from 'redux-saga-routines';
import { takeLatest } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { fetchEntity } from './operations';
import paginate from './paginate';
import * as fromPaginator from './paginate';
import * as fromEntities from './entities';

export const pagedList = (entityName, apiMethod) => {
  const pluralEntity = `${entityName}s`;
  const type = `line-up/${pluralEntity}/FETCH_${pluralEntity.toUpperCase()}`;
  const fetchData = createRoutine(type);
  const actions = {
    fetchData
  };

  //   Operations
  const fetchPage = fetchEntity.bind(null, fetchData, apiMethod);
  const watchFetch = function* watchFetch() {
    yield takeLatest(fetchData.TRIGGER, fetchPage);
  };
  const operations = {
    fetchPage,
    watchFetch
  };

  //   Reducers
  const byPage = paginate({
    mapActionToKey: action => action.page,
    types: [
      fetchData.REQUEST,
      fetchData.SUCCESS,
      fetchData.FAILURE,
      fetchData.FULFILL
    ]
  });
  const reducer = combineReducers({
    byPage
  });

  //   Selectors
  const getEntity = (state, entityId) =>
    fromEntities.getEntity(state.entities[entityName], entityId);
  const getEntitiesForPage = (state, page) => {
    const ids = fromPaginator.getIdsForPage(state[pluralEntity].byPage, page);
    return ids.map(id => getEntity(state, id));
  };
  const getCurrentPage = state =>
    fromPaginator.getCurrentPage(state[pluralEntity].byPage);
  const getTotalPages = state =>
    fromPaginator.getTotalPages(state[pluralEntity].byPage);
  const getIsLoadingForPage = (state, page) =>
    fromPaginator.getIsLoading(state[pluralEntity].byPage, page);
  const getIsFulfilledForPage = (state, page) =>
    fromPaginator.getIsFulfilled(state[pluralEntity].byPage, page);
  const selectors = {
    getEntity,
    getEntitiesForPage,
    getCurrentPage,
    getTotalPages,
    getIsLoadingForPage,
    getIsFulfilledForPage
  };

  return {
    reducer,
    operations,
    selectors,
    actions
  };
};
