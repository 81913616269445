import { flow, camelCase, upperFirst } from 'lodash';

export const titleCase = str =>
  str
    .toLowerCase()
    .replace(/-/g, ' ')
    .split(' ')
    .map(word => word.replace(word[0], word[0].toUpperCase()))
    .join(' ');

export const pascalCase = flow([camelCase, upperFirst]);
