import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedNumber } from 'react-intl';
/* eslint-disable react/style-prop-object */
import MainContent, {
  Title,
  HeaderActions,
  PageTop,
  LineUpHelmet,
} from '../../components/MainContent/MainContent';
import EmptyState from '../../components/EmptyState/EmptyState';

import TableView from '../table/TableView';
import {
  donationActions,
  donationSelectors,
} from '../../modules/donations/donations';
import { showModal } from '../../modules/modal/modal';

// Grommet
import { Box, Button } from 'grommet';
import { Download } from 'grommet-icons';

// Assets
import emptyPerformances from './empty_donations.png';

const columns = [
  {
    title: 'First Name',
    dataIndex: 'user.firstName',
    width: '25',
  },
  {
    title: 'Last Name',
    dataIndex: 'user.lastName',
    width: '25',
  },
  {
    title: 'Net',
    dataIndex: 'price',
    width: '10',
    render: (price) => (
      <FormattedNumber value={price} currency='GBP' style='currency' />
    ),
  },
  {
    title: 'Tax',
    dataIndex: 'tax',
    width: '10',
    render: (tax) => (
      <FormattedNumber value={tax} currency='GBP' style='currency' />
    ),
  },
  {
    title: 'Total',
    dataIndex: 'total',
    width: '10',
    render: (total) => (
      <FormattedNumber value={total} currency='GBP' style='currency' />
    ),
  },
  {
    title: 'Gift Aid',
    dataIndex: 'giftAid',
    width: '10',
    render: (giftAid) => (
      <FormattedNumber value={giftAid} currency='GBP' style='currency' />
    ),
  },
];

class DonationList extends Component {
  handleDonationClick = (donation) => {
    window.location = `/order/${donation.order.id}`;
  };
  handleSelectPage = (page) => {
    this.props.history.push(`/donations/${page}`);
  };

  handleExportClick = () => {
    this.props.showModal('EXPORT', {
      onDateRangeSelected: this.handleExportRangeSelected,
      title: 'Export Donations',
    });
  };
  handleExportRangeSelected = ({ from, to }) => {
    this.props.exportDonations({ from, to });
  };

  render() {
    const { donations, currentPage, totalPages, isLoading } = this.props;
    return (
      <>
        <LineUpHelmet
          title='Donations'
          description='Your donations in Line-Up'
        />
        <MainContent>
          <PageTop>
            <Title>Donations</Title>
            <HeaderActions>
              {!isLoading && donations.length !== 0 && (
                <Button
                  primary
                  onClick={this.handleExportClick}
                  label='Export'
                  size='small'
                  icon={<Download size='small' />}
                />
              )}
            </HeaderActions>
          </PageTop>
          <Box margin={{ vertical: 'medium' }}>
            <TableView
              columns={columns}
              data={donations}
              isLoading={isLoading}
              emptyStateComponent={
                <EmptyState
                  background
                  showButton={false}
                  emptyStateHeadline="You've had no donations yet"
                  emptyStateDescription='Use the event embed settings to turn on options for your customers to make donations at checkout'
                  emptyStateImageSrc={emptyPerformances}
                  emptyStateImageAlt='No donations yet'
                  emptyStateImageTitle='No donations yet'
                />
              }
              fetchData={this.props.loadDonationsPage}
              onRowClick={this.handleDonationClick}
              totalPages={totalPages}
              currentPage={currentPage}
              onPageClick={this.handleSelectPage}
            />
          </Box>
        </MainContent>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const page = parseInt(match.params.page, 10) || 1;
  return {
    donations: donationSelectors.getDonationsForPage(state, page),
    currentPage: page,
    totalPages: donationSelectors.getDonationsTotalPages(state),
    isLoading: donationSelectors.getDonationsLoadingStatus(state),
  };
};

export default connect(mapStateToProps, {
  ...donationActions,
  showModal,
})(DonationList);
