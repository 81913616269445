import React from 'react';
import { FormattedNumber } from 'react-intl';
import Button from 'components/Button/Button';
import CardDetail from 'components/Billing/CardDetail';
import styles from './ConfirmSubscriptionSection.module.css';
/* eslint-disable react/style-prop-object */
const ConfirmSubscription = ({ plan, payment, onSubmit }) => {
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = () => {
    setLoading(true);
    onSubmit(plan);
  };

  return (
    <div className={styles.root}>
      <p>
        You are changing your subscription to the {plan.name} plan. Billed at{' '}
        <FormattedNumber
          value={plan.amount}
          style='currency'
          currency={plan.currency}
        />{' '}
        per month.
      </p>
      <CardDetail card={payment.sources[0]} />
      <Button
        loading={loading}
        disabled={loading}
        primary
        type='button'
        onClick={handleSubmit}
      >
        Change my subscription
      </Button>
    </div>
  );
};

export default ConfirmSubscription;
