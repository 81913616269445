import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Form, { FormField } from '../Form/Form';
import { required } from '../../utils/validators';

let NoteForm = props => {
  const { handleSubmit, onCancel, submitting, edit } = props;
  return (
    <Form
      handleSubmit={handleSubmit}
      submitting={submitting}
      onCancel={onCancel}
      submitText={edit ? 'Edit Note' : 'Add Note'}
      formTitle={edit ? 'Edit Note' : 'Add Note'}
      guidanceNotes={
        edit
          ? 'Use the form below to edit the note: **bold**  _italic_'
          : 'Use the form below to add a the note: **bold**  _italic_'
      }
    >
      <Field
        name="text"
        component={FormField}
        type="textarea"
        rows={5}
        placeholder={'Add a note'}
        validators={[required]}
      />
    </Form>
  );
};

NoteForm = reduxForm({
  form: 'note'
})(NoteForm);

export default NoteForm;
