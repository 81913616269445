const MERCHANDISE = "product";
const VOUCHER = "voucher";
const MEMBERSHIP = "membership";
export const productTypes = {
  merchandise: MERCHANDISE,
  voucher: VOUCHER,
  membership: MEMBERSHIP,
};
const productTypeMerchandise = {
  value: productTypes.merchandise,
  label: "Merchandise - a physical product",
};
const productTypeVoucher = {
  value: productTypes.voucher,
  label: "Voucher - a discount voucher that can be used to purchase tickets",
};
const productTypeMembership = {
  value: productTypes.membership,
  label: "Membership - add discounts to customer's account",
};
export const productOptions = [
  productTypeMerchandise,
  productTypeVoucher,
  productTypeMembership,
];

const ON_SALE = "ON_SALE";
const HIDDEN = "HIDDEN";
const CANCELLED = "CANCELLED";
const PRIVATE = "PRIVATE";

export const performanceStatus = {
  ON_SALE: "On Sale",
  HIDDEN: "Hidden",
  CANCELLED: "Cancelled",
  PRIVATE: "Private",
};

const performanceStatusOnSale = {
  value: ON_SALE,
  label: "On Sale - Performance is on sale and available for purchase",
};
const performanceStatusHidden = {
  value: HIDDEN,
  label: "Hidden - Performance is off sale and hidden",
};
const performanceStatusCancelled = {
  value: CANCELLED,
  label: "Cancelled - Performance is cancelled",
};
const performanceStatusPrivate = {
  value: PRIVATE,
  label: "Private - Performance is private and hidden",
};
export const performanceStatusOptions = [
  performanceStatusOnSale,
  performanceStatusHidden,
  performanceStatusCancelled,
  performanceStatusPrivate,
];

const PUBLIC = "active";
const DISABLED = "disabled";
const TICKET_HIDDEN = "hidden";

export const ticketStatus = {
  active: "On Sale",
  hidden: "Hidden",
  disabled: "Inactive",
};
const ticketStatusPublic = {
  value: PUBLIC,
  label: "Public - a ticket that is visible everywhere",
};

const ticketStatusHidden = {
  value: TICKET_HIDDEN,
  label: "Hidden - a ticket that is only visible via issue tickets",
};

const ticketStatusDisabled = {
  value: DISABLED,
  label: "Inactive - a ticket that cannot be booked",
};

export const ticketOptions = [
  ticketStatusPublic,
  ticketStatusHidden,
  ticketStatusDisabled,
];
