import React from 'react';
import moment from 'moment';
import Form, { FormDateField } from '../../Form/Form';
import { Field, reduxForm } from 'redux-form';
import { required } from '../../../utils/validators';
import './InvoiceForm.css';
const validate = values => {
  const errors = {};
  if (values.toPeriod && values.fromPeriod) {
    if (moment(values.toPeriod).isBefore(moment(values.fromPeriod))) {
      errors.toPeriod = 'Period end date must be after period start date';
    }
  }
  return errors;
};

let InvoiceForm = props => {
  const { handleSubmit, onCancel, submitting } = props;
  return (
    <Form
      {...props}
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      submitting={submitting}
      className={'invoice-form'}
      formTitle="Create Invoice"
    >
      <Field
        name="fromPeriod"
        label="Start of Period"
        component={FormDateField}
        validate={[required]}
      />
      <Field
        name="toPeriod"
        label="End of Period"
        component={FormDateField}
        validate={[required]}
      />
      <Field
        name="dateDue"
        label="Payment Due Date"
        component={FormDateField}
        validate={[required]}
      />
    </Form>
  );
};

export default (InvoiceForm = reduxForm({
  form: 'invoice',
  validate
})(InvoiceForm));
