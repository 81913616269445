import React from 'react';

const planOptions = [
  {
    name: 'Standard',
    code: 'standard',
    amount: 19,
    currency: 'GBP',
    description: (
      <ul>
        <li>3% transaction fee per ticket</li>
        <li>Private events</li>
        <li>Analytics integration</li>
      </ul>
    )
  },
  {
    name: 'Pro',
    code: 'pro',
    amount: 49,
    currency: 'GBP',
    description: (
      <ul>
        <li>2% transaction fee</li>
        <li>All the standard features</li>
        <li>Customisable booking fee</li>
      </ul>
    )
  }
];

const getPlanOption = code => planOptions.find(option => option.code === code);

export default getPlanOption;
