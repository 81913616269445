import * as fromEntities from '../shared/entities';
import { getChildIdsForParent } from '../shared/relationshipById';
import { getPerformanceStartDate } from '../../utils/dates';

export const getOrder = (state, id) => {
  const order = fromEntities.getEntity(state.entities.order, id);
  return order
    ? {
        ...order,
        refunds: order.refunds
          ? order.refunds.map((refundId) =>
              fromEntities.getEntity(state.entities.refund, refundId)
            )
          : [],
      }
    : {};
};

export const getTotalFaceValue = (state, orderId) => {
  const { ticketPurchases, productPurchases } = getOrder(state, orderId);
  const totalTicketPurchases = ticketPurchases
    ? ticketPurchases.reduce(
        (total, tp) =>
          total + tp.receipts.reduce((total, r) => total + r.faceValueTotal, 0),
        0
      )
    : 0;
  const totalProductPurchases = productPurchases
    ? productPurchases.reduce(
        (total, tp) =>
          total + tp.receipts.reduce((total, r) => total + r.faceValueTotal, 0),
        0
      )
    : 0;
  return totalTicketPurchases + totalProductPurchases;
};

export const getTotalOutsideFee = (state, orderId) => {
  const { ticketPurchases, productPurchases } = getOrder(state, orderId) || {};
  const totalTicketPurchases = ticketPurchases
    ? ticketPurchases.reduce(
        (total, tp) =>
          total +
          tp.receipts.reduce((total, r) => total + r.outsideFeeTotal, 0),
        0
      )
    : 0;
  const totalProductPurchases = productPurchases
    ? productPurchases.reduce(
        (total, tp) =>
          total +
          tp.receipts.reduce((total, r) => total + r.outsideFeeTotal, 0),
        0
      )
    : 0;
  return totalTicketPurchases + totalProductPurchases;
};
export const getOrderHasOutsideFee = (state, orderId) =>
  !!getTotalOutsideFee(state, orderId) > 0;

const getNoteIdsForOrder = (state, orderId) =>
  getChildIdsForParent(state.orders.notesByOrderId, orderId) || [];
export const getNotesForOrder = (state, orderId) =>
  getNoteIdsForOrder(state, orderId).map((id) =>
    fromEntities.getEntity(state.entities.note, id)
  );

const getTicketLineItems = (purchases) =>
  purchases.map((pp) => ({
    title: pp.event.title,
    description: pp.ticket.description,
    price: pp.price,
    tax: pp.tax,
    total: pp.total,
    quantity: pp.quantity,
    currency: pp.ticket.currency,
    date: getPerformanceStartDate(pp.performance),
    purchaseId: pp.id,
    eventId: pp.event.id,
    performanceId: pp.performance.id,
    receipts: [...pp.receipts],
    seats: pp.receipts.filter((receipt) => receipt.seat).map((receipt) => receipt.seat),
  }));
const getProductLineItems = (purchases) =>
  purchases.map((pp) => ({
    title: pp.product.title,
    description: pp.product.description,
    price: pp.price,
    tax: pp.tax,
    total: pp.total,
    quantity: pp.quantity,
    currency: pp.product.currency,
    receipts: [...pp.receipts],
  }));

const getDonationLineItems = (purchases) =>
  purchases.map((pp) => ({
    ...pp,
    title: 'Donation',
    description: 'Donation',
    quantity: 1,
  }));

export const getLineItemsForOrder = (state, orderId) => {
  const order = getOrder(state, orderId);
  const { ticketPurchases, productPurchases, donation } = order || {};
  return {
    ...(ticketPurchases &&
      ticketPurchases.length > 0 && {
        tickets: getTicketLineItems(ticketPurchases),
      }),
    ...(productPurchases &&
      productPurchases.length > 0 && {
        products: getProductLineItems(productPurchases),
      }),
    ...(donation && {
      donations: getDonationLineItems([donation]),
    }),
  };
};

export const getSelectedReceipts = (state) => state.orders.selectedItems;
export const getSelectedReceiptsCount = (state) => {
  const selectedItems = getSelectedReceipts(state);
  return Object.keys(selectedItems).reduce(
    (total, key) => total + selectedItems[key].length,
    0
  );
};

export const getRefundError = (state) => state.orders.refundError;
export const getFormResponses = (state) => state.orders.formResponses.data;
export const getOrderLoadingStatus = state => state.orders.loading;
