import React from 'react';
import FormFieldSelect from '../../Form/FormFieldSelect/FormFieldSelect';
import { Field } from 'redux-form';
import Button from '../../Button/Button';

const MailchimpListSelect = ({ lists }) => {
  let options = lists.map(list => ({
    value: list.id,
    description: list.name
  }));
  const placeholder = {
    value: 'NOT_SELECTED',
    description: 'Please select one of your Mailchimp Audiences (Lists)'
  };
  options = [placeholder, ...options];
  return (
    <>
      <Field
        label="Mailchimp Audience (List) Name"
        name="mainListId"
        component={FormFieldSelect}
        options={options}
        type="select"
      />
      <Button
        target="_blank"
        href="https://admin.mailchimp.com/lists/new-list/"
        mailchimp
      >
        Create A New Audience (List) on Mailchimp
      </Button>
    </>
  );
};

export default MailchimpListSelect;
