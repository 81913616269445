import React from 'react';
import Pagination from '../../components/Pagination/Pagination';
import { connectPagination } from 'react-instantsearch-dom';
import './AISearch.css';

export const AIPagination = connectPagination(
  ({ refine, nbPages, currentRefinement }) => (
    <Pagination
      totalPages={nbPages}
      currentPage={currentRefinement}
      onSelectPage={refine}
    />
  )
);
