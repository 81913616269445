import React from 'react';
import { CONTENT_TYPE_HTML, CONTENT_TYPE_TEXT } from './constants';
import styles from './PreviewPane.module.css';

const IFrameContainer = ({ content, ...props }) => (
  <iframe srcDoc={content} {...props} title="Preview" />
);

const PreviewPane = ({ contentType, value }) => {
  let emptyContent = 'No content yet';
  let content = value ? value : emptyContent;
  let container = null;

  if (contentType === CONTENT_TYPE_HTML) {
    container = <IFrameContainer className={styles.iframe} content={content} />;
  }

  if (contentType === CONTENT_TYPE_TEXT) {
    content = `<pre>${content}</pre>`;
    container = <IFrameContainer className={styles.iframe} content={content} />;
  }

  return (
    <div className={styles.root}>
      <div className="form__row">
        <label className="form__label">Preview</label>
        {container}
      </div>
    </div>
  );
};

export default PreviewPane;
