import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

/* eslint-disable react/style-prop-object */
import MainContent, {
  Title,
  HeaderActions,
  PageTop,
  LineUpHelmet,
} from '../../components/MainContent/MainContent';
import EmptyState from '../../components/EmptyState/EmptyState';
import SearchBar from '../../components/SearchBar/SearchBar';
import {
  discountActions,
  discountSelectors,
} from '../../modules/discounts/discounts';
import TableView from '../table/TableView';

// Utils
import _ from 'lodash';

// Grommet
import { Box, Button } from 'grommet';
import { Add } from 'grommet-icons';

// Assets
import emptyDiscounts from './empty_discounts.png';

const columns = [
  {
    title: 'Name',
    dataIndex: 'code',
    width: '40',
  },
  {
    title: 'Uses Remaining',
    dataIndex: 'usesLeft',
    width: '30',
    render: (usesLeft, discount) => {
      return discount.unlimited ? 'Unlimited' : `${discount.usesLeft}`;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '10',
  },
];

const SEARCH_DEBOUNCE = 500;

class DiscountList extends Component {
  handleDiscountClick = (discount) => {
    this.props.history.push(`/discount/${discount.id}`);
  };
  handleSelectPage = (page) => {
    this.props.history.push(`/discounts/${page}`);
  };

  searchQuery = _.debounce((query) => {
    if (query.length === 0 || query.length > 2) {
      if (this.props.currentPage !== 1) {
        this.props.history.push(`/discounts/1`);
      }
      this.props.searchDiscounts({ page: 1, query: query });
    }
  }, SEARCH_DEBOUNCE);

  handleSearchTextChange = (e) => {
    const query = e.target.value;
    this.searchQuery(query);
  };

  render() {
    const { discounts, currentPage, totalPages, isLoading } = this.props;
    return (
      <>
        <LineUpHelmet
          title='Discounts'
          description='Manage your Line-Up discounts'
        />
        <MainContent>
          <PageTop>
            <Title>Discount Codes</Title>
            <HeaderActions>
              <Link to='/discount/create'>
                <Button
                  primary
                  label='New Discount Code'
                  size='small'
                  icon={<Add size='small' />}
                  fill={true}
                />
              </Link>
            </HeaderActions>
          </PageTop>
          <Box margin={{ vertical: 'medium' }}>
            <SearchBar onTextChange={this.handleSearchTextChange} />
          </Box>
          <TableView
            columns={columns}
            data={discounts}
            isLoading={isLoading}
            emptyStateComponent={
              <EmptyState
                background
                buttonText='Create New Discount Code'
                buttonLink='/discount/create'
                showButton={true}
                emptyStateHeadline='You have no Discounts set up yet'
                emptyStateDescription='Create Discount Codes to enable ticket buyers to get a discount at checkout. Once Discounts are created, you can manage them here.'
                emptyStateImageSrc={emptyDiscounts}
                emptyStateImageAlt='No discounts yet'
                emptyStateImageTitle='No discounts yet'
              />
            }
            fetchData={this.props.searchDiscounts}
            onRowClick={this.handleDiscountClick}
            totalPages={totalPages}
            currentPage={currentPage}
            onPageClick={this.handleSelectPage}
          />
        </MainContent>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const page = parseInt(match.params.page, 10) || 1;
  return {
    discounts: discountSelectors.getDiscountsForPage(state, page),
    currentPage: page,
    totalPages: discountSelectors.getDiscountsTotalPages(state),
    isLoading: discountSelectors.getDiscountsLoadingStatus(state),
  };
};

export default connect(mapStateToProps, {
  ...discountActions,
})(DiscountList);
