import React from 'react';
import { FormattedDate } from 'react-intl';
/* eslint-disable react/style-prop-object */
import './InvoiceHeaderItem.css';

const InvoiceHeaderItem = ({ title, value, date = false }) => {
  return (
    <span className='invoice-header-item'>
      <span className='invoice-header-item__title'>{title}</span>
      <span className='invoice-header-item__value'>
        {date ? (
          <FormattedDate
            value={value}
            year='numeric'
            day='numeric'
            month='short'
            weekday='short'
          />
        ) : (
          value
        )}
      </span>
    </span>
  );
};

export default InvoiceHeaderItem;
