import React from 'react';
import './FormField.css';
import classNames from 'classnames';

const FormField = ({
  input,
  label,
  type,
  placeholder,
  className,
  id,
  inline,
  switchfield,
  labelAfter,
  width,
  rows,
  step,
  hintBefore,
  hintAfter,
  readOnly = false,
  meta: { touched, error, warning },
  setFormikInputValue, // use this to set the value of this field, useful for custom components
  errorWithoutTouch, // set this to true if the validation error for this field doesn't depend on the field being touched
  setFormikInputError,
  ...otherProps
}) => {
  const style = () => {
    if (width) {
      return {
        width: `${width}%`
      };
    }
    return {};
  };

  const rowClass = classNames(className, 'form__row', {
    'form__row--check': !inline && (type === 'checkbox' || type === 'radio'),
    'form__row--check-inline':
      inline && (type === 'checkbox' || type === 'radio'),
    'form__row--inline': inline && (type !== 'checkbox' || type !== 'radio')
  });
  const inputClass = classNames({
    'form__field-input': type !== 'checkbox' && type !== 'radio',
    'form__field-input--check':
      !inline && (type === 'checkbox' || type === 'radio'),
    'form__field-input--check-inline':
      inline && (type === 'checkbox' || type === 'radio'),
    'form__field-input--inline':
      inline && type !== 'checkbox' && type !== 'radio',
    'form__field-input--error': (touched && error) || (touched && warning)
  });
  const labelClass = classNames('form__label', {
    'form__label--inline': inline && type !== 'checkbox' && type !== 'radio',
    'form__label--check-inline':
      (inline && type === 'checkbox') || type === 'radio',
    'form__label--switch': switchfield
  });

  const Element = type === 'textarea' ? 'textarea' : 'input';

  return (
    <div className={rowClass}>
      {label && (
        <label className={labelClass} htmlFor={id}>
          {label}
        </label>
      )}
      {hintBefore && (
        <div className="form__hint form__hint--before">{hintBefore}</div>
      )}
      <Element
        {...input}
        placeholder={placeholder}
        id={id}
        type={type}
        className={inputClass}
        rows={rows}
        step={step}
        style={style()}
        readOnly={readOnly}
        {...otherProps}
      />
      {labelAfter && (
        <label className={labelClass} htmlFor={id}>
          {labelAfter}
        </label>
      )}
      {(touched || errorWithoutTouch) &&
        ((error && <div className="form__error">{error}</div>) ||
          (warning && <div className="form__error">{warning}</div>))}
      {hintAfter && (
        <div className="form__hint form__hint--after">{hintAfter}</div>
      )}
    </div>
  );
};

export default FormField;
