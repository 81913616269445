import { combineReducers } from 'redux';
import * as actions from './actions';
import * as types from './types';
//- Reducers

const byId = (state = [], action) => {
  switch (action.type) {
    case actions.fetchAllEvents.TRIGGER:
      return [];
    case actions.fetchEvents.SUCCESS:
      return [...state, ...action.payload.response.result];
    case actions.fetchAllEvents.FAILURE:
      return [];
    default:
      return state;
  }
};

const searchTerm = (state = null, action) => {
  switch (action.type) {
    case types.SEARCH_EVENTS:
      return action.payload.searchTerm;
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case actions.fetchAllEvents.TRIGGER:
      return true;
    case actions.fetchAllEvents.SUCCESS:
    case actions.fetchAllEvents.FAILURE:
      return false;
    default:
      return state;
  }
};

const invitations = (state = [], action) => {
  switch (action.type) {
    case actions.getEventInvitations.SUCCESS:
      return [...action.payload.response];
    case actions.createEventInvitation.SUCCESS:
      state.push(action.payload.response);
      return state;
    case actions.deleteEventInvitation.SUCCESS:
      return state.filter(
        (item, index) => item.id !== action.payload.response.id
      );
    default:
      return state;
  }
};

const permissions = (state = [], action) => {
  switch (action.type) {
    case actions.getEventPermissions.SUCCESS:
      return [...action.payload.response];
    case actions.deleteEventPermission.SUCCESS:
      return state.filter(
        (item, index) => item.userId !== action.payload.response.userId
      );
    default:
      return state;
  }
};

const createBulkUploadLoading = (state = {"status": null}, action) => {
  switch (action.type) {
    case actions.createBulkUpload.TRIGGER:
      return {"status": true};
    case actions.createBulkUpload.SUCCESS:
      return {"status": false};
    case actions.createBulkUpload.FAILURE:
      return {"status": false,"errors":action.payload.error.errors};
    default:
      return state;
  }
};

const bulkUploadFileData = (state = null, action) => {
  switch (action.type) {
    case types.SET_BULK_UPLOAD_FILE_DATA:
      state = action.payload;
      return state;
    default:
      return state;
  }
};

const publicEvent = (state = null, action) => {
  switch (action.type) {
    case actions.fetchEventBySlug.SUCCESS:
      return action.payload.response;
    case actions.fetchEventBySlug.FAILURE:
      return action.payload.error;
    default:
      return state;
  }
};

const publicEventLoading = (state = null, action) => {
  switch (action.type) {
    case actions.fetchEventBySlug.TRIGGER:
      return true;
    case actions.fetchEventBySlug.SUCCESS:
    case actions.fetchEventBySlug.FAILURE:
      return false;
    default:
      return state;
  }
};

const events = combineReducers({
  byId,
  searchTerm,
  loading,
  invitations,
  permissions,
  createBulkUploadLoading,
  bulkUploadFileData,
  publicEvent,
  publicEventLoading,
});

export default events;
