import React from 'react';
import { connect } from 'react-redux';
import { SubTitle } from '../../../components/MainContent/MainContent';
import TicketWidgetAdmin from '../../../components/TicketWidgetAdmin/TicketWidgetAdmin';

// Grommet
import { Box } from 'grommet';

const customerIndex = process.env.REACT_APP_ALGOLIA_CUSTOMERS_INDEX;

class Issue extends React.Component {
  componentDidMount() {}

  render() {
    const { eventId } = this.props;
    return (
      <>
        <SubTitle title='Sell' />
        <Box margin={{ vertical: 'medium' }}>
          <div>
            <TicketWidgetAdmin
              eventId={eventId}
              apiKey={window.p_lukey}
              secretApiKey={window.lukey}
              searchKey={window.users_api_key}
              searchIndex={customerIndex}
            />
          </div>
        </Box>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const eventId = parseInt(ownProps.match.params.eventId, 10);
  return {
    eventId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Issue);
