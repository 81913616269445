export const isAuthenticated = state =>
  state.account.user.data && state.account.user.data.id !== undefined;

export const getUserToken = state => state.account.user.data.userToken;
export const triedRecoveringAuth = state =>
  state.account.user.triedRecoveringAuth;
export const getSubscription = state => state.account.subscription.data;
export const hasSubscription = state =>
  state.account.subscription.data !== null;
export const getPaymentDetails = state => state.account.payment.data;
export const getStripeSettings = state => state.account.stripeSettings.data;
export const getBusinessSettings = state => state.account.businessSettings.data;
export const isBusinessSettingsLoaded = state =>
  state.account.businessSettingsLoaded;
export const getCurrencies = state => state.account.currencies.data;
export const isStripeSettingsLoaded = state =>
  state.account.stripeSettingsLoaded;
export const isCurrenciesLoaded = state => state.account.currenciesLoaded;
