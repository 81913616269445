import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import TicketCreate from './TicketCreate';
import TicketDetail from './TicketDetail';
import TicketAllocation from '../ticketAllocation/TicketAllocation';
import ReviewTicketIssue from '../ticketAllocation/ReviewTicketIssue';

class Ticket extends Component {
  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route exact path={`${match.path}`} component={TicketDetail} />
        <Route exact path={`${match.path}/edit`} component={TicketCreate} />
        <Route
          exact
          path={`${match.path}/ticket-allocation/:allocationId(\\d+)`}
          component={TicketAllocation}
        />
        <Route
          exact
          path={`${match.path}/ticket-allocation/:allocationId(\\d+)/review`}
          component={ReviewTicketIssue}
        />
      </Switch>
    );
  }
}

export default Ticket;
