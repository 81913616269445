import { combineReducers } from 'redux';
import * as types from './types';
import paginate from '../shared/paginate';
import * as actions from './actions';
//- Reducers

const newDiscount = (state = null, action) => {
  switch (action.type) {
    case types.CREATE.REQUEST:
    case types.CREATE.FAILURE:
      return null;
    case types.CREATE.SUCCESS:
      return action.payload.response.result;
    default:
      return state;
  }
};

const byPage = paginate({
  mapActionToKey: action => action.page,
  types: [
    actions.searchDiscounts.REQUEST,
    actions.searchDiscounts.SUCCESS,
    actions.searchDiscounts.FAILURE,
  ],
});

const loading = (state = false, action) => {
  switch (action.type) {
    case actions.searchDiscounts.REQUEST:
      return true;
    case actions.searchDiscounts.SUCCESS:
      return false;
    case actions.searchDiscounts.FAILURE:
      return false;
    default:
      return state;
  }
};

const discounts = combineReducers({
  loading,
  newDiscount,
  byPage,
});

export default discounts;
