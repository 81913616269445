import React from 'react';
import { Field, reduxForm } from 'redux-form';

import Form, { FormField } from 'components/Form/Form';

const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'A name is required for this lane';
  }
  if (!values.priority) {
    errors.priority = 'Priority is required for this lane';
  }

  return errors;
};

// needs: event, lanes, order, order notes, total tickets in order
let LaneForm = ({ onCancel, handleSubmit }) => {
  return (
    <Form
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      formTitle="Lane"
      guidanceNotes="Use the form below to update information related to this lane"
    >
      <Field label="Name" type="text" name="name" component={FormField} />

      <Field
        label="Capacity"
        type="text"
        name="capacity"
        component={FormField}
      />

      <Field
        label="Priority"
        type="text"
        name="priority"
        component={FormField}
      />
    </Form>
  );
};

LaneForm = reduxForm({
  form: 'laneForm',
  validate
})(LaneForm);

export default LaneForm;
