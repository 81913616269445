import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { SubTitle, PageTop } from '../../../components/MainContent/MainContent';
import EmptyState from '../../../components/EmptyState/EmptyState';

import {
  eventEmailScheduleActions,
  eventEmailScheduleSelectors,
} from '../../../modules/eventEmailSchedules/eventEmailSchedules';
import TableView from '../../table/TableView';

// Grommet
import { Box, Button, Text } from 'grommet';
import { Add } from 'grommet-icons';

// Assets
import emptyEmails from './empty_emails.png';

const DeleteButton = styled(Button)`
  background-color: #ff2e4a;
  &:hover {
    background-color: #d41c34;
  }
`;

// stored in the backend as a delta in minutes, so we parse that
// out into something readable
const ScheduleReadableDeltaValue = ({ delta, displayUnit }) => {
  let mins = Math.abs(delta); // delta can be -ve or +ve
  let convertedDelta = null;
  let suffix = null;
  switch (displayUnit) {
    case 'days':
      convertedDelta = mins / 1440;
      suffix = convertedDelta > 1 ? 'days' : 'day';
      break;

    case 'hours':
      convertedDelta = mins / 60;
      suffix = delta > 1 ? 'hours' : 'hour';
      break;
    default:
      convertedDelta = mins;
      suffix = delta > 1 ? 'minutes' : 'minute';
  }

  return (
    <div>
      <span>{convertedDelta > 0 ? `${convertedDelta} ${suffix}` : null}</span>
    </div>
  );
};

const columns = onDelete => [
  {
    title: 'Email Template',
    render: (text, record) => <>{record.email.subject}</>,
  },
  {
    title: 'Time',
    render: (text, record) => (
      <ScheduleReadableDeltaValue
        delta={record.startDeltaMinutes}
        displayUnit={record.displayUnit}
      />
    ),
  },
  {
    title: 'Before or after',
    render: (text, record) => (
      <span>
        {record.startDeltaMinutes < 0 ? 'Before' : 'After'} performance start
        time
      </span>
    ),
  },
  {
    title: '',
    render: (text, record) => (
      <Box direction='row' gap='small' justify='end'>
        <Button
          secondary
          label='Edit'
          size='small'
          href={`/event/${record.eventId}/email-schedule/${record.id}`}
        />
        <DeleteButton
          onClick={() => onDelete(record)}
          label='Delete'
          primary
          size='small'
        />
      </Box>
    ),
  },
];

// TODO: put this into the components/ folder
export const EventEmailSchedulesList = ({ schedules, onDelete, eventId }) => {
  // we're fetching all event email schedules, so pass in these placeholder
  // values to TableView
  const currentPage = 1;
  const totalPages = 1;
  const fetchData = () => {};
  const handleSelectPage = () => {};

  if (!schedules) {
    return null;
  }

  return (
    <TableView
      columns={columns(onDelete)}
      data={schedules}
      totalPages={totalPages}
      currentPage={currentPage}
      fetchData={fetchData}
      onPageClick={handleSelectPage}
      emptyStateComponent={
        <EmptyState
          background
          buttonText='Create New Email Schedule'
          buttonLink={`/event/${eventId}/email-schedule/create`}
          showButton={true}
          emptyStateHeadline='You have no Emails yet'
          emptyStateDescription='You can schedule an email to be sent to attendees at a set time, pre or post show using one of your pre-defined email templates'
          emptyStateImageSrc={emptyEmails}
          emptyStateImageAlt='No email yet'
          emptyStateImageTitle='No emails yet'
        />
      }
    />
  );
};

const EventEmailSchedule = ({
  eventId,
  fetchEventEmailSchedules,
  deleteEventEmailSchedule,
  schedules,
}) => {
  React.useEffect(() => {
    fetchEventEmailSchedules({ eventId });
  }, [fetchEventEmailSchedules, eventId]);

  const handleDelete = eventEmailSchedule => {
    deleteEventEmailSchedule({ id: eventEmailSchedule.id });
  };

  return (
    <>
      <PageTop>
        <SubTitle title='Pre/Post Show Emails'>
          <Button
            href={`/event/${eventId}/email-schedule/create`}
            secondary={true}
            size='small'
            icon={<Add size='small' />}
            label='New Email Schedule'
          />
        </SubTitle>
      </PageTop>
      <Box margin={{ vertical: 'medium' }}>
        <Text size='small'>
          Schedule an email to be sent to attendees at a set time, pre or post
          show.
        </Text>
      </Box>
      <Box margin={{ bottom: 'medium' }}>
        <EventEmailSchedulesList
          onDelete={handleDelete}
          schedules={schedules}
          eventId={eventId}
        />
      </Box>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const eventId = parseInt(ownProps.match.params.eventId, 10);

  return {
    eventId,
    schedules: eventEmailScheduleSelectors.getEventEmailSchedules(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        ...eventEmailScheduleActions,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventEmailSchedule);
