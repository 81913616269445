import React from 'react';
import { FormattedDate } from 'react-intl';
import { Field, reduxForm } from 'redux-form';

import { SubTitle } from 'components/MainContent/MainContent';
import Form, { FormFieldReactSelect } from 'components/Form/Form';
import TimeLine from 'components/TimeLine/TimeLine';
import InfoBlock, {
  InfoBlockList,
  InfoBlockListItem
} from 'components/InfoBlock/InfoBlock';
// import Button from 'components/Button/Button';
import FormFieldColourSelect from 'components/Form/FormFieldColourSelect/FormFieldColourSelect';

import { getPerformanceStartDate } from 'utils/dates';

// Grommet
import { Box, Button } from 'grommet';
import { Add, Share, Note } from 'grommet-icons';

const getLaneOptions = (lanes, disabledLanes) => {
  return lanes.map(lane => ({
    value: lane.id,
    label: lane.name,
    isDisabled: disabledLanes.find(l => l.id === lane.id) ? true : false
  }));
};

const validate = values => {
  const errors = {};
  if (!values.lane) {
    errors.lane = 'Please select a lane';
  }
  return errors;
};

// needs: event, lanes, order, order notes, total tickets in order
let BookingForm = ({
  onCancel,
  handleSubmit,
  event,
  placement,
  lanes,
  order,
  notes,
  formResponses,
  onEditNote,
  onCreateNote,
  disabledLanes,
  canUpdateLane,
  submitting
}) => {
  const startDate = getPerformanceStartDate(placement.eventPerformance);
  const timeline = [];
  let customerName = '';

  for (let note of notes) {
    timeline.push({
      text: note.text,
      date: note.createdAt,
      user: note.createdBy.displayname,
      icon: <Note size='small' />,
      onEdit: onEditNote,
      id: note.id
    });
  }

  if (placement.ticketReceipt.firstName && placement.ticketReceipt.lastName) {
    customerName = `${placement.ticketReceipt.firstName} ${placement.ticketReceipt.lastName}`;
  } else {
    customerName = placement.orderSummary.customer.displayname;
  }

  return (
    <Form
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      formTitle='Booking Information'
      submitting={submitting}
    >
      <Box direction='row' justify='between' align='center'>
        <SubTitle title='Details' />
        <Box justify='end' width='small'>
          <Button
            secondary
            size='small'
            href={`/order/${order.id}`}
            target='_blank'
            label='Manage Order'
            icon={<Share size='small' />}
          />
        </Box>
      </Box>

      <InfoBlock isInverted={true}>
        <InfoBlockList>
          <InfoBlockListItem attribute='Customer' value={customerName} />
          <InfoBlockListItem
            attribute='Name'
            value={placement.ticketReceipt.reference}
          />
          <InfoBlockListItem
            attribute='Date'
            value={
              <FormattedDate
                value={startDate}
                weekday='short'
                year='numeric'
                
                day='numeric'
                month='short'
                hour='numeric'
                minute='numeric'
              />
            }
          />
          <InfoBlockListItem
            attribute='Order reference'
            value={order.reference}
          />
          <InfoBlockListItem
            attribute='Ticket reference'
            value={placement.ticketReceipt.reference}
          />
        </InfoBlockList>
      </InfoBlock>

      <Box direction='row' justify='between' align='center'>
        <SubTitle title='Notes' />
        <Box justify='end' width='small'>
          <Button
            secondary
            size='small'
            onClick={onCreateNote}
            label='New Note'
            icon={<Add size='small' />}
          />
        </Box>
      </Box>
      <TimeLine isInverted items={timeline} />

      {formResponses && formResponses.length > 0 ? (
        <>
          <SubTitle title={formResponses[0].name} />
          <InfoBlock>
            <InfoBlockList>
              {formResponses[0].items.map(item => (
                <InfoBlockListItem
                  attribute={item.schema.label}
                  value={item.value}
                />
              ))}
            </InfoBlockList>
          </InfoBlock>
        </>
      ) : null}

      <Box
        direction='row'
        justify='between'
        align='center'
        gap='medium'
        margin={{ top: 'medium' }}
      >
        <Field
          name='lane'
          label='Lane'
          isClearable={false}
          component={FormFieldReactSelect}
          options={getLaneOptions(lanes, disabledLanes)}
          isDisabled={!canUpdateLane}
        />

        <Field
          name='colour'
          label='Colour'
          isClearable={false}
          component={FormFieldColourSelect}
        />
      </Box>
    </Form>
  );
};

BookingForm = reduxForm({
  form: 'plannerBookingForm',
  validate
})(BookingForm);

export default BookingForm;
