import React from 'react';
import Table, {
  TableHeader,
  TableRow,
  TableCell
} from '../../../components/Table/Table';

const PerformanceSummaryTable = ({
  capacity,
  sold,
  comps,
  allocated,
  reserved,
  remaining
}) => (
  <Table borders tableMargin="0 0 1rem 0" containerBorder mobileWide>
    <TableHeader borders>
      <TableCell borders width={15} center>
        Capacity
      </TableCell>
      <TableCell borders width={15} center>
        Tickets Sold
      </TableCell>
      <TableCell borders width={15} center>
        Comps
      </TableCell>
      <TableCell borders width={15} center>
        Allocations Held
      </TableCell>
      <TableCell borders width={15} center>
        Reserved
      </TableCell>
      <TableCell borders width={15} center>
        Capacity Remaining
      </TableCell>
    </TableHeader>
    <TableRow>
      <TableCell borders center>
        {capacity}
      </TableCell>
      <TableCell borders center>
        {sold}
      </TableCell>
      <TableCell borders center>
        {comps}
      </TableCell>
      <TableCell borders center>
        {allocated}
      </TableCell>
      <TableCell borders center>
        {reserved}
      </TableCell>
      <TableCell borders center>
        {remaining}
      </TableCell>
    </TableRow>
  </Table>
);

export default PerformanceSummaryTable;
