import React from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';
import Form, { FormField } from 'components/Form/Form';
import Button from '../Button/Button';
import { FormRow } from '../Form/Form';
import * as validators from 'utils/validators';

const validate = values => {
  let errors = {};

  if (!values.eventPlannerLanes || values.eventPlannerLanes.length === 0) {
    errors.eventPlannerLanes = [{ name: 'There must be at least one lane' }];
  } else {
    const laneErrors = [];
    values.eventPlannerLanes.forEach((lane, laneIndex) => {
      const laneError = {};
      if (!lane.name) {
        laneError.name = 'The lane must have a name';
      }
      if (
        !lane.capacity ||
        !validators.integer(lane.capacity) ||
        !validators.positiveInt(lane.capacity)
      ) {
        laneError.capacity = 'Must be set as a positive number';
      }
      if (
        !lane.priority ||
        !validators.integer(lane.priority) ||
        !validators.positiveInt(lane.priority)
      ) {
        laneError.priority = 'Must be set as a positive number';
      }
      laneErrors[laneIndex] = laneError;
    });
    errors.eventPlannerLanes = laneErrors;
  }

  return errors;
};

const LaneFieldArray = ({ fields, meta: { error: submitFailed } }) => {
  return (
    <div>
      {fields.map((member, index) => {
        return (
          <FormRow key={index}>
            <Field
              type='text'
              component={FormField}
              name={`${member}.name`}
              label='Lane name'
            />
            <Field
              type='text'
              component={FormField}
              name={`${member}.capacity`}
              label='Lane capacity per timeslot'
              fieldShort={true}
            />
            <Field
              type='text'
              component={FormField}
              name={`${member}.priority`}
              label='Priority'
              fieldShort={true}
            />
            <div className='form__row'>
              <label className='form__label'>&nbsp;</label>
              <div>
                <Button
                  danger
                  type='button'
                  onClick={() => fields.remove(index)}
                >
                  Remove lane
                </Button>
              </div>
            </div>
          </FormRow>
        );
      })}
      <FormRow>
        <div className='form__row'>
          <Button secondary type='button' onClick={() => fields.push({})}>
            Add Lane
          </Button>
        </div>
      </FormRow>
    </div>
  );
};

const PlannerForm = ({
  handleSubmit,
  submitting,
  formTitle,
  guidanceNotes,
  onCancel,
  onDelete,
  initialValues
}) => {
  return (
    <Form
      submitting={submitting}
      handleSubmit={handleSubmit}
      formTitle={formTitle ? formTitle : 'New planner'}
      guidanceNotes={
        guidanceNotes
          ? guidanceNotes
          : 'Use the form below to create a new event planner'
      }
      onCancel={onCancel}
    >
      <FieldArray name='eventPlannerLanes' component={LaneFieldArray} />

      {initialValues && initialValues.id && (
        <p>
          <Button danger onClick={onDelete} type='button' nomargin>
            Delete planner
          </Button>
        </p>
      )}
    </Form>
  );
};

const formConfig = {
  form: 'PlannerForm',
  validate
};

export default reduxForm(formConfig)(PlannerForm);
