export const FETCH_MAILCHIMP_INTEGRATION =
  'line-up/mailchimp/FETCH_MAILCHIMP_INTEGRATION';
export const COMPLETE_MAILCHIMP_CONNECT =
  'line-up/mailchimp/COMPLETE_MAILCHIMP_CONNECT';
export const FETCH_MAILCHIMP_LISTS = 'line-up/mailchimp/FETCH_MAILCHIMP_LISTS';
export const UPDATE_MAILCHIMP_INTEGRATION =
  'line-up/mailchimp/UPDATE_MAILCHIMP_INTEGRATION';
export const SYNC_EVENT_ATTENDEES_TO_MAILCHIMP =
  'line-up/mailchimp/SYNC_EVENT_ATTENDEES_TO_MAILCHIMP';
export const DISCONNECT_MAILCHIMP_INTEGRATION =
  'line-up/mailchimp/DISCONNECT_MAILCHIMP_INTEGRATION';
