import React from "react";
import { FormattedDate } from "react-intl";
/* eslint-disable react/style-prop-object */
import Table, {
  TableCell,
  TableBody,
  TableHeader,
  TableRow,
} from "../../Table/Table";
import styled from "styled-components";

// Grommet
import { Button } from "grommet";

const DeleteButton = styled(Button)`
  background-color: #ff2e4a;
  &:hover {
    background-color: #d41c34;
  }
`;

const InvoiceRowItem = ({ invoice, onBtnClick, onDeleteClick, onRowClick }) => {
  const handleClick = (evt) => {
    evt.preventDefault();
    if (onBtnClick) {
      onBtnClick(invoice.id, invoice.reference);
    }
  };
  const handleDeleteClick = (evt) => {
    evt.preventDefault();
    if (onDeleteClick) {
      if (window.confirm("Are you sure you wish to delete this item?")) {
        onDeleteClick(invoice.id);
      }
    }
  };
  const handleOnRowClick = (evt) => {
    evt.preventDefault();
    onRowClick && onRowClick(invoice.id);
  };
  return (
    <TableRow striped={true} onClick={handleOnRowClick}>
      <TableCell>{invoice.reference}</TableCell>
      <TableCell>
        {invoice.dateIssued && (
          <FormattedDate
            value={invoice.dateIssued}
            year="numeric"
            day="numeric"
            month="short"
            weekday="short"
          />
        )}
      </TableCell>
      <TableCell>
        {invoice.dateDue && (
          <FormattedDate
            value={invoice.dateDue}
            year="numeric"
            day="numeric"
            month="short"
            weekday="short"
          />
        )}
      </TableCell>
      <TableCell>
        {invoice.fromPeriod && (
          <FormattedDate
            value={invoice.fromPeriod}
            year="numeric"
            day="numeric"
            month="short"
            weekday="short"
          />
        )}
      </TableCell>
      <TableCell>
        {invoice.toPeriod && (
          <FormattedDate
            value={invoice.toPeriod}
            year="numeric"
            day="numeric"
            month="short"
            weekday="short"
          />
        )}
      </TableCell>
      <TableCell>
        <Button secondary onClick={handleClick} size="small" label="Download" />
      </TableCell>
      <TableCell>
        {onDeleteClick && (
          <DeleteButton
            primary
            onClick={handleDeleteClick}
            size="small"
            label="Delete"
          />
        )}
      </TableCell>
    </TableRow>
  );
};

const InvoiceTable = ({ invoices, onBtnClick, onDeleteClick, onRowClick }) => {
  return (
    <Table mobileWide>
      <TableHeader>
        <TableCell width={30}>Invoice Reference</TableCell>
        <TableCell width={15}>Date Created</TableCell>
        <TableCell width={15}>Date Due</TableCell>
        <TableCell width={15}>Period Start</TableCell>
        <TableCell width={15}>Period End</TableCell>
        <TableCell width={5} />
        <TableCell width={5} />
      </TableHeader>
      <TableBody
        renderItem={(item) => (
          <InvoiceRowItem
            invoice={item}
            key={item.id}
            onBtnClick={onBtnClick}
            onDeleteClick={onDeleteClick}
            onRowClick={onRowClick}
          />
        )}
        items={invoices}
      />
    </Table>
  );
};

export default InvoiceTable;
