import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { SubTitle } from 'components/MainContent/MainContent';
import { PlannerProvider } from './Provider';
import PlannerWithContext from './PlannerWithContext';

import { showModal, hideModal } from 'modules/modal/modal';
import { eventActions, eventSelectors } from 'modules/events/events';

import { Box } from 'grommet';

const Planner = ({ eventId, event, fetchEvent, showModal, hideModal }) => {
  React.useEffect(() => {
    fetchEvent({ eventId: eventId });
  }, [fetchEvent, eventId]);

  if (event === null) {
    return;
  }

  return (
    <div>
      <SubTitle title='Planner' />
      <Box margin={{ bottom: 'large' }}>
        <PlannerProvider event={event}>
          <PlannerWithContext showModal={showModal} hideModal={hideModal} />
        </PlannerProvider>
      </Box>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const eventId = parseInt(match.params.eventId, 10);
  const event = eventSelectors.getEvent(state, eventId);
  return {
    eventId,
    event,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        ...eventActions,
        showModal,
        hideModal,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Planner);
