import React from 'react';
import Html from 'slate-html-serializer';
import { Editor, getEventTransfer } from 'slate-react';
import classNames from 'classnames';
import { debounce } from 'lodash';
import Toolbar from './Toolbar/Toolbar';
import { renderNode, renderMark } from './renderers';
import rules from './serialize';
import onKeyDown from './keys';
import './TextEditor.css';

const DEFAULT_NODE = 'paragraph';

const wrapLink = (editor, href) => {
  editor.wrapInline({
    type: 'link',
    data: { href }
  });

  editor.moveToEnd();
};

const unwrapLink = editor => {
  editor.unwrapInline('link');
};

const html = new Html({ rules });

class TextEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: html.deserialize(this.props.initialValue)
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.pristine &&
      this.props.initialValue !== prevProps.initialValue
    ) {
      this.setState({ value: html.deserialize(this.props.initialValue) });
    }
  }
  handleChange = change => {
    if (!this.state.value.document.equals(change.value.document)) {
      this.handleDocumentChange(change);
    }
    this.setState({ value: change.value });
  };

  handleDocumentChange = debounce(change => {
    const { onChange } = this.props;
    onChange(html.serialize(change.value));
  }, 150);

  selectionHasMark = type =>
    this.state.value.activeMarks.some(mark => mark.type === type);
  selectionHasBlock = type => {
    let isActive = this.state.value.blocks.some(node => node.type === type);
    if (['numbered-list', 'bulleted-list'].includes(type)) {
      const {
        value: { document, blocks }
      } = this.state;

      if (blocks.size > 0) {
        const parent = document.getParent(blocks.first().key);
        isActive =
          this.state.value.blocks.some(node => node.type === 'list-item') &&
          parent &&
          parent.type === type;
      }
    }
    return isActive;
  };
  selectionHasLink = () => {
    return this.state.value.inlines.some(inline => inline.type === 'link');
  };
  handleMarkClick = (event, type) => {
    event.preventDefault();
    this.editor.toggleMark(type);
    this.editor.focus();
  };

  handleBlockClick = (event, type) => {
    event.preventDefault();
    this.editor.focus();
    const { editor } = this;
    const { value } = editor;
    const { document } = value;

    // Handle everything but list buttons.
    if (type !== 'bulleted-list' && type !== 'numbered-list') {
      const isActive = this.selectionHasBlock(type);
      const isList = this.selectionHasBlock('list-item');

      if (isList) {
        editor
          .setBlocks(isActive ? DEFAULT_NODE : type)
          .unwrapBlock('bulleted-list')
          .unwrapBlock('numbered-list');
      } else {
        editor.setBlocks(isActive ? DEFAULT_NODE : type);
      }
    } else {
      // Handle the extra wrapping required for list buttons.
      const isList = this.selectionHasBlock('list-item');
      const isType = value.blocks.some(block => {
        return !!document.getClosest(block.key, parent => parent.type === type);
      });

      if (isList && isType) {
        editor
          .setBlocks(DEFAULT_NODE)
          .unwrapBlock('bulleted-list')
          .unwrapBlock('numbered-list');
      } else if (isList) {
        editor
          .unwrapBlock(
            type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list'
          )
          .wrapBlock(type);
      } else {
        editor.setBlocks('list-item').wrapBlock(type);
      }
    }
  };
  handleLinkClick = event => {
    event.preventDefault();

    const { editor } = this;
    const { value } = editor;
    const hasLinks = this.selectionHasLink();

    if (hasLinks) {
      editor.command(unwrapLink);
    } else if (value.selection.isExpanded) {
      const href = window.prompt('Enter the URL of the link:');

      if (href == null) {
        return;
      }

      editor.command(wrapLink, href);
    } else {
      const href = window.prompt('Enter the URL of the link:');

      if (href == null) {
        return;
      }

      const text = window.prompt('Enter the text for the link:');

      if (text == null) {
        return;
      }

      editor
        .insertText(text)
        .moveFocusBackward(text.length)
        .command(wrapLink, href);
    }
  };
  handlePaste = (event, editor, next) => {
    const transfer = getEventTransfer(event);
    if (transfer.type !== 'html') return next();
    const { document } = html.deserialize(transfer.html);
    editor.insertFragment(document);
  };

  ref = editor => {
    this.editor = editor;
  };

  render() {
    const rowClass = classNames(this.props.className, 'text-editor');
    return (
      <div>
        <Toolbar
          onMarkClick={this.handleMarkClick}
          onBlockClick={this.handleBlockClick}
          selectionHasMark={this.selectionHasMark}
          selectionHasBlock={this.selectionHasBlock}
          onLinkClick={this.handleLinkClick}
          selectionHasLink={this.selectionHasLink}
        />
        <Editor
          spellCheck
          placeholder={this.props.placeholder}
          onPaste={this.handlePaste}
          ref={this.ref}
          value={this.state.value}
          onChange={this.handleChange}
          onKeyDown={onKeyDown}
          renderNode={renderNode}
          renderMark={renderMark}
          className={rowClass}
          tabIndex={0}
        />
      </div>
    );
  }
}

export default TextEditor;
