import React from 'react';
import './TableRow.css';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
const TableRow = ({
  children,
  striped,
  className,
  onClick,
  noClick,
  bgColor,
  color = 'black',
  toLink
}) => {
  const RowStyle = {
    backgroundColor: bgColor,
    color: color,
    textDecoration: 'none'
  };
  const tblClass = classNames('lutable__row', className, {
    'lutable__row--alt-color': striped,
    'lutable__row--selectable': onClick
  });

  return noClick ? (
    <div style={RowStyle} className={tblClass}>
      {children}
    </div>
  ) : onClick ? (
    <div style={RowStyle} className={tblClass} onClick={onClick}>
      {children}
    </div>
  ) : (
    <Link style={RowStyle} className={tblClass} to={toLink}>
      {children}
    </Link>
  );
};
export default TableRow;
