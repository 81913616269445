import React from 'react';
import { TableCell, TableRow } from '../../Table/Table';
import { FormattedStartDate } from '../../Date/Date';
import Imgix from 'react-imgix';
import { Link } from 'react-router-dom';

// Grommet
import { Box, Button, Text, ResponsiveContext } from 'grommet';
import { Ticket, Location, Calendar } from 'grommet-icons';

const EventRowItem = ({ event, onClick }) => {
  const handleClick = evt => {
    evt.preventDefault();
    onClick(event);
  };

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <TableRow striped={true} onClick={handleClick}>
            <TableCell width={70}>
              <Box direction='row' gap='medium' align='center'>
                {size !== 'small' ? (
                  <>
                    {event.image && (
                      <>
                        <Imgix
                          src={event.image.url}
                          width={90} // This sets what resolution the component should load from the CDN and the size of the resulting image
                          height={90}
                          imgixParams={{
                            mask: 'corners',
                            'corner-radius': '5,5,5,5'
                          }}
                          className='lutable__cell-image-image'
                        />
                        <Imgix
                          src={event.image.url}
                          width={50} // This sets what resolution the component should load from the CDN and the size of the resulting image
                          height={50}
                          imgixParams={{
                            mask: 'corners',
                            'corner-radius': '5,5,5,5'
                          }}
                          className='lutable__cell-image-image--mobile'
                        />
                      </>
                    )}
                  </>
                ) : null}
                <Box direction='column' gap='xsmall'>
                  <Text weight={900} size='medium' color='heading'>
                    {event.title}
                  </Text>
                  <Box direction='row' gap='xsmall' align='center'>
                    {size !== 'small' ? (
                      <Location size='small' color='copy-light' />
                    ) : null}
                    <Text size='small' color='copy-light'>
                      {event.venue.name}
                    </Text>
                  </Box>
                  <Box direction='row' gap='xsmall' align='center'>
                    {size !== 'small' ? (
                      <Calendar size='small' color='copy-light' />
                    ) : null}
                    <div onClick={e => e.stopPropagation()}>
                      {event.nextPerformance ? (
                        <>
                          <Text size='small' color='copy-light'>
                            Next:{' '}
                          </Text>
                          <Link
                            style={{ textDecoration: 'none' }}
                            to={`/event/${event.id}/performance/${event.nextPerformance.id}`}
                          >
                            <Text size='small' color='brand'>
                              <FormattedStartDate
                                performance={event.nextPerformance}
                              />
                            </Text>
                          </Link>
                        </>
                      ) : event.lastPerformance ? (
                        <>
                          <Text size='small' color='copy-light'>
                            Last:{' '}
                          </Text>
                          <Link
                            style={{ textDecoration: 'none' }}
                            to={`/event/${event.id}/performance/${event.lastPerformance.id}`}
                          >
                            <Text size='small' color='brand'>
                              <FormattedStartDate
                                performance={event.lastPerformance}
                              />
                            </Text>
                          </Link>
                        </>
                      ) : (
                        'No performances'
                      )}
                    </div>
                  </Box>
                </Box>
              </Box>
            </TableCell>
            <TableCell width={30} textright onClick={e => e.stopPropagation()}>
              <Box
                direction={size !== 'small' ? 'row' : 'column'}
                gap='small'
                justify='end'
              >
                <Link to={`/event/${event.id}/issue`}>
                  <Button
                    icon={<Ticket size='small' />}
                    secondary
                    label='Sell'
                    size='small'
                    fill={size !== 'small' ? false : true}
                  />
                </Link>
              </Box>
            </TableCell>
          </TableRow>
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default EventRowItem;
