import React from 'react';
import styles from './Columns.module.css';

import DateKeyColumn from './DateKeyColumn';
import LaneColumn from './LaneColumn';

import { getStartEndForRender, getDateTimeDividerBlocks } from './layoutUtils';

const Columns = ({
  lanes,
  performances,
  placements,
  onPlacementClick,
  showModal,
  hideModal,
  onCreateAllocationClick
}) => {
  const { first, hours } = getStartEndForRender(performances);
  let blocks = getDateTimeDividerBlocks(first, hours);

  return (
    <div className={styles.columnsContainer}>
      <div className={styles.columnsRoot}>
        <DateKeyColumn
          startFrom={first}
          blocks={blocks}
          performances={performances}
          placements={placements}
        />
        {lanes.map(lane => (
          <LaneColumn
            key={lane.id}
            lane={lane}
            startFrom={first}
            performances={performances}
            placements={placements}
            blocks={blocks}
            onPlacementClick={onPlacementClick}
            showModal={showModal}
            hideModal={hideModal}
            onCreateAllocationClick={onCreateAllocationClick}
          />
        ))}
      </div>
    </div>
  );
};

export default Columns;
