import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedDate } from "react-intl";
/* eslint-disable react/style-prop-object */
import {
  ContentContainer,
  SubTitle,
  PageTop,
  MultiActionContainer,
} from "../../../components/MainContent/MainContent";

import InfoBlock, {
  InfoBlockList,
  InfoBlockListItem,
} from "../../../components/InfoBlock/InfoBlock";
import { showModal } from "../../../modules/modal/modal";
import {
  getPerformanceStartDate,
  getPerformanceEndDate,
} from "../../../utils/dates";
import { performanceStatus } from "../../../utils/types";
import Can from "../../../components/Can";
import { seatingChartSelectors } from "../../../modules/seatingCharts/seatingCharts";
import {
  PerformanceSummaryTable,
  TicketList,
} from "../../../components/Event/Event";
import {
  ticketActions,
  ticketSelectors,
} from "../../../modules/tickets/tickets";
import { performanceActions } from "../../../modules/performances/performances";
import { EventAuthContext } from "../event-auth-context";

// Grommet
import { Box, Button, Text, ResponsiveContext } from "grommet";
import {
  Add,
  Edit,
  List,
  DocumentPdf,
  DocumentCsv,
  CheckboxSelected,
  Lounge,
  Trash,
} from "grommet-icons";
import TicketEmptyState from "components/Event/Ticket/TicketEmptyState";
import styled from "styled-components";

const DeleteButton = styled(Button)`
  background-color: #ff2e4a;
  &:hover {
    background-color: #d41c34;
  }
`;

class PerformanceDetail extends Component {
  componentDidMount() {
    this.props.fetchTicketsForPage({
      performanceId: this.props.performance.id,
      page: this.props.ticketsPage,
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.performanceId !== prevProps.performanceId) {
      this.props.fetchTicketsForPage({
        performanceId: this.props.performance.id,
        page: this.props.ticketsPage,
      });
    }
  }

  handleTicketClick = (ticketId) => {
    const { history, match } = this.props;
    history.push(`${match.url}/ticket/${ticketId}`);
  };

  handleNewTicketClick = () => {
    const { history, match } = this.props;
    history.push(`${match.url}/ticket/create`);
  };

  handleEditPerformanceClick = (ev) => {
    ev.preventDefault();
    this.props.showModal("PERFORMANCE_CREATE_MODAL", {
      eventId: this.props.eventId,
      performanceId: this.props.performance.id,
      seatingCharts: this.props.seatingCharts,
      role: this.context,
      capacityLeft: this.props.performance.capacityLeft,
      capacitySet: this.props.performance.capacitySet,
    });
  };

  handleManageSeatsClick = (ev) => {
    this.props.showModal("MANAGE_SEATING_CHART_MODAL", {});
  };

  handleDownloadGuestlistCSV = () => {
    this.props.downloadGuestlistCSV({ id: this.props.performance.id });
  };

  handleDownloadGuestlistPDF = () => {
    this.props.downloadGuestlistPDF({ id: this.props.performance.id });
  };

  handleDeletePerformanceClick = () => {
    this.props.showModal("ALERT_MODAL", {
      title: "Delete Performance",
      description: `Are you sure you wish to delete this performance? This action cannot be undone.`,
      onConfirmClick: this.handleConfirmPerformanceDelete,
    });
  };
  handleConfirmPerformanceDelete = () => {
    const { eventId, performance } = this.props;
    const successUrl = `/event/${eventId}/performances/`;
    this.props.deletePerformance({
      performanceId: performance.id,
      onSuccessUrl: successUrl,
    });
  };

  render() {
    const { performance, match, tickets, ticketsLoading } = this.props;
    const performanceStartDate = getPerformanceStartDate(performance);
    const performanceEndDate = getPerformanceEndDate(performance);
    const status = performanceStatus[performance.status];

    const totalSold = tickets.reduce((a, b) => a + b.quantitySold, 0);
    const totalComps = tickets.reduce((a, b) => a + b.quantityComped, 0);
    const totalRedeemed = tickets.reduce((a, b) => a + b.quantityRedeemed, 0);
    const totalAllocated = tickets.reduce((a, b) => a + b.quantityAllocated, 0);
    const totalReserved = tickets.reduce((a, b) => a + b.quantityReserved, 0);
    const totalTicketCapacityRemaining = tickets.reduce(
      (a, b) => a + b.inventoryLeft,
      0
    );
    const totalTicketCapacity =
      tickets.reduce((a, b) => a + b.inventoryLeft, 0) +
      totalSold +
      totalComps +
      totalAllocated +
      totalReserved;

    const role = this.context;
    return (
      <ResponsiveContext.Consumer>
        {(size) => (
          <ContentContainer>
            <PageTop>
              <SubTitle title={"Details"}>
                <Box direction={"row"} gap={"small"} fill={false}>
                  <Can
                    role={role}
                    perform={"event:edit:inventory"}
                    yes={() => (
                      <Button
                        secondary
                        size="small"
                        label="Edit"
                        icon={<Edit size="small" />}
                        onClick={this.handleEditPerformanceClick}
                      />
                    )}
                  />
                  <Can
                    role={role}
                    perform={"event:edit"}
                    yes={() =>
                      performance.canDelete && (
                        <DeleteButton
                          primary
                          size="small"
                          label="Delete"
                          icon={<Trash size="small" />}
                          onClick={this.handleDeletePerformanceClick}
                        />
                      )
                    }
                  />
                </Box>
              </SubTitle>
            </PageTop>

            <InfoBlock>
              <InfoBlockList>
                <InfoBlockListItem
                  attribute="Start Date"
                  value={
                    <FormattedDate
                      value={performanceStartDate}
                      weekday="short"
                      year="numeric"
                      day="numeric"
                      month="short"
                    />
                  }
                />
                <InfoBlockListItem
                  attribute="Start Time"
                  value={
                    <FormattedDate
                      value={performanceStartDate}
                      hour="numeric"
                      minute="numeric"
                    />
                  }
                />
                <InfoBlockListItem
                  attribute="End Date"
                  value={
                    <FormattedDate
                      value={performanceEndDate}
                      weekday="short"
                      year="numeric"
                      day="numeric"
                      month="short"
                    />
                  }
                />
                {performance.endTime && (
                  <InfoBlockListItem
                    attribute="End Time"
                    value={
                      <FormattedDate
                        value={performanceEndDate}
                        hour="numeric"
                        minute="numeric"
                      />
                    }
                  />
                )}
                <InfoBlockListItem attribute="Status" value={status} />
                {performance.availability !== null && (
                  <InfoBlockList>
                    <InfoBlockListItem
                      attribute="Total Capacity"
                      value={performance.capacitySet}
                    />
                    <InfoBlockListItem
                      attribute="Capacity Remaining"
                      value={
                        performance.capacityLeft > 0
                          ? performance.capacityLeft
                          : "Sold Out"
                      }
                    />
                  </InfoBlockList>
                )}
              </InfoBlockList>
            </InfoBlock>

            <SubTitle title={"Guests"}>
              <MultiActionContainer>
                <Can
                  role={role}
                  perform={"event:view:guestlist:email"}
                  yes={() => (
                    <Box
                      direction={size !== "small" ? "row" : "column"}
                      gap={size !== "small" ? "small" : "medium"}
                      fill={size !== "small" ? false : true}
                    >
                      <Link to={`${match.url}/receipts`}>
                        <Button
                          secondary
                          size="small"
                          label="Web Check-In"
                          icon={<CheckboxSelected size="small" />}
                          fill={size !== "small" ? false : true}
                        />
                      </Link>
                      <Link to={`${match.url}/waiting-list`}>
                        <Button
                          secondary
                          size="small"
                          label="Waiting List"
                          icon={<List size="small" />}
                          fill={size !== "small" ? false : true}
                        />
                      </Link>
                      <Button
                        secondary
                        size="small"
                        label="Download CSV"
                        icon={<DocumentCsv size="small" />}
                        onClick={this.handleDownloadGuestlistCSV}
                        fill={size !== "small" ? false : true}
                      />
                      <Button
                        secondary
                        size="small"
                        label="Download PDF"
                        icon={<DocumentPdf size="small" />}
                        onClick={this.handleDownloadGuestlistPDF}
                        fill={size !== "small" ? false : true}
                      />
                    </Box>
                  )}
                />
              </MultiActionContainer>
            </SubTitle>

            <InfoBlock>
              <InfoBlockList>
                <InfoBlockListItem
                  attribute="Guests"
                  value={totalSold + totalComps}
                />
                <InfoBlockListItem
                  attribute="Checked In"
                  value={totalRedeemed}
                />
              </InfoBlockList>
            </InfoBlock>
            {performance.seatingChart && (
              <>
                <SubTitle title={"Seating Chart"}>
                  <Can
                    role={role}
                    perform={"event:edit"}
                    yes={() => (
                      <Box
                        direction={size !== "small" ? "row" : "column"}
                        gap={size !== "small" ? "small" : "medium"}
                        fill={size !== "small" ? false : true}
                      >
                        <Link to={`${match.url}/seating-chart`}>
                          <Button
                            secondary
                            size="small"
                            icon={<Lounge size="small" />}
                            label="Manage Seats on Sale"
                            fill={size !== "small" ? false : true}
                          />
                        </Link>
                      </Box>
                    )}
                  />
                </SubTitle>
                <InfoBlock>
                  <InfoBlockList>
                    <InfoBlockListItem
                      attribute="Seating Chart"
                      value={performance.seatingChart.name}
                    />
                  </InfoBlockList>
                </InfoBlock>
              </>
            )}

            <SubTitle title={"Tickets"}>
              <Can
                role={role}
                perform={"event:manage-users"}
                yes={() => (
                  <Button
                    secondary
                    size="small"
                    icon={<Add size="small" />}
                    label="New Ticket"
                    href={`${match.url}/ticket/create`}
                  />
                )}
              />
            </SubTitle>
            <Box margin={{ vertical: "medium" }}>
              <Box margin={{ bottom: "medium" }}>
                <Text margin={{ bottom: "medium" }} size="small" weight={900}>
                  Performance Tickets Summary
                </Text>
                <PerformanceSummaryTable
                  capacity={
                    performance.availability !== null
                      ? performance.capacitySet
                      : totalTicketCapacity
                  }
                  remaining={
                    performance.availability !== null
                      ? performance.capacityLeft
                      : totalTicketCapacityRemaining
                  }
                  sold={totalSold}
                  comps={totalComps}
                  allocated={tickets.reduce(
                    (a, b) => a + b.quantityAllocated,
                    0
                  )}
                  reserved={tickets.reduce((a, b) => a + b.quantityReserved, 0)}
                />
              </Box>
              <Box margin={{ bottom: "medium" }}>
                <Text margin={{ bottom: "medium" }} size="small" weight={900}>
                  Performance Tickets - click ticket to edit
                </Text>
                <TicketList
                  isLoading={ticketsLoading}
                  isEmpty={tickets.length === 0}
                  tickets={tickets}
                  onClick={this.handleTicketClick}
                  emptyStateComponent={
                    <TicketEmptyState
                      onNewTicketClick={this.handleNewTicketClick}
                    />
                  }
                />
              </Box>
            </Box>
          </ContentContainer>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}
PerformanceDetail.contextType = EventAuthContext;
const mapStateToProps = (state, ownProps) => {
  const eventId = parseInt(ownProps.match.params.eventId, 10);
  const performanceId = ownProps.performance.id;
  const ticketsPage = ticketSelectors.getCurrentPageForPerformanceId(
    state,
    performanceId
  );
  return {
    ticketsPage,
    seatingCharts: seatingChartSelectors.getSeatingCharts(state),

    tickets: ticketSelectors.getForPerformanceIdAndPage(
      state,
      performanceId,
      ticketsPage
    ),
    ticketsLoading: ticketSelectors.getIsLoadingForPerformanceId(
      state,
      performanceId,
      ticketsPage
    ),
    ticketsTotalPages: ticketSelectors.getTotalPagesForPerformanceId(
      state,
      performanceId
    ),
    eventId: eventId,
  };
};

export default connect(mapStateToProps, {
  showModal,
  ...ticketActions,
  ...performanceActions,
})(PerformanceDetail);
