import React from 'react';
import Table, { TableCell, TableHeader, TableBody } from '../../../Table/Table';
import ReceiptRowItem from './ReceiptRowItem/ReceiptRowItem';

const ReceiptTable = ({
  receipts,
  selectedReceipts,
  onReceiptClick,
  lineItemType,
  onReceiptRedeemClick,
}) => {
  const includeSeat = !!receipts.find(r => r.seat);
  const allSelected =
    receipts.filter(r => !selectedReceipts.includes(r.id)).length === 0;
  const handleReceiptTableClick = e => {
    allSelected
      ? receipts.forEach(r =>
          selectedReceipts.includes(r.id) ? onReceiptClick(r.id) : null
        )
      : receipts.forEach(r =>
          selectedReceipts.includes(r.id) ? null : onReceiptClick(r.id)
        );
  };
  return (
    <Table containerBorder mobileWide>
      <TableHeader
        className='receipt__header'
        onClick={handleReceiptTableClick}
      >
        <TableCell width={3} />
        <TableCell width={23}>Reference</TableCell>
        <TableCell width={12}>Name</TableCell>
        <TableCell width={5}>{includeSeat ? 'Seat' : null}</TableCell>
        <TableCell width={8}>Face Value</TableCell>
        <TableCell width={8}>Booking Fee</TableCell>
        <TableCell width={8}>Discount</TableCell>
        <TableCell width={8}>Total</TableCell>
        <TableCell width={8}>Status</TableCell>
        <TableCell width={17} />
      </TableHeader>
      <TableBody
        renderItem={receipt => (
          <ReceiptRowItem
            includeSeat={includeSeat}
            key={receipt.id}
            receipt={receipt}
            onReceiptClick={onReceiptClick}
            isSelected={!!selectedReceipts.includes(receipt.id)}
            lineItemType={lineItemType}
            onReceiptRedeemClick={onReceiptRedeemClick}
          />
        )}
        items={receipts}
      />
    </Table>
  );
};
export default ReceiptTable;
