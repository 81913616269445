import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* eslint-disable react/style-prop-object */
import './Tag.css';
const Tag = ({ text }) =>
  text ? (
    <span className="ticket-receipt-cell__discount">
      <FontAwesomeIcon icon="tag" />
      {text}
    </span>
  ) : null;
export default Tag;
