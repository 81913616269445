import React from "react";
import styled from "styled-components";

// Grommet
import { Box, Heading, ResponsiveContext } from "grommet";

const ContentHeading = styled(Heading)`
  color: #999;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
`;

const ContentType = ({ label, icon }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <ContentHeading
            level={size !== "small" ? "2" : "5"}
            size="xsmall"
            margin={{ vertical: "none" }}
            color="copy"
          >
            <Box
              direction="row"
              align="center"
              alignContent="center"
              gap="small"
              margin={size !== "small" ? "" : { bottom: "medium" }}
            >
              {icon}
              {label}
            </Box>
          </ContentHeading>
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default ContentType;
