import rules from '../utils/rbac-rules';
const check = (rules, role, action, data) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

const Can = ({ yes = () => null, no = () => null, ...props }) =>
  check(rules, props.role, props.perform, props.data) ? yes() : no();

export const checkRole = (role, action, data) =>
  check(rules, role, action, data);
export default Can;
