import React from "react";
import moment from "moment";
import {
  aggregatorTemplates as tpl,
  numberFormat,
  derivers,
  aggregators,
} from "../../../components/PivotTable/Utilities";
import PivotTable from "../../../components/PivotTable/PivotTable";

const Inventory = ({ data, title, exportFilename }) => {
  const usFmtInt = numberFormat({ digitsAfterDecimal: 0 });
  const sortDates = (left, right) => {
    return moment
      .utc(left, "ddd, DD MMM YYYY HH:mm")
      .diff(moment.utc(right, "ddd, DD MMM YYYY HH:mm"));
  };
  return (
    <PivotTable
      title={title}
      exportFilename={exportFilename}
      data={data}
      rows={["Day", "Start Time"]}
      aggregators={{
        Status: () => tpl.listUnique(", ")(["Status"]),
        Capacity: () => tpl.sum(usFmtInt)(["Capacity"]),
        Sold: () => tpl.sum(usFmtInt)(["Sold"]),
        Comps: () => tpl.sum(usFmtInt)(["Comp"]),
        Held: () => tpl.sum(usFmtInt)(["Held"]),
        "Capacity Left": () => tpl.sum(usFmtInt)(["Capacity Left"]),
        Cancelled: () => tpl.sum(usFmtInt)(["Cancelled"]),
      }}
      derivedAttributes={{
        Time: derivers.dateFormat("Start Date Time", "%H:%M", true),
        "Event Date": derivers.dateFormat(
          "Start Date Time",
          "%w, %d %n %y %H:%M",
          true
        ),
        Day: derivers.dateFormat("Start Date", "%w, %d %n %y", true),
      }}
      rowSubTotals={true}
      rowSubTotalName={"Total For Day"}
      sorters={{
        Day: sortDates,
      }}
    />
  );
};

export default Inventory;
