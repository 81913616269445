import React, { Component } from "react";
import { Helmet } from "react-helmet";

class TicketWidgetAdmin extends Component {
  componentDidMount() {
    window.LineupTicketingAdminLoader &&
      window.LineupTicketingAdminLoader.setupWidgets();
  }

  render() {
    const LOADER_URL = process.env.REACT_APP_ADMIN_LOADER_URL;
    const {
      eventId,
      apiKey,
      secretApiKey,
      searchKey,
      searchIndex,
    } = this.props;
    return (
      <>
        <Helmet>
          <script src={LOADER_URL} />
        </Helmet>
        <div
          className="lineup-ticketing-admin"
          data-lineup-event-id={eventId}
          data-lineup-api-key={apiKey}
          data-lineup-api-secret-key={secretApiKey}
          data-lineup-search-api-key={searchKey}
          data-lineup-search-index={searchIndex}
        />
      </>
    );
  }
}

export default TicketWidgetAdmin;
