import { combineReducers } from 'redux';
import paginate from '../shared/paginate';
import * as types from './types';
//- Reducers

const byPage = paginate({
  mapActionToKey: action => action.page,
  types: [
    types.DONATIONS.REQUEST,
    types.DONATIONS.SUCCESS,
    types.DONATIONS.FAILURE,
  ],
});
const loading = (state = false, action) => {
  switch (action.type) {
    case types.DONATIONS.REQUEST:
      return true;
    case types.DONATIONS.SUCCESS:
    case types.DONATIONS.FAILURE:
      return false;
    default:
      return state;
  }
};
const donations = combineReducers({
  byPage,
  loading,
});

export default donations;
