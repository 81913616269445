import React, { Fragment } from 'react';
import moment from 'moment';
import {
  aggregatorTemplates as tpl,
  numberFormat
} from '../../../components/PivotTable/Utilities';
import PivotTable from '../../../components/PivotTable/PivotTable';
import ReportSection from '../../../components/ReportSection/ReportSection';

const WrapsReport = ({ data, title, exportFilename }) => {
  const usFmtInt = numberFormat({ digitsAfterDecimal: 0 });
  const fmt = numberFormat({ prefix: '£' });
  return (
    <Fragment>
      <ReportSection>
        <PivotTable
          title={`${title} - Summary`}
          exportFilename={`${exportFilename}-summary`}
          data={data}
          aggregators={{
            'Qty Sold': () => tpl.sum(usFmtInt)(['Sold']),
            Comps: () => tpl.sum(usFmtInt)(['Comp']),
            Gross: () => tpl.sum(fmt)(['Face Value Gross'])
          }}
          rows={['Advance']}
          derivedAttributes={{
            Channel: record =>
              record['Seller'] === 'Direct' ? 'Direct Sales' : 'Agency Sales',
            Sold: record => (record['Gross'] !== 0 ? record['Quantity'] : 0),
            Comp: record => (record['Gross'] === 0 ? record['Quantity'] : 0),
            Advance: record =>
              moment
                .utc(record['Start Date Time'])
                .isSame(moment(record['Transaction Date']), 'day')
                ? 'On The Day'
                : 'Advance'
          }}
          cols={['Channel']}
        />
      </ReportSection>
      <ReportSection>
        <PivotTable
          title={`${title} - Sales By Ticket Type / Price`}
          exportFilename={`${exportFilename}-sales-by-ticket-type-and-price`}
          data={data}
          aggregators={{
            'Qty Sold': () => tpl.sum(usFmtInt)(['Sold']),
            Comps: () => tpl.sum(usFmtInt)(['Comp']),
            Gross: () => tpl.sum(fmt)(['Face Value Gross'])
          }}
          derivedAttributes={{
            Channel: record =>
              record['Seller'] === 'Direct' ? 'Direct Sales' : 'Agency Sales',
            Price: record => fmt(Math.abs(record['Face Value Gross'])),
            Sold: record => (record['Gross'] !== 0 ? record['Quantity'] : 0),
            Comp: record => (record['Gross'] === 0 ? record['Quantity'] : 0)
          }}
          rows={['Ticket Description', 'Price', 'Discount Code']}
          cols={['Channel']}
        />
      </ReportSection>
    </Fragment>
  );
};

export default WrapsReport;
