import { ThemeType } from 'grommet';

const theme: ThemeType = {
  global: {
    breakpoints: {
      small: {
        value: 1025 // This is the media query used to determine when to move to tablet/mobile styles - the default value is 768
      }
    },
    colors: {
      brand: '#399ba2',
      'brand-hover': '#2f8d94',
      focus: '#f1f1f1',
      'light-1': '#ffffff',
      'light-2': '#f1f1f1',
      'dark-2': '#494949',
      sidebar: '#102052',
      topbar: '#e8f1fa',
      heading: '#0b1638',
      'sidebar-copy': '#c8d4f7',
      border: '#ddd',
      copy: '#333',
      'copy-light': '#808080',
      black: '#000000',
      infobox: '#f7f8fa',
      secondary: '#dfe6f5',
      'secondary-hover': '#cfd5e3',
      error: '#ff2e4a',
      'error-hover': '#eb2640',
      checkedin: '#78cf9c',
      text: {
        light: '#333'
      }
    },
    font: {
      family: 'Lato',
      size: '16px',
      height: '21px'
    },
    input: {
      weight: 400
    },
    focus: {
      outline: {
        color: '#399ba2',
        size: '0'
      }
    }
  },
  icon: {
    size: {
      small: '18px',
      medium: '24px',
      large: '48px',
      xlarge: '96px'
    },
    extend: undefined
  },
  heading: {
    weight: 900,
    level: {
      '1': {
        small: {
          size: '22px'
        }
      },
      '2': {
        small: {
          size: '20px'
        },
        xsmall: {
          size: '14px'
        }
      },
      '3': {
        xsmall: {
          size: '16px'
        }
      },
      '4': {
        small: {
          size: '16px'
        },
        xsmall: {
          maxWidth: '1000px'
        }
      }
    }
  },
  anchor: {
    textDecoration: 'none',
    fontWeight: 400,
    outline: 0,
    color: {
      dark: 'brand',
      light: 'brand'
    },
    hover: {
      textDecoration: 'none',
      fontWeight: 400
    }
  },
  button: {
    default: {},
    primary: {
      background: {
        color: 'brand'
      },
      color: 'light-1'
    },
    secondary: {
      background: {
        color: 'secondary'
      },
      color: 'heading'
    },
    hover: {
      primary: {
        background: 'brand-hover'
      },
      secondary: {
        background: 'secondary-hover',
        color: 'heading'
      }
    },
    border: {
      radius: '4px'
    },
    padding: {
      vertical: '12px',
      horizontal: '16px'
    },
    size: {
      small: {
        border: {
          radius: '4px'
        },
        pad: {
          vertical: '10px',
          horizontal: '14px'
        }
      },
      medium: {
        border: {
          radius: '4px'
        },
        pad: {
          vertical: '12px',
          horizontal: '14px'
        }
      }
    },
    extend: `{
      font-weight: 900;
    }`
  },
  paragraph: {
    medium: {
      size: '16px',
      height: '22px',
      maxWidth: '800px'
    }
  },
  text: {
    small: {
      size: '13px',
      height: '19px',
      maxWidth: '312px'
    },
    medium: {
      size: '16px',
      height: '22px',
      maxWidth: '384px'
    }
  }
};

export { theme };
