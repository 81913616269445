import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import BulkUploadForm from '../../../../components/BulkUploadForm/BulkUploadForm';
import Papa from 'papaparse';
import { useHistory } from 'react-router';
import moment from 'moment';
import { eventActions } from '../../../../modules/events/events';
import {
  SubTitle,
  PageTop,
} from '../../../../components/MainContent/MainContent';
import {
  accountActions,
  accountSelectors,
} from '../../../../modules/account/account';
import { Redirect } from 'react-router-dom';
const BulkUpload = ({
  eventId,
  setBulkUploadFileData,
  getBusinessSettings,
  businessSettings,
  isBusinessSettingsLoaded,
}) => {
  const history = useHistory();
  const [error, setError] = useState(null);
  useEffect(() => {
    if (!businessSettings) {
      getBusinessSettings();
    }
  }, [getBusinessSettings, businessSettings]);
  const validationError = results => {
    let error = null;
    for (let row of results.data) {
      if (parseInt(row.min_per_order) === 0) {
        error = 'Please enter a value greater than zero for min per order';
        break;
      }
      if (parseInt(row.max_per_order) === 0) {
        error = 'Please enter a value greater than zero for max per order';
        break;
      }
      if (
        row.min_per_order !== '' &&
        row.max_per_order !== '' &&
        parseInt(row.max_per_order) < parseInt(row.min_per_order)
      ) {
        error = 'Please enter a max value greater than min value';
        break;
      }
    }
    return error;
  };
  const isHeaderFormatCorrect = fields => {
    let headerFields = [
      'date',
      'start_time',
      'end_time',
      'capacity',
      'performance_status',
      'ticket_status',
      'ticket_name',
      'ticket_description',
      'quantity',
      'ticket_face_value',
      'booking_fee',
    ];
    return headerFields.every(i => fields.includes(i));
  };
  const formatPapaParseResults = results => {
    let formattedData = [];
    let currentPerformance = null;
    results.data.forEach(row => {
      if (row.date !== '') {
        currentPerformance && formattedData.push(currentPerformance);
        currentPerformance = {
          date: moment.utc(row.date, 'DD/MM/YYYY'),
          start_time: row.start_time,
          end_time: row.end_time === '' ? undefined : row.end_time,
          capacity: parseInt(row.capacity),
          performance_status:
            row.performance_status.toLowerCase() === 'hidden'
              ? 'Hidden'
              : 'On Sale',
          seating_chart_id:
            row.seating_plan_id && parseInt(row.seating_plan_id),
          tickets: [
            {
              ticket_name: row.ticket_name,
              ticket_description: row.ticket_description,
              quantity: parseInt(row.quantity),
              ticket_face_value:
                row.ticket_face_value === ''
                  ? 0
                  : parseFloat(row.ticket_face_value),
              booking_fee:
                row.booking_fee === '' ? 0 : parseFloat(row.booking_fee),
              ticket_status:
                row.ticket_status.toLowerCase() === 'hidden'
                  ? 'Hidden'
                  : 'On Sale',
              seating_category_key:
                row.seating_category_key && parseInt(row.seating_category_key),
              max_per_order:
              row.max_per_order && row.max_per_order !== '' ? parseInt(row.max_per_order) : null,
              min_per_order:
              row.min_per_order && row.min_per_order !== '' ? parseInt(row.min_per_order) :null ,
            },
          ],
        };
      } else {
        if (row.ticket_name !== '') {
          currentPerformance.tickets.push({
            ticket_name: row.ticket_name,
            ticket_description: row.ticket_description,
            quantity: parseInt(row.quantity),
            ticket_face_value:
              row.ticket_face_value === ''
                ? 0
                : parseFloat(row.ticket_face_value),
            booking_fee:
              row.booking_fee === '' ? 0 : parseFloat(row.booking_fee),
            ticket_status:
              row.ticket_status.toLowerCase() === 'hidden'
                ? 'Hidden'
                : 'On Sale',
            seating_category_key:
              row.seating_category_key && parseInt(row.seating_category_key),
            max_per_order:
              row.max_per_order && row.max_per_order !== '' ? parseInt(row.max_per_order) : null,
            min_per_order:
              row.min_per_order && row.min_per_order !== '' ? parseInt(row.min_per_order) :null ,
          });
        }
      }
    });
    currentPerformance && formattedData.push(currentPerformance);
    return formattedData;
  };
  const handleBulkUploadClick = file => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      encoding: 'ISO-8859-1',
      error: (error, file) => {
        setError(`${error.row} : ${error.message}`);
      },
      complete: (results, file) => {
        const HeaderFormatCorrect = isHeaderFormatCorrect(results.meta.fields);
        const error = validationError(results);
        if (HeaderFormatCorrect && !error) {
          const data = formatPapaParseResults(results);
          setBulkUploadFileData(data);
          history.push(`/event/${eventId}/bulkupload/review`);
        } else {
          error
            ? setError(error)
            : setError(
                'Oops - there seems to be a problem. Please double check the uploaded CSV Format and ensure it matches the template above'
              );
        }
      },
    });
  };
  return (
    <>
      <PageTop>
        <SubTitle title='New Bulk Upload' />
      </PageTop>
      <BulkUploadForm
        title='New Bulk Upload'
        onBulkUploadClick={handleBulkUploadClick}
        error={error}
        isLoading={!isBusinessSettingsLoaded}
        isEmpty={!businessSettings || !businessSettings.country}
        emptyStateComponent={<Redirect to='/account/ticket-sales/' />}
      />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const eventId = parseInt(match.params.eventId, 10);
  return {
    eventId,
    businessSettings: accountSelectors.getBusinessSettings(state),
    isBusinessSettingsLoaded: accountSelectors.isBusinessSettingsLoaded(state),
  };
};

export default connect(
  mapStateToProps,
  {
    ...eventActions,
    ...accountActions,
  }
)(BulkUpload);
