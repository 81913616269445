import React from 'react';

const PastDue = ({ subscription }) => {
  return (
    <div>
      <p>
        There was a problem taking payment for your latest invoice, please make
        sure your card details are up-to-date. You will not be able to change
        your subscription until your latest invoice has been paid.{' '}
        {/* <a class='button' href='#'>
          Update payment details
        </a>
        or <a href='#'>no thanks, downgrade my account to the free package</a> */}
      </p>
    </div>
  );
};

export default PastDue;
