import React from 'react';
import FormikField from 'components/Form/FormikField';
import FormField from 'components/Form/FormField/FormField';
import FormFieldCountrySelect from 'components/Form/FormFieldCountrySelect/FormFieldCountrySelect';

const AddressSection = () => {
  return (
    <>
      <FormikField
        name="name"
        label="Your name"
        component={FormField}
        type="text"
        placeholder="Your full name"
      />
      <FormikField
        name="companyName"
        label="Company name"
        component={FormField}
        type="text"
        placeholder="Company name (optional)"
      />
      <FormikField
        name="vat"
        label="VAT (if applicable)"
        component={FormField}
        type="text"
        placeholder="Company VAT (optional)"
      />
      <FormikField
        name="street"
        label="Street"
        component={FormField}
        type="text"
        placeholder=""
      />
      <FormikField
        name="town"
        label="Town"
        component={FormField}
        type="text"
        placeholder=""
      />
      <FormikField
        name="city"
        label="City"
        component={FormField}
        type="text"
        placeholder=""
      />
      <FormikField
        name="county"
        label="County"
        component={FormField}
        type="text"
        placeholder=""
      />
      <FormikField
        name="country"
        label="Country"
        component={FormFieldCountrySelect}
        type="select"
      />
      <FormikField
        name="postcode"
        label="Postal code / ZIP code"
        component={FormField}
        type="text"
        placeholder=""
      />
    </>
  );
};

export default AddressSection;
