import React from 'react';
import moment from 'moment';
import {
  aggregatorTemplates as tpl,
  numberFormat
} from '../../../components/PivotTable/Utilities';
import PivotTable from '../../../components/PivotTable/PivotTable';
import ReportSection from '../../../components/ReportSection/ReportSection';

const SalesByTransactionDay = ({ data, title, exportFilename }) => {
  const usFmtInt = numberFormat({ digitsAfterDecimal: 0 });
  const fmt = numberFormat({ prefix: '£' });
  const sortDates = (left, right) => {
    return moment.utc(left, 'YYYY-MM-DD').diff(moment.utc(right, 'YYYY-MM-DD'));
  };
  let totalSold = 0;
  let totalValue = 0;
  return (
    <ReportSection>
      <PivotTable
        title={title}
        exportFilename={`${exportFilename}.csv`}
        data={data}
        aggregators={{
          'Qty Sold': () => tpl.sum(usFmtInt)(['Sold']),
          'Cumulative Qty Sold': () => tpl.sum(usFmtInt)(['Cumulative Qty']),
          Value: () => tpl.sum(fmt)(['Gross']),
          'Cumulative Value': () => tpl.sum(fmt)(['Cumulative Value'])
        }}
        rows={['Transaction Date']}
        derivedAttributes={{
          Sold: record => (record['Gross'] !== 0 ? record['Quantity'] : 0),
          'Cumulative Qty': record => (totalSold += record['Sold']),
          'Cumulative Value': record => (totalValue += record['Gross'])
        }}
        sorters={{
          'Transaction Date': sortDates
        }}
      />
    </ReportSection>
  );
};

export default SalesByTransactionDay;
