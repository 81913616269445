import { combineReducers } from 'redux';
import relationalPaginator from '../shared/relationalPaginator';
import * as actions from './actions';
import * as types from './types';

// {
//     1 : {
//         byPage: {
//             1: {
//                 ids: [1,2,3],
//                 isFetching:true,
//                 isFetched:false,
//                 lastUpdated:now(),
//                 error:"this is an error",
//                 currentPage:1,
//                 totalPages:10
//             },
//             2: {
//                 ids:[4,5,6],
//
//             }
//     }
// }

const ticketAllocationsByTicketId = relationalPaginator({
  fetchRoutine: actions.fetchTicketAllocationPage,
  mapActionToPage: action => action.payload.page,
  mapActionToParentKey: action => action.payload.ticketId
});

const purchases = (state = [], action = {}) => {
  switch (action.type) {
    case types.ADD_CUSTOMER_DATA:
      const purchases = action.payload.purchasesData;
      return [...purchases];
    default:
      return state;
  }
};

const discount = (state = null, action = {}) => {
  switch (action.type) {
    case types.ADD_DISCOUNT:
      return action.payload.discountId;
    case types.REMOVE_DISCOUNT:
      return null;
    default:
      return state;
  }
};

const agent = (state = null, action = {}) => {
  switch (action.type) {
    case types.ADD_AGENT:
      return action.payload.agentId;
    case types.REMOVE_AGENT:
      return null;
    default:
      return state;
  }
};

const error = (state = null, action) => {
  switch (action.type) {
    case actions.issueTicket.TRIGGER:
      return null;
    case actions.issueTicket.FAILURE:
      return action.payload.errors ? action.payload : 'Unknown error';
    default:
      return state;
  }
};
const currentAllocation = combineReducers({
  purchases,
  discount,
  agent,
  error
});

const ticketAllocation = combineReducers({
  currentAllocation,
  ticketAllocationsByTicketId
});

export default ticketAllocation;
