import React from 'react';

// Grommet
import { Box } from 'grommet';

const InfoBlockList = ({ children, form }) => {
  return (
    <Box direction="column" gap="xsmall">
      {children}
    </Box>
  );
};

export default InfoBlockList;
