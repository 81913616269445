import React from "react";
import PageNav, { PageNavItem } from "../../components/PageNav/PageNav";
import { Title, ContentType } from "../../components/MainContent/MainContent";

// Grommet
import { Box, ResponsiveContext } from "grommet";
import { Cube } from "grommet-icons";

const ProductNav = ({ title, productId }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <div>
          <Box>
            <ContentType icon={<Cube size="small" />} label="Product" />
            <Box
              direction="row"
              justify="between"
              align="center"
              margin={{ top: "small" }}
            >
              <Title>{title}</Title>
            </Box>
            <Box margin={{ vertical: "medium" }}>
              {size !== "small" ? (
                <PageNav mobileMenu="Product Menu">
                  <PageNavItem to={`/product/${productId}`} label="Info" />
                  {/* <PageNavItem
                    to={`/product/${productId}/embed-settings`}
                    label="Embed"
                  /> */}
                </PageNav>
              ) : (
                <PageNav mobileMenu="Product Menu">
                  {[
                    {
                      label: (
                        <PageNavItem
                          to={`/product/${productId}`}
                          label="Info"
                        />
                      )
                    },
                    {
                      /* {
                      label: (
                        <PageNavItem
                          to={`/product/${productId}/embed-settings`}
                          label="Embed"
                        />
                      )
                    } */
                    }
                  ]}
                </PageNav>
              )}
            </Box>
          </Box>
        </div>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default ProductNav;
