import isHotkey from 'is-hotkey';

const onKeyDown = (event, editor, next) => {
  const marks = [
    ['b', 'bold'],
    ['i', 'italic'],
    ['s', 'strikethrough'],
    ['u', 'underline'],
    ['`', 'code']
  ];
  const [, markName] =
    marks.find(([key]) => isHotkey(`mod+${key}`, event)) || [];
  if (markName) {
    event.preventDefault();
    return editor.toggleMark(markName);
  } else {
    return next();
  }
};
export default onKeyDown;
