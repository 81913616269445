import React from 'react';
import classNames from 'classnames';

import './TableHeader.css';

const TableHeader = ({ children, className, onClick, borders, bgColor }) => {
  const headerStyle = {
    backgroundColor: `#${bgColor}`
  };
  const handleClick = e => {
    e.preventDefault();
    onClick && onClick(e);
  };
  const tblClass = classNames('lutable__header', className, {
    'lutable__header--selectable': onClick,
    'lutable__header--borders': borders
  });
  return (
    <div style={headerStyle} className={tblClass} onClick={handleClick}>
      {children}
    </div>
  );
};
export default TableHeader;
