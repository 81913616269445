// import { combineReducers } from 'redux';
// byParentId: {
// 1 : {
//     currentPage:1,
//     totalPages:10,
//     resultsPerPage: 100,
//     byPage: {
//         1: {
//             ids: [1,2,3],
//             isFetching:true,
//             isFetched:false,
//             lastUpdated:now(),
//             error:"this is an error"
//         }
//     }
// }
// }

// const paginate = ({ fetchRoutine, mapActionToPage }) => {
//   if (typeof mapActionToPage !== 'function') {
//     throw new Error('Expected mapActionToPage to be a function.');
//   }

//   const updatePagination = (
//     state = {
//       isFetching: false,
//       isFetched: false,
//       lastUpdated: null,
//       error: null,
//       ids: [],
//     },
//     action
//   ) => {
//     switch (action.type) {
//       case fetchRoutine.TRIGGER:
//         return {
//           ...state,
//           isFetching: true,
//           isFetched: false,
//           error: null,
//           lastUpdated: Date.now(),
//         };
//       case fetchRoutine.SUCCESS:
//         return {
//           ...state,
//           ids: action.payload.response.result,
//         };
//       case fetchRoutine.FAILURE:
//         return {
//           ...state,
//           error: action.error,
//           lastUpdated: null,
//         };
//       case fetchRoutine.FULFILL:
//         return {
//           ...state,
//           isFetching: false,
//           isFetched: true,
//         };
//       default:
//         return state;
//     }
//   };
//   const currentPage = (currentPage = 1, action = {}) => {
//     return action.type === fetchRoutine.TRIGGER
//       ? action.payload.page
//       : currentPage;
//   };
//   const resultsPerPage = (resultsPerPage = 25, action = {}) => {
//     return action.type === fetchRoutine.SUCCESS
//       ? action.payload.response
//         ? action.payload.response.pagination.resultsPerPage
//         : resultsPerPage
//       : resultsPerPage;
//   };
//   const totalPages = (totalPages = 1, action = {}) => {
//     return action.type === fetchRoutine.SUCCESS
//       ? action.payload.response
//         ? action.payload.response.pagination.totalPages
//         : totalPages
//       : totalPages;
//   };

//   const byPage = (state = {}, action) => {
//     switch (action.type) {
//       case fetchRoutine.TRIGGER:
//       case fetchRoutine.SUCCESS:
//       case fetchRoutine.FAILURE:
//       case fetchRoutine.FULFILL:
//         const page = mapActionToPage(action);
//         return {
//           ...state,
//           [page]: updatePagination(state[page], action),
//         };
//       default:
//         return state;
//     }
//   };

//   return combineReducers({
//     byPage,
//     currentPage,
//     resultsPerPage,
//     totalPages,
//   });
// };

// export default paginate;

const relationalPaginator = ({
  fetchRoutine,
  mapActionToPage,
  mapActionToParentKey,
}) => {
  if (typeof mapActionToPage !== 'function') {
    throw new Error('Expected mapActionToPage to be a function.');
  }
  if (typeof mapActionToParentKey !== 'function') {
    throw new Error('Expected mapActionToParentKey to be a function.');
  }
  const updatePagination = (
    state = {
      isFetching: false,
      isFetched: false,
      lastUpdated: null,
      error: null,
      ids: [],
    },
    action
  ) => {
    switch (action.type) {
      case fetchRoutine.TRIGGER:
        return {
          ...state,
          isFetching: true,
          isFetched: false,
          error: null,
          lastUpdated: Date.now(),
        };
      case fetchRoutine.SUCCESS:
        return {
          ...state,
          ids: action.payload.response.result,
        };
      case fetchRoutine.FAILURE:
        return {
          ...state,
          error: action.error,
          lastUpdated: null,
        };
      case fetchRoutine.FULFILL:
        return {
          ...state,
          isFetching: false,
          isFetched: true,
        };
      default:
        return state;
    }
  };
  const updateByPage = (state = {}, action) => {
    const page = mapActionToPage(action) || 1;
    return {
      ...state,
      [page]: updatePagination(state[page], action),
    };
  };

  const updateChildren = (
    state = {
      byPage: {},
      currentPage: 1,
      resultsPerPage: 25,
      totalPages: 1,
    },
    action
  ) => {
    switch (action.type) {
      case fetchRoutine.TRIGGER:
        return {
          ...state,
          currentPage: action.payload.page,
          byPage: updateByPage(state.byPage, action),
        };
      case fetchRoutine.SUCCESS:
        return {
          ...state,
          resultsPerPage: action.payload.response.pagination.perPage,
          totalPages: action.payload.response.pagination.totalPages,
          currentPage: action.payload.response.pagination.pageNumber,
          byPage: updateByPage(state.byPage, action),
        };
      case fetchRoutine.FAILURE:
      case fetchRoutine.FULFILL:
        return {
          ...state,
          byPage: updateByPage(state.byPage, action),
        };
      default:
        return state;
    }
  };
  const byParentId = (state = {}, action) => {
    switch (action.type) {
      case fetchRoutine.TRIGGER:
      case fetchRoutine.SUCCESS:
      case fetchRoutine.FAILURE:
      case fetchRoutine.FULFILL:
        const parentId = mapActionToParentKey(action);
        return {
          ...state,
          [parentId]: updateChildren(state[parentId], action),
        };
      default:
        return state;
    }
  };

  return byParentId;
};

export default relationalPaginator;

const getIdsForPage = (state, page) =>
  state.byPage[page] ? state.byPage[page].ids : [];
const getCurrentPage = state => state.currentPage;
const getTotalPages = state => state.totalPages;
const getIsLoading = (state, page) =>
  state.byPage[page] ? state.byPage[page].isFetching : false;
const getIsLoaded = (state, page) =>
  state.byPage[page] ? state.byPage[page].isFetched : false;

export const getIdsForParentIdAndPage = (state, parentId, page) =>
  state[parentId] ? getIdsForPage(state[parentId], page) : [];
export const getCurrentPageForParentId = (state, parentId) =>
  state[parentId] ? getCurrentPage(state[parentId]) : 1;
export const getTotalPagesForParentId = (state, parentId) =>
  state[parentId] ? getTotalPages(state[parentId]) : 1;
export const getIsLoadingForParentId = (state, parentId, page) =>
  state[parentId] ? getIsLoading(state[parentId], page) : false;
export const getIsLoadedForParentId = (state, parentId) =>
  state[parentId] ? getIsLoaded(state[parentId]) : false;
