import { takeEvery } from 'redux-saga/effects';
import * as api from '../../services/api';
import * as actions from './actions';
import { fetchEntity } from '../shared/operations';

const fetchInvoices = fetchEntity.bind(
  null,
  actions.fetchInvoices,
  api.fetchInvoices
);
export function* watchFetchInvoices() {
  yield takeEvery(actions.fetchInvoices, fetchInvoices);
}

const fetchInvoice = fetchEntity.bind(
  null,
  actions.fetchInvoice,
  api.fetchInvoice
);
export function* watchFetchInvoice() {
  yield takeEvery(actions.fetchInvoice, fetchInvoice);
}
//
// export const fetchInvoicePdf = fetchEntity.bind(
//   null,
//   actions.fetchInvoicePdf,
//   api.fetchInvoicePdf
// );
// export function* watchFetchAgentInvoicePdf() {
//   yield takeEvery(actions.fetchInvoicePdf, fetchInvoicePdf);
// }
//
// export const deleteInvoice = deleteEntity.bind(
//   null,
//   actions.deleteInvoice,
//   api.deleteInvoice
// );
// export function* watchDeleteAgentInvoice() {
//   yield takeEvery(actions.deleteInvoice, deleteInvoice);
//   while (true) {
//     const {
//       payload: { page, agentId }
//     } = yield take(actions.deleteInvoice.SUCCESS);
//     yield put(actions.fetchInvoices({ page: page, agentId: agentId }));
//   }
// }
