import React from 'react';

import { connect } from 'react-redux';

import { hideModal } from 'modules/modal/modal';

import Modal from 'components/Modal/Modal';

import { Box, Button } from 'grommet';

import { Title } from 'components/MainContent/MainContent';

import { accountActions } from 'modules/account/account';

import { bindActionCreators } from 'redux';

const StripeDisconnectModal = ({ hideModal, deleteStripeSettings }) => {
  const handleCloseModal = () => {
    hideModal();
  };
  const handleStripeDisconnectClick = () => {
    deleteStripeSettings();
    hideModal();
  };
  return (
    <Modal onRequestClose={handleCloseModal}>
      <Box pad='medium' background={{ color: 'white' }}>
        <div>
          <Title>Disconnect from Stripe </Title>
          <Box margin={{ vertical: 'medium' }}>
            If you disconnect your Stripe account, you will no longer be able to
            sell any tickets and any tickets that you have listed will be no
            longer available to purchase.
          </Box>
          <Box gap='small' direction='row'>
            <Button
              primary
              onClick={handleStripeDisconnectClick}
              label='Disconnect from Stripe'
              size='small'
              hoverIndicator='error-hover'
              color='error'
            />

            <Button
              secondary
              onClick={handleCloseModal}
              label='Cancel'
              size='small'
            />
          </Box>
        </div>
      </Box>
    </Modal>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        ...accountActions,
        hideModal
      },
      dispatch
    )
  };
};

export default connect(null, mapDispatchToProps)(StripeDisconnectModal);
