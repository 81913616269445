import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './TicketReceiptPlacement.module.css';

const TicketReceiptPlacement = ({ placement, onClick }) => {
  let name = '';
  if (placement.ticketReceipt.firstName && placement.ticketReceipt.lastName) {
    name = `${placement.ticketReceipt.firstName} ${placement.ticketReceipt.lastName}`;
  } else {
    name = placement.orderSummary.customer.displayname;
  }

  const customStyles = {};
  if (placement.colour) {
    customStyles.backgroundColor = placement.colour;
  }

  return (
    <div className={styles.root} onClick={onClick}>
      <div className={styles.content} style={customStyles}>
        <span className={styles.laneTicketReceiptPlacementName}>{name}</span>
        {placement.hasNotes && (
          <div className={styles.laneTicketReceiptNotesIcon}>
            <FontAwesomeIcon icon="align-left" />
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketReceiptPlacement;
