import * as fromEntities from '../shared/entities';
import {
  getIdsForParentIdAndPage,
  getCurrentPageForParentId,
  getTotalPagesForParentId,
  getIsLoadingForParentId,
  getIsLoadedForParentId,
} from '../shared/relationalPaginator';

export const getTicket = (state, ticketId) =>
  fromEntities.getEntity(state.entities.ticket, ticketId);
export const getUserSettings = state =>
  fromEntities.getEntity(
    state.entities.userSettings,
    state.tickets.currentUser.id
  );
export const getIsUserSettingsLoaded = state =>
  !state.tickets.currentUser.isLoading && state.tickets.currentUser.isLoaded;

export const getForPerformanceIdAndPage = (state, performanceId, page) =>
  getIdsForParentIdAndPage(
    state.tickets.ticketsByPerformanceId,
    performanceId,
    page
  ).map(id => getTicket(state, id));
export const getCurrentPageForPerformanceId = (state, performanceId) =>
  getCurrentPageForParentId(
    state.tickets.ticketsByPerformanceId,
    performanceId
  );
export const getTotalPagesForPerformanceId = (state, performanceId) =>
  getTotalPagesForParentId(state.tickets.ticketsByPerformanceId, performanceId);
export const getIsLoadingForPerformanceId = (state, performanceId, page) =>
  getIsLoadingForParentId(
    state.tickets.ticketsByPerformanceId,
    performanceId,
    page
  );
export const getIsLoadedForPerformanceId = (state, performanceId) =>
  getIsLoadedForParentId(state.tickets.ticketsByPerformanceId, performanceId);

export const getAvailabilityForPerformanceId = (state, performanceId) => {
  const tickets = getForPerformanceIdAndPage(state, performanceId, 1);
  return {
    totalSold: tickets.reduce((a, b) => a + b.quantitySold, 0),
    totalComps: tickets.reduce((a, b) => a + b.quantityComped, 0),
    totalRedeemed: tickets.reduce((a, b) => a + b.quantityRedeemed, 0),
    totalAllocated: tickets.reduce((a, b) => a + b.quantityAllocated, 0),
    totalReserved: tickets.reduce((a, b) => a + b.quantityReserved, 0),
  };
};
export const getTransferEntries = state => state.tickets.transferEntries;
