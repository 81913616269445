import { takeEvery, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as api from '../../services/api';
import * as actions from './actions';
import { fetchEntity, submitForm } from '../shared/operations';

const fetchProducts = fetchEntity.bind(
  null,
  actions.fetchProductsPage,
  api.fetchProducts
);

export function* watchFetchProducts() {
  yield takeEvery(actions.fetchProductsPage.TRIGGER, fetchProducts);
}

const fetchProduct = fetchEntity.bind(
  null,
  actions.fetchProduct,
  api.fetchProduct
);

export function* watchFetchProduct() {
  yield takeEvery(actions.fetchProduct.TRIGGER, fetchProduct);
}

const createProduct = submitForm.bind(
  null,
  actions.createProduct,
  api.createProduct
);

function* showNewProduct(action) {
  const { payload } = action;
  yield put(push(`/product/${payload.response.result}`));
}

export function* watchCreateProduct() {
  yield takeEvery(actions.createProduct.TRIGGER, createProduct);
  yield takeLatest(actions.createProduct.SUCCESS, showNewProduct);
}

const updateProduct = submitForm.bind(
  null,
  actions.updateProduct,
  api.updateProduct
);

export function* watchUpdateProduct() {
  yield takeEvery(actions.updateProduct.TRIGGER, updateProduct);
}
