import React, { Component } from 'react';
import DiscountForm from '../../components/DiscountForm/DiscountForm';
import MainContent, { Title } from '../../components/MainContent/MainContent';
import { discountActions } from '../../modules/discounts/discounts';
import { ticketGroupActions } from '../../modules/ticketGroups/ticketGroups';
import { connect } from 'react-redux';

// Grommet
import { Box } from 'grommet';

class Discounts extends Component {
  componentWillMount() {
    if (this.props.discountId) {
      this.props.loadDiscount(this.props.discountId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.discountId !== nextProps.discountId) {
      this.props.loadDiscount(nextProps.discountId);
    }
  }
  handleDiscountSubmit = values => {
    const newDiscount = {
      ...values,
      fixedDiscount: values.amountType.value === 'fixed' ? values.amount : 0,
      percentageDiscount:
        values.amountType.value === 'percent' ? values.amount / 100 : 0,
      ticketPrice: values.amountType.value === 'price' ? values.amount : null,
      ticketGroups: values.ticketGroups
        ? values.ticketGroups.map(v => ({ slug: v.value }))
        : [],
      status: values.enabled ? 'active' : 'inactive',
      maxUses: values.maxUses ? values.maxUses : null,
      minBasketValue: values.minBasketValue ? values.minBasketValue : 0,
      maxDiscountAmount: values.maxDiscountAmount
        ? values.maxDiscountAmount
        : null,
      minTicketQuantity: values.minTicketQuantity
        ? values.minTicketQuantity
        : null,
    };
    if (this.props.discountId) {
      this.props.updateDiscountCode(
        this.props.discountId,
        newDiscount,
        this.props.history.push
      );
    } else {
      this.props.createDiscountCode(newDiscount, this.props.history.push);
    }
  };

  render() {
    return (
      <div>
        <MainContent>
          <Title>
            {this.props.discountId ? 'Edit Discount Code' : 'New Discount Code'}
          </Title>
          <Box margin={{ vertical: 'medium' }}>
            <DiscountForm
              onSubmit={this.handleDiscountSubmit}
              discountId={this.props.discountId}
            />
          </Box>
        </MainContent>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const discountId = ownProps.match.params.discountId
    ? parseInt(ownProps.match.params.discountId, 10)
    : null;
  return {
    discountId,
  };
};

export default connect(
  mapStateToProps,
  {
    ...discountActions,
    ...ticketGroupActions,
  }
)(Discounts);
