import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';
/* eslint-disable react/style-prop-object */
import MainContent, {
  ContentContainer,
  Title,
  ContentType,
  SubTitle,
} from '../../../components/MainContent/MainContent';
import InfoBlock, {
  InfoBlockList,
  InfoBlockListItem,
  InfoBlockActions,
} from '../../../components/InfoBlock/InfoBlock';

import {
  ticketAllocationActions,
  ticketAllocationSelectors,
} from '../../../modules/ticketAllocation/ticketAllocation';
import {
  discountActions,
  discountSelectors,
} from '../../../modules/discounts/discounts';
import { agentActions, agentSelectors } from '../../../modules/agents/agents';
import { showModal } from '../../../modules/modal/modal';

import styled from 'styled-components';

// Grommet
import { Box, Button, Text } from 'grommet';
import { Edit, Trash, Bookmark, Upload, LinkUp } from 'grommet-icons';

const DeleteButton = styled(Button)`
  background-color: #ff2e4a;
  &:hover {
    background-color: #d41c34;
  }
`;

class TicketAllocation extends Component {
  componentDidMount() {
    this.props.fetchTicketAllocation({ allocationId: this.props.allocationId });
    this.props.searchDiscounts({ page: 1, discountType: 'agent' });
    this.props.fetchAgentsPage({ page: 1 });
    this.props.removeDiscount();
    this.props.removeAgent();
  }

  componentDidUpdate(prevProps) {
    if (this.props.allocationId !== prevProps.allocationId) {
      this.props.fetchTicketAllocation({
        allocationId: this.props.allocationId,
      });
      this.props.removeDiscount();
      this.props.removeAgent();
    }
  }

  handleIssueTicketClick = (e) => {
    e.preventDefault();
    this.props.showModal('ISSUE_TICKET', {
      ticket: this.props.ticket,
      allocationId: this.props.allocationId,
      agentId: this.props.allocation.agent
        ? this.props.allocation.agent.id
        : null,
      onSubmitSuccess: this.handleOnSubmitSuccessIssue,
    });
  };

  handleBulkIssueClick = (e) => {
    e.preventDefault();
    if (this.props.allocation.agent) {
      this.props.addAgent(this.props.allocation.agent.id);
    }
    this.props.showModal('BULK_ISSUE_TICKETS', {
      onSubmitSuccess: this.handleOnSubmitSuccessBulk,
      ticket: this.props.ticket,
      allocationId: this.props.allocationId,
      history: this.props.history,
      discounts: this.props.discounts,
      agents: this.props.agents,
      agentId: this.props.allocation.agent
        ? this.props.allocation.agent.id
        : null,
    });
  };

  handleOnSubmitSuccessBulk = () => {
    this.props.history.push(`${this.props.match.url}/review`);
  };
  handleOnSubmitSuccessIssue = (result) => {
    this.props.fetchTicketAllocation({ allocationId: this.props.allocationId });
  };
  handelEditClick = (evt) => {
    evt.preventDefault();
    this.props.showModal('TICKET_ALLOCATION_CREATE_MODAL', {
      ticketId: this.props.ticket.id,
      maxInventory:
        this.props.ticket.inventoryLeft + this.props.allocation.quantity,
      allocationId: this.props.allocationId,
    });
  };
  handelDeleteClick = (evt) => {
    evt.preventDefault();
    this.props.showModal('ALERT_MODAL', {
      title: 'Delete Allocation',
      description: `Are you sure you wish to delete this allocation? Any remaining inventory on this allocation will be released back to the ticket inventory and all reference to this allocation will be removed from any reports.`,
      onConfirmClick: this.handleConfirmDelete,
    });
  };
  handleConfirmDelete = () => {
    const { eventId, performanceId, ticket } = this.props;
    const successUrl = `/event/${eventId}/performance/${performanceId}/ticket/${ticket.id}`;
    this.props.deleteTicketAllocation({
      allocationId: this.props.allocationId,
      onSuccessUrl: successUrl,
    });
  };
  render() {
    const { allocation, ticket } = this.props;
    const { agent } = allocation;

    if (!allocation.description) {
      return (
        <MainContent>
          <Title>Manage Ticket Allocation</Title>
        </MainContent>
      );
    }
    return (
      <ContentContainer>
        <Box
          margin={{ top: 'small', bottom: 'large' }}
          pad='medium'
          background='infobox'
          round='xsmall'
        >
          <ContentType icon={<Bookmark size='small' />} label='Allocation' />
          <Box margin={{ vertical: 'small' }}>
            <SubTitle title={allocation.description}>
              <Button
                secondary
                size='small'
                label='Edit'
                icon={<Edit size='small' />}
                onClick={this.handelEditClick}
              />
              <DeleteButton
                primary
                size='small'
                label='Delete'
                icon={<Trash size='small' />}
                onClick={this.handelDeleteClick}
              />
            </SubTitle>
            <Text size='small'>{allocation.quantity} Tickets Held</Text>
          </Box>

          <SubTitle title={'Issue Tickets'} />
          <InfoBlock isInverted={true}>
            <InfoBlockActions>
              <Button
                secondary
                size='small'
                label='Issue Tickets'
                icon={<LinkUp size='small' />}
                onClick={this.handleIssueTicketClick}
              />
              <Button
                secondary
                size='small'
                label='Bulk Issue'
                icon={<Upload size='small' />}
                onClick={this.handleBulkIssueClick}
              />
            </InfoBlockActions>
          </InfoBlock>
          <SubTitle title='Ticket Details' />
          <InfoBlock isInverted={true}>
            <InfoBlockList>
              <InfoBlockListItem
                attribute='Description'
                value={ticket.description}
              />
              {ticket.feeTotal > 0 && (
                <InfoBlockListItem
                  attribute='Face Vale'
                  value={
                    <FormattedNumber
                      value={ticket.faceValueTotal}
                      style='currency'
                      currency={ticket.currency}
                    />
                  }
                />
              )}
              {ticket.feeTotal > 0 && (
                <InfoBlockListItem
                  attribute='Booking Fee'
                  value={
                    <FormattedNumber
                      value={ticket.feeTotal}
                      style='currency'
                      currency={ticket.currency}
                    />
                  }
                />
              )}
              <InfoBlockListItem
                attribute='Total'
                value={
                  <FormattedNumber
                    value={ticket.total}
                    style='currency'
                    currency={ticket.currency}
                  />
                }
              />
            </InfoBlockList>
          </InfoBlock>
          {agent && agent.commissionRate && (
            <Fragment>
              <SubTitle title='Allocation Agent' />
              <InfoBlock isInverted={true}>
                <InfoBlockList>
                  <InfoBlockListItem
                    attribute='Agent'
                    value={
                      <Link
                        style={{ textDecoration: 'none', width: '100%' }}
                        to={`/agent/${agent.id}`}
                      >
                        <Text size='small' color='brand'>
                          {agent.company}
                        </Text>
                      </Link>
                    }
                  />
                  {agent.commissionRate.insideFeePercentage > 0 && (
                    <InfoBlockListItem
                      attribute='Inside Fee Percentage'
                      value={
                        <FormattedNumber
                          value={agent.commissionRate.insideFeePercentage}
                          style='percent'
                        />
                      }
                    />
                  )}
                  {agent.commissionRate.insideFeeFixedValue > 0 && (
                    <InfoBlockListItem
                      attribute='Inside Fee Fixed Value'
                      value={
                        <FormattedNumber
                          value={agent.commissionRate.insideFeeFixedValue}
                          style='currency'
                          currency={ticket.currency}
                        />
                      }
                    />
                  )}
                  {agent.commissionRate.insideFeeTaxRate > 0 && (
                    <InfoBlockListItem
                      attribute='Inside Fee Tax Rate'
                      value={
                        <FormattedNumber
                          value={agent.commissionRate.insideFeeTaxRate}
                          style='percent'
                        />
                      }
                    />
                  )}
                </InfoBlockList>
              </InfoBlock>
            </Fragment>
          )}
        </Box>
      </ContentContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const allocationId = parseInt(match.params.allocationId, 10);
  const allocation = ticketAllocationSelectors.getTicketAllocation(
    state,
    allocationId
  );
  const ticket = allocation.ticket
    ? ticketAllocationSelectors.getTicket(state, allocation.ticket)
    : {};
  return {
    allocationId,
    allocation,
    ticket,
    discounts: discountSelectors.getDiscountsForPage(state, 1),
    agents: agentSelectors.getAgentsForPage(state, 1),
    performanceId: parseInt(match.params.performanceId, 10),
    eventId: parseInt(match.params.eventId, 10),
  };
};

export default connect(mapStateToProps, {
  ...ticketAllocationActions,
  ...discountActions,
  ...agentActions,
  showModal,
})(TicketAllocation);
