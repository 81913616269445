import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { bindPromiseCreators } from 'redux-saga-routines';

import MainContent, { Title } from 'components/MainContent/MainContent';
import AccountNav from 'components/Account/AccountNav/AccountNav';
import BillingForm from 'components/Subscription/BillingForm';

import { accountSelectors, accountActions } from 'modules/account/account';

const CreatePaymentMethod = ({
  payment,
  dispatch,
  submitCreatePaymentMethod,
  packageOption
}) => {
  const handleSubmit = (values, { setFormError }) => {
    return submitCreatePaymentMethod(values)
      .then(() => {
        dispatch(
          push(`/account/confirm-subscription?packageOption=${packageOption}`)
        );
      })
      .catch(e => {
        setFormError(e.errors._error);
      });
  };

  return (
    <MainContent>
      <Title>Your Account</Title>
      <AccountNav />
      <h1>Please add your payment details</h1>

      <p>To turn on your subscription, we need your billing details</p>
      <p>
        <FontAwesomeIcon icon="info-circle" /> <b>About Line-Up billing</b>
      </p>
      <p>
        You are able to cancel your plan at any point during your billing cycle
        and at the end of the billing cycle, no further charges will be made
        against your card.
      </p>

      <h1>Billing &amp; payment details</h1>

      <p>
        Please complete the following billing and payment card details before
        reviewing your order on the next page
      </p>
      <br />

      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}>
        <Elements>
          <BillingForm onSubmit={handleSubmit} />
        </Elements>
      </StripeProvider>
    </MainContent>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    payment: accountSelectors.getPaymentDetails(state),
    packageOption: state.router.location.query.packageOption
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        submitCreatePaymentMethod: accountActions.submitCreatePaymentMethod
      },
      dispatch
    ),
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePaymentMethod);
