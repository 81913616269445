import React, { useEffect } from "react";
import { connect } from "react-redux";

// Components
import { SubTitle } from "../../../components/MainContent/MainContent";

// Grommet
import { Box, Button, ResponsiveContext } from "grommet";
import { DocumentPdf } from "grommet-icons";

import PerformanceDetail from "../../report/sales/PerformanceDetail";
import {
  reportActions,
  reportSelectors,
} from "../../../modules/reports/reports";

//utils
import moment from "moment";

const EventSales = ({
  eventId,
  reportData,
  fetchTicketData,
  exportSalesReportPDF,
}) => {
  useEffect(() => {
    fetchTicketData({
      eventId: eventId,
      reportName: "performance-detail",
      from: new Date(2018, 1, 1, 0, 0, 0, 0).toISOString(),
      to: new Date().toISOString(),
    });
  }, [fetchTicketData, eventId]);
  const handleDownloadSalesReportPdf = () => {
    exportSalesReportPDF({ eventId });
  };
  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <>
          <Box
            direction={size !== "small" ? "row" : "column"}
            justify="between"
            align="center"
          >
            <SubTitle title="Sales" />
            <Box width={size !== "small" ? "medium" : "100%"}>
              <Button
                secondary
                size="small"
                label="Download Full Sales Report"
                icon={<DocumentPdf size="small" />}
                onClick={handleDownloadSalesReportPdf}
                fill={size !== "small" ? false : true}
              />
            </Box>
          </Box>
          <Box margin={{ vertical: "medium" }}>
            <PerformanceDetail
              data={reportData}
              title="Sales By Performance Date"
              exportFilename="sales-by-performance-date"
            />
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const eventId = parseInt(match.params.eventId, 10);
  return {
    reportData: reportSelectors.reportData(state),
    eventId,
  };
};

export default connect(mapStateToProps, {
  ...reportActions,
})(EventSales);
