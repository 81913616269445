import { combineReducers } from 'redux';
import paginate from '../shared/paginate';
import * as actions from './actions';
import * as types from './types';

const byPage = paginate({
  mapActionToKey: action => action.page,
  types: [
    actions.fetchPerformancesPage.REQUEST,
    actions.fetchPerformancesPage.SUCCESS,
    actions.fetchPerformancesPage.FAILURE
  ]
});

const byId = (state = [], action) => {
  switch (action.type) {
    case actions.fetchAllPerformances.TRIGGER:
      return [];
    case actions.fetchPerformancesPage.SUCCESS:
      return [...state, ...action.payload.response.result];
    case actions.fetchAllPerformances.FAILURE:
      return [];
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case actions.fetchAllPerformances.TRIGGER:
      return true;
    case actions.fetchAllPerformances.SUCCESS:
    case actions.fetchAllPerformances.FAILURE:
      return false;
    default:
      return state;
  }
};

const filters = (
  state = {
    date: 'future'
  },
  action
) => {
  switch (action.type) {
    case types.SET_DATE_FILTER:
      return {
        ...state,
        date: action.payload
      };
    default:
      return state;
  }
};

const performances = combineReducers({
  byPage,
  byId,
  loading,
  filters
});

export default performances;
