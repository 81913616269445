import React, { Component } from 'react';
import { connect } from 'react-redux';
import UserForm from '../../components/UserForm/UserForm';
import MainContent, { Title } from '../../components/MainContent/MainContent';
import { userActions } from '../../modules/users/users';
import { removeEmpty } from '../../utils/forms';

// Grommet
import { Box } from 'grommet';

class UserEdit extends Component {
  componentWillMount() {
    this.props.loadUser(this.props.userId);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.userId !== nextProps.userId) {
      this.props.loadUser(nextProps.userId);
    }
  }

  handleUserSubmit = values => {
    this.props.updateUser(this.props.userId, removeEmpty(values));
  };
  handleSubmitSuccess = () => {
    this.props.history.push(`/customer/${this.props.userId}`);
  };

  render() {
    return (
      <MainContent>
        <Title>Edit Customer Details</Title>
        <Box margin={{ top: 'medium' }}>
          <UserForm
            onSubmit={this.handleUserSubmit}
            onSubmitSuccess={this.handleSubmitSuccess}
            userId={this.props.userId}
          />
        </Box>
      </MainContent>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userId: parseInt(ownProps.match.params.userId, 10)
  };
};

export default connect(mapStateToProps, {
  ...userActions
})(UserEdit);
