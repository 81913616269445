import { takeEvery } from 'redux-saga/effects';
import { fetchEntity, submitForm } from '../shared/operations';
import * as actions from './actions';
import * as api from '../../services/api';

const fetchEventPlanner = fetchEntity.bind(
  null,
  actions.fetchEventPlanner,
  api.fetchEventPlanner
);

export function* watchFetchEventPlanner() {
  yield takeEvery(actions.fetchEventPlanner.TRIGGER, fetchEventPlanner);
}

const createEventPlanner = submitForm.bind(
  null,
  actions.createEventPlanner,
  api.createEventPlanner
);

export function* watchCreateEventPlanner() {
  yield takeEvery(actions.createEventPlanner.TRIGGER, createEventPlanner);
}

const updateEventPlanner = submitForm.bind(
  null,
  actions.updateEventPlanner,
  api.updateEventPlanner
);

export function* watchUpdateEventPlanner() {
  yield takeEvery(actions.updateEventPlanner.TRIGGER, updateEventPlanner);
}

const deleteEventPlanner = submitForm.bind(
  null,
  actions.deleteEventPlanner,
  api.deleteEventPlanner
);

export function* watchDeleteEventPlanner() {
  yield takeEvery(actions.deleteEventPlanner.TRIGGER, deleteEventPlanner);
}
