import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import { TableCell, TableRow } from '../../../../Table/Table';
import { Price } from '../../../../Utils/Utils';
import './DonationRowItem.css';

const DonationRowItem = ({ donation, onSelect, isSelected, className }) => {
  const refundTotal = donation.totalRefund;
  const isFullyRefund = donation.total - refundTotal === 0;
  const handleClick = () => {
    !isFullyRefund && onSelect && onSelect(donation.id);
  };
  const rowClass = classNames(className, 'receipt-row', {
    'receipt-row--selected': isSelected,
    'receipt-row--disabled': isFullyRefund
  });

  return (
    <TableRow striped={true} onClick={handleClick} className={rowClass}>
      <TableCell>
        {!isFullyRefund && (
          <input readOnly type="checkbox" checked={isSelected} />
        )}
      </TableCell>
      <TableCell>
        <span>
          <Price
            amount={donation.total}
            currency={donation.currency}
            showZero
          />{' '}
          Donation
        </span>
      </TableCell>
      <TableCell>
        <Price
          amount={donation.total - refundTotal}
          currency={donation.currency}
          showZero
        />
        {refundTotal > 0 && (
          <Fragment>
            <FontAwesomeIcon
              data-tip=""
              data-for="donationTotal"
              className="receipt-row__icon receipt-row__icon--refund"
              name="info-circle"
            />
            <ReactTooltip id="donationTotal">
              <span>
                <Price amount={refundTotal} currency={donation.currency} />{' '}
                refunded
              </span>
            </ReactTooltip>
          </Fragment>
        )}
      </TableCell>
      <TableCell>
        {refundTotal > 0 && (
          <Fragment>
            <FontAwesomeIcon
              data-for={`donation-total-${donation.id}`}
              data-tip=""
              className="receipt-row__icon receipt-row__icon--refund"
              name="undo"
            />
            <ReactTooltip id={`donation-total-${donation.id}`}>
              <span>
                {isFullyRefund ? 'Fully refunded:' : 'Partially refunded:'}{' '}
                <Price amount={refundTotal} currency={donation.currency} />
              </span>
            </ReactTooltip>
          </Fragment>
        )}
      </TableCell>
    </TableRow>
  );
};

export default DonationRowItem;
