import * as api from '../../services/api';
import { pagedList } from '../shared/pagedList';
import * as moduleOperations from './operations';
import * as moduleActions from './actions';

const { selectors, reducer, operations, actions } = pagedList(
  'email',
  api.fetchEmails
);

export const emailSelectors = selectors;
export const emailOperations = {
  watchFetch: operations.watchFetch,
  ...moduleOperations
};
export const emailActions = {
  ...actions,
  ...moduleActions
};

export default reducer;
