import React from 'react';
import { connect } from 'react-redux';
import { bindPromiseCreators } from 'redux-saga-routines';

import MainContent, { Title } from 'components/MainContent/MainContent';
import AccountNav from 'components/Account/AccountNav/AccountNav';
import AccountDetailsForm from 'components/Account/AccountDetailsForm/AccountDetailsForm';
import { accountActions } from 'modules/account/account';

// Grommet
import { Box } from 'grommet';

const AccountDetails = ({ user, submitUpdateUser }) => {
  const handleSubmit = (values, { setFormError }) => {
    return submitUpdateUser(values)
      .then(r => null)
      .catch(e => console.log(e));
  };

  return (
    <MainContent>
      <Title>Your Account</Title>
      <div>
        <Box margin={{ vertical: 'medium' }}>
          <AccountNav />
        </Box>
      </div>
      <div>
        <Box margin={{ bottom: 'large' }}>
          <AccountDetailsForm user={user} onSubmit={handleSubmit} />
        </Box>
      </div>
    </MainContent>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.account.user.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        submitUpdateUser: accountActions.submitUpdateUser,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetails);
