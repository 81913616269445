import { combineReducers } from 'redux';
import * as actions from './actions';
import paginate from '../shared/paginate';
//- Reducers

const loading = (state = false, action) => {
  switch (action.type) {
    case actions.fetchWebhooks.TRIGGER:
      return true;
    case actions.fetchWebhooks.SUCCESS:
    case actions.fetchWebhooks.FAILURE:
      return false;
    default:
      return state;
  }
};

const byPage = paginate({
  mapActionToKey: action => action.page,
  types: [
    actions.fetchWebhooks.REQUEST,
    actions.fetchWebhooks.SUCCESS,
    actions.fetchWebhooks.FAILURE,
  ],
});

const types = (
  state = {
    data: [],
  },
  action
) => {
  switch (action.type) {
    case actions.fetchWebhookTypes.SUCCESS: {
      return {
        ...state,
        data: action.payload.response,
      };
    }
    default:
      return state;
  }
};

const webhooks = combineReducers({
  byPage,
  loading,
  types,
});

export default webhooks;
