import React from 'react';
import chroma from 'chroma-js';
import classNames from 'classnames';

import Select from 'components/Select/Select';

export const colourOptions = [
  { label: 'Default', value: '#399ba2' },
  { label: 'Blue', value: '#22c7e0' },
  { label: 'Navy', value: '#143485' },
  { label: 'Pink', value: '#c93eb5' },
  { label: 'Red', value: '#c91e2c' },
  { label: 'Orange', value: '#FF8B00' },
  { label: 'Yellow', value: '#e3b40b' },
  { label: 'Green', value: '#36B37E' },
  { label: 'Grey', value: '#999999' },
  { label: 'Black', value: '#111111' }
];

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 1,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10
  }
});

const colourStyles = {
  container: styles => ({
    ...styles,
    fontFamily: 'lato, sans serif',
    fontSize: '0.85rem',
    marginTop: '0',
    marginBottom: '0'
  }),
  valueContainer: styles => ({
    ...styles,
    padding: '0 0.5rem'
  }),
  control: styles => ({
    ...styles,
    backgroundColor: 'white',
    borderWidth: '0.09rem',
    borderColor: '#ddd',
    borderRadius: '0.2rem'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.value);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.value
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.value,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.value : color.alpha(0.3).css())
      }
    };
  },
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.value) })
};

const ColourSelect = ({
  input,
  width,
  label,
  className,
  id,
  placeholder,
  creating,
  loading,
  labelAfter,
  options,
  onSelect,
  onRemove,
  onClear,
  isClearable = true,
  meta: { submitting, touched, error, warning },
  isDisabled
}) => {
  const rowClass = classNames('form__row', className);

  const handleChange = (value, { action }) => {
    if (action === 'select-option' && value) {
      onSelect && onSelect(value);
    } else if (action === 'clear') {
      onClear && onClear();
    }
    input.onChange(value);
  };

  const value = colourOptions.find(
    option => option.value === input.value.value
  );

  return (
    <div className={rowClass}>
      <label className="form__label" htmlFor={id}>
        {label}
      </label>
      <Select
        {...input}
        value={value}
        onChange={handleChange}
        onBlur={() => null}
        isDisabled={submitting || creating || isDisabled}
        styles={colourStyles}
        isClearable={isClearable}
        placeholder={placeholder}
        options={colourOptions}
      />
      {touched &&
        ((error && <span className="form__error">{error}</span>) ||
          (warning && <span className="form__error">{warning}</span>))}
      {labelAfter && (
        <label className="form__label" htmlFor={id}>
          {labelAfter}
        </label>
      )}
    </div>
  );
};

export default ColourSelect;
