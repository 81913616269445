import * as types from './types';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const fetchMailchimpIntegration = createRoutine(
  types.FETCH_MAILCHIMP_INTEGRATION
);
export const completeMailchimpConnect = createRoutine(
  types.COMPLETE_MAILCHIMP_CONNECT
);
export const fetchMailchimpLists = createRoutine(types.FETCH_MAILCHIMP_LISTS);
export const updateMailchimpIntegration = createRoutine(
  types.UPDATE_MAILCHIMP_INTEGRATION
);
export const submitUpdateMailchimpIntegration = promisifyRoutine(
  updateMailchimpIntegration
);

export const syncEventAttendeesToMailchimp = createRoutine(
  types.SYNC_EVENT_ATTENDEES_TO_MAILCHIMP
);
export const submitSyncEventAttendeesToMailchimp = promisifyRoutine(
  syncEventAttendeesToMailchimp
);

export const disconnectMailchimpIntegration = createRoutine(
  types.DISCONNECT_MAILCHIMP_INTEGRATION
);
