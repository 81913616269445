import React from 'react';
import { connect } from 'react-redux';
import { SubTitle } from '../../../components/MainContent/MainContent';

import MailchimpSection from './MailchimpSection';

// Grommet
import { Box } from 'grommet';

class Tools extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <SubTitle title="Tools" />
        <Box margin={{ vertical: 'medium' }}>
          <MailchimpSection eventId={this.props.eventId} />
        </Box>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const eventId = parseInt(ownProps.match.params.eventId, 10);
  return {
    eventId
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Tools);
