import * as types from './types';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const fetchWebhooks = createRoutine(types.FETCH_WEBHOOKS);
export const fetchWebhookTypes = createRoutine(types.FETCH_WEBHOOK_TYPES);
export const createWebhook = createRoutine(types.CREATE_WEBHOOK);
export const submitCreateWebhook = promisifyRoutine(createWebhook);
export const updateWebhook = createRoutine(types.UPDATE_WEBHOOK);
export const submitEditWebhook = promisifyRoutine(updateWebhook);
export const deleteWebhook = createRoutine(types.DELETE_WEBHOOK);
export const submitDeleteWebhook = promisifyRoutine(deleteWebhook);
export const fetchWebhook = createRoutine(types.FETCH_WEBHOOK);
