import React from "react";

// Grommet
import { Box, ResponsiveContext } from "grommet";

const HeaderActions = ({ children }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => <Box fill={size !== "small" ? false : true}>{children}</Box>}
    </ResponsiveContext.Consumer>
  );
};

export default HeaderActions;
