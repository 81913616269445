import React from 'react';
import { default as RSelect } from 'react-select';

const Select = props => {
  const styles = {
    container: styles => ({
      ...styles,
      fontFamily: 'lato, sans serif',
      fontSize: '0.85rem',
      marginTop: '0',
      marginBottom: '0',
      minWidth: '15rem',
      fontWeight: '900'
    }),
    control: styles => ({
      ...styles,
      backgroundColor: 'white',
      borderWidth: '2px',
      borderColor: '#ddd',
      borderRadius: '0.2rem'
    }),
    valueContainer: styles => ({
      ...styles,
      padding: '0 0.5rem',
      zIndex: '2'
    }),
    menu: styles => ({
      ...styles,
      zIndex: '5'
    })
  };
  return <RSelect styles={styles} {...props} />;
};

export default Select;
