import React from 'react';
import { reduxForm, Field } from 'redux-form';

import Form from '../../components/Form/Form';
import FormField from '../../components/Form/FormField/FormField';

const validate = values => {
  let errors = {};

  if (!values.email) {
    errors.email = 'Please enter an email address';
  }

  return errors;
};

let EmailPreviewForm = ({ handleSubmit }) => {
  return (
    <Form
      formTitle="Preview this email"
      handleSubmit={handleSubmit}
      cancelBtn={false}
      submitText="Send email"
    >
      <Field
        label="The email address you want to send a preview email to"
        name="email"
        component={FormField}
        type="text"
        placeholder="hello@myvenue.co.uk"
      />
    </Form>
  );
};

const stateFormName = 'emailPreviewForm';

EmailPreviewForm = reduxForm({
  form: stateFormName,
  validate
})(EmailPreviewForm);

export default EmailPreviewForm;
