import { takeEvery } from 'redux-saga/effects';
import * as api from '../../services/api';
import * as actions from './actions';
import { submitForm } from '../shared/operations';

const createVenue = submitForm.bind(null, actions.createVenue, api.createVenue);

export function* watchCreateVenue() {
  yield takeEvery(actions.createVenue.TRIGGER, createVenue);
}
