import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import TextButton from '../../Button/TextButton';
import Form, {
  FormField,
  FormImageField,
  FormSection,
  FormFieldRichText,
  FormFieldSwitch,
} from '../../Form/Form';
import TagSelect from './TagSelect';
import CategorySelect from './CategorySelect';
import VenueSelect from './VenueSelect';
import './EventForm.css';
import { uploadImage, deleteImage } from '../../../services/api';
import { required, maxLength } from '../../../utils/validators';
let EventForm = props => {
  const {
    handleSubmit,
    submitting,
    venues,
    error,
    reset,
    onCreateVenueClick,
  } = props;
  return (
    <Form
      handleSubmit={handleSubmit}
      onCancel={reset}
      submitting={submitting}
      error={error}
      sectional
    >
      <div className='event-form-container'>
        <div className='event-form-info'>
          <FormSection title='Main Info'>
            <Field
              name='title'
              label='Event Title'
              component={FormField}
              type='text'
              placeholder='Type your event title here'
              validate={[required, maxLength(255)]}
            />
            <Field
              name='description'
              label='Description'
              component={FormFieldRichText}
              type='text'
              placeholder={'Type your description here'}
              validate={[required]}
            />
            <Field
              name='shortDescription'
              label='Short Description'
              component={FormField}
              type='text'
              placeholder='A great summary of the event in 140 characters'
              hintBefore='(Optional - A short description is used on the Line-Up event page)'
              validate={[maxLength(255)]}
            />
          </FormSection>
          <FormSection title='Location'>
            <Field
              name='venue'
              label='Venue'
              component={VenueSelect}
              defaultOptions={venues}
              type='text'
              placeholder='Type the venue name and pick from the dropdown menu'
              validate={[required]}
              hintAfter={
                <span className='event-form__new-venue'>
                  Can't find the venue?
                  <TextButton
                    onClick={onCreateVenueClick}
                    className='event-form__text-button'
                  >
                    Create New Venue
                  </TextButton>
                </span>
              }
            />
          </FormSection>
          <FormSection title='Artwork & Media'>
            <Field
              name='image'
              label='Images'
              hintBefore='(Images should be a .jpg or a .png file and should be no
                larger than 1MB)'
              component={FormImageField}
              placeholder='Drop image here or click to upload'
              uploadImage={uploadImage}
              deleteImage={deleteImage}
              validate={[required]}
            />
          </FormSection>
          <FormSection title='Optional'>
            <Field
              name='bookingInformation'
              label='Booking Information'
              component={FormFieldRichText}
              type='text'
              placeholder={'Type additional booking information here'}
            />
            <Field
              name='private'
              label='Private Event'
              hintAfter='(Private events are hidden from search engines and your public events calendar)'
              component={FormFieldSwitch}
              switchfield
              placeholder={'Type additional booking information here'}
            />
          </FormSection>
        </div>
        <div className='event-form-extras'>
          <div className='event-form-extras-title'>Extras</div>
          <Field
            name='categories'
            label='Categories'
            component={CategorySelect}
            hintBefore='Add some categories to help identify your event.'
            placeholder='eg. Theatre, Comedy...'
          />
          <Field
            name='tags'
            label='Tags'
            component={TagSelect}
            hintBefore='Add some tags to help segment your ticket buyers.'
            placeholder='eg. Music, Jazz...'
          />
        </div>
      </div>
    </Form>
  );
};

EventForm = reduxForm({
  form: 'event',
})(EventForm);

const mapStateToProps = (state, ownProps) => {
  const event = ownProps.event;
  let venues = [];
  let initialValues;
  if (event) {
    if (event.description) {
      initialValues = {
        ...event,
        venue: {
          label: event.venue.name,
          value: event.venue.id,
        },
        tags: event.tags
          ? event.tags.map(tag => ({
              label: tag.name,
              value: tag.id,
            }))
          : [],
        categories: event.categories
          ? event.categories.map(category => ({
              label: category.name,
              value: category.id,
            }))
          : [],
      };
      venues = [
        {
          label: event.venue.name,
          value: event.venue.id,
        },
      ];
    }
  }
  return {
    initialValues,
    venues,
  };
};

export default connect(mapStateToProps)(EventForm);
