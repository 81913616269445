import React from 'react';
import * as api from 'services/api';

export const useOrderFormResponses = () => {
  const [responses, setResponses] = React.useState({
    fetching: false,
    fetched: false,
    data: [],
    error: null
  });

  const fetchOrderFormResponses = async data => {
    setResponses({
      ...responses,
      fetching: true
    });
    const request = await api.fetchOrderFormResponses(data);
    if (request.error) {
      setResponses({
        ...responses,
        error: request.error,
        fetching: false
      });
      return;
    }

    setResponses({
      ...responses,
      error: null,
      data: request.response,
      fetched: true,
      fetching: false
    });
  };

  return {
    ...responses,
    fetchOrderFormResponses
  };
};
