import React from 'react';

// Grommet
import { Box, ResponsiveContext } from 'grommet';

const MultiActionContainer = ({ children }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          direction={size !== 'small' ? 'row' : 'column'}
          justify={size !== 'small' ? 'start' : 'start'}
          align={size !== 'small' ? 'center' : 'start'}
          gap={size !== 'small' ? 'small' : 'medium'}
          fill={size !== 'small' ? false : true}
        >
          {children}
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default MultiActionContainer;
