import React from 'react';
import { AIPagination as Pagination } from '../../components/AISearch/AISearch';
import { FormattedNumber, FormattedDate } from 'react-intl';
/* eslint-disable react/style-prop-object */
import Table, {
  TableCell,
  TableRow,
  TableBody,
  TableHeader,
} from '../../components/Table/Table';
import { SearchBox, Highlight, Configure } from 'react-instantsearch-dom';
import EmptyState from '../../components/EmptyState/EmptyState';
import emptyOrders from './empty_orders.png';
import SearchWithEmptyState from '../../components/SearchWithEmptyState';

// Grommet
import { Box, Heading } from 'grommet';

const Order = ({ hit, onGenerateOrderLink }) => {
  return (
    <TableRow striped={true} toLink={onGenerateOrderLink(hit.objectID)}>
      <TableCell>
        <span className='table__cell-item'>
          <Highlight attribute='customer.firstName' hit={hit} />{' '}
          <Highlight attribute='customer.lastName' hit={hit} />
        </span>
        <span className='table__cell-item'> ({hit.customer.emailAddress})</span>
      </TableCell>

      <TableCell>
        <span>{hit.invoice ? hit.invoice.reference : ''}</span>
      </TableCell>

      <TableCell>
        <span className='table__cell-item'>
          <FormattedNumber
            value={hit.total}
            style='currency'
            currency={hit.currency}
          />
        </span>
      </TableCell>

      <TableCell>
        <Highlight attribute='event.title' hit={hit} />
      </TableCell>

      <TableCell>
        <FormattedDate
          weekday='short'
          year='numeric'
          day='numeric'
          month='short'
          value={hit.createdAt}
        />
      </TableCell>
    </TableRow>
  );
};

const OrderTable = ({ hits, onGenerateOrderLink }) => {
  return hits.length > 0 ? (
    <TableBody
      renderItem={item => (
        <Order
          hit={item}
          key={item.objectID}
          onGenerateOrderLink={onGenerateOrderLink}
        />
      )}
      items={hits}
    />
  ) : (
    <TableRow striped={true}>
      <td colspan='5'>
        <Box
          pad='medium'
          background='light-2'
          width='100%'
          align='center'
          alignContent='center'
        >
          <Heading level='4'>No orders found</Heading>
        </Box>
      </td>
    </TableRow>
  );
};

export const OrderList = ({ onGenerateOrderLink }) => {
  return (
    <SearchWithEmptyState
      Component={({ hits }) => (
        <div>
          <Box margin={{ vertical: 'medium' }}>
            <SearchBox />
          </Box>
          <Box margin={{ vertical: 'small' }}>
            <Configure hitsPerPage={25} />
            <Table containerBorder mobileWide>
              <TableHeader>
                <TableCell width={35}>Customer</TableCell>
                <TableCell width={20}>Reference</TableCell>
                <TableCell width={7}>Total</TableCell>
                <TableCell width={23}>Event Title</TableCell>
                <TableCell width={15}>Date Placed</TableCell>
              </TableHeader>
              <OrderTable
                onGenerateOrderLink={onGenerateOrderLink}
                hits={hits}
              />
            </Table>
            <Pagination />
          </Box>
        </div>
      )}
      EmptyComponent={() => (
        <EmptyState
          background
          showButton={false}
          emptyStateHeadline='You have no Orders yet'
          emptyStateDescription="When you've had your first orders, they will appear here."
          emptyStateImageSrc={emptyOrders}
          emptyStateImageAlt='No Orders yet'
          emptyStateImageTitle='No Orders yet'
        />
      )}
    />
  );
};
