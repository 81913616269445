import React from 'react';
import './FormSectionHeader.css';

const FormSectionHeader = ({ title }) => (
  <div className="form__section-header">
    <div className="form__section-header-title">{title}</div>
  </div>
);

export default FormSectionHeader;
