import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '../../components/Button/Button';
import {
  ticketGroupActions,
  ticketGroupSelectors
} from '../../modules/ticketGroups/ticketGroups';
import Pagination from '../../components/Pagination/Pagination';
import MainContent, {
  Title,
  HeaderActions
} from '../../components/MainContent/MainContent';
import TicketGroupTable from '../../components/TicketGroup/TicketGroupTable';

class TicketGroupList extends Component {
  componentWillMount() {
    this.props.loadTicketGroupsPage(this.props.currentPage);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.currentPage !== nextProps.currentPage) {
      this.props.loadTicketGroupsPage(nextProps.currentPage);
    }
  }

  handleTicketGroupClick = ticketGroup => {
    this.props.history.push(`/ticket-group/${ticketGroup.id}`);
  };

  handleSelectPage = page => {
    this.props.history.push(`/ticket-groups/${page}`);
  };

  render() {
    const { ticketGroups, currentPage, totalPages } = this.props;
    return (
      <MainContent>
        <Title>Ticket Groups</Title>
        <HeaderActions>
          <Button primary to="/discount/create">
            Create Ticket Group
          </Button>
        </HeaderActions>
        <TicketGroupTable
          ticketGroups={ticketGroups}
          onTicketGroupClick={this.handleTicketGroupClick}
        />
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onSelectPage={this.handleSelectPage}
        />
      </MainContent>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const page = parseInt(match.params.page, 10) || 1;
  return {
    ticketGroups: ticketGroupSelectors.getTicketGroupsForPage(state, page),
    currentPage: page,
    totalPages: ticketGroupSelectors.getTicketGroupsTotalPages(state)
  };
};

export default connect(
  mapStateToProps,
  {
    ...ticketGroupActions
  }
)(TicketGroupList);
