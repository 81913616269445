export const removeEmpty = obj =>
  Object.keys(obj)
    .filter(k => obj[k] !== null && obj[k] !== undefined && obj[k] !== '') // Remove undef. and null.
    .reduce((newObj, k) => {
      if (typeof obj[k] !== 'object') {
        Object.assign(newObj, { [k]: obj[k] });
      } else if (Object.keys(removeEmpty(obj[k])).length !== 0) {
        Object.assign(newObj, { [k]: removeEmpty(obj[k]) });
      }
      return newObj;
    }, {});
