import * as fromListIds from '../shared/listIds';
import * as fromEntities from '../shared/entities';
import Fuse from 'fuse.js';

//- Selectors
export const getTicketReceipt = (state, ticketReceiptId) =>
  fromEntities.getEntity(state.entities.ticketReceipt, ticketReceiptId);

export const getTicketReceipts = state => {
  const ids = fromListIds.getIds(state.ticketReceipts.byId);
  return ids.map(id =>
    fromEntities.getEntity(state.entities.ticketReceipt, id)
  );
};

const fuseOptions = {
  shouldSort: true,
  threshold: 0.2,
  tokenize: true,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    'firstName',
    'lastName',
    'emailAddress',
    'reference',
    'ticketDescription'
  ]
};

const getFilterReceipts = state => {
  const ids = fromListIds.getIds(state.ticketReceipts.byId);
  let list = ids.map(id =>
    fromEntities.getEntity(state.entities.ticketReceipt, id)
  );
  list.sort((a, b) => (a.lastName < b.lastName ? -1 : 1));
  const { searchTerm } = state.ticketReceipts;
  if (searchTerm) {
    const fuse = new Fuse(list, fuseOptions);
    list = fuse.search(state.ticketReceipts.searchTerm);
  }
  return list;
};

export const getTicketReceiptsForPage = (state, page, perPage = 25) => {
  return getFilterReceipts(state).slice((page - 1) * perPage, page * perPage);
};

export const getTicketReceiptsTotalPages = (state, perPage = 25) =>
  Math.ceil(getFilterReceipts(state).length / perPage);

export const getTicketReceiptsSummary = state => {
  return getTicketReceipts(state).reduce((summary, item) => {
    let totals = summary[item.ticketDescription] || {};
    summary = {
      ...summary,
      [item.ticketDescription]: {
        ...totals,
        totalSold: (totals['totalSold'] || 0) + 1,
        totalCheckedIn:
          (totals['totalCheckedIn'] || 0) + (item.isRedeemed ? 1 : 0)
      }
    };
    return summary;
  }, {});
};

export const getTicketReceiptsLoadingStatus = state =>
  state.ticketReceipts.loading;

export const getTicketReceiptsError = state => state.ticketReceipts.error;
