const UserRoles = [
  {
    label: 'Inventory Manager',
    description:
      'Inventory management for the event, has access to the inventory reports and can edit inventory numbers',
    value: '97',
  },
  {
    label: 'Financial Reporting',
    description: 'Access to financial reports for the event',
    value: '161',
  },
  {
    label: 'Agent',
    description: 'Access to sell tickets via the Line-Up API',
    value: '9',
  },
  {
    label: 'Front of House',
    description: 'Access to the guest list and to issue tickets',
    value: '41',
  },
  {
    label: 'Admin',
    description:
      'Admin privileges across the event, can manage users, edit the event and has access to all reports',
    value: '235',
  },
];

export default UserRoles;
