import React from 'react';
import styles from './TicketAllocationPlacement.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TicketAllocationPlacement = ({ placement, onClick }) => (
  <div className={styles.root}>
    <div className={styles.content} onClick={onClick}>
      <span className={styles.laneTicketAllocationPlacementName}>
        {placement.ticketAllocation.description}
      </span>
      <div className={styles.laneTicketAllocationNotesIcon}>
        <FontAwesomeIcon
          className={styles.laneTicketAllocationPlacementNameIcon}
          icon="registered"
        />
      </div>
    </div>
  </div>
);

export default TicketAllocationPlacement;
