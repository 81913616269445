import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Link } from 'react-router-dom';
import { FormattedDate } from 'react-intl';
import {
  SubTitle,
  ContentType
} from '../../../components/MainContent/MainContent';
import { getPerformanceStartDate } from '../../../utils/dates';
import Ticket from '../ticket/Ticket';
import PerformanceDetail from './PerformanceDetail';
import TicketReceiptList from './ticketReceipt/TicketReceiptList';
import WaitingList from './waitingList/WaitingList';
import TicketCreate from '../ticket/TicketCreate';
import ManageSeatingChart from './ManageSeatingChart';
import {
  performanceActions,
  performanceSelectors
} from '../../../modules/performances/performances';

// Grommet
import { Box } from 'grommet';
import { Clock } from 'grommet-icons';

class Performance extends Component {
  componentWillMount() {
    this.props.fetchPerformance({ performanceId: this.props.performanceId });
  }
  componentDidUpdate(prevProps) {
    if (this.props.performanceId !== prevProps.performanceId) {
      this.props.fetchPerformance({ performanceId: this.props.performanceId });
    }
  }

  render() {
    const { eventId, performance, match, performanceId } = this.props;

    if (!performance || !performance.id) {
      return null;
    } else {
      const performanceStartDate = getPerformanceStartDate(performance);

      return (
        <div>
          <Box margin={{ bottom: 'small' }}>
            <ContentType icon={<Clock size='small' />} label='Performance' />
          </Box>
          <Box margin={{ bottom: 'small' }}>
            <Link
              to={`/event/${eventId}/performance/${performanceId}`}
              style={{ textDecoration: 'none' }}
            >
              <SubTitle
                title={
                  <FormattedDate
                    value={performanceStartDate}
                    weekday='short'
                    year='numeric'
                    
                    day='numeric'
                    month='short'
                    hour='numeric'
                    minute='numeric'
                  />
                }
                linkColor={true}
              />
            </Link>
          </Box>
          <Switch>
            <Route
              exact
              path={`${match.path}`}
              render={props => (
                <PerformanceDetail
                  {...props}
                  eventId={eventId}
                  performance={performance}
                />
              )}
            />
            <Route
              exact
              path={[
                `${match.path}/receipts`,
                `${match.path}/receipts/:page(\\d+)`
              ]}
              component={TicketReceiptList}
            />
            <Route
              exact
              path={[
                `${match.path}/waiting-list`,
                `${match.path}/waiting-list/:page(\\d+)`
              ]}
              component={WaitingList}
            />
            <Route
              exact
              path={`${match.path}/ticket/create`}
              component={TicketCreate}
            />
            <Route
              path={`${match.path}/ticket/:ticketId(\\d+)`}
              component={Ticket}
            />
            <Route
              exact
              path={`${match.path}/seating-chart`}
              component={ManageSeatingChart}
            />
          </Switch>
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const eventId = parseInt(ownProps.match.params.eventId, 10);
  const performanceId = parseInt(ownProps.match.params.performanceId, 10);
  const performance = performanceSelectors.getPerformance(state, performanceId);
  // const availability = ticketSelectors.getAvailabilityForPerformanceId(
  //   state,
  //   performanceId
  // );
  return {
    eventId,
    performanceId,
    performance
  };
};

export default connect(mapStateToProps, {
  ...performanceActions
})(Performance);
