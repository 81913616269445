import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { orderSelectors } from '../../../modules/orders/orders';
import Modal from '../../../components/Modal/Modal';

import { hideModal } from '../../../modules/modal/modal';
import RefundForm from '../../../components/RefundForm/RefundForm';

class RefundModal extends Component {
  filterReceipts = (lineItems, filter, faceValueAmount, outsideFeeAmount) =>
    lineItems.reduce(
      (items, item) => [
        ...items,
        ...item.receipts
          .filter(r => (this.props.selectAll ? true : filter.includes(r.id)))
          .map(r => {
            return {
              receiptId: r.id,
              faceValueTotal:
                !faceValueAmount && faceValueAmount !== 0
                  ? r.total - r.outsideFeeTotal - r.faceValueTotalRefund
                  : Math.min(
                      r.total - r.outsideFeeTotal - r.faceValueTotalRefund,
                      faceValueAmount
                    ),
              outsideFeeTotal:
                !outsideFeeAmount && outsideFeeAmount !== 0
                  ? r.outsideFeeTotal - r.outsideFeeTotalRefund
                  : Math.min(
                      r.outsideFeeTotal - r.outsideFeeTotalRefund,
                      outsideFeeAmount
                    ),
              cancelReceipt: this.props.cancelReceipts
            };
          })
      ],
      []
    );

  filterDonations = (donations, filter, faceValueAmount) =>
    donations
      .filter(r => (this.props.selectAll ? true : filter.includes(r.id)))
      .map(r => {
        return {
          receiptId: r.id,
          faceValueTotal:
            !faceValueAmount && faceValueAmount !== 0
              ? r.total - r.totalRefund
              : Math.min(r.total - r.totalRefund, faceValueAmount),
          outsideFeeTotal: 0
        };
      });

  getRefundData = () => {
    const {
      faceValueRefundAmount,
      outsideFeeRefundAmount,
      order,
      selectedReceipts
    } = this.props;
    const { ticketPurchases, productPurchases, donation } = order;
    return {
      ...(ticketPurchases && {
        tickets: this.filterReceipts(
          ticketPurchases,
          selectedReceipts.tickets,
          faceValueRefundAmount,
          outsideFeeRefundAmount
        )
      }),
      ...(productPurchases && {
        products: this.filterReceipts(
          productPurchases,
          selectedReceipts.products,
          faceValueRefundAmount,
          outsideFeeRefundAmount
        )
      }),
      ...(donation && {
        donations: this.filterDonations(
          [donation],
          selectedReceipts.donations,
          faceValueRefundAmount
        )
      })
    };
  };

  sumFaceValueRefund = refundItems =>
    refundItems.reduce((prev, cur) => prev + cur.faceValueTotal, 0);
  sumOutsideFeeRefund = refundItems =>
    refundItems.reduce((prev, cur) => prev + cur.outsideFeeTotal, 0);

  getRefundTotals = () => {
    const refundData = this.getRefundData();
    return {
      totalFaceValueRefund: Object.keys(refundData).reduce(
        (total, key) => total + this.sumFaceValueRefund(refundData[key]),
        0
      ),
      totalOutsideFeeRefund: Object.keys(refundData).reduce(
        (total, key) => total + this.sumOutsideFeeRefund(refundData[key]),
        0
      )
    };
  };
  handleOnSubmit = values => {
    this.props.onRefundClick &&
      this.props.onRefundClick(values, this.getRefundData());
    this.handleClose();
  };
  handleClose = () => {
    this.props.onClose && this.props.onClose();
    this.props.hideModal();
  };

  render() {
    const {
      orderId,
      order,
      currency,
      faceValueRefundType,
      outsideFeeRefundType,
      hasOutsideFee
    } = this.props;
    const {
      totalFaceValueRefund,
      totalOutsideFeeRefund
    } = this.getRefundTotals();
    return (
      <Modal onRequestClose={this.handleClose}>
        <RefundForm
          onSubmit={this.handleOnSubmit}
          onCancel={this.handleClose}
          currency={currency}
          orderId={orderId}
          initialValues={{
            cancelReceipts: true,
            refundOutsideFee: true,
            faceValueRefundType: 'full',
            outsideFeeRefundType: 'full'
          }}
          hasOutsideFee={hasOutsideFee}
          hasValue={order.total > 0}
          totalFaceValueRefund={totalFaceValueRefund}
          totalOutsideFeeRefund={totalOutsideFeeRefund}
          faceValueRefundType={faceValueRefundType}
          outsideFeeRefundType={outsideFeeRefundType}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { orderId, selectAll } = ownProps;
  const selector = formValueSelector('refund');
  const faceValueRefundType = selector(state, 'faceValueRefundType');
  const faceValueRefundAmount =
    faceValueRefundType === 'partial'
      ? selector(state, 'faceValueRefundAmount')
      : faceValueRefundType === 'none'
      ? 0
      : null;
  const outsideFeeRefundType = selector(state, 'outsideFeeRefundType');
  const outsideFeeRefundAmount =
    outsideFeeRefundType === 'partial'
      ? selector(state, 'outsideFeeRefundAmount')
      : outsideFeeRefundType === 'none'
      ? 0
      : null;
  const hasOutsideFee = orderSelectors.getOrderHasOutsideFee(state, orderId);
  return {
    selectedReceipts: orderSelectors.getSelectedReceipts(state),
    order: orderSelectors.getOrder(state, orderId),
    selectAll,
    faceValueRefundAmount,
    faceValueRefundType,
    outsideFeeRefundAmount,
    outsideFeeRefundType,
    hasOutsideFee,
    cancelReceipts: selector(state, 'cancelReceipts')
  };
};

export default connect(
  mapStateToProps,
  { hideModal }
)(RefundModal);
