import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Modal from 'components/Modal/Modal';
import LaneForm from 'components/Planner/LaneForm';

import { hideModal } from 'modules/modal/modal';

const LaneEditModal = ({ hideModal, initialValues, onSubmit }) => {
  return (
    <Modal onRequestClose={() => hideModal()}>
      <LaneForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        onCancel={hideModal}
      />
    </Modal>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        hideModal
      },
      dispatch
    )
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: {
      name: ownProps.lane.name,
      priority: ownProps.lane.priority,
      capacity: ownProps.lane.capacity
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LaneEditModal);
