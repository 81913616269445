import { createAction } from 'redux-actions';
import * as types from './types';

//- Actions
export const updateEmbedSettings = createAction(
  types.EMBED_SETTINGS_UPDATE,
  (eventId, embedSettingsData) => ({ eventId, embedSettingsData })
);

export const embedSettingsUpdate = {
  request: createAction(types.UPDATE.REQUEST),
  success: createAction(types.UPDATE.SUCCESS, response => ({
    response
  })),
  failure: createAction(types.UPDATE.FAILURE, error => ({
    error
  }))
};

export const loadEmbedSettings = createAction(
  types.LOAD_EMBED_SETTINGS,
  eventId => ({
    eventId
  })
);

export const embedSettings = {
  request: createAction(types.EMBED_SETTINGS.REQUEST, eventId => ({ eventId })),
  success: createAction(types.EMBED_SETTINGS.SUCCESS, (eventId, response) => ({
    eventId,
    response
  })),
  failure: createAction(types.EMBED_SETTINGS.FAILURE, (eventId, error) => ({
    eventId,
    error
  }))
};
