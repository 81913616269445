import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Grommet
import { Box, Text } from 'grommet';

const StyledText = styled(Text)`
  font-weight: 900;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #399ba2;
  &:hover {
    font-weight: 900;
  }
`;

const StyledIcon = styled.span`
  margin: 0 0.75rem 0 0;
  padding: 0;
`;

const Lozenge = ({ label, icon, href, description, target }) => {
  return (
    <Link
      to={{ pathname: href }}
      style={{ textDecoration: 'none' }}
      target={target}
    >
      <Box
        direction='column'
        align='start'
        pad='medium'
        background='infobox'
        round='xsmall'
        fill
        gap='small'
        responsive={false}
      >
        <Box align='center' direction='row'>
          <StyledIcon>{icon}</StyledIcon>
          <StyledText>{label}</StyledText>
        </Box>

        {description && (
          <Text size='small' color='copy'>
            {description}
          </Text>
        )}
      </Box>
    </Link>
  );
};

export default Lozenge;
