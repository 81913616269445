import { takeEvery, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as api from '../../services/api';
import * as actions from './actions';
import { fetchEntity, submitForm } from '../shared/operations';
import {
  setAuthenticationKeys,
  saveUserDataClientSide,
  getUserDataClientSide,
  clearUserDataClientSide,
} from './utils';

const refreshClientSideUserData = (action) => {
  saveUserDataClientSide(action.payload.response);
};

// Authenticate user
const authenticateUser = submitForm.bind(
  null,
  actions.authenticateUser,
  api.authenticateUser
);

function authenticateUserSuccess(action) {
  saveUserDataClientSide(action.payload.response);
  setAuthenticationKeys(action.payload.response);
}

export function* watchAuthenticateUser() {
  yield takeEvery(actions.authenticateUser.TRIGGER, authenticateUser);
  yield takeEvery(actions.authenticateUser.SUCCESS, authenticateUserSuccess);
  yield takeEvery(actions.authenticateUser.SUCCESS, getSubscription);
  yield takeEvery(actions.authenticateUser.SUCCESS, getPaymentMethod);
}

// Logout user
function* logoutUser() {
  clearUserDataClientSide();
  yield put(actions.logoutUser.success());
  yield put(actions.logoutUser.fulfill());
  yield put(push('/user/login'));
}

export function* watchLogoutUser() {
  yield takeEvery(actions.logoutUser.TRIGGER, logoutUser);
}

// Create user
const createUser = submitForm.bind(null, actions.createUser, api.createUser);

function createUserSuccess(action) {
  saveUserDataClientSide(action.payload.response);
  setAuthenticationKeys(action.payload.response);
}

export function* watchCreateUser() {
  yield takeEvery(actions.createUser.TRIGGER, createUser);
  yield takeEvery(actions.createUser.SUCCESS, createUserSuccess);
  yield takeEvery(actions.createUser.SUCCESS, getSubscription);
  yield takeEvery(actions.createUser.SUCCESS, getPaymentMethod);
}

// Recover auth - load auth data from localstorage after user hard refreshes page, or
// when they come back to the site
function* recoverAuth(action) {
  const userData = getUserDataClientSide();
  if (userData !== false) {
    setAuthenticationKeys(userData);
    yield put(
      actions.recoverAuth.success({
        user: userData,
      })
    );
    yield put(push(action.payload.redirect));
  } else {
    yield put(actions.recoverAuth.failure());
  }
  yield put(actions.recoverAuth.fulfill());
}

export function* watchRecoverAuthentication() {
  yield takeEvery(actions.recoverAuth.TRIGGER, recoverAuth);
  yield takeEvery(actions.recoverAuth.SUCCESS, getSubscription);
  yield takeEvery(actions.recoverAuth.SUCCESS, getPaymentMethod);
}

function* reAuth(action) {
  const { authToken, redirect } = action.payload;
  yield put(actions.reAuth.request({ authToken }));
  const { response, error } = yield call(api.getUseFromToken, { authToken });
  if (response) {
    yield put(actions.reAuth.success({ response }));
    yield put(push(redirect));
  } else {
    yield put(actions.reAuth.failure({ error }));
  }
  yield put(actions.reAuth.fulfill());
}

export function* watchReAuth() {
  yield takeEvery(actions.reAuth.TRIGGER, reAuth);
  yield takeEvery(actions.reAuth.SUCCESS, authenticateUserSuccess);
  yield takeEvery(actions.reAuth.SUCCESS, getSubscription);
  yield takeEvery(actions.reAuth.SUCCESS, getPaymentMethod);
}

// Update user
const updateUser = submitForm.bind(
  null,
  actions.updateUser,
  api.updateCurrentUser
);

export function* watchUpdateUser() {
  yield takeEvery(actions.updateUser.TRIGGER, updateUser);
  yield takeEvery(actions.updateUser.SUCCESS, refreshClientSideUserData);
}

// Update user profile
const updateUserProfile = submitForm.bind(
  null,
  actions.updateUserProfile,
  api.updateUserProfile
);

export function* watchUpdateUserProfile() {
  yield takeEvery(actions.updateUserProfile.TRIGGER, updateUserProfile);
  yield takeEvery(actions.updateUserProfile.SUCCESS, refreshClientSideUserData);
}

// Get user subscription
const getSubscription = fetchEntity.bind(
  null,
  actions.getSubscription,
  api.getSubscription
);

export function* watchGetSubscription() {
  yield takeEvery(actions.getSubscription.TRIGGER, getSubscription);
}

// Get payment method for account
const getPaymentMethod = fetchEntity.bind(
  null,
  actions.getPaymentMethod,
  api.getPaymentMethod
);

export function* watchGetPaymentMethod() {
  yield takeEvery(actions.getPaymentMethod.TRIGGER, getPaymentMethod);
}

// Create payment method
const createPaymentMethod = submitForm.bind(
  null,
  actions.createPaymentMethod,
  api.createPaymentMethod
);

export function* watchCreatePaymentMethod() {
  yield takeEvery(actions.createPaymentMethod.TRIGGER, createPaymentMethod);
}

// Update user subscription
const updateSubscription = submitForm.bind(
  null,
  actions.updateSubscription,
  api.updateSubscription
);

export function* watchUpdateSubscription() {
  yield takeEvery(actions.updateSubscription.TRIGGER, updateSubscription);
}

const getStripeSettings = fetchEntity.bind(
  null,
  actions.getStripeSettings,
  api.getStripeSettings
);

export function* watchGetStripeSettings() {
  yield takeEvery(actions.getStripeSettings.TRIGGER, getStripeSettings);
}

const createStripeSettings = fetchEntity.bind(
  null,
  actions.createStripeSettings,
  api.createStripeSettings
);

export function* watchCreateStripeSettings() {
  yield takeEvery(actions.createStripeSettings.TRIGGER, createStripeSettings);
}

const deleteStripeSettings = fetchEntity.bind(
  null,
  actions.deleteStripeSettings,
  api.deleteStripeSettings
);

export function* watchDeleteStripeSettings() {
  yield takeEvery(actions.deleteStripeSettings.TRIGGER, deleteStripeSettings);
}

const createBusinessSettings = fetchEntity.bind(
  null,
  actions.createBusinessSettings,
  api.createBusinessSettings
);

export function* watchCreateBusinessSettings() {
  yield takeEvery(
    actions.createBusinessSettings.TRIGGER,
    createBusinessSettings
  );
}

const getBusinessSettings = fetchEntity.bind(
  null,
  actions.getBusinessSettings,
  api.getBusinessSettings
);

export function* watchGetBusinessSettings() {
  yield takeEvery(actions.getBusinessSettings.TRIGGER, getBusinessSettings);
}

const forgotPassword = submitForm.bind(
  null,
  actions.forgotPassword,
  api.forgotPassword
);

export function* watchForgotPassword() {
  yield takeEvery(actions.forgotPassword.TRIGGER, forgotPassword);
}

const resetPassword = submitForm.bind(
  null,
  actions.resetPassword,
  api.resetPassword
);

export function* watchResetPassword() {
  yield takeEvery(actions.resetPassword.TRIGGER, resetPassword);
}

const getCurrencies = fetchEntity.bind(
  null,
  actions.getCurrencies,
  api.getCurrencies
);

export function* watchGetCurrencies() {
  yield takeEvery(actions.getCurrencies.TRIGGER, getCurrencies);
}
