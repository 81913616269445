import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
/* eslint-disable react/style-prop-object */
import { FormattedNumber } from 'react-intl';
import MainContent, {
  Title,
  HeaderActions,
  ContentContainer,
  SubTitle,
} from '../../../components/MainContent/MainContent';
import InfoBlock, {
  InfoBlockList,
  InfoBlockListItem,
} from '../../../components/InfoBlock/InfoBlock';
import { bindPromiseCreators } from 'redux-saga-routines';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import Button from '../../../components/Button/Button';
import IssueTicketsTable from '../../../components/IssueTicketsTable/IssueTicketsTable';

import {
  ticketAllocationActions,
  ticketAllocationSelectors,
} from '../../../modules/ticketAllocation/ticketAllocation';

class ReviewTicketIssue extends Component {
  handleIssueClick = e => {
    const {
      allocationId,
      eventId,
      ticketId,
      performanceId,
      purchases,
      agent,
    } = this.props;
    const agentId = agent ? agent.id : null;
    e.preventDefault();
    if (purchases.length > 0) {
      this.props
        .onIssueTicketClick({
          allocationId,
          purchaseData: this.props.purchases,
          agentId,
        })
        .then(response =>
          this.props.history.push(
            `/event/${eventId}/performance/${performanceId}/ticket/${ticketId}/ticket-allocation/${allocationId}`
          )
        );
    }
  };
  render() {
    const { purchases, agent, discount, issueError } = this.props;
    if (purchases.length === 0) {
      return null;
    }
    const agentCommissionRate = agent ? agent.commissionRate : null;
    const useDiscountCommission =
      discount &&
      (discount.insideFeePercentage || discount.insideFeeFixedValue);
    const insideFeePercentage = useDiscountCommission
      ? discount.insideFeePercentage
      : agentCommissionRate
      ? agentCommissionRate.insideFeePercentage
      : null;
    const insideFeeFixedValue = useDiscountCommission
      ? discount.insideFeeFixedValue
      : agentCommissionRate
      ? agentCommissionRate.insideFeeFixedValue
      : null;
    const insideFeeTaxRate = useDiscountCommission
      ? discount.insideFeeTaxRate
      : agentCommissionRate
      ? agentCommissionRate.insideFeeTaxRate
      : null;

    return (
      <MainContent>
        <Title>Review Customers</Title>
        <HeaderActions>
          <Button primary onClick={this.handleIssueClick}>
            Issue Tickets
          </Button>
        </HeaderActions>
        {issueError && <ErrorMessage error={issueError} />}
        <ContentContainer>
          {(insideFeePercentage || insideFeeFixedValue || agent) && (
            <Fragment>
              <SubTitle title={'Allocation Details'} />
              <InfoBlock>
                <InfoBlockList>
                  {insideFeePercentage > 0 && (
                    <InfoBlockListItem
                      attribute='Inside Fee Percentage'
                      value={
                        <FormattedNumber
                          value={insideFeePercentage}
                          style='percent'
                        />
                      }
                    />
                  )}
                  {insideFeeFixedValue > 0 && (
                    <InfoBlockListItem
                      attribute='Inside Fee Fixed Value'
                      value={
                        <FormattedNumber
                          value={insideFeeFixedValue}
                          style='currency'
                          currency='GBP'
                        />
                      }
                    />
                  )}
                  {insideFeeTaxRate > 0 && (
                    <InfoBlockListItem
                      attribute='Inside Fee Tax Rate'
                      value={
                        <FormattedNumber
                          value={insideFeeTaxRate}
                          style='percent'
                        />
                      }
                    />
                  )}
                  <InfoBlockListItem
                    attribute='Agent'
                    value={agent ? agent.description : 'None'}
                  />
                  <InfoBlockListItem
                    attribute='Discount Code'
                    value={discount ? discount.code : 'None'}
                  />
                </InfoBlockList>
              </InfoBlock>
            </Fragment>
          )}
          <SubTitle title={'Customer Details'} />
          <IssueTicketsTable purchases={purchases} />
        </ContentContainer>
      </MainContent>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        onIssueTicketClick: ticketAllocationActions.sumbmitIssueTicketForm,
      },
      dispatch
    ),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  return {
    purchases: ticketAllocationSelectors.getPurchases(state),
    allocationId: parseInt(match.params.allocationId, 10),
    eventId: parseInt(match.params.eventId, 10),
    performanceId: parseInt(match.params.performanceId, 10),
    ticketId: parseInt(match.params.ticketId, 10),
    discount: ticketAllocationSelectors.getDiscount(state),
    agent: ticketAllocationSelectors.getAgent(state),
    issueError: ticketAllocationSelectors.getIssueError(state),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewTicketIssue);
