import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import Modal from '../../../components/Modal/Modal';
import { hideModal } from '../../../modules/modal/modal';
import IssueTicketBulkForm from '../../../components/IssueTicketForm/IssueTicketBulkForm';
import {
  ticketAllocationActions,
  ticketAllocationSelectors,
} from '../../../modules/ticketAllocation/ticketAllocation';
import Papa from 'papaparse';

class IssueTicketBulkModal extends Component {
  validateData = data => {
    let errors = data.reduce((errors, row, index) => {
      if (row.length !== 3) {
        errors.push({
          row: index,
          _error: 'insufficent data',
        });
      } else {
        if (!parseInt(row[2], 10)) {
          errors.push({
            row: index,
            _error: 'unable to pass quantity',
          });
        }
      }
      return errors;
    }, []);
    return errors;
  };
  handleOnSubmit = values => {
    const result = Papa.parse(values.data);
    const errors = this.validateData(result.data);
    if (errors.length > 0) {
      throw new SubmissionError({
        data:
          'Unable to pass data. Data should be in the format first name, last name, ticket quantity',
        _error: errors,
      });
    }
    const { ticket, discount, agent } = this.props;
    const commissionRate = agent ? agent.commissionRate : null;
    const discountCodeAmount = discount
      ? discount.ticketPrice
        ? ticket.faceValueTotal - discount.ticketPrice
        : discount.fixedDiscount +
          discount.percentageDiscount * ticket.faceValueTotal
      : 0;
    const discountAmount = discount
      ? discountCodeAmount
      : ticket.faceValueTotal - values.price;
    const includeFee = discount
      ? !discount.removeBookingFee
      : commissionRate
      ? commissionRate.includeOutsideFee
      : values.includeFee;
    const bookingFee = includeFee && ticket.feesPassed ? ticket.feeTotal : 0;

    const purchasesData = result.data.reduce((purchases, row) => {
      const purchaseData = {
        quantity: row[2],
        customer: {
          firstName: row[0],
          lastName: row[1],
        },
        ...(discount ? { discountId: discount.id } : {}),
        discount: discountAmount,
        faceValue: ticket.faceValueTotal,
        bookingFee: bookingFee,
        total: ticket.faceValueTotal - discountAmount + bookingFee,
        feeExempt: !includeFee,
      };
      purchases.push(purchaseData);
      return purchases;
    }, []);
    this.props.addCustomerData(purchasesData);
  };
  handleDiscountSelect = option => {
    this.props.addDiscount(option.value);
  };
  handleAgentSelect = option => {
    this.props.addAgent(option.value);
  };
  handleDiscountClear = () => {
    this.props.removeDiscount();
  };
  handleAgentClear = () => {
    this.props.removeAgent();
  };
  handleOnSubmitSuccess = () => {
    this.props.hideModal();
    this.props.onSubmitSuccess && this.props.onSubmitSuccess();
  };
  render() {
    const discountOptions = this.props.discounts.map(d => ({
      value: d.id,
      label: d.code,
    }));
    const agentOptions = this.props.agents.map(d => ({
      value: d.id,
      label: d.company,
    }));
    const intialAgent = this.props.agentId
      ? agentOptions.find(a => a.value === this.props.agentId)
      : null;
    return (
      <Modal onRequestClose={() => this.props.hideModal()}>
        <IssueTicketBulkForm
          onSubmit={this.handleOnSubmit}
          onSubmitSuccess={this.handleOnSubmitSuccess}
          onCancel={this.props.hideModal}
          ticket={this.props.ticket}
          discounts={discountOptions}
          agents={agentOptions}
          onAgentSelect={this.handleAgentSelect}
          onDiscountSelect={this.handleDiscountSelect}
          onClearDiscount={this.handleDiscountClear}
          onClearAgent={this.handleAgentClear}
          initialValues={{
            price: this.props.ticket.faceValueTotal,
            includeFee: true,
            agent: intialAgent,
          }}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  discount: ticketAllocationSelectors.getDiscount(state),
  agent: ticketAllocationSelectors.getAgent(state),
});

export default connect(
  mapStateToProps,
  {
    ...ticketAllocationActions,
    hideModal,
  }
)(IssueTicketBulkModal);
