import React from 'react';
import { TableCell, TableRow } from '../../Table/Table';
import Button from '../../Button/Button';

const TicketRowItem = ({ allocation, onClick }) => {
  const handleClick = evt => {
    evt.preventDefault();
    onClick && onClick(allocation.id);
  };
  return (
    <TableRow striped={true} onClick={handleClick}>
      <TableCell>{allocation.description}</TableCell>
      <TableCell center>{allocation.quantity}</TableCell>
      <TableCell center>{allocation.quantityAllocated}</TableCell>
      <TableCell center>
        {allocation.quantity + allocation.quantityAllocated}
      </TableCell>
      <TableCell center>
        <Button onClick={handleClick} secondary>
          Manage
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default TicketRowItem;
