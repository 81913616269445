import React from 'react';
import Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import moment from 'moment';
import { toast } from 'react-toastify';

import PlannerEmptyState from './PlannerEmptyState';
import PlannerMenu from 'components/Planner/PlannerMenu';

import Columns from './Columns';
import { usePlanner } from './Provider';
import { getFirstFutureDate } from 'utils/dates';
import PlannerNoFuturePerformancesState from './PlannerNoFuturePerformancesState';

const PlannerWithContext = ({ showModal, hideModal }) => {
  const {
    plannerData,
    fetchedPlanner,
    performances,
    setSelectedDay,
    currentSelectedDay,
    fetchedPerformances,
    event,
    updatePlacement,
    hasPlanner,
    createTicketAllocation,
    issueTicketForPlacementAllocation,
    placements,
    deleteTicketAllocation,
    updateLocalPlacementData,
  } = usePlanner();

  const handleDayChange = day => {
    setSelectedDay(day);
  };

  const handlePlacementClick = placement => {
    if (placement.isTicketReceipt) {
      showModal('PLANNER_BOOKING_MODAL', {
        event: event,
        placement: placement,
        lanes: plannerData.lanes,
        currentSelectedDay: currentSelectedDay,
        placements: placements,
        onSubmit: values => {
          const newLane = plannerData.lanes.find(
            lane => lane.id === parseInt(values.lane.value)
          );
          return updatePlacement({
            ...placement,
            eventPlannerLaneId: newLane.id,
            eventPlannerLane: newLane,
            colour: values.colour.value,
          }).then(() => {
            hideModal();
          });
        },
        // when a new note is added, mark the placement as having a note
        onNoteSubmitSuccess: () => {
          updateLocalPlacementData({
            ...placement,
            hasNotes: true,
          });
        },
      });
    } else if (placement.isTicketAllocation) {
      showModal('CONFIRM_RESERVATION_MODAL', {
        placement: placement,
        onSubmit: values => {
          // builds the data required to issue a ticket
          const purchaseData = [
            {
              quantity: 1,
              customer: {
                firstName: values.firstName,
                lastName: values.lastName,
              },
              discount:
                placement.ticketAllocation.ticket.faceValueTotal - values.price,
              feeExempt: !values.includeFee,
            },
          ];
          // if colour has been set
          const placementData = placement;
          if (values.colour) {
            placementData.colour = values.colour.value;
          }

          return issueTicketForPlacementAllocation({
            purchaseData,
            placement: placementData,
          }).then(() => {
            hideModal();
          });
        },
        onDelete: () => {
          deleteTicketAllocation({
            allocationId: placement.ticketAllocation.id,
          }).then(() => {
            hideModal();
          });
        },
      });
    } else {
      console.log('Unknown placement type');
    }
  };

  const handleCreateAllocationClick = (lane, performance) => {
    if (performance.capacityLeft === 0) {
      toast.error('There is no capacity left for this performance');
      return false;
    }

    showModal('CREATE_RESERVATION_MODAL', {
      event,
      lane,
      performance,
      onSubmit: values => {
        return createTicketAllocation({
          eventPlannerLaneId: lane.id,
          quantity: 1,
          ...values,
          ticketId: values.ticketId.value,
        }).then(() => {
          hideModal();
        });
      },
    });
  };

  if (!fetchedPlanner) {
    return null;
  }

  if (fetchedPlanner && !hasPlanner) {
    return <PlannerEmptyState event={event} />;
  }

  if (!fetchedPerformances) {
    return null;
  }

  const dates = performances.map(groupedPerformance =>
    moment(groupedPerformance.date)
  );
  const futureDate = getFirstFutureDate(dates);
  if (!futureDate) {
    return <PlannerNoFuturePerformancesState event={event} />;
  }

  return (
    <>
      <PlannerMenu
        currentSelectedDay={currentSelectedDay}
        performances={performances}
        onDayChange={handleDayChange}
        event={event}
      />

      <DndProvider backend={Backend}>
        <Columns
          lanes={plannerData.lanes}
          performances={plannerData.performances}
          placements={plannerData.placements}
          onPlacementClick={handlePlacementClick}
          onCreateAllocationClick={handleCreateAllocationClick}
          showModal={showModal}
          hideModal={hideModal}
        />
      </DndProvider>
    </>
  );
};

export default PlannerWithContext;
