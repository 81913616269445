import React from 'react';
import * as Yup from 'yup';

import FormikField from 'components/Form/FormikField';
import FormikForm from 'components/Form/FormikForm';
import FormField from 'components/Form/FormField/FormField';
import FormikImageUploadField from 'components/Form/FormikImageUploadField/FormikImageUploadField';

const validationSchema = Yup.object({
  photo: Yup.object().nullable(),
  displayname: Yup.string().required('Your display name is required')
  // bio: Yup.string().required('A bio is required'),
  // website: Yup.string()
});

const AccountProfileForm = ({ user, onSubmit, uploader }) => {
  return (
    <FormikForm
      validationSchema={validationSchema}
      initialValues={{
        displayname: user.displayname,
        bio: user.bio || '',
        photo: user.photo,
        url: user.url || ''
      }}
      onSubmit={onSubmit}
      cancelBtn={false}
      submitText='Save changes'
      formTitle='Update your profile'
    >
      <FormikField
        name='photo'
        label='Image'
        component={FormikImageUploadField}
        placeholder='Drop image here or click to upload'
        uploader={uploader}
      />
      <FormikField
        name='displayname'
        label='Display name'
        component={FormField}
        type='text'
        placeholder='Your display name'
      />
      {/* <FormikField
        name='bio'
        label='Bio'
        component={FormField}
        type='textarea'
        placeholder='Your bio'
      />
      <FormikField
        name='url'
        label='Website'
        component={FormField}
        type='text'
        placeholder='Your website address'
      /> */}
    </FormikForm>
  );
};

export default AccountProfileForm;
