import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindPromiseCreators } from 'redux-saga-routines';

import MainContent, { Title } from 'components/MainContent/MainContent';
import AccountNav from 'components/Account/AccountNav/AccountNav';
import ConfirmSubscriptionSection from 'components/Subscription/ConfirmSubscriptionSection';

import { accountSelectors, accountActions } from 'modules/account/account';
import getPlanFromSubscriptionOption from './PlanOptionsData';

const AccountConfirmSubscription = ({
  subscription,
  payment,
  dispatch,
  packageOption,
  submitUpdateSubscription,
}) => {
  const [plan, setPlan] = React.useState(null);

  React.useEffect(() => {
    // if packageOption is not set
    if (!packageOption) {
      dispatch(push(`/account/change-plan`));
    } else {
      setPlan(getPlanFromSubscriptionOption(packageOption));
    }
  }, [dispatch, setPlan, packageOption]);

  const handleSubmit = plan => {
    return submitUpdateSubscription({ code: plan.code })
      .then(() => {
        dispatch(push(`/account/plan`));
      })
      .catch(e => {
        console.log(e);
      });
  };

  if (!packageOption || !plan) {
    return null;
  }

  return (
    <MainContent>
      <Title>Your Account</Title>
      <AccountNav />
      <h1>Confirm your new subscription</h1>
      <ConfirmSubscriptionSection
        plan={plan}
        payment={payment}
        onSubmit={handleSubmit}
      />
    </MainContent>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    subscription: accountSelectors.getSubscription(state),
    payment: accountSelectors.getPaymentDetails(state),
    packageOption: state.router.location.query.packageOption,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        submitUpdateSubscription: accountActions.submitUpdateSubscription,
      },
      dispatch
    ),
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountConfirmSubscription);
