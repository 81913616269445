import React from 'react';
import classNames from 'classnames';

import styles from './LaneColumn.module.css';
import LaneMenu from 'components/Planner/LaneMenu';
import PlaceholderCell from 'components/Planner/PlaceholderCell';

import LaneCellDropTarget from './LaneCellDropTarget';
import LanePlacement from './LanePlacement';
import { usePlanner } from './Provider';

// Grommet
import { Box, Text } from 'grommet';
import { Lock, SubtractCircle } from 'grommet-icons';

import {
  buildColumnCellsLayoutData,
  buildPlacementLayoutData,
  isLaneDisabledForDate,
  isLaneLockedForDate
} from './layoutUtils';

const LaneColumn = ({
  lane,
  startFrom,
  performances,
  placements,
  blocks,
  onPlacementClick,
  showModal,
  hideModal,
  onCreateAllocationClick
}) => {
  const {
    updateLane,
    deleteLane,
    updateLaneLock,
    updateLaneDisabledStatus,
    currentSelectedDay,
    columnWidth
  } = usePlanner();
  const lanePlacementData = buildPlacementLayoutData(
    startFrom,
    lane,
    performances,
    placements,
    columnWidth
  );
  const cells = buildColumnCellsLayoutData(
    startFrom,
    lane,
    performances,
    placements
  );

  const isLocked = isLaneLockedForDate(lane, currentSelectedDay);
  const isDisabled = isLaneDisabledForDate(lane, currentSelectedDay);

  const handleLockLane = lane => {
    showModal('LOCK_LANE_CONFIRM_MODAL', {
      lane: lane,
      isLocked: isLocked,
      onLockChange: lane => {
        updateLaneLock(lane, lane.isLocked).then(() => hideModal());
      }
    });
  };

  const handleDisableLane = lane => {
    showModal('DISABLE_LANE_CONFIRM_MODAL', {
      lane: lane,
      isDisabled: isDisabled,
      onDisableChange: lane => {
        updateLaneDisabledStatus(lane, lane.isDisabled).then(() => hideModal());
      }
    });
  };

  const handleEditLane = lane => {
    showModal('EDIT_LANE_MODAL', {
      lane: lane,
      onSubmit: values => {
        return updateLane(lane, values).then(() => hideModal());
      }
    });
  };

  const handleDeleteLane = lane => {
    showModal('DELETE_LANE_CONFIRM_MODAL', {
      lane: lane,
      onDelete: lane => {
        return deleteLane(lane).then(() => hideModal());
      }
    });
  };

  const columnRootClass = classNames(styles.laneColumnRoot, {
    [styles.laneColumnDisabled]: isDisabled,
    [styles.laneColumnLocked]: isLocked
  });
  const style = {
    width: `${columnWidth}px`,
    minWidth: `${columnWidth}px`
  };

  return (
    <div className={columnRootClass} style={style}>
      <div className={styles.laneColumnHeader}>
        <span className={styles.laneColumnCapacity} title="Lane Capacity">
          {lane.capacity}
        </span>
        <Box
          direction="row"
          align="center"
          gap="xsmall"
          width="170px"
          justify="center"
        >
          <Box width={{ max: '150px' }} overflow="hidden" wrap="false">
            <Text size="small" weight="900" truncate={true}>
              {lane.name}
            </Text>
          </Box>
          {isLocked ? <Lock size="small" color="light-1" /> : null}
          {isDisabled ? <SubtractCircle size="small" color="light-1" /> : null}
        </Box>
        <LaneMenu
          lane={lane}
          onLockLane={handleLockLane}
          onDisableLane={handleDisableLane}
          onEditLane={handleEditLane}
          onDeleteLane={handleDeleteLane}
          isLocked={isLocked}
          isDisabled={isDisabled}
        />
      </div>
      <div className={styles.laneColumnContent}>
        {blocks.map((block, index) => (
          <div key={index} className={styles.laneColumnBlock} />
        ))}
        {lanePlacementData.map(item => {
          if (item.type === 'placement') {
            return (
              <LanePlacement
                key={item.placement.id}
                placement={item.placement}
                performance={item.performance}
                layout={item.layout}
                onClick={onPlacementClick}
              />
            );
          } else {
            return null;
          }
        })}
        {cells.map(cell => {
          if (cell.type === 'droptarget') {
            return (
              <LaneCellDropTarget
                key={`${cell.lane.id}_${cell.performance.id}`}
                onClick={onCreateAllocationClick}
                {...cell}
              />
            );
          } else if (cell.type === 'placeholder') {
            return (
              <PlaceholderCell
                key={`${cell.lane.id}_${cell.performance.id}`}
                {...cell}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

export default LaneColumn;
