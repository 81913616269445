import React, { Fragment } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from 'react-accessible-accordion';
import { SubTitle } from '../../MainContent/MainContent';
import Table from '../../Table/Table';

import LineItemRowItem from './LineItemRowItem/LineItemRowItem';
import ReceiptTable from './ReceiptTable/ReceiptTable';
import DonationTable from './DonationTable/DonationTable';
import { FormattedMessage } from 'react-intl';
import './LineItemsTable.css';

// Grommet
import { Box, Button } from 'grommet';

const LineItemsTable = ({
  title,
  lineItems,
  selectedReceipts,
  onReceiptClick,
  onRefundClick,
  onEditClick,
  selectedItemsCount,
  orderIsFullyRefunded,
  onReceiptRedeemClick,
  readOnly,
}) => {
  const selectedReceiptCount =
    selectedReceipts.tickets.length + selectedReceipts.products.length;
  if (!lineItems) {
    return null;
  }
  return (
    <div>
      <Box margin={{ bottom: 'large' }}>
        <SubTitle title={title}>
          {!readOnly && (
            <Button
              onClick={onRefundClick}
              secondary
              size='small'
              label={
                <FormattedMessage
                  id='refund'
                  defaultMessage={`Refund {selectedItemsCount, plural, =0 {All Items} one {# Item} other {# Items}}`}
                  values={{ selectedItemsCount }}
                />
              }
            />
          )}

          {!readOnly && (
            <Button
              onClick={onEditClick}
              secondary
              size='small'
              disabled={selectedReceiptCount === 0}
              label={
                <FormattedMessage
                  id='edit'
                  defaultMessage={`{selectedReceiptCount, plural, =0 {Select Items to Edit} one {Edit # Item} other {Edit # Items}}`}
                  values={{ selectedReceiptCount }}
                />
              }
            />
          )}
        </SubTitle>
        <Box margin={{ vertical: 'medium' }}>
          {Object.keys(lineItems).map((key, idx) => {
            return (
              <Fragment key={idx}>
                <div className='line-items__section-title'>{key}</div>
                <Accordion accordion={false} className='line-items'>
                  {lineItems[key].map((lineItem, idx) => (
                    <AccordionItem key={idx}>
                      <AccordionItemTitle>
                        <Table mobileWide>
                          <LineItemRowItem
                            title={lineItem.title}
                            quantity={lineItem.quantity}
                            description={lineItem.description}
                            date={lineItem.date}
                            total={lineItem.total}
                            currency={lineItem.currency}
                            lineItemType={key}
                          />
                        </Table>
                      </AccordionItemTitle>
                      <AccordionItemBody>
                        {key !== 'donations' ? (
                          <ReceiptTable
                            selectedReceipts={selectedReceipts[key] || []}
                            receipts={lineItem.receipts}
                            onReceiptClick={id => onReceiptClick(key, id)}
                            lineItemType={key}
                            onReceiptRedeemClick={onReceiptRedeemClick}
                          />
                        ) : (
                          <DonationTable
                            selectedReceipts={selectedReceipts[key] || []}
                            donation={lineItem}
                            onReceiptClick={id => onReceiptClick(key, id)}
                          />
                        )}
                      </AccordionItemBody>
                    </AccordionItem>
                  ))}
                </Accordion>
              </Fragment>
            );
          })}
        </Box>
      </Box>
    </div>
  );
};

export default LineItemsTable;
