import React from 'react';
import styles from './UserPhoto.module.css';
import Imgix from 'react-imgix';

// generates a random dark colour based on given string
const stringToHslColor = str => {
  var s = 30;
  var l = 70;
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
};

// shows the user's photo, or a placeholder
const UserPhoto = ({ user }) => {
  const lastNameInitial = user.lastName ? user.lastName[0] : '';
  const firstNameInitial = user.firstName ? user.firstName[0] : '';
  const initial = `${firstNameInitial}${lastNameInitial}`.trim().toUpperCase();
  const color = stringToHslColor(user.firstName);
  let avatar = '';

  if (!user.photo) {
    const style = {
      backgroundColor: color
    };
    avatar = (
      <figure className={styles.root} style={style}>
        {initial}
      </figure>
    );
  } else {
    avatar = (
      <figure className={styles.root}>
        <Imgix
          src={user.photo.url}
          width={40}
          height={40}
          imgixParams={{ mask: 'ellipse' }}
        />
      </figure>
    );
  }

  return avatar;
};

export default UserPhoto;
