import React from 'react';

// Redux
import { connect } from 'react-redux';
// Modules
import { hideModal } from 'modules/modal/modal';

// Components
import AddUserForm from 'components/Event/AddUserForm/AddUserForm';
import Modal from 'components/Modal/Modal';
import { eventActions } from '../../../modules/events/events';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { toast } from 'react-toastify';

const AddUserModal = ({
  hideModal,
  submitCreateEventInvitation,
  getEventInvitations,
  getEventPermissions,
  eventId,
}) => {
  const handleAddUserFormSubmit = (values, { setFormError }) => {
    values.eventId = eventId;
    submitCreateEventInvitation(values)
      .then(() => {
        toast(`Invitation sent to ${values.emailAddress}`);
        handleCloseAddUserModal();
      })
      .catch((e) => {
        if (e.error) {
          setFormError(e.error.errors._error);
        }
      });
  };
  const handleCloseAddUserModal = () => {
    hideModal();
    getEventInvitations({ eventId: eventId });
    getEventPermissions({ eventId: eventId });
  };
  return (
    <Modal onRequestClose={handleCloseAddUserModal}>
      <AddUserForm onAddUserFormSubmit={handleAddUserFormSubmit} />
    </Modal>
  );
};

const mapStateToProps = (state, ownProps) => {
  const eventId = parseInt(ownProps.eventId, 10);
  return {
    eventId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindPromiseCreators(
      {
        submitCreateEventInvitation: eventActions.submitCreateEventInvitation,
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        getEventInvitations: eventActions.getEventInvitations,
        getEventPermissions: eventActions.getEventPermissions,
        hideModal,
      },
      dispatch
    ),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal);
