import { combineReducers } from 'redux';
import relationalPaginator from '../shared/relationalPaginator';
import * as actions from './actions';
import * as types from './types';

const ticketsByPerformanceId = relationalPaginator({
  fetchRoutine: actions.fetchTicketsForPage,
  mapActionToPage: action => action.payload.page,
  mapActionToParentKey: action => action.payload.performanceId,
});

const currentUser = (
  state = {
    id: null,
    isLoading: false,
    isLoaded: false,
  },
  action
) => {
  switch (action.type) {
    case actions.fetchUserSettings.TRIGGER:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case actions.fetchUserSettings.SUCCESS:
      return {
        ...state,
        isLoaded: true,
        id: action.payload.response.result,
      };
    case actions.fetchUserSettings.FAILURE:
      return {
        ...state,
        isLoaded: false,
      };
    case actions.fetchUserSettings.FULFILL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

const transfer = (state = { seats: [] }, action) => {
  switch (action.type) {
    case types.SELECT_PERFORMANCE_FOR_PURCHASE: {
      const { selectedPerformance } = action.payload;
      return {
        ...state,

        selectedPerformance: selectedPerformance,
      };
    }
    case types.SELECT_TICKET_FOR_PURCHASE: {
      const { selectedTicket } = action.payload;
      return {
        ...state,
        selectedTicket: selectedTicket,
      };
    }
    case types.SELECT_SEAT_FOR_PURCHASE: {
      const { selectedTicket, selectedSeat } = action.payload;
      return {
        ...state,
        selectedTicket: selectedTicket,
        seats: [...state.seats, selectedSeat],
      };
    }
    case types.UNSELECT_SEAT_FOR_PURCHASE: {
      const { selectedTicket, selectedSeat } = action.payload;
      return {
        ...state,
        seats: state.seats.filter(s => s != selectedSeat),
      };
    }

    default:
      return state;
  }
};

const transferEntries = (state = {}, action) => {
  switch (action.type) {
    case types.SELECT_PERFORMANCE_FOR_PURCHASE:
    case types.SELECT_TICKET_FOR_PURCHASE:
    case types.SELECT_SEAT_FOR_PURCHASE:
    case types.UNSELECT_SEAT_FOR_PURCHASE: {
      const { purchaseId } = action.payload;
      return {
        ...state,
        [purchaseId]: transfer(state[purchaseId], action),
      };
    }
    default:
      return state;
  }
};

const tickets = combineReducers({
  ticketsByPerformanceId,
  currentUser,
  transferEntries,
});

export default tickets;
