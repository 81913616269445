import React, { Component } from 'react';
import { connect } from 'react-redux';
/* eslint-disable react/style-prop-object */
import {
  ContentContainer,
  SubTitle,
  ContentType,
} from '../../../components/MainContent/MainContent';
import { Price } from '../../../components/Utils/Utils';
import InfoBlock, {
  InfoBlockList,
  InfoBlockListItem,
  InfoBlockText,
} from '../../../components/InfoBlock/InfoBlock';
import { showModal } from '../../../modules/modal/modal';
import Can from '../../../components/Can';
import {
  ticketAllocationActions,
  ticketAllocationSelectors,
} from '../../../modules/ticketAllocation/ticketAllocation';
import {
  ticketActions,
  ticketSelectors,
} from '../../../modules/tickets/tickets';
import { TicketAllocationList } from '../../../components/Event/Event';
import Pagination from '../../../components/Pagination/Pagination';
import { ticketStatus } from '../../../utils/types';
import { EventAuthContext } from '../event-auth-context';

import styled from 'styled-components';

// Grommet
import { Box, Button, ResponsiveContext } from 'grommet';
import { Add, Edit, Ticket, Trash } from 'grommet-icons';

const DeleteButton = styled(Button)`
  background-color: #ff2e4a;
  &:hover {
    background-color: #d41c34;
  }
`;

class TicketDetail extends Component {
  componentDidMount() {
    this.props.fetchTicket({ ticketId: this.props.ticketId });
    this.loadAllocations();
  }
  componentDidUpdate(prevProps) {
    if (this.props.ticket.id !== prevProps.ticket.id) {
      this.props.fetchTicket({ ticketId: this.props.ticketId });
      this.loadAllocations();
    }
  }
  loadAllocations = () => {
    this.props.fetchTicketAllocationPage({
      ticketId: this.props.ticket.id,
      page: this.props.allocationsPage,
    });
  };
  handleCreateTicketAllocationClick = evt => {
    evt.preventDefault();
    this.props.showModal('TICKET_ALLOCATION_CREATE_MODAL', {
      ticketId: this.props.ticket.id,
      maxInventory: this.props.ticket.inventoryLeft,
      onSubmitSuccess: this.loadAllocations,
    });
  };

  handleAllocationClick = allocationId => {
    const { eventId, performanceId, ticket } = this.props;
    this.props.history.push(
      `/event/${eventId}/performance/${performanceId}/ticket/${
        ticket.id
      }/ticket-allocation/${allocationId}`
    );
  };
  handleSelectAllocationsPage = page => {};
  handleOnDeleteClick = () => {
    this.props.showModal('ALERT_MODAL', {
      title: 'Delete Ticket',
      description: `Are you sure you wish to delete this ticket? This action cannot be undone.`,
      onConfirmClick: this.handleConfirmDelete,
    });
  };
  handleConfirmDelete = () => {
    const { eventId, performanceId, ticketId } = this.props;
    const successUrl = `/event/${eventId}/performance/${performanceId}`;
    this.props.deleteTicket({
      ticketId: ticketId,
      onSuccessUrl: successUrl,
    });
  };
  render() {
    const {
      ticket,
      eventId,
      performanceId,
      allocations,
      allocationsLoading,
      allocationsTotalPages,
      allocationsPage,
    } = this.props;
    const role = this.context;
    return (
      <ResponsiveContext.Consumer>
        {size => (
          <ContentContainer>
            <Box
              margin={{ top: 'small', bottom: 'large' }}
              pad='medium'
              background='infobox'
              round='xsmall'
            >
              <ContentType icon={<Ticket size='small' />} label='Ticket' />
              <Box margin={{ vertical: 'small' }}>
                <SubTitle title={ticket.description} />
              </Box>

              <SubTitle title={'Details'}>
                <Can
                  role={role}
                  perform={'event:edit:inventory'}
                  yes={() => (
                    <Box
                      direction={size !== 'small' ? 'row' : 'column'}
                      gap={size !== 'small' ? 'small' : 'medium'}
                      fill={size !== 'small' ? false : true}
                    >
                      <Button
                        secondary
                        size='small'
                        icon={<Edit size='small' />}
                        label='Edit'
                        href={`/event/${eventId}/performance/${performanceId}/ticket/${
                          ticket.id
                        }/edit`}
                        fill={size !== 'small' ? false : true}
                      />
                      {ticket.quantitySold === 0 && (
                        <DeleteButton
                          primary
                          size='small'
                          icon={<Trash size='small' />}
                          label='Delete'
                          onClick={this.handleOnDeleteClick}
                          fill={size !== 'small' ? false : true}
                        />
                      )}
                    </Box>
                  )}
                />
              </SubTitle>

              <InfoBlock isInverted={true}>
                <InfoBlockList>
                  <InfoBlockListItem
                    attribute='Status'
                    value={ticketStatus[ticket.status]}
                  />
                  <InfoBlockListItem
                    attribute='Face Value'
                    value={
                      <Price
                        amount={ticket.faceValueTotal}
                        currency={ticket.currency}
                      />
                    }
                  />
                  <InfoBlockListItem
                    attribute='Booking Fee'
                    value={
                      <Price
                        amount={ticket.outsideFeeTotal}
                        currency={ticket.currency}
                      />
                    }
                  />
                  <InfoBlockListItem
                    attribute='Sold'
                    value={ticket.quantitySold}
                  />
                  <InfoBlockListItem
                    attribute='Comps'
                    value={ticket.quantityComped}
                  />
                  <InfoBlockListItem
                    attribute='Held Allocations'
                    value={ticket.quantityAllocated}
                  />
                  <InfoBlockListItem
                    attribute='Reserved'
                    value={ticket.quantityReserved}
                  />
                  <InfoBlockListItem
                    attribute='Remaining'
                    value={ticket.inventoryLeft}
                  />
                  {ticket.minPerOrder !== null && (
                    <InfoBlockListItem
                      attribute='Minimum Per Order'
                      value={ticket.minPerOrder}
                    />
                  )}
                  {ticket.maxPerOrder !== null && (
                    <InfoBlockListItem
                      attribute='Maximum Per Order'
                      value={ticket.maxPerOrder}
                    />
                  )}
                </InfoBlockList>
              </InfoBlock>
              <Can
                role={role}
                perform='event:edit'
                yes={() => (
                  <>
                    <SubTitle title={'Allocations'}>
                      <Button
                        secondary
                        size='small'
                        icon={<Add size='small' />}
                        label='New Allocation'
                        onClick={this.handleCreateTicketAllocationClick}
                      />
                    </SubTitle>
                    <Box margin={{ vertical: 'medium' }}>
                      {allocationsLoading ? null : allocations.length > 0 ? (
                        <TicketAllocationList
                          allocations={allocations}
                          onClick={this.handleAllocationClick}
                        />
                      ) : (
                        <InfoBlockText>
                          There are no allocations for this event
                        </InfoBlockText>
                      )}
                      <Pagination
                        totalPages={allocationsTotalPages}
                        currentPage={allocationsPage}
                        onSelectPage={this.handleSelectAllocationsPage}
                      />
                    </Box>
                  </>
                )}
              />
            </Box>
          </ContentContainer>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}
TicketDetail.contextType = EventAuthContext;
const mapStateToProps = (state, ownProps) => {
  const eventId = parseInt(ownProps.match.params.eventId, 10);
  const performanceId = parseInt(ownProps.match.params.performanceId, 10);
  const ticketId = parseInt(ownProps.match.params.ticketId, 10);
  const allocationsPage = ticketAllocationSelectors.getCurrentPageForTicketId(
    state,
    ticketId
  );
  const allocations = ticketAllocationSelectors.getForTicketIdAndPage(
    state,
    ticketId,
    allocationsPage
  );
  const allocationsLoading = ticketAllocationSelectors.getIsLoadingForTicketId(
    state,
    ticketId,
    allocationsPage
  );
  const allocationsTotalPages = ticketAllocationSelectors.getTotalPagesForTicketId(
    state,
    ticketId
  );
  return {
    performanceId,
    eventId,
    allocationsPage,
    allocationsLoading,
    allocations,
    allocationsTotalPages,
    ticketId,
    ticket: ticketSelectors.getTicket(state, ticketId),
  };
};

export default connect(
  mapStateToProps,
  {
    ...ticketAllocationActions,
    ...ticketActions,
    showModal,
  }
)(TicketDetail);
