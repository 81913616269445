import React from 'react';
import Table, {
  TableCell,
  TableRow,
  TableBody,
  TableHeader
} from '../../components/Table/Table';
import InfoBlock from '../../components/InfoBlock/InfoBlock';

const CustomerRow = ({ purchase, index }) => (
  <TableRow striped={true} key={index}>
    <TableCell>{purchase.customer.firstName}</TableCell>
    <TableCell>{purchase.customer.lastName}</TableCell>
    <TableCell>{purchase.quantity}</TableCell>
    <TableCell>{purchase.faceValue}</TableCell>
    <TableCell>{purchase.discount}</TableCell>
    <TableCell>{purchase.bookingFee}</TableCell>
    <TableCell>{purchase.total}</TableCell>
  </TableRow>
);

const IssueTicketsTable = ({ purchases }) => (
  <InfoBlock>
    <Table>
      <TableHeader>
        <TableCell width={25}>First Name</TableCell>
        <TableCell width={25}>Last Name</TableCell>
        <TableCell width={10}>Quantity</TableCell>
        <TableCell width={10}>Face Value</TableCell>
        <TableCell width={10}>Discount</TableCell>
        <TableCell width={10}>Booking Fee</TableCell>
        <TableCell width={10}>Total</TableCell>
      </TableHeader>
      <TableBody
        renderItem={(purchase, index) => (
          <CustomerRow purchase={purchase} key={index} />
        )}
        items={purchases}
      />
    </Table>
  </InfoBlock>
);

export default IssueTicketsTable;
