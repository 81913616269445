import React from 'react';
import styled from 'styled-components';

// Grommet
import { Box } from 'grommet';

export { default as InfoBlockList } from './InfoBlockList/InfoBlockList';
export { default as InfoBlockListItem } from './InfoBlockListItem/InfoBlockListItem';
export { default as InfoBlockActions } from './InfoBlockActions/InfoBlockActions';
export { default as InfoBlockFooter } from './InfoBlockFooter/InfoBlockFooter';
export { default as InfoBlockText } from './InfoBlockText/InfoBlockText';
export { default as InfoBlockSubtitle } from './InfoBlockSubtitle/InfoBlockSubtitle';
export { default as InfoBlockImage } from './InfoBlockImage/InfoBlockImage';

const InfoBox = styled(Box)`
  background-color: ${props => (props.isInverted ? '#fff' : '#f7f8fa')};
`;

const InfoBlock = ({ children, isInverted }) => {
  return (
    <div>
      <InfoBox
        isInverted={isInverted}
        pad="medium"
        margin={{ vertical: 'medium' }}
      >
        {children}
      </InfoBox>
    </div>
  );
};

export default InfoBlock;
