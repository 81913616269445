import React from 'react';
import { FormattedNumber, FormattedDate } from 'react-intl';
/* eslint-disable react/style-prop-object */
import Table, {
  TableCell,
  TableBody,
  TableHeader,
  TableRow,
} from '../Table/Table';
import Button from '../../components/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getPerformanceStartDate } from '../../utils/dates';

const TicketRowItem = ({ ticket, onBtnClick }) => {
  const handleClick = evt => {
    evt.preventDefault();
    if (onBtnClick) {
      onBtnClick(ticket.id);
    }
  };
  return (
    <TableRow striped={true}>
      <TableCell>{ticket.event.title}</TableCell>
      <TableCell>{ticket.description}</TableCell>
      <TableCell>
        <FormattedDate
          value={getPerformanceStartDate(ticket.performance)}
          weekday='short'
          year='numeric'
          
          day='numeric'
          month='short'
          hour='numeric'
          minute='numeric'
        />
      </TableCell>
      <TableCell>
        {ticket.faceValueTotal > 0 ? (
          <FormattedNumber
            value={ticket.faceValueTotal}
            style='currency'
            currency='GBP'
          />
        ) : (
          'Free'
        )}
      </TableCell>

      <TableCell>
        {ticket.outsideFeeTotal > 0 && (
          <FormattedNumber
            value={ticket.outsideFeeTotal}
            style='currency'
            currency='GBP'
          />
        )}
      </TableCell>
      <TableCell>
        <Button danger onClick={handleClick}>
          <FontAwesomeIcon icon='minus' />
        </Button>
      </TableCell>
    </TableRow>
  );
};

const TicketList = ({ tickets, onBtnClick }) => {
  return (
    <Table>
      <TableHeader>
        <TableCell width={30}>Event</TableCell>
        <TableCell width={25}>Ticket</TableCell>
        <TableCell width={20}>Event Date</TableCell>
        <TableCell width={10}>Price</TableCell>
        <TableCell width={10}>Fee</TableCell>
        <TableCell width={5} />
      </TableHeader>
      <TableBody
        renderItem={item => (
          <TicketRowItem ticket={item} key={item.id} onBtnClick={onBtnClick} />
        )}
        items={tickets}
      />
    </Table>
  );
};

export default TicketList;
