import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
// Components
import MainContent, {
  Title,
  HeaderActions,
  PageTop,
  LineUpHelmet,
} from 'components/MainContent/MainContent';

// Grommet
import { Box, Button } from 'grommet';
import { Add } from 'grommet-icons';

import {
  seatingChartActions,
  seatingChartSelectors,
} from 'modules/seatingCharts/seatingCharts';
import { bindActionCreators } from 'redux';

import SeatingChartsTable from 'components/SeatingChartsTable/SeatingChartsTable';

const SeatingChartList = ({
  seatingCharts,
  fetchAllSeatingCharts,
  deleteSeatingChart,
  dispatch,
}) => {
  useEffect(() => {
    dispatch(fetchAllSeatingCharts());
  }, [dispatch]);
 
  const handleSeatingChartEditClick = item => {
    dispatch(push(`/tools/seating-charts/edit/${item.key}/${item.hasDraft}`));
  };
  const handleSeatingChartManageClick = item => {
    dispatch(push(`/tools/seating-charts/manage/${item.key}/`));
  };
  const handleSeatingChartDeleteClick = item => {
    deleteSeatingChart({ chartId: item.id });
  };

  return (
    <>
      <LineUpHelmet
        title='Seating Charts'
        description='Manage your seating charts in Line-Up'
      />
      <MainContent>
        <PageTop>
          <Title>Seating Charts</Title>
          <HeaderActions>
            <Button
              primary
              href='/tools/seating-charts/create'
              label='New Seating Chart'
              icon={<Add size='small' />}
              size='small'
            />
          </HeaderActions>
        </PageTop>
        <Box margin={{ vertical: 'medium' }}>
          <SeatingChartsTable
            seatingCharts={seatingCharts}
            onSeatingChartEditClick={handleSeatingChartEditClick}
            onSeatingChartManageClick={handleSeatingChartManageClick}
            onSeatingChartDeleteClick={handleSeatingChartDeleteClick}
          />
        </Box>
      </MainContent>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    seatingCharts: seatingChartSelectors.getSeatingCharts(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        ...seatingChartActions,
      },
      dispatch
    ),
    dispatch,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeatingChartList);
