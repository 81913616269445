import React, { useRef } from "react";
import { SeatsioSeatingChart } from "@seatsio/seatsio-react";
import { Box } from "grommet";
import { float } from "utils/validators";
import { Button } from "grommet";
const PRICE_FORMAT = "0,0.00";
const SeatingChart = ({
  publicKey,
  performanceId,
  tickets,
  seatSelectedHandler,
  seatUnSelectedHandler,
  quantity,
  currentSeats,
  selectedSeats,
  onSeatingChartModalClose,
}) => {
  const chart = useRef(null);

  const handleConfirm = () => {
    chart.current && chart.current.destroy();
    onSeatingChartModalClose();
  };

  const handleCancel = () => {
    chart.current && chart.current.destroy();
    onSeatingChartModalClose();
  };

  const tooltipText = (object) => {
    if (object.status === "held") {
      return "Held seat";
    }
  };

  const getPricingData = () => {
    let prices = tickets.reduce((pricing, item) => {
      (pricing[item.categoryId] = pricing[item.categoryId] || []).push({
        ticketType: item.description,
        price: item.total,
      });
      return pricing;
    }, {});
    let pricingData = Object.keys(prices).map((catId) => {
      let categoryPrices = prices[catId];
      if (categoryPrices.length === 1) {
        return {
          category: catId,
          price: categoryPrices[0].price,
        };
      }
      return {
        category: catId,
        ticketTypes: categoryPrices,
      };
    });
    return pricingData;
  };

  function currencyCodeToSymbol(currencyCode) {
    const supportedCurrencies = {
      GBP: "£",
      USD: "$",
      EUR: "€",
      AUD: "$",
      ZAR: "R",
      CAD: "$",
      DKK: "kr",
    };

    if (supportedCurrencies.hasOwnProperty(currencyCode)) {
      return supportedCurrencies[currencyCode];
    } else {
      return currencyCode;
    }
  }

  const formatPrice = (price, currency) => {
    let symbol = currencyCodeToSymbol(currency);
    let priceText = float(price).format(PRICE_FORMAT);
    return `${symbol}${priceText}`;
  };

  const getPricingFormat = (price) => {
    let currency = tickets[0].currency;
    let isFree = price == 0;
    if (isFree) {
      return "Free";
    } else {
      return formatPrice(price, currency);
    }
  };

  const getTicketFromObject = (object, selectedTicketType) => {
    const selectedTicket = tickets.find((ticket) => {
      return (
        object.category.key === ticket.categoryId &&
        (!selectedTicketType ||
          selectedTicketType.ticketType == ticket.description)
      );
    });
    return selectedTicket;
  };

  const handleObjectSelected = (object, selectedTicketType) => {
    const selectedTicket = getTicketFromObject(object, selectedTicketType);
    if (selectedTicket) {
      seatSelectedHandler(selectedTicket, object.label);
    }
  };

  const handleObjectUnSelected = (object, selectedTicketType) => {
    const selectedTicket = getTicketFromObject(object, selectedTicketType);
    if (selectedTicket) {
      seatUnSelectedHandler(selectedTicket.id, object.label);
    }
  };

  const objectIcon = (object, defaultIcon, extraConfig) => {
    if (object.category && object.category.label === "Wheelchair") {
      return "wheelchair";
    }
    if (object.selected === true) {
      return "check";
    }
    if (object.status === "held") {
      return "pause-circle";
    }
    if (
      extraConfig.currentSeats &&
      extraConfig.currentSeats.find((s) => s === object.label)
    ) {
      return "star";
    }
    return null;
  };

  return (
    publicKey && (
      <Box background="white" pad="medium">
        <Box background="white">
          <SeatsioSeatingChart
            pricing={getPricingData}
            publicKey={publicKey}
            event={`${performanceId}`}
            showLegend={false}
            priceFormatter={getPricingFormat}
            priceLevelsTooltipMessage="Please choose a ticket type:"
            tooltipInfo={tooltipText}
            onObjectSelected={handleObjectSelected}
            onObjectDeselected={handleObjectUnSelected}
            maxSelectedObjects={quantity}
            selectedObjects={selectedSeats}
            objectIcon={objectIcon}
            stylePreset="bubblegum"
            extraConfig={{ currentSeats }}
            onRenderStarted={(createdChart) => {
              chart.current = createdChart;
            }}
          />
        </Box>
        <Box direction="row" gap="medium" justify="end">
          <Button
            secondary
            onClick={handleCancel}
            label="Cancel"
            size="small"
            fill={false}
          />
          <Button
            primary
            onClick={handleConfirm}
            label="Confirm"
            size="small"
            fill={false}
          />
        </Box>
      </Box>
    )
  );
};

export default SeatingChart;
