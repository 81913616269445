import React from 'react';
import classNames from 'classnames';

// Grommet
import { Box, Text, Button, ResponsiveContext } from 'grommet';

const PageNavButton = ({ label, onClick, active, className, to }) => {
  const itemClass = classNames('page-nav-menu__item', className, {
    'page-nav-menu__item--selected': active
  });
  const itemClassSelected = classNames(
    'page-nav-menu__item',
    className,
    'page-nav-menu__item--selected'
  );
  const itemLabelClass = classNames('page-nav-menu__item-label', className);
  const itemLabelClassSelected = classNames(
    'page-nav-menu__item-label',
    className,
    'page-nav-menu__item--label-selected'
  );
  const handleOnClick = e => {
    e.preventDefault();
    onClick && onClick();
  };
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          {size !== 'small' ? (
            <span className={active ? itemClassSelected : itemClass}>
              <Button onClick={handleOnClick} plain>
                <Box pad={{ horizontal: 'small', bottom: 'xsmall' }}>
                  <Text size='small' color='heading'>
                    <span
                      className={
                        active ? itemLabelClassSelected : itemLabelClass
                      }
                    >
                      {label}
                    </span>
                  </Text>
                </Box>
              </Button>
            </span>
          ) : (
            <span className={active ? itemClassSelected : itemClass}>
              <Button onClick={handleOnClick} plain>
                <Box pad={{ horizontal: 'small', bottom: 'xsmall' }}>
                  <Text size='small' color='heading'>
                    <span
                      className={
                        active ? itemLabelClassSelected : itemLabelClass
                      }
                    >
                      {label}
                    </span>
                  </Text>
                </Box>
              </Button>
            </span>
          )}
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default PageNavButton;
